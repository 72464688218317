<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <v-row class="px-2" no-gutters>
            <span class="hamletFont text-h5 topTitleColor--text font-weight-black">
                <span>6-6.&nbsp;CCTV&nbsp;설치·운영 관리대장</span>
            </span>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme my-0" height="28" color="grey006"
                :disabled="ldr" @click="rprtActCall"
                samll outlined rounded>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>출력</span>
            </v-btn>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 mx-3 align-center" no-gutters>
            <v-col cols="2">
                <span class="fontOneRem">* <strong class="red--text">주 1회</strong>이상 작성</span>
            </v-col>
            <v-spacer></v-spacer>
            <ExpRngMonthPicker
                :strtValue="$moment().format('YYYY')+'-01'" :disabled="ldr"
                @chngDtRng="getPckrDt">
            </ExpRngMonthPicker>
            <v-spacer></v-spacer>
            <v-col cols="2"></v-col>
        </v-row>
        <v-divider :class="['mt-4', dspTblRowCnt > 19 ? 'ml-2 mr-2__5' : 'mx-2']"  color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', dspTblRowCnt > 19 ? 'ml-2 mr-2__5' : 'mx-2']" no-gutters>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 27px;" cols="1">
                <span class="font-weight-medium">연월</span>
            </v-col>
            <v-col class="" cols="11">
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 27px;" cols="2">
                        <span class="font-weight-medium">점검주</span>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR" style="padding-top: 27px;" cols="2">
                                <span class="font-weight-medium">점검일자</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR" style="line-height: 1.2; padding-top: 22px;" cols="2">
                                <span class="font-weight-medium">작동상태<br>영상 삭제주기</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR" style="line-height: 1.2; padding-top: 22px;" cols="1">
                                <span class="font-weight-medium">안내판<br>설치장소</span>
                            </v-col>
                            <v-col class="tableBR" cols="2">
                                <v-row class="px-0 py-1 justify-center" no-gutters>
                                    <span class="font-weight-medium">영상정보 저장장치</span>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col
                                        class="txtAlgnMid tableBR pt-1"
                                        style="line-height: 1.2; padding-bottom: 6px;"
                                        cols="6">
                                        <span class="font-weight-medium">정상<br>작동여부</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid" style="padding-top: 11px;" cols="6">
                                        <span class="font-weight-medium">잠금장치</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="tableBR" cols="2">
                                <v-row class="px-0 py-1 justify-center" no-gutters>
                                    <span class="font-weight-medium">모니터 관리</span>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col
                                        class="txtAlgnMid tableBR pt-1"
                                        style="line-height: 1.2; padding-bottom: 6px;"
                                        cols="6">
                                        <span class="font-weight-medium">화면 외부<br>유출 유·무</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid" style="padding-top: 11px;" cols="6">
                                        <span class="font-weight-medium">잠금장치</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR" style="padding-top: 27px;" cols="1">
                                <span class="font-weight-medium">담당자</span>
                            </v-col>
                            <v-col class="txtAlgnMid" style="padding-top: 27px;" cols="2">
                                <span class="font-weight-medium">조치사항 등</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider :class="[dspTblRowCnt > 19 ? 'ml-2 mr-2__5' : 'mx-2']"></v-divider>
        <div :class="['overflow-y-auto', dspTblRowCnt > 19 ? 'pl-2' : 'px-2']" style="width: 100%; height: 610px;">
            <v-progress-linear v-if="ldr" class="" color="cyan" indeterminate></v-progress-linear>
            <v-row v-for="(yymm, i) in tblYYMM" :key="i" class="pa-0" no-gutters>
                <v-col class="d-flex align-center justify-center tableBR tableBB pt-2" cols="1">
                    <span class="fontOneRem">{{ $moment(yymm.yymm, 'YYYYMM').format('YYYY년 MM월') }}</span>
                </v-col>
                <v-col class="" cols="11">
                    <v-row
                        v-for="(weekly, j) in tblWeekly.filter( v => v.yyyy == yymm.yyyy && v.mnth == yymm.mm )" :key="j"
                        class="pa-0 tableBB" no-gutters>
                        <v-col class="d-flex align-center justify-center tableBR py-1" cols="2">
                            <span class="fontOneRem">{{ $moment(weekly.weeklyStrt, 'YYYYMMDD').format('MM.DD') }}</span>
                            <span class="mx-1">
                                <v-icon class="" size="12">mdi-tilde</v-icon>
                            </span>
                            <span class="fontOneRem">{{ $moment(weekly.weeklyEnd, 'YYYYMMDD').format('MM.DD') }}</span>
                        </v-col>
                        <v-col class="" cols="10">
                            <v-row
                                :class="['pa-0', weekly.list.length != (k+1) ? 'tableBB' : '']"
                                v-for="(itm, k) in weekly.list" :key="k"
                                no-gutters>
                                <v-col
                                    class="crsShp-Pointer txtAlgnMid tableBR py-1"
                                    @click="onCctvOprMdl(itm.cctvOprPk)"
                                    cols="2">
                                    <span class="fontOneRem">{{ $moment(itm.inspDt).format('YYYY.MM.DD') }}</span>
                                </v-col>
                                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                    <span v-if="itm.prfrCd == '1'" class="fontOneRem">정상</span>
                                    <span v-else-if="itm.prfrCd == '2'" class="fontOneRem">비정상</span>
                                    <span class="fontOneRem">,&nbsp;&nbsp;{{ itm.dltCycl }}</span>
                                </v-col>
                                <v-col class="tableBR" cols="1">
                                    <v-row
                                        v-if="itm.instlCnfrmYn == '1'"
                                        class="px-0 justify-center align-center" style="padding-top: 6px;"
                                        no-gutters>
                                        <v-icon class="mr-1" style="padding-bottom: 2px;" size="18">mdi-check-bold</v-icon>
                                        <span class="fontOneRem">확인</span>
                                    </v-row>
                                </v-col>
                                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                    <span v-if="itm.vdOprCd == '1'" class="fontOneRem">양호</span>
                                    <span v-else-if="itm.vdOprCd == '2'" class="fontOneRem">불량</span>
                                </v-col>
                                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                    <span v-if="itm.vdLckCd == '1'" class="fontOneRem">양호</span>
                                    <span v-else-if="itm.vdLckCd == '2'" class="fontOneRem">불량</span>
                                </v-col>
                                <v-col class="tableBR" cols="1">
                                    <v-row
                                        v-if="itm.extrLeakYn == '1'"
                                        class="px-0 justify-center align-center" style="padding-top: 6px;"
                                        no-gutters>
                                        <v-icon class="mr-1" style="padding-bottom: 2px;" size="18">mdi-check-bold</v-icon>
                                        <span class="fontOneRem">확인</span>
                                    </v-row>
                                </v-col>
                                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                    <span v-if="itm.mntrLckCd == '1'" class="fontOneRem">양호</span>
                                    <span v-else-if="itm.mntrLckCd == '2'" class="fontOneRem">불량</span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="1">
                                    <span class="fontOneRem">{{ itm.wrtr }}</span>
                                </v-col>
                                <v-col class="overflow-text-hidden pa-2 py-1" cols="2">
                                    <span class="fontOneRem">{{ itm.cntnt }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="weekly.list.length == 0" class="pa-0" no-gutters>
                                <v-col
                                    class="nmlBfClrImp-yellow001 crsShp-Pointer txtAlgnMid tableBR py-1"
                                    @click="onCctvOprMdl(0)" cols="2">
                                    <span class="fontOneRem">미작성</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <CctvOprSavMdl
                v-if="lclMdlInf.typ == '01'"
                @offMdl="offCctvOprMdl">
            </CctvOprSavMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import ExpRngMonthPicker from '../../components/commons/ExpRngMonthPicker.vue';

import CctvOprSavMdl from '../../components/opr/CctvOprSavMdl.vue';                       // 욕구사정

export default {
    name: 'CctvOprMgmt',
        
    components: {
        ExpRngMonthPicker,
        CctvOprSavMdl,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        // CCTV 관리대장 다건 조회
        getCctvOprList: function (strt, end){
            let obj = {}
            obj.inqYYMMStart = strt
            obj.inqYYMMEnd = end

            http.post('opr/selCctvOprList', obj)
                .then((response) => ( this.cctvOprRst(response.data) ))
                .catch((error) => console.log('connect error /opr/selCctvOprList : ' + error))
        },

        cctvOprRst: function(res){
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    let chc = this.tblWeekly.findIndex( v => v.weeklyStrt == itm.weekStart && v.weeklyEnd == itm.weekEnd )
                    if(chc > -1){
                        if(this.tblWeekly[chc].list.length == 0)
                            this.dspTblRowCnt -= 1

                        this.dspTblRowCnt += 1
                        this.tblWeekly[chc].list.push(itm)
                    }
                });
            }

            this.ldr = false
        },

        // 연월 기간 피커 변동 이벤트
        getPckrDt: function(obj){
            this.startYYMM = obj.strtYYMM
            this.endYYMM = obj.endYYMM
            this.getRngWeely(obj)
        },

        // 주차별 테이블 로우 생성
        getRngWeely: function(obj){
            this.tblYYMM.splice(0)
            this.tblWeekly.splice(0)
            this.dspTblRowCnt = 0
            this.ldr = true
       
            let strtDt = this.$moment(obj.strtYYMM, 'YYYYMM').day(7).format('YYYYMMDD')
            let endDt = this.$moment(obj.endYYMM, 'YYYYMM').endOf('month').format('YYYYMMDD')
            let diffToDay = Math.abs(this.$moment(strtDt, 'YYYYMMDD').diff(this.$moment(endDt, 'YYYYMMDD')))
            let idx = this.$moment.duration(diffToDay).asDays() / 7

            for (let index = 0; index <= idx; index++) {
                let tmp = this.$moment(strtDt, 'YYYYMMDD').add((index*7), 'day')
                let wbj = {}
                wbj.yyyy = tmp.format('YYYY')
                wbj.mnth = tmp.format('MM')
                wbj.weeklyStrt = tmp.format('YYYYMMDD')
                wbj.weeklyEnd = this.$moment(tmp).add(6, 'day').format('YYYYMMDD')
                wbj.list = []

                this.dspTblRowCnt += 1
                this.tblWeekly.push(wbj)
                
                // 노출할 연월 목록 작성
                let chc = this.tblYYMM.findIndex( v => v.yymm == tmp.format('YYYYMM') )
                if(chc == -1){
                    let yobj = {}
                    yobj.yyyy = tmp.format('YYYY')
                    yobj.mm = tmp.format('MM')
                    yobj.yymm = tmp.format('YYYYMM')
                    this.tblYYMM.push(yobj)
                }
            }

            // 정렬
            this.tblYYMM.sort(
                function (a, b) {
                    let x = a.yymm.toLowerCase()
                    let y = b.yymm.toLowerCase()

                    if (x > y)
                        return -1
                    if (x < y)
                        return 1

                    return 0
            })

            this.tblWeekly.sort(
                function (a, b) {
                    let x = a.weeklyStrt.toLowerCase()
                    let y = b.weeklyStrt.toLowerCase()

                    if (x > y)
                        return -1
                    if (x < y)
                        return 1

                    return 0
            })

            // 조회
            this.getCctvOprList(obj.strtYYMM, obj.endYYMM)
        },

        // CCTV 관리대장 수정, 저장 모달
        onCctvOprMdl: function (pk) {
            let obj = {}
            obj.cctvOprPk = pk

            if(this.ldr == false){
                this.$store.commit('setTmpObj', obj)
                this.onLclMdl('01', 1000)
            }
            else{
                let wrnnObj = {}
                wrnnObj.actv = true                                       // 경고 노출여부
                wrnnObj.tmr = 2500                                        // 경고 노출시간 (-1: 무제한)
                wrnnObj.type = 'info'                                     // 경고 유형 (success, info, warning, error)
                wrnnObj.title = '비정상 접근입니다. 잠시후 다시 시도해주세요'   // 경고 타이틀
                wrnnObj.cntnt = []                                        // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', wrnnObj)
            }
        },

        offCctvOprMdl: function () {
            this.tblWeekly.forEach(itm => {
                if(itm.list.length > 0)
                    itm.list.splice(0)
            });
        
            this.dspTblRowCnt = this.tblWeekly.length
            this.getCctvOprList(this.startYYMM, this.endYYMM)
            this.$store.commit('setTmpObj', {})

            this.rstLclMdlInf()
        },

        // 공통 출력물 팝업 호출
        rprtActCall: function (){
            let oObj = {}
            oObj.rptTyp = '1'
            oObj.mode = '2'
            oObj.fcltyNum = this.$store.getters.getFcltyNum
            oObj.prtClcd = '1'
            oObj.inqClcd = 1
            oObj.prtCd = '90'
            oObj.inqYyMmStart = this.startYYMM
            oObj.inqYyMmEnd = this.endYYMM
            oObj.list = [ { bzClcd: [], bzPk: [] } ]

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: oObj, outObj: null, runEvt: true,
            });
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },
    
    data: () => ({
        ldr: true,           // 로딩바
        dspTblRowCnt: 0,     // 테이블 로우 수
        startYYMM: '',
        endYYMM: '',
        tblYYMM: [],         // 연월목록
        tblWeekly: [],       // 주별목록
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },     // 통합 팝업 제어
    }),
};
</script>