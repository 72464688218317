<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">일반상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 25px;" cols="2">
                        <span class="font-weight-medium">보행</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_1" :ripple="false"
                                true-value="1" false-value="2" label="단독보행"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_8" :ripple="false"
                                true-value="1" false-value="2" label="보조기사용"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_2" :ripple="false"
                                true-value="1" false-value="2" label="지팡이"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_3" :ripple="false"
                                true-value="1" false-value="2" label="보행기"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_4" :ripple="false"
                                true-value="1" false-value="2" label="휠체어"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_5" :ripple="false"
                                true-value="1" false-value="2" label="부축도움"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_6" :ripple="false"
                                true-value="1" false-value="2" label="보행불가능"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_7" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 212px;">
                                <v-text-field
                                    v-model="inpTxt.val_8_7"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="2">
                        <span class="font-weight-medium">치매</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_9_1" :ripple="false"
                                true-value="1" false-value="2" label="기억력저하"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_9_2" :ripple="false"
                                true-value="1" false-value="2" label="기억력저하 + 행동변화증상"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_9_3" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 108px;">
                                <v-text-field
                                    v-model="inpTxt.val_9_3"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 13px; line-height: 1.3;" cols="2">
                        <span class="font-weight-medium">치매증상<br>&nbsp;&#40;&nbsp;행동변화&nbsp;&#41;&nbsp;</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_1" :ripple="false"
                                true-value="1" false-value="2" label="배회"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_2" :ripple="false"
                                true-value="1" false-value="2" label="야간수면장애"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_3" :ripple="false"
                                true-value="1" false-value="2" label="망상·환각"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_4" :ripple="false"
                                true-value="1" false-value="2" label="폭력성"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_5" :ripple="false"
                                true-value="1" false-value="2" label="우울·불안"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_6" :ripple="false"
                                true-value="1" false-value="2" label="거부"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_7" :ripple="false"
                                true-value="1" false-value="2" label="성적행동"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_8" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 249px;">
                                <v-text-field
                                    v-model="inpTxt.val_10_8"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 60px;" cols="2">
                        <span class="font-weight-medium">시력상태</span>
                    </v-col>
                    <v-col class="" style="padding-top: 1px; padding-bottom: 2px;" cols="10">
                        <v-radio-group
                            v-model="inpRadio.val_11_1"
                            class="radio-dft-Theme mt-0" ref="info" hide-details row>
                            <v-radio
                                class="pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="정상 (안경사용포함)" value="1">
                            </v-radio>
                            <v-radio
                                class="pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="1미터 정도 떨어진 글씨는 읽을 수 있다" value="2">
                            </v-radio>
                            <v-radio
                                class="dft-rdo-line pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="눈 앞에 근접한 글씨만 읽을 수 있다" value="3">
                            </v-radio>
                            <v-radio
                                class="dft-rdo-line pl-2" style="padding-bottom: 2px !important; padding-right: 120px !important;"
                                :ripple="false" label="거의 보이지 않는다" value="4">
                            </v-radio>
                            <v-radio
                                class="dft-rdo-line pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="보이는지 판단 불능" value="5">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 60px;" cols="2">
                        <span class="font-weight-medium">청력상태</span>
                    </v-col>
                    <v-col class="" style="padding-top: 1px; padding-bottom: 2px;" cols="10">
                        <v-radio-group
                            v-model="inpRadio.val_12_1"
                            class="radio-dft-Theme mt-0" ref="info" hide-details row>
                            <v-radio
                                class="pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="정상 (보청기 사용포함)" value="1">
                            </v-radio>
                            <v-radio
                                class="pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="가까운 곳에서 대화는 가능하나 먼곳의 말소리는 듣지 못한다" value="2">
                            </v-radio>
                            <v-radio
                                class="dft-rdo-line pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="큰소리만 들을 수 있다" value="3">
                            </v-radio>
                            <v-radio
                                class="dft-rdo-line pl-2" style="padding-bottom: 2px !important; padding-right: 120px !important;"
                                :ripple="false" label="소리에 거의 반응이 없다" value="4">
                            </v-radio>
                            <v-radio
                                class="dft-rdo-line pl-2 pr-16" style="padding-bottom: 2px !important;"
                                :ripple="false" label="들리는지 판단 불능" value="5">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row class="pa-0 mb-2 posn-rltv" no-gutters>
                    <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 정보</span>
                </v-row>
                <v-divider class="" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                        <span class="font-weight-medium">키(cm)</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-2 py-1" cols="4">
                        <v-text-field
                            v-model="inpTxt.val_1_1"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                        <span class="mx-1" style="margin-bottom: 2px;">cm</span>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                        <span class="font-weight-medium">체중(kg)</span>
                    </v-col>
                    <v-col class="d-flex align-center px-2 py-1" cols="4">
                        <v-text-field
                            v-model="inpTxt.val_1_2"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                        <span class="mx-1" style="margin-bottom: 2px;">kg</span>
                    </v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 8px; padding-bottom: 7px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="21" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'GnrlState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                let filterList = value.filter( v => v.depth3 != '13' && v.depth3 != '14' && v.depth3 != '90' )
                if(filterList.length > 0)
                    this.prcRecAbst(filterList)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            let rec1 = arr.filter( v => v.depth3 == '1' )       // 수급자 정보
            let recOthr = arr.filter( v => v.depth3 != '1' )    // 수급자 제외
        
            rec1.forEach(itm => {
                this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });

            recOthr.forEach(itm => {
                if(itm.depth3 == '11' || itm.depth3 == '12')
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                else
                    this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '8' && itm.valCd == '7')
                    this.inpTxt.val_8_7 = itm.val2
                else if(itm.depth3 == '9' && itm.valCd == '3')
                    this.inpTxt.val_9_3 = itm.val2
                else if(itm.depth3 == '10' && itm.valCd == '8')
                    this.inpTxt.val_10_8 = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt.val_99_1 = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                Object.keys(this.inpTxt).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '12'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = ''
                    oObj.val2 = this.inpTxt[itm]

                    if(oObj.depth3 == '1')
                        finArr.push(oObj)
                    else if(oObj.depth3 == '99'){
                        oObj.depth2 = '4'
                        finArr.push(oObj)
                    }
                });

                Object.keys(this.inpRadio).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0 
                    oObj.depth2 = '4'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpRadio[itm]
                    oObj.val2 = ''

                    finArr.push(oObj)
                });

                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0 
                    oObj.depth2 = '4'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '8' && oObj.valCd == '7')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '9' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '10' && oObj.valCd == '8')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
        
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    if(oObj.depth3 == '11' || oObj.depth3 == '12')
                        oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else
                        oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '1'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '8' && oObj.valCd == '7')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '9' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '10' && oObj.valCd == '8')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }
  
            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = '0'
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 수급자 정보, 기타, 판단근거
        inpTxt: { val_1_1: '', val_1_2: '', val_8_7: '', val_9_3: '', val_10_8: '', val_99_1: '' },
        // 시력상태 (d3: 11) - cd1: 정상(안경사용포함), cd2: 1미터 정도 ~ , cd3: 눈앞에 ~, cd4: 거의 보이지 않음, cd5: 보이는지 판단 불능
        // 청력상태 (d3: 12) - cd1: 정상(보청기사용포함), cd2: 가까운 곳에서 ~ , cd3: 큰소리만 ~, cd4: 소리에 거의 ~, cd5: 들리는지 판단 불능
        inpRadio: { val_11_1: '0', val_12_1: '0', },
        // 보행 (d3: 8) - cd1: 단독보행, cd2: 지팡이, cd3: 보행기, cd4: 휠체어, cd5: 부축도움, cd6: 보행불가능, cd7: 기타
        // 치매 (d3: 9) - cd1: 기억력저하, cd2: 기억력저하+행동변화증상, cd3: 기타
        // 치매증상 (d3: 10) - cd1: 배회, cd2: 야간수면장애, cd3: 망상환각, cd4: 폭력성, cd5: 우울불안, cd6: 거부, cd7: 성적행동, cd8: 기타
        inpCheck: {
            val_8_1: '2', val_8_8: '2', val_8_2: '2', val_8_3: '2', val_8_4: '2', val_8_5: '2', val_8_6: '2', val_8_7: '2',
            val_9_1: '2', val_9_2: '2', val_9_3: '2', val_10_1: '2', val_10_2: '2', val_10_3: '2', val_10_4: '2',
            val_10_5: '2', val_10_6: '2', val_10_7: '2', val_10_8: '2',
        },
    }),
};
</script>