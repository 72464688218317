<template>
    <v-sheet>
        
        <div class="d-flex justify-center align-center pr-3">
            <v-chip-group v-model="subTab" class="my-1" mandatory>
                <v-chip class="px-9" active-class="black100 white--text font-weight-bold" style="font-size: 1rem;" @click="isSubTab(0)">급여제공기록</v-chip>
                <v-chip class="px-9" active-class="black100 white--text font-weight-bold" style="font-size: 1rem;" @click="isSubTab(1)">바이탈기록</v-chip>                
            </v-chip-group>
            <span class="d-inline-block red--text" style="width:300px;">※ 체중 매월 1회 이상 측정</span>
            <v-spacer></v-spacer>   
            <div v-if="subTab==0">
                <v-btn 
                    class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25" @click="getPreData"
                    :disabled="preDisabled1"
                    small outlined>
                    <v-icon size="14">mdi-text-box-search-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
                </v-btn>
            </div>       
            <div v-if="subTab==1">  
                <v-btn
                    class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25" @click="getPreData"
                    :disabled="preDisabled2"
                    small outlined>
                    <v-icon size="14">mdi-text-box-search-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
                </v-btn>
            </div>
            <div>
                <v-btn                
                    class="ml-1" color="grey006" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"
                    @click="$parent.$parent.$parent.$parent.prtPyrprRcrd(subTab)"                    
                    small outlined>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                <v-icon size="20">mdi-printer-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">출력</span>
                            </div>
                        </template>
                        <span>건강기록지 출력</span>
                    </v-tooltip>
                </v-btn>
            </div>
        </div>
        <div style="height:310px;">            
            <div v-show="subTab == 0">
                <v-form ref="tabform1" lazy-validation>
                <v-row no-gutters>
                    <v-col cols="6">                       
                        <div class="d-flex greyE01 pa-0 mr-2__5">
                            <div class="d-flex justify-center align-center tableBT tableBB" style="width:20%;">
                                <span class="black--text font-weight-medium">급여계획</span>
                            </div>
                            <div class="tableBT tableBL" style="width:80%">
                                <div class="d-flex">
                                    <div class="d-flex pa-1 justify-center align-center tableBB" style="width:40%">
                                        <span class="black--text font-weight-medium">제공내용</span>
                                    </div>
                                    <div class="d-flex pa-1 justify-center align-center tableBB tableBL" style="width:30%">
                                        <span class="black--text font-weight-medium">제공계획</span>
                                    </div>
                                    <div class="d-flex pa-1 justify-center align-center tableBB tableBL" style="width:20%">
                                        <span class="black--text font-weight-medium">제공기록</span>
                                    </div>
                                    <div class="d-flex pa-1 justify-center align-center tableBB tableBL" style="width:10%">
                                        <span class="black--text font-weight-medium">처리</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="hlthNrsRecList.length > 0" style="overflow-y:scroll;height:284px;font-size:0.9rem !important">
                            <div class="d-flex pa-0 ma-0" v-for="(item, j) in hlthNrsRecList" :key="j">
                                <div class="d-flex justify-center align-center tableBB" style="width:20%;">
                                    <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                                </div>
                                <div class="tableBL" style="width:80%">
                                    <div class="d-flex" v-for="(list, k) in item.list" :key="k">
                                        <div class="d-flex pa-1 justify-center align-center tableBB" style="width:40%">
                                            {{list.clsfc3Cdnm}}
                                        </div>
                                        <div class="d-flex pa-1 justify-center align-center tableBB tableBL" style="width:30%">
                                            {{list.itmValNm}}
                                        </div>
                                        <div class="d-flex pa-1 justify-center align-center tableBB tableBL" style="width:20%">
                                            <span style="width:50px">
                                                <v-text-field 
                                                    v-model="list.itm1Val"
                                                    :ref="`nrsRec_itm1Val_`+j" @keydown.enter="nxtTxtAction(j,k)"
                                                    color="blue" height="28"
                                                    :rules="[rules.numberCustmCheck]"
                                                    hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                            <span class="pl-1">분</span>
                                        </div>
                                        <div class="d-flex pa-1 justify-center align-center tableBB tableBL" style="width:10%">
                                            <v-btn  icon color='grey006' @click="delRecList(j,k)">
                                                <v-icon v-if="list.bnftOfrRecPk > 0">mdi-trash-can-outline</v-icon>
                                                <v-icon v-else>mdi-eraser</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="mr-2__5 tableBB">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6" class="pl-2">
                        <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4"> 
                                <span class="black--text font-weight-medium">작성자</span>
                            </v-col>
                            <v-col class="d-flex justify-start align-center py-1 ma-0" cols="8"> 
                                <span class="ml-1" style="width:75px;">
                                    <v-text-field v-model="hlthNrsRecInfo.wrtr" color="blue" height="28" readonly hide-details outlined dense></v-text-field>
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="30"
                                    height="28"  
                                    dark
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onModal(1)"
                                >
                                    <v-icon size="22">mdi-account-edit</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <div>
                                    <div class="black--text d-flex justify-center align-center font-weight-medium">내용</div>
                                    <div class="black--text d-flex justify-center align-center font-weight-medium" style="font-size:0.9rem !important">&#40;급여제공기록지기재&#41;</div>
                                    <div class="black--text d-flex justify-center align-center font-weight-medium">
                                        <v-btn 
                                            class="mt-2 ml-1" color="blue001" style="padding: 13px 12px 12px 10px !important"
                                            min-width="30" height="25" @click="menu.splice(2, 1, true), hlthNrsRecCntntPk=1">
                                            <v-icon class="white--text" size="20">mdi-clipboard-text-outline</v-icon>
                                            <span class="white--text">특이사항 목록</span>
                                        </v-btn>
                                    </div>
                                </div> 
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="8"> 
                                <v-textarea v-model="hlthNrsRecInfo.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="7" outlined dense hide-details no-resize>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4"> 
                                <span class="black--text font-weight-medium">상세내용&#40;필요시&#41;</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="8"> 
                                <v-textarea v-model="hlthNrsRecInfo.spclNote" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="7" outlined dense hide-details no-resize>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                </v-form>
            </div>
            <div v-show="subTab == 1">
                <v-form ref="tabform2" lazy-validation>
                    <div :class="['d-flex', hlthVtlRecList.length > 6 ? 'mr-2__5' : '']" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;">                                
                            <span class="black--text font-weight-medium">회차</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">시간&#40;필요시&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">혈압&#40;고&#47;저&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">맥박&#40;회&#47;분&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">체온&#40;°C&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">호흡&#40;회&#47;분&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">혈당&#40;mg&#47;oL&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">체중&#40;kg&#41;</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">                                
                            <v-btn 
                                class="mr-1" 
                                color="blue200" 
                                height="26" 
                                @click="addList2"
                                icon>
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div style="height:274px;overflow-y:auto"> 
                        <div v-for="(item,k) in hlthVtlRecList" :key="k" class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;">                                
                                {{k+1}}
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                <!-- <span style="width:60px;">
                                    <v-text-field v-model="item.obsrvTmHh" :rules="[rules.required]" color="blue" hide-details outlined dense></v-text-field>
                                </span>
                                <span class="px-1">&#58;</span>
                                <span style="width:60px;">
                                    <v-text-field v-model="item.obsrvTmMm" :rules="[rules.required]" color="blue" hide-details outlined dense></v-text-field>
                                </span> -->
                                <span class="white d-inline-block pa-1" style="width: 80px;"> 
                                    <CmmTimeField v-model="item.obsrvTm" :required="false" :ref="`cmmtime_`+(k)+`_1`" @nxtFocus="nxtAction(k,2)"></CmmTimeField>                                    
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                                <div class="d-flex justify-center align-center">
                                <span style="width:70px;">
                                    <v-text-field v-model="item.bldPrsH" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_2`" @keydown.enter="nxtAction(k,3)" onfocus="this.select()"></v-text-field>
                                </span>
                                <span class="px-1">&#47;</span>
                                <span style="width:70px;">
                                    <v-text-field v-model="item.bldPrsL" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_3`" @keydown.enter="nxtAction(k,4)" onfocus="this.select()"></v-text-field>
                                </span>
                                </div>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:70px;">
                                    <v-text-field v-model="item.pulse" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_4`" @keydown.enter="nxtAction(k,5)" onfocus="this.select()"></v-text-field>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:70px;">
                                    <v-text-field v-model="item.tmprt" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_5`" @keydown.enter="nxtAction(k,6)" onfocus="this.select()"></v-text-field>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:70px;">
                                    <v-text-field v-model="item.rsprt" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_6`" @keydown.enter="nxtAction(k,7)" onfocus="this.select()"></v-text-field>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:70px;">
                                    <v-text-field v-model="item.bldSgr" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_7`" @keydown.enter="nxtAction(k,8)" onfocus="this.select()"></v-text-field>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:70px;">
                                    <v-text-field v-model="item.wght" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(k)+`_8`" @keydown.enter="nxtAction(k,1)" onfocus="this.select()"></v-text-field>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">                                                                
                                <v-btn v-if="item.hlthVtlRecPk > 0" icon color='grey006' @click="delHlthVtl(k)">
                                    <v-icon >mdi-trash-can-outline</v-icon>
                                </v-btn>
                                <v-btn v-else-if="item.hlthVtlRecPk==0 && k!=0" icon color='grey006' @click="delHlthVtl(k)">                                            
                                    <v-icon >mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-form>
            </div>            
        </div>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">건강 및 간호 제공 기록내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="200" locale="ko"
                :headers="headers"
                :items="hlthHisList" 
                hide-default-header       
                hide-default-footer 
                dense disable-pagination>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[3].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[4].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[5].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[6].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[7].text}}</th> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[8].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:225px !important;">{{props.headers[9].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="['fixedCol', inqYMD == item.recDt ? 'sltRow' : 'disSltRow']"
                        @click="tableClickEvent(item.recDt)">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.recDt?.beforeDateFormatDot()}}</td> 
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.bldPrs}}</td>      
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.pulse}}</td>   
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.tmprt}}</td>      
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.rsprt}}</td>      
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.bldSgr}}</td>   
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.wght}}</td>    
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.hltTmSum}}</td>      
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.nlsTmSum}}</td>    
                        <td class="clmsBodyCommon fixedCol0">
                            <div style="width:225px;" class="text-overflow">{{item.cntnt}}</div>
                        </td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="mt-2">            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt = $parent.$parent.$parent.$parent.inqYMD></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[2]" eager content-class="round" persistent max-width="800">
                <hlthNrsRecCntntListMdl 
                    :hlthNrsRecCntntPk="hlthNrsRecCntntPk"
                    :bnMmbrPk = $parent.$parent.$parent.$parent.bnMmbrPk
                    :prtWrtDt = $parent.$parent.$parent.$parent.inqYMD
                    @isClose="isModal(2)" 
                    @outCntnt="getCntnt">
                </hlthNrsRecCntntListMdl>
            </v-dialog>
        </div>     
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue'
import EmpSelector from '../../components/commons/EmpSelector.vue'
import hlthNrsRecCntntListMdl from './HlthNrsRecCntntListMdl.vue'

import { selHlthNsgRcrd, insMultiBnftRcrd, insHlthVtlMultiRec,  selHlthNrsRecList,
            selHlthVtlRecList, selHlthNrsRecHisList, delHlthNsgRcrd, delBnftOfrRec, delHlthVtlRec} from '../../api/bnftrcrd.js';


export default {
    name: 'HlthNsgRcrdTab',

    components: {
        EmpSelector,
        CmmTimeField,
        hlthNrsRecCntntListMdl,        
    },

    props : {

    },

    created: function(){       
        // this.getCmnItmCd()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           this.inqYMD = this.$parent.$parent.$parent.$parent.inqYMD
        })
    },

    computed: {
        getYmd:function(){
            return this.$parent.$parent.$parent.$parent.inqYMD
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },  

    watch: {
        'getYmd':function(value){
           this.inqYMD = value 
        },
        getSubEvt: function (bol) {           
            if(bol && this.rmHlthVtlCnfm){
                this.rmHlthVtlRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmHlthVtlCnfm = false
            }

            if(bol && this.rmHlthRecCnfm){
                this.rmBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmHlthRecCnfm = false
            }

            if(bol && this.rmAllHlthNsgCnfm){
                this.onDelete()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmAllHlthNsgCnfm = false
            }
            
        },
    },

    methods: {        
        
        onLoad:function(){
            this.subTab = 0
            this.page=1
            this.onReset()
            // this.getHlthNrsRecList()
            // this.getHlthVtlRecList()
            // this.getHlthNrsRecHisList(0,this.itemsRows)     
            this.getHlthNsgRcrd()   
        },
        reLoad:function(){
            this.onReset()
            this.getHlthNsgRcrd()
        },        
        onReset:function(){
            this.hlthNrsRecInfo = JSON.parse(JSON.stringify(this.dummyHlthNrsRecInfo))                
            this.preData1       = false
            this.preData2       = false
            this.preDisabled1   = false
            this.preDisabled2   = false
        },
        frmReset:function(){
            this.$refs.tabform1.resetValidation();  
            this.$refs.tabform2.resetValidation(); 
        },
        isSubTab: function (value) {
            this.subTab = value
        },
        //건강 및 간호 기록 화면
        getHlthNsgRcrd:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum, 
                bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD      : this.$parent.$parent.$parent.$parent.inqYMD, 
                recDt       : this.$parent.$parent.$parent.$parent.inqYMD, 
                inqYYMM     : this.$moment(this.$parent.$parent.$parent.$parent.inqYMD, 'YYYYMMDD').format('YYYYMM'),
                clsfc1Cd    : '302',
                clsfc2Cd    : '1',
                rnStrt      : 0,
                rnLmt      : this.itemsRows,
            }
            selHlthNsgRcrd(obj)
                .then((response) => ( this.getHlthNsgRcrdAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selHlthNsgRcrd : ' + error))
        },

        getHlthNsgRcrdAfter:function(res){
            if(res.statusCode == 200){                  
                if(res.result.nrsInfo != undefined)     this.getHlthNrsRecListAfter(res.result.nrsInfo)
                if(res.result.vtlRecList != undefined)  this.getHlthVtlRecListAfter(res.result.vtlRecList)
                if(res.result.hisInfo != undefined)     this.getHlthNrsRecHisListAfter(res.result.hisInfo)
            } else {
                this.$emit("hlthBlnftTrigger",res)
            }
        },
        onSave:function(dupYn = '2'){
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){
                if(this.subTab == 0){
                    if(this.$refs.tabform1.validate()){
                        
                        let obj = {
                            fcltyNum    : this.$store.getters.getFcltyNum,
                            bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                            userNm      : this.$store.getters.getUserNm,
                            ignrYn      : dupYn,
                            hisList     : []
                        }
    
                        this.hlthNrsRecList.forEach(el => {
                            let item = {                                
                                recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                                clsfc1Cd    : el.clsfc1Cd,
                                clsfc2Cd    : el.clsfc2Cd,
                                clsfc3Cd    : el.clsfc3Cd,
                                mdfDt       : el.mdfDt,
                                wrtr        :this.hlthNrsRecInfo.wrtr,
                                wrtrMmbrPk  :this.hlthNrsRecInfo.wrtrMmbrPk,
                                recList     : []
                            }
    
                            el.list.forEach(itm => {
                                let list = {                         
                                    itm1Cd          : itm.itm1Cd,
                                    itm1Val         : itm.itm1Val,
                                    wrtr            : this.hlthNrsRecInfo.wrtr,
                                    wrtrMmbrPk      : this.hlthNrsRecInfo.wrtrMmbrPk,
                                    bnftOfrRec302Pk : (itm.bnftOfrRecPk != null)?itm.bnftOfrRecPk:0,
                                }
                                item.recList.push(list)
                            });

                            if(item.recList.length > 0) obj.hisList.push(item)
                        });


                        let recInfo = {                           
                            recDt           : this.$parent.$parent.$parent.$parent.inqYMD,
                            clsfc1Cd        : this.hlthNrsRecInfo.clsfc1Cd,
                            clsfc2Cd        : this.hlthNrsRecInfo.clsfc2Cd,
                            clsfc3Cd        : this.hlthNrsRecInfo.clsfc3Cd,
                            cntnt           : this.hlthNrsRecInfo.cntnt,
                            spclNote        : this.hlthNrsRecInfo.spclNote,
                            wrtr            : this.hlthNrsRecInfo.wrtr,
                            wrtrMmbrPk      : this.hlthNrsRecInfo.wrtrMmbrPk,
                            mdfDt           : this.hlthNrsRecInfo.mdfDt,
                            recList         : []
                        }

                        let recInfolist = {                                  
                            wrtr                : this.hlthNrsRecInfo.wrtr,
                            wrtrMmbrPk          : this.hlthNrsRecInfo.wrtrMmbrPk,
                            bnftOfrRec302Pk     : this.hlthNrsRecInfo.bnftOfrRecPk,
                        }

                        recInfo.recList.push(recInfolist)
                        obj.hisList.push(recInfo)
                        
                        this.$emit('onProgress','1')
                        
                        insMultiBnftRcrd(obj)
                            .then((response) => ( this.onSaveAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
    
                    }
                }else if(this.subTab == 1){
                    if(this.$refs.tabform2.validate()){
                        let obj = {
                            list:[],
                        }

                        this.hlthVtlRecList.forEach(e => {
                            e.fcltyNum  = this.$store.getters.getFcltyNum  
                            e.bnMmbrPk  = this.$parent.$parent.$parent.$parent.bnMmbrPk 
                            e.userNm    = this.$store.getters.getUserNm                   
                            e.recDt     = this.$parent.$parent.$parent.$parent.inqYMD     
                            
                            if(e.obsrvTm !=''){
                                let obsrvTm = e.obsrvTm.split(":")
                                e.obsrvTmHh = obsrvTm[0]
                                e.obsrvTmMm = obsrvTm[1]
                            }

                            if((e.bldPrsH != '' && e.bldPrsH != null) || (e.bldPrsL != '' && e.bldPrsL != null) 
                            || (e.pulse != '' && e.pulse != null) || (e.tmprt != '' && e.tmprt != null)
                            || (e.rsprt != '' && e.rsprt != null) || (e.bldSgr != '' && e.bldSgr != null)
                            || (e.wght != '' && e.wght != null)) obj.list.push(e)                            
                        });

                        
                        if(obj.list.length > 0){
                            this.$emit('onProgress','1')
                            insHlthVtlMultiRec(obj)
                                .then((response) => ( this.onSaveAfter(response.data) ))
                                .catch((error) => console.log('upload error /bnftrcrd/insHlthVtlMultiRec : ' + error))
                        } else {
                            let msgObj = {
                                statusCode  : 300,
                                message     : '변경사항이 없습니다.'
                            }

                            this.$emit("hlthBlnftTrigger",msgObj)
                        }
                    }            
                }
            }
        },
        onSaveAfter:function(res){
            this.$emit("hlthBlnftTrigger",res)
            this.$emit('onProgress','2')

            if(res.statusCode == 200) this.reLoad()
        },        
        
        getHlthVtlRecList(){
            let fcltyNum = this.$store.getters.getFcltyNum

            let obj = {
                fcltyNum: fcltyNum, 
                clsfc1Cd: '302',
                clsfc2Cd: '1',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                recDt:this.$parent.$parent.$parent.$parent.inqYMD,
            }            

            if(this.preData2){
                let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
                let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
                date = dateArr[0]?.afterDateFormatHyp()    
                obj.recDt = date
            }

            selHlthVtlRecList(obj)
                .then((response) => ( this.getHlthVtlRecListAfter(response.data)))
                .catch((error) => console.log(error))
        },
        getHlthVtlRecListAfter:function(res){
            
            this.hlthVtlRecList.splice(0)
            let recpk = 0

            res.forEach(e => {
                if(e.obsrvTmHh?.isEmptyVal() && e.obsrvTmMm?.isEmptyVal()){
                    e.obsrvTm = e.obsrvTmHh +':'+e.obsrvTmMm
                }
                
                if(this.preData2){
                    e.hlthVtlRecPk = 0
                    e.spclbdUsePk = 0
                }else{
                    recpk++
                }

                if(e.obsrvTm == null || e.obsrvTm == undefined)  e.obsrvTm = ''

                this.hlthVtlRecList.push(e)
            });


            if(this.hlthVtlRecList.length == 0){
                this.addList2()
                this.addList2()
            } else if(this.hlthVtlRecList.length == 1) {
                this.addList2()
            }
            
            if(recpk > 0) this.preDisabled2 = true

            this.preData2 = false

            this.$refs.tabform2.resetValidation();    
            this.row = this.hlthVtlRecList.length
        },
        getHlthNrsRecHisList : function(strt, lmt){
            let ym = this.$parent.$parent.$parent.$parent.inqYMD.substr(0,6)            
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum,
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYYMM:ym,
                rnStrt:strt,
                rnLmt:lmt, 
            }            
            selHlthNrsRecHisList(obj)
                .then((response) => ( this.getHlthNrsRecHisListAfter(response.data)))
                .catch((error) => console.log(error))                
        },
        getHlthNrsRecHisListAfter:function(res){
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.hlthHisList = res.list
            this.hlthHisList.forEach(e => {
                e.bldPrs = e.bldPrsH+'/'+e.bldPrsL
            });
        },
        getHlthNrsRecList(){
            let fcltyNum    = this.$store.getters.getFcltyNum
            let bnMmbrPk    = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let ymd         = this.$parent.$parent.$parent.$parent.inqYMD

            if(this.preData1){
                let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
                let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
                date = dateArr[0]?.afterDateFormatHyp()    
                ymd = date
            }

            selHlthNrsRecList(fcltyNum, ymd, bnMmbrPk)
                .then((response) => ( this.getHlthNrsRecListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selHlthNrsRecList : ' + error))
        },
        getHlthNrsRecListAfter:function(res){
            
            this.hlthNrsRecList.splice(0)

            if(res.outList != null && res.outList != undefined) {
                res.outList.forEach(el => {
                    if(this.preData1) {
                        el.bnftOfrRecHisPk = 0
                        el.list.forEach(itm => {
                            itm.bnftOfrRecHisPk = 0
                            itm.bnftOfrRecPk = 0
                        });
                    }
                    this.hlthNrsRecList.push(el)
                });
            }

            if(res.recList != null && res.recList != undefined) {
                res.recList.forEach(el => {                    
                    this.hlthNrsRecInfo.bnftOfrRecPk    = (this.preData1)?0:el.bnftOfrRec302Pk
                    this.hlthNrsRecInfo.wrtr            = el.wrtr
                    this.hlthNrsRecInfo.wrtrMmbrPk      = el.wrtrMmbrPk
                    this.hlthNrsRecInfo.cntnt           = el.cntnt
                    this.hlthNrsRecInfo.spclNote        = el.spclNote
                    this.hlthNrsRecInfo.mdfDt           = el.mdfDt
                });
            }

            if(this.hlthNrsRecInfo.bnftOfrRecPk > 0) this.preDisabled1 = true

            this.preData1 = false
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getHlthNrsRecHisList(strt,this.itemsRows)
        },        
        tableClickEvent(value){
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
        //목록 추가
        addList:function(key){
            let obj = {
                    bnftOfrRecPk:0,
                    recDt:'',
                    clsfc1Cd:'302',
                    clsfc2Cd:'1',
                    clsfc3Cd:'',
                    wrtr:'',
                    wrtrMmbrPk:0,
                    ofrTmStrt: "",
                    ofrTmHhStrt:"",
                    ofrTmMmStrt:"",
                    itm1Cd:"1",
                    itm1Val: "",
                    itm1ValAr:[],
                    itm2Cd: "2",
                    itm2Val: "",
                    itm3Cd: "3",
                    itm3Val: ""
                }

            this.groups[key].list.push(obj)
        },
        addList2:function(){
            this.hlthVtlRecList.push({
                hlthVtlRecPk:0,
                recDt:'',
                clsfc1Cd:'302',
                clsfc2Cd:'1', 
                obsrvTm:'',
                obsrvTmHh:'',
                obsrvTmMm:'',
                bldPrsH: '',
                bldPrsL: '',
                pulse: '',
                tmprt: '',
                rsprt: '',
                bldSgr: '',
                wght: '',
            })
            this.row ++
        },        
        delAll:function(){

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = ' 건강 급여 제공기록'
            obj.cntnt = ['건강 및 간호 기록을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   


            this.rmAllHlthNsgCnfm = true 
        },
        //기록 DB 삭제
        onDelete:function(){
            
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum ,
                bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                userNm      : this.$store.getters.getUserNm,
                recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                hisList     : []
            }
            
            this.hlthNrsRecList.forEach(el => {
                let item = {                                
                    fcltyNum    : this.$store.getters.getFcltyNum ,
                    bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    userNm      : this.$store.getters.getUserNm,
                    recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                    clsfc1Cd    : el.clsfc1Cd,
                    clsfc2Cd    : el.clsfc2Cd,
                    clsfc3Cd    : el.clsfc3Cd,
                }

                obj.hisList.push(item)
            })

            let recInfo = {                         
                fcltyNum        : this.$store.getters.getFcltyNum ,
                bnMmbrPk        : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                userNm          : this.$store.getters.getUserNm,  
                recDt           : this.$parent.$parent.$parent.$parent.inqYMD,
                clsfc1Cd        : this.hlthNrsRecInfo.clsfc1Cd,
                clsfc2Cd        : this.hlthNrsRecInfo.clsfc2Cd,
                clsfc3Cd        : this.hlthNrsRecInfo.clsfc3Cd,
            }

            obj.hisList.push(recInfo)
            
            delHlthNsgRcrd(obj)
                .then((response) => ( this.onDeleteAfter(response.data)))
                .catch((error) => console.log('upload error /bnftrcrd/delHlthNsgRcrd : ' + error))
        },

        //급여제공기록 삭제
        delRecList:function(key, idx){
            if(this.hlthNrsRecList[key].list[idx].bnftOfrRecPk > 0){
                this.key = key
                this.idx = idx
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '급여제공기록'
                obj.cntnt = [this.hlthNrsRecList[key].list[idx].clsfc3Cdnm+'의 급여제공기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmHlthRecCnfm = true
            }else{
                this.hlthNrsRecList[key].list[idx].itm1Val = ''
            }

        },

        rmBnftOfrRec:function(){

            if(this.hlthNrsRecList[this.key].list[this.idx].bnftOfrRecPk > 0 ){
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum    ,
                    bnMmbrPk        : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    userNm          : this.$store.getters.getUserNm,
                    clsfc1Cd        : '302',       
                    bnftOfrRec302Pk : this.hlthNrsRecList[this.key].list[this.idx].bnftOfrRecPk,
                }
                delBnftOfrRec(obj)
                    .then((response) => ( this.rmHlthVtlRecAfter(response.data)))
                    .catch((error) => console.log('upload error /bnftrcrd/delBnftOfrRec : ' + error))
            }
        },

        // 바이탈 기록 삭제
        delHlthVtl:function(idx){
            if(this.hlthVtlRecList[idx].hlthVtlRecPk > 0){
                this.idx = idx
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '바이탈기록'
                obj.cntnt = [this.hlthVtlRecList[idx].obsrvTm+'의 바이탈기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmHlthVtlCnfm = true
            }else{
                if(idx != 0){
                    this.hlthVtlRecList.splice(idx,1)
                }
            }            
        },

        rmHlthVtlRec:function(){
            let hlthVtlRecPk = this.hlthVtlRecList[this.idx].hlthVtlRecPk
            if(hlthVtlRecPk > 0){
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum    ,
                    bnMmbrPk : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    userNm : this.$store.getters.getUserNm,
                    hlthVtlRecPk : hlthVtlRecPk
                }
                delHlthVtlRec(obj)
                    .then((response) => ( this.rmHlthVtlRecAfter(response.data)))
                    .catch((error) => console.log('upload error /bnftrcrd/delHlthVtlRec : ' + error))
            }
        },

        rmHlthVtlRecAfter:function(res){
            this.$emit("hlthBlnftTrigger",res)
            this.reLoad()
            this.idx = 0
        },

        onDeleteAfter:function(res){
            
            if(this.type=="subRec2"){
                if(this.idx !=0) this.hlthVtlRecList.splice(this.idx,1)
            }

            this.$emit("hlthBlnftTrigger",res)
            this.reLoad()
            this.key = 0
            this.idx = 0
            this.type = ''
            this.delTitle = ''
        },
        //작업자선택
        onModal: function (key) {  
            this.menu.splice(key, 1, true)
        },
        isModal: function (key) {
            if(key == 2) this.hlthNrsRecCntntPk = -1
            this.menu.splice(key, 1, false)
        },   
        getEmpInfo: function (obj) {
            this.hlthNrsRecInfo.wrtr = obj[0].name
            this.hlthNrsRecInfo.wrtrMmbrPk = obj[0].mmbrPk
        },
        getCntnt:function(obj){
            this.hlthNrsRecInfo.cntnt = obj.cntnt
            this.hlthNrsRecInfo.spclNote = obj.spclNote
        },
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        nxtAction:function(index, key){
            if(key != 1){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target][0] != undefined)  this.$refs[target][0].focus()
            }else{
                let target =  'cmmtime_'+(index+1)+'_'+key                         
                if(this.$refs[target] != undefined && this.$refs[target][0].$refs.cmmTimeField != undefined)  
                    this.$refs[target][0].$refs.cmmTimeField .focus()
            }
        },
        nxtTxtAction:function(key, idx){
            let target =  'nrsRec_itm1Val_'+key

            if(this.$refs[target][idx+1] != undefined){
                this.$refs[target][idx+1].focus()
            }else{
                target =  'nrsRec_itm1Val_'+(key+1)
                if(this.$refs[target] !=undefined) this.$refs[target][0].focus()
            }
        },
        getPreData:function(){
            if(this.subTab == 0) {
                this.preData1 = true
                this.getHlthNrsRecList()
            } else if(this.subTab == 1) {
                this.preData2 = true
                this.getHlthVtlRecList()
            }
        },
    },
    data: () => ({       
        rmHlthVtlCnfm:false,      
        rmHlthRecCnfm:false,   
        rmAllHlthNsgCnfm:false,
        recPsbYn:'2', //작성여부
        hlthNrsRecCntntPk:-1,
        inqYMD:'',
        pageCount:0,
        itemsRows: 4,
        forceRender:0,
        subTab:0,
        page:1,
        key:0,
        idx:0,
        type:'',
        delTitle:'급여제공기록',
        rtimes:[false,false,false,false,false,false,false,],
        mtimes:[false,false,false,false,false,false,false,],
        items:{
            bnftOfrRecHisPk:0,
            clsfc1Cd:'',
            clsfc2Cd:'',
            clsfc3Cd:'',
            cntnt:'',
            spclNote:''
        },
        disabledChk:true,        
        hlthVtlRecList:[{hlthVtlRecPk:0,recDt:'',clsfc1Cd:'302',clsfc2Cd:'1',ofrTmStrt: "",obsrvTm:"",obsrvTmHh:"",obsrvTmMm:"",bldPrsH: "",bldPrsL: "",
                    pulse: "",tmprt: "",rsprt: "",bldSgr: "",wght: "",}],
        hlthHisList:[{recDt:'',bldPrsH:'',bldPrsL:'',pulse:'',tmprt:'',rsprt:'',bldSgr:'',wght:'',hltTmSum:'',nlsTmSum:'',cntnt:'',wrtr:'',}],
        hlthNrsRecList:[{bnftOfrRecHisPk:0,clsfc2Cdnm:'',list:[{bnftOfrRecPk:0,}]}],
        hlthNrsRecInfo:{bnftOfrRecHisPk:0,clsfc1Cd:'302',clsfc2Cd:'1',clsfc3Cd:'1',cntnt:'',spclNote:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0,},
        dummyHlthNrsRecInfo:{bnftOfrRecHisPk:0,clsfc1Cd:'302',clsfc2Cd:'1',clsfc3Cd:'1',cntnt:'',spclNote:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0,},
        preDisabled1:false,
        preData1:false,
        preDisabled2:false,
        preData2:false,                
        preymd:'',
        menu: [false,false,false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
            numberCustmCheck: value => (value > -1 && value < 999) || 'size to bigger',
        }, 
        headers: [
            { text: '기록일자',width: '', value: '', align: 'center', },
            { text: '혈압',width: '', value: '', align: 'center', },
            { text: '맥박',width: '', value: '', align: 'center', },
            { text: '체온',width: '', value: '', align: 'center', },
            { text: '호흡',width: '', value: '', align: 'center', },
            { text: '혈당',width: '', value: '', align: 'center', },
            { text: '체중',width: '', value: '', align: 'center', },
            { text: '건강(분)',width: '', value: '', align: 'center', },
            { text: '간호(분)',width: '', value: '', align: 'center', },
            { text: '간호일지',width: '', value: '', align: 'center', },
        ],
        row: 0,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '52',
            inqYmdStart: '', inqYmdEnd: '', inqYMD: '', inqYYYY: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
}
</script>