<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">자원이용</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                        <div class="txtAlgnMid" style="width: 100%;">
                            <span class="font-weight-medium">진료병원</span>
                        </div>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="d-inline-block tableBR ml-2 fontOneRem" style="width: 100px;">병원명 (진료과)</span>
                            <v-col class="pa-2" cols="8">
                                <v-text-field
                                    v-model="inpTxt.val_7_1"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="d-inline-block tableBR ml-2 fontOneRem" style="width: 100px;">정기진료</span>
                            <v-radio-group
                                v-model="inpRadio.val_7_2"
                                class="radio-dft-Theme my-1 ml-2" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="무" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-3" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="유" value="1">
                                </v-radio>
                            </v-radio-group>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="d-inline-block tableBR ml-2 fontOneRem" style="width: 100px;">전화번호</span>
                            <v-col class="pa-2" cols="8">
                                <v-text-field
                                    v-model="inpTxt.val_7_4"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                        <div class="txtAlgnMid" style="width: 100%;">
                            <span class="font-weight-medium">약 복용</span>
                        </div>
                    </v-col>
                    <v-col class="px-2 pt-1 pb-2" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-radio-group
                                v-model="inpRadio.val_8_1"
                                class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="있음" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="없음" value="2">
                                </v-radio>
                            </v-radio-group>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="fontOneRem mr-2">횟수:</span>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field
                                    v-model="inpTxt.val_8_2"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                            <span class="fontOneRem mr-2">/일,</span>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field
                                    v-model="inpTxt.val_8_3"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                            <span class="fontOneRem mr-4">/주,</span>
                            <span class="fontOneRem mr-2">1회 약 복용 개수:</span>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field
                                    v-model="inpTxt.val_8_4"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                            <span class="fontOneRem mr-4">개</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 11px;" cols="2">
                        <span class="font-weight-medium">종교활동</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-radio-group
                                v-model="inpRadio.val_9_1"
                                class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="천주교" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="기독교" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="불교" value="3">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="기타" value="4">
                                </v-radio>
                            </v-radio-group>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 142px;">
                                <v-text-field
                                    v-model="inpTxt.val_9_1"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" style="padding-top: 11px;" cols="2">
                        <div class="txtAlgnMid" style="width: 100%;">
                            <span class="font-weight-medium">지역사회 자원</span>
                        </div>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_1" :ripple="false"
                                true-value="1" false-value="2" label="노인맞춤돌봄서비스"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_2" :ripple="false"
                                true-value="1" false-value="2" label="노인돌봄기본서비스"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_3" :ripple="false"
                                true-value="1" false-value="2" label="노인돌봄종합서비스"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-2 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_4" :ripple="false"
                                true-value="1" false-value="2" label="단기가사서비스"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_5" :ripple="false"
                                true-value="1" false-value="2" label="독거노인사회관계활성화"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_6" :ripple="false"
                                true-value="1" false-value="2" label="초기독거노인자립지원"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-divider class="" style="margin-bottom: 6px;"></v-divider>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_7" :ripple="false"
                                true-value="1" false-value="2" label="가사간병"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_8" :ripple="false"
                                true-value="1" false-value="2" label="재가복지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_9" :ripple="false"
                                true-value="1" false-value="2" label="급식 및 도시락배달"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_10" :ripple="false"
                                true-value="1" false-value="2" label="보건소 사업"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_11" :ripple="false"
                                true-value="1" false-value="2" label="개인 간병인"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_12" :ripple="false"
                                true-value="1" false-value="2" label="산업재해 간병인"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_13" :ripple="false"
                                true-value="1" false-value="2" label="치매 안심센터"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_14" :ripple="false"
                                true-value="1" false-value="2" label="복지관 (마을회관)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_15" :ripple="false"
                                true-value="1" false-value="2" label="노인정"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_16" :ripple="false"
                                true-value="1" false-value="2" label="이동서비스"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_17" :ripple="false"
                                true-value="1" false-value="2" label="종교단체"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_18" :ripple="false"
                                true-value="1" false-value="2" label="이·미용"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_10_19" :ripple="false"
                                true-value="1" false-value="2" label="주거 개선사업"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-1" color="black"></v-divider>
                <v-row style="padding-top: 6px; padding-bottom: 5px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="31" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'UseOfRsr',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                if(itm.depth3 == '10')
                    this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                else if(itm.depth3 == '7' || itm.depth3 == '8' || itm.depth3 == '9')
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '7' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '7' && itm.valCd == '4')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '8' && itm.valCd == '2')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '8' && itm.valCd == '3')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '8' && itm.valCd == '4')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '9' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oTxt = {}
                oTxt.examPk = 0
                oTxt.depth2 = '11'
                oTxt.depth3 = '99'
                oTxt.valCd = '1'
                oTxt.val = ''
                oTxt.val2 = this.inpTxt.val_99_1
                finArr.push(oTxt)

                let oTxt_7_1 = {}
                oTxt_7_1.examPk = 0
                oTxt_7_1.depth2 = '11'
                oTxt_7_1.depth3 = '7'
                oTxt_7_1.valCd = '1'
                oTxt_7_1.val = ''
                oTxt_7_1.val2 = this.inpTxt.val_7_1
                finArr.push(oTxt_7_1)

                let oTxt_7_4 = {}
                oTxt_7_4.examPk = 0
                oTxt_7_4.depth2 = '11'
                oTxt_7_4.depth3 = '7'
                oTxt_7_4.valCd = '4'
                oTxt_7_4.val = ''
                oTxt_7_4.val2 = this.inpTxt.val_7_4
                finArr.push(oTxt_7_4)

                let oTxt_8_2 = {}
                oTxt_8_2.examPk = 0
                oTxt_8_2.depth2 = '11'
                oTxt_8_2.depth3 = '8'
                oTxt_8_2.valCd = '2'
                oTxt_8_2.val = ''
                oTxt_8_2.val2 = this.inpTxt.val_8_2
                finArr.push(oTxt_8_2)

                let oTxt_8_3 = {}
                oTxt_8_3.examPk = 0
                oTxt_8_3.depth2 = '11'
                oTxt_8_3.depth3 = '8'
                oTxt_8_3.valCd = '3'
                oTxt_8_3.val = ''
                oTxt_8_3.val2 = this.inpTxt.val_8_3
                finArr.push(oTxt_8_3)

                let oTxt_8_4 = {}
                oTxt_8_4.examPk = 0
                oTxt_8_4.depth2 = '11'
                oTxt_8_4.depth3 = '8'
                oTxt_8_4.valCd = '4'
                oTxt_8_4.val = ''
                oTxt_8_4.val2 = this.inpTxt.val_8_4
                finArr.push(oTxt_8_4)

                Object.keys(this.inpRadio).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '11'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpRadio[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '9' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });

                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '11'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '1' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    if(oObj.depth3 == '10')
                        oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]
                   
                    if(oObj.depth3 == '7' && oObj.valCd == '2')
                        oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '8' && oObj.valCd == '1')
                        oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '9' && oObj.valCd == '1')
                        oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '7' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '7' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '8' && oObj.valCd == '2')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '8' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '8' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '9' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = '0'
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 병원-병원명(진료과), 병원-전화번호, 약복용-횟수/일, 약복용-횟수/주, 약복용-약복용갯수, 종교-기타 판단근거
        inpTxt: {
            val_7_1: '', val_7_4: '', val_8_2: '', val_8_3: '', val_8_4: '', val_9_1: '',
            val_99_1: ''
        },
        // 병원 (d3: 7) - cd2: 정기진료
        // 약복용 (d3: 8) - cd1: 유무
        // 종교 (d3: 9) - cd1: 종교활동
        inpRadio: { val_7_2: '0', val_8_1: '0', val_9_1: '0', },
        // 지역사회 (d3: 10) - cd1: 노인맞춤돌봄서비스, cd2: 노인돌봄기본서비스, cd3: 노인돌봄종합서비스, cd4: 단기가사서비스,
        //                   cd5: 독거노인사회관계활성화, cd6: 초기독거노인자립지원, cd7: 가사간병, cd8: 재가복지,
        //                   cd9: 급식 및 도시락배달, cd10: 보건소사업, cd11: 개인간병인, cd12: 산업재해 간병인,
        //                   cd13: 치매안심센터, cd14: 복지관(마을회관), cd15: 노인정, cd16: 이동서비스, cd17: 종교단체,
        //                   cd18: 이미용, cd19: 주거개선사업
        inpCheck: {
            val_10_1: '2', val_10_2: '2', val_10_3: '2', val_10_4: '2', val_10_5: '2',
            val_10_6: '2', val_10_7: '2', val_10_8: '2', val_10_9: '2', val_10_10: '2',
            val_10_11: '2', val_10_12: '2', val_10_13: '2', val_10_14: '2', val_10_15: '2',
            val_10_16: '2', val_10_17: '2', val_10_18: '2', val_10_19: '2',
        },
    }),
};
</script>