<template>
    <v-container fluid>
        <v-row class="pa-0" no-gutters>
            <expandQtrPicker @nowQtr="getQtr"></expandQtrPicker>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"
                @click="onImprvMdl(0)"
                samll rounded>
                <v-icon small>mdi-pencil-outline</v-icon>
                <span>의견수렴 작성</span>
            </v-btn>
        </v-row>
        <v-divider :class="['mt-1', prgOpRecList.length > 10 ? 'mr-2__5' : '']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', prgOpRecList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 11px;" cols="2">
                <span class="font-weight-medium">프로그램 유형</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 11px;" cols="2">
                <span class="font-weight-medium">프로그램 구분</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 11px;" cols="2">
                <span class="font-weight-medium">제공일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 11px;" cols="3">
                <span class="font-weight-medium">수급자 그룹</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" style="line-height: 1.2;" cols="1">
                <span class="font-weight-medium">참여<br>인원수</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 11px;" cols="1">
                <span class="font-weight-medium">진행자</span>
            </v-col>
            <v-col class="txtAlgnMid" style="padding-top: 11px;" cols="1">
                <span class="font-weight-medium">상세</span>
            </v-col>
        </v-row>
        <v-divider :class="[prgOpRecList.length > 10 ? 'mr-2__5' : '']" color=""></v-divider>
        <div v-if="prgOpRecList.length > 0" class="overflow-y-auto" style="width: 100%; height: 321px;">
            <v-row
                :class="['tableBB', prgOpRecList.length > 10 ? '' : '']"
                v-for="(itm, i) in prgOpRecList" :key="i"
                no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.idxNm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.prgTypNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.opDtDot }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="3">
                    <span class="fontOneRem">{{ itm.grpNm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="1">
                    <span class="fontOneRem">{{ itm.prtcpNum }}명</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="1">
                    <span class="fontOneRem">{{ itm.mc }}</span>
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="blueBtnColor" min-width="20" height="20"
                        @click="onPrgrmRecMdl(itm)"
                        small icon>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                            </template>
                            <span>프로그램 운영 기록</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white mt-1" :style="{ width: $parent.$parent.viewWdth+'px' }"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 의견수렴 내역</span>
        </v-row>
        <v-divider :class="[prgImprvList.length > 5 ? 'mr-2__5' : '']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', prgImprvList.length > 5 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">프로그램 유형</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">작성일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">작성자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="5">
                <span class="font-weight-medium">의견수렴 반영일자</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="1">
                <span class="font-weight-medium">상세</span>
            </v-col>
        </v-row>
        <v-divider :class="[prgImprvList.length > 5 ? 'mr-2__5' : '']" color=""></v-divider>
        <div v-if="prgImprvList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', prgImprvList.length > 10 ? '' : '']"
                v-for="(itm, i) in prgImprvList" :key="i"
                no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.idxNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.wrtDtDot }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.wrtr }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="5">
                    <span v-if="itm.rflDtDot == ''" class="fontOneRem red--text">미반영</span>
                    <span v-else class="fontOneRem">{{ itm.rflDtDot }}</span>
                    <!-- <span class="fontOneRem" v-if="itm.rflDt != ''">반영</span>
                    <span class="fontOneRem" v-else>미반영</span> -->
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="blueBtnColor" min-width="20" height="20"
                        @click="onImprvMdl(itm.prgImprvPk)"
                        small icon>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                            </template>
                            <span>의견수렴 상세</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>

        <!-- 팝업 영억 -->
        <v-dialog v-model="imprvMdl" max-width="1200" min-width="1200" persistent eager>
            <insPrgImprv ref="insPrgImprv" @isClose="offImprvMdl"></insPrgImprv>
        </v-dialog>
    </v-container>
</template>

<script>
import { http } from '@/api/baseAxios';

import expandQtrPicker from '../../components/commons/expandQtrPicker.vue';
import insPrgImprv from '../../components/prgrm/insPrgImprv.vue';

export default {
    name: 'PrgImprv',
    
    components: {
        expandQtrPicker,
        insPrgImprv,
    },

    props : { 
        prtYyyy: { type: Number, default: 2000 },
        prtCd40: { type: Array, default: () => { return [] } },
    },

    created: function(){
       
    },

    computed: {
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.prtYyyy != 2000 && this.sltQtr != 0){
                this.getPrgOpRecList(this.prtYyyy, this.sltQtr)
                this.getPrgImprvList(this.prtYyyy, this.sltQtr)
            }
        })
    },

    watch: {
        prtCd40: {
            deep: true,
            handler: function (value){
                if(value.length > 0){
                    this.prgOpRecList.forEach(itm => {
                        let chc = this.prtCd40.findIndex( v => v.valcd == itm.idxCd )
                        if(chc > -1)
                            itm.idxNm = this.prtCd40[chc].valcdnm
                    });
                }
                    
            },
        },
       'prtYyyy': function () {
            if(this.prtYyyy != 2000 && this.sltQtr != 0){
                this.getPrgOpRecList(this.prtYyyy, this.sltQtr)
                this.getPrgImprvList(this.prtYyyy, this.sltQtr)
            }
        },
    },
    
    methods: {
        // 프로그램 운영 기록 다건 조회
        getPrgOpRecList: function (yyyy, qtr) {
            this.prgOpRecList.splice(0)
            
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.inqYYYY = yyyy
            obj.inqQtr = qtr

            http.post('prgrm/selPrgOpRecList', obj)
                .then((response) => ( this.prgOpRecRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgOpRecList : ' + error))
        },

        prgOpRecRst: function (res) {
            if(res.list.length > 0){
                res.list.forEach(itm => {
                    let chc = this.prtCd40.findIndex( v => v.valcd == itm.idxCd )
                    if(chc > -1)
                        itm.idxNm = this.prtCd40[chc].valcdnm

                    itm.grpNm  = ''
                    itm.bnfcrgrNm?.forEach((nm, i) => {
                        itm.grpNm += nm
                        if(itm.bnfcrgrNm.length != (i+1))
                            itm.grpNm += ', '
                    });

                    if(itm.opDt != null && itm.opDt != '')
                        itm.opDtDot = this.$moment(itm.opDt, 'YYYYMMDD').format('YYYY.MM.DD')
                });

                this.prgOpRecList = res.list
            }
        },

        // 프로그램 의견수렴 내역 다건 조회
        getPrgImprvList: function (yyyy, qtr) {
            this.prgImprvList.splice(0)
            
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.inqYYYY = yyyy
            obj.inqQtr = qtr
   
            http.post('prgrm/selPrgImprvList', obj)
                .then((response) => ( this.prgImprvRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvList : ' + error))
        },

        prgImprvRst: function (res) {
            if(Array.isArray(res)){
                res.forEach(itm => {
                    let chc = this.prtCd40.findIndex( v => v.valcd == itm.idxCd )
                    if(chc > -1)
                        itm.idxNm = this.prtCd40[chc].valcdnm

                    if(itm.rflDt == null)
                        itm.rflDtDot = ''
                    else
                        itm.rflDtDot = itm.rflDt

                    if(itm.rflDtDot != '')
                        itm.rflDtDot = this.$moment(itm.rflDtDot, 'YYYYMMDD').format('YYYY.MM.DD')

                    if(itm.wrtDt != null && itm.wrtDt != '')
                        itm.wrtDtDot = this.$moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                });

                this.prgImprvList = res
            }
        },

        // 분기 선택 이벤트
        getQtr: function (value) {
            this.sltQtr = value

            if(this.prtYyyy != 2000){
                this.getPrgOpRecList(this.prtYyyy, value)
                this.getPrgImprvList(this.prtYyyy, value)
            }
        },

        onPrgrmRecMdl: function (obj) {
            let oObj = {}
            oObj.prgOpPlnPk = obj.prgOpPlnPk
            oObj.prgOpRecPk = obj.prgOpRecPk
            oObj.prgInfPk = obj.prgInfPk
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: oObj, outObj: null, runEvt: false,
            });
        },

        onImprvMdl: function (pk) {
            if(pk != 0)
                this.$refs.insPrgImprv.selPrgImprvInfo(pk)

            this.$refs.insPrgImprv.onCodeload()

            this.imprvMdl = true
        },

        offImprvMdl: function () {
            this.imprvMdl = false
      
            this.getPrgImprvList(this.prtYyyy, this.sltQtr)
        },
    },

    data: () => ({
        imprvMdl: false,
        sltQtr: 0,
        prgOpRecList: [],
        prgImprvList: [],
    }),
};
</script>