<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">         
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">{{ prgInfo.prgNm }}</span>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-row class="px-3" no-gutters>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"                
                samll rounded
                @click="dialog.splice(0, 1, true)">
                <v-icon small>mdi-pencil-outline</v-icon>
                <span>프로그램 일정등록</span>
            </v-btn>
        </v-row>
        <div class="pa-2 overflow-y-auto" style="height: 550px;">
            <v-divider class="mt-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
                </v-col>
                <v-col class="tableBR px-2" style="padding-top: 6px;" cols="4">
                    <span class="fontOneRem">{{ prgInfo.prgTypNm }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">프로그램 구분</span>
                </v-col>
                <v-col class="px-2" style="padding-top: 6px;" cols="4">
                    <span class="fontOneRem">{{ prgInfo.idxNm }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">준비물</span>
                </v-col>
                <v-col class="px-2 overflow-y-auto" style="padding-top: 8px;" cols="10">
                    <div class="fontOneRem" v-html="prgInfo.mtrl"></div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">목표</span>
                </v-col>
                <v-col class="pa-2 overflow-y-auto" cols="10">
                    <div class="fontOneRem" v-html="prgInfo.goal"></div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                    <div class="txtAlgnMid" style="width: 100%; line-height: 1.3;">
                        <span class="fontOneDotOneRem font-weight-bold">프로그램 내용<br>(진행과정)</span>
                    </div>
                </v-col>
                <v-col class="pa-2 overflow-y-auto" cols="10">
                    <div class="fontOneRem" v-html="prgInfo.prgCntnt"></div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">기대효과</span>
                </v-col>
                <v-col class="pa-2 overflow-y-auto" cols="10">
                    <div class="fontOneRem" v-html="prgInfo.expEfc"></div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR py-2" cols="2">
                    <div class="txtAlgnMid" style="width: 100%; line-height: 1.3;">
                        <span class="fontOneDotOneRem font-weight-bold">기능회복훈련<br>(급여제공기록지포함)</span>
                    </div>
                </v-col>
                <v-col class="pa-2" cols="10">
                    <span class="fontOneRem" v-for="(shtTyp, idx) in prgInfo.shtTyps" :key="shtTyp">
                        <b v-if="idx !=0">,</b>
                        {{shtTyp}}
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1300">
                <PrgrmNew v-if="dialog[0]" :isUpd="false" :prtObj="prgInfo" @isClose="dialog.splice(0, 1, false)"></PrgrmNew>
                <!-- <PrgPlnMdl v-if="dialog[0]" :sltData="prgInfo" @isClose="dialog.splice(0, 1, false)"></PrgPlnMdl>   -->
            </v-dialog>   
        </div>
    </v-sheet>
</template>

<script>
// import PrgPlnMdl from '../../components/prgrm/PrgPlnMdl.vue';
import PrgrmNew from '../../components/prgrm/PrgrmNew.vue';

import { http } from '@/api/baseAxios';

export default {
    name: 'PrgPlnInfo',
    
    components: {  
        PrgrmNew,
    },

    props : {
        sltItm:{type:Object, default:new Object}
    },

    computed: {
        
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.getPrgInfShtInfo()
        })
    },

    watch: {

    },

    methods: {  
        
        onClose: function () {
            this.$emit('isClose')
        },

        // 프로그램정보 활동지 조회
        getPrgInfShtInfo: function (){                
            
            http.post('prgrm/selPrgInfShtInfo', this.sltItm)
                .then((response) => ( this.prgInfShtRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },

        //프로그램정보 활동지 결과 반영
        prgInfShtRst:function(res){
            if(res.statusCode == '200'){                
                this.setData(res.result)
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },


        //프로그램 정보 셋팅
        setData:function(obj){
            
            if(obj.prgInf != null){
                this.prgInfo.prgInfPk       = this.sltItm.prgInfPk
                this.prgInfo.prgTypMngPk 	= obj.prgInf.prgTypMngPk       //프로그램유형관리 고유번호
                this.prgInfo.prgNm 	        = obj.prgInf.prgNm    		    //프로그램명
                this.prgInfo.plc 	        = ''    		                //장소
                this.prgInfo.mc 	        = ''    			            //진행자
                this.prgInfo.mcMmbrPk 	    = ''    	                    //진행자 구성원 고유번호
                this.prgInfo.mtrl 	        = obj.prgInf.mtrl?.replaceAll("\n", "<br />")    		    //준비물
                this.prgInfo.goal 	        = obj.prgInf.goal?.replaceAll("\n", "<br />")   		    //목표
                this.prgInfo.prgCntnt 	    = obj.prgInf.prgCntnt?.replaceAll("\n", "<br />")   	    //프로그램내용
                this.prgInfo.expEfc 	    = obj.prgInf.expEfc?.replaceAll("\n", "<br />")    		    //기대효과
                this.prgInfo.fncRcvCd 	    = obj.prgInf.fncRcvCd    	    //기능회복훈련코드
                this.prgInfo.fncRcvCds 	    = []    	                    //기능회복훈련코드
                this.prgInfo.shtTyp 	    = obj.prgInf.shtTyp             //활동지 유형구분(공통값분류코드 133참조)
                this.prgInfo.shtTyps	    = []		                    //활동지 유형구분(공통값분류코드 133참조)
                this.prgInfo.shtMda 	    = obj.prgInf.shtMda             //활동지 미디어구분(공통값분류코드 134참조)
                this.prgInfo.shtMdas	    = []		                    //활동지 미디어구분(공통값분류코드 134참조)
                this.prgInfo.shtSbjt 	    = obj.prgInf.shtSbjt		                    //활동지 활동지 주제구분(공통값분류코드 135참조)
                this.prgInfo.shtSbjts 	    = []		                    //활동지 활동지 주제구분(공통값분류코드 135참조)
                this.prgInfo.useYn 	        = obj.prgInf.useYn              //사용여부
                this.prgInfo.idxCd          = obj.prgInf.idxCd
                this.prgInfo.idxNm          = obj.prgInf.idxNm

                
                this.prgInfo.fncRcvCd.forEach(el => {                            
                    this.$parent.$parent.$parent.$parent.tblUse.vCd38.forEach(cd => {
                        if(cd.valcd == el) this.prgInfo.fncRcvCds.push(cd)
                    });
                });

                this.prgInfo.shtTyp.forEach(el => {                            
                    this.$parent.$parent.$parent.$parent.tblUse.vCd133.forEach(cd => {
                        if(cd.valcd == el) this.prgInfo.shtTyps.push(cd.valcdnm)
                    });
                });

                this.prgInfo.shtMda.forEach(el => {                            
                    this.$parent.$parent.$parent.$parent.tblUse.vCd134.forEach(cd => {
                        if(cd.valcd == el) this.prgInfo.shtMdas.push(cd)
                    });
                });

                this.prgInfo.shtSbjt.forEach(el => {                            
                    this.$parent.$parent.$parent.$parent.tblUse.vCd135.forEach(cd => {
                        if(cd.valcd == el) this.prgInfo.shtSbjts.push(cd)
                    });
                });
            }

            if(this.prgInfo.prgTypMngPk != null) {
                let idx = this.$parent.$parent.$parent.$parent.tblUse.prgTyplist.findIndex(v=> v.prgTypMngPk == this.prgInfo.prgTypMngPk)
                this.prgInfo.idxCd = this.$parent.$parent.$parent.$parent.tblUse.prgTyplist[idx].idxCd
                this.$parent.$parent.$parent.$parent.tblUse.vCd40.forEach(cd => {
                    if(cd.valcd == this.$parent.$parent.$parent.$parent.tblUse.prgTyplist[idx].idxCd) this.prgInfo.idxNm = cd.valcdnm
                });
            
                this.prgInfo.prgTypNm     = this.$parent.$parent.$parent.$parent.tblUse.prgTyplist[idx].prgTypNm
            }
            else{
                this.prgInfo.idxCd = ''
                this.prgInfo.idxNm = ''
            }
        },

        
    },
    data: () => ({   
        prgInfo:{            
            prgInfPk        : 0,
            prgTypMngPk 	: null,     //프로그램유형관리 고유번호
            prgNm 	        :'',    		//프로그램명
            plc 	        :'',    		//장소
            mc 	            :'',    			//진행자
            mcMmbrPk 	    :'',    	//진행자 구성원 고유번호
            mtrl 	        :'',    		//준비물
            goal 	        :'',    		//목표
            prgCntnt 	    :'',    	//프로그램내용
            expEfc 	        :'',    		//기대효과
            fncRcvCd 	    : [],    	//기능회복훈련코드
            fncRcvCds 	    : [],    	//기능회복훈련코드
            shtTyp 	        : [],		//활동지 유형구분(공통값분류코드 133참조)
            shtTyps	        : [],		//활동지 유형구분(공통값분류코드 133참조)
            shtMda 	        : [],		//활동지 미디어구분(공통값분류코드 134참조)
            shtMdas	        : [],		//활동지 미디어구분(공통값분류코드 134참조)
            shtSbjt 	    : [],		//활동지 활동지 주제구분(공통값분류코드 135참조)
            shtSbjts 	    : [],		//활동지 활동지 주제구분(공통값분류코드 135참조)
            useYn 	        :'',    		//사용여부
            idxCd           :'',
            idxNm           :'',
        },
        dialog:[false],
    }),
};
</script>