<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">수급자별 그룹 설정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 기본정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="tableBR px-4 py-1" cols="4">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span v-if="bscsInf.age != ''" class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">입소일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="4">
                <span class="mr-2">{{ bscsInf.entDt }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">주요질환</span>
            </v-col>
            <v-col class="overflow-text-hidden px-4 py-1" cols="10">
                <span class="mr-2">{{ bscsInf.majorDsss }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">그룹 설정 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium posn-rltv">
                    작성일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center tableBR px-4" cols="4">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInp.wrtDt"
                        :required="true"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium posn-rltv">
                    작성자
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="4">
                <span class="white d-inline-block" style="width: 140px;">
                    <v-text-field
                        class="text-field-clearable" :append-icon="bscsInp.wrtr == '' ? '' : 'mdi-close-circle-outline'"
                        v-model="bscsInp.wrtr" @input="aplctSavBtn" @click:append="clrWrtrField"
                        height="28" readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="brown lighten-3 ml-1" style="padding: 0px 1px 0px 2px !important;"
                    min-width="30" height="28"
                    @click="onLclMdl('01', 700)"
                    small depressed>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-5" cols="2">
                <span class="font-weight-medium">비고</span>
            </v-col>
            <v-col class="px-4 py-1" cols="10">
                <v-textarea
                    class="rmrksArea" rows="3" 
                    v-model="bscsInp.rmrks" @input="aplctSavBtn"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 수급자 그룹 목록</span>
        </v-row>
        <v-divider :class="[bnfcrGrList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01', bnfcrGrList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자 그룹명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">프로그램 유형</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="6">
                <span class="font-weight-medium">설명</span>
            </v-col>
            <v-col class="txtAlgnMid pt-1" cols="1">
                <v-btn
                    class="" color="blue200" height="26"
                    @click="onLclMdl('11', 900) "
                    icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider :class="[bnfcrGrList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="bnfcrGrList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', bnfcrGrList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in bnfcrGrList" :key="i"
                no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="3">
                    <span class="fontOneRem">{{ itm.bnfcrgrNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontOneRem">{{ itm.idxNm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1 px-2" cols="6">
                    <span class="fontOneRem">{{ itm.bnfcrgrDesc }}</span>
                </v-col>
                <v-col style="padding-top: 3px; padding-left: 21px;" cols="1">
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="rmBnfcrGrpRow(i)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-minus-circle-outline</v-icon>
                            </template>
                            <span>그룹 제거</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 프로그램 수급자 그룹이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn" :max-width="lclMdlInf.maxWidth"
            content-class="round"
            persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부, checkr: 체크박스 모델
            -->
            <IntgrSlctn
                v-if="lclMdlInf.typ == '01'"
                :mdlTtl="'직원 선택'" :tabInfList="['2']" :fixList="exclsList" :mode="'1'"
                :cd41="prtCd41"
                :cd42="prtCd42"
                :cd65="prtCd65"
                :prtWrtDt="bscsInp.wrtDt"
                @intgrResList="getAplyList" @onClose="rstLclMdlInf">
            </IntgrSlctn>
            <PrgmBnfcrGrMgmt
                v-else-if="lclMdlInf.typ == '11'"
                ref="pMSelector"
                :isUpd="false" :prgBnfcrgrChkItems="prgBnfcrGrInfPks"
                @isClose="rstLclMdlInf" @outEmp="chngBnfcrGrp">
            </PrgmBnfcrGrMgmt>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import PrgmBnfcrGrMgmt from './PrgrmgrMultiSelector.vue';

export default {
    name: 'BnfcrGrpCnfgrMdl',
    
    components: {
        CmmDateComp,
        IntgrSlctn,
        PrgmBnfcrGrMgmt,
    },

    props : { 
        prtCd40: { type: Array, default: () => { return [] } },
        prtCd41: { type: Array, default: () => { return [] } },
        prtCd42: { type: Array, default: () => { return [] } },
        prtCd65: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {

    },

    watch: {

    },
        
    methods: {
        // 프로그램 수급자 그룹 목록 조회
        getBnfcrGrList: function (mbr){
            this.bnfcrGrList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr

            http.post('prgrm/selBnfcrGrList', obj)
                .then((response) => ( this.bnfcrGrListRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selBnfcrGrList : ' + error))
        },

        bnfcrGrListRst: function (res){
            if(res.statusCode == '200')
                this.bnfcrGrList = res.result.list

            this.bnfcrGrList.forEach(itm => {
                let tmp = itm
                tmp.disChekr = false
                tmp.checkr = '2'
                this.exclsList.push(itm)

                if(itm.prgBnfcrgrInfPk != null)
                    this.prgBnfcrGrInfPks.push(itm.prgBnfcrgrInfPk)
            });

            this.aplctSavBtn()
        },

        // 프로그램 수급자 그룹설정 저장
        setBnfcrGr: function (obj){
            http.post('prgrm/insBnfcrgrBnfcrHis', obj)
                .then((response) => ( this.bnfcrGrRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/insBnfcrgrBnfcrHis : ' + error))
        },

        bnfcrGrRst: function (res){
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200){
                this.rstFld()
                this.$parent.$parent.$parent.$parent.mdlOff()
            }
            else {
                obj.tmr = 5000
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 수급자 선택 이벤트
        getAplyList: function (arr){
            if(arr.length == 1){
                this.bscsInp.wrtr = arr[0].name
                this.bscsInp.wrtrPk = arr[0].empPk
            }

            this.aplctSavBtn()
        },

        // 프로그램 수급자 그룹 목록 단건 제거
        rmBnfcrGrpRow: function (idx){
            let trgPk = this.bnfcrGrList[idx].prgBnfcrgrInfPk
            
            let chc = this.prgBnfcrGrInfPks.findIndex( v => v == trgPk)
            if(chc > -1)
                this.prgBnfcrGrInfPks.splice(chc, 1)

            let chc1 = this.bnfcrGrList.findIndex( v => v.prgBnfcrgrInfPk == trgPk)
            if(chc1 > -1)
                this.bnfcrGrList.splice(chc1, 1)
        },

        // 프로그램 수급자 관리 모달 정보 수신
        chngBnfcrGrp: function (grInf){
            this.prgBnfcrGrInfPks.splice(0)
            this.bnfcrGrList.splice(0)

            grInf.forEach(itm => {
                let obj = {}
                obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                obj.bnfcrgrPrgPk = null
                obj.prgBnfcrgrInfPk = itm.prgBnfcrgrInfPk
                obj.bnfcrgrNm = itm.bnfcrgrNm
                obj.bnfcrgrDesc = itm.bnfcrgrDesc
                obj.idxCd = itm.idxCd
                obj.idxNm = itm.idxNm
                obj.chkyn = itm.chkyn
                obj.useYn = itm.useYn

                this.prgBnfcrGrInfPks.push(itm.prgBnfcrgrInfPk)
                this.bnfcrGrList.push(obj)
            });

        },

       // 기본정보 적용
       aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.entDt = obj.entDt
            this.bscsInf.majorDsss = obj.majorDsss

            this.bscsInp.wrtDt = this.$moment().format('YYYY-MM-DD')

            this.getBnfcrGrList(this.$store.getters.getBnfcrState.bnmmbr)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInp.wrtDt) == false)
                return 0
            if(this.bscsInp.wrtr == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.wrtDt = this.$moment(this.bscsInp.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD')
            obj.wrtr = this.bscsInp.wrtr
            obj.rmrks = this.bscsInp.rmrks
            obj.list = []

            this.bnfcrGrList.forEach(itm => {
                let inObj = {}
                inObj.prgBnfcrgrInfPk = itm.prgBnfcrgrInfPk
                obj.list.push(inObj)
            });
          
            this.setBnfcrGr(obj)
        },

        // 작성자 초기화
        clrWrtrField: function () {
            this.bscsInp.wrtr = ''
            this.bscsInp.wrtrPk = -1

            this.aplctSavBtn()
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInp.wrtDt = ''
            this.bscsInp.wrtrPk = -1
            this.bscsInp.wrtr = ''
            this.bscsInp.rmrks = ''
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        prgBnfcrGrInfPks: [],
        bnfcrGrList: [],
        exclsList: [],
        bscsInf: { bnMmbrPk: -1, name: '', gndr: '', age: '', entDt: '', majorDsss: '' },
        bscsInp: { wrtDt: '', wrtr: '', wrtrPk: -1, rmrks: '' },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 수급자 선택)
    }),
};
</script>