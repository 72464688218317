<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="py-7 pl-7 pr-6 midAreaColor" cols="3">
                <v-sheet class="" color="midAreaColor">
                    <p class="hamletFont text-h5 topTitleColor--text font-weight-black">
                        <span>
                            야간 점검 일지
                        </span>
                    </p>                    
                    <v-divider class="midAreaColor my-1"></v-divider>
                    <!--  :search="schDummy" :custom-filter="customFilter" -->
                    <v-data-table
                        class="dft-hvr-tbl" height="710" locale="ko"
                        :headers="headers" :items="nghList"                       
                        fixed-header hide-default-header disable-pagination hide-default-footer dense>

                        <template v-slot:header="props">
                            <th class="black--text bottomBorder px-1 py-3" style="width: 250px;">{{ props.props.headers[0].text }}</th>
                            <th class="black--text bottomBorder px-1 py-3" style="width: 120px;">{{ props.props.headers[1].text }}</th>
                            <th class="black--text bottomBorder px-1 py-3" style="width: 120px;">{{ props.props.headers[2].text }}</th>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr
                                :class="[tblIdx == index ? 'onClr' : '']" 
                                @click="chcTblIdx(item, index)">
                                <td class="txtAlgnMid tableBB pa-1">
                                    <span class="fontOneRem">{{ item.inspAreaNm }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB pa-1">
                                    <div class="overflow-text-hidden">
                                        <span class="fontOneRem">{{ item.rn }}</span>
                                    </div>
                                </td>
                                <td class="txtAlgnMid tableBB pa-1">
                                    <span v-if="item.inspTmHh == null || item.inspTmMm == null" class="fontOneRem red--text">미작성</span>
                                    <span v-else class="fontOneRem">{{ item.inspTmHh+':'+item.inspTmMm }}</span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-divider class="midAreaColor my-1"></v-divider>
                </v-sheet>
            </v-col>
            <v-col class="py-7 pr-7 pl-6" cols="9">
                <v-sheet class="" color="">
                    <v-row class="pa-0 justify-center align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <span v-for="(list, i) in filledBtn" :key="i">
                            <v-btn :class="list.class" height="30" :color="list.color"
                                @click="onClickBtn(i)"
                                :disabled="list.disabled" samll rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </span>
                        <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                            <v-btn :disabled="list.disabled"
                                :class="list.class" 
                                samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                {{list.text}}
                            </v-btn>
                        </span>  
                        <v-icon color="black" @click="$emit('isClose', '.')" x-large>mdi-close</v-icon>
                    </v-row>
                    <v-row class="pa-0 mt-3" no-gutters>
                        <v-col cols="7">
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                                    <span class="font-weight-medium posn-rltv">
                                        점검구역
                                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                                        </span>
                                    </span>
                                </v-col>
                                <v-col class="px-2 py-1" cols="10">
                                    <div style="width:200px;">
                                        <v-select
                                            v-model="inspAreaPk"
                                            :key="forceRender"
                                            :items="areaList"
                                            :disabled="inspRecPk > 0"
                                            item-text="inspAreaNm" item-value="inspAreaPk"
                                            dense hide-details outlined
                                            @change="chnArea">
                                        </v-select>  
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                                    <span class="font-weight-medium posn-rltv">야간점검일</span>
                                </v-col>
                                <v-col class="tableBR fontNineThRem d-flex justify-left align-center px-2 py-1" cols="4">
                                    <span>{{ inqymd }}</span>
                                    <span v-if="inspDtlDt != ''">
                                        &#40;{{ inspDtlDt }}&#41;
                                    </span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 tableBR d-flex justify-center align-center" cols="2">
                                    <span class="font-weight-medium posn-rltv">점검자</span>
                                </v-col>
                                <v-col class="px-2 py-1 d-flex justify-left align-center" cols="4">
                                    <span class="d-inline-block ml-1" style="width: 100px;">
                                        <v-text-field
                                            v-model="wrtr" :rules="[rules.required]"
                                            height="28" readonly hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <v-btn 
                                        class="ml-1 brown lighten-3 d-inline-block"
                                        style="padding: 0px 1px 0px 2px !important;"
                                        min-width="30" height="26" @click="menu.splice(0,1, true)"
                                        dark small>
                                        <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                            </template>
                                            <span>직원선택</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01  d-flex justify-center align-center tableBR" cols="2">
                                    <span class="font-weight-medium posn-rltv">점검시간</span>
                                </v-col>
                                <v-col class="px-2 py-1" cols="10">
                                    <span class="d-inline-block" style="width: 80px;">
                                        <CmmTimeField v-model="inspTm" :required="true" @input="setTmInspDT"></CmmTimeField>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>                        
                            <v-divider :class="['mt-3', inspRecItmTot > 14 ? 'mr-2__5' : '']"></v-divider>
                            <v-row :class="['nmlBfClrImp-greyE01 pa-0', inspRecItmTot > 14 ? 'mr-2__5' : '']" no-gutters>
                                <v-col class="tableBR d-flex justify-center align-center" style="padding-top: 6px;" cols="3">
                                    <span class="fontOneDotOneRem font-weight-bold">구분</span>
                                </v-col>
                                <v-col class="pa-0 ma-0" cols="9">
                                    <v-row class="" no-gutters>
                                        <v-col class="tableBR d-flex justify-center align-center" style="padding-top: 6px;" cols="8">
                                            <span class="fontOneDotOneRem font-weight-bold">점검항목</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <div class="tableBB d-flex justify-center pa-1">
                                                <span class="fontOneDotOneRem font-weight-bold">점검결과</span>
                                            </div>
                                            <div>
                                                <span class="d-inline-block tableBR ngh" style="width: 33.3333%; text-align: center;">
                                                    <v-icon color="black" small>mdi-checkbox-blank-circle-outline</v-icon>
                                                </span>
                                                <span class="d-inline-block tableBR ngh" style="width: 33.3333%; text-align: center;">
                                                    <v-icon color="black" small>mdi-triangle-outline</v-icon>
                                                </span>
                                                <span class="d-inline-block ngh" style="width: 33.3333%; text-align: center;">
                                                    <v-icon color="black" small>mdi-close</v-icon>
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>                            
                                </v-col>
                            </v-row>         
                            <v-divider :class="[inspRecItmTot > 14 ? 'mr-2__5' : '']"></v-divider>
                            <div v-if="inspRecDtlList.length > 0" class="overflow-y-auto" style="width: 100%; height: 535px;">
                                <v-row
                                    class="tableBB"
                                    v-for="(itm, i) in inspRecDtlList" :key="i"
                                    no-gutters>               
        
                                    <v-col class="tableBR pb-2 pl-2 d-flex justify-center align-center" cols="3">
                                        <span class="fontOneRem">{{ itm.clsfc3Cdnm }}</span>
                                    </v-col>
                                    <v-col class="pa-0 ma-0" cols="9">
                                        <v-row                                    
                                            v-for="(itmDtl, j) in itm.dtlList" :key="j"
                                            no-gutters>   
                                            <v-col :class="['tableBR pt-2 pl-2', j == (itm.dtlList.length - 1)?'':'tableBB']" cols="8">
                                                <span class="fontOneRem">{{ itmDtl.itm1CdNm }}</span>
                                            </v-col>
                                            <v-col :class="['', j == (itm.dtlList.length - 1)?'':'tableBB']" cols="4">
                                                <div
                                                    class="d-flex justify-center align-center cmmFontSizeOne">
                                                    <v-radio-group
                                                        v-model="itmDtl.itm1Val" :key="forceRender"
                                                        class="radio-dft-Theme ma-0 pa-0 d-flex justify-center align-center d-inline-block" style="width:100%;" hide-details row>
                                                        <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:33.3333%; height: 37px;"
                                                            on-icon="mdi-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="1"></v-radio>
                                                        <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:33.3333%;"
                                                            on-icon="mdi-triangle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="2"></v-radio>
                                                        <v-radio class="d-inline-block ma-0 d-flex justify-center" style="width:33.3333%;"
                                                            on-icon="mdi-close" off-icon="mdi-minus-circle-outline" :ripple="false" value="3"></v-radio>
                                                    </v-radio-group>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row v-else class="tableBB pa-0" no-gutters>
                                <v-col class="txtAlgnMid py-2" cols="12">
                                    <span class="fontOneRem grey006--text">※ 조회된 점검 항목이 없습니다.</span>
                                </v-col>
                            </v-row>   
                        </v-col>
                        <v-col class="pl-4" cols="5">
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center" cols="12">
                                    <span class="font-weight-medium posn-rltv">특이사항</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="px-2 py-1" cols="12">
                                    <v-textarea
                                        class="rmrksArea" rows="40"
                                        v-model="cntnt"
                                        no-resize hide-details outlined dense>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" content-class="round" max-width="800" persistent eager>
            <EmpSelector v-if="menu[0]" @isClose="menu.splice(0,1,false)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'NghChcMdl',
    
    components: {
        EmpSelector,
        CmmTimeField
    },

    props : { 
        inqymd      : { type: String, default: '' },
        nghList     : { type: Array, default: () => { return [] } },
        areaList    : { type: Array, default: () => { return [] } },
        sltOrd      : { type: Number, default: -1 },
        sltAreaPk   : { type: Number, default: -1 },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {   
            this.prtWrtDt = this.$moment(this.inqymd, 'YYYY.MM.DD').format('YYYYMMDD')            
            if(this.sltOrd > 0 && this.sltAreaPk > 0){
                let idx = this.nghList.findIndex(v=>v.rn == this.sltOrd && v.inspAreaPk == this.sltAreaPk)
                this.chcTblIdx(this.nghList[idx], idx)
            }
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {     
           
        getSubEvt: function (bol) {
            if(bol && this.rmNghChcCnFm){
                this.removeInspRecDtl()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmNghChcCnFm = false
            }
        },
    },
        
    methods: {
         // 야간점검일지 상세 내역
         getInspRecDtl501List: function (){    
            this.inspRecDtlList.splice(0)
            this.inspRecItmTot=0

            let obj = {
                inspOrd       : this.inspOrd,
                inspAreaPk    : this.inspAreaPk,
                inqYMD        : this.prtWrtDt,
                inspRecPk     : this.inspRecPk
            }

            http.post('sfty/selInspRecDtl501List', obj)
                .then((response) => ( this.recDtl501ListRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl501List : ' + error))
        },

        //점검기록상세 저장
        insInspRecDtl: function (obj){    
            
            http.post('sfty/insNwInspRecDtl', obj)
                .then((response) => ( this.insInspRecDtlRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/insNwInspRecDtl : ' + error))
        },

        //야간점검일지 삭제
        removeInspRecDtl: function (){
            let obj = {}
            obj.clsfc1Cd = 501
            obj.clsfc2Cd = 1
            obj.inspRecPk   = this.inspRecPk
            obj.inspAreaPk  = this.inspAreaPk
            obj.inspDt      = this.prtWrtDt            
            obj.wrtr        = this.wrtr
            obj.tkeOvrPk    = this.tkeOvrPk
            obj.list = []

            this.inspRecDtlList.forEach(itm => {
                if(itm.dtlList != null){
                    itm.dtlList.forEach(list => {                                                           
                        obj.list.push(list)
                    });
                }
            });

            http.post('sfty/delNwInspRecDtl', obj)
                .then((response) => ( this.insInspRecDtlRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/delNwInspRecDtl : ' + error))
        },

        recDtl501ListRst:function(res){            
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {   
                        
                        if(itm.dtlList != null){
                           this.inspRecItmTot += itm.dtlList.length
                            
                           //수정일 경우
                           if(this.inspRecPk > 0){                                
                                if(itm.dtlList[0].inspTmHh != null && itm.dtlList[0].inspTmMm != null) this.inspTm       = itm.dtlList[0].inspTmHh+":"+itm.dtlList[0].inspTmMm     
                                this.inspDtlDt    = this.$moment(itm.dtlList[0].inspDtlDt,'YYYYMMDD').format('YYYY.MM.DD')
                                this.wrtr         = itm.dtlList[0].wrtr
                                this.wrtrMmbrPk   = itm.dtlList[0].wrtrMmbrPk
                           }

                           itm.dtlList.forEach(el => {
                                if(el.itm1Val == undefined) el.itm1Val = '1'
                           });


                        }

                        
                        this.inspRecDtlList.push(itm)                
                    });
                }

                if(res.result.tkeOvrInfo != null) {
                    this.tkeOvrPk   = res.result.tkeOvrInfo.tkeOvrPk
                    if(res.result.tkeOvrInfo.cntnt != '' && res.result.tkeOvrInfo.cntnt != null){
                        this.cntnt      = res.result.tkeOvrInfo.cntnt
                    }
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            ++this.forceRender
        },

        insInspRecDtlRst:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀   

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit('isClose', '.')                    
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)   
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)             
            }            
            this.$store.commit('setWrnnInf', obj)
        },

        preInspRecDtl: function (){
            if(this.isNghChcValid()){
                
                let obj = {}
                obj.clsfc1Cd    = 501
                obj.clsfc2Cd    = 1
                obj.inspRecPk   = this.inspRecPk
                obj.inspAreaPk  = this.inspAreaPk
                obj.inspDt      = this.prtWrtDt
                obj.wrtr        = this.wrtr
                obj.wrtrMmbrPk  = this.wrtrMmbrPk
                obj.tkeOvrPk    = this.tkeOvrPk
                obj.wrtDt       = this.prtWrtDt
                obj.wrtDtlDt    = this.$moment(this.inspDtlDt,'YYYY.MM.DD').format('YYYYMMDD')
                obj.wrtTmHh     = this.$moment(this.inqymd+' '+this.inspTm).format('HH')
                obj.wrtTmMm     = this.$moment(this.inqymd+' '+this.inspTm).format('mm')
                obj.cntnt       = this.cntnt
                obj.list        = []

                this.inspRecDtlList.forEach(itm => {
                    if(itm.dtlList != null){
                        itm.dtlList.forEach(list => {                               
                            list.inspTmHh       = this.$moment(this.inqymd+' '+this.inspTm).format('HH')
                            list.inspTmMm       = this.$moment(this.inqymd+' '+this.inspTm).format('mm')
                            list.inspDt         = this.prtWrtDt
                            list.inspDtlDt      = this.$moment(this.inspDtlDt,'YYYY.MM.DD').format('YYYYMMDD')
                            list.wrtr           = this.wrtr
                            list.wrtrMmbrPk     = this.wrtrMmbrPk
                            obj.list.push(list)
                        });

                    }
                });          
                this.insInspRecDtl(obj)
            }

        },

        preRemoveInsp:function(){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '야간 점검 일지'
            obj.cntnt = ['삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });

            this.rmNghChcCnFm = true
        },

        onClickBtn: function (index) {
            switch(index) {
                case 0:     //신규   
                    this.chnNewArea()               
                    break;
                case 1:     //저장
                    this.preInspRecDtl()
                    break;
                case 2:    
                    this.preRemoveInsp()
                    break;
                case 3:    
                    break;
                default:
                    break;
            }
        },

        chcTblIdx:function(itm, idx){    
             
            if(this.tblIdx == idx) { 
                this.tblIdx = -1              
                this.filledBtn[2].disabled = true
                this.setAreaAct()                 
            }
            else {
                this.tblIdx = idx
                if(itm.inspRecPk > 0) this.filledBtn[2].disabled = false
                else this.filledBtn[2].disabled = true
                this.setAreaAct(itm)
            }
            this.getInspRecDtl501List()
        },

        chnNewArea:function(){         
            this.tblIdx = -1                                    
            this.filledBtn[2].disabled = true
            this.setAreaAct()
            this.getInspRecDtl501List()
        },

        chnArea:function(){
            if(this.inspAreaPk > 0){

                if(this.tblIdx > -1){
                    this.tblIdx = -1                                    
                    this.filledBtn[2].disabled = true
                    let tmpPk = this.inspAreaPk
                    this.setAreaAct()
                    this.inspAreaPk = tmpPk
                }

                this.getInspRecDtl501List()
            }
        },

        //점검 구역 정보 SETTING
        setAreaAct:function(itm = null){
            this.inspAreaPk     = -1
            this.wrtr           = ''
            this.inspTm         = ''
            this.wrtrMmbrPk     = -1
            this.inspOrd        = null
            this.tkeOvrPk       = -1
            this.cntnt          = '특이사항 없음'

            if(itm != null){
                this.inspAreaPk  = itm.inspAreaPk
                this.inspRecPk   = itm.inspRecPk
                this.inspOrd     = itm.inspOrd
            }
        },

        //점검자 조회
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
            this.wrtrMmbrPk = obj[0].mmbrPk                
            // if(obj[0].wrkOvnYn == '1'){
            // }else{
            //     this.onAlrt('야간 근무자만 선택 할 수 있습니다!')
            //     this.wrtr = ''
            //     this.wrtrMmbrPk = ''
            // }
        },

        //시간설정에 따른 날짜 변경
        setTmInspDT:function(){
            //new Date 안할 경우 provided is not in a recognized RFC2822 경고 발생
            let datetm = this.inqymd+' '+this.inspTm

            if(this.$moment(datetm, 'YYYY.MM.DD HH:mm', true).isValid()){
                let hh = this.$moment(this.inqymd+' '+this.inspTm).format('HH')

                //18:00~23:59 사이 값이 아닐 경우 inspDtlDT 내일로 설정
                if(hh >= 18 && hh <= 23)
                    this.inspDtlDt = this.inqymd
                else 
                    this.inspDtlDt = this.$moment(this.inqymd, 'YYYY.MM.DD').add(1, 'days').format('YYYY.MM.DD')

            } 
            else {
                this.inspDtlDt = ''
            }
        },

        isNghChcValid:function(){
            let resrt = true

            if(this.inspAreaPk == '' || this.inspAreaPk == null || this.inspAreaPk < 1) {
                this.onAlrt('점검구역을 선택해주세요.')
                resrt = false
            }

            if(resrt && (this.wrtr == '' || this.wrtr == null)) {
                this.onAlrt('점검자를 선택해주세요.')
                resrt = false
            }

            if(resrt && (this.inspTm == '' || this.inspTm == null)) {
                this.onAlrt('점검시간을 선택해주세요.')
                resrt = false
            }

            let hh = this.$moment(this.inqymd+' '+this.inspTm).format('HH')
            let mm = this.$moment(this.inqymd+' '+this.inspTm).format('mm')

            if(resrt && (hh > 6 && hh < 22)) {
                this.onAlrt('점검시간을 22시 이후에서 다음날 6:00 까지만 입력 가능합니다')
                resrt = false
            }

            if(resrt && (hh == 6 && mm != "00")) {
                this.onAlrt('점검시간을 22시 이후에서 다음날 6:00 까지만 입력 가능합니다')
                resrt = false
            }
            
            if(resrt && (this.inspRecItmTot == 0)) {
                this.onAlrt('점검기록이 존재하지 않습니다.')
                resrt = false
            }

            return resrt
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
    },

    data: () => ({
        rmNghChcCnFm  : false,
        forceRender : 0,
        prtWrtDt    :'',
        tblIdx      : -1,
        inspAreaPk  : -1,
        inspRecPk   : -1,
        wrtr        : '',
        wrtrMmbrPk  : -1,
        inspTm      : '',            
        inspDtlDt   : '',
        tkeOvrPk    : null,
        cntnt       : "특이사항 없음",
        inspOrd     : null,
        menu        : [false],
        headers: [
            { text: '점검구역', value: 'rn', sortable: true, width: '200', class: 'black--text bottomBorder py-3', align: 'center', },
            { text: '점검회차', value: 'idxnm', sortable: true, width: '150', class: 'black--text bottomBorder', align: 'center', },
            { text: '점검시간', value: 'bnfcrgrNm', sortable: true, width: '150', class: 'black--text bottomBorder', align: 'center', },
        ],
        inspRecDtlList  : [],
        inspRecItmTot   : 0,
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'white--text', color:'blueBtnColor', text: '신규', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>