<template>
    <v-sheet class="rounded-lg rounded-l-0 px-3 py-1" color="" min-width="1260" max-width="1560" height="835">
        <!-- contents -->
        <v-row class="pa-0 align-center" no-gutters>
            <span class="ml-1 hamletFont text-h5 topTitleColor--text font-weight-black">9-2. 직원현황</span>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme mr-1" height="28" color="grey006"
                :disabled="exlBtn"
                @click="onClickBtn('엑셀')"
                samll outlined rounded>
                <v-icon small>mdi-microsoft-excel</v-icon>
                <span>엑셀</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme mr-1" height="28" color="grey006"
                :disabled="pdfBtn"
                @click="onClickBtn('출력')"
                samll outlined rounded>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>출력</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
        <v-tabs
            v-model="tab" class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab" class="">
            <v-tab-item :transition="false" eager>
                <tab1 ref="tab1" :tab="tab" :prtCd42="vCd42" :prtCd70="vCd70"></tab1>
            </v-tab-item>
            <v-tab-item :transition="false" eager>
                <tab2 ref="tab2" :tab="tab" :prtCd42="vCd42" :prtCd70="vCd70"></tab2>
            </v-tab-item>
            <v-tab-item :transition="false" eager>
                <tab3 ref="tab3" :tab="tab" :prtCd42="vCd42" :prtCd107="vCd107"></tab3>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';

import tab1 from '../../components/btcInq/EmpInfSttsTab1.vue';
import tab2 from '../../components/btcInq/EmpInfSttsTab2.vue';
import tab3 from '../../components/btcInq/EmpInfSttsTab3.vue';

export default {
    name: 'EmpInfStts',
        
    components: {
        tab1,
        tab2,
        tab3,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.getExcelInfo()
            this.getCode(['42', '70', '73', '107'])
        })
    },

    computed: {

    },

    watch:{ 
        'tab': function (){
            let arrCnt = 0

            switch (this.tab) {
                case 0:
                    arrCnt = this.$refs.tab1.empList.length
                    break;
                case 1:
                    arrCnt = this.$refs.tab2.empList.length
                    break;
                case 2:
                    arrCnt = this.$refs.tab3.empList.length
                    break;
                default:
                    break;
            }

            if(arrCnt > 0){
                this.exlBtn = false
                this.pdfBtn = false
            }
            else{
                this.exlBtn = true
                this.pdfBtn = true
            }
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70
            this.vCd73 = res.vCd73
            this.vCd107 = res.vCd107
        },

        onClickBtn: function (value){
            switch (value) {
                case '엑셀':
                    if(this.tab == 0)
                        this.$refs.tab1.xlsxProd()
                    else if (this.tab == 1)
                        this.$refs.tab2.xlsxProd()
                    else if (this.tab == 2)
                        this.$refs.tab3.xlsxProd()
                    break;
                case '출력':
                    this.prtThrowObj.inqWrkStcds = null
              
                    if(this.tab == 0){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.prtThrowObj.prtCd = '18'
                        this.prtThrowObj.inqYMD= this.$refs.tab1.date

                        let chc = this.$refs.tab1.vCd70.findIndex( v => v.isOn == true )
                        if(chc > -1){
                            this.prtThrowObj.inqWrkStcds = []
                            this.$refs.tab1.vCd70.forEach(itm => {
                                if(itm.isOn)
                                    this.prtThrowObj.inqWrkStcds.push(itm.valcd)
                            });
                        }

                        this.$store.commit('setRootInf', {
                            actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                        });
                    }else if (this.tab == 1){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.prtThrowObj.prtCd = '38'
                        this.prtThrowObj.inqYmdStart = this.$moment(this.$refs.tab2.date).startOf('month').format('YYYYMMDD')
                        this.prtThrowObj.inqYmdEnd = this.$moment(this.$refs.tab2.date).endOf('month').format('YYYYMMDD')
                     
                        this.$store.commit('setRootInf', {
                            actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                        });
                    }else if (this.tab == 2){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.prtThrowObj.prtCd = '36'
                        if(this.$refs.tab3.srchCd  == 0){
                            this.prtThrowObj.inqYmdStart = this.$refs.tab3.date
                            this.prtThrowObj.inqYmdEnd = this.$refs.tab3.date
                        }else if (this.$refs.tab3.srchCd == 1){
                            this.prtThrowObj.inqYmdStart = this.$refs.tab3.mDate + '01'
                            this.prtThrowObj.inqYmdEnd = this.$moment(this.$refs.tab3.mDate).endOf('month').format('YYYYMMDD')
                        }else if (this.$refs.tab3.srchCd == 2){
                            this.prtThrowObj.inqYmdStart = this.$moment(this.$refs.tab3.yDate, 'YYYY').startOf('year').format('YYYYMMDD')
                            this.prtThrowObj.inqYmdEnd = this.$moment(this.$refs.tab3.yDate, 'YYYY').endOf('year').format('YYYYMMDD')
                        }

                        this.$store.commit('setRootInf', {
                            actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                        });
                    }
                    break;
                default:
                    break;
            }
        },
        
    },
    
    data: () => ({
        tab:0,
        exlBtn: true,
        pdfBtn: true,
        vCd42: [],                          // 직원 직종 코드
        vCd70: [],                          // 직원 상태 코드
        vCd73: [],                          // 근무 형태 코드
        vCd107: [],                         // 휴가 형태 코드
        tabItems: [
            { index: 0, tab: '직원현황', class: 'fontOneDotTwoRem px-15'}, 
            { index: 1, tab: '연차내역', class: 'fontOneDotTwoRem px-15'},
            { index: 2, tab: '휴가사용내역', class: 'fontOneDotTwoRem px-15'},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '', inqYmdStart: '', inqYmdEnd: '', inqYMD: '',
            inqWrkStcds: null,
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>