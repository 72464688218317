<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">       
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold blue--text">{{inObj.name}} </span>
            <span class="text-h4 font-weight-bold pl-2">구강관찰기록</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="$emit('isClose')">mdi-close</v-icon>
        </div>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">작성 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR d-flex justify-center align-center" cols="3">                
                <span class="font-weight-medium">
                    관찰월도
                </span>
            </v-col>            
            <v-col class="px-2 py-1 d-flex justify-left align-center" cols="9">
                {{$moment(dntObs.obsYymm,'YYYYMM').format('YYYY년 MM월')}}
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                <span class="font-weight-medium posn-rltv">
                    <span>작성일</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="3">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="dntObs.wrtDt" :minDt="minDt" :maxDt="maxDt" :required="true" @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                <span class="font-weight-medium posn-rltv">
                    <span>작성자</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 pb-1 d-flex justify-left align-center" style="padding-top: 3px;" cols="3">
                <span class="d-inline-block" style="width: 100px;">
                    <v-text-field v-model="dntObs.wrtr" color="blue" height="28" ref="info" readonly
                        :rules="[rules.required]" hide-details outlined dense></v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" 
                    small 
                    min-width="30"
                    height="26"  
                    :disabled="dntObs.wrtDt == '' || dntObs.wrtDt == null"
                    style="padding:0px 1px 0px 2px !important"
                    @click="menu.splice(0,1,true)"
                    >
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="3">                               
                <span class="font-weight-medium posn-rltv">
                    <span>구강상태</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex justify-left align-center" cols="9">
                <!-- <v-radio-group
                    v-model="dntObs.orlCndCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio v-for="(cd, i) in cd152" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="cd.valcdnm" :value="cd.valcd">
                    </v-radio>
                </v-radio-group> -->
                <v-checkbox class="dftChcBox-Theme  authAgr fontTwoRem py-1 px-2" style="margin-top: 0px !important;"
                    v-for="(cd, i) in cd152" :key="i"
                    v-model="dntObs.orlCndCd"  
                    hide-details 
                    :ripple="false" 
                    :label="cd.valcdnm" :value="cd.valcd" @click="aplctSavBtn">
                </v-checkbox>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="3">                
                <span class="font-weight-medium posn-rltv">
                    <span>치아상태</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="pb-1" style="padding-top: 3px;" cols="9">
                <v-radio-group
                    v-model="dntObs.dntCndCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio v-for="(cd, i) in cd153" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="cd.valcdnm" :value="cd.valcd">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01  d-flex justify-center align-center tableBR" cols="3">                
                <span class="font-weight-medium posn-rltv">
                    <span>세척방법</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex justify-left align-center" style="padding-top: 3px;" cols="9">
                <!-- <v-radio-group
                    v-model="dntObs.clnMthdCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio v-for="(cd, i) in cd154" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="cd.valcdnm" :value="cd.valcd">
                    </v-radio>
                </v-radio-group> -->
                <v-checkbox class="dftChcBox-Theme  authAgr fontTwoRem py-1 px-2" style="margin-top: 0px !important;"
                    v-for="(cd, i) in cd154" :key="i"
                    v-model="dntObs.clnMthdCd"  
                    hide-details 
                    :ripple="false" 
                    :label="cd.valcdnm" :value="cd.valcd" @click="aplctSavBtn">
                </v-checkbox>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01  d-flex justify-center align-center tableBR" cols="3">                
                <span class="font-weight-medium posn-rltv">
                    <span>특이사항</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="pb-1" style="padding-top: 3px;" cols="9">
                <v-radio-group
                    v-model="dntObs.spclCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio v-for="(cd, i) in cd142" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="cd.valcdnm" :value="cd.valcd">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01  d-flex justify-center align-center tableBR" cols="3">
                <span class="font-weight-medium posn-rltv">상세내용</span>
            </v-col>
            <v-col class="d-flex justify-left align-center" style="padding-top: 3px;" cols="9">
                <!-- <v-radio-group
                    v-model="dntObs.dtlCntntCd"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio v-for="(cd, i) in cd155" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="cd.valcdnm" :value="cd.valcd">
                    </v-radio>
                </v-radio-group> -->
                <v-checkbox class="dftChcBox-Theme  authAgr fontTwoRem py-1 px-2" style="margin-top: 0px !important;"
                    v-for="(cd, i) in cd155" :key="i"
                    v-model="dntObs.dtlCntntCd"  
                    :disabled="dntObs.spclCd=='2'"
                    hide-details 
                    :ripple="false" 
                    :label="cd.valcdnm" :value="cd.valcd" @click="aplctSavBtn">
                </v-checkbox>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01  d-flex justify-center align-center tableBR" cols="3">
                <span class="font-weight-medium posn-rltv">조치사항</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="9">
                <v-text-field
                    v-model="dntObs.actns"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="grey006" min-width="110" height="36"
                :disabled="delBtn"
                @click="preDelDntObs">
                <span class="white--text fontOneDotTwoRem">삭제</span>
            </v-btn>
            <v-btn
                class="ml-4" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn"
                @click="preSavDntObs">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
            <span
                class="d-inlne-block"
                style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">구강관찰기록 내역</span>
            <v-spacer></v-spacer>
        </v-row>
        <v-divider :class="[oralCareHistList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <v-row :class="[oralCareHistList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">관찰월도</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">작성일</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">치아상태</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">특이사항</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="3">
                <span class="font-weight-medium">상세내용</span>
            </v-col>
        </v-row>
        <v-divider :class="[oralCareHistList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div
            v-if="oralCareHistList.length > 0"
            class="overflow-y-auto" style="width: 100%; height: 130px; padding-bottom: 1px;">
            <v-row
                :class="['tableBB', oralCareHistList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in oralCareHistList" :key="i"
                @click="rowClicked(itm.dntObsPk)"
                no-gutters>
                <v-col :class="['txtAlgnMid tableBR py-1'
                    ,sltDntObsPk == itm.dntObsPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                    ,itm.dntObsPk == null ? '' : 'crsShp-Pointer']" cols="2">
                    <span class="fontOneRem">{{ $moment(itm.obsYymm, 'YYYYMM').format('YYYY.MM') }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBR py-1'
                    ,sltDntObsPk == itm.dntObsPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                    ,itm.dntObsPk == null ? '' : 'crsShp-Pointer']" cols="2">
                    <span class="fontOneRem">{{ $moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBR py-1'
                    ,sltDntObsPk == itm.dntObsPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                    ,itm.dntObsPk == null ? '' : 'crsShp-Pointer']" cols="3">                                        
                    <span>{{ itm.dntCndCdNm }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBR py-1'
                    ,sltDntObsPk == itm.dntObsPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                    ,itm.dntObsPk == null ? '' : 'crsShp-Pointer']" cols="2">                                        
                    <span>{{ itm.spclCdNm }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid py-1'
                    ,sltDntObsPk == itm.dntObsPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                    ,itm.dntObsPk == null ? '' : 'crsShp-Pointer']" cols="3">                                        
                    <span>{{ itm.dtlCntntNm }}</span>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="menu.splice(0,1,false)" @outEmp="getEmpInfo" :prtWrtDt="dntObs.wrtDt"></EmpSelector>
            </v-dialog>  
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../components/commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'OralCareSavMdl',

    props : {
        inObj : {type: Object, default: new Object()},
        cd142:{type: Array, default: () => { return [] }},
        cd152:{type: Array, default: () => { return [] }},
        cd153:{type: Array, default: () => { return [] }},
        cd154:{type: Array, default: () => { return [] }},
        cd155:{type: Array, default: () => { return [] }},
    },
            
    components: {
        CmmDateComp,
        EmpSelector
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.setDntObs(this.inObj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmOralCareCnFm){
                this.delDntObs()                
                this.rmOralCareCnFm = false

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
            }

        },
    },
        
    methods: {
        //기본정보 셋팅
        setDntObs(obj, hist = '1'){
            this.dntObs.dntObsPk    = obj.dntObsPk
            this.dntObs.bnMmbrPk    = obj.bnMmbrPk
            this.dntObs.obsYymm     = obj.obsYymm
            this.dntObs.orlCndCd    = (obj.orlCndCd != null && obj.orlCndCd != '')?obj.orlCndCd:[]
            this.dntObs.dntCndCd    = obj.dntCndCd
            this.dntObs.clnMthdCd   = (obj.clnMthdCd != null && obj.clnMthdCd != '')?obj.clnMthdCd:[]
            this.dntObs.spclCd      = obj.spclCd
            this.dntObs.dtlCntntCd  = (obj.dtlCntntCd != null && obj.dtlCntntCd != '')?obj.dtlCntntCd:[]
            this.dntObs.actns       = obj.actns
            this.dntObs.wrtr        = obj.wrtr
            this.dntObs.wrtrMmbrPk  = obj.wrtrMmbrPk
            this.dntObs.wrtDt       = (obj.wrtDt != '' && obj.wrtDt != null)?this.$moment(obj.wrtDt,'YYYYMMDD').format('YYYY-MM-DD'):''

            this.aplctSavBtn()            

            //날짜 범위지정
            if(obj.obsYymm != '') {
                this.minDt = this.$moment(obj.obsYymm+'01').format('YYYY-MM-DD')
                this.maxDt = this.$moment(this.minDt,'YYYYMMDD').endOf('month').format('YYYY-MM-DD')
            }

            if(obj.dntObsPk > 0) this.delBtn = false
            else this.delBtn = true

            if(hist == '1') this.getDntObsHstList()
        },
        getEmpInfo: function (obj) {
            this.dntObs.wrtr = obj[0].name
            this.dntObs.wrtrMmbrPk = obj[0].mmbrPk
            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
            
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.dntObs.wrtDt) == false)
                return 0

            if(this.dntObs.wrtrMmbrPk == -1 || this.dntObs.wrtr == '' || this.dntObs.wrtr == null)
                return 0

            if(this.dntObs.orlCndCd == '' || this.dntObs.orlCndCd == null || this.dntObs.orlCndCd.length == 0)
                return 0
            
            if(this.dntObs.dntCndCd == '' || this.dntObs.dntCndCd == null)
                return 0
            
            if(this.dntObs.clnMthdCd == '' || this.dntObs.clnMthdCd == null|| this.dntObs.clnMthdCd.length == 0)
                return 0
            
            if(this.dntObs.spclCd == '' || this.dntObs.spclCd == null)
                return 0

            if(this.dntObs.spclCd == '2'){
                this.dntObs.dtlCntntCd.splice(0)
            }

            this.savBtn = false
        },

        preSavDntObs: function(){
            let obj = {
            }

            obj.dntObsPk    = this.dntObs.dntObsPk
            obj.bnMmbrPk    = this.dntObs.bnMmbrPk
            obj.obsYymm     = this.dntObs.obsYymm
            obj.orlCndCd    = this.dntObs.orlCndCd
            obj.dntCndCd    = this.dntObs.dntCndCd
            obj.clnMthdCd   = this.dntObs.clnMthdCd
            obj.spclCd      = this.dntObs.spclCd
            obj.dtlCntntCd  = this.dntObs.dtlCntntCd
            obj.actns       = this.dntObs.actns
            obj.wrtr        = this.dntObs.wrtr
            obj.wrtrMmbrPk  = this.dntObs.wrtrMmbrPk
            obj.wrtDt       = this.$moment(this.dntObs.wrtDt,'YYYY-MM-DD').format('YYYYMMDD')            

           
            this.savDntObs(obj)
        },

        preDelDntObs:function(){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '구강관찰기록'
            obj.cntnt = ['삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });

            this.rmOralCareCnFm = true
        },

        //구강관찰기록 저장
        savDntObs: function (obj){            
            http.post('bnftrcrd/insDntObs', obj)
                .then((response) => ( this.savDntObsRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/insDntObs : ' + error))
        },

        //구강관찰기록 다건 조회(내역)
        getDntObsHstList: function (){
            this.oralCareHistList.splice(0)            
            let obj = {}
            obj.inqYYYY     = this.$moment(this.dntObs.obsYymm,'YYYYMM').format('YYYY') 
            obj.bnMmbrPk    = this.dntObs.bnMmbrPk

            http.post('bnftrcrd/selDntObsHstList', obj)
                .then((response) => ( this.dntObsHstListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selDntObsHstList : ' + error))
        },
        

        //구강관찰기록 삭제
        delDntObs: function (){     
            let obj = {
                dntObsPk : this.dntObs.dntObsPk
            }       
            http.post('bnftrcrd/delDntObs', obj)
                .then((response) => ( this.savDntObsRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/delDntObs : ' + error))
        },

        savDntObsRst:function(res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200') {
                this.savBtn = false
                this.$emit('isClose')
            }
            else {
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)

        },

        dntObsHstListRst:function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null && res.result.list != undefined) {
                    res.result.list.forEach(item => {  

                        this.cd153.forEach(code => {
                            if(item.dntCndCd == code.valcd) item.dntCndCdNm = code.valcdnm
                        });

                        this.cd142.forEach(code => {
                            if(item.spclCd == code.valcd) item.spclCdNm = code.valcdnm
                        });

                        let dtlCntntNm   = ''

                        // 상세내용코드(공통항목값분류코드 155참조)
                        if(item.dtlCntntCd != null && item.dtlCntntCd != ''){
                            item.dtlCntntCd.forEach((val, index) => {                                
                                if(index != 0) dtlCntntNm   += ','
                                
                                this.cd155.forEach(code => {
                                    if(val == code.valcd) dtlCntntNm += code.valcdnm
                                });
                            });
                        }

                        item.dtlCntntNm = dtlCntntNm

                        this.oralCareHistList.push(item)
                    });
                }
            }
            else {
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }  
        },

        rowClicked:function(pk){

            if(this.sltDntObsPk == pk){
                this.sltDntObsPk = -1
                this.frmReset()                     
            }else{
                this.sltDntObsPk = pk   
                if(this.sltDntObsPk != null){
                    let idx = this.oralCareHistList.findIndex(v=>v.dntObsPk == pk)
                    this.setDntObs(this.oralCareHistList[idx],'2')
                }else{
                    this.sltDntObsPk = -1
                    this.frmReset()                    
                }
            }
            
        },     

        frmReset:function(){
            this.dntObs.dntObsPk    = -1                        
            this.dntObs.orlCndCd.splice(0)
            this.dntObs.dntCndCd    = ''
            this.dntObs.clnMthdCd.splice(0)
            this.dntObs.spclCd      = ''
            this.dntObs.dtlCntntCd.splice(0)
            this.dntObs.actns       = ''
            this.dntObs.wrtr        = ''
            this.dntObs.wrtrMmbrPk  = -1
            this.dntObs.wrtDt       = ''
            this.savBtn             = true
            this.delBtn             = true
        }
    },
    
    data: () => ({   
        rmOralCareCnFm   : false,
        savBtn          : true,
        delBtn          : true,
        minDt           : '',
        maxDt           : '',
        dntObs:{
            dntObsPk    : -1,
            bnMmbrPk    : -1,
            obsYymm     : '',
            orlCndCd    : [],
            dntCndCd    : '',
            clnMthdCd   : [],
            spclCd      : '',
            dtlCntntCd  : [],
            actns       : '',
            wrtr        : '',
            wrtrMmbrPk  : -1,
            wrtDt       : '',
        },
        sltDntObsPk      : -1,
        oralCareHistList : [],
        rules   : {            
            required: value => !!value || 'Required.',
        },
        menu    : [false]
    }),
};
</script>