<template>
    <v-sheet class="" rounded="lg" width="100%">
        <v-form ref="form" lazy-validation>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pa-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">운영일시</span>
                </v-col>
                <v-col class="tableBR pa-2" cols="3">
                    <span class="fontOneRem" v-if="items.opDt != null">{{opDt}} / {{opTmStrt}} ~ {{ opTmEnd }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pa-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">프로그램명</span>
                </v-col>
                <v-col class="pa-2" cols="5">
                    <span class="fontOneRem">{{ items.prgNm }}</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR py-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">프로그램 수급자 그룹</span>
                </v-col>
                <v-col class="tableBR px-1 d-flex justify-left align-center" cols="3">
                    <div class="pt-1 overflow-y-auto" style="height: 64px;">
                        <v-chip
                            v-for="(itm, j) in items.bnfcrgrNm" :key="j"
                            class="pa-2 mr-1" style="background-color: white !important;"
                            color="grey"
                            outlined small label>
                            {{ itm }}
                        </v-chip>
                    </div>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR py-2" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">참여/등록인원(참여율)</span>
                </v-col>
                <v-col class="d-flex align-center justify-center tableBR pa-2" cols="2">
                    <span class="fontOneRem">{{ items.prtCpDesc }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR py-2" cols="1">
                    <span class="fontOneDotOneRem font-weight-bold">진행자</span>
                </v-col>
                <v-col class="d-flex align-center justify-center pa-2" cols="2">
                    <span class="fontOneRem">{{ items.wrtr }}</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-divider :class="['mt-4', items1.length > 12 ? 'mr-2__5' : '']"></v-divider>
            <v-row
                :class="['pa-0', items1.length > 12 ? 'mr-2__5' : '']"
                no-gutters>
                <v-col class="greyE01 d-flex" cols="12">
                    <div class="d-flex justify-center align-center" style="width:4%">
                        <span class="fontOneDotOneRem font-weight-bold">연번</span>
                    </div>
                    <div class="d-flex justify-center align-center tableBL" style="width:9%;">
                        <span class="fontOneDotOneRem font-weight-bold">수급자</span>
                    </div>
                    <div class="d-flex justify-center align-center tableBL" style="width:4%; line-height: 1.2;">
                        <span class="fontOneDotOneRem font-weight-bold">참여<br />여부</span>
                    </div>
                    <div class="tableBL" style="width:9%;">
                        <div class="txtAlgnMid pa-1" style="width:100%;">
                            <span class="fontOneDotOneRem font-weight-bold">참여도</span>
                        </div>
                        <v-divider></v-divider>
                        <v-row v-if="$parent.$parent.$parent.$parent.$parent.prtCd3.length > 0" class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 3" :key="i"
                                :class="['txtAlgnMid py-1', i < 3 ? 'tableBR' : '' ]"
                                cols="4">
                                <span class="fontOneDotOneRem font-weight-bold">
                                    {{ $parent.$parent.$parent.$parent.$parent?.prtCd3[i-1].valcdnm }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="tableBL" style="width:9%;">
                        <div class="txtAlgnMid pa-1" style="width:100%;">
                            <span class="fontOneDotOneRem font-weight-bold">만족도</span>
                        </div>
                        <v-divider></v-divider>
                        <v-row v-if="$parent.$parent.$parent.$parent.$parent.prtCd3.length > 0" class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 3" :key="i"
                                :class="['txtAlgnMid py-1', i < 3 ? 'tableBR' : '' ]"
                                cols="4">
                                <span class="fontOneDotOneRem font-weight-bold">
                                    {{ $parent.$parent.$parent.$parent.$parent?.prtCd3[i-1].valcdnm }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="tableBL" style="width:9%;">
                        <div class="txtAlgnMid pa-1" style="width:100%;">
                            <span class="fontOneDotOneRem font-weight-bold">수행도</span>
                        </div>
                        <v-divider></v-divider>
                        <v-row v-if="$parent.$parent.$parent.$parent.$parent.prtCd3.length > 0" class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 3" :key="i"
                                :class="['txtAlgnMid py-1', i < 3 ? 'tableBR' : '' ]"
                                cols="4">
                                <span class="fontOneDotOneRem font-weight-bold">
                                    {{ $parent.$parent.$parent.$parent.$parent?.prtCd3[i-1].valcdnm }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="pa-0 align-center justify-center tableBL" style="width:50%;" no-gutters>
                        <span class="fontOneDotOneRem font-weight-bold">특이사항</span>
                    </v-row>
                    <v-row class="pa-0 align-center justify-center tableBL" style="width:6%;" no-gutters>
                        <v-btn
                            class="" color="blue200" height="26"
                            @click="onLclMdl('01', 700)"
                            icon>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider :class="[items1.length > 12 ? 'mr-2__5' : '']"></v-divider>
            <div class="overflow-y-auto" style="height: 424px;">
                <v-row
                    v-for="(item, i) in items1" :key="i"
                    class="pa-0 tableBB" no-gutters>
                    <v-col class="d-flex" cols="12" style="height: 33px;">
                        <div class="txtAlgnMid" style="width:4%; padding-top: 6px;">
                            <span class="fontOneRem">{{ item.rn }}</span>
                        </div>
                        <div class="txtAlgnMid tableBL px-1" style="width:9%; padding-top: 6px;">
                            <span class="fontOneRem">{{ item.name }}</span>
                            <!-- <v-text-field
                                v-model="item.name"
                                color="blue" height="28"
                                hide-details outlined dense>
                            </v-text-field> -->
                        </div>
                        <div class="tableBL pl-3" style="width:4%; padding-top: 1px;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="item.prtcpYn" @click="radio(i)"
                                true-value="1" false-value="2"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-checkbox-blank-outline"
                                hide-details>
                            </v-checkbox>
                        </div>
                        <div class="tableBL" style="width:9%;">
                            <v-radio-group
                                v-model="item.prtcpCd" row class="d-flex radio-dft-Theme pa-0 ma-0"
                                style="width:100%; height: 100%;" :disabled="disabled[i]" hide-details>
                                <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%;"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="tableBL" style="width:9%;">
                            <v-radio-group v-model="item.stsfcCd" row class="d-flex radio-dft-Theme pa-0 ma-0"
                                style="width:100%; height: 100%;" :disabled="disabled[i]" hide-details>
                                <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%;"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="tableBL" style="width:9%;">
                            <v-radio-group v-model="item.perfCd" row class="d-flex radio-dft-Theme pa-0 ma-0"
                                style="width:100%; height: 100%;" :disabled="disabled[i]" hide-details>
                                <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%;"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="tableBL px-1" style="width: 50%; padding-top: 2px;">
                            <v-text-field
                                v-model="item.spclNote"
                                color="blue" height="28"
                                hide-details outlined dense>
                            </v-text-field>
                        </div>
                        <div class="tableBL" style="width: 6%; padding-top: 2px; padding-left: 2px;">
                            <v-btn
                                class="nmlBfClrImp-white ml-1"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="sngSave(i)" :disabled="item.prgPrtcpBnfcrPk == 0"
                                small icon>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-content-save-edit-outline</v-icon>
                                    </template>
                                    <span>수정</span>
                                </v-tooltip>
                            </v-btn>
                            <v-btn
                                class="nmlBfClrImp-white ml-1"
                                color="blackBtnColor" min-width="20" height="20"
                                :disabled="item.prgPrtcpBnfcrPk == 0" @click="sngDelete(i)"
                                small icon>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>삭제</span>
                                </v-tooltip>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn" :max-width="lclMdlInf.maxWidth"
            content-class="round"
            persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부, checkr: 체크박스 모델
            -->
            <IntgrSlctn
                :mdlTtl="'수급자 선택'" :tabInfList="['1']" :fixList="exclsList" mode="1"
                    :cd41="$parent.$parent.$parent.$parent.$parent.prtCd41"
                    :cd42="$parent.$parent.$parent.$parent.$parent.prtCd42"
                    :cd65="$parent.$parent.$parent.$parent.$parent.prtCd65"
                :prtWrtDt="$moment().format('YYYYMMDD')"
                @intgrResList="getAplyList" @onClose="rstLclMdlInf">
            </IntgrSlctn>
        </v-dialog>
    </v-sheet>
</template>

<script>
import IntgrSlctn from '../commons/IntgrSlctn.vue';

import {
    selPrgOpRecBnfcrInfo, selPrgOpRecBnfcrList, insPrgPrtcpBnfcrMulti, delPrgPrtcpBnfcr, selPrgOpRecInfo
} from '../../api/prgrm.js';

export default {
    name: 'PrgrmRecTab2',

    components: {
        IntgrSlctn
    },

    props: {
  
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                let chc = this.items1.findIndex( v => v.rmPosn == true )
                if(chc > -1){
                    let obj = {}
                    obj.prgOpRecPk = null
                    obj.prgPrtcpBnfcrPk = this.items1[chc].prgPrtcpBnfcrPk
                    obj.fcltyNum = this.$store.getters.getFcltyNum
                    obj.userNm = this.$store.getters.getUserNm

                    if(obj.prgPrtcpBnfcrPk != 0)
                        this.rmPrgBnfcr(obj)
                }

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                delete this.items1[chc].rmPosn
                this.runAble = '0'
            }
        },
    },

    methods: {
        // 프로그램 운영기록 한건 조회
        selPrgOpRecInfo: function (pk) {
            selPrgOpRecInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selPrgOpRecInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecInfo : ' + error))
        },
        selPrgOpRecInfoAfter: function (res) {
            this.opDt = res.opDt?.beforeDateFormatDot()
            this.opTmStrt = res.opTmHhStrt+':'+res.opTmMmStrt
            this.opTmEnd = res.opTmHhEnd+':'+res.opTmMmEnd
        },
        //프로그램운영 기록 수급자별 기본 조회
        selPrgOpRecBnfcrInfo: function (pk) {
            selPrgOpRecBnfcrInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selPrgOpRecBnfcrInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecBnfcrInfo : ' + error))
        },
        selPrgOpRecBnfcrInfoAfter: function (res) {
            if(typeof res.opDt != 'undefined'){
                this.prtWrtDt = res.opDt
                res.opDtDot = this.$moment(res.opDt, 'YYYYMMDD').format('YYYY.MM.DD')
            }
            this.items = res

            this.selPrgOpRecBnfcrList(
                this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                this.$parent.$parent.$parent.$parent.$parent.prgInfPk,
                this.$parent.$parent.$parent.$parent.$parent.prgOpPlnPk
            )  
        },
        //프로그램운영 기록 수급자평가 다건 조회
        selPrgOpRecBnfcrList: function (recpk, prgInfPk, prgPlnPk) {
            this.items1.splice(0)

            selPrgOpRecBnfcrList(
                this.$store.getters.getFcltyNum, prgInfPk, recpk, prgPlnPk, this.items.opDt, this.items.opTmHhStrt, this.items.opTmMmStrt
            )
                .then((response) => (this.selPrgOpRecBnfcrListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecBnfcrList : ' + error))
        },
        selPrgOpRecBnfcrListAfter: function (res) {
            if(res?.length > 0){
                res.forEach(e => {
                    if (e.prtcpYn == "1") 
                        this.disabled[e.rn - 1] = false
                    else if (e.prtcpYn == null)
                        this.disabled[e.rn - 1] = true

                    if(e.spclNote == null){
                        if (e.aplctClcd == "1")
                            e.spclNote = '외출'
                        else if (e.aplctClcd == "2")
                            e.spclNote = '외박'
                    }
                });

                res.forEach(itm => {
                    let tmp = itm
                    tmp.disChekr = true
                    tmp.checkr = '1'
                    this.exclsList.push(itm)
                });
         
                this.items1 = res
            }
        },
        // 프로그램 참여 수급자 저장
        setPrgBnfcrMulti: function (obj) {
            insPrgPrtcpBnfcrMulti(obj)
                .then((response) => (this.insPrgPrtcpBnfcrMultiAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/insPrgPrtcpBnfcrMulti : ' + error))
        },
        // 프로그램 참여 수급자 삭제
        rmPrgBnfcr: function (obj) {
            delPrgPrtcpBnfcr(obj)
                .then((response) => (this.delPrgPrtcpBnfcrAfter(response.data)))
                .catch((error) => console.log(error))
        },
        sngSave: function (idx) {
            let obj = Object.assign({}, this.items1[idx])
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.prgOpRecPk = this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk
            obj.userNm = this.$store.getters.getUserNm

            let result = {}
            result.list = []
            result.list.push(obj)

            this.setPrgBnfcrMulti(result)
        },
        onSave: function () {
            if (this.$refs.form.validate()) {
                let obj = {
                    list: [],
                }
                this.items1.forEach(e => {
                    this.$emit('progressOff')
                    let item = {
                        prgOpRecPk: this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                        bnMmbrPk: e.bnMmbrPk,
                        prtcpYn: e.prtcpYn,
                        prtcpCd: e.prtcpCd,
                        stsfcCd: e.stsfcCd,
                        perfCd: e.perfCd,
                        spclNote: e.spclNote,
                        userNm: this.$store.getters.getUserNm,
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgPrtcpBnfcrPk: e.prgPrtcpBnfcrPk,
                    }
                    obj.list.push(item)
                });
                
                this.setPrgBnfcrMulti(obj)
            }else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = '필수 항목을 입력해주세요.'        // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        insPrgPrtcpBnfcrMultiAfter: function (res) {
            this.$emit('prgrmRecTrigger', 'ins', res)
        },
        //프로그램 참여 수급자 전체 삭제
        onDelete() {
            if (this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk != undefined) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgOpRecPk: this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                    userNm: this.$store.getters.getUserNm,
                }

                this.rmPrgBnfcr(obj)
            }
        },
        //프로그램 참여 수급자 단건 삭제 (prgPrtcpBnfcrPk -> 단건 삭제시 추가)
        sngDelete: function (idx) {
            this.items1[idx].rmPosn = true
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '수급자: ' + this.items1[idx].name
            obj.cntnt = ['프로그램 참여 수급자를 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });  

            // let obj = {}
            // obj.prgOpRecPk = null
            // obj.prgPrtcpBnfcrPk = itm.prgPrtcpBnfcrPk
            // obj.fcltyNum = this.$store.getters.getFcltyNum
            // obj.userNm = this.$store.getters.getUserNm

            // if(obj.prgPrtcpBnfcrPk != 0)
            //     this.rmPrgBnfcr(obj)
        },
        delPrgPrtcpBnfcrAfter(res) {
            this.$emit('prgrmRecTrigger', 'delRe', res)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        resetPage: function () {
            this.items1 = [Object.assign({}, { bnfcrgrNms: [], prgNm: '', fncRcvCd: [], plc: "", mc: "", mtrl: "", prgCntnt: "", imprvRfl: "", imprvOpn: "" })]
            this.opDt = ''
            this.opTmStrt = ''
            this.opTmEnd = ''
            this.idxCdNm = ''
            this.idxCd = ''
            this.images = null
            this.eval = ''
            this.$refs.form.resetValidation()
        },
        radio: function (key) {
            if (this.items1[key].prtcpYn == 1) {
                this.disabled[key] = false
                this.items1[key].prtcpCd = '1'
                this.items1[key].stsfcCd = '1'
                this.items1[key].perfCd = '1'
            } else if (this.items1[key].prtcpYn == 2) {
                this.disabled[key] = true
                this.items1[key].prtcpCd = ''
                this.items1[key].stsfcCd = ''
                this.items1[key].perfCd = ''
            }
        },
        getAplyList: function (arr){
            arr.forEach(itm => {
                let obj = {}
                obj.bnMmbrPk = itm.bnMmbrPk
                obj.checkr = itm.checkr
                obj.disChekr = itm.disChekr
                obj.name = itm.name
                obj.perfCd = ''
                obj.prgPrtcpBnfcrPk = 0
                obj.prtcpCd = ''
                obj.prtcpYn = ''
                obj.spclNote = ''
                obj.stsfcCd = ''
                obj.rn = this.items1.length + 1
        
                this.items1.push(obj)
                this.disabled[obj.rn - 1] = true
            });
        },
        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },
        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        opDt:'',
        opTmStrt: '',
        opTmEnd: '',
        prtWrtDt: '',
        runAble: '0',
        forceRender: 0,
        disabled: [],
        exclsList: [],
        items: {},
        items1: [
            {
                bnMmbrPk: 0, fcltyNum: 0, perfCd: '', prgOpRecPk: 0, prgPrtcpBnfcrPk: 0,
                prtcpCd: '', prtcpYn: '', spclNote: '',stsfcCd: '', userNm: '',
            }
        ],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 수급자 선택)
        headers: [
            { text: '연번', value: '', align: 'center' },
            { text: '수급자', value: '', align: 'center' },
            { text: '참여 여부', value: '', align: 'center' },
            { text: '상', value: '', align: 'center' },
            { text: '중', value: '', align: 'center' },
            { text: '하', value: '', align: 'center' },
            { text: '상', value: '', align: 'center' },
            { text: '중', value: '', align: 'center' },
            { text: '하', value: '', align: 'center' },
            { text: '상', value: '', align: 'center' },
            { text: '중', value: '', align: 'center' },
            { text: '하', value: '', align: 'center' },
            { text: '특이사항', value: '', align: 'center' },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
    }),
};
</script>