<template>
    <v-sheet class="pa-0">
        <v-row class="align-center" no-gutters>
            <v-col cols='4'>
                <v-row class="pa-0 align-center" no-gutters>
                    <CmmExpnBtn
                        v-model="isEmpStt"
                        :btnNm="'상태'" vTyp="1" :bImg="'ic-house'"
                        :iList="vCd70" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                    </CmmExpnBtn>
                    <CmmExpnBtn
                        v-model="isJobStt"
                        :btnNm="'직종'" vTyp="2" :bIcon="'mdi-account-tie-woman'"
                        :iList="vCd42" @onList="dmyTxt = String(Number(dmyTxt)+1)"
                        class="ml-2">
                    </CmmExpnBtn>
                    <span class="d-inline-block ml-2" style="width: 106px; height: 34px;">
                        <v-text-field
                            v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                            class="txt-Theme" height="34" label="이름조회"
                            background-color="white" prepend-inner-icon="mdi-magnify"
                            single-line outlined dense filled hide-details>
                        </v-text-field>
                    </span>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <expandNowDatePicker @nowDate="getDate"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <v-col cols='4'></v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-data-table
            class="greyE00-hdr-tbl" height="652" locale="ko"
            :headers="headers" :items="empList" item-key="rn"
            :loading="isLoad" :loading-text="loadMsg"
            :search="dmyTxt" :custom-filter="srchFltr"
            fixed-header disable-pagination hide-default-header hide-default-footer dense>
            <template v-slot:header="{ props }" >
                <thead>
                    <tr>
                        <th v-for="(hdr, l) in props.headers" :key="l">
                            <span>{{ hdr.text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item, index }'>
                <tr>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ index + 1 }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.wrkStNm }}</span>
                    </td>
                    <td class="overflow-text-hidden txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.name }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.jobNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.birth }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <v-img
                            v-if="item.gndr == '여'"
                            class="d-inline-block" style="vertical-align: middle;"
                            src="../../assets/icon/ic-female.svg" width="24">
                        </v-img>
                        <v-img
                            v-else-if="item.gndr == '남'"
                            class="d-inline-block" style="vertical-align: middle;"
                            src="../../assets/icon/ic-male.svg" width="24">
                        </v-img>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.entDt }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.rtrDt }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.wrkAnlDesc }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.anlDys }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.anlUseCnt }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.ddcDys }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.rmnAnl }}</span>
                    </td>
                    <td class="txtAlgnMid px-1">
                        <v-btn class="rounded d-inline-block" @click="onClick(item)" icon>                                
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-list-box-outline</v-icon>
                                </template>
                                <span>{{item.name}} 연차이력</span>
                            </v-tooltip>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        
        <v-dialog v-model="menu[0]" max-width="900" content-class="round" persistent eager>
            <AnlHstr :sltEmpPk="sltEmpPk" :wrtMnt="useMnth" :mode="'rpt'" @modalClose="modalChanger"></AnlHstr>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selBtcInqEmpAnlList } from '../../api/bnfcrStts';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import AnlHstr from '../../components/emp/AnlHstrMdl.vue';

export default {
    name: 'EmpInfSttsTab2',

    props: {
        tab: { type: Number, default: 0 },
        prtCd42: { type: Array, default: () => { return [] } },     // 직종
        prtCd70: { type: Array, default: () => { return [] } },     // 상태
    },
    components: {
        CmmExpnBtn,
        expandNowDatePicker,
        AnlHstr,
    },

    computed: {

    },

    watch: {
        prtCd42: {
            deep: true,
            handler: function (value){
                let arr = [...value]
                if(arr.length > 0)
                    arr.forEach(itm => {
                        itm.isOn = true
                    });
               this.vCd42 = arr
            },
        },
        prtCd70: {
            deep: true,
            handler: function (value){
                let arr = [...value]
                if(arr.length > 0)
                    arr.forEach(itm => {
                        if(itm.valcd == '1')
                            itm.isOn = true
                    });
               this.vCd70 = arr
            },
        },
    },

    created: function () {
   
    },

    mounted: function () {
        this.$nextTick(function () {
            
        })
    },

    methods: {
        xlsxProd: function (){
            this.$parent.$parent.$parent.$parent.exlBtn = true
            let shetNm = '연차사용내역'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.empList, shetNm, title)

            this.$parent.$parent.$parent.$parent.exlBtn = false
        },
        selBtcInqEmpAnlList: function () {
            this.empList.splice(0)
            this.$parent.$parent.$parent.$parent.exlBtn = true
            this.$parent.$parent.$parent.$parent.pdfBtn = true

            let obj = {
                inqYMD: this.date,
                fcltyNum: this.$store.getters.getFcltyNum
            }

            selBtcInqEmpAnlList(obj)
                .then((response) => ( this.selBtcInqEmpAnlListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selBtcInqEmpAnlList : ' + error))
        },
        selBtcInqEmpAnlListAfter: function (res) {
            if(Array.isArray(res)){
                res.forEach(itm => {
                    if(itm.jobNm == null) itm.jobNm = ''
                    if(itm.birth == null) itm.birth = ''
                    if(itm.entDt == null) itm.entDt = ''
                    if(itm.rtrDt == null) itm.rtrDt = ''

                    itm.celphnNm = itm.celphn_1 + ' - ' + itm.celphn_2 + ' - ' + itm.celphn_3
                    //itm.isSelected = false
                });

                this.empList = res

                if(res.length > 0){
                    this.$parent.$parent.$parent.$parent.exlBtn = false
                    this.$parent.$parent.$parent.$parent.pdfBtn = false
                }
            }
        
            this.isLoad = false
        },
        
        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 재직상태 검색
            let entRunChc = this.vCd70.filter( v => v.isOn == true )
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.wrkStNm == itm.valcdnm
                    else field2 = field2 || filter.wrkStNm == itm.valcdnm
                });
            }

            // 직종 검색
            let jobRunChc = this.vCd42.filter( v => v.isOn == true )
            if(jobRunChc.length == 0)
                field3 = false
            else{
                jobRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.jobNm == itm.valcdnm
                    else field3 = field3 || filter.jobNm == itm.valcdnm
                });
            }

            return field1 && field2 && field3
        },
        getDate: function (obj) {
            this.date = obj.date            
            this.selBtcInqEmpAnlList()
        },
        getPickerDate: function (obj) {
            this.mDate = obj
        },
        getYear: function (obj) {
            this.yDate = obj
        },        
        modalChanger: function() {
            this.selBtcInqEmpAnlList()
            this.menu.splice(0, 1, false)
            this.sltEmpPk   = -1
            this.useMnth    = 0
        },
        onClick: function(item) {
            this.useMnth = parseInt( Number(item.wrkMnthCalc))
            this.sltEmpPk = item.empPk            
            this.menu.splice(0, 1, true)
        },
    },
    data: () => ({
        date: 19900101,
        useMnth: 0,
        sltEmpPk:-1,
        isEmpStt: false,
        isJobStt: false,
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        dmyTxt: '',                         // 테이블 반응 더미(검색용)
        srchTxt: '',                        // 테이블 이름 검색 필드
        srchCd: 0,
        menu: [false,],
        empList: [],
        vCd42: [],                          //직원 직종 코드
        vCd70: [],                          //직원 상태 코드
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '근무현황', key: 'wrkStNm', width: 12, },
            { header: '직원명', key: 'name', width: 12, },
            { header: '직종', key: 'jobNm', width: 12, },
            { header: '생년월일', key: 'birth', width: 16, },
            { header: '성별', key: 'gndr', width: 10, },
            { header: '입사일', key: 'entDt', width: 12, },
            { header: '퇴사일', key: 'rtrDt', width: 12, },
            { header: '근무년수', key: 'wrkAnlDesc', width: 16, },
            { header: '발생일수', key: 'anlDys', width: 16, },
            { header: '사용일수', key: 'anlUseCnt', width: 16, },
            { header: '차감일수', key: 'ddcDys', width: 16, },
            { header: '잔여일수', key: 'rmnAnl', width: 16, },
        ],
        headers: [
            { text: '연번', value: 'rn', sortable: true, align: 'center' },
            { text: '근무현황', value: 'wrkStNm', sortable: true, align: 'center' },
            { text: '직원명', value: 'name', sortable: true, align: 'center' },
            { text: '직종', value: 'jobNm', sortable: true, align: 'center' },
            { text: '생년월일', value: 'birth', sortable: true, align: 'center' },
            { text: '성별', value: 'gbdr', sortable: true, align: 'center' },
            { text: '입사일', value: 'entDt', sortable: true, align: 'center' },
            { text: '퇴사일', value: 'rtrDt', sortable: true, align: 'center' },
            { text: '근무년수', value: 'wrkAnlDesc', sortable: true,  align: 'center' },
            { text: '발생일수', value: 'anlDys', sortable: true,  align: 'center' },
            { text: '사용일수', value: 'anlUseCnt', sortable: true, align: 'center' },
            { text: '차감일수', value: 'ddcDys', sortable: true,  align: 'center' },
            { text: '잔여일수', value: 'rmnAnl', sortable: true,  align: 'center' },
            { text: '상세', value: '', sortable: true,  align: 'center' },
        ],
    }),
};
</script>