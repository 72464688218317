<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 align-center" no-gutters>
            <span class="text-h4 font-weight-bold">전자서명 관리</span>
            <v-spacer></v-spacer>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="5">
                <v-divider :class="[edctnSignList.length > 8 ? 'mr-2__5' : '']"></v-divider>
                <v-row :class="['pa-0 nmlBfClrImp-greyE01', edctnSignList.length > 8 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-2" cols="4">
                        <span class="fontOneDotOneRem font-weight-medium">대상자</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-2" cols="4">
                        <span class="fontOneDotOneRem font-weight-medium">구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-2" cols="4">
                        <span class="fontOneDotOneRem font-weight-medium">서명 여부</span>
                    </v-col>
                </v-row>
                <v-divider :class="[edctnSignList.length > 8 ? 'mr-2__5' : '']"></v-divider>
                <div v-if="edctnSignList.length > 0" class="overflow-y-auto" style="width: 100%; height: 279px;">
                    <v-row
                        v-for="(itm, i) in edctnSignList" :key="i"
                        :class="[
                            'tableBB hvr-col',
                            sltEduAtndPk == itm.eduAtndPk ? 'nmlBfClrImp-blue003' : ''
                            ]"
                        @click="tableClickEvent(itm)"
                        no-gutters>
                        <v-col class="txtAlgnMid tableBR py-1" cols="4">
                            <span class="fontOneRem">{{ itm.name }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="4">
                            <span class="fontOneRem">{{ itm.pstnNm }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-1" cols="4">
                            <v-icon v-if="itm.eSigPk > 0">mdi-circle-outline</v-icon>
                            <v-icon v-else>mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else class="tableBB pa-0" no-gutters>
                    <v-col class="txtAlgnMid py-2" cols="12">
                        <span class="fontOneRem grey006--text">※ 목록이 존재하지 않습니다.</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="7" class="pl-3">
                <div v-if="signCanv" class="d-flex justify-center">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" cols="11">
                            <vue-esign
                                class="mt-0 rounded" style="border: 3px solid #4bc5e8;"
                                ref="esign" format="image/png" :width="400" :height="300"
                                :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor">
                            </vue-esign>
                        </v-col>
                        <v-col class="" cols="1">
                            <v-btn color="blueBtnColor" height="30" @click="handleGenerate()" icon>
                                <v-icon>mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                            <v-btn color="grey006" height="30" @click="handleReset()" icon>
                                <v-icon>mdi-eraser</v-icon>
                            </v-btn> 
                        </v-col>
                    </v-row>
                </div>
                <div v-if="signImg" class="d-flex justify-center">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" cols="11">
                            <div class="mt-0 rounded" style="border: 3px solid #4bc5e8;">
                                <v-img :src="imgSrc" :width="imgWidth"></v-img>
                            </div>
                        </v-col>
                        <v-col class="" cols="1">
                            <v-btn color="grey006" height="30" @click="onDelSign()" icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div
                    v-if="sltEduAtndPk == 0"
                    class="d-inline-block d-flex justify-center align-center rounded-lg"
                    style="width: 100%; height: 100%; opacity: 0.7; border: 3px solid #4bc5e8;">
                    <span class="fontOneDotFivRem font-weight-bold red--text">좌측 메뉴에서 선택 후 이용해주세요.</span>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import { remoteURL } from '@/api/baseAxios';
import { uploadSIgn, delESig } from '../../api/index.js';

export default {
    name: 'EdctnJrnlSignMdl',

    props : {
        edurecpk : {type:Number, default:-1}
    },

    components: {
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$refs.esign != undefined) this.$refs.esign.reset()            
            this.sign = ''
            this.signCanv = false
            this.signImg = false 
            this.getEduAtndSigList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.rmEdSignCnFm){
                this.delSignAct()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },
        
    methods: {      
        //교육참석자 전자서명 다건 조회
        getEduAtndSigList:function(){  
            let obj = {
                eduRecPk : this.edurecpk,
            }     
            http.post('emp/selEduAtndSigList', obj)
                .then((response) => ( this.eduAtndSigListRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduAtndSigList : ' + error))
        },
        eduAtndSigListRst:function(res){
            if(res.statusCode == '200'){
                this.edctnSignList = res.result.list

                if(this.sltEduAtndPk > 0){
                    let eSigPk = 0
                    let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)
                    target.forEach(e => {
                        eSigPk = e.eSigPk;
                    });

                    if(eSigPk > 0){
                        this.setSignImg()
                        this.signCanv = false
                        this.signImg = true 
                    }else{
                        this.signCanv = true
                        this.signImg = false 
                    }

                }
                
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        handleReset: function () {
            this.$refs.esign.reset()
            this.sign = ''
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.setSign()
            }).catch(err => {
                alert(err)
            })
        },
        setSign: function () {
            let mmbrPk = 0;
            
            let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)
            target.forEach(e => {
                mmbrPk = e.mmbrPk;
            });
            
            if(mmbrPk > 0){
                //교육기록(eSigClcd): 8
                let obj = {}                
                obj.mmbrPk = mmbrPk
                obj.bzClcdPk = this.edurecpk
                obj.eSigClcd = '8'
                obj.sign = this.sign

                uploadSIgn(obj)
                    .then((response) => ( this.setSignAfter(response.data) ))
                    .catch((error) => console.log('connect error /cmm/uploadSIgn : ' + error))
            }
        },
        setSignAfter:function(res){
            let obj = {}    
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEduAtndSigList()
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },
        tableClickEvent: function(obj){
            if(this.sltEduAtndPk != obj.eduAtndPk){
                this.sltEduAtndPk = obj.eduAtndPk
                if(obj.eSigPk > 0){
                    this.signCanv = false
                    this.signImg = true                
                    this.setSignImg()
                }
                else{
                    this.signCanv = true
                    this.signImg = false
                    this.sign = ''
                    if(this.$refs.esign != undefined) this.$refs.esign.reset()
                }
            }
            else{
                this.sltEduAtndPk = -1
                this.signCanv = false
                this.signImg = false
                this.sign = ''
                if(this.$refs.esign != undefined) this.$refs.esign.reset()
            }
        },
        setSignImg:function(){
            let mpk = 0;
            
            let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)
            target.forEach(e => {
                mpk = e.mmbrPk;
            });

            let path = remoteURL + 'cmm/eSigImgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            let bzClcd = '&eSigClcd=8'
            let bzPk = '&bzClcdPk=' + this.edurecpk
            let mmbrPk = '&mmbrPk=' + mpk
            this.imgSrc = path + fcltyNum + bzClcd + bzPk + mmbrPk + '&t=' + new Date().getTime()
        },        
        onClose: function () {
            this.$emit('modalEvent', 2)
        },
        onDelSign:function(){
            if(this.sltEduAtndPk > 0){

               // let idx = this.edctnSignList.findIndex(x=>x.eduAtndPk === this.sltEduAtndPk)
                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '교육서명 '//+this.edctnSignList[idx].name
                obj.cntnt = ['삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   
    
                this.rmEdSignCnFm = true
            }
        },
        delSignAct:function(){
            let idx = this.edctnSignList.findIndex(x=>x.eduAtndPk === this.sltEduAtndPk)
            if(idx > -1){
                let obj = {}
                obj.eSigPk = this.edctnSignList[idx].eSigPk

                if(obj.eSigPk > 0)
                    delESig(obj)
                        .then((response) => ( this.delSignActAfter(response.data) ))
                        .catch((error) => console.log('connect error /cmm/delESig : ' + error))
            }
        },
        delSignActAfter: function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
            obj.type = 'success'            // 경고 유형 (success, info, warning, error)

            if(res.statusCode == 200)
                this.getEduAtndSigList()
            else{
                obj.tmr = 5000
                obj.type = 'error'
            }
            
            this.$store.commit('setWrnnInf', obj)
        }
    },
    
    data: () => ({
        sltEduAtndPk        : -1,
        edctnSignHeaders    : [
            { text: '대상자', value: '', align: 'center'},
            { text: '구분', value: '', align: 'center'},
            { text: '서명여부', value: '', align: 'center'},
        ],
        edctnSignList       : [],
        sign                :  '',
        lineWidth           : 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor           : '#000000',       //브러시 색상 
        bgColor             : '',              //캔버스 색상
        resultImg           : '',              //생성된 이미지
        isCrop              : false,           //자르기
        signCanv            : false,
        signImg             : false,
        imgSrc              : '',
        imgWidth            : '400',
        rmEdSignCnFm        : false,
    }),
};
</script>