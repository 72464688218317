<template>
    <v-sheet class="ml-0 pt-1" min-width="" max-width="">
        <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
            <v-col cols="6">
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">재난상황대응훈련</span>
                <span class="d-inline-block red--text pl-4" style="width:300px;">※ 반기별 1회 이상 작성</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
                class="white--text mr-2" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor" :disabled="savBtn"
                min-width="20" height="20" small outlined
                @click="preInsEduRec">
                <v-icon class="mr-1" size="14">mdi-content-save-edit-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">저장</span>
            </v-btn>           
            <v-btn
                class="white--text mr-2" style="padding: 9px 7px 8px 5px !important;"
                color="red" :disabled="delBtn"
                min-width="20" height="20" small outlined
                @click="rmEduRec">
                <v-icon class="mr-1" size="14">mdi-trash-can-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">삭제</span>
            </v-btn>
            <v-btn
                class="white--text" style="padding: 9px 7px 8px 5px !important;"
                color="grey006" :disabled="delBtn"
                @click="onPrtEdctn"
                min-width="20" height="20" small outlined>
                <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">출력</span>
            </v-btn>
        </v-row>
        <v-row class="" no-gutters>
            <v-col class="" cols="6">
                <v-divider :class="[tblRowCnt > 18 ? 'mr-2__5' : '']"  color="black"></v-divider>
                <v-row :class="['nmlBfClrImp-greyE01 pa-0', tblRowCnt > 18 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                        <span class="font-weight-medium fontOneDotOneRem">교육대상자</span>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium">교육시기</span>
                            </v-col>
                            <v-col class="" cols="10">
                                <v-row no-gutters>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="6">
                                        <span class="fontOneDotOneRem font-weight-medium">교육명</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                        <span class="fontOneDotOneRem font-weight-medium">교육일자</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid py-1" cols="2">
                                        <span class="fontOneDotOneRem font-weight-medium">참석자수</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider :class="[tblRowCnt > 18 ? 'mr-2__5' : '']"></v-divider>
                <div v-if="empEduList.length > 0" class="overflow-y-auto" style="width: 100%; height: 645px;">
                    <v-progress-linear v-if="this.ldr" class="" color="cyan" indeterminate></v-progress-linear>            
                    <v-row v-for="(pstn, i) in empEduList" :key="i" 
                        class="pa-0" 
                        no-gutters>
                        <v-col class="d-flex align-center justify-center tableBB tableBR px-1" cols="2">
                            <span v-if="pstn.pstnCd == '1'" class="fontOneRem font-weight-medium">
                                수급자
                            </span>
                            <span v-if="pstn.pstnCd == '3'" class="fontOneRem font-weight-medium">
                                직원
                            </span>
                        </v-col>
                        <v-col class="" cols="10">
                            <v-row v-for="(qtr, j) in pstn.qtrList" :key="j"                                 
                                class=""
                                no-gutters>
                                <v-col class="d-flex align-center justify-center tableBB tableBR py-1" cols="2">
                                    {{qtr.eduQtr}} 분기
                                </v-col>
                                <v-col class="" cols="10">
                                    <v-row v-for="(edu, j) in qtr.eduList" :key="j"                                 
                                        :class="[
                                            'pa-0 hvr-col tableBB',
                                            sltPk == edu.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white',
                                            edu.eduRecPk == null ? '' : 'crsShp-Pointer'
                                        ]"
                                        no-gutters>
                                        <v-col @click="rowClicked(edu.eduRecPk)" class="tableBR px-1 text-overflow" style="padding-top: 5px;" cols="6">
                                            <span class="fontNineThRem">{{edu.eduNm}}</span>
                                        </v-col>
                                        <v-col @click="rowClicked(edu.eduRecPk)" class="tableBR px-1" style="padding-top: 5px;" cols="4">
                                            <span v-if="edu.eduDtStrt != null && edu.eduDtStrt != ''" class="fontNineThRem">
                                                {{$moment(edu.eduDtStrt, 'YYYYMMDD').format('YYYY.MM.DD')}}</span>
                                        </v-col>
                                        <v-col @click="rowClicked(edu.eduRecPk)" class="txtAlgnMid py-1" cols="2">
                                            <span class="fontNineThRem">{{edu.eduAtndCnt?.toLocaleString('ko-KR') }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else class="px-2" no-gutters>
                    <v-col class="txtAlgnMid py-2 tableBB" cols="12">
                        <span class="fontOneRem grey006--text">※ 프로그램 제공 현황이 존재하지 않습니다.</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="pl-3" cols="6">
                <v-form ref="edfrm" lazy-validation>
                    <v-divider color="black"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">훈련일시</span>
                        </v-col>
                        <v-col class="pl-2 ma-0 d-flex justify-start align-center" cols="10">                              
                            <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp v-model="items.eduDtStrt" :required="true" :minDt="minDt" :maxDt="maxDt"></CmmDateComp>
                            </span>
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField v-model="items.eduTmStrt" :required="true"></CmmTimeField>                                     
                            </span>
                            <span class="px-2">&#126;</span>
                            <!-- <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp v-model="items.eduDtEnd" :required="true" :minDt="items.eduDtStrt"></CmmDateComp>
                            </span> -->
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField v-model="items.eduTmEnd" :required="true"></CmmTimeField>                                     
                            </span>                    
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>
                    <v-row class="pa-0" no-gutters>                        
                        <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">작성자</span>
                        </v-col>
                        <v-col class="pl-2 py-1 ma-0 d-flex justify-start align-center" cols="10">    
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field
                                    v-model="items.wrtr" color="blue" ref="info" height="28"
                                    :rules="[rules.required, rules.strCheck]"
                                    hide-details outlined readonly dense>
                                </v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                :disabled="!$moment(items.eduDtStrt,'YYYY-MM-DD').isValid()"  
                                @click="$parent.$parent.$parent.$parent.onDialog(items.eduDtStrt,0)"                          
                                >
                                <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                            </v-btn>              
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>                    
                    
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">훈련명</span>
                        </v-col>
                        <v-col class="px-2 py-1 ma-0 d-flex justify-start align-center tableBR" cols="4">    
                            <v-text-field 
                                v-model="items.eduNm" 
                                color="blue" height="28" 
                                :rules="[rules.required]" 
                                hide-details outlined dense></v-text-field>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">훈련장소</span>
                        </v-col>
                        <v-col class="pl-2 ma-0 d-flex justify-start align-center" cols="4">    
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field 
                                    v-model="items.eduLoc" 
                                    color="blue" height="28" 
                                    :rules="[rules.required]" 
                                    hide-details outlined dense></v-text-field>
                            </span>            
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1 nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">참석자</span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="25"  
                                dark
                                style="padding:0px 1px 0px 2px !important"
                                @click="$parent.$parent.$parent.$parent.onDialog(items.eduDtStrt,1,exclsList)"      
                                >
                                <v-icon size="20">mdi-account-multiple</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="px-2 py-1 ma-0 d-flex justify-start align-center posn-rltv" cols="10">
                            <div style="height:60px;overflow-y:auto">
                                <v-chip 
                                    v-for="(list, j) in exclsList" :key="j" 
                                    class="pa-2 mr-1"
                                    close
                                    label                            
                                    color="blue"
                                    outlined
                                    small           
                                    @click:close="rmAtndRow(list, j)"
                                    >
                                    {{list.name}}
                                </v-chip>
                            </div>
                            <span style="position:absolute;bottom:9px;right:20px;">
                                <v-btn
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="28" height="28"
                                    outlined
                                    @click="dialog.splice(1,1,true)"
                                    dark small>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                        </template>
                                        <span>확대</span>
                                    </v-tooltip>
                                </v-btn>
                            </span> 
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>                    
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1 nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">훈련내용</span>
                        </v-col>
                        <v-col class="px-2 py-1 ma-0 d-flex justify-start align-center posn-rltv" cols="10">
                            <v-textarea
                                v-model="items.eduCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="13" outlined dense hide-details no-resize>
                            </v-textarea>
                            <span style="position:absolute;bottom:9px;right:20px;">
                                <v-btn
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="28" height="28"
                                    outlined
                                    dark small
                                    @click="onCmnts('eduCntnt')">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                        </template>
                                        <span>확대</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>   
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1 pl-2 nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">사진첨부</span>
                            <v-file-input 
                                class="pl-1"
                                v-model="images" 
                                :accept="imgAccept"
                                dense hide-details truncate-length="5" 
                                multiple counter
                                prepend-icon="mdi-camera-outline"
                                hide-input 
                                @change="upFile('img')"
                                ></v-file-input>
                        </v-col>
                        <v-col class="px-2 ma-0 d-flex justify-start align-center posn-rltv" cols="10">
                            <div class="d-flex justify-start align-center" style="height:100px;overflow-y:auto">
                                <span class="ma-1 d-inline-block" v-for="(item,i) in imageRes" :key="i">
                                        <imageCard 
                                            ref="imageCard" 
                                            :imgSrc="item.src" 
                                            :imgWidth="'100'" 
                                            @isEvent="rmFile(item, 'img', i)" 
                                            @onDown="$parent.$parent.$parent.$parent.fileDownload(item)"></imageCard>
                                    </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1 pl-2 nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                            <span class="font-weight-medium fontOneDotOneRem">자료첨부</span>
                            <v-file-input 
                                v-model="files" 
                                dense hide-details truncate-length="5" 
                                multiple counter
                                hide-input                                         
                                outlined 
                                @change="upFile('file')"
                                ></v-file-input>
                        </v-col>
                        <v-col class="px-2 ma-0 d-flex justify-start align-center posn-rltv" cols="10">
                            <div class="d-flex justify-start align-center" style="height:100px;overflow-y:auto">
                                <v-chip v-for="(item, j) in fileRes" :key="j"                        
                                    label                        
                                    close
                                    class="ma-1"
                                    outlined
                                    @click:close="rmFile(item, 'file', j)"
                                    @click="$parent.$parent.$parent.$parent.fileDownload(item)"
                                >
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    {{ item.orgFileNm }}
                                </v-chip> 
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>
                </v-form>
            </v-col>
        </v-row>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <CmntsMdl v-if="dialog[0]" @isClose="dialog.splice(0, 1, false)" @outText="setDtldGls" :mdlTtl = "mdlTtl" :mdlCmnts="mdlCmnts" :hSize='30'></CmntsMdl>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" content-class="round" persistent max-width="800">
                <AtndMdl v-if="dialog[1]" @isClose="dialog.splice(1, 1, false)" :mdlTtl = "'참석자 상세보기'" :atndList="exclsList"></AtndMdl>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';
import { remoteURL } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

import CmntsMdl from '../commons/CmntsMdl.vue';
import AtndMdl from '../commons/AtndMdl.vue';

import imageCard from '../commons/imageCard.vue';

export default {
    name: 'EdctnJrnlSilvCareRprt',
    
    props : {           
       tab : { type: Number, default: 0 },
    },

    components: {  
        CmmDateComp,
        CmmTimeField,
        CmntsMdl,
        AtndMdl,
        imageCard,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.$parent.$parent.$parent.$parent.wrtr       = ''
            this.$parent.$parent.$parent.$parent.wrtrMmbrPk = -1
            this.$parent.$parent.$parent.$parent.exclsChn = 0
            this.getEmpEduList()
        })
    },

    computed: {
        getInqYYYY: function () {
            return this.$parent.$parent.$parent.$parent.yyyy
        },
        getWrtrMmbrPk: function () {
            return this.$parent.$parent.$parent.$parent.wrtrMmbrPk
        },
        getExclsChn: function () {
            return this.$parent.$parent.$parent.$parent.exclsChn
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{ 
        getInqYYYY: function () {
            this.getEmpEduList()
            this.minDt = this.$parent.$parent.$parent.$parent.yyyy+'-01-01'
            this.maxDt = this.$moment(this.$parent.$parent.$parent.$parent.yyyy+'1201','YYYYMMDD').endOf('month').format('YYYY-MM-DD')
        },
        getWrtrMmbrPk :{
            deep: true,
            handler: function (value){  
                this.items.wrtrMmbrPk   =  value
                this.items.wrtr         =  this.$parent.$parent.$parent.$parent.wrtr
            }
        },
        getExclsChn :{
            deep: true,
            handler: function (){  
                this.setAplyList(this.$parent.$parent.$parent.$parent.exclsArr)                
            }
        },        
        getSubEvt: function (bol) {
            if(bol && this.rmEdJrnlCnFm){
                this.delEduRec()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }

            if(bol && this.rmEdJrnlAtndCnFm){
                this.delEduAtnd()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }

            if(bol && this.rmEdJrnlFileCnFm){
                this.delAtchdFile()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },
        
    methods: {
        // 직원교육 다건 조회
        getEmpEduList: function (){    
            this.ldr        = true
            this.tblRowCnt  = 0
            this.empEduList.splice(0)

            let obj = {
                inqYYYY     : this.$parent.$parent.$parent.$parent.yyyy,
                eduTypcd    : '703',
            }

            http.post('emp/selEduRecList', obj)
                .then((response) => ( this.empEduListRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRecList : ' + error))
        },

        //직원 교육기록 단건 조회
        getEduRecInfo: function (){           
            this.frmReset()
            if(this.sltPk > 0 )  { 
                let obj = {
                    eduRecPk: this.sltPk
                }
    
                http.post('emp/selEduRecInfo', obj)
                    .then((response) => ( this.eduRecInfoRst(response.data) ))
                    .catch((error) => console.log('connect error /emp/selEduRecInfo : ' + error))
            } 
        },

        //직원 교육기록 참가자 조회
        getEduAtndList: function (){    
            this.ldr        = true
            this.eduAtndList.splice(0)
            this.exclsList.splice(0)       
            if(this.sltPk > 0 )  { 
                let obj = {
                    eduRecPk: this.sltPk
                }
    
                http.post('emp/selEduAtndList', obj)
                    .then((response) => ( this.prevRst(response.data) ))
                    .catch((error) => console.log('connect error /emp/selEduAtndList : ' + error))
            } 
        },

        //첨부파일 조회
        getAtchdFileList: function (){                
            this.files      = null
            this.images     = null
            this.fileRes.splice(0)
            this.imageRes.splice(0) 

            if(this.sltPk > 0 )  { 
                let obj = {
                    bzPk    :this.sltPk,
                    bzClcd  :'10'
                }
    
                http.post('opr/selAtchdFileList', obj)
                    .then((response) => ( this.atchdFileListRst(response.data) ))
                    .catch((error) => console.log('connect error /opr/selAtchdFileList : ' + error))
            } 
        },

        //참석자 삭제
        delEduAtnd:function(){
            if(this.sltAtndPk > 0){                
                let obj = {
                    eduAtndPk: this.sltAtndPk
                }
                http.post('emp/delEduAtnd', obj)
                    .then((response) => ( this.delEduAtndRst(response.data) ))
                    .catch((error) => console.log('connect error /emp/delEduAtnd : ' + error))
            }   
        },

        //첨부파일 삭제
        delAtchdFile:function(){
            if(this.sltAtchdFilePk > 0){  
                let obj = {
                    atchdFilePk: this.sltAtchdFilePk
                }
                http.post('opr/delAtchdFile', obj)
                    .then((response) => ( this.delAtchdFileRst(response.data) ))
                    .catch((error) => console.log('connect error /emp/delAtchdFile : ' + error))
            }
        },

        //임시첨부파일 업로드
        upFile: function (type) {            
            let formData    = new FormData();
            let bl          = false
            this.tmpType    = type

            if(type == 'file') {
                if(this.files != null){
                    this.files.forEach(element => {
                        formData.append("files", element)
                    })
                }

                if(this.files.length > 0) {
                    bl = true
                    formData.append("files", this.files);
                }
            }
            else if(type == 'img'){
                if(this.images != null){
                    this.images.forEach(element => {
                        formData.append("files", element)
                    })

                    if(this.images.length > 0) {
                        bl = true
                        formData.append("files", this.images);
                    }

                }
            }

            if(bl){
                http.post('opr/tmpFileUpload', formData, { headers: { 'Content-Type': 'multipart/form-data' }, })
                    .then((response) => ( this.upFileRst(response.data) ))
                    .catch((error) => console.log('connect error /opr/tmpFileUpload : ' + error))
            }
            
            
        },

        //교육기록 저장
        setEduRec : function(obj){
            http.post('emp/insEduRec', obj)
                    .then((response) => ( this.setEduRecRst(response.data) ))
                    .catch((error) => console.log('connect error /emp/insEduRec : ' + error))
        },

        delEduRec:function(){

            let obj = {
                eduRecPk:this.sltPk,
            }

            http.post('emp/delEduRec', obj)
                    .then((response) => ( this.delEduRecRst(response.data) ))
                    .catch((error) => console.log('connect error /emp/delEduRec : ' + error))
        },

        empEduListRst:function(res){
            if(res.statusCode == '200'){                
                res.result.list.forEach(el => {
                    el.qtrList.forEach(itm => {                        
                        this.tblRowCnt  += itm.eduList.length
                    });
                    this.empEduList.push(el)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.ldr = false
        },

        eduRecInfoRst:function(res){
            
            if(res.statusCode == '200'){
                if(res.result.eduInfo != null){
                    //교육기록 초기화
                    this.items.eduDtStrt    = this.$moment(res.result.eduInfo.eduDtStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.items.eduTmStrt    = res.result.eduInfo.eduTmHhStrt + ':'+ res.result.eduInfo.eduTmMmStrt
                    this.items.eduTmEnd     = res.result.eduInfo.eduTmHhEnd + ':'+ res.result.eduInfo.eduTmMmEnd
                    this.items.eduClcd      = res.result.eduInfo.eduClcd
                    this.items.wrtr         = res.result.eduInfo.wrtr
                    this.items.wrtrMmbrPk   = res.result.eduInfo.wrtrMmbrPk
                    this.items.eduSubjCd    = res.result.eduInfo.eduSubjCd
                    this.items.eduNm        = res.result.eduInfo.eduNm
                    this.items.instrNm      = res.result.eduInfo.instrNm
                    this.items.orgnz        = res.result.eduInfo.orgnz
                    this.items.eduLoc       = res.result.eduInfo.eduLoc
                    this.items.eduMthdCd    = res.result.eduInfo.eduMthdCd
                    this.items.eduCntnt     = res.result.eduInfo.eduCntnt
                    this.items.rmrks        = res.result.eduInfo.rmrks

                    this.eduClcd            = res.result.eduInfo.eduClcd
                    this.eduSubjCd          = res.result.eduInfo.eduSubjCd

                    if(res.result.atndList != null){
                        this.eduAtndListRst(res.result.atndList)
                    }

                    if(res.result.files != null){
                        this.atchdFileListRst(res.result.files)
                    }

                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.ldr = false
        },

        //결과 반영
        prevRst:function(res){
            this.ldr        = false
            if(res.statusCode == '200'){
                this.eduAtndListRst(res.result)
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //참석자 결과 반영
        eduAtndListRst:function(res){  
            this.eduAtndList = res
            this.eduAtndList.forEach(e => {

                let obj = {
                    eduAtndPk:e.eduAtndPk,
                    name:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:(e.pstnCd=='1')?'1':'2'
                }

                this.exclsList.push(obj)
            });
        },

        //첨부파일 결과 반영
        atchdFileListRst:function(res){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            res.forEach(x=>{
                if(x.fileKndCd == "2"){
                    x.src = remoteURL + 'opr/imgView?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                }else{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]  
                    this.fileRes.push(x)
                }
            })
        },

        //참가자 삭제 결과 반영
        delEduAtndRst:function(res){
            let obj = {}    
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEduAtndList()
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
            this.sltAtchdFilePk = -1
        },

        //첨부파일 삭제 결과 반영
        delAtchdFileRst:function(res){
            let obj = {}    
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getAtchdFileList()
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
            this.sltAtndPk = -1
        },

        //임시첨부파일 결과 반영
        upFileRst:function(res){
            if(this.tmpType == 'file') {
                res.files.forEach(x=>{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]
                    this.fileRes.push(x)
                })
    
                this.files = null
            }
            else if(this.tmpType == 'img') {
                res.files.forEach(x=>{
                    x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                })       

                this.images = null
            }
        },

        //교육저장 결과반영
        setEduRecRst:function(res){
            let obj = {}    
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEmpEduList()

                if(this.sltPk == -1) this.frmReset()
                else this.getEduRecInfo()
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },
        
        delEduRecRst:function(res){
            let obj = {}    
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEmpEduList()
                this.frmReset()
                this.sltPk == -1
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },

        rowClicked:function(pk){
            this.delBtn = true

            if(this.sltPk == pk){
                this.frmReset()  
                this.sltPk = -1
            }else{
                this.sltPk = pk   
                if(this.sltPk != null){                    
                    this.delBtn = false
                    this.getEduRecInfo()
                }else{
                    this.sltPk  = -1
                    this.frmReset()
                }
            }
        },        
        frmReset:function(){
            //작성자 초기화
            this.eduClcd            = null
            this.eduSubjCd          = null

            //교육기록 초기화
            this.items.eduDtStrt    = ''
            this.items.eduTmStrt    = ''
            this.items.eduTmEnd     = ''
            this.items.eduClcd      = ''
            this.items.wrtr         = ''
            this.items.wrtrMmbrPk   = -1
            this.items.eduSubjCd    = ''
            this.items.eduNm       = ''
            this.items.instrNm     = ''
            this.items.orgnz       = ''
            this.items.eduLoc      = ''
            this.items.eduMthdCd   = ''
            this.items.eduCntnt    = ''
            this.items.rmrks       = ''
            this.eduAtndList.splice(0)
            this.exclsList.splice(0)
            //첨부파일 초기화
            this.files             = null
            this.fileRes.splice(0)
            this.images            = null
            this.imageRes.splice(0)

        },
        //세부내용 확대
        onCmnts:function(typ){
            this.mdlTyp = typ
            this.mdlTtl     = (typ == 'eduCntnt')?'교육내용':'비고'
            this.mdlCmnts   = (typ == 'eduCntnt')? this.items.eduCntnt:this.items.rmrks

            this.dialog.splice(0, 1, true)
        },
        //세부내용 설정
        setDtldGls:function(txt){
            if(this.mdlTyp == 'eduCntnt'){
                this.items.eduCntnt = txt
            }else{
                this.items.rmrks = txt
            }
        },

        //참석자 설정
        setAplyList: function (arr){

            arr.forEach(e => {  
                let mmbrpk = 0
                let cd = ''

                switch (e.dtTyp) {
                    case 'bnfcr':
                        cd = '1'
                        mmbrpk = e.bnMmbrPk
                        break;
                    case 'emp':
                        cd = '2'
                        mmbrpk = e.mmbrPk
                        break;
                    default:
                        break;
                }            

                if(this.exclsList.findIndex( v => v.mmbrpk == mmbrpk) == -1){
                    let obj = {
                        name:e.name,
                        mmbrpk:mmbrpk,
                        disChekr:false,
                        checkr:cd
                    }

                    this.exclsList.push(obj)
                }

            });            
        },

        //참석자 삭제
        rmAtndRow:function(itm, j){                    
            if(itm.eduAtndPk != null && itm.eduAtndPk != undefined) {
                this.sltAtndPk  = itm.eduAtndPk
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '참석자 '+itm.name
                obj.cntnt = ['삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   
    
                this.rmEdJrnlAtndCnFm = true
            } 
            else 
                this.exclsList.splice(j, 1)
        },

        //첨부파일 삭제
        rmFile:function(itm, type, idx){
            if(itm.atchdFilePk != undefined) {
                this.sltAtchdFilePk  = itm.atchdFilePk
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '첨부파일 '+itm.orgFileNm
                obj.cntnt = ['삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   
    
                this.rmEdJrnlFileCnFm = true
            } 
            else {
                if(type == 'img') this.imageRes.splice(idx,1)
                if(type == 'file') this.fileRes.splice(idx,1)
            }
        },

        //교육기록 삭제
        rmEduRec:function(){
            if(this.sltPk > 0) {                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '직원교육 '+this.items.eduNm
                obj.cntnt = ['삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   
    
                this.rmEdJrnlCnFm = true
            } 
        },

        preInsEduRec:function(){
            if(this.isEduRecValid()){
                let obj = {                    
                    eduRecPk:this.sltPk,
                    eduTypcd:'703',
                    eduClcd:this.eduClcd,
                    eduSubjCd:this.eduSubjCd,
                    eduNm:this.items.eduNm,
                    instrNm:this.items.instrNm,
                    eduDtStrt:this.$moment(this.items.eduDtStrt, 'YYYY-MM-DD').format('YYYYMMDD'),
                    eduTmHhStrt:'',
                    eduTmMmStrt:'',
                    eduDtEnd:this.$moment(this.items.eduDtStrt, 'YYYY-MM-DD').format('YYYYMMDD'),
                    eduTmHhEnd:'',
                    eduTmMmEnd:'',
                    orgnz:this.items.orgnz,
                    eduLoc:this.items.eduLoc,
                    eduMthdCd:this.items.eduMthdCd,
                    eduCntnt:this.items.eduCntnt,
                    wrtr:this.items.wrtr,
                    atndList:[],
                    files:[],
                }

                if(this.items.eduTmStrt !='' ) {
                    let eduTmStrt = this.items.eduTmStrt.split(":")
                    obj.eduTmHhStrt = eduTmStrt[0]
                    obj.eduTmMmStrt = eduTmStrt[1]
                }

                if(this.items.eduTmEnd !='' ) {
                    let eduTmEnd = this.items.eduTmEnd.split(":")
                    obj.eduTmHhEnd = eduTmEnd[0]
                    obj.eduTmMmEnd = eduTmEnd[1]
                }

                this.imageRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        obj.files.push(x)
                    }
                })

                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        x.fileKndCd = '1'
                        obj.files.push(x)
                    }
                })

                this.exclsList.forEach(x => {
                    let atnd = {                        
                        atndNm:x.name,
                        mmbrPk:x.mmbrpk,
                        eduAtndPk:x.eduAtndPk,
                        pstnCd:(x.checkr=='1')?x.checkr:'3',
                    }
                    
                    obj.atndList.push(atnd)
                });
                this.setEduRec(obj)
            } 
            else this.$refs.edfrm.validate()
        },

        //교육기록 저장 유효성 검사
        isEduRecValid:function(){
            let resrt = true

            if(resrt && (this.items.eduDtStrt == '' || this.items.eduDtStrt == null)) {
                this.onAlrt('훈련일시을 입력해주세요!')
                resrt = false
            }

            if(resrt && (this.items.eduTmStrt == '' || this.items.eduTmStrt == null)) {
                this.onAlrt('훈련일시을 입력해주세요!')
                resrt = false
            }

            if(resrt && (this.items.eduTmEnd == '' || this.items.eduTmEnd == null)) {
                this.onAlrt('훈련일시을 입력해주세요!')
                resrt = false
            }

            if(resrt && (this.items.wrtr == ''  || this.items.wrtr == null)) {
                this.onAlrt('작성자를(을) 선택주세요!')
                resrt = false
            }

            if(resrt && (this.items.eduNm == ''  || this.items.eduNm == null)) {
                this.onAlrt('훈련명을 입력주세요!')
                resrt = false
            }

            if(resrt && (this.items.eduLoc == ''  || this.items.eduLoc == null)) {
                this.onAlrt('훈련장소을 입력주세요!')
                resrt = false
            }

            return resrt
        },

         //메세지 발송
         bizSend:function(){
            if(this.sltPk > 0){
                this.$parent.$parent.$parent.$parent.sltEduPk = this.sltPk
                this.$parent.$parent.$parent.$parent.eCntnt     = this.items.eduNm
                this.$parent.$parent.$parent.$parent.eSndData.splice(0)

                this.eduAtndList.forEach(item => {
                    this.$parent.$parent.$parent.$parent.eSndData.push(item.mmbrPk)
                });

                this.$parent.$parent.$parent.$parent.onDialog('',2)
            }
        },

        //교육 서명 모달 오픈
        onSignMdl:function(val){
            if(val > 0){
                
                if(this.sltPk != val){
                    this.rowClicked(val)
                } 
                this.$parent.$parent.$parent.$parent.sltEduPk = this.sltPk
                this.$parent.$parent.$parent.$parent.onDialog('',3)
            } else {
                this.onAlrt('대상을 찾을 수 없습니다.')
            }
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
        
        //출력
        onPrtEdctn:function(){
            if(this.sltPk > 0){
                this.$parent.$parent.$parent.$parent.prtEdctnJrnl('81', this.sltPk)
            } else 
                this.onAlrt('교육을 선택해주세요!')
            
        }
    },
    
    data: () => ({
        minDt           : '',
        maxDt           : '',
        ldr             : false,
        sltPk           : -1,
        sltAtndPk       : -1,
        sltAtchdFilePk  : -1,
        empEduList      : [],
        tblRowCnt       : 0, 
        savBtn          : false,
        delBtn          : true,
        rmEdJrnlCnFm    : false,
        rmEdJrnlAtndCnFm: false,
        rmEdJrnlFileCnFm: false,
        items       : {
            eduDtStrt   : '',
            eduTmStrt   : '',
            eduTmEnd    : '',
            eduClcd     : '',
            wrtr        : '',
            wrtrMmbrPk  : -1,
            eduSubjCd   : '',
            eduNm       : '',
            instrNm     : '',
            orgnz       : '',
            eduLoc      : '',
            eduMthdCd   : '',
            eduCntnt    : '',
            rmrks       : '',
        },
        eduClcd         : null,
        eduSubjCd       : null,
        eduAtndList     : [],
        exclsList       : [],
        tmpType         : '',
        files           : null,
        fileRes         : [],
        images          : null, 
        imageRes        : [],
        imgAccept       : 'image/*', 
        dialog          : [false,false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>