<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-form ref="mdlform" lazy-validation>
            <v-row class="pa-0 justify-center align-center" no-gutters>
                <span class="text-h4 font-weight-bold">프로그램&nbsp;수급자&nbsp;그룹&nbsp;관리</span>
                <v-spacer></v-spacer>
                <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
            </v-row>
            <v-divider class="white my-2"></v-divider>
            <v-row class="pa-0 align-center justify-end" no-gutters>
                <span class="fontOneRem grey006--text" style="margin-top: 6px;">※ 수급자가 포함된 수급자 그룹은 삭제 불가합니다.</span>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!isUpd"
                    class="white--text" height="30" color="blueBtnColor"
                    @click="onAction"
                    rounded>
                    <v-icon small>mdi-check</v-icon>
                    선택완료
                </v-btn>
                <span v-else class="d-inline-block" style="width: 94px; height: 24px;">
                    <v-checkbox
                        v-model="incldUnsd" max-width="20"
                        @change="rflUnsd"
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                        true-value="1" false-value="2" label="미사용 포함"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="nmlBfClrImp-greyE01 pa-0" no-gutters>
                <v-col v-if="!isUpd" class="txtAlgnMid tableBR py-1" style="" cols="1">
                    <span class="d-inline-block pl-1" style="width: 32px; height: 28px;">
                        <v-checkbox
                            v-if="isMltSlt"
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="allChecker"
                            :readonly="allReadonly" @change="allChk" hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col v-else class="txtAlgnMid tableBR py-1" style="" cols="1">
                    <span class="fontOneDotOneRem font-weight-bold">사용여부</span> 
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span> 
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                    <span class="fontOneDotOneRem font-weight-bold">수급자 그룹명</span> 
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="5">
                    <span class="fontOneDotOneRem font-weight-bold">수급자 그룹 설명</span> 
                </v-col>
                <v-col class="d-flex align-center justify-center pa-1" cols="1">
                    <v-btn
                        v-if="isUpd"
                        class="" color="blue200" height="26"
                        @click="addRow()"
                        icon>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <div v-if="items.length > 0">
                <v-row v-for="(list, i) in items" :key="i" class="pa-0 tableBB" no-gutters>
                    <v-col v-if="!isUpd" class="txtAlgnMid tableBR py-1" cols="1">
                        <span class="d-inline-block pl-1" style="width: 32px; height: 28px;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="list.chkyn" @click="sngChc(i)"
                                :key="forceRender"
                                @change="selChk"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                    <v-col v-else class="txtAlgnMid tableBR py-1" cols="1">
                        <span class="d-inline-block pl-1" style="width: 32px; height: 28px;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="list.useYn" :key="forceRender"
                                true-value="1" false-value="2"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR pa-1" cols="2">
                        <v-select
                            v-if="isUpd"
                            v-model="list.idxCd"
                            :items="vCd40" :rules="[rules.required]"
                            item-text="valcdnm" item-value="valcd"
                            :disabled="list.usedCnt > 0"
                            dense hide-details outlined>
                        </v-select>
                        <span v-else class="fontOneRem">{{ list.idxNm }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR pa-1" cols="3">
                        <v-text-field
                            v-if="isUpd"
                            v-model="list.bnfcrgrNm" :rules="[rules.required]"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                        <span v-else class="fontOneRem">{{ list.bnfcrgrNm }}</span>
                    </v-col>
                    <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="5">
                        <v-text-field
                            v-if="isUpd"
                            v-model="list.bnfcrgrDesc" :rules="[rules.required]"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                        <span v-else class="fontOneRem">{{ list.bnfcrgrDesc }}</span>
                    </v-col>
                    <v-col v-if="isUpd" class="d-flex align-center justify-center py-1" cols="1">
                        <v-btn
                            class="" color="blue200" height="26"
                            @click="onSave(i)"
                            icon>
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="list.prgBnfcrgrInfPk > 0 && list.usedCnt == 0"
                            class="" color="grey006" height="26"
                            @click="onDelete(i)"
                            icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn
                            v-else-if="list.prgBnfcrgrInfPk < 1"
                            class="" color="grey006" height="26"
                            @click="onDelete1(i)"
                            icon>
                            <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-row v-else class="tableBB pa-0" no-gutters>
                <v-col class="txtAlgnMid py-2" cols="12">
                    <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import { selPrgBnfcrgrInfList, insPrgBnfcrgrInf, delPrgBnfcrgrInf } from '../../api/prgrm.js';

export default {
    name: 'PrgrmgrMultiSelector',

    props: {
        prgInfPk: { type: Number, default: 0 },
        prtWrtDt: { type: String, default: '' },
        isMltSlt: { type: Boolean, default: true },
        isUpd: { type: Boolean, default: true },
        idxFltr: { type: String, default: '' },
        prgBnfcrgrChkItems: { type:Array, default: () => { return [] } }, 
        prgBnfcrgrNoItems : { type:Array, default: () => { return [] } }, 
    },

    components: {

    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selPrgBnfcrgrInfList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        'prgInfPk': function () {
            this.selPrgBnfcrgrInfList()
        },
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                if (this.items[this.key].prgBnfcrgrInfPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgBnfcrgrInfPk: this.items[this.key].prgBnfcrgrInfPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgBnfcrgrInf(obj)
                        .then((response) => (this.delPrgBnfcrgrInfAfter(response.data)))
                        .catch((error) => console.log(error))
                }
                else 
                    this.items.splice(this.key, 1)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
           
                this.runAble = '0'
            }
        },
    },

    methods: {
        onShow(){
            // this.getBnfcrList()
            this.prgBnfcrgrDummyList = Object.assign([], this.bnfcrList)      

            if(this.prgBnfcrgrChkItems != null){
                if(this.prgBnfcrgrChkItems.length > 0)
                    this.setReadonlys(this.prgBnfcrgrChkItems)
            }

            if(this.prgBnfcrgrNoItems != null){
                if(this.prgBnfcrgrNoItems.length > 0)
                    this.setNoDisplay(this.prgBnfcrgrNoItems)
            }
        },
        selPrgBnfcrgrInfList: function () {
            selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum, this.prgInfPk, this.useYn)
                .then((response) => (this.selPrgBnfcrgrInfListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgBnfcrgrInfList : ' + error))
        },
        selPrgBnfcrgrInfListAfter: function (res) {
            if(res.statusCode == '200'){
                this.vCd40 = res.result.code.vCd40
                res.result.list.forEach((item) => {
                    let chc = res.result.code.vCd40.findIndex( v => v.valcd == item.idxCd )
                    if(item.idxCd == null) item.idxCd = ''
                    if(chc > -1) item.idxNm = res.result.code.vCd40[chc].valcdnm
                    item.chkyn = false
                });

                // 
                if(this.idxFltr == '')
                    this.items = res.result.list
                else
                    this.items = res.result.list.filter( v => v.idxCd == this.idxFltr )
            }

            this.onShow()
        },
        onSave(key) {
            if (this.$refs.mdlform.validate()) {
                let chc = this.vCd40.findIndex( v => v.valcd == this.items[key].idxCd )
                let obj = {
                    bnfcrgrDesc: this.items[key].bnfcrgrDesc,
                    bnfcrgrNm: this.items[key].bnfcrgrNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgBnfcrgrInfPk: this.items[key].prgBnfcrgrInfPk,
                    idxCd: this.items[key].idxCd,
                    useYn: this.items[key].useYn,
                    userNm : this.$store.getters.getUserNm,
                }
                if(chc > -1) obj.idxNm = this.vCd40[chc].valcdnm

                insPrgBnfcrgrInf(obj)
                    .then((response) => (this.insPrgBnfcrgrInfAfter(response.data)))
                    .catch((error) => console.log(error))
            }else{
                let obj = {}
                obj.actv = true                         // 경고 노출여부
                obj.tmr = 2500                          // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                    // 경고 유형 (success, info, warning, error)
                obj.title = '필수 항목을 입력해주세요.'     // 경고 타이틀
                obj.cntnt = []                          // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        insPrgBnfcrgrInfAfter(res) {
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200) {
                this.selPrgBnfcrgrInfList()
                this.onShow()
            }
            else {
                obj.tmr = 5000
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        onDelete1(key) {
            this.items.splice(key, 1)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key

                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = this.items[this.key].bnfcrgrNm
                obj.cntnt = ['프로그램 유형을 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.runAble = '2'
            } else {
                if (key != 0)
                    this.items.splice(key, 1)
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items[this.key].prgBnfcrgrInfPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgBnfcrgrInfPk: this.items[this.key].prgBnfcrgrInfPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgBnfcrgrInf(obj)
                        .then((response) => (this.delPrgBnfcrgrInfAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items.splice(this.key, 1)
                }
            }
        },
        delPrgBnfcrgrInfAfter(res) {
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200) {
                this.selPrgBnfcrgrInfList()
                this.onShow()
                this.$emit('reload', true)

            } else {
                obj.tmr = 5000
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        onAction: function () {
            let selected = []
            
            this.items.forEach(x => {
                let chc = this.vCd40.findIndex( v => v.valcd == x.idxCd )
                if(chc > -1) x.idxNm = this.vCd40[chc].valcdnm

                if (x.chkyn) 
                    selected.push(x)
            })
            this.$emit('outEmp', selected)
            this.onClose()
        },

        onClose: function () {
            this.allChecker = false
            this.items.forEach(x=>{
                x.chkyn = false
            })
            ++this.forceRender
            this.$emit('isClose', '.')
        },

        expandClose: function () {

            this.$refs.exBtnClose.isClose()

        },

        allChk: function () {
            this.items.forEach(x => {
                if (this.allChecker) {
                    x.chkyn = true
                } else {
                    x.chkyn = false
                }
            })
        },
        selChk: function () {
            let chk = true
            this.items.forEach(x => {
                if (!x.chkyn) {
                    chk = false
                }
            })
            this.allChecker = chk
        },
        addRow() {
            let idx = this.items.length - 1
            let bldNm = ''

            if (idx > -1) {
                bldNm = this.items[idx].bldNm
            }

            let addObj = {
                bldFlr: '',
                bldNm: bldNm,
                bnfcrgrNm: null,
                bnfcrgrdesc: null,
                fcltyNum: '',
                bldInfPk: 0,
                useYn: '1',
            }
            this.items.push(addObj)
        },
        setReadonlys:function(prgGrInfPks){    
            this.items.forEach((x)=>{
                let bl = prgGrInfPks.includes(x.prgBnfcrgrInfPk)
                if(bl){
                    x.chkyn = true
                } 
            })
            ++this.forceRender
            this.selChk()
        },
        setNoDisplay:function(prgGrInfPks){
            this.allReadonly = false
            this.prgBnfcrGrDummyList.splice(0)

            this.items.forEach(x=>{
                let bl = prgGrInfPks.includes(x.prgBnfcrgrInfPk)
                if(!bl){
                    this.prgDummyList.push(x)
                }  
            })            
        },
        // 미사용 포함 라디오 이벤트
        rflUnsd: function(){
            if( this.incldUnsd == '1' )
                this.useYn = null
            else
                this.useYn = '1'

            this.selPrgBnfcrgrInfList()
        },

        // 단일선택모드 제어
        sngChc: function(idx){
            if(this.isMltSlt)
                return

            this.items?.forEach(itm => {
                itm.chkyn = false
            });

            this.items[idx].chkyn = true
        },
    },

    data: () => ({
        vCd40: [],
        items: [],
        useYn: '1',
        incldUnsd: '2',
        wrtDt: '',
        field: '',
        runAble: '0',
        allChecker: false,
        allReadonly: false,
        forceRender: 0,
        exBtn: [
            { index: 0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], }
        ],
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>