<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1360" max-width="1360" height="835">
        <v-row class="pa-0 align-center" no-gutters>
            <v-col class="" cols="2">
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">7-4. 교육&nbsp;및&nbsp;훈련일지</span>
            </v-col>
            <v-spacer></v-spacer>
            <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-col class="" cols="2"></v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-tabs
            v-model="tab" class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{ item.tab }}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab" :key="forceRender">            
            <v-tab-item transition="fade-transition">
                <edctnJrnlEmpRprt ref="edctnJrnlEmpRprt" v-if="tab==0"></edctnJrnlEmpRprt>
            </v-tab-item>
            <v-tab-item transition="fade-transition">
                <edctnJrnlSilvCareRprt ref="edctnJrnlSilvCareRprt" v-if="tab==1"></edctnJrnlSilvCareRprt>
            </v-tab-item>     
            <v-tab-item transition="fade-transition">
                <edctnJrnlDssRes ref="edctnJrnlDssRes" v-if="tab==2"></edctnJrnlDssRes>
            </v-tab-item>    
            <v-tab-item v-if="oldYn" transition="fade-transition">                
                <empwrMmt ref="empwrMmt" v-if="tab==3"></empwrMmt>
            </v-tab-item> 
            <v-tab-item v-if="!oldYn" transition="fade-transition">
                <edctnEndOfCare ref="edctnEndOfCare" v-if="tab==3"></edctnEndOfCare>                
            </v-tab-item>
            <v-tab-item v-if="!oldYn" transition="fade-transition">                
                <edctnOralHthCare ref="edctnOralHthCare" v-if="tab==4"></edctnOralHthCare>
            </v-tab-item>
            <v-tab-item v-if="!oldYn" transition="fade-transition">                
                <empStresDsltn ref="empStresDsltn" v-if="tab==5"></empStresDsltn>
            </v-tab-item> 
        </v-tabs-items>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isDialog(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'참석자 선택'" :tabInfList="['1','2']" :fixList="exclsList" mode="2"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :prtWrtDt = "prtWrtDt"
                    :rtrInc1   = "rtrInc1"
                    :rtrInc2   = "rtrInc2"
                    @intgrResList="getAplyList" @onClose="isDialog(1)">
                </IntgrSlctn>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[2]" max-width="1100">    
                <bizMsgMdl 
                    ref="bizMsgMdl"   
                    v-if="dialog[2]"                 
                    :bizMsgData="eSndData"
                    :bizMsgDocClcd="eDocClcd"
                    :bizMsgBzPk="sltEduPk"
                    :bizMsgShow="dialog[2]"
                    :bizMsgTitle="eCntnt"
                    :bizMsgCntnt="eCntnt"
                    @madalState="onReload(2)"
                ></bizMsgMdl>  
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[3]" max-width="800">    
                <edctnJrnlSignMdl 
                v-if="dialog[3]"
                :edurecpk = "sltEduPk"
                 @modalEvent="onReload(3)"></edctnJrnlSignMdl>  
            </v-dialog>
        </div>    
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[4]" :max-width="edctnSttWh">    
                <edctnSttMdl 
                v-if="dialog[4]"
                :title      = "eduTitle"
                :edutypcd   = "eduTypcd"
                :year       = "yyyy"
                :cd42      = "vCd42"
                 @isClose="dialog.splice(4, 1, false)"></edctnSttMdl>  
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import { getMultiStcd , fileDownload } from '../../api/index.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import edctnJrnlEmpRprt from '../../components/emp/EdctnJrnlEmpRprt.vue';                   //직원교육
import edctnJrnlSilvCareRprt from '../../components/emp/EdctnJrnlSilvCareRprt.vue';         //노인인권 및 학대 예방교육
import edctnJrnlDssRes from '../../components/emp/EdctnJrnlDssRes.vue';                     //재난상황대응훈련
import empwrMmt from '../../components/emp/EmpwrMmt.vue';                                   //역량강화
import edctnEndOfCare from '../../components/emp/EdctnEndOfCare.vue';                       //임종돌봄 교육
import edctnOralHthCare from '../../components/emp/EdctnOralHthCare.vue';                   //구강건강교육
import empStresDsltn from '../../components/emp/EmpStresDsltn.vue';                         //직무스트레스해소

import EmpSelector from '../../components/commons/EmpSelector.vue';
import IntgrSlctn from '../../components/commons/IntgOhrVer.vue';
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';
import edctnJrnlSignMdl from '../../components/emp/EdctnJrnlSignMdl.vue';
import edctnSttMdl from '../../components/emp/EdctnSttMdl.vue';                             //직원교육현황

export default {
    name: 'EdctnJrnl',
        
    components: {
        expandYearOnlyPicker,
        edctnJrnlEmpRprt,
        edctnJrnlSilvCareRprt,
        edctnJrnlDssRes,
        empwrMmt,
        edctnEndOfCare,
        edctnOralHthCare,
        empStresDsltn,
        EmpSelector,
        IntgrSlctn,
        bizMsgMdl,
        edctnJrnlSignMdl,
        edctnSttMdl,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.prtWrtDt = this.$moment().format('YYYYMMDD')
            this.getCode(['41', '42', '65', '74'])
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        getYear:function(yyyy){
            this.yyyy = yyyy
            this.tab = 0
            if(yyyy < 2025)
                this.oldYn = true
            else 
                this.oldYn = false

            this.tabItems.splice(0)

            if(this.oldYn){
                this.tabItemsOld.forEach(itm => {
                    this.tabItems.push(itm)
                });
            }
            else {
                this.tabItemsNew.forEach(itm => {
                    this.tabItems.push(itm)
                });
            }

            this.forceRender++
        },

        //공통코드 조회
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd74 = res.vCd74
        },

        onDialog:function(ymd, key, arr = null){
            this.prtWrtDt = ymd

            if(key == 1){
                this.exclsList.splice(0)
               
                if(typeof arr == 'object') {
                    arr.forEach(e => {
                        let obj = {
                            name:e.name,
                            mmbrpk:e.mmbrpk,
                            disChekr:true,
                            checkr:e.checkr
                        }
        
                        this.exclsList.push(obj)
                    });
                }
            }

            this.dialog.splice(key, 1, true)
        },

        //직원현황ON
        onEduSttMdl:function(){
            this.dialog.splice(4, 1, true)
        },

        isDialog:function(key){
            this.dialog.splice(key, 1, false)
        },

        getEmpInfo: function (obj) {
            this.wrtr       = obj[0].name
            this.wrtrMmbrPk = obj[0].mmbrPk
            this.jobCd      = obj[0].jobCd
        },

        getAplyList: function (arr){
            this.exclsArr = arr
            this.exclsChn++
        },

        //첨부파일 다운로드
        fileDownload:function(obj){           
            fileDownload(obj)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        onReload:function(key){
            this.dialog.splice(key, 1, false)

            if(this.tab == 0) {
                this.$refs.edctnJrnlEmpRprt.getEmpEduList()
            } 
            else if(this.tab == 1) {
                this.$refs.edctnJrnlSilvCareRprt.getEmpEduList()
            }
            else if(this.tab == 2) {
                this.$refs.edctnJrnlDssRes.getEmpEduList()
            }
            else if(this.tab == 3) {
                if(this.oldYn) this.$refs.empwrMmt.getEmpEduList()
                else this.this.$refs.edctnEndOfCare.getEmpEduList()
            }
            else if(this.tab == 4) {
                if(!this.oldYn) this.$refs.edctnOralHthCare.getEmpEduList()
            }
            else if(this.tab == 5) {
                if(!this.oldYn) this.$refs.empStresDsltn.getEmpEduList()
            }
            

            this.sltEduPk = -1

        },
        //출력
        prtEdctnJrnl:function(prtcd, bzpk){            

            this.prtThrowObj.list[0].bzPk.splice(0)                
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYmdStart = ""
            this.prtThrowObj.inqYmdEnd = ""
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = ''
            this.prtThrowObj.prtCd = prtcd

            this.prtThrowObj.list[0].bzPk.push(bzpk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        }
    },
    
    data: () => ({
        forceRender : 0,
        tab         : 0,        
        tabItems    : [],
        yyyy        : '',
        prtWrtDt    : '',
        oldYn       : false,
        rtrInc1     : false,
        rtrInc2     : true,
        vCd41       : [],
        vCd42       : [],
        vCd65       : [],
        vCd74       : [],
        dialog      : [false, false, false, false, false],
        wrtr        : '',           //작성자
        wrtrMmbrPk  : -1,
        jobCd       : '',           //작성자
        exclsChn    : 0,       
        exclsArr    : [],
        exclsList   : [],
        prtThrowObj : {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '29', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        tabItemsOld : [
            { index: 0, tab: '직원교육', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '노인인권 및 학대 예방교육', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 2, tab: '재난상황대응훈련', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 3, tab: '역량강화', class: 'fontOneDotTwoRem px-15', enter:false},
        ],
        tabItemsNew    : [
            { index: 0, tab: '직원교육', class: 'fontOneDotTwoRem px-8', enter:false},
            { index: 1, tab: '노인인권 및 학대 예방교육', class: 'fontOneDotTwoRem px-8', enter:false},
            { index: 2, tab: '재난상황대응훈련', class: 'fontOneDotTwoRem px-8', enter:false},            
            { index: 3, tab: '임종돌봄 교육', class: 'fontOneDotTwoRem px-8', enter:false},
            { index: 4, tab: '구강건강 교육', class: 'fontOneDotTwoRem px-8', enter:false},
            { index: 5, tab: '직무스트레스해소', class: 'fontOneDotTwoRem px-8', enter:false},
        ],
        sltEduPk    : -1,
        eSndData    : [],
        eDocClcd    : 8,
        eCntnt      : '',
        eduTitle    : '',
        eduTypcd    : '',
        edctnSttWh  : '1100'
    }),
};
</script>