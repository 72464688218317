<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">                    
                    <span>9-5. 프로그램 제공 현황</span>
                </span>
            </div>
            <div v-if="stlTab == 0">
                <div class="d-flex mt-4 mx-2">
                    <exBtn                    
                        style="margin-left: 2px;"
                        :btnData="exBtn[0]"
                        @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <!-- <exBtn v-if="routerRocation != 'EmrgnRcrd' && routerRocation != 'SpclBdrmUseRcrd'"
                        class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" /> -->
                    <exBtn
                        style="margin-left: 2px;" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <CmmLvnExpnBtn
                        v-if="routerRocation != 'EmrgnRcrd' && routerRocation != 'SpclBdrmUseRcrd'"
                        v-model="isLvnStt"
                        style="margin-left: 2px;"
                        @onList="lvnSttApply">
                    </CmmLvnExpnBtn>            
                    <v-spacer></v-spacer>
                    
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
                <div class="mt-2 mx-3 rounded-lg">
                    <v-data-table class="msclnTable empCheckTable rounded" height="693" locale="ko"
                        v-model="tableSelected" style="position: relative;"
                        :headers="headerList" :items="bnfcrList" :search="schDummy" :custom-filter="customFilter"
                        item-key="bnMmbrPk" checkbox-color="primary"
                        show-select disable-pagination fixed-header hide-default-footer dense>
                        <template v-slot:[`header.data-table-select`]="{ props, on }" >
                            <tr>
                                <td class="">
                                    <span class="d-inline-block pl-1" style="width: 24px; height: 28px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :input-value="props.value"
                                            @change="on.input($event)"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                            </tr>
                        </template>                    
                        <template v-slot:item='{ item, isSelected, select }'>
                            <tr>
                                <td class="py-1">
                                    <span class="d-inline-block" style="width: 24px; height: 31px; padding-left: 5px;">                                    
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :input-value="isSelected"
                                            @change="select($event)"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                                <td class="txtAlgnMid fontOneRem">{{item.rn}}</td>
                                <td class="txtAlgnMid fontOneRem">{{item.name}}</td>
                                <td class="txtAlgnMid fontOneRem"><span class="pl-1">{{item.age}}</span></td>
                                <td class="txtAlgnMid fontOneRem">
                                    <div class="d-flex justify-center align-center">
                                        <span :class="'ic-level level0'+item.acknwRtng" style="margin-left: 3px;">
                                            {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                        </span>
                                    </div>
                                </td>
                                <td class="txtAlgnMid fontOneRem">{{item.entStcd}}</td>
                            </tr>
                        </template>
                        <template v-slot:foot='{ items, pagination }'>
                            <div class="px-1 d-inline-block" style="width: 100%; position: absolute; bottom: -34px; left: 0;">
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="7">
                                        <span class="fontOneRem">전체: {{ pagination.itemsLength }}명,&nbsp;&nbsp;</span>
                                        <span class="fontOneRem">남: {{ items.filter( v => v.gndr == 'M').length }}명&nbsp;&nbsp;</span>
                                        <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col class="d-flex" cols="4">
                                        <v-spacer></v-spacer>
                                        <span class="d-inline-block" style="width: 100px;">
                                            <v-checkbox
                                                v-model="entChgBox" :ripple="false"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                true-value="1" false-value="2" label="퇴소자 조회"
                                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </div>
            <div v-else-if="stlTab == 1">
                <div class="d-flex mt-4 mx-2">
                    <exBtn
                        style="margin-left: 2px;" :btnData="exBtn[2]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <v-spacer></v-spacer>
                    
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="그룹명 조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
                <div class="mt-2 mx-3 rounded-lg">
                    <v-data-table class="msclnTable empCheckTable rounded" height="693" locale="ko"
                        v-model="prgSelected" style="position: relative;"
                        :headers="prgHeaderList" :items="prgList" :search="schDummy" :custom-filter="customFilter"
                        item-key="prgBnfcrgrInfPk" checkbox-color="primary"
                        show-select disable-pagination fixed-header hide-default-footer dense>
                        <template v-slot:[`header.data-table-select`]="{ props, on }" >
                            <tr>
                                <td class="">
                                    <span class="d-inline-block pl-1" style="width: 24px; height: 28px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :input-value="props.value"
                                            @change="on.input($event)"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                            </tr>
                        </template>                    
                        <template v-slot:item='{ item, isSelected, select }'>
                            <tr>
                                <td class="py-1">
                                    <span class="d-inline-block" style="width: 24px; height: 31px; padding-left: 5px;">                                    
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :input-value="isSelected"
                                            @change="select($event)"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                                <td class="txtAlgnMid fontOneRem">{{item.idxNm}}</td>
                                <td class="txtAlgnMid fontOneRem">{{item.bnfcrgrNm}}</td>
                                <td class="txtAlgnMid fontOneRem">
                                    <span v-if="item.useYn == '1'">사용</span>
                                    <span v-else class="red--text">미사용</span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </v-sheet>
        <router-view ref="btcInqChild"></router-view>
    </v-sheet>
</template>

<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';

import { http } from '@/api/baseAxios';
import { bnfcrList } from '../../api/bnfcr.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'BtcInq',
        
    components: {
        exBtn,
        CmmLvnExpnBtn,
    },

    created: function(){
        this.getCode(['40', '62', '65'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.routerRocation = this.$route.name
            this.getBnfcrList()
        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            console.log('Url Event from: ' + from.name)
        },
        'routerRocation': function (){
            this.headerList.forEach(items => {
                items.on = false
            });
            //this.getTableList()
        },
        'entChgBox': function () {
            this.getTableList()
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },        
        //간호기록현황, 프로그램 수급자 참여현황
        getBnfcrList: function (){
            let entStcd = null
            if(this.entChgBox == 1)
                entStcd = '4'

            let this_monday = this.$moment().day(1)
            let startDt = this_monday.format('YYYYMMDD');
            let endDt = this_monday.add(6,'days').format('YYYYMMDD');

            bnfcrList(this.$store.getters.getFcltyNum, entStcd, startDt, endDt)
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        stCdMapping: function (res) {
            res.vCd62.forEach((code) => {
                this.exBtn[1].expandList.push(code.valcdnm)
            });//end foreach

            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            res.vCd40.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd62 = res.vCd62
            this.vCd65 = res.vCd65
        },        
        getBnfcrListAfter: function (res){

            this.bnfcrList.splice(0)

            let me = this
            res.forEach(items => {
                items.entDt = this.$moment(items.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            this.bnfcrList = res
        },
        getTableList: function(){
            switch(this.stlTab) {               
                case 0:
                    this.bnfcrList.splice(0)                    
                    this.getBnfcrList()
                    break;
                case 1:
                    this.prgList.splice(0)
                    this.getPrgBnfcrgrPrdList()
                    break;
                default:
                    break;
            }
        },
        tableClickEvent: function (pk){
            if(this.sltBnMmBrPk == -1){
                this.sltBnMmBrPk = pk
            }
            else if(this.sltBnMmBrPk != pk){
                this.sltBnMmBrPk = pk
            }
            else{
                this.sltBnMmBrPk = -1
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {

            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
                case 'trgYYYY': this.schChkField.trgYYYY.splice(0)
                    sch.forEach((item) => { this.schChkField.trgYYYY.push(item) });
                    break;
                case 'idxNm': this.schChkField.idxNm.splice(0)
                    sch.forEach((item) => { this.schChkField.idxNm.push(item) });
                    break;
            }
            
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
             //value: items, search: search key, filter: array column
             let col0 = (this.stlTab == 0)?filter.name.includes(this.schField):filter.bnfcrgrNm.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0 && this.stlTab == 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.acknwRtng.length != 0 && this.stlTab == 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.acknwRtng.includes(stcd)
                    else col2 = col2 || filter.acknwRtng.includes(stcd)
                });
            }

            let col3 = true

            // 생활실 검색
            if(this.routerRocation != 'EmrgnRcrd' && this.routerRocation != 'SpclBdrmUseRcrd' && this.stlTab == 0) {
                let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
                if(lvnRunChc.length == 0)
                    col3 = false
                else{
                    lvnRunChc.forEach((itm, i) => {
                        if(i == 0) col3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                        else col3 = col3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                    });
                }
            }

            let col4 = true
            if(this.schChkField.idxNm.length != 0 && this.stlTab == 1){
                this.schChkField.idxNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.idxNm.includes(stcd)
                    else col2 = col2 || filter.idxNm.includes(stcd)
                });
            }

            return col0 && col1 && col2 && col3 && col4;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;                
                case 1: this.schChkField.target = 'acknwRtng'
                    break;    
                case 2: this.schChkField.target = 'idxNm'
                    break;                
                default: this.schChkField.target = ''
                    break;
            }
        },
        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
        getPrgBnfcrgrPrdList:function(){            
            this.prgList.splice(0)
            
            let obj = {
                inqYmdStart : this.sltStartYymm+'01',  
                inqYmdEnd   : this.$moment(this.sltEndYymm,'YYYYMM').endOf('month').format('YYYYMMDD')
            }

            http.post('prgrm/selPrgBnfcrgrPrdList', obj)
                .then((response) => ( this.prgBnfcrgrPrdListRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgBnfcrgrPrdList : ' + error))
        },
        prgBnfcrgrPrdListRst:function(res){
            if(res.statusCode == '200') {
                res.result.list.forEach(el => {
                    this.prgList.push(el)
                });
            }
            else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀 
                this.$store.commit('setWrnnInf', obj)
            }
        },
    },
    
    data: () => ({
        stlTab:0,
        sltStartYymm    : '',
        sltEndYymm      : '',
        vCd62           : [],
        vCd65           : [],
        lvnRmInf        : [],
        bnfcrList       : [],
        prgList         : [],
        tableSelected   : [],
        prgSelected     : [],
        midAreaWidth    : '430',
        routerRocation  : '',
        sltBnMmBrPk     : -1,
        schField        : '',
        schDummy        : '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng:[], idxNm:[], target: '' },
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },            
            { index:  1, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
            { index:  2, btnTitle: '프로그램 유형', btnIcon: 'category', exWidth: 180, exHeigth: 130, expandList: [], },
        ],
        groupList:{
            NsgRcrdStts: ['연번', '수급자', '나이', '등급', '현황', '생활실', '입소일'], 
            PrgBnfcrPrtcpStts: ['연번', '수급자', '나이', '등급', '현황', '생활실', '입소일'],
        },
        headerList: [
            { text: '연번', value: 'rn', align: 'center', on: false, sortable: false, width: '70', class: 'black--text sftyHeader py-2'},
            { text: '수급자', value: 'name', align: 'center', on: false, sortable: false, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '나이', value: 'age', align: 'center', on: false, sortable: false, width: '60', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '등급', value: 'acknwRtng', align: 'center', on: false, sortable: false, width: '60', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '현황', value: 'entStcd', align: 'center', on: false, sortable: false, width: '80', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
        ],
        prgHeaderList: [
            { text: '프로그램 유형', value: 'entStcd', align: 'center', on: false, sortable: false, width: '130', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '수급자 그룹명', value: 'bnfcrgrNm', align: 'center', on: false, sortable: false, width: '150', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '사용여부', value: 'useYn', align: 'center', on: false, sortable: false, width: '80', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
        ],
        isLvnStt            : false,                        // 생활실 확장 활성화 여부
        isEntStt            : false,                        // 현황 확장 활성화 여부
        aslLvnStt           : [],
        entChgBox           : false,
    }),
};
</script>