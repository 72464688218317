<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">CCTV&nbsp;설치·운영 관리대장</span>
            <span v-if="cctvOprPk == 0" class="text-h4 font-weight-bold">&nbsp;신규 작성</span>
            <span v-else-if="cctvOprPk > 0" class="text-h4 font-weight-bold">&nbsp;수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$emit('offMdl', true)" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기본 항목</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="2">
                <span class="font-weight-medium posn-rltv">
                    점검일자
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="tableBR px-2 py-1" cols="2">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="inpInf.inspDt"
                        :required="true"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="2">
                <span class="font-weight-medium posn-rltv">
                    담당자
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center tableBR px-2 py-1" cols="2">
                <span class="d-inline-block" style="width: 120px;">
                    <v-text-field
                        v-model="inpInf.wrtr" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details readonly outlined dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" style="padding: 0px 1px 0px 2px !important;"
                    min-width="30" height="25" @click="onLclMdl('11', 800)"
                    :disabled="/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.inpInf.inspDt) == false"
                    small>
                    <v-icon color="white" size="20">mdi-account-edit</v-icon>
                </v-btn>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">촬영기간 삭제주기</span>
            </v-col>
            <v-col class="px-2 py-1" cols="2">
                <v-text-field
                    v-model="inpInf.dltCycl"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">작동상태</span>
            </v-col>
            <v-col class="tableBR py-1" cols="2">
                <v-radio-group
                    v-model="inpInf.prfrCd"
                    class="radio-dft-Theme mt-0" hide-details row>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="정상" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="비정상" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">안내판 설치 장소</span>
            </v-col>
            <v-col class="px-2 py-1" cols="2">
                <v-checkbox
                    class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                    v-model="inpInf.instlCnfrmYn" :ripple="false"
                    true-value="1" false-value="2" label="확인"
                    on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                    hide-details>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 15px;" cols="2">
                <span class="font-weight-medium">조치사항 등</span>
            </v-col>
            <v-col class="px-2 py-1" cols="10">
                <v-textarea
                    class="rmrksArea" rows="2" 
                    v-model="inpInf.cntnt"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">영상정보 저장장치</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">정상작동 여부</span>
            </v-col>
            <v-col class="tableBR py-1" cols="2">
                <v-radio-group
                    v-model="inpInf.vdOprCd"
                    class="radio-dft-Theme mt-0" hide-details row>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="양호" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="불량" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">잠금장치</span>
            </v-col>
            <v-col class="py-1" cols="2">
                <v-radio-group
                    v-model="inpInf.vdLckCd"
                    class="radio-dft-Theme mt-0" hide-details row>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="양호" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="불량" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">모니터 관리</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">화면 외부 유출 유·무</span>
            </v-col>
            <v-col class="tableBR py-1" cols="2">
                <v-radio-group
                    v-model="inpInf.extrLeakYn"
                    class="radio-dft-Theme mt-0" hide-details row>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="유" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="무" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                <span class="font-weight-medium">잠금장치</span>
            </v-col>
            <v-col class="py-1" cols="2">
                <v-radio-group
                    v-model="inpInf.mntrLckCd"
                    class="radio-dft-Theme mt-0" hide-details row>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="양호" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2 mr-0" style="padding-bottom: 2px !important;"
                        :ripple="false" label="불량" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-4"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                v-if="cctvOprPk > 0"
                class="" color="grey006" min-width="110" height="36"
                :disabled="delBtn" @click="dcsnDelBtn">
                <span class="white--text fontOneDotTwoRem">삭제</span>
            </v-btn>
            <v-col v-if="cctvOprPk > 0" cols="1"></v-col>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <CctvOprSavMdl
                v-if="lclMdlInf.typ == '01'"
                @offMdl="offCctvOprMdl">
            </CctvOprSavMdl>
            <EmpSelector
                v-else-if="lclMdlInf.typ == '11'"
                @isClose="rstLclMdlInf" @outEmp="getEmpInf"
                :prtWrtDt="inpInf.inspDt">
            </EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'CctvOprSavMdl',

    components: {
        CmmDateComp,
        EmpSelector,
    },

    props : {
        
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getTmpObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                this.rmCctvOprInf(this.cctvOprPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },

    methods:{
        // CCTV 관리대장 단건 조회
        getCctvOprInf: function (){
            this.rstFld()

            let obj = {}
            obj.cctvOprPk = this.cctvOprPk

            http.post('opr/selCctvOprInfo', obj)
                .then((response) => ( this.cctvOprRst(response.data) ))
                .catch((error) => console.log('connect error /opr/selCctvOprInfo : ' + error))
        },

        cctvOprRst: function (res){
            if(res.result != null){
                this.inpInf.inspDt = this.$moment(res.result.inspDt, 'YYYYMMDD').format('YYYY-MM-DD')
                this.inpInf.prfrCd = res.result.prfrCd
                this.inpInf.dltCycl = res.result.dltCycl
                this.inpInf.instlCnfrmYn = res.result.instlCnfrmYn
                this.inpInf.vdOprCd = res.result.vdOprCd
                this.inpInf.vdLckCd = res.result.vdLckCd
                this.inpInf.extrLeakYn = res.result.extrLeakYn
                this.inpInf.mntrLckCd = res.result.mntrLckCd
                this.inpInf.cntnt = res.result.cntnt
                this.inpInf.wrtr = res.result.wrtr
                this.inpInf.wrtrMmbrPk = res.result.wrtrMmbrPk
            }

            this.aplctSavBtn()
        },

        // CCTV 관리대장 단건 저장
        setCctvOprInf: function (obj){
            this.savBtn = true
            this.delBtn = true

            http.post('opr/insCctvOpr', obj)
                .then((response) => ( this.cctvOprSavRst(response.data) ))
                .catch((error) => console.log('connect error /opr/insCctvOpr : ' + error))
        },

        cctvOprSavRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.savBtn = false
                this.delBtn = false
                this.$emit('offMdl', true)
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // CCTV 관리대장 단건 삭제
        rmCctvOprInf: function (pk){
            this.savBtn = true
            this.delBtn = true

            let obj = {}
            obj.cctvOprPk = pk
   
            http.post('opr/delCctvOpr', obj)
                .then((response) => ( this.cctvOprSavRst(response.data) ))
                .catch((error) => console.log('connect error /opr/delCctvOpr : ' + error))
        },

       // 기본정보 적용
       aplctInf: function (obj) {
            this.cctvOprPk = obj.cctvOprPk

            if(this.cctvOprPk > 0){
                this.delBtn = false
                this.getCctvOprInf()
            }
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.inpInf.inspDt) == false)
                return 0
            if(this.inpInf.wrtr == '')
                return 0
            if(this.inpInf.wrtrMmbrPk == -1)
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let insObj = Object.assign({}, this.inpInf)
            insObj.cctvOprPk = this.cctvOprPk
            insObj.inspDt = this.$moment(insObj.inspDt, 'YYYY-MM-DD').format('YYYYMMDD')
            
            this.setCctvOprInf(insObj)
        },

        // 삭제버튼 분기
        dcsnDelBtn: function () {
            this.runAble = '2'

            let wObj = {}
            wObj.actv = true                             // 경고 노출여부
            wObj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            wObj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            wObj.title = '점검일자를 정확히 입력해주세요.'   // 경고 타이틀
            wObj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.inpInf.inspDt) == false){
                this.$store.commit('setWrnnInf', wObj)
                return 0
            }

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '점검일: ' + this.$moment(this.inpInf.inspDt, 'YYYY-MM-DD').format('YYYY.MM.DD')
            obj.cntnt = ['CCTV 관리대장을 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },

        // 입력필드 초기화
        rstFld: function () {
            this.inpInf.inspDt = ''
            this.inpInf.prfrCd = '1'
            this.inpInf.dltCycl = '60일'
            this.inpInf.instlCnfrmYn = '1'
            this.inpInf.vdOprCd = '1'
            this.inpInf.vdLckCd = '1'
            this.inpInf.extrLeakYn = '1'
            this.inpInf.mntrLckCd = '1'
            this.inpInf.cntnt = ''
            this.inpInf.wrtr = ''
            this.inpInf.wrtrMmbrPk = -1

            this.aplctSavBtn()
        },

        getEmpInf: function (obj) {
            this.inpInf.wrtr = obj[0].name
            this.inpInf.wrtrMmbrPk = obj[0].mmbrPk

            this.aplctSavBtn()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        delBtn: true,
        runAble: '0',
        cctvOprPk: 0,
        inpInf: {
            inspDt: '', prfrCd: '1',  dltCycl: '60일', instlCnfrmYn: '1', vdOprCd: '1', vdLckCd: '1',
            extrLeakYn: '1', mntrLckCd: '1', cntnt: '', wrtr: '', wrtrMmbrPk: -1,
        },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },     // 통합 팝업 제어
    })
}
</script>

<!--
"inspDt" 		: "점검일자",
"prfrCd" 		: "성능코드(1:정상,2:비정상)",
"dltCycl" 		: "촬영기간삭제주기",
"instlCnfrmYn" : "안내판설치장소확인여부(1,2)",
"vdOprCd" 	: "영상정보 정상작동 코드(1:양호,2:불량)",
"vdLckCd" 	: "영상정보 잠금장치 코드(1:양호,2:불량)",
"extrLeakYn" 	: "화면외부유출유무",
"mntrLckCd" 	: "모니터 잠금장치 코드(1:양호,2:불량)",
"cntnt" 		: "조치사항 등",
"wrtr" 		: "작성자",
"wrtrMmbrPk" : "작성자 구성원 고유번호"
"cctvOprPk"	: (null or 0 신규) 
-->
