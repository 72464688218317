<template>
    <div class="d-flex">
        <v-sheet class="pa-3" width="360" height="835" color="midAreaColor">
            <p class="hamletFont text-h5 topTitleColor--text font-weight-black">
                <span v-if="routeLctnNm == 'BnfcrInfrmMgmt'">1-1.&nbsp;수급자&nbsp;정보관리</span>
                <span v-else-if="routeLctnNm == 'StayOutMgmt'">1-3.&nbsp;외출&#183;외박&nbsp;관리</span>
                <span v-else-if="routeLctnNm == 'BnftSrvMgmt'">2-2.&nbsp;급여제공&nbsp;계획&nbsp;및&nbsp;결과평가</span>
                <span v-else-if="routeLctnNm == 'GrpstRcpnt'">4-4.&nbsp;프로그램&nbsp;수급자&nbsp;그룹관리</span>
            </p>
            <v-row class="pa-0 mt-4" no-gutters>
                <CmmExpnBtn
                    v-model="isEntStt"
                    :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                    :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                </CmmExpnBtn>
                <CmmLvnExpnBtn
                    v-model="isLvnStt"
                    class="ml-1"
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 106px; height: 34px;">
                    <v-text-field
                        v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                        class="txt-Theme" height="34" label="이름조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-row>
            <p class="txtAlgnMid blue004--text my-2">※&nbsp;숨겨진 항목은 하단 스크롤을 이용해서 보세요.</p>
            <v-data-table
                class="bnfcrMidTable-Theme rounded" height="660" locale="ko"
                :headers="bnfcrHeader" :items="bnfcrList" item-key="rn"
                :loading="isLoad" :loading-text="loadMsg"
                :search="dmyTxt" :custom-filter="srchFltr"
                fixed-header disable-pagination hide-default-footer dense>
                <template v-slot:item='{ item, index }'>
                    <tr
                        :class="[sltRow.bnMmbrPk == item.bnMmbrPk ? 'onClr' : '']"
                        @click="evtRow(item)">
                        <td class="hvrClr">{{ index + 1 }}</td>
                        <!-- hghlgCol -->
                        <td class="hvrClr">
                            <span class="d-inline-block posn-rltv" style="width: 0px; height: 14px;">
                                <v-icon
                                    v-if="$moment().isAfter($moment(item.acknwPrdEnd, 'YYYYMMDD'))"
                                    style="position: absolute; top: 0px; left: -14px;"
                                    color="red" size="18">
                                    mdi-circle-medium
                                </v-icon>
                            </span>
                            <span>{{ item.name }}</span>
                        </td>
                        <td>{{ item.entStNm }}</td>
                        <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ item.lvnRmNm }}</span>
                                </template>
                                <span>{{ item.bldNm }} - {{ item.bldFlr }}층</span>
                            </v-tooltip>
                        </td>
                        <td>{{ item.age }}</td>
                        <td>
                            <v-img
                                v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"
                                class="ic-sex" style="margin-left: 3px;">
                            </v-img>
                            <v-img
                                v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"
                                class="ic-sex" style="margin-left: 3px;">
                            </v-img>
                        </td>
                        <td >
                            <span
                                :class="'ic-level level0'+item.acknwRtng"
                                style="margin-left: 3px; line-height: 0;">
                                {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                            </span>
                        </td>
                        <td>{{ $moment(item.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</td>
                        <td
                            :class="[$moment().isAfter($moment(item.acknwPrdEnd, 'YYYYMMDD').subtract('90', 'days')) ? 'red--text' : '']">
                            {{ $moment(item.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}
                        </td>
                    </tr>
                </template>
                <template v-slot:foot='{ items, pagination }'>
                    <div class="bnfcrMidTable-Foot d-inline-block">
                        <v-row class="pa-0 lign-center" no-gutters>
                            <span class="fontOneRem mr-2">전체: {{ pagination.itemsLength }}명,</span>
                            <span class="fontOneRem mr-2">남: {{ items.filter( v => v.gndr == 'M').length }}명,</span>
                            <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                        </v-row>
                    </div>
                </template>
            </v-data-table>
        </v-sheet>
        <v-sheet
            class="rounded-lg rounded-l-0 overflow-auto"
            min-width="912" max-width="1200">
            <router-view></router-view>
        </v-sheet>
    </div>
</template>

<script>
import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';

import { http } from '@/api/baseAxios';

export default {
    name: 'BnfcrCnfgr',
    
    components: {
        CmmExpnBtn,
        CmmLvnExpnBtn
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.routeLctnNm = this.$route.name

            this.getCode(['62', '63', '64', '65', '104', '114'])
            this.getAllBnfcrList()
        })
    },

    computed: {
        getRouteLoc(){
            return this.$route
        },
    },

    watch: {
        getRouteLoc: function (obj) {
            this.routeLctnNm = obj.name
        },
        'isEntStt': function (v) {
            if(v) this.isLvnStt = false
        },
        'isLvnStt': function (v) {
            if(v) this.isEntStt = false
        }
    },

    methods: {
        // 공통 코드 다중 조회
        getCode: function (arr) {
            let obj = {}
            obj.valClcdS = arr
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        stCdMapping: function (res){
            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd64 = res.vCd64
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
            if(res.vCd65.length > 0)
                res.vCd65[0].isOn = true
            this.vCd65 = res.vCd65
            this.vCd104 = res.vCd104
            this.vCd114 = res.vCd114

            this.valCdApply()
        },

        // 전체 수급자 목록 조회 (퇴소자 포함)
        getAllBnfcrList: function (){
            this.isLoad = true
            this.bnfcrList.splice(0)

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcr/selAllBnfcrList', obj)
                .then((response) => ( this.allBnfcrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrList : ' + error))
        },

        allBnfcrRst: function (res){
            if(res != null){
                if(res.length > 0){
                    res.forEach((itm, i) => {
                        itm.arrNum = i + 1
                    });
                    this.bnfcrList = res
                    this.dmyTxt = String(Number(this.dmyTxt)+1)
                    this.valCdApply()
                }

                if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                    this.sltRow.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                    this.sltRow.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                }
            }

            this.isLoad = false
        },

        // 테이블 행 클릭 이벤트
        evtRow: function (obj){
            if(this.sltRow.bnfcrPk == obj.bnfcrPk){
                this.sltRow.bnMmbrPk = -1
                this.sltRow.bnfcrPk = -1
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });
            }
            else{
                this.sltRow.bnMmbrPk = obj.bnMmbrPk
                this.sltRow.bnfcrPk = obj.bnfcrPk
                this.$store.commit('setBnfcrState', {
                    bnmmbr: obj.bnMmbrPk, bnfcr: obj.bnfcrPk, name: obj.name, entStcd: obj.entStcd, 
                    entDt: obj.entDt, lvDt: obj.lvDt == null ? '' : obj.lvDt
                });
            }
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){

            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                field3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else field3 = field3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return field1 && field2 && field3
        },

        // 테이블 데이터 코드 맵핑
        valCdApply: function (){
            // 추가 코드 맵핑 작성...
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr

            this.dmyTxt = String(Number(this.dmyTxt)+1)
        },

    },

    data: () => ({
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        sltRow: { bnMmbrPk: -1, bnfcrPk: -1 },
        srchTxt: '',                            // 테이블 이름 검색 필드
        dmyTxt: '',                             // 테이블 반응 더미(검색용)
        routeLctnNm: '',                        // 라우터 경로
        isEntStt: false,                        // 현황 확장 활성화 여부
        isLvnStt: false,                        // 생활실 확장 활성화 여부
        vCd62: [],                              // 인정등급
        vCd63: [],                              // 본인부담률
        vCd64: [],                              // 이메일도메인
        vCd65: [],                              // 입소현황
        vCd104: [],                             // 상담방법
        vCd114: [],                             // 상급침실 구분
        bnfcrList: [],                          // 수급자 목록
        aslLvnStt: [],
        bnfcrHeader: [
            { text: '연번', value: 'arrNum', sortable: true, filterable: false, width: '70' },
            { text: '수급자', value: 'name', sortable: true, filterable: true, width: '90' },
            { text: '현황', value: 'entStNm', sortable: true, filterable: true, width: '80' },
            { text: '생활실', value: 'lvnRmNm', sortable: true, filterable: false, width: '90' },
            { text: '나이', value: 'age', sortable: true, filterable: false, width: '60' },
            { text: '성별', value: 'gndr', sortable: true, filterable: false, width: '60' },
            { text: '등급', value: 'acknwRtng', sortable: true, filterable: false, width: '70 '},
            { text: '입소일', value: 'entDt', sortable: true, filterable: false, width: '92' },
            { text: '인정만료', value: 'acknwPrdEnd', sortable: true, filterable: false, width: '92' },
        ],
    }),
};
</script>