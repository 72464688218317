<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="py-7 pl-7 pr-6 midAreaColor" cols="4">
                <v-sheet class="" color="midAreaColor">
                    <p class="hamletFont text-h5 topTitleColor--text font-weight-black">
                        <span>
                            야간 점검 항목 관리
                        </span>
                    </p>                    
                    <v-divider class="midAreaColor my-1"></v-divider>
                    <!--  :search="schDummy" :custom-filter="customFilter" -->
                    <v-data-table
                        class="dft-hvr-tbl" height="680" locale="ko"
                        :headers="headers" :items="inspAreaList"                       
                        fixed-header hide-default-header disable-pagination hide-default-footer dense>

                        <template v-slot:header="props">
                            <th class="black--text bottomBorder px-1 py-3" style="width: 80px;">{{ props.props.headers[0].text }}</th>
                            <th class="black--text bottomBorder px-1 py-3" style="width: 200px;">{{ props.props.headers[1].text }}</th>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr
                                :class="[sltAreaPk == item.inspAreaPk ? 'onClr' : '']" 
                                @click="chcTblIdx(item.inspAreaPk)">
                                <td class="txtAlgnMid tableBB pa-1">
                                    <span class="fontOneRem">{{ index+1 }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB pa-1">
                                    <div class="overflow-text-hidden">
                                        <span class="fontOneRem">{{ item.inspAreaNm }}</span>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-divider class="midAreaColor my-1"></v-divider>
                </v-sheet>
            </v-col>
            <v-col class="py-7 pr-7 pl-6" cols="8">
                <v-sheet class="" color="">
                    <v-row class="pa-0 justify-center align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <span v-for="(list, i) in filledBtn" :key="i">
                            <v-btn :class="list.class" height="30" :color="list.color"
                                @click="onClickBtn(i)"
                                :disabled="list.disabled" samll rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </span>                        
                        <v-icon color="black" @click="$emit('isClose', '.')" x-large>mdi-close</v-icon>
                    </v-row>       
                    <v-divider :class="['mt-3', inspItmCdTot > 18 ? 'mr-2__5' : '']"></v-divider>
                    <v-row :class="['nmlBfClrImp-greyE01 pa-0', inspItmCdTot > 15 ? 'mr-2__5' : '']" no-gutters>
                        <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="3">
                            <span class="fontOneDotOneRem font-weight-bold">구분</span>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="9">
                            <v-row class="" no-gutters>
                                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="8">
                                    <span class="fontOneDotOneRem font-weight-bold">점검항목</span>
                                </v-col>
                                <v-col class="d-flex justify-center pa-1" style="padding-top: 6px;" cols="4">
                                    <div style="width:90px;">
                                        <v-select
                                            v-model="allUseYn"
                                            :key="forceSRender"
                                            :items="useYnItm"
                                            @change="chnAllYn"
                                            item-text="valcdnm" item-value="valcd"
                                            dense hide-details outlined>
                                        </v-select> 
                                    </div>
                                </v-col>
                            </v-row>                            
                        </v-col>
                    </v-row>         
                    <v-divider :class="[inspItmCdTot > 18 ? 'mr-2__5' : '']"></v-divider>
                    <div v-if="inspItmList.length > 0" class="overflow-y-auto" style="width: 100%; height: 630px;">
                        <v-row
                            class="tableBB"
                            v-for="(itm, i) in inspItmList" :key="i"
                            no-gutters>               

                            <v-col class="tableBR pb-2 pl-2 d-flex justify-center align-center" cols="3">
                                <span class="fontOneRem">{{ itm.clsfc3Cdnm }}</span>
                            </v-col>
                            <v-col class="pa-0 ma-0" cols="9">
                                <v-row                                    
                                    v-for="(itmcd, j) in itm.itmCdList" :key="j"
                                    no-gutters>   
                                    <v-col :class="['tableBR pt-2 pl-2', j == (itm.itmCdList.length - 1)?'':'tableBB']" cols="8">
                                        <span class="fontOneRem">{{ itmcd.itmCdnm }}</span>
                                    </v-col>
                                    <v-col :class="['pa-1 d-flex justify-center', j == (itm.itmCdList.length - 1)?'':'tableBB']" cols="4">
                                        <div style="width:90px;">
                                            <v-select
                                                v-model="itmcd.useYn"
                                                :key="forceSRender"
                                                :items="useYnItm"
                                                item-text="valcdnm" item-value="valcd"
                                                dense hide-details outlined>
                                            </v-select> 
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else class="tableBB pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="fontOneRem grey006--text">※ 조회된 점검 항목이 없습니다.</span>
                        </v-col>
                    </v-row>                    
                </v-sheet>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" max-width="800">    
            <InspItmCdMdl v-if="menu[0]" @isClose="onClose(0)"></InspItmCdMdl>  
        </v-dialog>
    </v-sheet>
</template>

<script>

import { http } from '@/api/baseAxios';

import InspItmCdMdl from '../../components/sfty/InspItmCdMdl.vue'; //기관야간점검 항목 관리

export default {
    name: 'InspItmMdl',
    
    components: {
        InspItmCdMdl
    },

    props : { 
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getInspAreaList()
        })
    },

    computed: {
       
    },

    watch: {
        'sltAreaPk':function(){
            this.getInspAreaItmCdList()
        }
    },
        
    methods: {
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //저장
                    this.insInspAreaItms()
                    break;               
                case 1:     //점검항목관리                    
                    this.menu.splice(0, 1, true)
                    break;               
                default:
                    break;
            }
        },
        // 점검구역 내역
        getInspAreaList: function (){    
            this.inspAreaList.splice(0)

            let obj = {                
            }

            http.post('sfty/selInspAreaList', obj)
                .then((response) => ( this.inspAreaListRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspAreaList : ' + error))
        },

        //점검구역별 점검항목 다건 조회
        getInspAreaItmCdList: function (){    
            this.inspItmList.splice(0)
            this.inspItmCdTot=0

            if(this.sltAreaPk > 0){
                let obj = {   
                    inspAreaPk : this.sltAreaPk
                }
    
                http.post('sfty/selInspAreaItmCdList', obj)
                    .then((response) => ( this.areaItmCdListRst(response.data) ))
                    .catch((error) => console.log('connect error /sfty/selInspAreaItmCdList : ' + error))
            }
        },

        // 점검구역별 점검항목 저장
        insInspAreaItms: function (){    
            if(this.sltAreaPk > 0){
                let obj = {        
                    inspAreaPk:this.sltAreaPk,
                    list : []
                }

                this.inspItmList.forEach(itm => {
                    itm.itmCdList.forEach(itmcd => {
                        obj.list.push(itmcd)
                    });
                });

                http.post('sfty/insInspAreaItms', obj)
                    .then((response) => ( this.insInspAreaItmsRst(response.data) ))
                    .catch((error) => console.log('connect error /sfty/insInspAreaItms : ' + error))
            } else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = '점검구역을 선택해주세요.'         // 경고 타이틀                

                this.$store.commit('setWrnnInf', obj)
            }
        },

        inspAreaListRst : function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {                        
                        this.inspAreaList.push(itm)                
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            ++this.forceRender
        },

        areaItmCdListRst:function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {                        
                        if(itm.itmCdList != null){
                           this.inspItmCdTot += itm.itmCdList.length
                        }

                        this.inspItmList.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }

            ++this.forceSRender;
        },

        insInspAreaItmsRst:function(res){
             // this.snackControll([true, 5000, res.message, 'error'])
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getInspAreaItmCdList()
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }
            this.$store.commit('setWrnnInf', obj)
        },

        chcTblIdx:function(pk){   
            if(this.sltAreaPk == pk) { 
                this.sltAreaPk = -1
                this.filledBtn[0].disabled = true
            }
            else {
                this.sltAreaPk = pk
                this.filledBtn[0].disabled = false
            }
        },

        chnAllYn:function(){
            this.inspItmList

            this.inspItmList.forEach(itm => {
                itm.itmCdList.forEach(itmcd => {
                    itmcd.useYn = this.allUseYn
                });
            })
        },

        onClose:function(key){
            this.menu.splice(key, 1, false)
            this.getInspAreaItmCdList()
        }
    },

    data: () => ({        
        forceRender : 0,        
        forceSRender : 0,
        sltAreaPk   : -1,
        allUseYn    : '',
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '80', class: 'black--text bottomBorder', align: 'center', },
            { text: '점검구역', value: 'inspAreaNm', sortable: true, width: '200', class: 'black--text bottomBorder py-3', align: 'center', },            
        ],
        inspAreaList    : [],
        inspItmList     : [],
        inspItmCdTot    : 0, //항목코드 전체수
        filledBtn   : [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, },
            { icon: 'mdi-text-box-edit-outline', class: 'ml-1 white--text', color:'blue001', text: '점검항목관리', disabled: false, },
        ],
        outlinedBtn : [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        useYnItm      : [
            {valcdnm:'선택', valcd:''},
            {valcdnm:'예', valcd:'1'},
            {valcdnm:'아니오', valcd:'2'},
        ],
        menu            : [false]
    }),
};
</script>