<template>
    <v-sheet class="rounded-lg rounded-l-0 px-3" min-width="1260" max-width="1460" height="835">
        <v-divider class="white my-1"></v-divider>
        <v-row class="pa-0 align-center" no-gutters>
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">4-1. 프로그램 운영기록</span>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text mx-1" height="28" color="blueBtnColor"
                @click="onClickBtn('프로그램 일정등록')" :disabled="filledBtn[0].disabled"
                samll rounded>
                <v-icon small>{{ filledBtn[0].icon }}</v-icon>
                <span>{{ filledBtn[0].text }}</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme white--text mx-1" height="28" color="grey006"
                @click="onClickBtn('출력')" :disabled="prtBtn"
                samll rounded outlined>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>출력</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
        <expandWeekOnlyPicker @nowWeek="getWeek" class="my-1"></expandWeekOnlyPicker>
        <v-divider class="mt-2"></v-divider>
        <v-data-table
            class="" locale="ko" height="700"
            :headers="headers" :items="items"
            disable-pagination hide-default-footer hide-default-header fixed-header>
            <template v-slot:header="{ props }">
                <thead>
                    <tr>
                        <!-- 1256 -->
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:100px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[0].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:100px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[1].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:200px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[2].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:110px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[3].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:110px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[4].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width:180px; line-height: 1.1;">
                            <span class="fontOneDotOneRem black--text">프로그램<br />수급자그룹</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width:70px; line-height: 1.1;">
                            <span class="fontOneDotOneRem black--text">참여<br />인원수</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:140px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[5].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:90px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[6].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width:70px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[7].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp px-1" style="width:80px;">
                            <span class="fontOneDotOneRem black--text">{{ props.headers[8].text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item, index }'>
                <tr>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <span class="fontOneRem">{{ item.opDt?.beforeDateFormatDot() }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <span class="fontOneRem">{{ item.opTm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2" style="line-height: 1.2;">
                        <span class="fontOneRem">{{ item.prgNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <span class="fontOneRem">{{ item.prgTypNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <v-chip
                            class="" style="padding-bottom: 2px;"
                            :ripple="false" :color="'level0' + item.idxCd" small>
                            <span class="fontOneRem font-weight-medium white--text">{{ item.idxCdnm?.slice(0, 2) }}</span>
                        </v-chip>
                    </td>
                    <td class="tableBB tableBR px-1 pt-1">
                        <v-chip
                            v-for="(itm, j) in item.bnfcrgrNm" :key="j"
                            class="white pa-2 mx-1 mb-1" color="grey"                          
                            small label outlined>
                            <span>{{ itm }}</span>
                        </v-chip>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <span class="fontOneRem">{{ item.prtcpNum }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <span class="fontOneRem">{{ item.plc }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <span class="fontOneRem">{{ item.mc }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB tableBR px-2">
                        <v-btn
                            class="nmlBfClrImp-white ml-1" min-width="20" height="20"
                            :color="item.prgOpRecPk == null ? 'blue200' : ''"
                            @click="showDialog(item.prgOpPlnPk, item.prgOpRecPk, item.prgInfPk)"
                            small icon>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                </template>
                                <span>프로그램 운영기록</span>
                            </v-tooltip>
                        </v-btn>
                    </td>
                    <td class="tableBB px-1">
                        <div class="d-flex justify-center" style="width: 70px;">
                            <v-btn
                                v-if="item.prgOpRecPk != null"
                                class="nmlBfClrImp-white ml-1" min-width="20" height="20" color="grey006"
                                @click="mtngRcrdPrt(item.prgOpRecPk)"
                                small icon>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                    </template>
                                    <span>출력</span>
                                </v-tooltip>
                            </v-btn>
                            <v-btn
                                class="nmlBfClrImp-white ml-1" min-width="20" height="20" color="grey006"
                                @click="onDelete(item.prgOpPlnPk, index)"
                                small icon>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>삭제</span>
                                </v-tooltip>
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        
        <v-dialog v-model="dialog" persistent max-width="1200">
            <PrgrmNew
                ref="PrgrmNew"
                @isClose="dialog = false"
                @modalEvent="selPrgOpRecWrtList"
            ></PrgrmNew>
        </v-dialog>
    </v-sheet>  
</template>

<script>
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';
import PrgrmNew from '../../components/prgrm/PrgrmNew.vue';

import { selPrgOpRecWrtList, delPrgOpPln } from '../../api/prgrm.js';
import { getStcd } from '../../api/index.js';

export default {
    name: 'PrgSrvRcrd',

    components: {
        expandWeekOnlyPicker,
        PrgrmNew,
    },
    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.selPrgOpRecWrtList()
        })
    },

    computed: {
        getRootEvt: function () {
            return this.$store.getters.getRootRunEvt
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getRootEvt: function (bol) {
            if(bol && this.$store.getters.getRootInObj == null){
                this.selPrgOpRecWrtList()
                this.$store.commit('setRootRunEvt', false)
            }
            else if(bol && this.$store.getters.getRootInObj != null){
                if(Object.keys(this.$store.getters.getRootInObj).length == 3){
                    this.selPrgOpRecWrtList()
                    this.$store.commit('setRootRunEvt', false)
                }
            }
        },
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                if (this.plnPk > 0) {
                    if (this.items[this.key].prgOpPlnPk != undefined) {
                        let obj = {
                            fcltyNum: this.$store.getters.getFcltyNum,
                            prgOpPlnPk: this.items[this.key].prgOpPlnPk,
                            userNm: this.$store.getters.getUserNm,
                        }
                        delPrgOpPln(obj)
                            .then((response) => (this.delPrgOpPlnAfter(response.data)))
                            .catch((error) => console.log(error))
                    } else {
                        this.items.splice(this.key, 1)
                    }
                }

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
        'startWeek': function () {
            this.selPrgOpRecWrtList()
        },
        'cdItems': function () {
            if(this.items.length > 0){
                this.items.forEach((item) => {
                    this.cdItems.forEach((cd) => {
                        if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                    });
                });
            }
        }
    },

    methods: {
        stCdMapping: function (res) {
            this.cdItems = res
            
            if(this.items.length > 0){
                this.items.forEach((item) => {
                    this.cdItems.forEach((cd) => {
                        if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                    });
                });
            }
        },
        getWeek: function (startWeek, endWeek) {
            this.startWeek = startWeek
            this.endWeek = endWeek
        },
        selPrgOpRecWrtList: function () {
            selPrgOpRecWrtList(this.$store.getters.getFcltyNum, this.startWeek, this.endWeek)
                .then((response) => (this.selPrgOpRecWrtListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecWrtList : ' + error))
        },
        selPrgOpRecWrtListAfter: function (res) {
            this.opDt = res.opDt
            this.opTm = res.opTm
            this.items = res

            this.items.forEach((item) => {
                this.cdItems.forEach((cd) => {
                    if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                });
            });

            let chc = this.items.filter( v => v.prgOpRecPk == null )
            if(this.items.length == 0)
                this.prtBtn = true
            else if(this.items.length == chc.length)
                this.prtBtn = true
            else
                this.prtBtn = false
        },
        isModalType: function () {
            this.dialog = false
            this.selPrgOpRecWrtList()
        },
        onDelete(pk, key) {
            this.key = key
            this.plnPk = pk
            this.runAble = '2'
        
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = this.items[key].prgNm
            obj.cntnt = ['프로그램 운영기록을 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });  
        },
        delPrgOpPlnAfter(res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200) 
                this.selPrgOpRecWrtList()
            else {
                obj.tmr = 4500
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        mtngRcrdPrt:function(pk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzPk.push(pk)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        onClickBtn:function(text){
            if(text == "프로그램 일정등록"){
                this.prgrmNewPk = 1
                this.dialog = true
            } else if (text == "삭제"){
                this.onDelete()
            } else if (text == "출력"){
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.prtThrowObj.inqYmdStart = this.startWeek?.afterDateFormatHyp()
                this.prtThrowObj.inqYmdEnd = this.endWeek?.afterDateFormatHyp()
                this.prtThrowObj.list[0].bzPk.splice(0)
                
                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                });
            } 
        },
        showDialog: function (plnPk, recPk, prgInfPk) {
            let obj = {}
            obj.prgOpPlnPk = plnPk
            obj.prgOpRecPk = recPk
            obj.prgInfPk = prgInfPk

            this.plnPk = plnPk
            this.recPk = recPk
            this.prgInfPk = prgInfPk

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: obj, outObj: null, runEvt: false,
            });
        },
    },

    data: () => ({
        prtBtn: true,
        startWeek: 0,
        key: 0,
        plnPk : 0,
        recPk : 0, 
        prgInfPk : 0,
        opDt: '',
        opTm: '',
        runAble: '0',
        dialog: false,
        items: [],
        cdItems: [],
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '프로그램 일정등록', type: 'action', },            
        ],
        headers: [
            { text: '운영일', value: 'opDt', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '운영시간', value: 'opTm', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '프로그램', value: 'prgNm', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '프로그램 구분', value: 'prgTypNm', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '프로그램 유형', value: 'idxCd', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '장소', value: 'plc', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '진행자', value: 'mc', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '기록', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '처리', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '16', inqYYYY: '',
            inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ], 
        },
    }),
};
</script>