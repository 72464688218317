<template>    
    <v-sheet class="px-3" min-width="1280" max-width="1280"> 
        <v-row no-gutters>
            <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
            <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />            
            <span class="d-inline-block ml-2" style="width: 105px;">
                <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" hide-details width="100px" height="35px"
                    single-line rounded outlined dense filled label="이름조회" @input="setSearchText" >
                </v-text-field>
            </span>
            <v-spacer></v-spacer>
            <!-- <span class="fontSeventhRem pt-2">v1</span> -->
        </v-row>
        <v-row class="mt-3" style="overflow-x: scroll;" no-gutters>
            <v-col cols="12">
                <v-data-table
                    class="copayClmsTbl tableFixedElm" locale="ko" height="680"
                    :headers="headers" 
                    :items="bnftOfrList"
                    :loading="loading" :loading-text="loadMsg"
                    :search="schDummy" :custom-filter="customFilter"
                    hide-default-header fixed-header hide-default-footer disable-pagination dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT clmsFixTbl" colspan="1" rowspan='4' style="width:60px; left: 0px; z-index: 3;">{{props.headers[0].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT clmsFixTbl" colspan="1" rowspan='4' style="width:120px; left: 54px; z-index: 3;">{{props.headers[1].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT clmsFixTbl" colspan="1" rowspan='4' style="width:60px; left: 153px; z-index: 3;">{{props.headers[2].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT clmsFixTbl" colspan="1" rowspan='4' style="width:60px; left: 207px; z-index: 3;">{{props.headers[3].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" colspan="1" rowspan='4' style="width:100px;">{{props.headers[4].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" colspan="1" rowspan='4' style="width:100px;">{{props.headers[5].text}}</th> 
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" colspan="1" rowspan='4' style="width:60px;">{{props.headers[6].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" colspan="1" rowspan='4' style="width:110px;">{{props.headers[7].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" colspan="1" rowspan='4' style="width:110px;">{{props.headers[8].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" style="" colspan='6'>수급자 욕구평가&#40;연 1회&#41;</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT" style="" colspan='3'>급여제공 계획&#40;연 1회&#41;</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR tableBT py-1" style="" colspan='2'>급여제공 결과평가<br/>&#40;반기1회&#41;</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBT" colspan='1' rowspan='1'>수급자<br/>&#40;보호자&#41;<br/>참여</th>                            
                            </tr>
                            <tr>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[9].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[10].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[11].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[12].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[13].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">물리&#40;작업&#41;<br />치료평가</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">물리&#40;작업&#41;<br />치료계획</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan='2' rowspan='1' style="top:70px;">급여제공계획</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[18].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='2' style="top:70px;">{{props.headers[19].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp" colspan="1" rowspan='2' style="top:70px;">상담내용급여<br/>제공반영<br/>&#40;연 1회&#41;</th>
                            </tr>
                            <tr class="tableBT">
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="top:105px;">{{props.headers[16].text}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="top:105px;">{{props.headers[17].text}}</th>                            
                            </tr>
                            <tr class="tableBT">
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot101}} / {{bnftOfrList.length}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot102}} / {{bnftOfrList.length}}</th>                            
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot103}} / {{bnftOfrList.length}}</th> 
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot104}} / {{bnftOfrList.length}}</th> 
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot105}} / {{bnftOfrList.length}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot106}} / {{bnftOfrList.length}}</th>                            
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot202}} / {{bnftOfrList.length}}</th> 
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot201}} / {{bnftOfrList.length}}</th> 
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot3}} / {{bnftOfrList.length}}</th>
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot4}} / {{bnftOfrList.length}}</th>                            
                                <th class="clmsHeaderCommon txtAlgnMidImp tableBR" colspan="1" rowspan='1' style="width:110px; top:140px;">{{totObj.tot5}} / {{bnftOfrList.length}}</th> 
                                <th class="clmsHeaderCommon txtAlgnMidImp" colspan="1" rowspan='1' style="width:120px; top:140px;">{{totObj.tot6}} / {{bnftOfrList.length}}</th> 
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white clmsFixTbl zPstn2" style=" left: 0px; z-index: 3;">{{item.rn}}</td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white clmsFixTbl zPstn2" style=" left: 54px; z-index: 3;">{{item.name}}</td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white clmsFixTbl zPstn2" style=" left: 153px; z-index: 3;">
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg" width="24"></v-img>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg" width="24"></v-img>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white clmsFixTbl zPstn2" style="left: 207px; z-index: 3;">{{item.age}}</td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white" style="">{{item.entStcd}}</td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white" style="">{{item.lvnRmNm}}</td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white" style="">
                                <div class="d-flex justify-center align-center">
                                    <span :class="'ic-level level0'+item.acknwRtng">
                                        {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                    </span>
                                </div>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white" style="">{{item.entDt?.beforeDateFormatDot()}}</td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white" style="">{{item.lvDt?.beforeDateFormatDot()}}</td>                                                
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('11',item,item.bnfcrExamHisPk101)">                                
                                <span v-if="item.bnfcrExamHisPk101 == null || item.bnfcrExamHisPk101 == '' || item.bnfcrExamHisPk101 == 0" >
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </span>
                                <span v-else>{{item.examDt101?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('12',item,item.bnfcrExamHisPk102)">
                                <div v-if="item.bnfcrExamHisPk102 == null || item.bnfcrExamHisPk102 == '' || item.bnfcrExamHisPk102 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </div>
                                <div v-else>{{item.examDt102?.beforeDateFormatDot()}}</div>
                                <div v-if="item.examStt102 != '' && item.examStt102 != null" class="d-flex justify-center align-center pb-1">
                                    <!-- <div><v-icon color="#4A148C">mdi-alert-decagram-outline</v-icon></div> -->
                                    <div class="font-weight-medium px-1 alert-msg">{{item.examStt102}}</div>
                                </div>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('13',item,item.bnfcrExamHisPk103)">
                                <div v-if="item.bnfcrExamHisPk103 == null || item.bnfcrExamHisPk103 == '' || item.bnfcrExamHisPk103 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </div>
                                <div v-else>{{item.examDt103?.beforeDateFormatDot()}}</div>                                
                                <div v-if="item.examStt103 != '' && item.examStt103 != null" class="d-flex justify-center align-center">
                                    <!-- <div><v-icon color="#4A148C">mdi-alert-decagram-outline</v-icon></div> -->
                                    <div class="font-weight-medium px-1 alert-msg">{{item.examStt103}}</div>
                                </div>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('14',item,item.bnfcrExamHisPk104)">
                                <span v-if="item.bnfcrExamHisPk104 == null || item.bnfcrExamHisPk104 == '' || item.bnfcrExamHisPk104 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </span>
                                <span v-else>{{item.examDt104?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('15',item,item.bnfcrExamHisPk105)">
                                <span v-if="item.bnfcrExamHisPk105 == null || item.bnfcrExamHisPk105 == '' || item.bnfcrExamHisPk105 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </span>
                                <span v-else>{{item.examDt105?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('16',item,item.bnfcrExamHisPk106)">
                                <span v-if="item.bnfcrExamHisPk106 == null || item.bnfcrExamHisPk106 == '' || item.bnfcrExamHisPk106 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </span>
                                <span v-else>{{item.examDt106?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('17',item,item.bnftOfrPlnHisPk202, item.bnfcrPk202)">                            
                                <span v-if="item.bnftOfrPlnHisPk202 == null || item.bnftOfrPlnHisPk202 == '' || item.bnftOfrPlnHisPk202 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </span>
                                <span v-else>{{item.wrDt202?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('18',item,item.bnftOfrPlnHisPk201, item.bnfcrPk201)">
                                <div v-if="item.bnftOfrPlnHisPk201 == null || item.bnftOfrPlnHisPk201 == '' || item.bnftOfrPlnHisPk201 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else class="d-inline-block tableunwritten">미작성</span>
                                </div>
                                <div v-else>
                                    <div>{{item.wrDt201?.beforeDateFormatDot()}}</div>
                                    <div v-if="item.bzPk != null && item.bzPk > 0" class="py-1">
                                        <span class="result-msg">결과평가반영</span>
                                    </div>
                                </div>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showBizMsg(item,item.bnftOfrPlnHisPk201)">
                                <div v-if="item.bnftOfrPlnHisPk201 == null || item.bnftOfrPlnHisPk201 == '' || item.bnftOfrPlnHisPk201 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != '' && (item.eSigCfm == '미작성' || item.eSigCfm == null)">퇴소</span>
                                    <span v-else :class="['',item.eSigCfm == '미작성'?'d-inline-block tableunwritten':'']">{{item.eSigCfm}}</span>                           
                                </div>
                                <div v-else>
                                    <span :class="['',item.eSigCfm == '미작성'?'d-inline-block tableunwritten':'']">{{item.eSigCfm}}</span>
                                </div>                        
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('20',item,item.bnftOfrPlnHisPk4, item.bnfcrPk4)">                               
                                <span v-if="item.bnftOfrPlnHisPk4 == null || item.bnftOfrPlnHisPk4 == '' || item.bnftOfrPlnHisPk4 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else >
                                        <span v-if="item.alwYn4 == '1'">해당없음</span>
                                        <span v-else class="d-inline-block tableunwritten">미작성</span>
                                    </span>
                                </span>
                                <span v-else>{{item.wrDt4?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp tableBR nmlBfClrImp-white crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('20',item,item.bnftOfrPlnHisPk5, item.bnfcrPk5)">
                                <span v-if="item.bnftOfrPlnHisPk5 == null || item.bnftOfrPlnHisPk5 == '' || item.bnftOfrPlnHisPk5 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else>
                                        <span v-if="item.alwYn5 == '1'">해당없음</span>
                                        <span v-else class="d-inline-block tableunwritten">미작성</span>
                                    </span>
                                </span>
                                <span v-else>{{item.wrDt5?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="clmsBodyCommon txtAlgnMidImp crsShp-Pointer fixedCol" style="" @click="$parent.$parent.showDialog('19',item,item.bnftOfrPlnHisPk6)">                                
                                <span v-if="item.bnftOfrPlnHisPk6 == null || item.bnftOfrPlnHisPk6 == '' || item.bnftOfrPlnHisPk6 == 0">
                                    <span v-if="item.lvDt != null && item.lvDt != ''">퇴소</span>
                                    <span v-else>
                                        <span v-if="item.alwYn6 == '1'">해당없음</span>
                                        <span v-else class="d-inline-block tableunwritten">미작성</span>
                                    </span>
                                </span>
                                <span v-else>{{item.wrDt6?.beforeDateFormatDot()}}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';
import exBtn from '../../components/commons/expandButtonCustom.vue';

export default {
    name: 'AllBnftOfrOldMdl',
        
    components: {
        exBtn
    },
    
    props : {
        inqYYYY : {Number:String, default:1990},
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllBnftOfrList() 
        })
    },

    watch:{
        'inqYYYY':function(val){
            if(val > 1990 &&  val < 2025) this.getAllBnftOfrList()
        }
    },
        
    methods: {
        //수급자평가 목록조회(2025년도 이전)
        getAllBnftOfrList: function (){      
            this.bnftOfrList.splice(0)     
            this.exBtn[0].expandList.splice(0)
            this.exBtn[1].expandList.splice(0)  
            this.loading = true
            let obj = {
                inqYYYY : this.inqYYYY,
            }
            http.post('bnftoffer/selAllBnftOfrList', obj)
                .then((response) => ( this.rstAllBnftOfrList(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selAllBnftOfrList : ' + error))
        },

        //결과처리 :: 수급자평가 목록조회(2025년도 이전)
        rstAllBnftOfrList:function(res){
            // 합계 변수 초기화
            Object.keys(this.totObj).forEach(itm => {
                this.totObj[itm] = 0
            });
            
            if(res.statusCode == '200'){
                if(res.result.codes != null){
                    this.vCd62 = res.result.codes.vCd62
                    this.vCd65 = res.result.codes.vCd65

                    this.vCd65.forEach((cd) => {
                        this.exBtn[0].expandList.push(cd.valcdnm)
                    });

                    this.vCd62.forEach((cd) => {
                        this.exBtn[1].expandList.push(cd.valcdnm)
                    });
                }

                if(res.result.list != null){
                    res.result.list.forEach(itm => {
                        
                        this.vCd65.forEach((cd) => {
                            if(itm.entStcd == cd.valcd) itm.entStcd = cd.valcdnm
                        });//end foreach

                        if(itm.bnfcrExamHisPk101 !=null && itm.bnfcrExamHisPk101 != '')
                            this.totObj.tot101++
                        if(itm.bnfcrExamHisPk102 !=null && itm.bnfcrExamHisPk102 != '')
                            this.totObj.tot102++
                        if(itm.bnfcrExamHisPk103 !=null && itm.bnfcrExamHisPk103 != '')
                            this.totObj.tot103++
                        if(itm.bnfcrExamHisPk104 !=null && itm.bnfcrExamHisPk104 != '')
                            this.totObj.tot104++
                        if(itm.bnfcrExamHisPk105 !=null && itm.bnfcrExamHisPk105 != '')
                            this.totObj.tot105++
                        if(itm.bnfcrExamHisPk106 !=null && itm.bnfcrExamHisPk106 != '')
                            this.totObj.tot106++
                        if(itm.bnftOfrPlnHisPk202 !=null && itm.bnftOfrPlnHisPk202 != '')
                            this.totObj.tot202++
                        if(itm.bnftOfrPlnHisPk201 !=null && itm.bnftOfrPlnHisPk201 != '')
                            this.totObj.tot201++
                        if(itm.eSigCfm == '서명완료')
                            this.totObj.tot3++
                        if(itm.bnftOfrPlnHisPk4 !=null && itm.bnftOfrPlnHisPk4 != '')
                            this.totObj.tot4++
                        if(itm.bnftOfrPlnHisPk5 !=null && itm.bnftOfrPlnHisPk5 != '')
                            this.totObj.tot5++
                        if(itm.bnftOfrPlnHisPk6 !=null && itm.bnftOfrPlnHisPk6 != '')
                            this.totObj.tot6++

                        this.bnftOfrList.push(itm)
                    });


                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.loading = false            
        },

        //검색필터
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        filterSearchD1: function (sch) {
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD2: function (sch) { 
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.vCd62.forEach((cd) => {
                    if(item == cd.valcdnm) item = cd.valcd
                })
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    this.$refs.exBtnClose1.isClose()
                    break;
                case 1: 
                    this.$refs.exBtnClose0.isClose()
                    break;
                default: 
                    this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    break;
            }
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((acknw, i) => {
                    if(i == 0) col2 = filter.acknwRtng.includes(acknw)
                    else col2 = col2 || filter.acknwRtng.includes(acknw)
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            return col0 && col1 && col2;
        },
    },
    
    data: () => ({
        vCd62           : [],
        vCd65           : [],
        exBtn           : [
                                {
                                    index:  0,
                                    btnTitle: '입소현황',
                                    btnIcon: 'house',
                                    exWidth: 104,
                                    exHeigth: 150,
                                    expandList: [],
                                },
                                {
                                    index:  1,
                                    btnTitle: '등급',
                                    btnIcon: 'wheelchair',
                                    exWidth: 104,
                                    exHeigth: 220,
                                    expandList: [],
                                },
                            ],
        schField        : '',
        schDummy        : '',
        schChkField1    : [],
        schChkField2    : [],
        loading         : false,
        loadMsg         : '잠시만 기다려주세요.',
        headers: [
            { text: '연번', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6'},
            { text: '수급자명', value: 'name', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '성별', value: 'gndr', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '나이', value: 'age', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '현황', value: 'entStcd', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '생활실', value: 'lvnRmNm', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '등급', value: 'acknwRtng', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '입소일', value: 'entDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '퇴소일', value: 'lvDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '욕구사정', value: 'bnfcrExamHisPk101', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '낙상위험도', value: 'bnfcrExamHisPk102', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '욕창위험도', value: 'bnfcrExamHisPk103', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '인지기능', value: 'bnfcrExamHisPk104', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '건강수준', value: 'bnfcrExamHisPk105', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '물리(작업)치료평가', value: 'bnfcrExamHisPk106', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '물리(작업)치료계획', value: 'bnftOfrPlnHisPk202', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '작성', value: 'wrtr201', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '서명', value: 'eSigCfm', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '전반기', value: 'bnftOfrPlnHisPk4', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '하반기', value: 'bnftOfrPlnHisPk5', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '상담내용급여 제공반영(연1회)', value: 'bnftOfrPlnHisPk6', align: 'center', sortable: true, width: '100', class: 'black--text'},
        ],
        bnftOfrList : [],
        totObj      : {
            tot101  : 0,
            tot102  : 0,
            tot103  : 0,
            tot104  : 0,
            tot105  : 0,
            tot106  : 0,
            tot202  : 0,
            tot201  : 0,
            tot3    : 0,
            tot4    : 0,
            tot5    : 0,
            tot6    : 0,
        },
    }),
};
</script>