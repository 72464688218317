<template>
    <v-sheet>
        <div v-if="$parent.$parent.$parent.$parent.progress" class="mt-2 d-flex">
            <div                        
                class="d-inline-block"
                style="width: 100%; height: 38px; text-align: center;">
                <v-progress-linear
                    class="mb-1" color="yellow darken-2"
                    indeterminate>
                </v-progress-linear>
                <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
            </div>            
        </div>
        <div v-else class="d-flex">
            <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:55%;font-size:0.9rem !important;">
                <v-form ref="tabform1" lazy-validation>
                    <div class="mt-2">
                        <div class="d-flex greyE01 pa-0 mr-2__5">
                            <div class="d-flex justify-center align-center tableBT tableBB pa-1" style="width:13%;" >
                                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearch"></exBtn>
                                <span class="black--text font-weight-medium">필요영역</span>
                            </div>
                            <div class="tableBT tableBL" style="width:87%;">
                                <div class="d-flex">
                                    <div class="d-flex py-2 justify-center align-center tableBB" style="width:20%">
                                        <span class="black--text font-weight-medium">세부목표</span>
                                    </div>
                                    <div class="tableBL" style="width:80%">
                                        <div class="d-flex tableBB">
                                            <div class="d-flex py-2 justify-center align-center" style="width:35%">
                                                <span class="black--text font-weight-medium">필요내용그룹</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:20%">
                                                <span class="black--text font-weight-medium">주기</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                <span class="black--text font-weight-medium">횟수</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                <span class="black--text font-weight-medium">시간(분)</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:25%">
                                                <span style="width:80px">
                                                    <v-text-field v-model="wrtr" color="blue" height="28" readonly hide-details outlined dense @click="onModal(i,j,k)"></v-text-field>
                                                </span>
                                                <v-btn 
                                                    class="ml-1 brown lighten-3" 
                                                    small 
                                                    min-width="30"
                                                    height="25"  
                                                    dark
                                                    style="padding:0px 1px 0px 2px !important"
                                                    @click="allEmpWrtr()"
                                                    >
                                                    <v-icon size="20">mdi-account-edit</v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height:455px;overflow-y:scroll;" class="ma-0 pa-0">
                            <div v-for="(item, i) in listPln"  :key="i">
                                <div v-if="(srhObj != '') ? (srhObj.findIndex(x=>x == item.clsfc2Cdnm) != -1) : true" class="d-flex pa-0 ma-0">
                                    <div class="d-flex justify-center align-center tableBB pa-1" style="width:13%;" >
                                        <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                                    </div>
                                    <div class="tableBL" style="width:87%;">
                                        <div v-for="(list,j) in item.list" :key="j" class="d-flex">
                                            <div class="d-flex px-1 justify-center align-center tableBB" style="width:20%;position:relative;">
                                                <span v-if="list.subList.length > 0" style="width:100%">
                                                    <v-textarea v-model="list.dtldGls" class="rmrksArea" no-resize  
                                                        :rows="list.subList.length" outlined dense hide-details>
                                                    </v-textarea>
                                                </span>
                                                <span v-else style="width:100%">
                                                    <v-text-field v-model="list.dtldGls" color="blue" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                
                                                <span style="position:absolute;bottom:9px;right:5px;">
                                                    <v-btn
                                                        class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                                        min-width="28" height="28" @click="onCmnts(i,j)"
                                                        outlined
                                                        dark small>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                                            </template>
                                                            <span>확대</span>
                                                        </v-tooltip>
                                                    </v-btn>
                                                </span>
                                            </div>
                                            <div class="tableBL" style="width:80%">
                                                <div  v-for="(subitm,k) in list.subList" :key="k" class="d-flex tableBB">
                                                    <div :class="['fixedCol d-flex justify-center align-center px-1 py-2 ma-0', key1 == i &&  key2 == j &&  key3 == k ? 'sltRow' : 'disSltRow']" style="width:35%" @click="setTmpDtlList(i,j,k)">
                                                        <span class="black--text font-weight-medium">{{subitm.clsfc3Cdnm}}</span>
                                                    </div>
                                                    <div class="tableBL d-flex px-1 py-2 justify-center align-center" style="width:20%">
                                                        <v-select 
                                                            v-model="subitm.itmVal1"                               
                                                            :items="codeItem"
                                                            item-text="valcdnm"
                                                            item-value="valcd"
                                                            dense hide-details outlined
                                                            @click="setTmpDtlList(i,j,k)"
                                                        />
                                                    </div>
                                                    <div class="tableBL d-flex px-1 py-2 justify-center align-center" style="width:10%" >
                                                        <v-text-field v-model="subitm.itmVal2" color="blue" height="28" hide-details outlined dense @click="setTmpDtlList(i,j,k)"></v-text-field>
                                                    </div>
                                                    <div class="tableBL d-flex px-1 py-2 justify-center align-center" style="width:10%">
                                                        <v-text-field v-model="subitm.itmVal3" color="blue" height="28" hide-details outlined dense @click="setTmpDtlList(i,j,k)"></v-text-field>
                                                    </div>
                                                    <div class="tableBL d-flex px-1 py-2 justify-center align-center" style="width:25%">
                                                        <span style="width:80px">
                                                            <v-text-field v-model="subitm.wrtr" color="blue" height="28" readonly hide-details outlined dense @click="onModal(i,j,k)"></v-text-field>
                                                        </span>
                                                        <v-btn 
                                                            class="ml-1 brown lighten-3" 
                                                            small 
                                                            min-width="30"
                                                            height="25"  
                                                            dark
                                                            style="padding:0px 1px 0px 2px !important"
                                                            @click="onModal(i,j,k)"
                                                            >
                                                            <v-icon size="20">mdi-account-edit</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider class="mr-2__5"></v-divider>
                    </div>                    
                </v-form>
            </div>
            <div class="d-inline-block pl-3 mt-2 align-self-start" style="width:45%;font-size:0.9rem !important;"> 
                <v-form ref="tabform2" lazy-validation>
                    <div class="greyE01 d-flex pa-0 mr-2__5" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                                                     
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%">
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;" >
                            <span class="black--text font-weight-medium">필요내용</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">세부제공내용</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                            
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>                            
                    </div>

                    <div v-if="listPln[key1]?.list[key2]?.subList[key3]?.listDtl?.length > 0" style="height:455px;overflow-y:scroll;">
                        <div v-for="(item, n) in listPln[key1].list[key2].subList[key3].listDtl" :key="n" class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                    v-model="item.itmVal" true-value="1" false-value="2" hide-details>
                                </v-checkbox>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">                                    
                                <v-textarea v-model="item.itmCdnm" class="rmrksArea" no-resize  
                                    rows="3" outlined dense hide-details>
                                </v-textarea>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">                                                                    
                                <v-textarea v-model="item.itmDesc" class="rmrksArea" no-resize  
                                    rows="3" outlined dense hide-details>
                                </v-textarea>
                            </div>
                            <div class="pr-1 pt-4" style="width: 15%; border-left: 1px solid #dbdbdb !important;">
                                <span>                                 
                                    <span class="d-flex justify-center align-center" v-if="item.bnftOfrPlnDtlPk !='' && item.bnftOfrPlnDtlPk != null && item.bnftOfrPlnDtlPk != undefined">
                                        <v-btn icon color="grey006" @click="onDelete(n)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                                    </span>
                                    <span class="d-flex justify-center align-center" v-else>
                                        <!-- <v-btn icon color='blue200' @click="onSave(n)">
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn> -->
                                        <v-btn icon color='grey006' @click="onDelete(n)"> 
                                            <v-icon>mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </span>
                            </div>  
                        </div>  
                    </div>
                    <div v-else>
                        <div class="d-flex pa-0 ma-0" style="width:750px;border-bottom: 1px solid #dbdbdb;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                ※'필요내용그룹'을 선택해주세요.
                            </div>
                        </div>
                    </div>                          
                    <v-divider v-if="tmpDtl.length > 6 " class="mr-2__5"></v-divider>             
                </v-form>
            </div>
        </div>        
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector v-if="menu[0]" @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <CmntsMdl v-if="menu[1]" @isClose="isModal(1)" @outText="setDtldGls" :mdlTtl = "mdlTtl" :mdlCmnts="mdlCmnts" :hSize='10'></CmntsMdl>
        </v-dialog>
    </v-sheet>
</template>
<script>
import {  insCmnItmCd} from '../../api/index.js';
import { selBnftOfrPlnDtlWrtList, delBnftOfrPlnDtl} from '../../api/bnftSrv.js';
import EmpSelector from '../commons/EmpSelector.vue';
import CmntsMdl from '../commons/CmntsMdl.vue';
import exBtn from '../commons/expandButtonCustom.vue';

export default {
    name: 'BnftSrvPlnTab2',

    props : {
        inqYYYY: Number,
        bnftOfrPlnHisPk : { type: Number, default: -1 }, 
    },

    components: {
        EmpSelector,
        exBtn,
        CmntsMdl,
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },  

    watch:{
        getSubEvt: function (bol) {
            if(bol && this.rmOfrPlnDtlCnFm){
                this.onActDelete()                
                this.rmOfrPlnDtlCnFm = false

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                
            }
        },
    },

    created:function(){          
    },

    
    mounted: function(){
    },
        
    methods: {   
        onShow(){          
            this.reset()  
        },
        setCmnts:function(value){
            this.cmnts = value
        },
        
        selBnftOfrPlnWrtListAfter(data){                
            this.reset()
            if(data.codes != undefined && data.codes !=null){
                this.codeMapping(data.codes)
            }

            this.exBtn[0].expandList.splice(0)
            
            data.outList.forEach(el => {
                el.visible = true
                this.listPln.push(el)
                this.exBtn[0].expandList.push(el.clsfc2Cdnm)
            });

            this.$parent.$parent.$parent.$parent.progress = false
        },
        setTmpDtlList:function(idx1,idx2,idx3){   
            this.key1 = idx1
            this.key2 = idx2
            this.key3 = idx3

            // if(this.listPln[idx1].list[idx2].subList[idx3] != undefined){ 
            //     this.tmpDtl = this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl                    
            // }
        },
        getDtlList: function(){ 
            if(this.listPln[this.key1].list[this.key2].subList[this.key3] != undefined){
                    let paramObj = {
                        bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                        bnftOfrPlnPk:this.listPln[this.key1].list[this.key2].subList[this.key3].bnftOfrPlnPk,
                        clsfc1Cd:this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc1Cd,
                        clsfc2Cd:this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc2Cd,
                        clsfc3Cd:this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc3Cd,
                    }

                    selBnftOfrPlnDtlWrtList(this.$store.getters.getFcltyNum,paramObj)
                        .then((response) => ( this.resDtlData(response.data)))
                        .catch((error) => console.log(error))
            }
        },
        resDtlData(data){  
            if(this.listPln[this.key1].list[this.key2].subList[this.key3] != undefined){
                this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl.splice(0)

                data.forEach(el => {
                    this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl.push(el)
                });

                // this.tmpDtl = this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl
            }
        },
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },
        reset(){
            this.key1 = -1
            this.key2 = -1
            this.key3 = -1            
            this.delIdx = -1      
            this.listPln.splice(0)
            this.listDtl.splice(0)
            this.tmpDtl.splice(0)
        },

        close(){
            this.srhObj.splice(0)
            this.exBtn[0].expandList.splice(0)
            this.reset()            
        },

        selValidate:function(){
            let res = {
                validateChk : true,
                validateMsg : ''
            }

            this.listPln.forEach(el=>{
                el.list.forEach(x => {                    
                    x.subList.forEach(k=>{
                        if(k.listDtl != undefined){
                            let chk = 0
                            k.listDtl.forEach(e=>{                         
                                if(e.itmVal == '1') {
                                    chk++
                                }
                            })
                            
                            if(chk == 0 && k.itmVal1 != 0 && k.itmVal1 != null){
                                res.validateMsg = k.clsfc3Cdnm+" 필요내용을 선택하여주세요."
                                res.validateChk = false
                            }
                        }
                        
                        if(k.itmVal1 != 0 && k.itmVal1 != null && k.wrtr == null){
                            res.validateMsg = k.clsfc3Cdnm+" 작성자을 선택하여주세요."
                            res.validateChk = false
                        }
    
                        if(k.itmVal1 != 0 && k.itmVal1 != null && k.itmVal3 == null){
                            res.validateMsg = k.clsfc3Cdnm+" 시간(분)을 입력하여주세요."
                            res.validateChk = false
                        }

                        if(k.itmVal1 != 4 && k.itmVal1 != null){
                            if(k.itmVal1 != 0 && (k.itmVal2 == null || k.itmVal2 == '' || k.itmVal2 == 0)){
                                res.validateMsg = k.clsfc3Cdnm+" 횟수를 입력하여주세요."
                                res.validateChk = false
                            }
                        }
                    })
                });
            })

            return res
        },
        selBnftOfrPlnWrtList:function(){
            let bnftOfrPlnWrtList = []
            this.listPln.forEach(el=>{
                el.list.forEach(x => {                      
                    x.subList.forEach(k=>{
                        k.dtldGls = x.dtldGls                    
                        bnftOfrPlnWrtList.push(k)
                    })
                });

            })
            return bnftOfrPlnWrtList
        },        
        codeMapping:function(res){
            this.codeItem = []

            let selR = {
                valcdnm:'선택',
                valcd:'0',
            }
            this.codeItem.push(selR)
            res["vCd45"].forEach(x=>{
                this.codeItem.push(x)
            })

        },

        getEmpInfo: function (obj) {
            if(this.empSltTyp == 'all'){
                this.listPln.forEach(pln => {
                    if(pln.list != null && pln.list != undefined){
                        pln.list.forEach(list => {
                            if(list.subList != null && list.subList != undefined){
                                list.subList.forEach(itm => {
                                    itm.wrtr = obj[0].name
                                    itm.wrtrMmbrPk = obj[0].empPk
                                })
                            }
                        })
                    }
                });
            }else{
                if(this.listPln[this.key1].list[this.key2].subList[this.key3] != undefined){
                    this.listPln[this.key1].list[this.key2].subList[this.key3].wrtr = obj[0].name
                    this.listPln[this.key1].list[this.key2].subList[this.key3].wrtrMmbrPk = obj[0].empPk
                }
            }
        },
        onModal: function (idx1,idx2,idx3) {  
            this.setTmpDtlList(idx1,idx2,idx3)
            this.prtWrtDt = this.$parent.$parent.$parent.$parent.items.wrtDt
            this.menu.splice(0, 1, true)
            this.empSltTyp = ''            
        },
        isModal: function (key) {
            //this.key1 = 0
            //this.key2 = 0
            this.menu.splice(key, 1, false)
        },
        //세부내용 확대
        onCmnts:function(idx1,idx2){
            this.key1 = idx1
            this.key2 = idx2

            this.mdlTtl     = this.listPln[this.key1]?.clsfc2Cdnm + ' 세부목표'
            this.mdlCmnts   = this.listPln[this.key1].list[this.key2].dtldGls

            this.menu.splice(1, 1, true)
        },
        //세부내용 적용
        setDtldGls:function(txt){
            this.listPln[this.key1].list[this.key2].dtldGls = txt
            this.key1 = -1
            this.key2 = -1
        },
        addRow:function(){
            if(this.listPln[this.key1].list[this.key2].subList[this.key3] != undefined){                  
                let addObj = {
                    bnftOfrPlnDtlPk:0,
                    chkyn:false,
                    clsfc1Cd    :this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc1Cd,
                    clsfc1Cdnm  :this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc1Cdnm,
                    clsfc2Cd    :this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc2Cd,
                    clsfc2Cdnm  :this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc2Cdnm,
                    clsfc3Cd    :this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc3Cd,
                    clsfc3Cdnm  :this.listPln[this.key1].list[this.key2].subList[this.key3].clsfc3Cdnm,
                    cmnItmCdPk  :'',
                    fcltyNum    :this.$store.getters.getFcltyNum,
                    itmCd       :'999',
                    itmCdnm     :'',
                    itmDesc     :'',
                    itmVal      :'',
                    valClcd     :'1',
                }

                this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl.push(addObj)
            }
        },
        onSave(idx){
            if(this.listPln[this.key1].list[this.key2].subList[this.key3] != undefined && this.$refs.tabform2.validate()){
                if(this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl[idx] !=undefined) {                      
                    insCmnItmCd(this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl[idx])
                    .then((response) => ( this.insCmnItmCdAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }
        },
        insCmnItmCdAfter(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀 

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getDtlList()
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }
            this.$store.commit('setWrnnInf', obj)
        },
        onDelete:function(idx){
            this.delIdx = idx

            let pk = this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl[this.delIdx].bnftOfrPlnDtlPk

            if(pk != '' && pk != null && pk != null){

                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '세부 필요내용'
                obj.cntnt = [this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl[this.delIdx].itmCdnm+'를(을) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmOfrPlnDtlCnFm = true

                
            }else{
                this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl.splice(idx,1)                
            }
            
        },
        onActDelete(){
            if(this.delIdx > -1){   
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    bnftOfrPlnDtlPk: this.listPln[this.key1].list[this.key2].subList[this.key3].listDtl[this.delIdx].bnftOfrPlnDtlPk,
                }

                delBnftOfrPlnDtl(obj)
                    .then((response) => ( this.onActDeleteAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftoffer/delBnftOfrPlnDtl : ' + error))
            }
            
            this.delIdx = -1
        },
        onActDeleteAfter(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀 

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getDtlList()                
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }       
            this.$store.commit('setWrnnInf', obj)     
        },        
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    break;
            }
        },
        filterSearch:function(srh){  
            this.srhObj = srh    

            // if(this.listPln.filter(x=> this.srhObj.some(e=>e == x.clsfc2Cdnm)).length > 0){
            //     this.listPln.forEach(el => {
            //         el.visible = false
            //     });

            //     this.listPln.filter(x=> this.srhObj.some(e=>e == x.clsfc2Cdnm)).forEach(itm => {
            //         itm.visible = true
            //     });
            // }else{
            //     this.listPln.forEach(el => {
            //         el.visible = true
            //     });
            // }

        },

        //작성저 전체 선택 오픈
        allEmpWrtr:function(){
            this.empSltTyp = 'all'
            this.prtWrtDt = this.$parent.$parent.$parent.$parent.items.wrtDt
            this.menu.splice(0, 1, true)
        }
        
    },
    
    data: () => ({ 
        prtWrtDt:'',
        key1:-1,      //clsfc2Cd
        key2:-1,      
        key3:-1,              
        delIdx:-1,   
        menu: [false,false],
        cmnts:'',
        srhObj:[],
        data:[],   
        codeItem:[],   
        groups:[],
        tmpgroups:[],        
        listPln:[{list:[{subList:[{listDtl:[]}]}]}],
        listDtl:[],
        tmpDtl:[],        
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        exBtn: [
            {
                index:  0,
                btnTitle: '',
                btnIcon: 'listChecks',
                exWidth: 154,
                exHeigth: 300,
                expandList: [],
            },
        ],     
        mdlTtl:'',
        mdlCmnts : '',
        wrtr:'',
        rmOfrPlnDtlCnFm:false,
        empSltTyp:'',
    }),
};
</script>
