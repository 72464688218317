<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">직원정보&nbsp;등록</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="empInfo" lazy-validation>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;" cols="12">
                    <span class="d-inline-block py-5" style="width: 130px;">
                        <v-img min-width="100%" max-width="100%" :key="forceRender"
                            :aspect-ratio="3/4" :src="imgSrc" lazy-src="../../assets/empNoImg.png">
                        </v-img>
                        <div class="fileImgbox d-flex justify-center align-center">
                            <v-file-input class="fileImgAdd mx-1" color="blueBtnColor" v-model="file"
                                prepend-icon="mdi-image-plus-outline" accept="image/*" @change="upImgFile" label="dd"
                                hide-input hide-details>
                            </v-file-input>
                            <v-btn class="mt-4 ml-2" @click="rechkModalTitle='직원 사진', menu.splice(4, 1, true)" icon>
                                <v-icon>mdi-image-remove-outline</v-icon>
                            </v-btn>
                        </div>
                    </span>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col cols="12">
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">직원명</span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                    <span class="d-inline-block px-2" style="width: 100%;">
                                        <v-text-field
                                            v-model="empInfo.name" :rules="[rules.required]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">성별</span>
                                </v-col>
                                <v-col class="py-1 tableBL tableBR" cols="2">
                                    <span class="">
                                        <v-radio-group class="radio-dft-Theme ma-0 pl-2 pt-1" v-model="empInfo.gndr" :rules="[rules.required]" hide-details row>
                                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                                        </v-radio-group>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                    <span class="black--text font-weight-medium">프로그램권한</span>
                                </v-col>
                                <v-col class="d-flex align-center pl-2 py-1 tableBL" cols="4">
                                    <span class="d-inline-block" style="width: 100px;">
                                        <v-text-field
                                            v-model="empInfo.authNm"
                                            color="blue" height="28" readonly hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span>
                                        <v-btn
                                            class="ml-2" color="#ccc"
                                            @click="menu.splice(5, 1, true)"
                                            min-width="30" height="24" small icon>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                        <v-icon size="24">mdi-lock-open-plus-outline</v-icon>
                                                    </div>
                                                </template>
                                                <span>권한선택</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">생년월일</span>
                                </v-col>
                                <v-col class="pl-2 py-1 tableBL tableBR" cols="2">
                                    <span class="d-inline-block" style="width: 133px;">
                                        <CmmDateComp v-model="empInfo.rsdntBirth" :required="true"></CmmDateComp>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">입사일</span>
                                </v-col>
                                <v-col class="pl-2 py-1 tableBL tableBR" cols="2">
                                    <span class="d-inline-block" style="width: 133px;">
                                        <CmmDateComp v-model="empInfo.entDt" :required="true"></CmmDateComp>
                                    </span>
                                </v-col>
                                <!-- 2025.01.09 입사전 건강검진 제출일 작성은 별도 페이지로 이관 -->
                                <!-- <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                    <span class="black--text font-weight-medium">입사전건강검진 제출일</span>
                                </v-col>
                                <v-col class="d-flex pl-2 py-1 tableBL" cols="4">
                                    <span class="d-inline-block" style="width: 133px;">
                                        <CmmDateComp v-model="empInfo.entHlthChdt" :required="false"></CmmDateComp>
                                    </span>
                                </v-col> -->
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">근무구분</span>
                                </v-col>
                                <v-col class="pl-1 py-1 tableBL tableBR" cols="2">
                                    <span class="">
                                        <v-radio-group class="d-flex radio-dft-Theme ma-0" v-model="empInfo.wrkClcd" :rules="[rules.required]" hide-details row>
                                            <v-radio class="pa-0 ma-0 mr-2" v-for="(cd, w) in workCode" :key="w"
                                                :ripple="false" :label="cd.valcdnm" :value="cd.valcd">
                                            </v-radio>
                                        </v-radio-group>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">조편성</span>
                                </v-col>
                                <v-col class="pl-1 py-1 tableBL tableBR" cols="2">
                                    <span class="d-inline-block" style="width: 100px;">
                                        <v-text-field
                                            v-model="empInfo.grpNm"
                                            color="blue" height="28" readonly hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span>
                                        <v-btn
                                            class="ml-2" color="#ccc"
                                            @click="menu.splice(6, 1, true)"
                                            min-width="30" height="24" small icon>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                        <v-icon size="24">mdi-account-group</v-icon>
                                                    </div>
                                                </template>
                                                <span>그룹 선택</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                    <span class="black--text font-weight-medium">휴대폰</span>
                                </v-col>
                                <v-col class="d-flex pl-2 py-1 tableBL" cols="4">
                                    <span class="d-inline-block" style="width: 80px;">
                                        <v-text-field
                                            v-model="empInfo.celphn_1"
                                            :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block d-flex align-center px-1">
                                        <v-icon class="pt-1" size="12">mdi-minus</v-icon>
                                    </span>
                                    <span class="d-inline-block" style="width: 80px;">
                                        <v-text-field
                                            v-model="empInfo.celphn_2"
                                            :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block d-flex align-center px-1">
                                        <v-icon class="pt-1" size="12">mdi-minus</v-icon>
                                    </span>
                                    <span class="d-inline-block" style="width: 80px;">
                                        <v-text-field
                                            v-model="empInfo.celphn_3"
                                            :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">담당직종</span>
                                </v-col>
                                <v-col class="d-flex pl-2 py-1 tableBL tableBR" cols="5">
                                    <span class="d-inline-block" style="width: 160px;">
                                        <v-select
                                            class="wrkSelect"
                                            v-model="empInfo.jobCd" :key="forceRender"
                                            :items="jobCode" item-text="valcdnm" item-value="valcd"
                                            dense hide-details outlined>
                                        </v-select>
                                    </span>
                                    <span class="d-inline-block ml-2" style="width: 106px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="empInfo.dmntSpclsYn"
                                            true-value="1" false-value="2" label="치매전문교육"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" hide-details>
                                        </v-checkbox>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                    <span class="black--text font-weight-medium">전화번호</span>
                                </v-col>
                                <v-col class="d-flex pl-2 py-1 tableBL" cols="4">
                                    <span class="d-inline-block" style="width: 80px;">
                                        <v-text-field
                                            v-model="empInfo.tel_1" :rules="[rules.numberCheck]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block d-flex align-center px-1">
                                        <v-icon class="pt-1" size="12">mdi-minus</v-icon>
                                    </span>
                                    <span class="d-inline-block" style="width: 80px;">
                                        <v-text-field
                                            v-model="empInfo.tel_2" :rules="[rules.numberCheck]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block d-flex align-center px-1">
                                        <v-icon class="pt-1" size="12">mdi-minus</v-icon>
                                    </span>
                                    <span class="d-inline-block" style="width: 80px;">
                                        <v-text-field
                                            v-model="empInfo.tel_3" :rules="[rules.numberCheck]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">아이디</span>
                                </v-col>
                                <v-col class="d-flex align-center py-1 tableBL tableBR" cols="2">
                                    <span class="d-inline-block pl-2 pr-1" style="width: 120px;">
                                        <v-text-field
                                            v-model="empInfo.id" :rules="[rules.required]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">비밀번호</span>
                                </v-col>
                                <v-col class="d-flex align-center py-1 tableBL tableBR" cols="2">
                                    <span class="d-inline-block px-2" style="width: 120px;">
                                        <v-text-field
                                            v-model="empInfo.passwd" :rules="[rules.required]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                    <span class="black--text font-weight-medium">비밀번호 확인</span>
                                </v-col>
                                <v-col class="d-flex align-center py-1 tableBL" cols="4">
                                    <span class="d-inline-block px-2" style="width: 120px;">
                                        <v-text-field
                                            v-model="empInfo.cnfrmPwd" :rules="[rules.required]"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">이메일</span>
                                </v-col>
                                <v-col class="d-flex pl-2 py-1 tableBL" cols="8">
                                    <span class="d-inline-block" style="width: 120px;">
                                        <v-text-field
                                            v-model="empInfo.emailId"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block d-flex align-center px-2">
                                        <v-icon class="" size="16">mdi-at</v-icon>
                                    </span>
                                    <span class="d-inline-block" style="width: 120px;">
                                        <v-text-field
                                            v-model="empInfo.emailDmn" :disabled="eDmn != '0' ? true : false"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block ml-2" style="width: 130px;">
                                        <v-select
                                            class="wrkSelect"
                                            v-model="eDmn" :key="forceRender"
                                            :items="eDmnCode" item-text="valcdnm" item-value="valcd"
                                            dense hide-details outlined>
                                        </v-select>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">주소</span>
                                </v-col>
                                <v-col class="d-flex pl-2 py-1 tableBL" cols="11">
                                    <span class="d-inline-block" style="width: 78px;">
                                        <v-text-field
                                            v-model="empInfo.zip"
                                            color="blue" height="28" disabled hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span>
                                        <v-btn
                                            class="ml-1" color="#3C9099" style="padding: 1px 0px 0px 3px !important"
                                            @click="menu.splice(3, 1, true)"
                                            min-width="30" height="24" dark small>
                                            <v-tooltip top >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                                </template>
                                                <span>주소검색</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                    <span class="d-inline-block ml-2" style="width: 41%;">
                                        <v-text-field
                                            v-model="empInfo.addr"
                                            color="blue" height="28" disabled hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block ml-1" style="width: 41%;">
                                        <v-text-field
                                            v-model="empInfo.addrDtl"
                                            color="blue" height="28" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="ma-0 pa-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                                    <span class="black--text font-weight-medium">비고</span>
                                </v-col>
                                <v-col class="d-flex px-2 py-1 tableBL" cols="11">
                                    <v-text-field
                                        v-model="empInfo.rmrks"
                                        color="blue" height="28" hide-details outlined dense>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="white--text" color="blueBtnColor" @click="onSave()" height="26" samll rounded>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span style="font-size: 1rem;">저장</span>
                    </v-btn>
                    <!-- <v-btn
                        class="white--text ml-3" color="grey006" @click="rechkModalTitle='직원 정보', menu.splice(4, 1, true)" height="26" samll rounded>
                        <v-icon small>mdi-trash-can-outline</v-icon>
                        <span style="font-size: 1rem;">삭제</span>
                    </v-btn> -->
                </v-col>
            </v-row>
        </v-form>
       
        <v-dialog v-model="menu[3]" content-class="round" max-width="500" persistent eager>
            <AdrsSrch ref="adrsSrch" @onDataThr="onAddressComp" @isClose="datePickClose(3)"></AdrsSrch>
        </v-dialog>
        <v-dialog v-model="menu[4]" max-width="500">  
            <btnModalDelete :title="rechkModalTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
        <v-dialog v-model="menu[5]" max-width="900" persistent>  
            <CmnAuthGrpMdl @modalClose="modalChanger" @prgmSlt="aplyPrgmAuth"></CmnAuthGrpMdl>  
        </v-dialog>
        <v-dialog v-model="menu[6]" max-width="800" persistent>
            <EmpGrpChcMdl
                :grpList="typeof $parent.$parent.$parent.$parent.$refs.empGrpModal.grpList == 'undefined' ? [] : $parent.$parent.$parent.$parent.$refs.empGrpModal.grpList"
                @modalClose="modalChanger" @grpObj="aplyGrp">
            </EmpGrpChcMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { insEmp, delEmp } from '../../api/emp.js';
import { uploadToFile, delAtchdFile } from '../../api/index.js';
import { remoteURL } from '@/api/baseAxios';

import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import AdrsSrch from '../commons/AdrsSrch.vue';
import CmnAuthGrpMdl from '../commons/CmnAuthGrpMdl.vue';
import EmpGrpChcMdl from './EmpGrpChcMdl.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EmpInfoNew',

    props : {
   
    },
        
    components: {
        AdrsSrch,
        btnModalDelete,
        CmnAuthGrpMdl,
        EmpGrpChcMdl,
        CmmDateComp,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.$parent.$parent.vCd41.length > 0)
                this.workCode = this.$parent.$parent.$parent.$parent.$parent.$parent.vCd41
            if(this.$parent.$parent.$parent.$parent.$parent.$parent.vCd64.length > 0)
                this.eDmnCode = this.$parent.$parent.$parent.$parent.$parent.$parent.vCd64
            if(this.$parent.$parent.$parent.$parent.$parent.$parent.vCd42.length > 0)
                this.jobCode = this.$parent.$parent.$parent.$parent.$parent.$parent.vCd42
        })
    },

    computed: {
        getWorkCode: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.vCd41
        },
        getEmailDmnCode: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.vCd64
        },
        getJobCode: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.vCd42
        },
    },

    watch:{
        getWorkCode: function (value) {
            this.workCode = value
        },
        getEmailDmnCode: function (value) {
            this.eDmnCode = value
        },
        getJobCode: function (value) {
            this.jobCode = value
        },
        getParentEmpInfo: function (value) {
            if(value == null) return 0

            this.empInfo = Object.assign({}, value)
            this.empInfo.rsdntBirth = this.empInfo.rsdntBirth?.afterDateFormatDot().beforeDateFormatHyp()
            this.empInfo.entHlthChdt = this.empInfo.entHlthChdt?.afterDateFormatDot().beforeDateFormatHyp()
            this.empInfo.entDt = this.empInfo.entDt?.afterDateFormatDot().beforeDateFormatHyp()
            this.jobCode.forEach(cd => {
                if(cd.valcdnm == this.empInfo.jobCd) this.empInfo.jobCd = cd.valcd
            });
            if(this.empInfo.celphn_1 == null) this.empInfo.celphn_1 = ''
            if(this.empInfo.celphn_2 == null) this.empInfo.celphn_2 = ''
            if(this.empInfo.celphn_3 == null) this.empInfo.celphn_3 = ''
            if(this.empInfo.tel_1 == null) this.empInfo.tel_1 = ''
            if(this.empInfo.tel_2 == null) this.empInfo.tel_2 = ''
            if(this.empInfo.tel_3 == null) this.empInfo.tel_3 = ''

            this.empInfo.jobCd
        },
        getParentEmpImg: function (value) {
            this.imgSrc = value
        },
        'eDmn': function () {
            if(this.eDmn == '0')
                this.empInfo.emailDmn = ''
            else {
                this.eDmnCode.forEach(cd => {
                    if(cd.valcd == this.eDmn) this.empInfo.emailDmn = cd.valcdnm
                });
            }
        },
    },
    
    methods: {
        setEmpInfo: function (obj) {
            insEmp(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmp : ' + error))
        },

        removeEmpInfo: function (pk) {
            delEmp(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmp : ' + error))
        },

        asyncResultChk: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.clearForm()
                this.onClose()
                this.$parent.$parent.$parent.$parent.$refs.empGrpModal.getEmpGrpList()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

            this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
            this.$parent.$parent.$parent.$parent.$parent.$parent.filterSearch(['재직'])
        },

        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmp : ' + error))
        },

        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.asyncResultChk(res)
                this.file = null
                this.resFile = null

                window.URL.revokeObjectURL(this.imgSrc)
                this.imgSrc = ''
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        preEmpInfo: function (obj) {
            // searching data
            let trg = this.jobCode.findIndex( v => v.valcd == obj.jobCd )
            if(trg > -1)
                obj.jobCdNm = this.jobCode[trg].valcdnm
            else
                obj.jobCdNm = ''

            //convert date format
            obj.entDt = obj.entDt?.afterDateFormatHyp()
            obj.rsdntBirth = obj.rsdntBirth?.afterDateFormatHyp()
            if(obj.entHlthChdt != '') obj.entHlthChdt = obj.entHlthChdt?.afterDateFormatHyp()

            //base data add
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.empPk = 0
            obj.wrkStcd = '1'
            obj.pstnCd = '3'
            obj.hisClcd = '4'
            obj.hisCdAr = []

            //file add
            if(this.resFile != null){
                this.resFile.atchdFilePk = obj.atchdFilePk
                obj.files = this.resFile
            }
            else
                obj.files = {}

            //check validate
            let chk = this.$refs.empInfo.validate()
            if(chk)
                this.setEmpInfo(obj)
        },
        upImgFile: function () {
            let formData = new FormData();

            if(this.file != null){
                if(this.file?.size > 0){
                    formData.append("files", this.file);
                    uploadToFile(formData)
                        .then((response) => ( this.cvPreview(response.data) ))
                        .catch((error) => console.log('upload error /opr/atchdFileUpload : ' + error))
                }
            }
        },
        cvPreview: function (res) {
            this.resFile = res

            let path = remoteURL + 'opr/imgPrview?'
            let fileName = 'fileNm=' + res.fileNm
            let filePath = 'filePth=' + res.filePth
            let fileCode = 'fileKndCd=2'

            let tmp = new Image()
            tmp.src = path+fileCode+'&'+filePath+'&'+fileName
            tmp.crossOrigin = 'Anonymous';

            let canvas = document.createElement("canvas")
            let context = canvas.getContext("2d")
            let path1 = this

            tmp.onload = function() {
                canvas.width = this.width
                canvas.height = this.height
                context.drawImage(tmp, 0, 0)

                canvas.toBlob((blob) => {
                    let url = URL.createObjectURL(blob)
                    path1.imgSrc = url
                })
            }
        },
        delModalAct: function(res){
            if(res == 9990){    //OK
                if(this.rechkModalTitle == '직원 정보'){
                    this.$parent.$parent.$parent.$parent.removeEmpInfo(this.empInfo.empPk)
                }
                else if(this.rechkModalTitle == '직원 사진'){
                    this.removeAthFile(this.empInfo.atchdFilePk)
                }

                this.menu.splice(4, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(4, 1, false)
        },
        clearForm: function () {
            this.empInfo = Object.assign({
                name: '', gndr: '', rsdntBirth: '', entDt: '', entHlthChdt: '', wrkClcd: '', jobCd: '1',
                celphn_1: '', celphn_2: '', celphn_3: '', zip: '', addr: '', addrDtl: '', sysPrvgPk: '', rmrks: '',
                tel_1: '', tel_2: '', tel_3: '', emailId: '', emailDmn: '',
            })
            
            this.eDmn = '0'
            this.file = null
            this.resFile = null

            window.URL.revokeObjectURL(this.imgSrc)
            this.imgSrc = ''

            this.forceRender =+ 1
        },
        onAddressComp: function (result) {
            this.empInfo.zip = result.zonecode
            this.empInfo.addr = result.address
            this.datePickClose(3)
        },

        aplyPrgmAuth: function (value) {
            this.empInfo.cmnAuthGrpPk = value.cmnAuthGrpPk
            this.empInfo.authNm = value.authNm
        },

        aplyGrp: function (value) {
            this.empInfo.empGrpPk = value.empGrpPk
            this.empInfo.grpNm = value.grpNm
        },

        onSave: function () {
            let obj = {}

            obj = Object.assign({}, this.empInfo)
            this.preEmpInfo(obj)
        },

        onClose: function () {
            this.$emit('modalClose', '0')
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [false, false, false, false, false, false],
        tipTxt: [false, false],
        rechkModalTitle: '',
        eDmn: '0',
        forceRender: 0,
        file: null,
        resFile: null,
        imgSrc: '',
        workCode: [],
        eDmnCode: [],
        jobCode: [],
        empInfo: {
            name: '', gndr: '', rsdntBirth: '', entDt: '', entHlthChdt: '', wrkClcd: '', jobCd: '0', empGrpPk: 0, grpNm: '',
            celphn_1: '', celphn_2: '', celphn_3: '', zip: '', addr: '', addrDtl: '', sysPrvgPk: '', rmrks: '',
            tel_1: '', tel_2: '', tel_3: '', emailId: '', emailDmn: '', empAcntPk: 0, cmnAuthGrpPk: 0, authNm: '',
            id: '', passwd: '', cnfrmPwd: '',
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            pwdSizeCheck: value => (value && value.length > 3) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>