<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1260" height="835">              
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">3-5-1. 전체건강기록&#40;구강&#41;</span>
            </div>
            <v-spacer></v-spacer>
        </div> 
        <div class="pl-1 mt-5">
            <v-tabs
                v-model="tab"
                class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :class="item.class" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item v-if="tab == 0" transition="fade-transition">
                    <OralCareMgmt v-if="tab==0"></OralCareMgmt>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import OralCareMgmt from '../../components/bnftrcrd/OralCareMgmt.vue';

export default {
    name: 'DntObsMgmt',
        
    components: {
        OralCareMgmt,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        
    },
    
    data: () => ({
        tab         : 0,
        tabItems    : [
            { index: 0, tab: '구강 관찰', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
    }),
};
</script>