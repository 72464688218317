<template>
    <v-sheet class="rounded-lg rounded-l-0 posn-rltv px-3" min-width="1260" max-width="1560" height="835">
        <v-divider class="white my-1"></v-divider>
        <v-row class="pa-0 align-center" no-gutters>
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">1-2. 상담일지</span>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-tabs
            v-model="tab"
            class="tab-dft-Theme mx-2" active-class="black"
            center-active hide-slider>
            <v-tab
                v-for="item in tabItems" :key="item.tab"
                :class="item.class" :disabled="item.enter">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2 mx-2" style="padding: 1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-row class="pa-0 mx-2" no-gutters>
                <v-col cols="4">
                    <v-row class="pa-0" no-gutters>
                        <CmmExpnBtn
                            v-model="isEntStt"
                            :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                            :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                        </CmmExpnBtn>
                        <CmmExpnBtn
                            v-model="isAcknwStt"
                            :btnNm="'등급'" vTyp="1" :bImg="'ic-wheelchair'"
                            :iList="vCd62" @onList="dmyTxt = String(Number(dmyTxt)+1)"
                            class="ml-2">
                        </CmmExpnBtn>
                        <span class="d-inline-block ml-2" style="width: 106px; height: 34px;">
                            <v-text-field
                                v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                                class="txt-Theme" height="34" label="이름조회"
                                background-color="white" prepend-inner-icon="mdi-magnify"
                                single-line outlined dense filled hide-details>
                            </v-text-field>
                        </span>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
                <v-spacer></v-spacer>
                <v-col class="d-flex align-center pr-1" style="height: 34px;" cols="4">
                    <v-spacer></v-spacer>
                    <span v-if="tab==1" class="fontNineThRem grey006--text">※ 신규 수급자 입소일부터 4주간 주 1회 이상 상담</span>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mx-2" color="#ddd"></v-divider>
            <v-tab-item transition="false" eager>
                <v-data-table
                    v-if="tab == 0"
                    class="greyE00-hdr-tbl mx-2" height="656" locale="ko"
                    :headers="tblHdr" :items="cnslnJrnlsList" item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"
                    :search="dmyTxt" :custom-filter="srchFltr"
                    fixed-header disable-pagination hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props, on }" >
                        <thead>
                            <tr>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[0].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span class="">{{ props.headers[1].text }}</span>
                                    <v-icon
                                        @click="on.sort(props.headers[1].value)"
                                        class="ml-1" size="14">
                                        mdi-swap-vertical-bold
                                    </v-icon>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[2].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[3].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[4].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[5].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[6].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[7].text }}</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[8].text }}</span>
                                </th>
                                <th style="padding: 4px !important;" colspan="4" rowspan='1'>
                                    <span>상담이력</span>
                                </th>
                                <th style="width: 88px;" colspan="1" rowspan='3'>
                                    <v-row class="pa-0 justify-center align-center" no-gutters>
                                        <span class="black--text">{{ props.headers[9].text }}</span>
                                        <v-btn
                                            style="margin-left: 2px;" width="18" height="18"
                                            @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 2)"
                                            icon>
                                            <v-icon size="17">mdi-help-circle-outline</v-icon>
                                        </v-btn>
                                    </v-row>
                                </th>
                                <th colspan="1" rowspan='3'>
                                    <span>{{ props.headers[10].text }}</span>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    v-for="(qtr, i) in 4" :key="i"
                                    class="tableBR" style="padding-top: 4px !important; padding-bottom: 5px !important;"
                                    colspan="1" rowspan='1'>
                                    <span>{{ qtr }}분기</span>
                                </th>
                            </tr>
                            <tr>
                                <th style="padding-top: 4px !important; padding-bottom: 5px !important;" colspan="9" rowspan='1'>
                                    <v-row class="pa-0 justify-center align-center" no-gutters>
                                        <v-icon size="24">mdi-star-four-points-small</v-icon>
                                        <span class="grey006--text mx-1">상담 수급자 수 / 대상 수급자 수</span>
                                    </v-row>
                                </th>
                                <th
                                    v-for="(trgCnt, j) in cnslnJrnlsTot" :key="j"
                                    class="tableBR" style="padding-top: 4px !important; padding-bottom: 5px !important;"
                                    colspan="1" rowspan='1'>
                                    <span>{{ cnslnJrnlsList.filter( v => v['cnsCnt' + (j+1) +'q'] > 0 ).length }}</span>
                                    <span class="mx-1">/</span>
                                    <span>{{ trgCnt.totCnt }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.rn }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <v-img
                                    v-if="item.gndr == 'F'"
                                    class="d-inline-block" style="vertical-align: middle;"
                                    src="../../assets/icon/ic-female.svg" width="24">
                                </v-img>
                                <v-img
                                    v-else-if="item.gndr == 'M'"
                                    class="d-inline-block" style="vertical-align: middle;"
                                    src="../../assets/icon/ic-male.svg" width="24">
                                </v-img>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.age }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.entStcdNm }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.acknwPrdEndDot }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.entDtDot }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.lvDtDot }}</span>
                            </td>
                            <td
                                v-for="(qtr, k) in 4" :key="k"
                                class="txtAlgnMid tableBR px-1 py-2">
                                <v-btn
                                    v-if="item['cnsCnt' + (k+1) + 'q'] > -1"
                                    class="px-0" width="52" min-width="40" height="26"
                                    :color="item['cnsCnt' + (k+1) + 'q'] == 0 ? 'red' : 'black'"
                                    @click="onCnslnJrnlsMdl(item, k)"
                                    outlined>
                                    <span v-if="item['cnsCnt' + (k+1) + 'q'] == 0">미작성</span>
                                    <span v-else>{{ item['cnsCnt' + (k+1) + 'q'] }}건</span>
                                </v-btn>
                                <v-icon v-else size="14">mdi-minus-thick</v-icon>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.bnftOfrCnt }}건</span>
                            </td>
                            <td class="txtAlgnMid px-1 py-2">
                                <v-btn
                                    class="nmlBfClrImp-white"
                                    color="grey006" min-width="20" height="20"
                                    :disabled="item.cnsTotCnt < 1"
                                    @click="cnslnJrnlsPrt(item)"
                                    small icon>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                        </template>
                                        <span>상담일지 출력</span>
                                    </v-tooltip>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item transition="false" eager>
                <v-data-table
                    v-if="tab == 1"
                    class="greyE00-hdr-tbl mx-2" height="644" locale="ko"
                    :headers="tblHdr" :items="entCnslnJrnlsList" item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"
                    :search="dmyTxt" :custom-filter="srchFltr"
                    fixed-header disable-pagination hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props, on }" >
                        <thead>
                            <tr>
                                <th>
                                    <span>{{ props.headers[0].text }}</span>
                                </th>
                                <th>
                                    <span class="ml-2">{{ props.headers[1].text }}</span>
                                    <v-icon
                                        @click="on.sort(props.headers[1].value)"
                                        class="ml-1" size="14">
                                        mdi-swap-vertical-bold
                                    </v-icon>
                                </th>
                                <th>
                                    <span>{{ props.headers[2].text }}</span>
                                </th>
                                <th>
                                    <span>{{ props.headers[3].text }}</span>
                                </th>
                                <th>
                                    <span>{{ props.headers[4].text }}</span>
                                </th>
                                <th>
                                    <span>{{ props.headers[5].text }}</span>
                                </th>
                                <th>
                                    <span>{{ props.headers[6].text }}</span>
                                </th>
                                <th>
                                    <span>{{ props.headers[7].text }}</span>
                                </th>
                                <th>
                                    <span>{{ props.headers[8].text }}</span>
                                </th>
                                <th v-for="(qtr, k) in 4" :key="k">
                                    <span>{{ k + 1 }}주차</span>
                                </th>
                                <th colspan="1" rowspan='2'>
                                    <span>{{ props.headers[10].text }}</span>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    style="padding-top: 4px !important; padding-bottom: 5px !important; top: 37px !important;"
                                    colspan="9" rowspan='1'>
                                    <v-row class="pa-0 justify-center align-center" no-gutters>
                                        <v-icon size="24">mdi-star-four-points-small</v-icon>
                                        <span class="grey006--text mx-1">상담 수급자 수 / 대상 수급자 수</span>
                                    </v-row>
                                </th>
                                <th
                                    v-for="(trgCnt, j) in entCnslnJrnlsTot" :key="j"
                                    class="tableBR"
                                    style="padding-top: 4px !important; padding-bottom: 5px !important; top: 37px !important;"
                                    colspan="1" rowspan='1'>
                                    <span>{{ entCnslnJrnlsList.filter( v => v['cnsCnt' + (j+1) +'w'] > 0 ).length }}</span>
                                    <span class="mx-1">/</span>
                                    <span>{{ trgCnt.totCnt }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.rn }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <v-img
                                    v-if="item.gndr == 'F'"
                                    class="d-inline-block" style="vertical-align: middle;"
                                    src="../../assets/icon/ic-female.svg" width="24">
                                </v-img>
                                <v-img
                                    v-else-if="item.gndr == 'M'"
                                    class="d-inline-block" style="vertical-align: middle;"
                                    src="../../assets/icon/ic-male.svg" width="24">
                                </v-img>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.age }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.entStcdNm }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.acknwPrdEndDot }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.entDtDot }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.lvDtDot }}</span>
                            </td>
                            <td
                                v-for="(qtr, k) in 4" :key="k"
                                :class="[
                                    'txtAlgnMid tableBR px-1 py-2',
                                    item['cnsCnt' + (k+1) + 'w'] == 0 && item['cnsYn' + (k+1) + 'w'] == '1' ? 'nmlBfClrImp-yellow001' : ''
                                ]" @click="onEntCnslnJrnlsMdl(item, k)">
                                <span v-if="item['cnsYn' + (k+1) + 'w'] == '1'" class="crsShp-Pointer">
                                    <v-row class="pa-0 justify-center" no-gutters>
                                        <span class="fontOneRem" v-if="item['cnsCnt' + (k+1) + 'w'] == 0">미작성</span>
                                        <span class="fontOneRem" v-else>{{ item['cnsCnt' + (k+1) + 'w'] }}건</span>
                                    </v-row>
                                    <v-row class="pa-0 justify-center" no-gutters>
                                        <span class="fontNineThRem">&#40; {{ $moment(item['cnclDtStrt' + (k+1)], 'YYYYMMDD').format('YY.MM.DD') }}&nbsp;~&nbsp;</span>
                                        <span class="fontNineThRem">{{ $moment(item['cnclDtEnd' + (k+1)], 'YYYYMMDD').format('YY.MM.DD') }} &#41;</span>
                                    </v-row>
                                </span>
                                <v-icon v-else size="14">mdi-minus-thick</v-icon>
                            </td>
                            <td class="txtAlgnMid px-1 py-2">
                                <v-btn
                                    class="nmlBfClrImp-white"
                                    color="grey006" min-width="20" height="20"
                                    :disabled="item.cnsTotCnt < 1"
                                    @click="cnslnJrnlsPrt(item)"
                                    small icon>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                        </template>
                                        <span>상담일지 출력</span>
                                    </v-tooltip>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <CnslnJrnlsMdl v-if="lclMdlInf.typ == '01'" @isClose="offCnslnJrnlsMdl"></CnslnJrnlsMdl>
            <EntCnslnJrnlsMdl v-else-if="lclMdlInf.typ == '02'" @isClose="offCnslnJrnlsMdl"></EntCnslnJrnlsMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import CnslnJrnlsMdl from '../../components/bnfcr/CnslnJrnlsMdl.vue';                   // 상담일지
import EntCnslnJrnlsMdl from '../../components/bnfcr/EntCnslnJrnlsMdl.vue';             // 입소 상담일지

export default {
    name: 'BnfcrCnslnJrnls',
    
    components: {
        CmmExpnBtn,
        expandYearOnlyPicker,
        CnslnJrnlsMdl,
        EntCnslnJrnlsMdl,
    },

    created: function(){
       
    },

    computed: {
    
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['62', '65', '104'])
        })
    },

    watch: {
       'tab': function(){
            this.dmyTxt = ''
            this.srchTxt = ''
            this.isEntStt = false
            this.isAcknwStt = false

            switch (this.tab) {
                case 0:
                    this.getCnslnHisList(this.sltYyyy)
                    break;
                case 1:
                    this.getEntCnslnHisList(this.sltYyyy)
                    break;
                default:
                    break;
            }
        },
    },
    
    methods: {
        // 공통 코드 다중 조회
        getCode: function (arr) {
            let obj = {}
            obj.valClcdS = arr
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        stCdMapping: function (res){
            if(res.vCd62.length > 0)
                res.vCd62.forEach(itm => {
                    itm.isOn = true
                });
            this.vCd62 = res.vCd62

            if(res.vCd65.length > 0)
                res.vCd65.forEach(itm => {
                    itm.isOn = true
                });
            this.vCd65 = res.vCd65
            this.vCd104 = res.vCd104

            if(this.cnslnJrnlsList.length > 0){
                this.cnslnJrnlsList.forEach(itm => {
                    let chc = this.vCd65.findIndex( v => v.valcd == itm.entStcd )
                    if(chc > -1)
                        itm.entStcdNm = this.vCd65[chc].valcdnm
                });
            }

            if(this.entCnslnJrnlsList.length > 0){
                this.entCnslnJrnlsList.forEach(itm => {
                    let chc = this.vCd65.findIndex( v => v.valcd == itm.entStcd )
                    if(chc > -1)
                        itm.entStcdNm = this.vCd65[chc].valcdnm
                });
            }

            this.valCdApply()
        },

        // 상담일지 다건 조회
        getCnslnHisList: function (year) {
            this.isLoad = true
            this.cnslnJrnlsList.splice(0)

            this.cnslnJrnlsTot.forEach(itm => {
                itm.totCnt = 0
            });

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.inqYYYY = year

            http.post('bnfcr/selCnslnHisList', obj)
                .then((response) => ( this.cnslnHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        
        cnslnHisRst: function (res) {
            if(Array.isArray(res)){
                res.forEach(itm => {
                    let chc = this.vCd65.findIndex( v => v.valcd == itm.entStcd )
                    if(chc > -1)
                        itm.entStcdNm = this.vCd65[chc].valcdnm

                    if(itm.acknwPrdEnd != '' && itm.acknwPrdEnd != null)
                        itm.acknwPrdEndDot = this.$moment(itm.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.acknwPrdEndDot = ''
                    if(itm.entDt != '' && itm.entDt != null)
                        itm.entDtDot = this.$moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.entDtDot = ''
                    if(itm.lvDt != '' && itm.lvDt != null)
                        itm.lvDtDot = this.$moment(itm.lvDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.lvDtDot = ''

                    if(itm.wrtPsbYn1q == '1')
                        this.cnslnJrnlsTot[0].totCnt += 1
                    if(itm.wrtPsbYn2q == '1')
                        this.cnslnJrnlsTot[1].totCnt += 1
                    if(itm.wrtPsbYn3q == '1')
                        this.cnslnJrnlsTot[2].totCnt += 1
                    if(itm.wrtPsbYn4q == '1')
                        this.cnslnJrnlsTot[3].totCnt += 1

                    itm.cnsTotCnt = 0
                    for (let index = 1; index < 5; index++) {
                        if(itm['cnsCnt'+index+'q'] > -1)
                            itm.cnsTotCnt += itm['cnsCnt'+index+'q']
                    }
                });
            
                this.cnslnJrnlsList = res
            }

            this.isLoad = false
        },

        // 입소 상담일지 다건 조회
        getEntCnslnHisList: function (year) {
            this.isLoad = true
            this.entCnslnJrnlsList.splice(0)

            this.entCnslnJrnlsTot.forEach(itm => {
                itm.totCnt = 0
            });

            let obj = {}
            obj.inqYYYY = year
            obj.cnslnClcd = '1'

            http.post('bnfcrAuth/selEntCnslnHisList', obj)
                .then((response) => ( this.entCnslnHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selEntCnslnHisList : ' + error))
        },

        entCnslnHisRst: function (res) {
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    let chc = this.vCd65.findIndex( v => v.valcd == itm.entStcd )
                    if(chc > -1)
                        itm.entStcdNm = this.vCd65[chc].valcdnm

                    if(itm.lvDt == null) itm.lvDt = ''
                    if(itm.entDt != '' && itm.entDt != null)
                        itm.entDtDot = this.$moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.entDtDot = ''
                    if(itm.lvDt != '' && itm.lvDt != null)
                        itm.lvDtDot = this.$moment(itm.lvDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.lvDtDot = ''
                    if(itm.acknwPrdEnd != '' && itm.acknwPrdEnd != null)
                        itm.acknwPrdEndDot = this.$moment(itm.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.acknwPrdEndDot = ''

                    if(itm.cnsYn1w == '1')
                        this.entCnslnJrnlsTot[0].totCnt += 1
                    if(itm.cnsYn2w == '1')
                        this.entCnslnJrnlsTot[1].totCnt += 1
                    if(itm.cnsYn3w == '1')
                        this.entCnslnJrnlsTot[2].totCnt += 1
                    if(itm.cnsYn4w == '1')
                        this.entCnslnJrnlsTot[3].totCnt += 1

                    itm.cnsTotCnt = 0
                    for (let index = 1; index < 5; index++) {
                        if(Number(itm['cnsCnt'+index+'w']) > -1)
                            itm.cnsTotCnt += Number(itm['cnsCnt'+index+'w'])
                    }

                });

                this.entCnslnJrnlsList = res.result.list
            }

            this.isLoad = false
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 등급 검색
            let acknwRunChc = this.vCd62.filter( v => v.isOn == true )
            if(acknwRunChc.length == 0)
                field3 = false
            else{
                acknwRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.acknwRtng == itm.valcd
                    else field3 = field3 || filter.acknwRtng == itm.valcd
                });
            }

            return field1 && field2 && field3
        },

        // 연도 변경 이벤트
        getYear: function(year){
            this.sltYyyy = year

            switch (this.tab) {
                case 0:
                    this.getCnslnHisList(year)
                    break;
                case 1:
                    this.getEntCnslnHisList(year)
                    break;
                default:
                    break;
            }
        },

        // 테이블 데이터 코드 맵핑
        valCdApply: function (){
            // 추가 코드 맵핑 작성...
        },

        // 상담일지 출력
        cnslnJrnlsPrt: function(itm){
            if(itm.bnMmbrPk > 0){
                let oObj = {}
                oObj.rptTyp = '1'
                oObj.mode = '2'
                oObj.fcltyNum = this.$store.getters.getFcltyNum
                oObj.bnMmbrPk = itm.bnMmbrPk
                //oObj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                oObj.prtClcd = '1'
                oObj.inqClcd = 2
                oObj.prtCd = '86'
                oObj.inqYYYY = this.sltYyyy
                oObj.list = [ { bzClcd: [], bzPk: [] } ]
    
                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: oObj, outObj: null, runEvt: true,
                });
            }
            else {
                let obj = {}
                obj.actv = true                      // 경고 노출여부
                obj.tmr = 3000                       // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                 // 경고 유형 (success, info, warning, error)
                obj.title = '수급자를 선택해주세요!'    // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 상담일지 모달 오픈
        onCnslnJrnlsMdl: function (itm, qtr) {
            let obj = {}
            obj.bnMmbrPk = itm.bnMmbrPk
            obj.bnfcrPk = itm.bnfcrPk
            obj.name = itm.name
            obj.age = itm.age
            obj.gndr = itm.gndr
            obj.entStcdNm = itm.entStcdNm
            obj.entDtDot = itm.entDtDot
            obj.lvDtDot = itm.lvDtDot
            obj.qtrToYear = this.$moment(this.sltYyyy, 'YYYY').quarter(qtr + 1).startOf('quarter').format('YYYY-MM')
            obj.vCd104 = this.vCd104
            obj.mode = 'target'

            this.$store.commit('setTmpObj', obj)
            this.onLclMdl('01', 1400)
        },

        // 입소 상담일지 모달 오픈
        onEntCnslnJrnlsMdl: function (itm, qtr) {
            if(itm['cnsYn'+(qtr+1)+'w'] == '2')
                return

            let endDt = ''
            for (let index = 1; index < 5; index++) {
                if(itm['cnsYn'+(index)+'w'] == '1')
                    endDt = itm['cnclDtEnd'+(index)]
            }

            let obj = {}
            obj.bnMmbrPk = itm.bnMmbrPk
            obj.bnfcrPk = itm.bnfcrPk
            obj.name = itm.name
            obj.age = itm.age
            obj.gndr = itm.gndr
            obj.entStcdNm = itm.entStcdNm
            obj.entDtDot = itm.entDtDot
            obj.lvDtDot = itm.lvDtDot
            obj.lmtStrt = this.$moment(itm.cnclDtStrt1, 'YYYYMMDD').format('YYYY-MM-DD')
            if(endDt != '')
                obj.lmtEnd = this.$moment(endDt, 'YYYYMMDD').format('YYYY-MM-DD')
            else
                obj.lmtEnd = ''
            obj.vCd104 = this.vCd104
            obj.fxdYyyy = this.sltYyyy

            this.$store.commit('setTmpObj', obj)
            this.onLclMdl('02', 1400)
        },

        offCnslnJrnlsMdl: function () {
            switch (this.tab) {
                case 0:
                    this.getCnslnHisList(this.sltYyyy)
                    break;
                case 1:
                    this.getEntCnslnHisList(this.sltYyyy)
                    break;
                default:
                    break;
            }
            this.$store.commit('setTmpObj', {})

            this.rstLclMdlInf()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        tab: 0,
        sltYyyy: '',                        // 선택된 연도
        dmyTxt: '',                         // 테이블 반응 더미(검색용)
        srchTxt: '',                        // 테이블 이름 검색 필드
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        isEntStt: false,                    // 현황 확장 활성화 여부
        isAcknwStt: false,                  // 등급 확장 활성화 여부
        vCd62: [],
        vCd65: [],
        vCd104: [],
        cnslnJrnlsList: [],
        entCnslnJrnlsList: [],
        cnslnJrnlsTot: [
            { qtr: '1', totCnt: 0 }, { qtr: '2', totCnt: 0 }, { qtr: '3', totCnt: 0 }, { qtr: '4', totCnt: 0 }
        ],
        entCnslnJrnlsTot: [
            { chap: '1', totCnt: 0 }, { chap: '2', totCnt: 0 }, { chap: '3', totCnt: 0 }, { chap: '4', totCnt: 0 }
        ],
        tabItems: [
            { index: 0, tab: '상담일지', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '입소 상담일지', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        tblHdr: [
            { text: '연번', value: 'rn', sortable: true, filterable: false },
            { text: '수급자명', value: 'name', sortable: true, filterable: true },
            { text: '성별', value: 'gndr', sortable: true, filterable: false },
            { text: '나이', value: 'age', sortable: true, filterable: false },
            { text: '현황', value: 'entStcdNm', sortable: true, filterable: true },
            { text: '등급', value: 'acknwRtng', sortable: true, filterable: true },
            { text: '인정만료일', value: 'acknwPrdEndDot', sortable: true, filterable: false },
            { text: '입소일', value: 'entDtDot', sortable: true, filterable: false },
            { text: '퇴소일', value: 'lvDtDot', sortable: true, filterable: false },
            { text: '급여제공', value: 'bnftOfrCnt', sortable: true, filterable: false },
            { text: '처리', value: '', sortable: true, filterable: false },
        ],
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },     // 통합 팝업 제어 (01: 상담일지)
    }),
};
</script>