<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">{{ agrTtl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">작성 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row v-if="bscsInf.clsfcCode == '4' || bscsInf.clsfcCode == '5'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                <span class="font-weight-medium posn-rltv">수급자</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="7">{{ name }}</v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode == '4' || bscsInf.clsfcCode == '5'" class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                <span class="font-weight-medium posn-rltv">
                    <span v-if="bscsInf.clsfcCode == '4' || bscsInf.clsfcCode == '5'">제공일자</span>
                    <span v-else>작성일</span>
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="7">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInf.agrDt" @input="aplctSavBtn" :required="true">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row v-if="bscsInf.clsfcCode != '4' && bscsInf.clsfcCode != '5'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    동의여부
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="7">
                <v-radio-group
                    v-model="bscsInf.agrYn" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="예" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="아니오" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode != '4' && bscsInf.clsfcCode != '5'" class="mr-1"></v-divider>
        <v-row v-if="bscsInf.clsfcCode != '1' && bscsInf.clsfcCode != '4'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">
                <span v-if="bscsInf.clsfcCode == '2' || bscsInf.clsfcCode == '5'" class="font-weight-medium">제공</span>
                <span v-else-if="bscsInf.clsfcCode == '3'" class="font-weight-medium">통지</span>
                <span class="font-weight-medium"> 받은자</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="5">
                <v-radio-group
                    v-model="bscsInf.rcvPstnCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="수급자" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="보호자" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="px-2 pt-1" cols="3">
                <v-text-field
                    v-model="bscsInf.rcvNm" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode != '1' && bscsInf.clsfcCode != '4'" class="mr-1"></v-divider>        
        <v-row v-if="bscsInf.clsfcCode == '4'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">                
                <span class="font-weight-medium posn-rltv">
                    보호자명
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>            
            <v-col class="px-2 py-1 d-flex justify-left align-center" cols="7">
                <span class="d-inline-block" style="width: 100px;">
                    <v-text-field
                        v-model="bscsInf.rcvNm"
                        class="" height="28" color="blue"
                        hide-details outlined dense readonly>
                    </v-text-field>
                </span>
                <v-btn 
                    class="ml-1 white--text darken-1" style="padding: 0px 1px 0px 2px !important"
                    color="bckgrColor" min-width="30" height="25" @click="dialog.splice(0, 1, true)"
                    :disabled="bscsInf.agrDt == ''"
                    small>
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>보호자선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode == '4'" class="mr-1"></v-divider>
        <v-row v-if="bscsInf.clsfcCode == '4'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">                
                <span class="font-weight-medium posn-rltv">
                    관계
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>            
            <v-col class="px-2 py-1 d-flex justify-left align-center" cols="7">
                <span class="d-inline-block" style="width: 100px;">
                    <v-text-field
                        v-model="bscsInf.rltns" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>                
            </v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode == '4'" class="mr-1"></v-divider>
        <v-row v-if="bscsInf.clsfcCode == '4' || bscsInf.clsfcCode == '5'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">                
                <span class="font-weight-medium posn-rltv">
                    안내방법
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>            
            <v-col class="px-2 py-1 d-flex justify-left align-center" cols="7">
                <span class="d-inline-block" style="width: 100px;">
                    <v-text-field
                        v-model="bscsInf.ntcMthd" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>                
            </v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode == '4' || bscsInf.clsfcCode == '5'" class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-row v-if="bscsInf.clsfcCode == '5'" class="pa-0 mt-6 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">연명의료결정제도란?</span>
        </v-row>
        <v-row v-if="bscsInf.clsfcCode == '5'" class="mt-3" no-gutters>                 
            <v-col cols="12">
                <div class="div-0">「호스피스,완화의료 및 임종 과정에 있는 환자의 연령의료결정에 관한 법률」</div>
                <div class="py-1">(이하 "연명의료결정법")이 제정되었고,  이 법에 따라 연명의료결정제도가</div>
                <div class="py-1">2018년 2월 4일부터 시행되었습니다.</div>
                <div class="py-1">연명의료결정제도는 생애 말기 무의미한 연명의료중단에 대한 본인의 의사를 미리</div>
                <div class="py-1">밝혀두고 이를 법적으로 보장하여 삶의 존엄한 마무리를 돕는 내용을 담고 있습니다.</div>
                <div class="py-1">연명의료관련 자세한 정보는 국립연명의료관리기관 &#40;https//lst.go.kr&#41;</div>
                <div class="py-1">
                    <span>홈페이지를 참조하세요.</span>
                    <v-btn                        
                        class="white--text ml-1" style="padding: 9px 7px 8px 5px !important;"
                        color="grey006" @click="goPage('https://drive.google.com/file/d/1eAjPhNaHccxXQkF6Nt2MeC2FN-ZCIIhg/view?usp=sharing')"                        
                        min-width="20" height="20" small outlined>
                        <v-icon class="mr-1" size="14">mdi-download</v-icon>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">연명의료결정제도 안내 브로슈어</span>
                    </v-btn>
                </div>
                <div>                                        
                    <span>&#40;</span> 
                    <span class="pr-1">출처:국립연명의료관리기관</span>
                    <a href="https://lst.go.kr" target="_blank">https://lst.go.kr</a>
                    <span>&#41;</span>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog[0]" content-class="round" persistent max-width="600">
            <fmlySelector v-if="dialog[0]" :prtBnMmbrPk="bnMmbrPk" @isClose="dialog.splice(0, 1, false)" @outFmly="getFmlyInfo"></fmlySelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';
import FmlySelector from '../../commons/FmlySelector.vue';

export default {
    name: 'AgrSavMdl',

    components: {
        CmmDateComp,
        FmlySelector,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 동의정보 저장
        setAgrOfrInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insAgrOfr', obj)
                .then((response) => ( this.agrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insAgrOfr : ' + error))
        },

        agrRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bnMmbrPk = obj.bnMmbrPk
            this.bnfcrPk = obj.bnfcrPk
            this.bscsInf.clsfcCode = obj.clsfcCode
            this.name = obj.name

            switch (obj.clsfcCode) {
                case '1':
                    this.agrTtl = '개인정보 수집 및 이용동의 ' + obj.subTtl
                    break;
                case '2':
                    this.agrTtl = '노인인권 보호지침 ' + obj.subTtl
                    break;
                case '3':
                    this.agrTtl = '수급자 안전관리 통지 ' + obj.subTtl
                    break;
                case '4':
                    this.agrTtl = '직원 인권 보호 ' + obj.subTtl
                    break;
                case '5':
                    this.agrTtl = '연명 의료 결정 제도 ' + obj.subTtl
                    break;
                default:
                    break;
            }

            if(obj.subTtl == '수정'){
                this.bscsInf.agrOfrPk = obj.agrOfrPk
                if(obj.clsfcCode != '1'){
                    this.bscsInf.agrDt = this.$moment(obj.ofrDt, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.bscsInf.rcvPstnCd = obj.rcvPstnCd
                    this.bscsInf.rcvNm = obj.rcvNm
                }
                else
                    this.bscsInf.agrDt = this.$moment(obj.agrDt, 'YYYYMMDD').format('YYYY-MM-DD')

                this.bscsInf.agrYn = obj.agrYn
                this.bscsInf.rltns = obj.rltns
                this.bscsInf.ntcMthd = obj.ntcMthd
            }
            else
                this.rstFld()

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.agrDt) == false)
                return 0

            if(this.bscsInf.agrYn == '' && (this.bscsInf.clsfcCode != '4' && this.bscsInf.clsfcCode != '5'))
                return 0

            if(this.bscsInf.clsfcCode == '2' || this.bscsInf.clsfcCode == '3' || this.bscsInf.clsfcCode == '5'){
                if(this.bscsInf.rcvPstnCd == '')
                    return 0
                if(this.bscsInf.rcvPstnCd == '2' && this.bscsInf.rcvNm == '')
                    return 0
            }

            if(this.bscsInf.clsfcCode == '4'){
                if(this.bscsInf.rcvNm == '')
                    return 0
                if(this.bscsInf.rltns == '')
                    return 0
            }

            if(this.bscsInf.ntcMthd == '' && (this.bscsInf.clsfcCode == '4' || this.bscsInf.clsfcCode == '5'))
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '변경할 정보를 입력해주세요.'     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)
            
            if(this.bscsInf.clsfcCode == '4' || this.bscsInf.clsfcCode == '5'){
                if(this.bscsInf.agrDt == ''){
                    this.$store.commit('setWrnnInf', obj)
                    return 0
                }
            }
            else {
                if(this.bscsInf.agrDt == '' || this.bscsInf.agrYn == ''){
                    this.$store.commit('setWrnnInf', obj)
                    return 0
                }
            }

            let insObj = Object.assign({}, this.bscsInf)
            insObj.bnMmbrPk = this.bnMmbrPk
            insObj.bnfcrPk = this.bnfcrPk
            insObj.agrDt = this.$moment(insObj.agrDt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.ofrDt = insObj.agrDt

            this.setAgrOfrInf(insObj)
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInf.agrOfrPk = -1
            this.bscsInf.agrDt = ''
            this.bscsInf.agrYn = ''
            this.bscsInf.rltns = ''
            this.bscsInf.ntcMthd = ''
        },

        getFmlyInfo: function (obj) {
            this.bscsInf.rcvNm = obj.name
            this.aplctSavBtn()
        },

        goPage:function(url){
            window.open(url, '_blank')
        }
    },

    data: () => ({
        savBtn: true,
        bnMmbrPk: -1,
        bnfcrPk : -1,
        name    : '',
        agrTtl: '',
        agrTyp: '',
        bscsInf: {
            agrOfrPk: -1, clsfcCode: '', agrDt: '', ofrDt: '', agrYn: '', rcvPstnCd: '', rcvNm: '',
            bnfcrSigYn: '', grdnSigYn: '', rltns: '', ntcMthd : ''
        },
        dialog:[false],
    })
}
</script>