<template>
    <v-sheet class="overflow-x-auto pa-2" max-width="1200" min-width="1200">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-divider class="mt-2"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">수급자</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid tableBR px-1" style="padding-top: 6px;" cols="2">
                <span v-if="bnfcrInf.name != ''" class="fontOneRem">{{ bnfcrInf.name }}&nbsp;&#40;&nbsp;</span>
                <span v-if="bnfcrInf.gndr == 'M'" class="fontOneRem">남</span>
                <span v-else-if="bnfcrInf.gndr == 'F'" class="fontOneRem">여</span>
                <span v-if="typeof bnfcrInf.age == 'number'" class="fontOneRem">&nbsp;&#47;&nbsp;{{ bnfcrInf.age }}세&nbsp;&#41;</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">입소일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneRem">{{ bnfcrInf.entDt }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">주요질환</span>
            </v-col>
            <v-col class="overflow-text-hidden px-3" style="padding-top: 6px;" cols="6">
                <span class="fontOneRem">{{ bnfcrInf.majorDsss }}</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-divider class="my-2 white"></v-divider>
        <v-row class="pa-0 align-center" no-gutters>
            <v-col class="pl-1" cols="4">
                <div v-if="isBdSre" class="fontOneRem" style="line-height: 1.2;">
                    욕창위험도: 
                    <strong class="red--text">{{ bnfctBdSre.bdSre }}</strong>
                    &#40; 평가일: {{ $moment(bnfctBdSre.bdSreExamDt, 'YYYYMMDD').format('YYYY.MM.DD') }} &#41;
                    <br>
                    &#40; 욕창위험도 평가 <strong class="red--text">분기별</strong> 1회 작성 &#41;
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end" style="padding-top: 2px;" cols="4">
                <v-btn
                    class="ml-1" color="#2196f3" style="padding: 13px 12px 12px 10px !important;"
                    min-width="30" height="25"
                    :disabled="bnMmbrPk < 1"
                    @click="onLclMdl('11', 1300)"
                    small outlined>
                    <v-icon size="20">mdi-square-edit-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">식이정보관리</span>                
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="my-1 white"></v-divider>
        <v-data-table
            class="" locale="ko" height="694"
            :loading="isLoad" :loading-text="loadMsg"
            :headers="bnftSrvHeader" :items="bnftSrvList"
            hide-default-header fixed-header disable-pagination hide-default-footer dense>
            <template v-slot:header="{ props }" >
                <thead>
                    <tr>                                                        
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR tableBT" style="width: 550px;" colspan="5" rowspan='1'>
                            <span class="black--text fontOneRem">{{ props.headers[1].text }}</span>
                            <span v-if="oldRecYn" class="ml-1 fontEighthRem">&#40; 연 1회 &#41;</span>
                            <span v-else class="ml-1 fontEighthRem">&#40; 반기 1회 &#41;</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR tableBT" style="width: 300px;" colspan="3" rowspan='1'>
                            <span class="black--text fontOneRem">{{ props.headers[2].text }}</span>
                            <span v-if="oldRecYn" class="ml-1 fontEighthRem">&#40; 연 1회 &#41;</span>
                            <span v-else class="ml-1 fontEighthRem">&#40; 반기 1회 &#41;</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 tableBR tableBT px-3" style="width: 110px;" colspan="1" rowspan='2'>
                            <div class="txtAlgnMid" style="line-height: 1.3;">
                                <div class="black--text fontOneRem">급여제공</div>
                                <div class="black--text fontOneRem">계획변경</div>
                                <div class="black--text fontOneRem">&#40;필요시&#41;</div>
                            </div>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 220px;" colspan="2" rowspan='1'>
                            <span class="black--text fontOneRem">물리(작업) 치료</span>
                            <span class="fontEighthRem ml-1">( 연 1회 )</span>
                        </th>
                    </tr>  
                    <tr>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">{{ props.headers[5].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">{{ props.headers[6].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">{{ props.headers[7].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">{{ props.headers[8].text }}</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <!-- <span class="black--text fontOneRem">{{ props.headers[9].text }}</span> -->
                            <span class="black--text fontOneRem">건강수준평가</span>
                            <span class="fontEighthRem ml-1">( 입소시 )</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="2" rowspan='1'>
                            <span class="black--text fontOneRem">급여제공계획</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">결과평가</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">물리치료평가</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp px-1" style="width: 110px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">물리치료계획</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(0,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>욕구사정평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(1,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>낙상위험도평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(2,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>욕창위험도평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(3,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>인지기능평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(4,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>건강수준평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(7,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>급여제공계획 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 80px; padding-bottom: 1px;" colspan="1" rowspan='1'>
                            <span class="black--text fontOneRem">서명</span>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;" colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(9,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>급여제공 결과평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(8,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>급여제공 계획변경 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(5,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>물리치료평가 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                        <th class="nmlBfClrImp-greyE01 txtAlgnMidImp" style="width: 110px;"  colspan="1" rowspan='1'>
                            <v-btn
                                class="nmlBfClrImp-greyE01"
                                color="blueBtnColor" min-width="20" height="20"
                                @click="showDialog(6,0)" :disabled="bnMmbrPk < 1"
                                small icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                    </template>
                                    <span>물리치료계획 추가</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
            </template>

            <template v-slot:item='{ item }'>
                <tr>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(0,item.bnfcrExamHisPk101)">
                        <div class="fontNineThRem">{{ item.examDt101 }} <br> {{ item.examRsncd101 }}</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(1,item.bnfcrExamHisPk102)">
                        <div class="fontNineThRem">{{ item.examDt102 }} <br> {{ item.examRsncd102 }}</div>
                        <div v-if="item.examStt102 != null && item.examStt102 != ''" class="fontEighthRem font-weight-medium red--text">{{ item.examStt102 }}&#40;{{ item.examVal102 }}점&#41;</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(2,item.bnfcrExamHisPk103)">
                        <div class="fontNineThRem">{{ item.examDt103 }} <br> {{ item.examRsncd103 }}</div>
                        <div v-if="item.examStt103 != null && item.examStt103 != ''" class="fontEighthRem font-weight-medium red--text">{{ item.examStt103 }}&#40;{{ item.examVal103 }}점&#41;</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(3,item.bnfcrExamHisPk104)">
                        <div class="fontNineThRem">{{ item.examDt104 }} <br> {{ item.examRsncd104 }}</div>
                        <div v-if="item.examStt104 != null && item.examStt104 != ''" class="fontEighthRem font-weight-medium red--text">{{ item.examStt104 }}&#40;{{ item.examVal104 }}점&#41;</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(4,item.bnfcrExamHisPk105)">
                        <div class="fontNineThRem">{{ item.examDt105 }} <br> {{ item.examRsncd105 }}</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB px-1" @click="showChkDialog(7,item.bnftOfrPlnHisPk201, item.bnfcrPk201)">
                        <div class="fontNineThRem">
                            {{ item.wrDt201 }}
                            <br>
                            <span v-if="item.bzPk != null && item.bzPk > 0" class="py-1">
                                <span class="fontEighthRem result-msg">결과평가반영</span>
                            </span>
                            <span v-else> {{item.mdfClcd201}}</span>    
                        </div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB px-1" @click="showBizMsg(item.bnftOfrPlnHisPk201)">                            
                        <div class="fontNineThRem">{{ item.eSigCfm }}</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(9,item.bnftOfrPlnHisPk4, item.bnfcrPk4)">
                        <div class="fontNineThRem">{{ item.wrDt4 }} <br> {{ item.wrtr4 }}</div>
                        <div v-if="item.ofrPlnStt4 != null" class="fontNineThRem font-weight-medium px-1 red--text">{{ item.ofrPlnStt4 }}</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(8,item.bnftOfrPlnHisPk3)">
                        <div class="fontNineThRem">{{ item.wrDt3 }} <br> {{ item.wrtr3 }}</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBR tableBB" @click="showChkDialog(5,item.bnfcrExamHisPk106)">
                        <div class="fontNineThRem">{{ item.examDt106 }} <br> {{ item.examRsncd106 }}</div>
                    </td>
                    <td class="hvr-col txtAlgnMid tableBB" @click="showChkDialog(6,item.bnftOfrPlnHisPk202, item.bnfcrPk202)">
                        <div class="fontNineThRem">{{ item.wrDt202 }} <br> {{ item.mdfClcd202 }}</div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        
        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <DsrEvltnComp
                v-if="lclMdlInf.typ == '00'"
                :bnMmbrPk="bnMmbrPk" :bsrEvltnPk="bsrEvltnPk" :inqYYYY="year"
                @modalEvent="modalEvent">
            </DsrEvltnComp>
            <FallEvltnComp
                v-else-if="lclMdlInf.typ == '01'"
                :bnMmbrPk="bnMmbrPk" :fallEvltnPk="fallEvltnPk" :inqYYYY="year"
                @modalEvent="modalEvent">
            </FallEvltnComp>
            <BdsrEvltnComp 
                v-else-if="lclMdlInf.typ == '02'"
                :bnMmbrPk="bnMmbrPk" :bdsrEvltnPk="bdsrEvltnPk" :inqYYYY="year"
                @modalEvent="modalEvent">
            </BdsrEvltnComp>
            <CgntvEvltnComp 
                v-else-if="lclMdlInf.typ == '03'"
                :bnMmbrPk="bnMmbrPk" :cgntvEvltnPk ="cgntvEvltnPk" :inqYYYY="year"
                @modalEvent="modalEvent">
            </CgntvEvltnComp>
            <HlthEvltnComp 
                v-else-if="lclMdlInf.typ == '04'"
                :bnMmbrPk="bnMmbrPk" :hlthEvltnPk ="hlthEvltnPk" :inqYYYY="year"
                @modalEvent="modalEvent">
            </HlthEvltnComp>
            <PhyscThrpyEvltnComp 
                v-else-if="lclMdlInf.typ == '05'"
                :bnMmbrPk="bnMmbrPk" :inqYYYY="year" :physcThrpyEvltnPk="physcThrpyEvltnPk"
                @modalEvent="modalEvent">
            </PhyscThrpyEvltnComp>
            <PhyscThrpyPlnComp 
                v-else-if="lclMdlInf.typ == '06'"
                :bnMmbrPk="bnMmbrPk" :bnfcrPk="bnfcrPk" :inqYYYY="year" :physcThrpyPlnPk="physcThrpyPlnPk"
                @modalEvent="modalEvent">
            </PhyscThrpyPlnComp>
            <BnftSrvPlnComp 
                v-else-if="lclMdlInf.typ == '07'"
                :bnMmbrPk="bnMmbrPk" :bnfcrPk="bnfcrPk" :bzPk="plnBzPk" :inqYYYY="year"
                :btnDisabled="false" :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                @modalEvent="modalEvent">
            </BnftSrvPlnComp>
            <BnftSrvPlnMdfComp
                v-else-if="lclMdlInf.typ == '08'"
                :bnMmbrPk="bnMmbrPk" :inqYYYY="year" :bnftSrvPlnPk="bnftSrvPlnPk"
                @isClose="modalEvent(8)" @modalEvent="modalEvent">
            </BnftSrvPlnMdfComp>
            <BnftSrvRsltEvltnComp 
                v-else-if="lclMdlInf.typ == '09'"
                :bnMmbrPk="bnMmbrPk" :bnfcrPk="bnfcrPk" :inqYYYY="year" :bnftSrvRsltEvltnPk="bnftSrvRsltEvltnPk"
                @modalEvent="modalEvent">
            </BnftSrvRsltEvltnComp>
            <BnftSrvPlnSignMdl 
                v-else-if="lclMdlInf.typ == '10'"
                :bnMmbrPk="bnMmbrPk" :bzPk="bizMsgBzPk"
                :eSigClcd="4" :msgTitle="year+'년 급여제공계획'"
                :msgCntnt="$store.getters.getBnfcrState.name"
                @modalEvent="modalEvent" @isClose="onSendClose">
            </BnftSrvPlnSignMdl>
            <BnfcrDietsMdl 
                v-else-if="lclMdlInf.typ == '11'"
                :bnMmbrPk="bnMmbrPk"
                @isClose="rstLclMdlInf">
            </BnfcrDietsMdl>
            <DsrEvltnAvrInd
                v-else-if="lclMdlInf.typ == '21'"
                @isClose="offDsrEvltnMdl">
            </DsrEvltnAvrInd>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import { getBnfcrBnftOfrList } from '../../api/bnftSrv.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';   // 연도선택

import DsrEvltnComp from '../../components/bnftSrv/DsrEvltn.vue';                       // 욕구사정
import FallEvltnComp from '../../components/bnftSrv/FallEvltn.vue';                     // 낙상위험도
import BdsrEvltnComp from '../../components/bnftSrv/BdsrEvltn.vue';                     // 욕창위험도
import CgntvEvltnComp from '../../components/bnftSrv/CgntvEvltn.vue';                   // 인지기능
import HlthEvltnComp from '../../components/bnftSrv/HlthEvltn.vue';                     // 건강수준
import PhyscThrpyEvltnComp from '../../components/bnftSrv/PhyscThrpyEvltn.vue';         // 물리치료평가
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue';             // 물리치료계획
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue';                   // 급여제공계획
import BnftSrvPlnMdfComp from '../../components/bnftSrv/BnftSrvPlnMdf.vue';             // 급여제공계획변경
import BnftSrvRsltEvltnComp from '../../components/bnftSrv/BnftSrvRsltEvltn.vue';       // 급여제공결과평가
import BnftSrvPlnSignMdl from '../../components/bnftSrv/BnftSrvPlnSignMdl.vue';         // 전자발송관리
import BnfcrDietsMdl from '../../components/bnftSrv/BnfcrDietsMdl.vue';                 // 식이정보관리
import DsrEvltnAvrInd from '../../components/bnftSrv/DsrEvltnAvrInd.vue';               // 욕구사정평가 (2025~)

export default {
    name: 'BnftSrvMgmt',

    created: function() {
        
    },
        
    components: {
        expandYearOnlyPicker,
        DsrEvltnComp,
        FallEvltnComp,
        BdsrEvltnComp,
        CgntvEvltnComp,
        HlthEvltnComp,
        PhyscThrpyEvltnComp,
        PhyscThrpyPlnComp,
        BnftSrvPlnComp,
        BnftSrvPlnMdfComp,
        BnftSrvRsltEvltnComp,
        BnftSrvPlnSignMdl,
        BnfcrDietsMdl,
        DsrEvltnAvrInd,
    },

    computed: {
        // 수급자 선택 이벤트
        getStoreBnMmbrPk: function (){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
    },

    watch: {
        getStoreBnMmbrPk: function (v){
            this.bnMmbrPk = v
            
            if(v == 0)
                this.bnftSrvList.splice(0)
            else{
                this.getBnfcrBscInf(v)
                this.getBnfcrBnftOfrList()
            }
        },
    },

    mounted: function(){
        this.$nextTick(function () {  
            this.getBnfcrBscInf(this.$store.getters.getBnfcrState.bnmmbr)
        
            this.oldRecYn = this.$moment(this.year ,'YYYY').isBefore('2025')
        })
    },
    
    methods: {
        // 수급자 기초정보 단건 조회
        getBnfcrBscInf: function (mbr){
            this.clrForm()

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = mbr

            http.post('bnfcr/selBnfcrBsInfo', obj)
                .then((response) => ( this.bnfcrBscInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrBsInfo : ' + error))
        },
        bnfcrBscInfRst: function (res){
            if(res != ''){
                this.bnfcrInf.name = res.name
                this.bnfcrInf.gndr = res.gndr
                this.bnfcrInf.age = res.age
                this.bnfcrInf.entDt = this.$moment(res.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                this.bnfcrInf.majorDsss = res.majorDsss
            }
        },
        getYear: function (yyyy){
            this.year = yyyy
            this.oldRecYn =  this.$moment(this.year ,'YYYY').isBefore('2025')

            if(this.bnMmbrPk != this.$store.getters.getBnfcrState.bnmmbr)
                this.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr

            this.getBnfcrBnftOfrList()
        },
        getBnfcrBnftOfrList: function (){
            this.isLoad = true
            this.bnftSrvList.splice(0)
            this.onBtn()

            let state = {};
            state.inqYYYY = this.year
            state.bnMmbrPk = this.bnMmbrPk
  
            if(this.bnMmbrPk > 0)
                getBnfcrBnftOfrList(this.$store.getters.getFcltyNum, state)
                    .then((response) => ( this.getBnfcrBnftOfrListAfter(response.data)))
                    .catch((error) => console.log(error))
            else
                this.isLoad = false
        },
        getBnfcrBnftOfrListAfter: function(res){
            if(res.result.stInfo != null){
                this.bnfctBdSre = res.result.stInfo

                if(res.result.stInfo.bdSre == '매우높은위험')
                    this.isBdSre = true
                else if(res.result.stInfo.bdSre == '높은위험')
                    this.isBdSre = true
                else
                    this.isBdSre = false
            }
            else
                this.isBdSre = false

            if(res.result.list.length > 0){
                res.result.list.map( x => {
                    x.examDt101 = x.examDt101?.beforeDateFormatDot()
                    x.examRsncd101 = this.setExamRsncdNm(x.examRsncd101)
                    x.examDt102 = x.examDt102?.beforeDateFormatDot()
                    x.examRsncd102 = this.setExamRsncdNm(x.examRsncd102)
                    x.examDt103 = x.examDt103?.beforeDateFormatDot()
                    x.examRsncd103 = this.setExamRsncdNm(x.examRsncd103)
                    x.examDt104 = x.examDt104?.beforeDateFormatDot()
                    x.examRsncd104 = this.setExamRsncdNm(x.examRsncd104)
                    x.examDt105 = x.examDt105?.beforeDateFormatDot()
                    x.examRsncd105 = this.setExamRsncdNm(x.examRsncd105)
                    x.examDt106 = x.examDt106?.beforeDateFormatDot()
                    x.examRsncd106 = this.setExamRsncdNm(x.examRsncd106)
                    x.wrDt202 = x.wrDt202?.beforeDateFormatDot()
                    x.mdfClcd202 = this.setPlnMdfClcdNm(x.mdfClcd202)
                    x.wrDt201 = x.wrDt201?.beforeDateFormatDot()
                    x.mdfClcd201 = this.setPlnMdfClcdNm(x.mdfClcd201)
                    x.wrDt3 = x.wrDt3?.beforeDateFormatDot()
                    x.mdfClcd3 = this.setPlnMdfClcdNm(x.mdfClcd3)
                    x.wrDt4 = x.wrDt4?.beforeDateFormatDot()
                    x.mdfClcd4 = this.setPlnMdfClcdNm(x.mdfClcd4)
                    x.wrDt5 = x.wrDt5?.beforeDateFormatDot()
                    x.mdfClcd5 = this.setPlnMdfClcdNm(x.mdfClcd5)
    
                    this.bnftSrvList.push(x)
                })
            }

            if(res.result.list.length > 11)
                this.headerStyle = "margin-right: 10px;";
            else
                this.headerStyle = "";

            this.isLoad = false
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getBnfcrBnftOfrList();
            }else{
                switch (event) {
                    case 0:
                        this.bsrEvltnPk = -1
                        break;
                    case 1:
                        this.fallEvltnPk = -1
                        break;
                    case 2:
                        this.bdsrEvltnPk = -1
                        break;
                    case 3:
                        this.cgntvEvltnPk = -1
                        break;    
                    case 4:
                        this.hlthEvltnPk = -1
                        break;    
                    case 5:
                        this.physcThrpyEvltnPk = -1
                        break; 
                    case 6:
                        this.physcThrpyPlnPk = -1
                        break;  
                    case 7:
                        this.bnftSrvPlnCmpPk = -1
                        this.plnBzPk = -1
                        break;      
                    case 8:
                        this.bnftSrvPlnPk = -1
                        break;
                    case 9:
                        this.bnftSrvRsltEvltnPk = -1
                        break;
                    default:
                        break;
                }

                this.rstLclMdlInf()
            }      
        },
        setExamRsncdNm:function(value){
            if(value=="1"){
                return "신규"
            }else if(value=="2"){
                return "재사정"
            }else if(value=="3"){
                return "상태변화"
            }
        },
        setPlnMdfClcdNm:function(value){
            if(value=="1"){
                return "신규"
            }else if(value=="2"){
                return "변경"
            }
        },
        showChkDialog(tabkey,value,bnfcr=null){
            if(value > 0) this.showDialog(tabkey,value,bnfcr)
        },
        showDialog(tabkey, value, bnfcr=null){   
            this.bnfcrPk = (bnfcr != null) ? bnfcr : this.$store.getters.getBnfcrState.bnfcr

            switch (tabkey) {
                case 0:
                    this.bsrEvltnPk = value
                    break;
                case 1:
                    this.fallEvltnPk = value
                    break;
                case 2:
                    this.bdsrEvltnPk = value
                    break;
                case 3:
                    this.cgntvEvltnPk = value
                    break;
                case 4:
                    this.hlthEvltnPk = value
                    break;
                case 5:
                    this.physcThrpyEvltnPk = value
                    break;
                case 6:
                    this.physcThrpyPlnPk = value
                    break;
                case 7:
                    this.bnftSrvPlnCmpPk = value
                    break;
                case 8:
                    this.bnftSrvPlnPk = value
                    break;
                case 9:
                    this.bnftSrvRsltEvltnPk = value
                    break;
                default:
                    this.modalEvent(2)
                    break;
            }

            if(tabkey == 0){
                if(this.oldRecYn)
                    this.onLclMdl('0'+tabkey, 1400)
                else
                    this.onDsrEvltnMdl()
            }
            else
                this.onLclMdl('0'+tabkey, 1400)
        },
        
        outOnSrvPln: function(){
            this.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            this.bnftSrvPlnCmpPk = 0
            this.onLclMdl('07', 1600)
        },

        showBizMsg:function(selPk){
            if(selPk > 0){
                this.bizMsgBzPk = selPk
                // this.bizMsg = true
                this.onLclMdl('10', 800)
            }else{
                this.snackControll([true, 5000, '급여제공계획을 작성해주세요.', 'error'])
            }
        },
        onSendClose:function(){
            // this.bizMsg = false
            this.bizMsgBzPk = -1
            this.rstLclMdlInf()
        },
        onBtn(){

            //수급자 선택시 버튼 활성화
            if(this.bnMmbrPk != null && this.bnMmbrPk > 0){
                this.btnOnOff=false
            }else{
                this.btnOnOff=true
            }   
        },
        
        snackControll: function (options) {
            this.snackOptions = options
        },

        clrForm: function (){
            this.bnfcrInf.name = ''
            this.bnfcrInf.gndr = ''
            this.bnfcrInf.age = ''
            this.bnfcrInf.entDt = ''
            this.bnfcrInf.majorDsss = ''
        },

        // 욕구사정평가 모달 (2024년 이후)
        onDsrEvltnMdl: function () {
            let obj = Object.assign({}, this.bnfcrInf)
            obj.bsrEvltnPk = this.bsrEvltnPk
            obj.examYyyy = this.year
            obj.isUpd = true

            this.$store.commit('setTmpObj', obj)
            this.onLclMdl('21', 1400)
        },

        offDsrEvltnMdl: function () {
            this.getBnfcrBnftOfrList()
            this.$store.commit('setTmpObj', {})

            this.rstLclMdlInf()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        oldRecYn : false,
        year: 0,
        bnMmbrPk:0,   
        bnfcrPk:0,   
        bsrEvltnPk:-1,              //욕구사정
        fallEvltnPk:-1,             //낙상위험도
        bdsrEvltnPk:-1,             //욕창위험도
        cgntvEvltnPk:-1,            //인지기능
        hlthEvltnPk:-1,             //건강수준
        physcThrpyEvltnPk:-1,       //물리치료평가
        physcThrpyPlnPk:-1,         //물리치료계획
        bnftSrvPlnCmpPk:-1,         //급여제공계획
        bnftSrvPlnPk:-1,            //급여제공계획변경
        bnftSrvRsltEvltnPk:-1,      //급여제공결과평가
        isBdSre: false,             // 욕창위험도 텍스트 출력 여부
        bnfctBdSre: {},             // 수급자 욕창위험도
        plnBzPk : -1,
        btnOnOff:true,
        bnftSrvHeader:[
            { text: '회차', align: 'center'},
            { text: '수급자 통합적 평가', align: 'center'},
            { text: '급여제공 계획', align: 'center'},
            { text: '급여제공 계획변경', align: 'center'},
            { text: '급여제공 결과평가', align: 'center'},
            { text: '욕구사정', align: 'center'},
            { text: '낙상위험도', align: 'center'},
            { text: '욕창위험도', align: 'center'},
            { text: '인지기능', align: 'center'},
            { text: '건강수준', align: 'center'},
            { text: '물리(작업) 치료평가', align: 'center'},
            { text: '물리(작업) 치료계획', align: 'center'},
            { text: '급여제공계획', align: 'center'},
            { text: '급여제공 계획변경', align: 'center'},
            { text: '상반기', align: 'center'},
            { text: '하반기', align: 'center'},
        ],
        bnftSrvList:[],
        bnfcrInf: { name: '', gndr: '', age: '', entDt: '', majorDsss: '' },
        headerStyle:'',
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        bnfcrExamHisPk:'',
        bizMsg:false,
        bizMsgBzPk:-1,
        snackOptions: [false, 3000, '', 'info'],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어
    }),
};
</script>

<style>
    .result-msg { border-radius:5px; background-color:#048d22; color:#FFFFFF; font-size:0.825rem; padding: 0px 3px; }
</style>