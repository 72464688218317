<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row no-gutters>
            <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
            <span class="fontOneDotTwoRem font-weight-medium ml-2">종합의견</span>
        </v-row>
        <v-divider class="mt-2" color="black"></v-divider>
        <v-row style="padding-top: 8px; padding-bottom: 7px;" no-gutters>
            <v-textarea
                class="rmrksArea" rows="31"
                v-model="inpTxt.val_1_1"
                no-resize hide-details outlined dense>
            </v-textarea>
        </v-row>
        <v-divider></v-divider>
    </v-sheet>
</template>

<script>
export default {
    name: 'CmprOpnn',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oTxt = {}
                oTxt.examPk = 0
                oTxt.depth2 = '999'
                oTxt.depth3 = '1'
                oTxt.valCd = '1'
                oTxt.val = ''
                oTxt.val2 = this.inpTxt.val_1_1

                finArr.push(oTxt)
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            this.inpTxt.val_1_1 = ''
        },
    },

    data: () => ({
        // 종합의견
        inpTxt: { val_1_1: '' },
        // 
        inpRadio: {  },
        // 
        inpCheck: {  },
    }),
};
</script>