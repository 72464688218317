<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0" min-width="1260" max-width="1460" height="835">
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">4-6. 프로그램 자료</span>
            </div>
            <v-spacer></v-spacer>            
        </div>
        <div class="mt-5 mx-3 overflow-y-auto">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-start align-center" cols="12">
                    <CmmPrgrmBtn
                        v-model="isShtTypStt"
                        class="ml-1"
                        :title="'유형'"
                        :list="tblUse.vCd133"
                        @onList="shtTypApply">
                    </CmmPrgrmBtn>

                    <CmmPrgrmBtn
                        v-model="isShtMdaStt"
                        class="ml-1"
                        :title="'미디어'"
                        :list="tblUse.vCd134"
                        @onList="shtMdaApply">
                    </CmmPrgrmBtn>

                    <CmmPrgrmBtn
                        v-model="isShtSbjtStt"
                        class="ml-1"
                        :title="'주제'"
                        :list="tblUse.vCd135"
                        @onList="shtSbjtApply">
                    </CmmPrgrmBtn>
                    <span class="d-inline-block pl-2" style="width: 200px; height: 34px;">
                        <v-text-field
                            v-model="prgInfParams.srchWd1"
                            class="txt-Theme" height="34" label="프로그램"
                            background-color="white"
                            @keyup.enter="getPage(1)"
                            single-line outlined dense filled hide-details>
                        </v-text-field>
                    </span>
                    <v-btn @click="getPage(1)"
                        class="ml-2 white--text" samll height="32" :color="'blueBtnColor'" rounded>
                        <v-icon small>mdi-magnify</v-icon>
                        <span style="font-size: 1rem;">조회</span>
                    </v-btn>  
                    <v-spacer></v-spacer>
                </v-col>
            </v-row>
            <div  v-if="tblUse.loading" class="mt-3">
                <v-progress-linear 
                    color="blue accent-3"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>  
            </div>

            <div v-else class="mt-3" >
                <div style="height:550px;">
                    <v-row  class="pa-0" no-gutters>
                        <v-col v-for="(itm, idx) in tblUse.serverItems" class="ma-0 pa-2" :key="idx" cols="3">
                            <v-card 
                                class="mx-auto"
                                max-width="320"
                                elevation="8"
                            >            
                                <v-img v-if="itm.fileLoc=='1'"
                                    height="150"
                                    referrerpolicy="no-referrer"
                                    :src="itm.nvrUrl"
                                    :aspect-ratio="itm.ratio"
                                ></v-img>
        
                                <v-img v-else-if="itm.fileLoc == '2'"
                                    height="150"
                                    :src="itm.src"
                                    :aspect-ratio="itm.ratio"
                                ></v-img>

                                <v-img v-else-if="itm.fileLoc == '3'"
                                    height="150"
                                    :src="itm.thmUrl"
                                    :aspect-ratio="itm.ratio"
                                ></v-img>
        
                                <v-divider class="mx-4"></v-divider>
                                
                                <v-card-title>
                                    <div class="txtAlgnMid text-overflow" style="width:280px">{{ itm.prgNm }}</div>
                                </v-card-title>    
                                <!-- <v-card-text></v-card-text>     -->
                                <v-card-actions>         
                                    <div class="d-flex justify-center align-center" style="width:100%">
                                        <v-btn
                                            class="" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"               
                                            min-width="30" height="25" small outlined
                                            @click="rowSltAct(itm, 0)">
                                            <v-icon size="20">mdi-text-box-edit-outline</v-icon>
                                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">계획지</span>
                                        </v-btn>
            
                                        <v-btn
                                            class="ml-4" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"               
                                            min-width="30" height="25" small outlined
                                            @click="rowSltAct(itm, 1)">
                                            <v-icon size="20">mdi-text-box-outline</v-icon>
                                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">활동지</span>
                                        </v-btn>                            
                                    </div>                 
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>  
                </div>

                <div class="mt-2">            
                    <v-pagination
                        v-model="tblUse.page"
                        :length="tblUse.pageCount"
                        @input="getPage"
                        total-visible="5">
                    </v-pagination>
                </div>
            </div>

        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1000">    
                <PrgPlnInfo v-if="dialog[0]" :sltItm="sltObj" @isClose="dialog.splice(0, 1, false)"></PrgPlnInfo>  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" max-width="800">    
                <PrgActshtInfo v-if="dialog[1]" :sltItm="sltObj" @isClose="dialog.splice(1, 1, false)"></PrgActshtInfo>  
            </v-dialog>   
        </div>
    </v-sheet>  
</template>

<script>
import CmmPrgrmBtn from '../../components/commons/CmmPrgrmBtn.vue';
import PrgActshtInfo from '../../components/prgrm/PrgActshtInfo.vue';
import PrgPlnInfo from '../../components/prgrm/PrgPlnInfo.vue';

import { http } from '@/api/baseAxios';
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'PrgActshtMgmt',

    components: {
        CmmPrgrmBtn,
        PrgActshtInfo,
        PrgPlnInfo,
    },
    created: function () {
       
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.selPrgOpRecWrtList()
            let obj = {}
            obj.page = 1
            obj.itemsPerPage = this.prgInfParams.rnLmt
            this.getCdPrgInfShtlist(obj)
        })
    },

    computed: {
       
    },

    watch: {        
        'isShtTypStt': function (v) {
            if(v) {
                this.isShtMdaStt = false
                this.isShtSbjtStt = false
            }
        },

        'isShtMdaStt': function (v) {
            if(v) {
                this.isShtTypStt = false
                this.isShtSbjtStt = false
            }
        },

        'isShtSbjtStt': function (v) {
            if(v) {
                this.isShtTypStt = false
                this.isShtMdaStt = false
            }
        },
    },

    methods: {
        // 프로그램정보 활동지 다건 조회
        getCdPrgInfShtlist: function (obj){    

            this.tblUse.serverItems.splice(0)
            this.tblUse.prgTyplist.splice(0)
            this.tblUse.vCd38.splice(0)
            this.tblUse.vCd40.splice(0)            
            this.tblUse.vCd133.splice(0)
            this.tblUse.vCd134.splice(0)
            this.tblUse.vCd135.splice(0)

            this.tblUse.loading = true
            this.prgInfParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)
            
            http.post('prgrm/selCdPrgInfShtlist', this.prgInfParams)
                .then((response) => ( this.prgInfShtRstCd(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selCdPrgInfShtlist : ' + error))
        },

        //프로그램정보 활동지 결과 반영
        prgInfShtRstCd:function(res){

            this.tblUse.loading = false
            if(res.statusCode == '200'){
                this.tblUse.totalItems = res.result.rnTot

                this.tblUse.pageCount = Math.ceil(Number(res.result.rnTot)/this.prgInfParams.rnLmt)


                if(res.result.prgTyplist != null && res.result.prgTyplist != undefined){
                    res.result.prgTyplist.forEach(itm => {
                        this.tblUse.prgTyplist.push(itm)
                    });
                } 
                
                if(res.result.codes != null){
                    if(res.result.codes.vCd38 != undefined) {
                        res.result.codes.vCd38.forEach(cd => {
                            this.tblUse.vCd38.push(cd)                            
                        });
                    }

                    if(res.result.codes.vCd40 != undefined) {
                        res.result.codes.vCd40.forEach(cd => {
                            this.tblUse.vCd40.push(cd)                            
                        });
                    }

                    if(res.result.codes.vCd133 != undefined) {
                        res.result.codes.vCd133.forEach(cd => {
                            this.tblUse.vCd133.push(cd)                            
                        });
                    }

                    if(res.result.codes.vCd134 != undefined) {
                        res.result.codes.vCd134.forEach(cd => {
                            this.tblUse.vCd134.push(cd)
                        });
                    }

                    if(res.result.codes.vCd135 != undefined) {
                        res.result.codes.vCd135.forEach(cd => {
                            this.tblUse.vCd135.push(cd)
                        });
                    }

                }
            
                res.result.list.forEach(itm => {
                    //이미지 비율
                    itm.ratio ='1:1'
                    if(itm.fileLoc == '2'){
                        itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePath+'&fileKndCd=2'
                    }

                    if(itm.width != '' &&  itm.width != null && itm.height != '' && itm.height != null){
                        if(Number(itm.width) > Number(itm.height)) {
                            let val = itm.width/itm.height
                            val = val.toFixed(1)
                            itm.ratio = val+'/'+1
                        } else if(Number(itm.width) < Number(itm.height)) {
                            let val = itm.height/itm.width
                            val = val.toFixed(1)
                            itm.ratio = 1+'/'+val
                        } 
                    }
                    this.tblUse.serverItems.push(itm)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 프로그램정보 활동지 다건 조회
        getPrgInfShtlist: function (obj){    

            this.tblUse.serverItems.splice(0)

            this.tblUse.loading = true
            this.prgInfParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)
            
            
            http.post('prgrm/selPrgInfShtlist', this.prgInfParams)
                .then((response) => ( this.prgInfShtRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },

        //프로그램정보 활동지 결과 반영
        prgInfShtRst:function(res){

            this.tblUse.loading = false
            if(res.statusCode == '200'){
                this.tblUse.totalItems = res.result.rnTot

                this.tblUse.pageCount = Math.ceil(Number(res.result.rnTot)/this.prgInfParams.rnLmt)

                res.result.list.forEach(itm => {
                    //이미지 비율
                    itm.ratio ='1:1'
                    if(itm.fileLoc == '2'){
                        itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePath+'&fileKndCd=2'
                    }

                    if(itm.width != '' &&  itm.width != null && itm.height != '' && itm.height != null){
                        if(Number(itm.width) > Number(itm.height)) {
                            let val = itm.width/itm.height
                            val = val.toFixed(1)
                            itm.ratio = val+'/'+1
                        } else if(Number(itm.width) < Number(itm.height)) {
                            let val = itm.height/itm.width
                            val = val.toFixed(1)
                            itm.ratio = 1+'/'+val
                        } 
                    }

                    
                    this.tblUse.serverItems.push(itm)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //계획서 및 활동지 선택
        rowSltAct:function(obj ,key){
            this.sltObj = obj
            this.dialog.splice(key, 1, true)
        },

        //유형 검색 적용
        shtTypApply:function (arr){
            this.prgInfParams.shtTyp.splice(0)
            arr.forEach(itm => {
                if(itm.isOn) this.prgInfParams.shtTyp.push(itm.valcd)
            });
            this.getPage(1)
        },

        //미디어 검색 적용
        shtMdaApply:function (arr){
            this.prgInfParams.shtMda.splice(0)

            arr.forEach(itm => {
                if(itm.isOn) this.prgInfParams.shtMda.push(itm.valcd)
            });

            this.getPage(1)
        },

        //제목 검색 유형
        shtSbjtApply:function (arr){
            this.prgInfParams.shtSbjt.splice(0)

            arr.forEach(itm => {
                if(itm.isOn) this.prgInfParams.shtSbjt.push(itm.valcd)
            });

            this.getPage(1)
        },

        //페이지 이동
        getPage:function(val){
            this.prgInfParams.page = val
            let obj = {}
            obj.page = this.prgInfParams.page
            obj.itemsPerPage = this.prgInfParams.rnLmt
            this.getPrgInfShtlist(obj)
        },
    },

    data: () => ({
        sltObj          : {},
        prgInfParams:{
            rnStrt      : 0,
            rnLmt       : 8,
            srchWd1     : '',
            shtTyp      :[],
            shtMda      :[],
            shtSbjt     :[],
            shtTyps     :[],
            shtMdas     :[],
            shtSbjts    :[],
        },
        tblUse:{
            loading         : false,
            totalItems      : 0,
            prgTyplist      : [],
            vCd38           : [],
            vCd40           : [],
            vCd133          : [],
            vCd134          : [],
            vCd135          : [],
            serverItems     : [],
            page            : 1,
            pageCount       : 0,
        },
        isShtTypStt     : false,
        isShtMdaStt     : false,
        isShtSbjtStt    : false,
        dialog          : [false,false,]        
    }),
};
</script>