<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="py-7 pl-7 pr-6 midAreaColor" cols="5">
                <v-sheet class="" color="midAreaColor">
                    <p class="hamletFont text-h5 topTitleColor--text font-weight-black">신규 수급자 상담일지</p>
                    <v-divider class="midAreaColor mt-1"></v-divider>
                    <v-row class="pa-0 align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <span class="text-h5 font-weight-bold topTitleColor--text mx-3">
                            {{ $moment(lmtQtr.orgEntDt, 'YYYY-MM-DD').format('YYYY.MM.DD') }}
                        </span>
                        <span style="padding-left: 2px;">
                            <v-icon color="topTitleColor" style="padding-bottom: 2px;" size="16">mdi-tilde</v-icon>
                        </span>
                        <span class="text-h5 font-weight-bold topTitleColor--text mx-3">
                            {{ $moment(lmtQtr.orgLvDt, 'YYYY-MM-DD').format('YYYY.MM.DD') }}
                        </span>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-divider class="midAreaColor my-1"></v-divider>
                    <v-data-table
                        class="dft-hvr-tbl rounded-lg" height="584" locale="ko"
                        :headers="tblHdr" :items="cnslnHisList"
                        :loading="isLoad" :loading-text="loadMsg"
                        fixed-header disable-pagination hide-default-footer dense>
                        <template v-slot:item='{ item }'>
                            <tr
                                :class="[sltCnsln == item.cnslnHisPk ? 'onClr' : '']"
                                @click="isKeepSlt(item.cnslnHisPk, item.bnftOfrPlnHisPk)">
                                <td class="txtAlgnMid tableBB py-2 px-1">
                                    <span class="fontOneRem">{{ item.cnslnDtDot }}</span>
                                    <!-- <span class="d-inline-block" style="width: 18px; height: 10px;"></span> -->
                                </td>
                                <td class="overflow-text-hidden txtAlgnMid tableBB py-2 px-1">
                                    <span class="fontOneRem">{{ item.cnslr }}</span>
                                    <!-- <span class="d-inline-block" style="width: 18px; height: 10px;"></span> -->
                                </td>
                                <td class="tableBB py-2 px-1">
                                    <div class="overflow-text-hidden txtAlgnMid" style="width: 84px;">
                                        <span class="fontOneRem">{{ item.cnslnMthdNm }}</span>
                                    </div>
                                </td>
                                <td class="overflow-text-hidden txtAlgnMid tableBB py-2 px-1">
                                    <span class="fontOneRem">{{ item.cnslnTrgt }} &#40;{{ item.rltns }}&#41;</span>
                                </td>
                                <td class="txtAlgnMid tableBB py-2 px-1">
                                    <span class="fontOneRem">{{ item.bnftOfrDtDot }}</span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col>
            <v-col class="py-7 pr-7 pl-6" cols="7">
                <v-sheet class="" color="">
                    <v-row class="pa-0 justify-center align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <v-icon color="black" @click="$emit('isClose', '.')" x-large>mdi-close</v-icon>
                    </v-row>
                    <v-row class="pa-0 mb-2 mr-1 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 기본정보</span>
                    </v-row>
                    <v-divider class="mr-1" color="black"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">수급자</span>
                        </v-col>
                        <v-col class="overflow-text-hidden tableBR px-4" style="padding-top: 5px;" cols="4">
                            <span v-if="bscsInf.name != ''" class="fontOneRem">{{ bscsInf.name }}&nbsp;&#40;&nbsp;</span>
                            <span v-if="bscsInf.gndr == 'M'" class="fontOneRem">남</span>
                            <span v-else-if="bscsInf.gndr == 'F'" class="fontOneRem">여</span>
                            <span v-if="typeof bscsInf.age == 'number'" class="fontOneRem">&nbsp;&#47;&nbsp;{{ bscsInf.age }}세&nbsp;&#41;</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">현황</span>
                        </v-col>
                        <v-col class="overflow-text-hidden px-4" style="padding-top: 5px;" cols="4">
                            <span class="fontOneRem">{{ bscsInf.entStcdNm }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">입소일</span>
                        </v-col>
                        <v-col class="overflow-text-hidden tableBR px-4" style="padding-top: 5px;" cols="4">
                            <span class="fontOneRem">{{ bscsInf.entDtDot }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">퇴소일</span>
                        </v-col>
                        <v-col class="overflow-text-hidden px-4" style="padding-top: 5px;" cols="4">
                            <span class="fontOneRem">{{ bscsInf.lvDtDot }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-divider class="white my-2"></v-divider>
                    <v-row class="pa-0 mb-2 mr-1 align-center posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">상담일지 상세정보</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="white--text mr-2" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" :disabled="savBtn" @click="preInsCnsln"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">저장</span>
                        </v-btn>
                        <v-btn
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blackBtnColor" :disabled="sltCnsln == -1" @click="dcsnDelBtn"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-trash-can-outline</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">삭제</span>
                        </v-btn>
                    </v-row>
                    <v-divider class="mr-1" color="black"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">상담일시</span>
                        </v-col>
                        <v-col class="py-1 px-4" cols="7">
                            <v-row class="pa-0" no-gutters>
                                <span class="d-inline-block" style="width: 143px;">
                                    <CmmDateComp
                                        v-model="cnslnHisInf.cnslnDt" :required="false"
                                        :minDt="lmtQtr.orgEntDt" :maxDt="lmtQtr.orgLvDt"
                                        @input="aplctSavBtn">
                                    </CmmDateComp>
                                </span>
                                <span class="d-inline-block ml-2" style="width: 60px;">
                                    <CmmTimeField
                                        v-model="cnslnHisInf.cnslnTmStrt"
                                        :required="false" :maxTm="cnslnHisInf.cnslnTmEnd"
                                        @input="aplctSavBtn">
                                    </CmmTimeField>
                                </span>
                                <span class="d-flex align-center ml-1">
                                    <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                                </span>
                                <span class="d-inline-block ml-1" style="width: 60px;">
                                    <CmmTimeField
                                        v-model="cnslnHisInf.cnslnTmEnd" :required="false"
                                        :minTm="cnslnHisInf.cnslnTmStrt"
                                        @input="aplctSavBtn">
                                    </CmmTimeField>
                                </span>
                            </v-row>
                        </v-col>
                        <v-col class="py-1 px-4" cols="3">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 2px !important;"
                                v-model="cnslnHisInf.cnslnImpsbYn" :ripple="false"
                                true-value="1" false-value="2" label="상담불가"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">상담대상</span>
                        </v-col>
                        <v-col class="tableBR py-1 px-4" cols="5">
                            <v-row class="pa-0" no-gutters>
                                <span claa="d-inline-block" style="width: 136px;">
                                    <v-text-field
                                        v-model="cnslnHisInf.cnslnTrgt" @input="aplctSavBtn"
                                        height="28" readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                            </v-row>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">관계</span>
                        </v-col>
                        <v-col class="py-1 px-4" cols="3">
                            <v-text-field
                                v-model="cnslnHisInf.rltns" @input="aplctSavBtn"
                                height="28" readonly hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">상담방법</span>
                        </v-col>
                        <v-col class="py-1 pl-4 pr-2" cols="3">
                            <v-select
                                v-model="cnslnHisInf.cnslnMthdCd"
                                :items="vCd104" :disabled="true"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </v-col>
                        <v-col class="py-1 pr-4" cols="7">
                            <v-text-field
                                v-model="cnslnHisInf.cnslnMthd"
                                :readonly="!(cnslnHisInf.cnslnMthdCd == '4')"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col
                            class="nmlBfClrImp-greyE01 txtAlgnMid tableBR"
                            style="padding-top: 22px; line-height: 1.2;"
                            cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">상담내용<br>&#40;불가사유&#41;</span>
                        </v-col>
                        <v-col class="py-1 px-4" cols="10">
                            <v-textarea
                                class="exp-textarea" rows="4"
                                v-model="cnslnHisInf.cnslnCntnt"
                                append-icon="mdi-magnify-expand"
                                @click:append="expArea('상담내용 (불가사유)', 'cnslnCntnt')"
                                @input="aplctSavBtn"
                                no-resize hide-details outlined dense>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col
                            class="nmlBfClrImp-greyE01 txtAlgnMid tableBR"
                            style="padding-top: 25px; line-height: 1.2;"
                            cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">조치내용</span>
                        </v-col>
                        <v-col class="py-1 px-4" cols="10">
                            <v-textarea
                                class="exp-textarea" rows="3"
                                v-model="cnslnHisInf.actnCntnt"
                                append-icon="mdi-magnify-expand"
                                @click:append="expArea('조치내용', 'actnCntnt')"
                                no-resize hide-details outlined dense>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">상담자</span>
                        </v-col>
                        <v-col class="py-1 px-4" cols="4">
                            <v-row class="pa-0" no-gutters>
                                <span class="d-inline-block mr-1" style="width: 122px;">
                                    <v-text-field
                                        v-model="cnslnHisInf.cnslr"
                                        height="28" readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn
                                    class="white--text" color="brown lighten-3" style="padding: 0px 1px 0px 2px !important;"
                                    min-width="30" height="26"
                                    :disabled="/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cnslnHisInf.cnslnDt) == false"
                                    @click="onLclMdl('04', 800)" small>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원 선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-row>
                        </v-col>
                        <v-col class="px-4" style="padding-top: 7px;" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-spacer></v-spacer>
                                <span class="fontNineThRem grey006--text pr-1">※ 상담일시 작성하면 직원 선택 가능합니다.</span>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 tableBR pl-1" style="padding-top: 56px;" cols="2">
                            <v-row class="pa-0 align-center justify-center" no-gutters>
                                <span class="font-weight-medium">자료첨부</span>
                                <span class="d-inline-block" style="padding-top: 2px;">
                                    <v-file-input
                                        v-model="files"
                                        class="athFileInput" truncate-length="5" prepend-icon="mdi-upload"
                                        @change="upFile()" :disabled="bscsInf.bnMmbrPk == -1 ? true : false"
                                        multiple counter hide-input outlined dense hide-details>
                                    </v-file-input>
                                </span>
                            </v-row>
                        </v-col>
                        <v-col class="overflow-y-auto px-4 py-1" style="width: 100%; height: 140px;" cols="10">
                            <span v-for="(item, j) in fileList" :key="j" class="pb-2 pr-2">
                                <v-chip
                                    class="mt-1"
                                    @click="athFileDownload(j)" @click:close="delAtchfiles(j)"
                                    label close outlined>
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    <span class="longText">{{ item.orgFileNm }}</span>
                                </v-chip> 
                            </span>
                            <v-divider v-if="fileList.length > 0" class="my-2"></v-divider>
                            <div>
                                <span v-for="( item, i ) in imgList" :key="i" class="d-inline-block mx-1">
                                    <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)"></imageCard>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-divider class="white my-2"></v-divider>
                    <v-row class="pa-0 mb-2 mr-1 align-center posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">급여제공 반영 정보</span>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" :disabled="bnftBtn" @click="onLclMdl('05', 1400)"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-text-box-edit-outline</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">급여제공</span>
                        </v-btn> -->
                    </v-row>
                    <v-divider class="mr-1" color="black"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">반영일</span>
                        </v-col>
                        <v-col class="overflow-text-hidden px-4" style="padding-top: 7px;" cols="3">
                            <span class="fontOneRem">{{ bnftOfrPlnHisInf.bnftOfrDt }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">급여내용</span>
                        </v-col>
                        <v-col class="overflow-text-hidden px-4" style="padding-top: 7px;" cols="5">
                            <span class="fontOneRem">{{ bnftOfrPlnHisInf.aftBnftCntnt }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                </v-sheet>
            </v-col>
        </v-row>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <CmntsMdl
                v-if="lclMdlInf.typ == '03'"
                @isClose="rstLclMdlInf" @outText="aplyArea"
                :mdlTtl="expMdlTtl" :mdlCmnts="expMdlCntnt" :hSize='30'>
            </CmntsMdl>
            <EmpSelector
                v-else-if="lclMdlInf.typ == '04'"
                :prtWrtDt="cnslnHisInf.cnslnDt"
                @isClose="rstLclMdlInf" @outEmp="getSltEmp">
            </EmpSelector>
            <BnftSrvPlnMdfComp
                v-else-if="lclMdlInf.typ == '05'"
                :bnMmbrPk="bscsInf.bnMmbrPk" :inqYYYY="pckrYyyy"
                :bnftSrvPlnPk="sltBnftOfr" :bnftSrvPlnBzPk="sltCnsln"
                :bnftSrvPlnBzClcd="'18'" :mdfRsncd="'5'"
                @isClose="rstLclMdlInf" @modalEvent="bnftSrvPlnMdfEvn()">
            </BnftSrvPlnMdfComp>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http, remoteURL } from '@/api/baseAxios';
import { tmpFileUpload, fileDownload, delAtchdFile } from '../../api/index.js';

import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import imageCard from '../commons/imageCard.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import CmntsMdl from '../commons/CmntsMdl.vue';
import BnftSrvPlnMdfComp from '../bnftSrv/BnftSrvPlnMdf.vue'; 

export default {
    name: 'EntCnslnJrnlsMdl',
    
    components: {
        CmmDateComp,
        CmmTimeField,
        imageCard,
        EmpSelector,
        CmntsMdl,
        BnftSrvPlnMdfComp,
    },

    props : { 
   
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getTmpObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                this.rmCnslnHis()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },

       'sltCnsln': function(){
            if(this.sltCnsln > 0){
                this.getCnslnHisInf(this.sltCnsln)
            }
            else{
                this.bnftBtn = true
                this.clrForm()
            }
       },
       'cnslnHisInf.cnslnDt': function(){
            // 상담일시 변경하면 상담자 초기화
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cnslnHisInf.cnslnDt) == false){
                this.cnslnHisInf.cnslr = ''
                this.cnslnHisInf.cnslrPk = -1
            }
       },
       'cnslnHisInf.cnslnMthdCd': function(){
            if(this.cnslnHisInf.cnslnMthdCd != '4')
                this.cnslnHisInf.cnslnMthd = ''
       },
    },
        
    methods: {
        // 입소 상담일지 가간별 다건 조회
        getEntBnfcrCnslnHisList: function (strt, end){
            this.sltCnsln = -1
            this.sltBnftOfr = -1
            this.isLoad = true
            this.clrForm()
            this.cnslnHisList.splice(0)

            let obj = {}
            obj.bnMmbrPk = this.bscsInf.bnMmbrPk
            obj.bnfcrPk = this.bscsInf.bnfcrPk
            obj.inqYmdStart = strt
            obj.inqYmdEnd = end

            http.post('bnfcrAuth/selEntBnfcrCnslnHisList', obj)
                .then((response) => ( this.entBnfcrCnslnHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selEntBnfcrCnslnHisList : ' + error))
        },

        entBnfcrCnslnHisRst: function (res){
            if(res.statusCode == '200'){
                if(res.result.list.length > 0){
                    res.result.list.forEach(itm => {
                        if(itm.cnslnDt != null || itm.cnslnDt != '')
                            itm.cnslnDtDot = this.$moment(itm.cnslnDt, 'YYYYMMDD').format('YYYY.MM.DD')
                        else
                            itm.cnslnDtDot = ''

                        if(itm.bnftOfrDt != '' && itm.bnftOfrDt != null)
                            itm.bnftOfrDtDot = this.$moment(itm.bnftOfrDt, 'YYYYMMDD').format('YYYY.MM.DD')
                        else
                            itm.bnftOfrDtDot = ''
                    });
                    this.cnslnHisList = res.result.list
                }
            }

            this.isLoad = false
        },

        // 상담일지 수급자별 한건 조회 (상세)
        getCnslnHisInf: function (pk){
            this.bnftBtn = true
            this.clrForm()

            let obj = {}
            obj.cnslnHisPk = pk
            obj.bnMmbrPk = this.bscsInf.bnMmbrPk
            obj.mdfClcd = '2'
            obj.mdfRsncd = '5'
            obj.bzClcd = 18
            obj.bzPk = pk
            obj.bnftOfrPlnHisPk = this.sltBnftOfr

            http.post('bnfcr/selCnslnHisInf', obj)
                .then((response) => ( this.cnslnHisInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisInf : ' + error))
        },

        cnslnHisInfRst: function (res) {
            if(res.statusCode == '200'){
                if(res.result.info != '' && res.result.info != null){
                    this.cnslnHisInf.cnslnHisPk = res.result.info.cnslnHisPk
                    if(/^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(res.result.info.cnslnDt))
                        this.cnslnHisInf.cnslnDt = this.$moment(res.result.info.cnslnDt, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.cnslnHisInf.cnslnImpsbYn = res.result.info.cnslnImpsbYn
                    this.cnslnHisInf.cnslnMthd = res.result.info.cnslnMthd
                    this.cnslnHisInf.cnslnMthdCd = res.result.info.cnslnMthdCd
                    this.cnslnHisInf.cnslnTmStrt = res.result.info.cnslnTmHhStrt+':'+res.result.info.cnslnTmMmStrt
                    this.cnslnHisInf.cnslnTmEnd = res.result.info.cnslnTmHhEnd+':'+res.result.info.cnslnTmMmEnd
                    //this.cnslnHisInf.cnslnTrgt = res.result.info.cnslnTrgt
                    //this.cnslnHisInf.rltns = res.result.info.rltns
                    this.cnslnHisInf.cnslr = res.result.info.cnslr
                    this.cnslnHisInf.cnslnCntnt = res.result.info.cnslnCntnt
                    this.cnslnHisInf.actnCntnt = res.result.info.actnCntnt
                }

                if(res.result.list.length > 0){
                    this.bnftOfrPlnHisInf.bnftOfrPlnHisPk = res.result.list[0].bnftOfrPlnHisPk
                    if(res.result.list[0].bnftOfrDt != '' && res.result.list[0].bnftOfrDt != null)
                        this.bnftOfrPlnHisInf.bnftOfrDt = this.$moment(res.result.list[0].bnftOfrDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        this.bnftOfrPlnHisInf.bnftOfrDt = ''
                    this.bnftOfrPlnHisInf.aftBnftCntnt = res.result.list[0].aftBnftCntnt
                }

                let fList = res.result.files.filter(v => v.fileKndCd == '1')
                let iList = res.result.files.filter(v => v.fileKndCd == '2')

                fList.forEach( items => {
                    let fileNm = items.fileNm.split(".")
                    items.ext = fileNm[1]
                    this.fileList.push(items)
                })
        
                iList.forEach(itm => {
                    itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePth+'&fileKndCd='+itm.fileKndCd
                    this.imgList.push(itm)
                })

                this.files = null
            }
            
            this.bnftBtn = false
            this.aplctSavBtn()
        },

        // 상담내역 저장
        setCnslnHis: function (obj){
            this.savBtn = true

            http.post('bnfcr/insCnslnHis', obj)
                .then((response) => ( this.cnslnHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insCnslnHis : ' + error))
        },

        cnslnHisRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.bnftSrvPlnMdfEvn()
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 상담내역 삭제
        rmCnslnHis: function (){
            this.savBtn = true

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.cnslnHisPk = this.sltCnsln
            obj.bnftOfrPlnHisPk = this.sltBnftOfr
            obj.userNm = this.$store.getters.getUserNm

            http.post('bnfcr/delCnslnHis', obj)
                .then((response) => ( this.cnslnHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delCnslnHis : ' + error))
        },

        // 첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/tmpFileUpload : ' + error))
        },

        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },

        // 첨부파일 다운로드
        athFileDownload: function (index) {
            fileDownload(this.fileList[index])
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },

        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/delAtchdFile : ' + error))
        },

        delAtchdFileAfter: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                this.fileList.splice(0)

                obj.tmr = 2500
                obj.type = 'info'
                
                this.getAtchdFileList()
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 상담일지 저장 사전 작업
        preInsCnsln: function (){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '상담일시 작성범위를 벗어났습니다.'  // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            let oObj = Object.assign({}, this.cnslnHisInf)
            oObj.fcltyNum = this.$store.getters.getFcltyNum
            oObj.bnMmbrPk = this.bscsInf.bnMmbrPk
            oObj.cnslnHisPk = this.sltCnsln
            oObj.name = this.bscsInf.name
            oObj.userNm = this.$store.getters.getUserNm
            oObj.cnslnDt = this.$moment(oObj.cnslnDt, 'YYYY-MM-DD').format('YYYYMMDD')
            oObj.files = []

            if(oObj.cnslnTmStrt != null || oObj.cnslnTmStrt != ''){
                let splitTime = oObj.cnslnTmStrt?.split(':', 2)
                oObj.cnslnTmHhStrt = splitTime[0]
                oObj.cnslnTmMmStrt = splitTime[1]
            }

            if(oObj.cnslnTmEnd != null || oObj.cnslnTmEnd != ''){
                let splitTime = oObj.cnslnTmEnd?.split(':', 2)
                oObj.cnslnTmHhEnd = splitTime[0]
                oObj.cnslnTmMmEnd = splitTime[1]
            }

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined) oObj.files.push(items)
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined) oObj.files.push(items)
            })

            let trg = this.$moment(oObj.cnslnDt, 'YYYY-MM-DD')
            let strt = this.$moment(this.lmtQtr.orgEntDt, 'YYYY-MM-DD')
            let end = '2999-12-31'
            if(this.lmtQtr.orgLvDt != '')
                end = this.$moment(this.lmtQtr.orgLvDt, 'YYYY-MM-DD')

            if(trg.isSameOrAfter(strt) && trg.isSameOrBefore(end))
                this.setCnslnHis(oObj)
            else
                this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.entStcdNm = obj.entStcdNm
            this.bscsInf.entDtDot = obj.entDtDot
            this.bscsInf.lvDtDot = obj.lvDtDot

            this.cnslnHisInf.cnslnTrgt = obj.name
            this.cnslnHisInf.cnslnTrgtPk = obj.bnMmbrPk
            this.cnslnHisInf.rltns = '본인'

            this.lmtQtr.orgEntDt = obj.lmtStrt
            this.lmtQtr.orgLvDt = obj.lmtEnd

            this.vCd104 = obj.vCd104
            this.pckrYyyy = obj.fxdYyyy

            this.getEntBnfcrCnslnHisList(
                this.$moment(obj.lmtStrt, 'YYYY-MM-DD').format('YYYYMMDD'),
                this.$moment(obj.lmtEnd, 'YYYY-MM-DD').format('YYYYMMDD'),
            )
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cnslnHisInf.cnslnDt) == false)
                return 0
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.cnslnHisInf.cnslnTmStrt) == false)
                return 0
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.cnslnHisInf.cnslnTmEnd) == false)
                return 0
            if(this.cnslnHisInf.cnslnTrgt == '')
                return 0
            if(this.cnslnHisInf.cnslr == '')
                return 0
            if(this.cnslnHisInf.cnslnCntnt == '')
                return 0
            if(this.cnslnHisInf.rltns == '')
                return 0

            this.savBtn = false
        },

        // 삭제버튼 분기
        dcsnDelBtn: function () {
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '수급자 ' + this.bscsInf.name + ' ' + this.$moment(this.cnslnHisInf.cnslnDt, 'YYYY-MM-DD').format('YYYY.MM.DD')
            obj.cntnt = ['상담내역을 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });
        },

        isKeepSlt: function (pk, bPk) {
            let tmp = -1
            if(bPk != null) tmp = bPk

            if(this.sltCnsln != pk)
                this.sltCnsln = pk
            else
                this.sltCnsln = -1

            if(this.sltBnftOfr != tmp)
                this.sltBnftOfr = tmp
            else
                this.sltBnftOfr = -1
        },

        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }
        },

        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined)
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            else
                this.imgList.splice(idx, 1)
        },

        delAtchfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined)
                this.removeAthFile(this.fileList[idx].atchdFilePk)
            else 
                this.fileList.splice(idx, 1)
        },

        // 직원 선택 모달 이벤트
        getSltEmp: function (obj) {
            this.cnslnHisInf.cnslr = obj[0].name
            this.cnslnHisInf.cnslrPk = obj[0].empPk

            this.aplctSavBtn()
        },

        // 입력필드 확장 내용 반영
        aplyArea: function (txt) {
            this.cnslnHisInf[this.cmntsVal] = txt

            this.aplctSavBtn()
        },

        // 입력필드 확장 모달 오픈
        expArea: function (ttl, objKey) {
            this.cmntsVal = objKey
            this.expMdlTtl = ttl
            this.expMdlCntnt = this.cnslnHisInf[objKey]

            this.onLclMdl('03', 800)
        },

        // 급여제공반영 모달 이벤트
        bnftSrvPlnMdfEvn: function () {
            this.getEntBnfcrCnslnHisList(
                this.$moment(this.lmtQtr.orgEntDt, 'YYYY-MM-DD').format('YYYYMMDD'),
                this.$moment(this.lmtQtr.orgLvDt, 'YYYY-MM-DD').format('YYYYMMDD'),
            )

            this.rstLclMdlInf()
        },

        // 초기화
        clrForm: function () {
            this.cnslnHisInf.cnslnHisPk = -1
            this.cnslnHisInf.cnslnDt = ''
            this.cnslnHisInf.cnslnImpsbYn = '2'
            this.cnslnHisInf.cnslnMthd = ''
            this.cnslnHisInf.cnslnMthdCd = '2'
            this.cnslnHisInf.cnslnTmStrt = ''
            this.cnslnHisInf.cnslnTmEnd = ''
            //this.cnslnHisInf.cnslnTrgt = ''
            //this.cnslnHisInf.cnslnTrgtPk = -1
            this.cnslnHisInf.cnslr = ''
            this.cnslnHisInf.cnslrPk = -1
            this.cnslnHisInf.cnslnCntnt = ''
            this.cnslnHisInf.actnCntnt = ''
            //this.cnslnHisInf.rltns = ''

            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)
            
            this.bnftOfrPlnHisInf.bnftOfrPlnHisPk = -1
            this.bnftOfrPlnHisInf.bnftOfrDt = ''
            this.bnftOfrPlnHisInf.aftBnftCntnt = ''

            this.aplctSavBtn()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        bnftBtn: true,
        sltCnsln: -1,
        sltBnftOfr: -1,
        pckrYyyy: 1900,
        pckrQtr: 1,
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        cmntsVal: '',                   // 확장모달
        expMdlTtl: '',                  // 확장모달
        expMdlCntnt: '',                // 확장모달
        runAble: '0',
        files: null,                    // 자료첨부
        fileList: [],                   // 자료첨부
        imgList: [],                    // 자료첨부
        cnslnHisList: [],
        vCd104: [],
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', age: '', gndr: '', entStcdNm: '', entDtDot: '', lvDtDot: ''
        },
        cnslnHisInf: {
            cnslnHisPk: -1, cnslnDt: '', cnslnImpsbYn: '2', cnslnMthd: '', cnslnMthdCd: '2', cnslnTmStrt: '', cnslnTmEnd: '',
            cnslnTrgt: '', cnslnTrgtPk: -1, cnslr: '', cnslrPk: -1, cnslnCntnt: '', actnCntnt: '', rltns: '', cnslnClcd: '1'
        },
        bnftOfrPlnHisInf: {
            bnftOfrPlnHisPk: -1, bnftOfrDt: '', aftBnftCntnt: '',
        },
        lmtQtr: { orgEntDt: '', orgLvDt: '' },
        tblHdr: [
            { text: '상담일', value: 'cnslnDtDot', sortable: false, width: '100', class: 'black--text bottomBorder py-3', align: 'center', },
            { text: '상담직원', value: 'cnslr', sortable: false, width: '84', class: 'black--text bottomBorder', align: 'center', },
            { text: '상담방법', value: 'cnslnMthdNm', sortable: false, width: '90', class: 'black--text bottomBorder', align: 'center', },
            { text: '상담대상(관계)', value: 'cnslnTrgt', sortable: false, width: '124', class: 'black--text bottomBorder', align: 'center', },
            { text: '급여제공 반영일', value: 'bnftOfrDt', sortable: false, width: '131', class: 'black--text bottomBorder', align: 'center', },
        ],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어
    }),
};
</script>