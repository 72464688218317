<template>
    <v-sheet class="ma-0 pa-7" rounded="md">        
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="8">
                <span class="text-h4 font-weight-bold">{{title}} &#40;{{year}}년&#41;</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="$emit('isClose')">mdi-close</v-icon>
            </v-col>
        </v-row>        
        <v-divider :class="['mt-8', empEduSttList.length > 0 ? 'mr-2__5' : '']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', empEduSttList.length > 0 ? 'mr-2__5' : '']" no-gutters>            
            <v-col class="txtAlgnMid tableBR py-1" :cols="cols1">
                <span class="font-weight-medium">직원명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" :cols="cols2">
                <span class="font-weight-medium">직종</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" :cols="cols3">
                <span class="font-weight-medium">입사일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" :cols="cols4">
                <span class="font-weight-medium">퇴사일</span>
            </v-col>
            <v-col class="" :cols="cols5">
                <v-row v-if="edutypcd == '702'" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="fontOneDotOneRem font-weight-medium">1분기</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="fontOneDotOneRem font-weight-medium">2분기</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="fontOneDotOneRem font-weight-medium">3분기</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="3">
                        <span class="fontOneDotOneRem font-weight-medium">4분기</span>
                    </v-col>                   
                </v-row>
                <v-row v-if="edutypcd == '706'" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="6">
                        <span class="fontOneDotOneRem font-weight-medium">상반기</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="6">
                        <span class="fontOneDotOneRem font-weight-medium">하반기</span>
                    </v-col>
                </v-row>
                <v-row v-if="edutypcd == '707'" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="12">
                        <span class="fontOneDotOneRem font-weight-medium">교육일자</span>
                    </v-col>
                </v-row>
            </v-col>           
        </v-row>
        <v-divider :class="[empEduSttList.length > 0 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="empEduSttList.length > 0" class="overflow-y-auto" style="width: 100%; height: 521px;">
            <v-progress-linear v-if="this.ldr" class="" color="cyan" indeterminate></v-progress-linear>  
            <v-row v-for="(item, i) in empEduSttList" :key="i" 
                class="pa-0" 
                no-gutters>
                <v-col class="d-flex align-center justify-center tableBB tableBR px-1" :cols="cols1">
                    <span class="fontOneRem font-weight-medium">{{item.name}}</span>
                </v-col>
                <v-col class="d-flex align-center justify-center tableBB tableBR px-1" :cols="cols2">
                    <span class="fontOneRem font-weight-medium">{{item.jobCd}}</span>
                </v-col>
                <v-col class="d-flex align-center justify-center tableBB tableBR px-1" :cols="cols3">
                    <span class="fontOneRem font-weight-medium">{{item.entDt?.beforeDateFormatDot() }}</span>
                </v-col>
                <v-col class="d-flex align-center justify-center tableBB tableBR px-1" :cols="cols4">
                    <span class="fontOneRem font-weight-medium">{{item.rtrDt?.beforeDateFormatDot() }}</span>
                </v-col>
                <v-col  class="" :cols="cols5">
                    <v-row v-if="edutypcd == '702'"
                        no-gutters>
                        <v-col class="txtAlgnMid tableBB tableBR py-1" cols="3">
                            <span v-if="item.qtrDt1 != null && item.qtrDt1 != ''">{{item.qtrDt1}}</span>
                            <span v-else>
                                <span v-if="item.rtr1" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBB tableBR py-1" cols="3">
                            <span v-if="item.qtrDt2 != null && item.qtrDt2 != ''">{{item.qtrDt2}}</span>
                            <span v-else>
                                <span v-if="item.rtr2" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBB tableBR py-1" cols="3">
                            <span v-if="item.qtrDt3 != null && item.qtrDt3 != ''">{{item.qtrDt3}}</span>
                            <span v-else>
                                <span v-if="item.rtr3" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBB py-1" cols="3">
                            <span v-if="item.qtrDt4 != null && item.qtrDt4 != ''">{{item.qtrDt4}}</span>
                            <span v-else>
                                <span v-if="item.rtr4" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row v-if="edutypcd == '706'"
                        no-gutters>
                        <v-col class="txtAlgnMid tableBB tableBR py-1" cols="6">
                            <span v-if="item.hlfDt1 != null && item.hlfDt1 != ''">{{item.hlfDt1}}</span>
                            <span v-else>
                                <span v-if="item.hlf1" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBB py-1" cols="6">
                            <span v-if="item.hlfDt2 != null && item.hlfDt2 != ''">{{item.hlfDt2}}</span>
                            <span v-else>
                                <span v-if="item.hlf2" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row v-if="edutypcd == '707'"
                        no-gutters>
                        <v-col class="txtAlgnMid tableBB tableBR py-1" cols="12">
                            <span v-if="item.eduDt != null && item.eduDt != ''">{{item.eduDt}}</span>
                            <span v-else>
                                <span v-if="item.hlf1 || item.hlf2" class="red--text">미작성</span>
                                <span v-else>퇴사</span>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 직원 교육 현황이 존재하지 않습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'EdctnSttMdl',

    props : {
        title       : { type: String, default: '' },
        edutypcd    : { type: String, default: '' },
        year        : { type: Number, default: 0 },
        cd42        : {type: Array, default: () => { return [] }},
    },
        
    components: {
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.edutypcd == '702') { 
                this.cols1                  = '1'
                this.cols2                  = '1'
                this.cols3                  = '1'
                this.cols4                  = '1'
                this.cols5                  = '8'
            }
            else if(this.edutypcd == '706')
            {
                this.cols1                  = '2'
                this.cols2                  = '2'
                this.cols3                  = '2'
                this.cols4                  = '2'
                this.cols5                  = '4'
            }
            else if(this.edutypcd == '707')
            {
                this.cols1                  = '2'
                this.cols2                  = '2'
                this.cols3                  = '2'
                this.cols4                  = '2'
                this.cols5                  = '4'
            }
            this.getEmpEduSttList()
        })
    },

    computed: {
       
    },

    watch:{
        
    },
    
    methods: {
        // 직원교육 다건 조회
        getEmpEduSttList: function (){        
            this.ldr = true        
            this.empEduSttList.splice(0)
            let obj = {
                inqYYYY     : this.year,
                eduTypcd    : this.edutypcd,
            }

            http.post('emp/selEmpEduSttList', obj)
                .then((response) => ( this.empEduSttListRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpEduSttList : ' + error))
        },
        empEduSttListRst:function(res){
            console.log(res)
            if(res.statusCode == '200'){
                res.result.list.forEach(el => {
                    
                    let rtr1 = true
                    let rtr2 = true
                    let rtr3 = true
                    let rtr4 = true
                    let hlf1 = true
                    let hlf2 = true

                    if(el.rtrDt != null && el.rtrDt != ''){
                        rtr1 = this.$moment(el.rtrDt).isAfter(this.$moment(this.year+'0101'))
                        rtr2 = this.$moment(el.rtrDt).isAfter(this.$moment(this.year+'0401'))
                        rtr3 = this.$moment(el.rtrDt).isAfter(this.$moment(this.year+'0701'))
                        rtr4 = this.$moment(el.rtrDt).isAfter(this.$moment(this.year+'1001'))
                        hlf1 = this.$moment(el.rtrDt).isAfter(this.$moment(this.year+'0101'))
                        hlf2 = this.$moment(el.rtrDt).isAfter(this.$moment(this.year+'0701'))
                    }

                    this.cd42.forEach(code => {
                        if(el.jobCd == code.valcd) el.jobCd = code.valcdnm
                    });

                    el.rtr1 = rtr1
                    el.rtr2 = rtr2
                    el.rtr3 = rtr3
                    el.rtr4 = rtr4
                    el.hlf1 = hlf1
                    el.hlf2 = hlf2
                    this.empEduSttList.push(el)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.ldr = false
        },
    },

    data: () => ({
        ldr                 : false,
        empEduSttList       : [],
        cols1               : '1',
        cols2               : '1',
        cols3               : '1',
        cols4               : '1',
        cols5               : '8',
    }),
};
</script>