<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">의사소통</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" style="padding-top: 11px;" cols="2">
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">의사소통</span>
                        </v-row>
                    </v-col>
                    <v-col class="px-2 py-1" cols="10">
                        <v-radio-group
                            v-model="inpRadio.val_13_1"
                            class="radio-dft-Theme mt-0" ref="info" hide-details row>
                            <v-radio
                                class="pl-0 pr-6" style="padding-bottom: 2px !important;"
                                :ripple="false" label="모두 이해하고 의사를 표현한다" value="1">
                            </v-radio>
                            <v-radio
                                class="pl-0 pr-6" style="padding-bottom: 2px !important;"
                                :ripple="false" label="대부분 이해하고 의사를 표현한다" value="2">
                            </v-radio>
                            <v-radio
                                class="pl-0 pr-6" style="padding-bottom: 2px !important;"
                                :ripple="false" label="가끔 이해하고 의사를 표현한다" value="3">
                            </v-radio>
                            <v-radio
                                class="pl-0 pr-6" style="padding-bottom: 2px !important;"
                                :ripple="false" label="거의 이해하지 못하고 의사를 전달하지 못한다" value="4">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" style="padding-top: 11px;" cols="2">
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">발음능력</span>
                        </v-row>
                    </v-col>
                    <v-col class="px-2 py-1" cols="10">
                        <v-radio-group
                            v-model="inpRadio.val_14_1"
                            class="radio-dft-Theme mt-0" ref="info" hide-details row>
                            <v-radio
                                class="pl-0 pr-8" style="padding-bottom: 2px !important;"
                                :ripple="false" label="정확하게 발음이 가능하다" value="1">
                            </v-radio>
                            <v-radio
                                class="pl-0 pr-0" style="padding-bottom: 2px !important;"
                                :ripple="false" label="웅얼거리는 소리로만 한다" value="2">
                            </v-radio>
                            <v-radio
                                class="pl-0 pr-7" style="padding-bottom: 2px !important;"
                                :ripple="false" label="간혹 어눌한 발음이 섞인다" value="3">
                            </v-radio>
                            <v-radio
                                class="pl-0 pr-0" style="padding-bottom: 2px !important;"
                                :ripple="false" label="전혀 발음하지 못한다" value="4">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 10px; padding-bottom: 10px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="30" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'CmncState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oObj = {}
                oObj.examPk = 0
                oObj.depth2 = '13'
                oObj.depth3 = '99'
                oObj.valCd = '1'
                oObj.val = ''
                oObj.val2 = this.inpTxt.val_99_1
                finArr.push(oObj)

                Object.keys(this.inpRadio).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '13'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpRadio[itm]
                    oObj.val2 = ''

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = '0'
            });
        },
    },

    data: () => ({
        // 판단근거
        inpTxt: { val_99_1: '' },
        // 의사소통 (d3: 13)
        // 발음능력 (d3: 14)
        inpRadio: { val_13_1: '0', val_14_1: '0', },
        // 
        inpCheck: { },
    }),
};
</script>