<template>
    <v-sheet class="posn-rltv">
        <v-row class="px-3" no-gutters>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"
                @click="onLclMdl('11', 900)"
                samll rounded>
                <v-icon small>mdi-account-multiple-plus</v-icon>
                <span>수급자 그룹 추가</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme white--text ml-1" height="28" color="blue001"
                @click="onLclMdl('12', 1300)"
                samll rounded>
                <v-icon style="padding-bottom: 1px;" small>mdi-format-list-group</v-icon>
                <span>그룹별 수급자 정보</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme white--text ml-1" height="28" color="blue001"
                @click="onLclMdl('13', 1300)"
                samll rounded>
                <v-icon style="padding-bottom: 1px;" small>mdi-format-list-group</v-icon>
                <span>그룹별 프로그램 정보</span>
            </v-btn>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <div class="pa-2">
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                    <span class="fontOneDotOneRem font-weight-bold">수급자</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR px-1" style="padding-top: 6px;" cols="2">
                    <span v-if="bnfcrInf.name != ''" class="fontOneRem">{{ bnfcrInf.name }}&nbsp;&#40;&nbsp;</span>
                    <span v-if="bnfcrInf.gndr == 'M'" class="fontOneRem">남</span>
                    <span v-else-if="bnfcrInf.gndr == 'F'" class="fontOneRem">여</span>
                    <span v-if="typeof bnfcrInf.age == 'number'" class="fontOneRem">&nbsp;&#47;&nbsp;{{ bnfcrInf.age }}세&nbsp;&#41;</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                    <span class="fontOneDotOneRem font-weight-bold">입소일</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                    <span class="fontOneRem">{{ bnfcrInf.entDt }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                    <span class="fontOneDotOneRem font-weight-bold">주요질환</span>
                </v-col>
                <v-col class="overflow-text-hidden px-3" style="padding-top: 6px;" cols="5">
                    <span class="fontOneRem">{{ bnfcrInf.majorDsss }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="white mb-6"></v-divider>
            <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                <span
                    class="d-inlne-block"
                    style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 정보</span>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="nmlBfClrImp-greyE01 pa-0" no-gutters>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="6">
                    <span class="fontOneDotOneRem font-weight-bold">신체상태</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="3">
                    <span class="fontOneDotOneRem font-weight-bold">인지상태</span>
                </v-col>
                <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="3">
                    <span class="fontOneDotOneRem font-weight-bold">기능회복훈련 계획</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="nmlBfClrImp-greyE01 pa-0" no-gutters>
                <v-col class="tableBR" style="min-height: 45px;" cols="6">
                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                        <v-col class="posn-rltv tableBR pb-1 pr-6" style="padding-top: 1px; height: 100%;" cols="6">
                            <p class="txtAlgnMid fontOneDotOneRem font-weight-bold ma-0 pa-0" style="line-height: 1.3;">
                                욕구사정 평가 <br> {{ dsrEvltnInf.examDt == '' ? '' : '( ' + dsrEvltnInf.examDt + ' )' }}
                            </p>
                            <span
                                class="d-inline-block"
                                style="width: 26px; height: 26px; position: absolute; top: 8px; right: 28px;">
                                <v-btn
                                    class="" color="grey006" min-width="26" height="26"
                                    @click="ctgyDsrEvltn()" :disabled="dsrEvltnInf.pk == -1"
                                    small icon>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="20">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>욕구사정평가 상세</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </v-col>
                        <v-col class="posn-rltv pb-1 pr-6" style="padding-top: 1px;" cols="6">
                            <p class="txtAlgnMid fontOneDotOneRem font-weight-bold ma-0 pa-0" style="line-height: 1.3;">
                                욕창 위험도 평가 <br> {{ bdsrEvltn.examDt == '' ? '' : '( ' + bdsrEvltn.examDt + ' )' }}
                            </p>
                            <span
                                class="d-inline-block"
                                style="width: 26px; height: 26px; position: absolute; top: 8px; right: 28px;">
                                <v-btn
                                    class="" color="grey006" min-width="26" height="26"
                                    @click="onLclMdl('22', 1400)" :disabled="bdsrEvltn.pk == -1"
                                    small icon>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="20">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>욕창위험도 평가 상세</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="posn-rltv tableBR pb-1 pr-6" style="padding-top: 1px;" cols="3">
                    <p class="txtAlgnMid fontOneDotOneRem font-weight-bold ma-0 pa-0" style="line-height: 1.3;">
                        인지기능 평가 <br> {{ cgntvEvltn.examDt == '' ? '' : '( ' + cgntvEvltn.examDt + ' )' }}
                    </p>
                    <span
                        class="d-inline-block"
                        style="width: 26px; height: 26px; position: absolute; top: 8px; right: 28px;">
                        <v-btn
                            class="" color="grey006" min-width="26" height="26"
                            @click="onLclMdl('23', 1400)" :disabled="cgntvEvltn.pk == -1"
                            small icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="20">mdi-square-edit-outline</v-icon>
                                </template>
                                <span>인지기능 평가 상세</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="posn-rltv pb-1 pr-6" style="padding-top: 1px;" cols="3">
                    <p class="txtAlgnMid fontOneDotOneRem font-weight-bold ma-0 pa-0" style="line-height: 1.3;">
                        급여제공계획 <br> {{ bnftSrvPln.wrtDt == '' ? '' : '( ' + bnftSrvPln.wrtDt + ' )' }}
                    </p>
                    <span
                        class="d-inline-block"
                        style="width: 26px; height: 26px; position: absolute; top: 8px; right: 28px;">
                        <v-btn
                            class="" color="grey006" min-width="26" height="26"
                            @click="onLclMdl('24', 1400)" :disabled="bnftSrvPln.pk == -1"
                            small icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="20">mdi-square-edit-outline</v-icon>
                                </template>
                                <span>급여제공계획 상세</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="tableBR" cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tableBR pb-2" style="padding-top: 6px; min-height: 106px;" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="txtAlgnEnd" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm01 }}</span>
                                </v-col>
                                <v-col class="txtAlgnFst pl-6" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm01 == '' ? '' : dsrEvltnInf.valCnt01 + '문항' }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="txtAlgnEnd" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm02 }}</span>
                                </v-col>
                                <v-col class="txtAlgnFst pl-6" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm02 == '' ? '' : dsrEvltnInf.valCnt02 + '문항' }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="txtAlgnEnd" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm03 }}</span>
                                </v-col>
                                <v-col class="txtAlgnFst pl-6" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm03 == '' ? '' : dsrEvltnInf.valCnt03 + '문항' }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="txtAlgnEnd" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm04 }}</span>
                                </v-col>
                                <v-col class="txtAlgnFst pl-6" cols="6">
                                    <span class="fontOneRem">{{ dsrEvltnInf.valNm04 == '' ? '' : dsrEvltnInf.valCnt04 + '문항' }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="d-flex align-center pb-2" style="padding-top: 6px;" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="txtAlgnEnd" cols="6">
                                    <span class="fontOneRem">{{ bdsrEvltn.valNm }}</span>
                                </v-col>
                                <v-col class="txtAlgnFst pl-2" cols="6">
                                    <span class="fontOneRem">{{ bdsrEvltn.val == '' ? '' : '( ' + bdsrEvltn.val + '점 )' }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex align-center tableBR pb-2" style="padding-top: 6px;" cols="3">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="txtAlgnEnd" cols="6">
                            <span class="fontOneRem">{{ cgntvEvltn.valNm }}</span>
                        </v-col>
                        <v-col class="txtAlgnFst pl-4" cols="6">
                            <span class="fontOneRem">{{ cgntvEvltn.val == '' ? '' : '( ' + cgntvEvltn.val + '점 )' }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex align-center justify-center pb-2" style="padding-top: 6px;" cols="3">
                    <span class="fontOneRem">{{ bnftSrvPln.dtldGls }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="white my-3"></v-divider>
            <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                <span
                    class="d-inlne-block"
                    style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 수급자 그룹 정보</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="white--text" style="padding: 9px 7px 8px 5px !important;"
                    color="blueBtnColor"
                    @click="onGrpCnfgrMdl" :disabled="$store.getters.getBnfcrState.bnmmbr == 0"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">수급자별 그룹 설정</span>
                </v-btn>
                <v-btn
                    class="white--text ml-2" style="padding: 9px 7px 8px 5px !important;"
                    color="blueBtnColor"
                    @click="onGrpLogMdl" :disabled="$store.getters.getBnfcrState.bnmmbr == 0"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">수급자별 그룹 내역</span>
                </v-btn>
            </v-row>
            <v-divider :class="[bnfcrGrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
            <v-row :class="['nmlBfClrImp-greyE01 pa-0', bnfcrGrList.length > 10 ? 'mr-2__5' : '']" no-gutters>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="3">
                    <span class="fontOneDotOneRem font-weight-bold">수급자 그룹명</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="5">
                    <span class="fontOneDotOneRem font-weight-bold">설명</span>
                </v-col>
                <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="2">
                    <span class="fontOneDotOneRem font-weight-bold">설정일자</span>
                </v-col>
            </v-row>
            <v-divider :class="[bnfcrGrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
            <div v-if="bnfcrGrList.length > 0" class="overflow-y-auto" style="width: 100%; height: 262px;">
                <v-row
                    class="tableBB"
                    v-for="(itm, i) in bnfcrGrList" :key="i"
                    no-gutters>
                    <v-col class="txtAlgnMid tableBR px-2 pb-1" style="padding-top: 1px;" cols="3">
                        <div class="overflow-text-hidden" style="width: 216px;">
                            <span class="fontOneRem">{{ itm.bnfcrgrNm }}</span>
                        </div>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR pb-1" style="padding-top: 1px;" cols="2">
                        <span class="fontOneRem">{{ itm.idxNm }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR px-2 pb-1" style="padding-top: 1px;" cols="5">
                        <div class="overflow-text-hidden" style="width: 362px;">
                            <span class="fontOneRem">{{ itm.bnfcrgrDesc }}</span>
                        </div>
                    </v-col>
                    <v-col class="txtAlgnMid pb-1" style="padding-top: 1px;" cols="2">
                        <span class="fontOneRem">{{ itm.wrtDtDot }}</span>
                    </v-col>
                </v-row>
            </div>
            <v-row v-else class="tableBB pa-0" no-gutters>
                <v-col class="txtAlgnMid py-2" cols="12">
                    <span class="fontOneRem grey006--text">※ 조회된 그룹정보가 없습니다.</span>
                </v-col>
            </v-row>
        </div>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <PrgmBnfcrGrMgmt
                v-if="lclMdlInf.typ == '11'"
                ref="pMSelector" :isUpd="true"
                @isClose="rstLclMdlInf">
            </PrgmBnfcrGrMgmt>
            <DsrEvltnComp
                v-else-if="lclMdlInf.typ == '21'"
                ref="dsrEvltn" 
                :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                :btnDisabled="true" :bsrEvltnPk="dsrEvltnInf.pk"
                :inqYYYY="yyyy"
                @modalEvent="rstLclMdlInf()">
            </DsrEvltnComp>
            <BdsrEvltnComp
                v-else-if="lclMdlInf.typ == '22'"
                ref="bdsrEvltn"
                :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                :bdsrEvltnPk="bdsrEvltn.pk"
                @modalEvent="rstLclMdlInf()">
            </BdsrEvltnComp>
            <CgntvEvltnComp
                v-else-if="lclMdlInf.typ == '23'"
                ref="cgntvEvltn" 
                :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr" 
                :btnDisabled="true" :cgntvEvltnPk="cgntvEvltn.pk"
                @modalEvent="rstLclMdlInf()">
            </CgntvEvltnComp>
            <BnftSrvPlnViw
                v-else-if="lclMdlInf.typ == '24'"
                :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                :bnfcrPk="$store.getters.getBnfcrState.bnfcr"
                :bnftSrvPlnCmpPk="bnftSrvPln.pk"
                @modalEvent="rstLclMdlInf()">
            </BnftSrvPlnViw>
            <GrpByBnfcrMdl v-else-if="lclMdlInf.typ == '12'" :compTyp="'bnfcr'" @isClose="rstLclMdlInf()"></GrpByBnfcrMdl>
            <GrpByBnfcrMdl v-else-if="lclMdlInf.typ == '13'" :compTyp="'prgm'" @isClose="rstLclMdlInf()"></GrpByBnfcrMdl>
            <DsrEvltnAvrInd
                v-if="lclMdlInf.typ == '31'"
                @isClose="offDsrEvltnMdl">
            </DsrEvltnAvrInd>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import PrgmBnfcrGrMgmt from './PrgrmgrMultiSelector.vue';       // 프로그램 수급자 그룹관리 모달
import DsrEvltnComp from '../bnftSrv/DsrEvltn.vue';             // 욕구사정평가 (~2024)
import BdsrEvltnComp from '../bnftSrv/BdsrEvltn.vue';           // 욕창위험도
import CgntvEvltnComp from '../bnftSrv/CgntvEvltn.vue';         // 인지기능
import BnftSrvPlnViw from '../bnftSrv/BnftSrvPlnViw.vue';       // 기능회복훈련
import GrpByBnfcrMdl from './GrpByBnfcrMdl.vue';                // 그룹별 수급자 정보
import DsrEvltnAvrInd from '../bnftSrv/DsrEvltnAvrInd.vue';     // 욕구사정평가 (2025~)

export default {
    name: 'GrpstRcpnt',
    
    components: {
        PrgmBnfcrGrMgmt,
        DsrEvltnComp,
        BdsrEvltnComp,
        CgntvEvltnComp,
        BnftSrvPlnViw,
        GrpByBnfcrMdl,
        DsrEvltnAvrInd,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                this.getPrgBnfcrGrpInf(this.$store.getters.getBnfcrState.bnmmbr)
        })
    },

    computed: {
        // 수급자 선택 이벤트
        getStoreBnMmbrPk: function (){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getRootActv: function () {
            return this.$store.getters.getRootActv
        },
    },


    watch: {
        getStoreBnMmbrPk: function (v){
            if(v == 0)
                this.clrForm()
            else
                this.getPrgBnfcrGrpInf(v)
        },
        getRootActv: function (v) {
            if(this.runAble == '1' && !v){
                if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                    this.getPrgBnfcrGrpInf(this.$store.getters.getBnfcrState.bnmmbr)
                else
                    this.clrForm()
            }

            if(!v)
                this.runAble = '0'
        },
    },
    
    methods: {
        // 프로그램 수급자 그룹관리 페이지 정보 토탈
        getPrgBnfcrGrpInf: function (mbr){
            this.clrForm()

            let obj = {}
            obj.bnMmbrPk = mbr

            http.post('prgrm/selGrpMgmt', obj)
                .then((response) => ( this.prgBnfcrGrpRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selGrpMgmt : ' + error))
        },

        prgBnfcrGrpRst: function (res){
            if(res.statusCode == '200'){
                if(res.result.bnfcrSttInf.bnfcrExamHisPk101 != null) this.dsrEvltnInf.pk = res.result.bnfcrSttInf.bnfcrExamHisPk101
                if(res.result.bnfcrSttInf.examDt101 == null)
                    this.dsrEvltnInf.examDt = '없음'
                else
                    this.dsrEvltnInf.examDt = this.$moment(res.result.bnfcrSttInf.examDt101, 'YYYYMMDD').format('YYYY.MM.DD')
                if(res.result.bnfcrSttInf.itm101ValNm1 != null) this.dsrEvltnInf.valNm01 = res.result.bnfcrSttInf.itm101ValNm1
                if(res.result.bnfcrSttInf.itm101ValNm2 != null) this.dsrEvltnInf.valNm02 = res.result.bnfcrSttInf.itm101ValNm2
                if(res.result.bnfcrSttInf.itm101ValNm3 != null) this.dsrEvltnInf.valNm03 = res.result.bnfcrSttInf.itm101ValNm3
                if(res.result.bnfcrSttInf.itm101ValNm4 != null) this.dsrEvltnInf.valNm04 = res.result.bnfcrSttInf.itm101ValNm4
                if(res.result.bnfcrSttInf.itm101ValCnt1 != null) this.dsrEvltnInf.valCnt01 = res.result.bnfcrSttInf.itm101ValCnt1
                if(res.result.bnfcrSttInf.itm101ValCnt2 != null) this.dsrEvltnInf.valCnt02 = res.result.bnfcrSttInf.itm101ValCnt2
                if(res.result.bnfcrSttInf.itm101ValCnt3 != null) this.dsrEvltnInf.valCnt03 = res.result.bnfcrSttInf.itm101ValCnt3
                if(res.result.bnfcrSttInf.itm101ValCnt4 != null) this.dsrEvltnInf.valCnt04 = res.result.bnfcrSttInf.itm101ValCnt4

                if(res.result.bnfcrSttInf.bnfcrExamHisPk103 != null) this.bdsrEvltn.pk = res.result.bnfcrSttInf.bnfcrExamHisPk103
                if(res.result.bnfcrSttInf.examDt103 == null)
                    this.bdsrEvltn.examDt = '없음'
                else
                    this.bdsrEvltn.examDt = this.$moment(res.result.bnfcrSttInf.examDt103, 'YYYYMMDD').format('YYYY.MM.DD')
                if(res.result.bnfcrSttInf.itm103Val != null) this.bdsrEvltn.val = res.result.bnfcrSttInf.itm103Val
                if(res.result.bnfcrSttInf.itm103ValNm != null) this.bdsrEvltn.valNm = res.result.bnfcrSttInf.itm103ValNm

                if(res.result.bnfcrSttInf.bnfcrExamHisPk104 != null) this.cgntvEvltn.pk = res.result.bnfcrSttInf.bnfcrExamHisPk104
                if(res.result.bnfcrSttInf.examDt104 == null)
                    this.cgntvEvltn.examDt = '없음'
                else
                    this.cgntvEvltn.examDt = this.$moment(res.result.bnfcrSttInf.examDt104, 'YYYYMMDD').format('YYYY.MM.DD')
                if(res.result.bnfcrSttInf.itm104Val != null) this.cgntvEvltn.val = res.result.bnfcrSttInf.itm104Val
                if(res.result.bnfcrSttInf.itm104ValNm != null) this.cgntvEvltn.valNm = res.result.bnfcrSttInf.itm104ValNm

                if(res.result.bnfcrSttInf.bnftOfrPlnHisPk != null) this.bnftSrvPln.pk = res.result.bnfcrSttInf.bnftOfrPlnHisPk
                if(res.result.bnfcrSttInf.wrtDt == null)
                    this.bnftSrvPln.wrtDt = '없음'
                else
                    this.bnftSrvPln.wrtDt = this.$moment(res.result.bnfcrSttInf.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                if(res.result.bnfcrSttInf.dtldGls != null) this.bnftSrvPln.dtldGls = res.result.bnfcrSttInf.dtldGls


                if(Object.keys(res.result.bnfcrInf).length > 0){
                    this.bnfcrInf.name = res.result.bnfcrInf.name
                    this.bnfcrInf.gndr = res.result.bnfcrInf.gndr
                    this.bnfcrInf.age = res.result.bnfcrInf.age
                    this.bnfcrInf.entDt = this.$moment(res.result.bnfcrInf.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    this.bnfcrInf.majorDsss = res.result.bnfcrInf.majorDsss
                }


                res.result.bnfcrgrList.forEach(itm => {
                    if(itm.wrtDt != null && itm.wrtDt != '')
                        itm.wrtDtDot = this.$moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.wrtDtDot = ''
                });

                this.bnfcrGrList = res.result.bnfcrgrList
            }
            else{
                let obj = {}
                obj.actv = true             // 경고 노출여부
                obj.tmr = 3500              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'        // 경고 유형 (success, info, warning, error)
                obj.title = res.message     // 경고 타이틀
                obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 수급자별 그룹설정 모달
        onGrpCnfgrMdl: function () {
            let obj = Object.assign({}, this.bnfcrInf)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 900, prsst: true, typ: '4-02', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 수급자별 그룹내역 모달
        onGrpLogMdl: function () {
            let obj = Object.assign({}, this.bnfcrInf)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 800, prsst: true, typ: '4-03', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 욕구사정평가 모달 (2024년 이후)
        onDsrEvltnMdl: function () {
            let obj = Object.assign({}, this.bnfcrInf)
            obj.bsrEvltnPk = this.dsrEvltnInf.pk
            obj.isUpd = false
     
            this.$store.commit('setTmpObj', obj)
            this.onLclMdl('31', 1400)
        },

        offDsrEvltnMdl: function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                this.getPrgBnfcrGrpInf(this.$store.getters.getBnfcrState.bnmmbr)
            else
                this.clrForm()
            
            this.$store.commit('setTmpObj', {})

            this.rstLclMdlInf()
        },

        // 욕구사정평가 모달 선택
        ctgyDsrEvltn: function () {
            let spltYYYY = this.$moment(this.dsrEvltnInf.examDt, 'YYYY.MM.DD').format('YYYY')
            this.yyyy = Number(spltYYYY)

            if( this.yyyy > 2024)
                this.onDsrEvltnMdl()
            else
                this.onLclMdl('21', 1400)
        },

        clrForm: function (){
            this.dsrEvltnInf.pk = -1
            this.dsrEvltnInf.examDt = ''
            this.dsrEvltnInf.valNm01 = ''
            this.dsrEvltnInf.valNm02 = ''
            this.dsrEvltnInf.valNm03 = ''
            this.dsrEvltnInf.valNm04 = ''
            this.dsrEvltnInf.valCnt01 = 0
            this.dsrEvltnInf.valCnt02 = 0
            this.dsrEvltnInf.valCnt03 = 0
            this.dsrEvltnInf.valCnt04 = 0
            this.bdsrEvltn.pk = -1
            this.bdsrEvltn.examDt = ''
            this.bdsrEvltn.valNm = ''
            this.bdsrEvltn.val = ''
            this.cgntvEvltn.pk = -1
            this.cgntvEvltn.examDt = ''
            this.cgntvEvltn.valNm = ''
            this.cgntvEvltn.val = ''
            this.bnftSrvPln.pk = -1
            this.bnftSrvPln.wrtDt = ''
            this.bnftSrvPln.dtldGls = ''

            this.bnfcrGrList.splice(0)

            this.bnfcrInf.name = ''
            this.bnfcrInf.gndr = ''
            this.bnfcrInf.age = ''
            this.bnfcrInf.entDt = ''
            this.bnfcrInf.majorDsss = ''
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            //this.getPrgBnfcrSttInf(this.$store.getters.getBnfcrState.bnmmbr)
            if(this.lclMdlInf.typ == '21')
                this.yyyy = 0

            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        yyyy: 0,                                                    // 욕구사정 평가 모달 전달 연도변수
        bnfcrGrList: [],
        bnfcrInf: { name: '', gndr: '', age: '', entDt: '', majorDsss: '' },
        dsrEvltnInf: {                                              // 욕구사정 평가 정보 (101)
            pk: -1, examDt: '',
            valNm01: '', valNm02: '', valNm03: '', valNm04: '',
            valCnt01: 0, valCnt02: 0, valCnt03: 0, valCnt04: 0
        },
        bdsrEvltn: { pk: -1, examDt: '', valNm: '', val: '' },      // 욕창 위험도 평가 정보 (103)
        cgntvEvltn: { pk: -1, examDt: '', valNm: '', val: ''  },    // 인지기능 평가 정보 (104)
        bnftSrvPln: { pk: -1, wrtDt: '', dtldGls: '' },             // 급여제공계획 정보
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },         // 통합 팝업 제어 (21: 욕구사정, 22: 욕창위험도, 23: 인지기능, 24: 급여제공)
    }),
};
</script>