<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">간호처치 상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 11px;" cols="2">
                        <span class="font-weight-medium">호흡</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_1" :ripple="false"
                                true-value="1" false-value="2" label="기관지 절개관 간호"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_2" :ripple="false"
                                true-value="1" false-value="2" label="흡인"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_3" :ripple="false"
                                true-value="1" false-value="2" label="산소요법"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_4" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 99px;">
                                <v-text-field
                                    v-model="inpTxt.val_1_4"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 26px;" cols="2">
                        <span class="font-weight-medium">영양</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_1" :ripple="false"
                                true-value="1" false-value="2" label="경관영양"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_3" :ripple="false"
                                true-value="1" false-value="2" label="비위관"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_4" :ripple="false"
                                true-value="1" false-value="2" label="위관"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1 mr-4" style="font-size: 0.98rem;">&#41;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_5" :ripple="false"
                                true-value="1" false-value="2" label="치료식이"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 147px;">
                                <v-text-field
                                    v-model="inpTxt.val_2_5"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_2" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_2_2"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="2">
                        <span class="font-weight-medium">배설</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_1" :ripple="false"
                                true-value="1" false-value="2" label="투석간호"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_2" :ripple="false"
                                true-value="1" false-value="2" label="유치도뇨관"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_3" :ripple="false"
                                true-value="1" false-value="2" label="단순도뇨"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_4" :ripple="false"
                                true-value="1" false-value="2" label="방광루"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_5" :ripple="false"
                                true-value="1" false-value="2" label="요루"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_6" :ripple="false"
                                true-value="1" false-value="2" label="장루간호"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 25px;" cols="2">
                        <span class="font-weight-medium">상처</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_4_1" :ripple="false"
                                true-value="1" false-value="2" label="상처간호"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="fontOneRem mr-1">부위:</span>
                            <span class="d-inline-block" style="width: 246px;">
                                <v-text-field
                                    v-model="inpTxt.val_4_1"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1 mr-2" style="font-size: 0.98rem;">&#41;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_4_4" :ripple="false"
                                true-value="1" false-value="2" label="당뇨발간호"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_4_3" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_4_3"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 45px;" cols="1">
                        <span class="font-weight-medium">욕창</span>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 tableBR" cols="1">
                        <v-row class="justify-center" style="padding-top: 8px; padding-bottom: 7px;" no-gutters>
                            <span class="font-weight-medium">단계</span>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="justify-center" style="padding-top: 25px;" no-gutters>
                            <span class="font-weight-medium">부위</span>
                        </v-row>
                    </v-col>
                    <v-col class="pt-1 pb-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-radio-group
                                v-model="inpRadio.val_5_1"
                                class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                <v-radio
                                    class="px-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="1단계" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="2단계" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="3단계" value="3">
                                </v-radio>
                                <v-radio
                                    class="px-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="4단계" value="4">
                                </v-radio>
                            </v-radio-group>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="white--text mr-2" style="padding: 9px 7px 8px 5px !important;"
                                color="green001" :disabled="inpRadio.val_5_1 == '0'"
                                @click="inpRadio.val_5_1 = '0'"
                                min-width="20" height="20" small outlined>
                                <v-icon class="mr-1" size="14">mdi-backspace-outline</v-icon>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">지우기</span>
                            </v-btn>
                        </v-row>
                        <v-divider class="mt-1 mb-2"></v-divider>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_1" :ripple="false"
                                true-value="1" false-value="2" label="머리"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_2" :ripple="false"
                                true-value="1" false-value="2" label="등"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_3" :ripple="false"
                                true-value="1" false-value="2" label="어깨"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_4" :ripple="false"
                                true-value="1" false-value="2" label="팔꿈치"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_5" :ripple="false"
                                true-value="1" false-value="2" label="엉덩이"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_6" :ripple="false"
                                true-value="1" false-value="2" label="뒤꿈치"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_6_7" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_6_7"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 82px;" cols="2">
                        <span class="font-weight-medium">통증</span>
                    </v-col>
                    <v-col class="tableBR pb-1" style="padding-top: 38px;" cols="3">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_1" :ripple="false"
                                true-value="1" false-value="2" label="암 발생부위"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-divider class="" style="margin-top: 38px; margin-bottom: 6px;"></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_8" :ripple="false"
                                true-value="1" false-value="2" label="일반통증부위"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-divider class="" style="margin-top: 8px; margin-bottom: 10px;"></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_9_1" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                    </v-col>
                    <v-col class="pb-2" style="padding-top: 7px;" cols="7">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_2" :ripple="false"
                                true-value="1" false-value="2" label="폐"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_3" :ripple="false"
                                true-value="1" false-value="2" label="위"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_4" :ripple="false"
                                true-value="1" false-value="2" label="대장"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_5" :ripple="false"
                                true-value="1" false-value="2" label="간"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_6" :ripple="false"
                                true-value="1" false-value="2" label="전립선"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_7" :ripple="false"
                                true-value="1" false-value="2" label="유방"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_8" :ripple="false"
                                true-value="1" false-value="2" label="담낭 및 기타담도"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_7_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 260px;">
                                <v-text-field
                                    v-model="inpTxt.val_7_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-divider class="mt-2" style="margin-bottom: 6px;"></v-divider>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_1" :ripple="false"
                                true-value="1" false-value="2" label="머리"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_2" :ripple="false"
                                true-value="1" false-value="2" label="상지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_3" :ripple="false"
                                true-value="1" false-value="2" label="하지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_4" :ripple="false"
                                true-value="1" false-value="2" label="허리"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_5" :ripple="false"
                                true-value="1" false-value="2" label="등"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_8_6" :ripple="false"
                                true-value="1" false-value="2" label="복부"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-divider class="mt-2" style="margin-bottom: 8px;"></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="ml-3 mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 311px;">
                                <v-text-field
                                    v-model="inpTxt.val_9_1"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1 mr-2" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 10px; padding-bottom: 10px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="30" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'NrsnCareState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                if(itm.depth3 != '5' || itm.depth3 != '9' || itm.depth3 != '99' )
                    this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '5' && itm.valCd == '1')
                    this.inpRadio.val_5_1 = itm.val
                else if(itm.depth3 == '1' && itm.valCd == '4')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '2' && itm.valCd == '2')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '2' && itm.valCd == '5')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '4' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '4' && itm.valCd == '3')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '6' && itm.valCd == '7')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '7' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '9' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let obj = {}
                obj.examPk = 0
                obj.depth2 = '7'
                obj.depth3 = '99'
                obj.valCd = '1'
                obj.val = ''
                obj.val2 = this.inpTxt.val_99_1
                finArr.push(obj)

                let rdo = {}
                rdo.examPk = 0
                rdo.depth2 = '7'
                rdo.depth3 = '5'
                rdo.valCd = '1'
                rdo.val = this.inpRadio.val_5_1
                rdo.val2 = ''
                finArr.push(rdo)
   
                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '7'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '1' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '2' && oObj.valCd == '2')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '2' && oObj.valCd == '5')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '4' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '4' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '6' && oObj.valCd == '7')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '7' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '9' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '5')
                        oObj.val = this.inpRadio.val_5_1
                    else if(oObj.depth3 == '1' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '2' && oObj.valCd == '2')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '2' && oObj.valCd == '5')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '4' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '4' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '6' && oObj.valCd == '7')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '7' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '9' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = '0'
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 기타, 치료식이, 상처부위, 판단근거
        inpTxt: {
            val_1_4: '', val_2_2: '', val_2_5: '', val_4_1: '', val_4_3: '', val_6_7: '', val_7_9: '', val_9_1: '',
            val_99_1: ''
        },
        // 욕창단계 (d3: 5) - 0: 없음, 1: 1단계, 2: 2단계, 3: 3단계, 4: 4단계
        inpRadio: { val_5_1: '0', },
        // 호흡 (d3: 1) - cd1: 기관지 절개관 간호, cd2: 흡인, cd3: 산소요법, cd4: 기타
        // 영양 (d3: 2) - cd1: 경관영양, cd2: 기타, cd3: 비위관, cd4: 위관, cd5: 치료식이
        // 배설 (d3: 3) - cd1: 투석간호, cd2: 유치도뇨관, cd3: 단순도뇨, cd4: 방광루, cd5: 요루, cd6: 장루간호
        // 상처 (d3: 4) - cd1: 상처간호, cd3: 기타, cd4: 당뇨발간호
        // 욕창부위 (d3: 6) - cd1: 머리, cd2: 등, cd3: 어깨, cd4: 팔꿈치, cd5: 엉덩이, cd6: 뒤꿈치, cd7: 기타
        // 통증 암발생부위 (d3: 7) - cd1: 암 발생부위, cd2: 폐, cd3: 위, cd4: 대장, cd5: 간, cd6: 전립선, cd7: 유방
        //                         cd8: 담낭 및 기타담도, cd9: 기타
        // 통증 일반통증부위 (d3: 8) - cd8: 일반통증부위, cd1: 머리, cd2: 상지, cd3: 하지, cd4: 허리, cd5: 등, cd6: 복부
        // 통증 기타 (d3: 9) - cd1: 기타
        inpCheck: {
            val_1_1: '2', val_1_2: '2', val_1_3: '2', val_1_4: '2',
            val_2_1: '2', val_2_2: '2', val_2_3: '2', val_2_4: '2', val_2_5: '2',
            val_3_1: '2', val_3_2: '2', val_3_3: '2', val_3_4: '2', val_3_5: '2', val_3_6: '2',
            val_4_1: '2', val_4_3: '2', val_4_4: '2',
            val_6_1: '2', val_6_2: '2', val_6_3: '2', val_6_4: '2', val_6_5: '2', val_6_6: '2', val_6_7: '2',
            val_7_1: '2', val_7_2: '2', val_7_3: '2', val_7_4: '2', val_7_5: '2', val_7_6: '2', val_7_7: '2', val_7_8: '2', val_7_9: '2',
            val_8_1: '2', val_8_2: '2', val_8_3: '2', val_8_4: '2', val_8_5: '2', val_8_6: '2', val_8_8: '2',
            val_9_1: '2',
        },
    }),
};
</script>