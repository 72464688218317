<template>
    <v-sheet class="pa-0 ma-0">
         <v-row class="mt-2" no-gutters>
            <v-col class="d-flex justify-start align-center" cols="4">
                <CmmExpnBtn
                    v-model="isEntStt"
                    :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                    :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                </CmmExpnBtn>
                <CmmLvnExpnBtn
                    v-model="isLvnStt"
                    class="ml-1"
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>
                <span class="ml-1 d-inline-block" style="width: 106px; height: 34px;">
                    <v-text-field
                        v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                        class="txt-Theme" height="34" label="이름조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="4">
                <expandYearOnlyPicker class="pl-4" @nowYear = "getYear"></expandYearOnlyPicker>
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="4">
                <v-btn                    
                    class="white--text mr-1" style="padding: 10px 10px 10px 10px !important;"
                    color="grey006"
                    @click="prtAllAgrOfr('91')"
                    min-width="20" height="20" small outlined>
                    <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">노인인권보호지침제공 대장 출력</span>
                </v-btn>
                <v-btn                    
                    class="white--text mr-1" style="padding: 10px 10px 10px 10px !important;"
                    color="grey006"
                    @click="prtAllAgrOfr('92')"
                    min-width="20" height="20" small outlined>
                    <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">직원인권 안내 대장 출력</span>
                </v-btn>
            </v-col>
        </v-row>       
        <v-row class="ma-0 pa-0">
            <v-data-table locale="ko" height="660"
                class="tableBB mt-2"
                :headers="headers" :items="allAgrOfrList"
                :loading="isLoad" :loading-text="loadMsg"
                :search="dmyTxt" :custom-filter="srchFltr" 
                fixed-header disable-pagination hide-default-footer dense>

                <template v-slot:header="props" >
                    <thead>
                        <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
            
                    <tr class="fixedCol">
                        <td class="fixedTable fixPstn0 zPstn2 txtAlgnMid fontOneRem removeBB">
                            <span>{{item.rn}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable fixPstn1 zPstn2 txtAlgnMid fontOneRem removeBB">
                            <span>{{item.name}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable fixPstn3 zPstn2 txtAlgnMid fontOneRem removeBB">
                            <span>{{item.entStcdNm}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.age}}</span>
                            <span class="d-inline-block" style="width:22px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                            <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                        </td>                        
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.rsdntBirth}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.lvnRmNm}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                            </span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            {{ item.entDt?.beforeDateFormatDot() }}
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            {{ item.lvDt?.beforeDateFormatDot() }}
                        </td>
                        <td class="fixedTable txtAlgnMid fixRPstn02 zPstn2 fontOneRem removeBB">                            
                            <span v-if="item.eHmnAgrOfrPk == null || item.eHmnAgrOfrPk == ''">
                                <v-btn 
                                    @click="onAgrAddMdl(item, '2')"
                                    min-width="56" width="56" height="26" color="red" samll outlined>
                                    <span style="font-size: 0.9rem;">미작성</span>
                                </v-btn>
                            </span>
                            <span v-else class="crsShp-Pointer" @click="onAgrMdl(item, '2')">
                                <span>{{item.eHmnOfrDt?.beforeDateFormatDot() }}</span>
                                <span v-if="item.eHmnRcvPstnCd != '1'">&#40;{{ item.eHmnRcvNm }}&#41;</span>
                            </span>
                        </td>
                        <td class="fixedTable txtAlgnMid fixRPstn01 zPstn2 fontOneRem removeBB">
                            <span v-if="item.empRgtAgrOfrPk == null || item.empRgtAgrOfrPk == ''">
                                <v-btn 
                                    @click="onAgrAddMdl(item, '4')"
                                    min-width="56" width="56" height="26" color="red" samll outlined>
                                    <span style="font-size: 0.9rem;">미작성</span>
                                </v-btn>
                            </span>
                            <span v-else class="crsShp-Pointer" @click="onAgrMdl(item, '4')">
                                {{item.empRgtOfrDt?.beforeDateFormatDot() }}
                                &#40;{{ item.empRgtRcvNm }}&#41;
                            </span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-row>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

export default {
    name: 'BnfcrAllAgrOfr',

    components: {
        CmmExpnBtn,
        CmmLvnExpnBtn,
        expandYearOnlyPicker,
    },

    created: function(){        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           
        })
    },

    computed: {
        getRootActv: function () {
            return this.$store.getters.getRootActv
        },
    },

    watch:{
        'isEntStt': function (v) {
            if(v) this.isLvnStt = false
        },
        'isLvnStt': function (v) {
            if(v) this.isEntStt = false
        },
        getRootActv: function (v) {
            if(this.runAble == '3' && !v ) this.getAllAgrOfrList()
        },
    },
        
    methods: {
        getYear: function (yyyy){
            this.inqyyyy = yyyy
            if(this.vCd65.length > 0)
                this.getAllAgrOfrList()
            else
                this.getCdAllAgrOfrList()
        },

        // 전체수급자 동의및제공서류 다건 조회(코드포함)
        getCdAllAgrOfrList: function (){
            this.allAgrOfrList.splice(0)
            this.isLoad = true
            let obj = {}
            obj.inqYYYY = this.inqyyyy
            http.post('bnfcrAuth/selCdAllAgrOfrList', obj)
                .then((response) => ( this.allAgrOfrListRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selCdAllAgrOfrList : ' + error))
        },

        // 전체수급자 동의및제공서류 다건 조회
        getAllAgrOfrList: function (){
            this.allAgrOfrList.splice(0)
            this.isLoad = true
            let obj = {}
            obj.inqYYYY = this.inqyyyy
            http.post('bnfcrAuth/selAllAgrOfrList', obj)
                .then((response) => ( this.allAgrOfrListRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selAllAgrOfrList : ' + error))
        },

        allAgrOfrListRst:function(res){      

            this.isLoad = false

            if(res.statusCode == '200'){                
                if(res.result.list != null && res.result.list != undefined) {
                    
                    if(this.vCd65.length == 0){
                        if(res.result.codes != null) { 
                            this.vCd62 = res.result.codes.vCd62
                            this.vCd63 = res.result.codes.vCd63
                            this.vCd65 = res.result.codes.vCd65
                            if(this.vCd65.length > 0) this.vCd65[0].isOn = true
                        }
                    }

                    res.result.list.forEach(item => {  

                        this.vCd65.forEach(code => {
                            if(item.entStcd == code.valcd) item.entStcdNm = code.valcdnm
                        });

                        item.rsdntBirth = item.rsdntBirth?.beforeDateFormatDot()                        
                        this.allAgrOfrList.push(item)
                    });
                }
            }
            else {
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )
            
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                field3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else field3 = field3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return field1 && field2 && field3
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr

            this.dmyTxt = String(Number(this.dmyTxt)+1)
        },

        // 동의 신규 팝업
        onAgrAddMdl: function (itm, clcd) {
            let obj = {}
            
            obj.bnMmbrPk = itm.mmbrPk
            obj.bnfcrPk = itm.bnfcrPk 
            obj.name    = itm.name
            obj.clsfcCode = clcd
            obj.subTtl = '신규'
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-12', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '3'
        },

        // 동의 변경 팝업
        onAgrMdl: function (itm, clcd) {
            let obj = {}
            obj.clsfcCode = clcd
            obj.subTtl      = '수정'
            obj.name        = itm.name

            if(clcd=='2'){
                obj.agrOfrPk    = itm.eHmnAgrOfrPk
                obj.rltns       = itm.eHmnRltns
                obj.ofrDt       = itm.eHmnOfrDt
                obj.rcvPstnCd   = itm.eHmnRcvPstnCd
                obj.rcvNm       = itm.eHmnRcvNm
                obj.agrYn       = itm.eHmnAgrYn
            }
            else if(clcd == '4') { 
                obj.agrOfrPk    = itm.empRgtAgrOfrPk
                obj.rltns       = itm.empRgtRltns
                obj.ofrDt       = itm.empRgtOfrDt
                obj.rcvNm       = itm.empRgtRcvNm
                obj.ntcMthd     = itm.empRgtNtcMthd
            }

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-12', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '3'
        },
        //출력
        prtAllAgrOfr:function(prtcd){            

            this.prtThrowObj.fcltyNum   = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYYYY    = this.inqyyyy
            this.prtThrowObj.prtCd      = prtcd
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        }
    },
    
    data: () => ({
        isLoad  : true,
        loadMsg : '잠시만 기다려주세요.',
        inqyyyy :'',
        vCd62   : [],
        vCd63   : [],
        vCd65   : [],
        srchTxt : '',                               // 테이블 이름 검색 필드
        dmyTxt      : '',                           // 테이블 반응 더미(검색용)
        routeLctnNm : '',                           // 라우터 경로
        isEntStt    : false,                        // 현황 확장 활성화 여부
        isLvnStt    : false,                        // 생활실 확장 활성화 여부
        aslLvnStt   : [],        
        exBtn: [ 
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 194, expandList: [], },
        ],
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 fixPstn0 greyE01 zPstn3 fontOneRem'},
            { text: '수급자', value: 'name', sortable: true, width: '110', align: 'center', class: 'black--text fixPstn1 greyE01 zPstn3 fontOneRem'},
            { text: '현황', value: 'entStcd', sortable: true, width: '120', align: 'center', class: 'black--text fixPstn3 greyE01 zPstn3 fontOneRem'},
            { text: '나이', value: 'age', sortable: true, width: '100', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '등급', value: 'acknwRtng', sortable: true, width: '78', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '입소일', value: 'entDt', sortable: true, width: '95', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '퇴소일', value: 'lvDt', sortable: true, width: '95', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '노인인권보호지침', value: 'eHmnAgrOfrPk', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fixRPstn02 zPstn3 fontOneRem'},
            { text: '직원인권보호지침', value: 'empRgtAgrOfrPk', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fixRPstn01 zPstn3 fontOneRem'},
        ],
        allAgrOfrList   : [],
        runAble         : '0',
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 1, prtCd: '', inqYYYY: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
}
</script>