<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">의약품 관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
       
        <v-form ref="form" lazy-validation> 
        <v-row class="mt-3" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">작성자</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="3" >  
                <span class="d-inline-block" style="width: 70px;">
                    <v-text-field v-model="items.wrtr" color="blue" height="28" ref="info" readonly
                        hide-details outlined dense :key="forceRender"></v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" 
                    small 
                    min-width="30"
                    height="26"  
                    style="padding:0px 1px 0px 2px !important"
                    @click="onModal(0)"
                    >
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </v-col>                                  
        </v-row>
        <v-row style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">투약방법</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="3" >  
                <span style="width:100px;">
                    <v-select 
                        v-model="items.drugClcd"                               
                        :items="drugCode"
                        :key="forceRender"
                        item-text="valcdnm"
                        item-value="valcd"
                        dense hide-details outlined
                        :rules="[rules.required]"
                    /> 
                </span>
            </v-col> 
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">투약명</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-center align-center" cols="5" >               
                <v-text-field v-model="items.drugNm" :rules="[rules.required]" color="blue" height="28" hide-details outlined dense :key="forceRender"></v-text-field>
            </v-col>                       
        </v-row>
        <v-row style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">투약효능</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-center align-center" cols="3" >               
                <v-text-field v-model="items.drugEfct" height="28" hide-details outlined dense :key="forceRender"></v-text-field>
            </v-col>
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">투약방법</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="5" >  
                <span style="width:100px;">
                    <v-text-field v-model="items.drugMthd" height="28" hide-details outlined dense :key="forceRender"></v-text-field>
                </span>
            </v-col>            
        </v-row>
        <v-row style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">취급방법</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-center align-center" cols="3" >               
                <v-text-field v-model="items.hndlMthd" hide-details outlined dense :key="forceRender"></v-text-field>
            </v-col>
            <v-col class="py-1 greyE01 d-flex justify-center align-center" cols="2" >
                <span class="black--text font-weight-medium" style="text-align:center;">비고</span>
            </v-col>
            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="5" >  
                <v-text-field v-model="items.rmrks" height="28" hide-details outlined dense :key="forceRender"></v-text-field>
            </v-col>            
        </v-row>
        </v-form> 
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="onSave" height="30" samll rounded>
                    <v-icon>mdi-content-save-outline</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">저장</span>
                </v-btn>
            </v-col>
        </v-row>      
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$moment().format('YYYYMMDD')"></EmpSelector>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../../components/commons/EmpSelector.vue';
import { insDrugInf } from '../../api/index.js';

export default {
    name: 'DrugInfMgmtWrt',

    props : {        
        drugInfWrtPk:{type:Number, default:-1},
    },
            
    components: {
        EmpSelector
    },

    created: function(){
        
    },
    
    mounted: function(){
        this.$nextTick(function () {
            this.onLoad()
        })
    },

    computed: {

    },

    watch: {
        'drugInfWrtPk':function(value){                    
            if(value > 0) this.onLoad()
        }
    },
        
    methods: {
        onLoad:function(){
            this.$refs.form.reset()
            this.items.drugInfPk = 0

            if(this.$parent.$parent.$parent.$parent.sltItems.drugInfPk > 0){                
                this.items = JSON.parse(JSON.stringify(this.$parent.$parent.$parent.$parent.sltItems))                
            }
            ++this.forceRender
        },
        onClose: function () {                        
            this.$emit('isClose', '.')
        },
        onSave: function() { 
            if(this.$refs.form.validate()){
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    drugInfPk:this.items.drugInfPk, 
                    drugClcd:this.items.drugClcd,
                    drugNm:this.items.drugNm,
                    drugAmt:this.items.drugAmt,
                    drugEfct:this.items.drugEfct,
                    drugMthd:this.items.drugMthd,
                    hndlMthd:this.items.hndlMthd,
                    rmrks:this.items.rmrks,
                    wrtr:this.items.wrtr,
                }
                // console.log(obj)
                insDrugInf(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /cmm/insDrugInf : ' + error))
            }
        },
        onSaveAfter :function(res){
            this.$emit('modalEvent', 99, res)
            this.$emit('isClose', '.')
        },
        //작업자선택
        onModal: function (key) { 
            this.menu.splice(key, 1, true)
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },   
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        field:'',
        drugInfPk:0,
        forceRender:0,
        schChkField1: [],
        menu:[false,],
        drugCode: [{valcdnm:'선택',valcd:''},{valcdnm:'처방',valcd:'1'},{valcdnm:'상비',valcd:'2'}],
        items: {drugInfPk:0,drugClcd:'',drugNm:'',drugAmt:0,drugEfct:'',drugMthd:'',rmrks:''},            
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },      
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'], 
        dialogDelete:false,
    }),
};
</script>