<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="py-7 pl-7 pr-6 midAreaColor" cols="4">
                <v-sheet class="" color="midAreaColor">
                    <p class="hamletFont text-h4 topTitleColor--text font-weight-black">프로그램 의견수렴</p>
                    <v-divider class="midAreaColor my-1"></v-divider>
                    <CmmYearQtrSelector
                        :applyDt="sltYyMm" :minDt="lmtQtr.strtYYMM" :maxDt="lmtQtr.endYYMM"
                        @cnfDt="vmlRflt">
                    </CmmYearQtrSelector>
                    <v-divider class="midAreaColor my-2"></v-divider>
                    <v-data-table
                        class="dft-hvr-tbl rounded-lg" height="584" locale="ko"
                        :headers="tblHdr" :items="prgImprvList"
                        :loading="isLoad" :loading-text="loadMsg"
                        fixed-header disable-pagination hide-default-footer dense>
                        <template v-slot:item='{ item }'>
                            <tr
                                :class="[sltImprv == item.prgImprvPk ? 'onClr' : '']"
                                @click="isKeepSlt(item.prgImprvPk)">
                                <td class="txtAlgnMid tableBB py-2 px-1">
                                    <span class="fontOneRem">{{ item.wrtDtDot }}</span>
                                    <span class="d-inline-block" style="width: 18px; height: 10px;"></span>
                                </td>
                                <td class="overflow-text-hidden txtAlgnMid tableBB py-2 px-1">
                                    <span class="fontOneRem">{{ item.wrtr }}</span>
                                    <span class="d-inline-block" style="width: 18px; height: 10px;"></span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col>
            <v-col class="py-7 pr-7 pl-6" cols="8">
                <v-sheet class="" color="">
                    <v-row class="pa-0 justify-center align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <v-icon color="black" @click="$emit('offMdl', '.')" x-large>mdi-close</v-icon>
                    </v-row>
                    <v-row class="pa-0 mb-2 mr-1 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 기본정보</span>
                    </v-row>
                    <v-divider class="mr-1" color="black"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium">프로그램 유형</span>
                        </v-col>
                        <v-col class="tableBR px-4" style="padding-top: 4px;" cols="3">
                            <v-select
                                v-model="opnnClctnInf.idxCd"
                                :items="prtCd40" :disabled="sltQtr != ''" @input="aplctSavBtn"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium">수급자 그룹</span>
                        </v-col>
                        <v-col class="px-4" style="padding-top: 4px;" cols="5">
                            <v-row class="pa-0 align-center" no-gutters>
                                <span class="d-inline-block" style="width: 220px; padding-top: 1px;">
                                    <v-text-field
                                        v-model="opnnClctnInf.bnfcrgrNm"
                                        class="" height="28" color="blue"
                                        hide-details outlined readonly dense>
                                    </v-text-field>
                                </span>
                                <v-btn
                                    class="ml-1" color="yellow002" style="padding: 0px 1px 0px 2px !important;"
                                    min-width="30" height="26" @click="onLclMdl('01', 900)"
                                    :disabled="sltQtr == '' && opnnClctnInf.idxCd == ''"
                                    dark small>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-multiple-plus</v-icon>
                                        </template>
                                        <span>수급자 그룹 선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-divider class="white my-4"></v-divider>
                    <v-row class="pa-0 mb-2 mr-1 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 의견 상세</span>
                    </v-row>
                    <v-divider class="mr-1" color="black"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 7px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                작성일
                                <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR px-4 py-1" cols="3">
                            <CmmDateComp
                                v-model="opnnClctnInf.wrtDt" :required="false"
                                :minDt="lmtQtr.strtDt" :maxDt="lmtQtr.endDt"
                                @input="aplctSavBtn">
                            </CmmDateComp>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 7px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                작성자
                                <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="4">
                            <v-row class="pa-0" no-gutters>
                                <span class="d-inline-block mr-1" style="width: 122px;">
                                    <v-text-field
                                        v-model="opnnClctnInf.wrtr" @input="aplctSavBtn"
                                        height="28" readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn
                                    class="white--text" color="brown lighten-3" style="padding: 0px 1px 0px 2px !important;"
                                    min-width="30" height="26"
                                    :disabled="/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.opnnClctnInf.wrtDt) == false"
                                    @click="onLclMdl('02', 800)" small>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원 선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 170px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv" style="line-height: 1;">
                                수급자 &#40;보호자&#41;<br>의견수렴 내용
                                <span class="d-inline-block" style="position: absolute; top: -22px; left: 40px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="10">
                            <v-textarea
                                class="rmrksArea" rows="23"
                                v-model="opnnClctnInf.imprvOpn"
                                @input="aplctSavBtn"
                                no-resize hide-details outlined dense>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-divider class="white my-2"></v-divider>
                    <v-row class="pa-0 justify-center" no-gutters>
                        <v-btn
                            v-if="opnnClctnInf.prgImprvPk != -1"
                            class="" color="grey006" min-width="110" height="36"
                            @click="dcsnDelBtn">
                            <span class="white--text fontOneDotTwoRem">삭제</span>
                        </v-btn>
                        <v-col v-if="opnnClctnInf.prgImprvPk != -1" cols="1"></v-col>
                        <v-btn
                            class="" color="blueBtnColor" min-width="110" height="36"
                            :disabled="savBtn" @click="dcsnSavBtn">
                            <span class="white--text fontOneDotTwoRem">저장</span>
                        </v-btn>
                    </v-row>
                    <v-divider class="white mt-1"></v-divider>
                </v-sheet>
            </v-col>
        </v-row>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <PrgmBnfcrGrMgmt
                v-if="lclMdlInf.typ == '01'" ref="pMSelector"
                :isMltSlt="false" :isUpd="false" :idxFltr="opnnClctnInf.idxCd"
                @outEmp="setPrgrmGrp" @isClose="rstLclMdlInf">
            </PrgmBnfcrGrMgmt>
            <EmpSelector
                v-else-if="lclMdlInf.typ == '02'"
                :prtWrtDt="opnnClctnInf.wrtDt"
                @isClose="rstLclMdlInf" @outEmp="getSltEmp">
            </EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmYearQtrSelector from '../commons/CmmYearQtrSelector';
import EmpSelector from '../commons/EmpSelector.vue';
import PrgmBnfcrGrMgmt from './PrgrmgrMultiSelector.vue';           // 프로그램 수급자 그룹관리 모달

export default {
    name: 'OpnnClctnMdl',
    
    components: {
        CmmDateComp,
        CmmYearQtrSelector,
        EmpSelector,
        PrgmBnfcrGrMgmt,
    },

    props : { 
        prtCd40: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getTmpObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                this.rmPrgImprvInf(this.opnnClctnInf.prgImprvPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
        'sltImprv': function (){
            if(this.sltImprv > -1)
                this.getPrgImprvInf(this.sltImprv)
            else
                this.clrForm()
        },
    },
        
    methods: {
        // 프로그램 의견수렴 내역 다건 조회
        getPrgImprvList: function (yyyy, qtr){
            this.sltImprv = -1
            this.isLoad = true
            this.clrForm()
            this.prgImprvList.splice(0)

            let obj = {}
            obj.inqYYYY = yyyy
            obj.inqQtr = qtr
            if(this.opnnClctnInf.prgBnfcrgrInfPk != -1)
                obj.prgBnfcrgrInfPk = this.opnnClctnInf.prgBnfcrgrInfPk
            else
                obj.prgBnfcrgrInfPk = null
            if(this.opnnClctnInf.idxCd == '')
                obj.idxCd = null
            else
                obj.idxCd = this.opnnClctnInf.idxCd
         
            http.post('prgrm/selPrgImprvList', obj)
                .then((response) => ( this.prgImprvRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvList : ' + error))
        },

        prgImprvRst: function (res) {
            if(Array.isArray(res)){
                res.forEach(itm => {
                    let chc = this.prtCd40.findIndex( v => v.valcd == itm.idxCd )
                    if(chc > -1)
                        itm.idxNm = this.prtCd40[chc].valcdnm
                    else
                        itm.idxNm = ''

                    if(itm.wrtDt == null) itm.wrtDt = ''
                    if(itm.wrtDt != '')
                        itm.wrtDtDot = this.$moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.wrtDtDot = ''
                });

                this.prgImprvList = res

                if(this.opnnClctnInf.orgPrgImprvPk != -1){
                    this.sltImprv = this.opnnClctnInf.orgPrgImprvPk
                    this.opnnClctnInf.orgPrgImprvPk = -1
                }
            }

            this.isLoad = false
            this.aplctSavBtn()
        },

        // 프로그램 의견수렴 내역 단건 조회
        getPrgImprvInf: function (pk){
            let obj = {}
            obj.prgImprvPk = pk

            http.post('prgrm/selPrgImprvInfo', obj)
                .then((response) => ( this.prgImprvDtlRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvInfo : ' + error))
        },

        prgImprvDtlRst: function (res){
            this.opnnClctnInf.prgBnfcrgrInfPk = res.prgBnfcrgrInfPk
            this.opnnClctnInf.prgImprvPk = res.prgImprvPk
            this.opnnClctnInf.bnfcrgrNm = res.bnfcrgrNm
            this.opnnClctnInf.idxCd = res.idxCd
            let chc = this.prtCd40.findIndex( v => v.valcd == res.idxCd )
            if(chc > -1)
                this.opnnClctnInf.idxNm = this.prtCd40[chc].valcdnm
            else
                this.opnnClctnInf.idxNm = ''

            this.opnnClctnInf.wrtr = res.wrtr
            if(res.wrtDt != null && res.wrtDt != '')
                this.opnnClctnInf.wrtDt = this.$moment(res.wrtDt, 'YYYYMMDD').format('YYYY-MM-DD')
            if(res.wrtrMmbrPk != null)
                this.opnnClctnInf.wrtrMmbrPk = res.wrtrMmbrPk

            this.opnnClctnInf.imprvOpn = res.imprvOpn
            if(res.rflDt == null) res.rflDt = ''
            this.opnnClctnInf.rflDt = res.rflDt

            this.aplctSavBtn()
        },

        // 프로그램 의견수렴 단건 저장
        setPrgImprvInf: function (obj){
            http.post('prgrm/insPrgImprv', obj)
                .then((response) => ( this.prgImprvSavRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/insPrgImprv : ' + error))
        },

        // 프로그램 의견수렴 단건 삭제
        rmPrgImprvInf: function (pk){
            let obj = {}
            obj.prgImprvPk = pk

            http.post('prgrm/delPrgImprv', obj)
                .then((response) => ( this.prgImprvSavRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/delPrgImprv : ' + error))
        },

        prgImprvSavRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                let tmp = this.$moment(this.opnnClctnInf.wrtDt, 'YYYY-MM-DD')
                let tmpQtr = tmp.quarter()
                let trg = tmp.quarter(tmpQtr).startOf('quarter').format('YYYY-MM')
                
                if(this.sltYyMm == trg)
                    this.getPrgImprvList(tmp.quarter(tmpQtr).startOf('quarter').format('YYYY'), tmpQtr)
                else
                    this.sltYyMm = trg
            }
            else{
                obj.type = 'warning'
                obj.tmr = 3500
            }

            this.$store.commit('setWrnnInf', obj)

            this.aplctSavBtn()
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.sltYyMm = String(obj.trgYyyy)
            this.sltQtr = obj.trgQtr

            if(obj.trgQtr == ''){
                this.sltYyMm = String(obj.trgYyyy) + '-01'
                this.lmtQtr.strtYYMM = obj.trgYyyy + '-01'
                this.lmtQtr.endYYMM = obj.trgYyyy + '-12'
                this.lmtQtr.strtDt = obj.trgYyyy + '-01-01'
                this.lmtQtr.endDt = obj.trgYyyy + '-12-31'
            }
            else{
                let trgDt = this.$moment(this.sltYyMm, 'YYYY').quarter(obj.trgQtr)
                this.sltYyMm = trgDt.startOf('day').format('YYYY-MM')
                this.lmtQtr.strtYYMM = trgDt.startOf('quarter').format('YYYY-MM')
                this.lmtQtr.endYYMM = trgDt.endOf('quarter').format('YYYY-MM')
                this.lmtQtr.strtDt = trgDt.startOf('quarter').format('YYYY-MM-DD')
                this.lmtQtr.endDt = trgDt.endOf('quarter').format('YYYY-MM-DD')
            }

            this.opnnClctnInf.prgBnfcrgrInfPk = obj.prgBnfcrgrInfPk
            this.opnnClctnInf.bnfcrgrNm = obj.bnfcrgrNm
            this.opnnClctnInf.idxCd = obj.idxCd
            this.opnnClctnInf.idxNm = obj.idxNm
            this.opnnClctnInf.orgPrgImprvPk = obj.prgImprvPk
            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
  
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.opnnClctnInf.wrtDt) == false)
                return 0
            if(this.opnnClctnInf.wrtr == '')
                return 0
            if(this.opnnClctnInf.imprvOpn == '')
                return 0
            if(this.opnnClctnInf.bnfcrgrNm == '')
                return 0
            if(this.opnnClctnInf.idxCd == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                         // 경고 노출여부
            obj.tmr = 2500                          // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                       // 경고 유형 (success, info, warning, error)
            obj.title = '작성일을 다시 확인해주세요.'   // 경고 타이틀
            obj.cntnt = []                          // 경고 내용 (arr만 출력됨!)

            let trgDt = this.$moment(this.opnnClctnInf.wrtDt, 'YYYY-MM-DD')
            if(!trgDt.isSameOrAfter(this.lmtQtr.strtDt) || !trgDt.isSameOrBefore(this.lmtQtr.endDt))
                this.$store.commit('setWrnnInf', obj)

            let oObj = Object.assign({}, this.opnnClctnInf)
            if(oObj.prgBnfcrgrInfPk == -1)
                oObj.prgBnfcrgrInfPk = null
            if(oObj.prgImprvPk == -1)
                oObj.prgImprvPk = null
            if(oObj.wrtrMmbrPk == -1)
                oObj.wrtrMmbrPk = null

            oObj.wrtYr = this.$moment(this.sltYyMm, 'YYYY-MM').format('YYYY')
            if(this.sltQtr == '')
                oObj.wrtQrtr = this.$moment(oObj.wrtDt, 'YYYY-MM-DD').quarter()
            else
                oObj.wrtQrtr = this.sltQtr
            oObj.wrtDt = this.$moment(oObj.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD')
            oObj.rflDt = null

            this.setPrgImprvInf(oObj)
        },

        // 삭제버튼 분기
        dcsnDelBtn: function () {
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '작성일: ' + this.$moment(this.opnnClctnInf.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
            obj.cntnt = []
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = ''
 
            if(this.opnnClctnInf.rflDt == ''){
                obj.cntnt.push('프로그램 의견수렴 내역을 삭제하시겠습니까?')
                obj.cnfrmTxt = '예, 삭제하겠습니다.'
            }
            else{
                obj.cntnt.push('의견수렴 반영정보가 존재합니다.')
                obj.cntnt.push('의견수렴 내역을 삭제하면 반영정보도 함께 삭제됩니다.')
                obj.cntnt.push('의견수렴 내역과 반영 내역을 모두 삭제할까요?')
                obj.cnfrmTxt = '예, 모두 삭제하겠습니다.'
            }

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },

        isKeepSlt: function (pk) {
            if(this.sltImprv != pk)
                this.sltImprv = pk
            else
                this.sltImprv = -1
        },

        // 분기 변경 이벤트
        vmlRflt: function (obj) {
            let trg = this.$moment(obj, 'YYYY-MM')
       
            this.getPrgImprvList(trg.format('YYYY'), trg.quarter())
        },

        // 그룹 변경 이벤트
        setPrgrmGrp: function(arr){
            if(arr.length == 1){
                this.opnnClctnInf.prgBnfcrgrInfPk = arr[0].prgBnfcrgrInfPk
                this.opnnClctnInf.bnfcrgrNm = arr[0].bnfcrgrNm
            }

            this.aplctSavBtn()
        },

        // 직원 선택 모달 이벤트
        getSltEmp: function (obj) {
            this.opnnClctnInf.wrtr = obj[0].name
            this.opnnClctnInf.wrtrMmbrPk = obj[0].empPk

            this.aplctSavBtn()
        },

        // 초기화
        clrForm: function () {
            if(this.sltQtr == ''){
                this.opnnClctnInf.prgBnfcrgrInfPk = -1
                this.opnnClctnInf.bnfcrgrNm = ''
                this.opnnClctnInf.idxCd = ''
                this.opnnClctnInf.idxNm = ''
            }

            this.opnnClctnInf.wrtrMmbrPk = -1
            this.opnnClctnInf.wrtDt = ''
            this.opnnClctnInf.wrtr = ''
            this.opnnClctnInf.imprvOpn = ''
            this.opnnClctnInf.rflDt = ''
            this.opnnClctnInf.prgImprvPk = -1

            this.aplctSavBtn()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        runAble: '0',
        sltYyMm: '',
        sltQtr: '',
        sltImprv: -1,
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        prgImprvList: [],
        lmtQtr: { strtYYMM: '', endYYMM: '', strtDt: '', endDt: '', },
        opnnClctnInf : {
            prgBnfcrgrInfPk: -1, bnfcrgrNm: '', idxCd: '', idxNm: '', wrtrMmbrPk: -1, wrtDt: '', wrtr: '',
            imprvOpn: '', rflDt: '', prgImprvPk: -1, orgPrgImprvPk: -1,
        },
        tblHdr: [
            { text: '작성일', value: 'wrtDt', sortable: true, width: '140', class: 'black--text bottomBorder py-3', align: 'center', },
            { text: '작성자', value: 'wrtr', sortable: true, width: '140', class: 'black--text bottomBorder', align: 'center', },
        ],
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },     // 통합 팝업 제어 (01: 프로그램 수급자 그룹 관리)
    }),
};
</script>