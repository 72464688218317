<template>
    <v-sheet class="pa-0">
        <v-row class="align-center" no-gutters>
            <v-col cols='4'>
                <v-row class="pa-0 align-center" no-gutters>
                    <CmmExpnBtn
                        v-model="isJobStt"
                        :btnNm="'직종'" vTyp="2" :bIcon="'mdi-account-tie-woman'"
                        :iList="vCd42" @onList="dmyTxt = String(Number(dmyTxt)+1)"
                        class="">
                    </CmmExpnBtn>
                    <CmmExpnBtn
                        v-model="isVctnStt"
                        :btnNm="'휴가종류'" vTyp="2" :bIcon="'mdi-airplane'"
                        :iList="vCd107" @onList="dmyTxt = String(Number(dmyTxt)+1)"
                        class="ml-2">
                    </CmmExpnBtn>
                    <span class="d-inline-block ml-2" style="width: 106px; height: 34px;">
                        <v-text-field
                            v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                            class="txt-Theme" height="34" label="이름조회"
                            background-color="white" prepend-inner-icon="mdi-magnify"
                            single-line outlined dense filled hide-details>
                        </v-text-field>
                    </span>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <span class="d-inline-block mt-1 mr-2" style="width: 97px; height: 32px;">                                            
                <v-select
                    v-model="srchCd" :items="selectItems"
                    item-text="valcdnm" item-value="valcd"
                    @change="selectedDate()"
                    dense hide-details outlined>
                </v-select>
            </span>
            <expandNowDatePicker v-if="srchCd == 0" @nowDate="getDate"></expandNowDatePicker>
            <expandMonthPicker v-else-if="srchCd == 1" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
            <expandYearOnlyPicker v-else-if="srchCd == 2" @nowYear="getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-col cols='4'></v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-data-table
            class="greyE00-hdr-tbl" height="652" locale="ko"
            :headers="headers" :items="empList" item-key="rn"
            :loading="isLoad" :loading-text="loadMsg"
            :search="dmyTxt" :custom-filter="srchFltr"
            fixed-header disable-pagination hide-default-header hide-default-footer dense>
            <template v-slot:header="{ props }" >
                <thead>
                    <tr>
                        <th v-for="(hdr, l) in props.headers" :key="l">
                            <span>{{ hdr.text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item, index }'>
                <tr>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ index + 1 }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.wrkDtNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.vctnTypNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.name }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.jobNm }}</span>
                    </td>
                    <td class="txtAlgnMid px-1 py-2">
                        <span class="fontOneRem">{{ item.rmrks }}</span>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
import { selBtcInqVctnUseList } from '../../api/bnfcrStts';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import CmmExpnBtn from '../../components/commons/CmmExpnBtn';

export default {
    name: 'EmpInfSttsTab3',

    props: {
        tab: { type: Number, default: 0 },
        prtCd42: { type: Array, default: () => { return [] } },     // 직종
        prtCd107: { type: Array, default: () => { return [] } },    // 휴가
    },
    components: {
        CmmExpnBtn,
        expandNowDatePicker,
        expandYearOnlyPicker,
        expandMonthPicker,
    },

    computed: {
    },

    watch: {
        prtCd42: {
            deep: true,
            handler: function (value){
                let arr = [...value]
                if(arr.length > 0)
                    arr.forEach(itm => {
                        itm.isOn = true
                    });
               this.vCd42 = arr
            },
        },
        prtCd107: {
            deep: true,
            handler: function (value){
                let arr = [...value]
                if(arr.length > 0)
                    arr.forEach(itm => {
                        itm.isOn = true
                    });
               this.vCd107 = arr
            },
        },
        'date': function(value){
            let obj = {
                inqYMD: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqVctnUseList(obj)
        },
        'mDate': function(value){
            let obj = {
                inqYYMM: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqVctnUseList(obj)
        },
        'yDate': function(value){
            let obj = {
                inqYYYY: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqVctnUseList(obj)
        },
    },

    created: function () {
      
    },

    mounted: function () {
        this.$nextTick(function () {
        })
    },

    methods: {
        xlsxProd: function (){
            let shetNm = '휴가사용내역'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.empList, shetNm, title)
            this.exlBtn = true
        },
        selBtcInqVctnUseList: function (obj) {
            this.$parent.$parent.$parent.$parent.exlBtn = true
            this.$parent.$parent.$parent.$parent.pdfBtn = true

            this.empList.splice(0)

            selBtcInqVctnUseList(obj)
                .then((response) => ( this.selBtcInqVctnUseListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkSchdHrlList : ' + error))
        },
        selBtcInqVctnUseListAfter: function (res) {
            if(Array.isArray(res)){
                res.forEach(itm => {
                    if(itm.rmrks == null) itm.rmrks = ''
                    if(itm.jobNm == null) itm.jobNm = ''
                    if(itm.entDt == null) itm.entDt = ''
                    if(itm.rtrDt == null) itm.rtrDt = ''
                    if(itm.vctnTypNm == null) itm.vctnTypNm = ''
                    if(itm.wrkDt != null)
                        itm.wrkDtNm = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.wrkDtNm = ''
                    //itm.isSelected = false
                });

                this.empList = res
                if(res.length > 0){
                    this.$parent.$parent.$parent.$parent.exlBtn = false
                    this.$parent.$parent.$parent.$parent.pdfBtn = false
                }
            }
        
            this.isLoad = false
        },
        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 휴가 검색
            let vctnRunChc = this.vCd107.filter( v => v.isOn == true )
            if(vctnRunChc.length == 0)
                field2 = false
            else{
                vctnRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.vctnTypNm == itm.valcdnm
                    else field2 = field2 || filter.vctnTypNm == itm.valcdnm
                });
            }

            // 직종 검색
            let jobRunChc = this.vCd42.filter( v => v.isOn == true )
            if(jobRunChc.length == 0)
                field3 = false
            else{
                jobRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.jobNm == itm.valcdnm
                    else field3 = field3 || filter.jobNm == itm.valcdnm
                });
            }

            return field1 && field2 && field3
        },
        getDate: function (obj) {
            this.date = obj.date
            this.inqYmd = obj.date
        },
        getPickerDate: function (obj) {
            this.mDate = obj
            this.inqYmd = obj
        },
        getYear: function (obj) {
            this.yDate = obj
        },
        selectedDate: function() {
            if(this.srchCd==0){
                let obj = {
                    inqYMD: this.date,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqVctnUseList(obj)
            } else if (this.srchCd==1){
                let obj = {
                    inqYYMM: this.mDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqVctnUseList(obj)
            } else {
                let obj = {
                    inqYYYY: this.yDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqVctnUseList(obj)
            }
        },  
    },
    data: () => ({
        isJobStt: false,
        isVctnStt: false,
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        dmyTxt: '',                         // 테이블 반응 더미(검색용)
        srchTxt: '',                        // 테이블 이름 검색 필드
        vCd42: [],                          //직원 직종 코드
        vCd107: [],                         //직원 휴가 코드
        empList: [],
        srchCd: 0,
        date: 19900101,
        mDate: 199001,
        yDate: 1990,
        inqYmd: 199001,
        headers: [
            { text: '연번', value: 'rn', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '휴가사용일', value: 'wrkDtNm', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '구분', value: 'vctnTypNm', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '직원명', value: 'name', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '담당직종', value: 'jobCd', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '비고', value: 'rmrks', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBT' },
        ],
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '휴가사용일', key: 'wrkDtNm', width: 12, },
            { header: '구분', key: 'vctnTypNm', width: 40, },
            { header: '직원명', key: 'name', width: 12, },
            { header: '담당직종', key: 'jobNm', width: 16, },
            { header: '비고', key: 'rmrks', width: 30, },
        ],
        selectItems: [{valcdnm: '일별',valcd: 0}, {valcdnm: '월별',valcd: 1},{valcdnm: '연별',valcd: 2}, ],
    }),
};
</script>