<template>
    <v-sheet class="pa-0 ma-0">
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">            
            <v-data-table
                class="greyE00-hdr-tbl" height="700" locale="ko"
                :headers="(!oldYn)?headers:headersold" 
                :items="doseRecList" 
                :loading="doseRecLoader"            
                fixed-header disable-pagination hide-default-footer dense>
                <template v-slot:header="props" >
                    <thead>
                        <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="fixedTable fixPstn0 zPstn2 tableBR tableBB clmsBodyCommon py-2">
                            <span class="cmmFontSizeOne">{{ $moment(item.doseDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                        </td>
                        <td class="fixedTable fixPstn9 zPstn2 tableBR tableBB clmsBodyCommon overflow-text-hidden px-2">
                            <span class="cmmFontSizeOne">{{ item.drugNm }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.drugAmt }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon overflow-text-hidden">
                            <span class="cmmFontSizeOne">{{ item.drugMthd }}</span>
                        </td>
                        <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.brkfsTmHh }}:{{ item.brkfsTmMm }}</span>
                            <span v-if="item.brkfs != null && item.brkfs != ''" class="cmmFontSizeOne">&#40;{{ item.brkfs }}&#41;</span>
                        </td>
                        <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.lunchTmHh }}:{{ item.lunchTmMm }}</span>
                            <span v-if="item.lunch != null && item.lunch != ''" class="cmmFontSizeOne">&#40;{{ item.lunch }}&#41;</span>
                        </td>
                        <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.dinerTmHh }}:{{ item.dinerTmMm }}</span>
                            <span v-if="item.diner != null && item.diner != ''" class="cmmFontSizeOne">&#40;{{ item.diner }}&#41;</span>
                        </td>
                        <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.bedTmHh }}:{{ item.bedTmMm }}</span>
                            <span v-if="item.bed != null && item.bed != ''" class="cmmFontSizeOne">&#40;{{ item.bed }}&#41;</span>
                        </td>
                        <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.needTmHh }}:{{ item.needTmMm }}</span>
                            <span v-if="item.need != null && item.need != ''" class="cmmFontSizeOne">&#40;{{ item.need }}&#41;</span>
                        </td>
                        <td v-if="!oldYn" class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.rmnDoseAmt }}</span>
                        </td>
                        <td v-if="!oldYn" class="txtAlgnMid tableBB clmsBodyCommon">
                            <span class="cmmFontSizeOne">{{ item.adjstRsn }}</span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'NsgRcrdSttsTab3',

    props: {
        
    },

    components: {
 
    },

    
    created: function(){
        
    },
    
    mounted: function () {
        this.$nextTick(function () {
            
        })
    },
    
    computed: {
        getBnMmbrPk: function () {
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getSltYYMM: function () {
            return this.$parent.$parent.$parent.$parent.sltYymm
        },
    },
    
    watch: {
        getBnMmbrPk: function ( v ) {
            if(v > 0)
                this.getBatDoseRecList()
            else
                this.doseRecList.splice(0)
        },
        getSltYYMM: function (yymm) {
            this.oldYn =  this.$moment(yymm+'01' ,'YYYYMMDD').isBefore('2025-01-01')
            
            if(yymm != '' && this.$store.getters.getBnfcrState.bnmmbr > 0)
                this.getBatDoseRecList()
        },
        'doseRecList': function () {
            this.$parent.$parent.$parent.$parent.totCnt.splice(2, 1, this.doseRecList.length)
        },
    },

    methods: {
        getBatDoseRecList: function () {
            this.doseRecList.splice(0)
            this.doseRecLoader = true

            let obj = {}
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.inqYmdStart = this.$moment(this.$parent.$parent.$parent.$parent.sltYymm, 'YYYYMM').startOf('months').format('YYYYMMDD')
            obj.inqYmdEnd = this.$moment(this.$parent.$parent.$parent.$parent.sltYymm, 'YYYYMM').endOf('months').format('YYYYMMDD')
            
            http.post('bnftrcrd/selBatDoseRecList', obj)
                .then((response) => ( this.batDoseRecListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selBatDoseRecList : ' + error))
        },
        batDoseRecListRst: function(res){
            this.doseRecLoader = false

            if(res.statusCode == '200'){      
                if(res.result.list != null && res.result.list != undefined) {
                    this.doseRecList = res.result.list

                    this.doseRecList.forEach(el => {
                        let wrtrGr  = []
                        let brkfs   = ''
                        let lunch   = ''
                        let diner   = ''
                        let bed     = ''
                        let need    = ''

                        if(el.wrtrGr != null){
                            wrtrGr = el.wrtrGr

                            brkfs   = (wrtrGr[0] != null && wrtrGr[0] != '')?wrtrGr[0]:wrtrGr[1]
                            lunch   = (wrtrGr[2] != null && wrtrGr[2] != '')?wrtrGr[2]:wrtrGr[3]
                            diner   = (wrtrGr[4] != null && wrtrGr[4] != '')?wrtrGr[4]:wrtrGr[5]
                            bed     = (wrtrGr[6] != null && wrtrGr[6] != '')?wrtrGr[6]:''
                            need    = (wrtrGr[7] != null && wrtrGr[7] != '')?wrtrGr[7]:''
                        }

                        el.brkfs    = brkfs
                        el.lunch    = lunch
                        el.diner    = diner
                        el.bed      = bed
                        el.need     = need

                    });
                }
            }
            else {
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
    },
    data: () => ({
        page            : 1,
        pageCount       : 0,
        itemsRows       : 15,
        doseRecLoader   : false,
        doseRecList     : [],
        oldYn           : false,
        headers: [
            { text: '투약일자', value: 'doseDt', align: 'center', width: '100',  sortable: false, class: 'black--text py-4 fixPstn0 greyE01 zPstn3 fontOneRem',},
            { text: '투약명', value: 'drugNm', align: 'center', width: '150',  sortable: false, class: 'black--text fixPstn9 greyE01 zPstn3 fontOneRem', },
            { text: '투약량', value: 'drugAmt', align: 'center', width: '80',  sortable: false, class: 'black--text greyE01 fontOneRem',},
            { text: '투약방법', value: 'drugMthd', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem',},
            { text: '아침', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '점심', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '저녁', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '취침전', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '필요시', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '잔여량', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '비고', value: '', align: 'center', width: '200',  sortable: false, class: 'black--text greyE01 fontOneRem', },
        ],
        headersold: [
            { text: '투약일자', value: 'doseDt', align: 'center', width: '100',  sortable: false, class: 'black--text py-4 fixPstn0 greyE01 zPstn3 fontOneRem',},
            { text: '투약명', value: 'drugNm', align: 'center', width: '150',  sortable: false, class: 'black--text fixPstn9 greyE01 zPstn3 fontOneRem', },
            { text: '투약량', value: 'drugAmt', align: 'center', width: '80',  sortable: false, class: 'black--text greyE01 fontOneRem',},
            { text: '투약방법', value: 'drugMthd', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem',},
            { text: '아침', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '점심', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '저녁', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '취침전', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
            { text: '필요시', value: '', align: 'center', width: '110',  sortable: false, class: 'black--text greyE01 fontOneRem', },
        ],
    }),
};
</script>