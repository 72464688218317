<template>
    <v-menu
        v-model="cld" :close-on-content-click="false" :disabled="disabled"
        transition="scale-transition" offset-y min-width="auto" dense>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="btnTyp == ''"
                class="ml-1 pa-0" color="light-green lighten-3" style="margin-bottom: 1px;"
                v-bind="attrs" v-on="on" min-width="30" height="25" dark small>
                <v-icon color="white" size="18" v-bind="attrs" v-on="on">mdi-calendar-blank</v-icon>
            </v-btn>
            <v-btn
                v-else-if="btnTyp == 'left'"
                class="pa-0 ml-1" style="min-width: 32px !important; background-color: white;"
                v-bind="attrs" v-on="on" color="btnBrdrColor" height="26" small outlined>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="black--text mt-0" small>mdi-calendar-start-outline</v-icon>
                    </template>
                    <span>시작연월</span>
                </v-tooltip>
            </v-btn>
            <v-btn
                v-else-if="btnTyp == 'right'"
                class="pa-0 ml-1" style="min-width: 32px !important; background-color: white;"
                v-bind="attrs" v-on="on" color="btnBrdrColor" height="26" small outlined>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="black--text mt-0" small>mdi-calendar-end-outline</v-icon>
                    </template>
                    <span>종료연월</span>
                </v-tooltip>
            </v-btn>
        </template>
        <v-date-picker
            v-model="sltDt"
            class="cmmDtPckr" locale="en-US"
            :weekday-format="getDay" :month-format="getMonth" :header-date-format="getTitle"
            :min="minDt" :max="maxDt" :type="pckType" :key="forceRender"
            @click:date="dtEvt('dt')" @click:month="dtEvt('mnt')"
            no-title scrollable>
        </v-date-picker>
    </v-menu>
</template>

<script>

export default {
    name: 'CmmDatePicker',

    props : {
        value: { type: String, default: '' },
        minDt: { type: String, default: '' },
        maxDt: { type: String, default: '' },
        pckType: { type: String, default: 'date' },
        disabled: { type: Boolean, default: false },
        btnTyp: { type: String, default: '' },
    },
            
    components: {

    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.forceRender += 1

            if(this.value == null)
                this.sltDt = ''
            else
                this.sltDt = this.value
        })
    },

    computed: {

    },

    watch:{
        'value': function () {
            if(this.value == null)
                this.sltDt = ''
            else
                this.sltDt = this.value
        },
    },
        
    methods: {
        getTitle: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)

            if(date.length == 4)
                return new Date(date).getFullYear() + '년'
            else
                return new Date(date).getFullYear() + '년 ' + monthName[i]
        },
        getMonth: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)
            return monthName[i]
        },
        getDay: function (date) {
            const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        thrDt: function () {
            this.$emit('cnfDt', this.$store.state.glblTmprDt)
        },
        dtEvt: function (v) {
            if(this.pckType == 'date' && v == 'dt')
                this.nptEvt()
            else if(this.pckType == 'month' && v == 'mnt')
                this.nptEvt()
        },
        nptEvt: function () {
            this.cld = false
            this.$emit('cnfDt', this.sltDt)
            this.forceRender += 1
        },
    },
    
    data: () => ({
        cld: false,
        sltDt: '',
        forceRender: 0,
    }),
};
</script>

<style>
    .cmmDtPckr .v-picker__body div .v-date-picker-table { height: initial; padding-bottom: 16px; }
    .cmmDtPckr .v-picker__body div .v-date-picker-table table thead tr th {font-size: 14px; }
    .cmmDtPckr .v-picker__body div .v-date-picker-table table thead tr th:nth-child(1) { color: #ff7451; }
    .cmmDtPckr .v-picker__body div .v-date-picker-table table thead tr th:last-child { color: #6396e4; }
    .cmmDtPckr .v-picker__body div .v-date-picker-table table tbody tr td button .v-btn__content { font-size: 13px; }
    /* .cmmDtPckr .v-picker__body div .v-date-picker-table table tbody tr td:nth-child(1) button div { color: #ff7451; } */
</style>