<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">재활상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 11px;" cols="2">
                        <span class="font-weight-medium">운동장애</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_1" :ripple="false"
                                true-value="1" false-value="2" label="우측상지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_2" :ripple="false"
                                true-value="1" false-value="2" label="좌측상지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_3" :ripple="false"
                                true-value="1" false-value="2" label="우측하지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_4" :ripple="false"
                                true-value="1" false-value="2" label="좌측하지"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 38px;" cols="2">
                        <span class="font-weight-medium">관절구축</span>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center mb-1" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_1" :ripple="false"
                                true-value="1" false-value="2" label="어깨관절"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_2" :ripple="false"
                                true-value="1" false-value="2" label="좌"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_3" :ripple="false"
                                true-value="1" false-value="2" label="우"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1 mr-4" style="font-size: 0.98rem;">&#41;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_4" :ripple="false"
                                true-value="1" false-value="2" label="팔꿈치관절"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_5" :ripple="false"
                                true-value="1" false-value="2" label="좌"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_6" :ripple="false"
                                true-value="1" false-value="2" label="우"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-row class="pa-0 align-center mb-1" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_7" :ripple="false"
                                true-value="1" false-value="2" label="손목 및 수지관절"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_8" :ripple="false"
                                true-value="1" false-value="2" label="좌"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_9" :ripple="false"
                                true-value="1" false-value="2" label="우"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1 mr-4" style="font-size: 0.98rem;">&#41;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_10" :ripple="false"
                                true-value="1" false-value="2" label="고관절"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_11" :ripple="false"
                                true-value="1" false-value="2" label="좌"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_12" :ripple="false"
                                true-value="1" false-value="2" label="우"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_13" :ripple="false"
                                true-value="1" false-value="2" label="무릎관절"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_14" :ripple="false"
                                true-value="1" false-value="2" label="좌"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_15" :ripple="false"
                                true-value="1" false-value="2" label="우"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1 mr-4" style="font-size: 0.98rem;">&#41;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_16" :ripple="false"
                                true-value="1" false-value="2" label="발목관절"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_17" :ripple="false"
                                true-value="1" false-value="2" label="좌"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_18" :ripple="false"
                                true-value="1" false-value="2" label="우"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 38px;" cols="2">
                        <span class="font-weight-medium">보행장애</span>
                    </v-col>
                    <v-col class="py-1" cols="10">
                        <v-row class="px-2 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_1" :ripple="false" @change="dftEvt()"
                                true-value="1" false-value="2" label="지난 3개월 간 낙상경험"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-radio-group
                                v-model="inpRadio.val_3_2"
                                class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="매일" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="주 1회이상" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="월 1회이상" value="3">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="가끔" value="4">
                                </v-radio>
                            </v-radio-group>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-divider class="my-1"></v-divider>
                        <v-row class="px-2 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_3" :ripple="false"
                                true-value="1" false-value="2" label="걸음걸이 및 균형"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_4" :ripple="false"
                                true-value="1" false-value="2" label="서거나 걸을 때 균형을 유지하지 못함"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_5" :ripple="false"
                                true-value="1" false-value="2" label="일어서거나 걸을 때 어지러움"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_6" :ripple="false"
                                true-value="1" false-value="2" label="보조도구나 부축해서 걷기"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 4px; padding-bottom: 3px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="22" 
                        v-model="inpTxt.val_4_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'RhblState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                if(itm.depth3 == '4' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '3' && itm.valCd == '2')
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                else
                    this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oTxt = {}
                oTxt.examPk = 0
                oTxt.depth2 = '8'
                oTxt.depth3 = '4'
                oTxt.valCd = '1'
                oTxt.val = ''
                oTxt.val2 = this.inpTxt.val_4_1
                finArr.push(oTxt)

                let oRdo = {}
                oRdo.examPk = 0
                oRdo.depth2 = '8'
                oRdo.depth3 = '3'
                oRdo.valCd = '2'
                oRdo.val = this.inpRadio.val_3_2
                oRdo.val2 = ''
                finArr.push(oRdo)

                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '8'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '4'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '3' && oObj.valCd == '2')
                        oObj.val = this.inpRadio.val_3_2

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        dftEvt: function (){
            if(this.inpCheck.val_3_1 == '2')
                this.inpRadio.val_3_2 = '0'
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 기타, 판단근거
        inpTxt: { val_4_1: '' },
        // 보행장애 (d3: 3) - cd2: 지난 3개월간 낙상 경험 ( 매일(1), 주1회이상(2), 월1회이상(3), 가끔(4) )
        inpRadio: { val_3_2: '' },
        // 운동장애 (d3: 1) - cd1: 우측상지, cd2: 좌측상지, cd3: 우측하지, cd4: 좌측하지
        // 관절구축 (d3: 2) - cd1: 어깨관절, cd2: 어깨관절(좌), cd3: 어깨관절(우), cd4: 팔꿈치관절, cd5: 팔꿈치관절(좌), cd6: 팔꿈치관절(우)
        //                   cd7: 손목 및 수지관절, cd8: 손목 및 수지관절(좌), cd9: 손목 및 수지관절(우), cd10: 고관절
        //                   cd11: 고관절(좌), cd12: 고관절(우), cd13: 무릎관절, cd14: 무릎관절(좌), cd15: 무릎관절(우)
        //                   cd16: 발목관절, cd17: 발목관절(좌), cd18: 발목관절(우)
        // 보행장애 (d3: 3) - cd1: 지난 3개월간 낙상 경험, cd3: 걸음걸이 및 균형, cd4: 서거나 걸을때 균형을 유지하지 못함
        //                   cd5: 일어서거나 걸을때 어지러움, cd6: 보조도구나 부축해서 걷기
        inpCheck: {
            val_1_1: '2', val_1_2: '2', val_1_3: '2', val_1_4: '2',
            val_2_1: '2', val_2_2: '2', val_2_3: '2', val_2_4: '2', val_2_5: '2', val_2_6: '2', val_2_7: '2',
            val_2_8: '2', val_2_9: '2', val_2_10: '2', val_2_11: '2', val_2_12: '2', val_2_13: '2', val_2_14: '2',
            val_2_15: '2', val_2_16: '2', val_2_17: '2', val_2_18: '2',
            val_3_1: '2', val_3_3: '2', val_3_4: '2', val_3_5: '2', val_3_6: '2',
        },
    }),
};
</script>