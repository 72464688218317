<template>
    <v-sheet class="rounded-lg rounded-l-0 posn-rltv px-3" min-width="1260" :max-width="viewWdth" height="835">
        <v-divider class="white my-1"></v-divider>
        <v-row class="pa-0 align-center" no-gutters>
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">9-3. 본인부담금</span>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text mx-1" height="28" color="grey006"
                :disabled="exlBtn" @click="dvsnExl"
                samll rounded outlined>
                <v-icon small>mdi-microsoft-excel</v-icon>
                <span>엑셀</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme white--text mx-1" height="28" color="grey006"
                :disabled="pdfBtn" @click="dvsnPrt"
                samll rounded outlined>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>출력</span>
            </v-btn>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-tabs
            v-model="tab"
            class="tab-dft-Theme mx-2" active-class="black"
            center-active hide-slider>
            <v-tab
                v-for="item in tabItems" :key="item.tab"
                :class="item.class" :disabled="item.enter">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-divider class="bodyColor mx-2" style="padding: 1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-divider class="white mt-3"></v-divider>
            <v-row class="pa-0 mx-2 align-center" no-gutters>
                <v-col class="pr-5" cols="4">
                    <ExpRngMonthPicker
                        v-if="tab != 2"
                        :strtValue="$moment().format('YYYY-MM')"
                        :endValue="$moment().format('YYYY-MM')"
                        :disabled="isLoad"
                        @chngDtRng="getPckrMonth">
                    </ExpRngMonthPicker>
                    <ExpRngDayPicker
                        v-else-if="tab == 2"
                        :strtValue="$moment().startOf('month').format('YYYY-MM-DD')"
                        :endValue="$moment().endOf('month').format('YYYY-MM-DD')"
                        :disabled="isLoad"
                        @chngDtRng="getPckrDt">
                    </ExpRngDayPicker>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="pr-1" cols="1">
                    <v-select
                        v-if="tab != 2"
                        v-model="srchObj.cpyToClms"
                        :items="srchCpyTyp"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                    <v-select
                        v-else-if="tab == 2"
                        v-model="srchObj.cpyToClms"
                        :items="srchPrcTyp"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </v-col>
                <div
                    v-if="srchObj.cpyToClms == '2'"
                    class="d-flex align-center px-1">
                    <span class="d-inline-block" style="width: 136px;">
                        <v-text-field
                            v-model="srchObj.bnfcrNm"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn
                        class="white--text ml-1" style="padding: 0px 1px 0px 2px !important; margin-bottom: 2px;"
                        color="#ffab91" min-width="30" height="28" elevation="0" @click="onLclMdl('01', 800)" small>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>수급자 선택</span>
                        </v-tooltip>
                    </v-btn>
                </div>
            </v-row>
            <v-divider class="mt-2 mx-2"></v-divider>
            <v-tab-item transition="false" eager>
                <v-data-table
                    v-if="tab == 0"
                    class="greyE00-hdr-tbl mx-2" height="656" locale="ko"
                    :headers="tblHdr" :items="batCpyClmList" item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"
                    fixed-header disable-pagination hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th
                                    v-for="(hdr, i) in props.headers" :key="i"
                                    :class="hdr.class" :style="hdr.style"
                                    colspan="1" rowspan='1'>
                                    <span>{{ hdr.text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="onFixd fixPstn0 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.rn }}</span>
                            </td>
                            <td class="onFixd fixPstn10 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.clmYymm }}</span>
                            </td>
                            <td class="onFixd fixPstn13 overflow-text-hidden txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="onFixd fixPstn14 txtAlgnMid tableBR px-1 py-2">
                                <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="onFixd fixPstn15 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.copayRateNm }}</span>
                            </td>
                            <td class="onFixd fixPstn16 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.ofrdys }}일</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.copay?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.mealCost?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.upBedFee?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.lqdFood?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.snackFee?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.btyFee?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.clncPhrmcFee?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.cntrcDctrFee?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.msclnFee?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.prpymApl?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.unpdAmt?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnMid px-1 py-2">
                                <span class="fontOneRem">{{ item.clmTot?.toLocaleString('ko-KR') }}원</span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]="{  }">
                        <tr class="allHdrFix" style="bottom: 0;">
                            <td
                                class="onFixd fixPstn0 txtAlgnMid tableBR px-1 py-2"
                                style="background-color: #ededed !important;"
                                colspan="6">
                                <span class="fontOneRem font-weight-medium">합계</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.copay?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.mealCost?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.upBedFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.lqdFood?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.snackFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.btyFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.clncPhrmcFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.cntrcDctrFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.msclnFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.prpymApl?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.unpdAmt?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR px-1 py-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmTot.clmTot?.toLocaleString('ko-KR') }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item transition="false" eager>
                <v-data-table
                    v-if="tab == 1"
                    class="greyE00-hdr-tbl mx-2" height="656" locale="ko"
                    :headers="tblHdr" :items="batCpyClmDpsList" item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"
                    fixed-header disable-pagination hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th
                                    v-for="(hdr, k) in 5" :key="k"
                                    :class="props.headers[hdr-1].class" :style="props.headers[hdr-1].style"
                                    colspan="1" rowspan='2'>
                                    <span>{{ props.headers[hdr-1].text }}</span>
                                </th>
                                <th
                                    v-for="(hdr, i) in tblDpsHdr" :key="i+5"
                                    :class="hdr.class" :style="hdr.style"
                                    colspan="1" rowspan='2'>
                                    <span>{{ hdr.text }}</span>
                                </th>
                                <th style="width: 880px" colspan="8" rowspan='1'>
                                    <span>수납 상세 금액</span>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    v-for="(hdr, j) in 7" :key="j+11"
                                    :class="props.headers[hdr+5].class" :style="props.headers[hdr+5].style+'top: 37px;'"
                                    colspan="1" rowspan='2'>
                                    <span>{{ props.headers[hdr+5].text }}</span>
                                </th>
                                <th
                                    :class="props.headers[14].class" :style="props.headers[14].style+'top: 37px;'"
                                    colspan="1" rowspan='2'>
                                    <span>{{ props.headers[14].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr>
                            <td class="onFixd fixPstn0 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ index + 1 }}</span>
                            </td>
                            <td class="onFixd fixPstn10 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.clmYymm }}</span>
                            </td>
                            <td class="onFixd fixPstn13 overflow-text-hidden txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="onFixd fixPstn14 txtAlgnMid tableBR px-1 py-2">
                                <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="onFixd fixPstn15 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.copayRateNm }}</span>
                            </td>
                            <td class="txtAlgnEnd tableBR pa-2">
                                <span class="fontOneRem">{{ item.copay?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnEnd tableBR pa-2">
                                <span class="fontOneRem">{{ item.npymntSum?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnEnd tableBR pa-2">
                                <span class="fontOneRem">{{ item.clmMnthSum?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="txtAlgnEnd tableBR pa-2">
                                <span class="fontOneRem">{{ item.dpsAmtTot?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="pa-0" colspan="10">
                                <div
                                    v-for="(dps, j) in item.clmsList" :key="j"
                                    :class="['d-flex', item.clmsList.length != (j+1) ? 'tableBB' : '']" style="height: 38px;">
                                    <span
                                        class="d-inline-block txtAlgnMid overflow-text-hidden tableBR px-2"
                                        style="width: 120px; height: 100%; padding-top: 9px;">
                                        <span v-if="dps.dpsDt == ''" class="fontOneRem grey006--text">-</span>
                                        <span v-else class="fontOneRem">{{ dps.dpsDt }}</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd overflow-text-hidden tableBR px-2"
                                        style="width: 140px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt1?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt2?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt5?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt3?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt4?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt8?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd tableBR px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt6?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnEnd px-2"
                                        style="width: 110px; height: 100%; padding-top: 9px;">
                                        <span class="fontOneRem">{{ dps.dpsAmt9?.toLocaleString('ko-KR') }}원</span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]="{  }">
                        <tr class="allHdrFix" style="bottom: 0;">
                            <td
                                class="onFixd fixPstn0 txtAlgnMid tableBR px-1 py-2"
                                style="background-color: #ededed !important;"
                                colspan="5">
                                <span class="fontOneRem font-weight-medium">합계</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.copay?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.npymntSum?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.clmMnthSum?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmtTot?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pa-2" style="" colspan="1"></td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt1?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt2?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt5?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt3?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt4?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt8?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt6?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batCpyClmDpsTot.dpsAmt9?.toLocaleString('ko-KR') }}원</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item transition="false" eager>
                <v-data-table
                    v-if="tab == 2"
                    class="greyE00-hdr-tbl mx-2" height="656" locale="ko"
                    :headers="tblHdr" :items="batDpsPrcList" item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"
                    fixed-header disable-pagination hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <!-- 128 -->
                        <thead>
                            <tr>
                                <th
                                    :class="props.headers[0].class" :style="props.headers[0].style"
                                    colspan="1" rowspan='1'>
                                    <span>{{ props.headers[0].text }}</span>
                                </th>
                                <th
                                    :class="props.headers[1].class" :style="props.headers[2].style"
                                    colspan="1" rowspan='1'>
                                    <span>{{ props.headers[2].text }}</span>
                                </th>
                                <th
                                    v-for="(hdr, i) in tblPrcHdr" :key="i"
                                    :class="hdr.class" :style="hdr.style"
                                    colspan="1" rowspan='1'>
                                    <span>{{ hdr.text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="onFixd fixPstn0 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.rn }}</span>
                            </td>
                            <td class="onFixd fixPstn10 overflow-text-hidden txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="onFixd fixPstn17 txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.dpsDt }}</span>
                            </td>
                            <td class="txtAlgnEnd tableBR pa-2">
                                <span class="fontOneRem">{{ item.dpstot?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="overflow-text-hidden txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.dpsnm }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.dpsMthdNm }}</span>
                            </td>
                            <td class="txtAlgnMid tableBR px-1 py-2">
                                <span class="fontOneRem">{{ item.cashbillnm }}</span>
                            </td>
                            <td class="txtAlgnEnd tableBR pa-2">
                                <span class="fontOneRem">{{ item.prpymrfndamt?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="overflow-text-hidden txtAlgnFst pa-2">
                                <span class="fontOneRem">{{ item.rmrks }}</span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]="{  }">
                        <tr class="allHdrFix" style="bottom: 0;">
                            <td
                                class="onFixd fixPstn0 txtAlgnMid tableBR px-1 py-2"
                                style="background-color: #ededed !important;"
                                colspan="3">
                                <span class="fontOneRem font-weight-medium">합계</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batDpsPrcTot.dpsTot?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01 tableBR" style="" colspan="3"></td>
                            <td class="nmlBfClrImp-greyE01 txtAlgnEnd tableBR pa-2" style="" colspan="1">
                                <span class="fontOneRem font-weight-medium">{{ batDpsPrcTot.prpymRfndAmt?.toLocaleString('ko-KR') }}원</span>
                            </td>
                            <td class="nmlBfClrImp-greyE01" style="" colspan="1"></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <BnfcrSelector v-if="lclMdlInf.typ == '01'" @isClose="rstLclMdlInf" @outBnfcr="getSltBnfcr"></BnfcrSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import { dblHdrXlsxPrdc, cpyClmDpsXlsxPrdc } from '../../api/global/cmmExcelJS.js';

import ExpRngMonthPicker from '../../components/commons/ExpRngMonthPicker.vue';
import ExpRngDayPicker from '../../components/commons/ExpRngDayPicker.vue';
import BnfcrSelector from '../../components/commons/BnfcrSelector.vue';

export default {
    name: 'CopayStts',

    components: {           
        ExpRngMonthPicker,
        ExpRngDayPicker,
        BnfcrSelector,
    },

    created: function () {
        
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {    
            let calc = this.$vuetify.breakpoint.width-220
            if(calc < 1262)
                this.viewWdth = 1262
            else if(calc > 1261 && calc < 1560)
                this.viewWdth = calc
            else if(calc > 1559)
                this.viewWdth = 1560
        })
    },

    computed: {
        getDspWdth: function () {
            return this.$vuetify.breakpoint.width
        },
    },

    watch: {
        getDspWdth: function (v) {
            let calc = v-220
            if(calc < 1262)
                this.viewWdth = 1262
            else if(calc > 1261 && calc < 1560)
                this.viewWdth = calc
            else if(calc > 1559)
                this.viewWdth = 1560
        },

        'tab': function () {
            switch (this.tab) {
                case 0:
                    if(this.srchObj.cpyToClms == '1')
                        this.getBatCpyClmList(null)
                    else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                        this.getBatCpyClmList(this.srchObj.bnMmbrPk)
                    break;
                case 1:
                    if(this.srchObj.cpyToClms == '1')
                        this.getBatCpyClmDpsList(null)
                    else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                        this.getBatCpyClmDpsList(this.srchObj.bnMmbrPk)
                    break;
                case 2:
                    if(this.srchObj.cpyToClms == '1')
                        this.getBatDpsPrcList(null)
                    else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                        this.getBatDpsPrcList(this.srchObj.bnMmbrPk)
                    break;
                default:
                    break;
            }
        },

        'srchObj.cpyToClms': function () {
            if(this.srchObj.cpyToClms == '1'){
                switch (this.tab) {
                    case 0:
                        this.getBatCpyClmList(null)
                        break;
                    case 1:
                        this.getBatCpyClmDpsList(null)
                        break;
                    case 2:
                        this.getBatDpsPrcList(null)
                        break;
                    default:
                        break;
                }
            }
            else if(this.srchObj.cpyToClms == '2'){
                switch (this.tab) {
                    case 0:
                        this.batCpyClmList.splice(0)
                        Object.keys(this.batCpyClmTot).forEach(itm => {
                            this.batCpyClmTot[itm] = 0
                        });
                        break;
                    case 1:
                        this.batCpyClmDpsList.splice(0)
                        Object.keys(this.batCpyClmDpsTot).forEach(itm => {
                            this.batCpyClmDpsTot[itm] = 0
                        });
                        break;
                    case 2:
                        this.batDpsPrcList.splice(0)
                        Object.keys(this.batDpsPrcTot).forEach(itm => {
                            this.batDpsPrcTot[itm] = 0
                        });
                        break;
                    default:
                        break;
                }

                this.srchObj.bnfcrNm = ''
                this.srchObj.bnMmbrPk = -1
            }
        }
    },

    methods: {
        // 본인부담금 청구내역(청구월도) 다건 조회
        getBatCpyClmList: function (pk) {
            this.isLoad = true
            this.exlBtn = true
            this.pdfBtn = true
            this.batCpyClmList.splice(0)
            Object.keys(this.batCpyClmTot).forEach(itm => {
                this.batCpyClmTot[itm] = 0
            });
         
            let obj = {}
            obj.inqYyMmStart = this.startYYMM
            obj.inqYyMmEnd = this.endYYMM
            obj.bnMmbrPk = pk

            http.post('batchInq/selBatCopayClmList', obj)
                .then((response) => ( this.batCpyClmRst(response.data) ))
                .catch((error) => console.log('connect error /batchInq/selBatCopayClmList : ' + error))
        },

        batCpyClmRst: function(res){
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    itm.clmYymm = this.$moment(itm.clmYymm, 'YYYYMM').format('YYYY.MM')

                    this.batCpyClmTot['copay'] += itm.copay
                    this.batCpyClmTot['mealCost'] += itm.mealCost
                    this.batCpyClmTot['upBedFee'] += itm.upBedFee
                    this.batCpyClmTot['lqdFood'] += itm.lqdFood
                    this.batCpyClmTot['snackFee'] += itm.snackFee
                    this.batCpyClmTot['btyFee'] += itm.btyFee
                    this.batCpyClmTot['clncPhrmcFee'] += itm.clncPhrmcFee
                    this.batCpyClmTot['cntrcDctrFee'] += itm.cntrcDctrFee
                    this.batCpyClmTot['msclnFee'] += itm.msclnFee
                    this.batCpyClmTot['prpymApl'] += itm.prpymApl
                    this.batCpyClmTot['unpdAmt'] += itm.unpdAmt
                    this.batCpyClmTot['clmTot'] += itm.clmTot
                });
                this.batCpyClmList = res.result.list
            }

            this.rflModule()
            this.isLoad = false
        },

        // 본인부담금 청구내역(수납내역) 다건 조회
        getBatCpyClmDpsList: function (pk) {
            this.isLoad = true
            this.exlBtn = true
            this.pdfBtn = true
            this.batCpyClmDpsList.splice(0)
            Object.keys(this.batCpyClmDpsTot).forEach(itm => {
                this.batCpyClmDpsTot[itm] = 0
            });
         
            let obj = {}
            obj.inqYyMmStart = this.startYYMM
            obj.inqYyMmEnd = this.endYYMM
            obj.bnMmbrPk = pk

            http.post('batchInq/selBatCopayClmDpsList', obj)
                .then((response) => ( this.batCpyClmDpsRst(response.data) ))
                .catch((error) => console.log('connect error /batchInq/selBatCopayClmDpsList : ' + error))
        },

        batCpyClmDpsRst: function(res){
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    itm.clmsList.forEach(clms => {
                        if(clms.dpsAmt == null) clms.dpsAmt = 0
                        if(clms.dpsDt == null) clms.dpsDt = ''
                        for (let index = 1; index < 10; index++) {
                            if(clms['dpsAmt' + index] == null)
                                clms['dpsAmt' + index] = 0
                        }

                        if(clms.dpsDt != '')
                            clms.dpsDt = this.$moment(clms.dpsDt, 'YYYYMMDD').format('YYYY.MM.DD')

                        this.batCpyClmDpsTot['dpsAmt'] += clms.dpsAmt
                        for (let index = 1; index < 10; index++) {
                            this.batCpyClmDpsTot['dpsAmt'+index] += clms['dpsAmt'+index]
                        }
                    });

                    if(itm.clmYymm == null) itm.clmYymm = ''
                    if(itm.copay == null) itm.copay = 0
                    if(itm.npymntSum == null) itm.npymntSum = 0
                    if(itm.clmMnthSum == null) itm.clmMnthSum = 0
                    if(itm.dpsAmtTot == null) itm.dpsAmtTot = 0

                    if(itm.clmYymm != '')
                        itm.clmYymm = this.$moment(itm.clmYymm, 'YYYYMM').format('YYYY.MM')

                    this.batCpyClmDpsTot['copay'] += itm.copay
                    this.batCpyClmDpsTot['npymntSum'] += itm.npymntSum
                    this.batCpyClmDpsTot['clmMnthSum'] += itm.clmMnthSum
                    this.batCpyClmDpsTot['dpsAmtTot'] += itm.dpsAmtTot
                });

                this.batCpyClmDpsList = res.result.list
            }

            this.rflModule()
            this.isLoad = false
        },

        // 본인부담금 입금내역 다건 조회
        getBatDpsPrcList: function (pk) {
            this.isLoad = true
            this.exlBtn = true
            this.pdfBtn = true
            this.batDpsPrcList.splice(0)
            Object.keys(this.batDpsPrcTot).forEach(itm => {
                this.batDpsPrcTot[itm] = 0
            });
         
            let obj = {}
            obj.inqYmdStart = this.startYYMMDD
            obj.inqYmdEnd = this.endYYMMDD
            obj.bnMmbrPk = pk

            http.post('batchInq/selBatDpsPrcHisList', obj)
                .then((response) => ( this.batDpsPrcRst(response.data) ))
                .catch((error) => console.log('connect error /batchInq/selBatDpsPrcHisList : ' + error))
        },

        batDpsPrcRst: function (res) {
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    if(itm.dpsDt == null) itm.dpsDt = ''
                    if(itm.dpstot == null) itm.dpstot = 0
                    if(itm.prpymrfndamt == null) itm.prpymrfndamt = 0

                    if(itm.dpsDt != '')
                        itm.dpsDt = this.$moment(itm.dpsDt, 'YYYYMMDD').format('YYYY.MM.DD')

                    this.batDpsPrcTot['dpsTot'] += itm.dpstot
                    this.batDpsPrcTot['prpymRfndAmt'] += itm.prpymrfndamt
                });

                // batDpsPrcTot: { dpsTot: 0, prpymRfndAmt: 0 },
                this.batDpsPrcList = res.result.list
            }

            this.rflModule()
            this.isLoad = false
        },

        rflModule: function(){
            switch (this.tab) {
                case 0:
                    if(this.batCpyClmList.length == 0){
                        this.exlBtn = true
                        this.pdfBtn = true
                    }
                    else{
                        this.exlBtn = false
                        this.pdfBtn = false
                    }
                    break;
                case 1:
                    if(this.batCpyClmDpsList.length == 0){
                        this.exlBtn = true
                        this.pdfBtn = true
                    }
                    else{
                        this.exlBtn = false
                        this.pdfBtn = false
                    }
                    break;
                case 2:
                    if(this.batDpsPrcList.length == 0){
                        this.exlBtn = true
                        this.pdfBtn = true
                    }
                    else{
                        this.exlBtn = false
                        this.pdfBtn = false
                    }
                    break;
                default:
                    break;
            }
        },

        dvsnPrt: function(){
            let prtCd = ''

            switch (this.tab) {
                case 0:
                    prtCd = '19'
                    break;
                case 1:
                    prtCd = '20'
                    break;
                case 2:
                    prtCd = '21'
                    break;
                default:
                    break;
            }

            if(this.srchObj.cpyToClms == '1')
                this.cpyClmPrt(null, prtCd)
            else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                this.cpyClmPrt(this.srchObj.bnMmbrPk, prtCd)
        },

        dvsnExl: function(){
            this.isLoad = true
            this.exlBtn = true
            this.pdfBtn = true

            switch (this.tab) {
                case 0:
                    this.thrwClmCrtDwnl()
                    break;
                case 1:
                    this.thrwDpsCrtDwnl()
                    break;
                case 2:
                    this.thrwPrcCrtDwnl()
                    break;
                default:
                    break;
            }

            this.isLoad = false
            this.exlBtn = false
            this.pdfBtn = false
        },

        // 연월 기간 피커 변동 이벤트
        getPckrMonth: function(obj){
            this.batDpsPrcList.splice(0)

            this.startYYMM = obj.strtYYMM
            this.endYYMM = obj.endYYMM

            switch (this.tab) {
                case 0:
                    if(this.srchObj.cpyToClms == '1')
                        this.getBatCpyClmList(null)
                    else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                        this.getBatCpyClmList(this.srchObj.bnMmbrPk)
                    break;
                case 1:
                    if(this.srchObj.cpyToClms == '1')
                        this.getBatCpyClmDpsList(null)
                    else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                        this.getBatCpyClmDpsList(this.srchObj.bnMmbrPk)
                    break;
                default:
                    break;
            }
        },
        // 일간 피커 변동 이벤트
        getPckrDt: function(obj){
            this.batCpyClmList.splice(0)
            this.batCpyClmDpsList.splice(0)

            this.startYYMMDD = obj.strtYYMMDD
            this.endYYMMDD = obj.endYYMMDD

            if(this.srchObj.cpyToClms == '1')
                this.getBatDpsPrcList(null)
            else if(this.srchObj.cpyToClms == '2' && this.srchObj.bnMmbrPk != -1)
                this.getBatDpsPrcList(this.srchObj.bnMmbrPk)
        },

        // 수급자 선택 모달 이벤트
        getSltBnfcr: function (obj) {
            if(this.srchObj.bnMmbrPk != obj.bnMmbrPk){
                switch (this.tab) {
                    case 0:
                        this.getBatCpyClmList(obj.bnMmbrPk)
                        break;
                    case 1:
                        this.getBatCpyClmDpsList(obj.bnMmbrPk)
                        break;
                    case 2:
                        this.getBatDpsPrcList(obj.bnMmbrPk)
                        break;
                    default:
                        break;
                }
            }

            this.srchObj.bnfcrNm = obj.name
            this.srchObj.bnMmbrPk = obj.bnMmbrPk
        },

        // 청구내역 출력
        cpyClmPrt: function(pk, typ){
            let oObj = {}
            oObj.rptTyp = '1'
            oObj.mode = '2'
            oObj.fcltyNum = this.$store.getters.getFcltyNum
            oObj.bnMmbrPk = pk
            //oObj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            oObj.prtClcd = '1'
            oObj.inqClcd = 2
            oObj.prtCd = typ
            if(this.tab == 2){
                oObj.inqYmdStart = this.startYYMMDD
                oObj.inqYmdEnd = this.endYYMMDD
            }
            else{
                oObj.inqYyMmStart = this.startYYMM
                oObj.inqYyMmEnd = this.endYYMM
            }

            oObj.list = [ { bzClcd: [], bzPk: [] } ]
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: oObj, outObj: null, runEvt: true,
            });

        },

        // 청구내역 엑셀 다운로드
        thrwClmCrtDwnl: function (){
            let mrgList = [ { colStrt: 'F1', colEnd: 'F1', colNm: '급여' }, { colStrt: 'G1', colEnd: 'N1', colNm: '비급여' } ]

            let shetNm = '본인부담금(청구내역)'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum 
            title += '_(' + this.$moment(this.startYYMM, 'YYYYMM').format('YYYY.MM') + '~'
            title += this.$moment(this.endYYMM, 'YYYYMM').format('YYYY.MM') + ')'

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 헤더 병합, 시트명, 파일명, 작성자, 합계 )
            dblHdrXlsxPrdc(this.exlBatCpyClmHdr, this.batCpyClmList, mrgList, shetNm, title, this.$store.getters.getUserNm, 'Y')
        },

        // 수납내역 엑셀 다운로드
        thrwDpsCrtDwnl: function (){
            let xlsxArr = []
            let shetNm = '본인부담금(수납내역)'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum 
            title += '_(' + this.$moment(this.startYYMM, 'YYYYMM').format('YYYY.MM') + '~'
            title += this.$moment(this.endYYMM, 'YYYYMM').format('YYYY.MM') + ')'

            this.batCpyClmDpsList.forEach((itm, i) => {
                
                if(itm.clmsList.length == 0){
                    let obj = Object.assign({}, itm)
                    obj.rn = i+1
                    obj.mgRow = false
                    obj.mgRowCnt = 1
                    obj.dpsDt = ''
                    obj.dpsAmt = 0
                    for (let index = 1; index < 10; index++) {
                        obj['dpsAmt' + index] = 0
                    }

                    xlsxArr.push(obj)
                }
                else{
                    itm.clmsList.forEach((idx, j) => {
                        let obj = Object.assign({}, itm)
                        obj.rn = i+1
                        if(j == 0 && itm.clmsList.length > 1){
                            obj.mgRow = true
                            obj.mgRowCnt = itm.clmsList.length
                        }
                        else{
                            obj.mgRow = false
                            obj.mgRowCnt = 1
                        }
                        obj.dpsDt = idx.dpsDt
                        obj.dpsAmt = idx.dpsAmt
                        for (let index = 1; index < 10; index++) {
                            obj['dpsAmt' + index] = idx['dpsAmt' + index]
                        }
                        xlsxArr.push(obj)
                    });
                }
            });

            // 본인부담금 수납내역 엑셀 생성 및 다운로드
            cpyClmDpsXlsxPrdc(this.exlBatDpsHdr, xlsxArr, shetNm, title, this.$store.getters.getUserNm, 'Y')
        },

        // 입금내역 엑셀 다운로드
        thrwPrcCrtDwnl: function (){
            let mrgList = [ { colStrt: 'D1', colEnd: 'F1', colNm: '입금내역' }, ]

            let shetNm = '본인부담금(입금내역)'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum 
            title += '_(' + this.$moment(this.startYYMMDD, 'YYYYMMDD').format('YYYY.MM.DD') + '~'
            title += this.$moment(this.endYYMMDD, 'YYYYMMDD').format('YYYY.MM.DD') + ')'

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 헤더 병합, 시트명, 파일명, 작성자, 합계 )
            dblHdrXlsxPrdc(this.exlBatPrcHdr, this.batDpsPrcList, mrgList, shetNm, title, this.$store.getters.getUserNm, 'Y')
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        tab: 0,
        exlBtn: true,
        pdfBtn: true,
        viewWdth: 1256,
        startYYMM: '',
        endYYMM: '',
        startYYMMDD: '',
        endYYMMDD: '',
        batCpyClmList: [],
        batCpyClmDpsList: [],
        batDpsPrcList: [],
        batCpyClmTot: {
            copay: 0, mealCost: 0, upBedFee: 0, lqdFood: 0, snackFee: 0, btyFee: 0, clncPhrmcFee: 0,
            cntrcDctrFee: 0, msclnFee: 0, prpymApl: 0, unpdAmt: 0, clmTot: 0,
        },
        batCpyClmDpsTot: {
            copay: 0, npymntSum: 0, clmMnthSum: 0, dpsAmtTot: 0, dpsAmt: 0, dpsAmt1: 0, dpsAmt2: 0, dpsAmt3: 0, dpsAmt4: 0,
            dpsAmt5: 0, dpsAmt6: 0, dpsAmt7: 0, dpsAmt8: 0, dpsAmt9: 0,
        },
        batDpsPrcTot: { dpsTot: 0, prpymRfndAmt: 0 },
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        srchObj: { cpyToClms: '1', bnfcrNm: '', bnMmbrPk: -1 },
        tabItems: [
            { index: 0, tab: '청구내역', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '수납내역', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 2, tab: '입금내역', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        tblHdr: [
            { text: '연번', value: 'rn', sortable: true, filterable: false, class: 'fixPstn0 zPstn3', style: 'width: 62px;' },
            { text: '청구월도', value: 'clmYymm', sortable: true, filterable: true, class: 'fixPstn10 zPstn3', style: 'width: 78px;' },
            { text: '수급자명', value: 'name', sortable: true, filterable: true, class: 'fixPstn13 zPstn3', style: 'width: 100px;' },
            { text: '등급', value: 'acknwRtngNm', sortable: true, filterable: false, class: 'fixPstn14 zPstn3', style: 'width: 50px;' },
            { text: '본인부담률', value: 'copayRateNm', sortable: true, filterable: false, class: 'fixPstn15 zPstn3', style: 'width: 100px;' },
            { text: '일수', value: 'ofrDys', sortable: true, filterable: false, class: 'fixPstn16 zPstn3', style: 'width: 60px;' },
            { text: '본인부담금', value: 'copay', sortable: true, filterable: false, class: '', style: 'width: 110px;' },
            { text: '식사재료비', value: 'mealCost', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '상급침실비', value: 'upBedFee', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '경관유동식비', value: 'lqdFood', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '간식비', value: 'snackFee', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '이·미용비', value: 'btyFee', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '진료약제비', value: 'clncPhrmcFee', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '계약의사 진찰비', value: 'cntrcDctrFee', sortable: true, filterable: false, class: '', style: 'width: 104px;' },
            { text: '기타비용', value: 'msclnFee', sortable: true, filterable: false, class: '', style: 'width: 110px;' },
            { text: '선납적용금액', value: 'prpymApl', sortable: true, filterable: false, class: '', style: 'width: 110px;' },
            { text: '미납금액', value: 'unpdAmt', sortable: true, filterable: false, class: '', style: 'width: 110px;' },
            { text: '청구금액', value: 'clmTot', sortable: true, filterable: false, class: '', style: 'width: 110px;' },
        ],
        tblDpsHdr: [
            { text: '본인부담금 청구액', value: 'copay', sortable: true, filterable: false, class: '', style: 'width: 140px;' },
            { text: '비급여 청구액', value: 'npymntSum', sortable: true, filterable: false, class: '', style: 'width: 140px;' },
            { text: '총 청구액', value: 'clmMnthSum', sortable: true, filterable: false, class: '', style: 'width: 140px;' },
            { text: '총 수납액', value: 'dpsamttot', sortable: true, filterable: false, class: '', style: 'width: 140px;' },
            { text: '수납일자', value: 'dpsDt', sortable: true, filterable: false, class: '', style: 'width: 120px;' },
            { text: '수납금액', value: 'dpsAmt', sortable: true, filterable: false, class: '', style: 'width: 140px;' },
        ],
        tblPrcHdr: [
            { text: '입금일자', value: 'dpsDt', sortable: true, filterable: false, class: 'fixPstn17 zPstn3', style: 'width: 128px;' },
            { text: '입금액', value: 'dpsTot', sortable: true, filterable: false, class: '', style: 'width: 160px;' },
            { text: '입금자명', value: 'dpsNm', sortable: true, filterable: false, class: '', style: 'width: 200px;' },
            { text: '입금방법', value: 'dpsMthdNm', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '현금영수증', value: 'cashbillnm', sortable: true, filterable: false, class: '', style: 'width: 100px;' },
            { text: '선납환불액', value: 'prpymRfndAmt', sortable: true, filterable: false, class: '', style: 'width: 160px;' },
            { text: '비고', value: 'rmrks', sortable: true, filterable: false, class: '', style: 'width: 510px;' },
        ],
        exlBatCpyClmHdr: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '청구월도', key: 'clmYymm', width: 12, },
            { header: '수급자명', key: 'name', width: 12, },
            { header: '등급', key: 'acknwRtngNm', width: 12, },
            { header: '본인부담률', key: 'copayRateNm', width: 16, },
            { header: '본인부담금', key: 'copay', width: 12, style: { numFmt: '#,##0' } },
            { header: '식사재료비', key: 'mealCost', width: 16, style: { numFmt: '#,##0' } },
            { header: '상급침실비', key: 'upBedFee', width: 16, style: { numFmt: '#,##0' } },
            { header: '경관유동식비', key: 'lqdFood', width: 16, style: { numFmt: '#,##0' } },
            { header: '간식비', key: 'snackFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '이·미용비', key: 'btyFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '진료약제비', key: 'clncPhrmcFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '계약의사진찰비', key: 'cntrcDctrFee', width: 16, style: { numFmt: '#,##0' } },
            { header: '기타비용', key: 'msclnFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '선납적용금액', key: 'prpymApl', width: 16, style: { numFmt: '#,##0' } },
            { header: '미납금액', key: 'unpdAmt', width: 16, style: { numFmt: '#,##0' } },
            { header: '청구금액', key: 'clmTot', width: 16, style: { numFmt: '#,##0' } },
        ],
        exlBatDpsHdr: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '청구월도', key: 'clmYymm', width: 12, },
            { header: '수급자명', key: 'name', width: 12, },
            { header: '등급', key: 'acknwRtngNm', width: 12, },
            { header: '본인부담률', key: 'copayRateNm', width: 16, },
            { header: '본인부담금 청구액', key: 'copay', width: 16, style: { numFmt: '#,##0' } },
            { header: '비급여 청구액', key: 'npymntSum', width: 16, style: { numFmt: '#,##0' } },
            { header: '총 청구액', key: 'clmMnthSum', width: 16, style: { numFmt: '#,##0' } },
            { header: '총 수납액', key: 'dpsAmtTot', width: 16, style: { numFmt: '#,##0' } },
            { header: '수납일자', key: 'dpsDt', width: 12, },
            { header: '수납금액', key: 'dpsAmt', width: 16, style: { numFmt: '#,##0' } },
            { header: '본인부담금', key: 'dpsAmt1', width: 16, style: { numFmt: '#,##0' } },
            { header: '식사재료비', key: 'dpsAmt2', width: 12, style: { numFmt: '#,##0' } },
            { header: '상급침실비', key: 'dpsAmt5', width: 12, style: { numFmt: '#,##0' } },
            { header: '경관유동식비', key: 'dpsAmt3', width: 12, style: { numFmt: '#,##0' } },
            { header: '간식비', key: 'dpsAmt4', width: 12, style: { numFmt: '#,##0' } },
            { header: '이·미용비', key: 'dpsAmt8', width: 12, style: { numFmt: '#,##0' } },
            { header: '진료약제비', key: 'dpsAmt6', width: 12, style: { numFmt: '#,##0' } },
            { header: '기타비용', key: 'dpsAmt9', width: 12, style: { numFmt: '#,##0' } },
        ],
        exlBatPrcHdr: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '수급자명', key: 'name', width: 12, },
            { header: '입금일자', key: 'dpsDt', width: 16, },
            { header: '입금액', key: 'dpstot', width: 14, style: { numFmt: '#,##0' } },
            { header: '입금자명', key: 'dpsnm', width: 30, },
            { header: '입금방법', key: 'dpsMthdNm', width: 12, },
            { header: '현금영수증', key: 'cashbillnm', width: 12, },
            { header: '선납환불액', key: 'prpymrfndamt', width: 14, style: { numFmt: '#,##0' } },
            { header: '비고', key: 'rmrks', width: 70, },
        ],
        srchCpyTyp: [ { valcd: '1', valcdnm: '청구월도' }, { valcd: '2', valcdnm: '수급자' } ],      // 청구 조회 조건
        srchPrcTyp: [ { valcd: '1', valcdnm: '입금일자' }, { valcd: '2', valcdnm: '수급자' } ],      // 입금 조회 조건
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },                                        // 통합 팝업 제어
    }),
};
</script>