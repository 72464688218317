<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+200" :max-width="areaWidth+500">
        <v-row class="pa-0 mx-3 mt-3 align-center" no-gutters>
            <v-spacer></v-spacer>
            <ExpRngMonthPicker
                :strtValue="$moment().format('YYYY')+'-01'" :disabled="ldr"
                @chngDtRng="getPckrDt">
            </ExpRngMonthPicker>
            <v-spacer></v-spacer>
        </v-row>
        <div class="pr-2 mt-3 px-2">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition">
                    <tab1 v-if="tab == 0"></tab1>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <tab2 v-if="tab == 1"></tab2>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import ExpRngMonthPicker from '../../components/commons/ExpRngMonthPicker.vue';
import tab1 from '../../components/btcInq/PrgBnfcrPrtcpSttsTab1.vue';
import tab2 from '../../components/btcInq/PrgBnfcrPrtcpSttsTab2.vue';
export default {
    name: 'PrgBnfcrPrtcpStts',
        
    components: {
        ExpRngMonthPicker,
        tab1,
        tab2,
    },

    created: function(){
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },

    watch:{ 
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        'tab':function(value){
            //선택 초기화
            this.$parent.$parent.tableSelected.splice(0)
            this.$parent.$parent.prgSelected.splice(0)

            this.$parent.$parent.stlTab = value
            if(value == 1) {
                this.$parent.$parent.sltStartYymm = this.startYYMM
                this.$parent.$parent.sltEndYymm = this.endYYMM
                this.$parent.$parent.getPrgBnfcrgrPrdList()
            }
        }
    },
        
    methods: {
        // 연월 기간 피커 변동 이벤트
        getPckrDt: function(obj){
            this.startYYMM  = obj.strtYYMM
            this.endYYMM    = obj.endYYMM
            if(this.tab == 1) {
                this.$parent.$parent.sltStartYymm = this.startYYMM
                this.$parent.$parent.sltEndYymm = this.endYYMM
                this.$parent.$parent.getPrgBnfcrgrPrdList()
            }
        },
    },
    
    data: () => ({
        areaWidth   : 708,
        ldr         : false,           // 로딩바
        startYYMM   : '',
        endYYMM     : '',
        tab         : 0,
        tabItems: [
            { index: 0, tab: '수급자별', class: 'fontOneDotTwoRem px-15'},
            { index: 1, tab: '그룹별', class: 'fontOneDotTwoRem px-15'},
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class: 'ml-1 white--text', color:'grey006', text: '엑셀', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-printer-outline', class: 'ml-1 white--text', color:'grey006', text: '출력', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
    }),
};
</script>