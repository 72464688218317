<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700" style="position:relative">        
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">급여제공계획</span>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(list.text)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="btnDisabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue' && list.visible"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
            </span>
            <span>
                <v-btn  
                    :class="outlinedBtn[0].class" :color="outlinedBtn[0].color" :disabled="outlinedBtn[0].disabled"
                    @click="onClickBtn(outlinedBtn[0].text)"
                    height="30" samll rounded outlined>
                    <v-icon small>{{outlinedBtn[0].icon}}</v-icon>
                    <span style="font-size: 1rem;">{{outlinedBtn[0].text}}</span>
                </v-btn>
                <v-btn  
                    :class="outlinedBtn[1].class" :color="outlinedBtn[1].color" :disabled="outlinedBtn[1].disabled"
                    @click="getExcelCrtDwnl(bnftOfrPlnHisPk)"
                    height="30" samll rounded outlined>
                    <v-icon small>{{outlinedBtn[1].icon}}</v-icon>
                    <span style="font-size: 1rem;">{{outlinedBtn[1].text}}</span>
                </v-btn>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 mt-2 tableBT tableBB" no-gutters>
                <v-col class="d-flex greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">적용기간</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-0 ma-0" cols="3">
                    <span class="white d-inline-block pa-1" style="width: 154px;"> 
                        <CmmDateComp v-model="items.aplStrt" :required="true" :btnActv="true" :maxDt="items.aplEnd"></CmmDateComp>
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 20px;">  
                        ~
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 154px;">                     
                        <CmmDateComp v-model="items.aplEnd" :required="true" :btnActv="true" :minDt="items.aplStrt"></CmmDateComp>
                    </span>
                </v-col>    
                <v-col class="d-flex greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>  
                <v-col class="d-flex justify-start align-center pa-0 ma-0" cols="2">
                    <span class="white d-inline-block pa-1" style="width: 154px;">     
                        <!-- <CmmDateComp v-model="items.wrtDt" :required="true" :minDt="inqYYYY+'-01-01'" :maxDt="inqYYYY+'-12-31'"></CmmDateComp> -->
                        <CmmDateComp v-model="items.wrtDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>  
                <v-col class="d-flex  greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">공단 통보일</span>
                </v-col>  
                <v-col class="d-flex justify-start align-center pa-0 ma-0" cols="2">
                    <span class="white d-inline-block pa-1" style="width: 154px;">                           
                        <CmmDateComp v-model="items.ntcDt" :required="false"></CmmDateComp>                            
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mb-2 tableBB" no-gutters>
                <v-col class="d-flex  greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>  
                <v-col class="d-flex justify-start align-center pa-0 ma-0" cols="2">
                    <span class="d-inline-block py-1 pl-1" style="width: 80px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">동의일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 ma-0" cols="2">
                    {{items.sgnDt}}
                </v-col>  
                <v-col class="d-flex greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">동의자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 ma-0" cols="2">
                    {{items.sgnNm}}
                </v-col>  
                <v-col class="d-flex greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">결과평가반영</span>
                </v-col>
                <v-col class="d-flex align-center ma-0" cols="2">
                    <div v-if="resPlnHis.bnftOfrPlnHisPk > 0" 
                        class="d-flex justify-start align-center pl-2" 
                        style="font-size:1.0rem !important;cursor:pointer"
                        @click="onResCmnts"
                    >
                        <v-icon color="blue">mdi-checkbox-marked-outline</v-icon>
                        <span class="pl-1 black--text font-weight-medium">급여제공 결과평가 반영</span>
                        <span>&#40;</span>
                        <span>{{resPlnHis.wrtDt?.beforeDateFormatDot()}}</span>
                        <span>&#41;</span>
                    </div> 
                </v-col>  
            </v-row>
        </v-form>
        <v-tabs
            v-model="tab" class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item eager transition="fade-transition">
                <tab3 ref="tab3"                    
                    :inqYYYY="inqYYYY"                  
                    @bnftSrvPlnTrigger="bnftSrvPlnReLoad" 
                    style="height: 500px;"></tab3>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab1 ref="tab1"                    
                    :inqYYYY="inqYYYY"                  
                    @bnftSrvPlnTrigger="bnftSrvPlnReLoad" 
                    style="height: 500px;"></tab1>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab2  ref="tab2" 
                    :tab="tab"                      
                    :inqYYYY="inqYYYY"  
                    :bnftOfrPlnHisPk="bnftOfrPlnHisPk"                
                    @bnftSrvPlnTrigger="bnftSrvPlnReLoad" 
                    style="height: 500px;"></tab2>
            </v-tab-item>            
        </v-tabs-items>         
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>  
        <v-dialog v-model="menu[2]" eager content-class="round" persistent max-width="800">
            <v-sheet class="ma-0 pa-7" rounded="lg">
                <div class="d-flex justify-left">
                    <span class="text-h4 font-weight-bold">급여제공&nbsp;결과평가</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" x-large @click="onResClose">mdi-close</v-icon>
                </div>
                <v-row class="pa-0 my-0" no-gutters>
                    <v-col class="d-flex align-start mt-2" cols="12">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">총평&#40;종합소견&#41;</span>
                    </v-col>
                </v-row>
                <v-row class="pa-0 my-0" no-gutters>
                    <v-col class="pa-2 d-flex justify-start align-center" cols="12">
                        <div v-html="resPlnHis.cmnts" class="pa-2 rounded-lg overflow-y-auto" style="width:100%;height:500px;border:2px solid #e7e3e3;"></div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-dialog> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="bizMsg" max-width="1100" eager>    
                <bizMsgMdl 
                    ref="bizMsgMdl"                    
                    :bizMsgData="[bnMmbrPk]"
                    :bizMsgDocClcd="4"
                    :bizMsgBzPk="bnftOfrPlnHisPk"
                    :bizMsgShow="bizMsg"
                    :bizMsgTitle="'급여제공계획'"
                    :bizMsgCntnt="$store.getters.getBnfcrState.name"
                    @madalState="onSendClose"
                ></bizMsgMdl>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import {getBnftOfrPlnHisInfo, insBnftOfrWrtPln, delBnftOfrWrtPln, selBnftOfrPlnList, selBnftOfrPlnWrtList} from '../../api/bnftSrv.js';
import { excelCrtDwnl } from '../../api/index.js';
import {selBnfcrExamSmryList, } from '../../api/bnftoffer.js';

import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import tab1 from './BnftSrvPlnTab1.vue';
import tab2 from './BnftSrvPlnTab2.vue';
import tab3 from './BnftSrvPlnTab3.vue';
import bizMsgMdl from '../commons/BizMsgMdl.vue';

export default {
    name: 'BnftSrvPln',

    props : {
        inqYYYY         : Number,       
        bnMmbrPk        : { type: Number, default: -1 },
        bnfcrPk         : { type: Number, default: -1 },
        bnftSrvPlnCmpPk : { type: Number, default: -1 }, 
        btnDisabled     : { type: Boolean, default: false },
        bzPk            : { type: Number, default: -1 },
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        EmpSelector,
        bizMsgMdl,
        tab1,
        tab2,
        tab3,
    },
    
    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },  

    watch: {     
        // 'bnftSrvPlnCmpPk':function(value){   
        //     this.filledBtn[0].visible = false 

        //     if(value > -1){
        //         this.bnftOfrPlnHisPk = value
        //         this.onShow()
        //          if(value == 0) this.filledBtn[0].visible = true
        //     }
            
        //     if(value > 0){
        //         this.outlinedBtn[0].disabled = false
        //         this.outlinedBtn[1].disabled = false
        //     }
        //     else{
        //         this.outlinedBtn[0].disabled = true
        //         this.outlinedBtn[1].disabled = true
        //         if(this.bzPk  > 0){
        //             this.getResPlnHisInfo(this.bzPk)
        //         }
        //     }
        // }
        getSubEvt: function (bol) {
            if(bol && this.rmBnftSrvCnFm){
                this.onDelete()                
                this.rmBnftSrvCnFm = false

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
            }

        },
    },

    mounted: function(){             
        this.$nextTick(function () {   
            this.filledBtn[0].visible = false 
                   
            this.bnftOfrPlnHisPk = this.bnftSrvPlnCmpPk

            if(this.bnftOfrPlnHisPk > -1){
                this.onShow()
                 if(this.bnftOfrPlnHisPk == 0) this.filledBtn[0].visible = true
            }
            
            if(this.bnftOfrPlnHisPk > 0){
                this.outlinedBtn[0].disabled = (this.btnDisabled)?true:false
                this.outlinedBtn[1].disabled = false
            }
            else{
                this.outlinedBtn[0].disabled = true
                this.outlinedBtn[1].disabled = true
                if(this.bzPk  > 0){
                    this.getResPlnHisInfo(this.bzPk)
                }
            }
        })
    },
    
    methods: {
        getExcelCrtDwnl: function (pk) {
            excelCrtDwnl(this.$store.getters.getFcltyNum, '5', null, null, pk)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /report/excelCrtDwnl : ' + error))
        },
        fileDownLoaderAfter: function (res) {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))

            link.href = url
            link.setAttribute('download', '공단급여제공계획서_' + this.$store.getters.getFcltyNum + '_' +  this.$parent.$parent.$parent.$parent.year + '.xlsx')
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        onShow : function(){
            this.progress = true

            //급여제공발송 ON/OFF
            if(this.bnftOfrPlnHisPk > 0) this.filledBtn[2].visible = true 
            else this.filledBtn[2].visible = false

            this.getInit()
            this.getOfrPlnHisInfo()
            this.selBnftOfrPlnList()
            this.selBnftOfrPlnWrtList()
            this.getBnfcrExamSmryList()
        },
        onClose: function () {
            this.bnftOfrPlnHisPk=0
            this.frmReset();
            this.$emit('modalEvent', 7)
        },
        onClickBtn: function(text) {
            if(text == "이전자료"){
                this.getPreData = true
                this.selBnftOfrPlnList()
                this.selBnftOfrPlnWrtList()
            }else if(text=="저장"){                
                this.insOfrPhyThrPln()                                             
            } else if(text=="삭제"){
                if(this.bnftOfrPlnHisPk > 0){
                    let obj = {}
                    obj.icon = 'mdi-alert'
                    obj.iconClr = 'red'
                    obj.title = '급여제공계획'
                    obj.cntnt = ['삭제하시겠습니까?']
                    obj.cnclTxt = '아니오'
                    obj.cnfrmTxt = '예, 삭제하겠습니다.'

                    this.$store.commit('setSubInf', {
                        actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                    });

                    this.rmBnftSrvCnFm = true
                }
            } else if(text=="급여제공계획 발송"){
                if(this.bnftOfrPlnHisPk > 0){
                    this.bizMsg = true
                }
            } else if(text=="출력"){
                this.pyrlPlanPrt()
            }
        },
        onSendClose:function(){
            this.bizMsg = false
        },
        getInit(){
            // let date = new Date()                                    
            // let dateStr = date.toISOString().split('T',2)[0]
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.wrtDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'
            this.forceRender += 1

            this.resPlnHis.bnftOfrPlnHisPk  = 0
            this.resPlnHis.cmnts            = ''
            this.resPlnHis.wrtDt            = ''

        },

        getOfrPlnHisInfo(){            
            if(this.bnftOfrPlnHisPk !='' && this.bnftOfrPlnHisPk > 0){                
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    bnMmbrPk :  this.bnMmbrPk,
                    inqYYYY : this.inqYYYY,
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                }
                
                getBnftOfrPlnHisInfo(fcltyNum, state)
                .then((response) => ( this.getOfrPlnHisInfoAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        getOfrPlnHisInfoAfter(data){            
            data.wrtDt = data.wrtDt?.beforeDateFormatHyp()
            data.aplStrt = data.aplStrt?.beforeDateFormatHyp()
            data.aplEnd = data.aplEnd?.beforeDateFormatHyp()
            data.ntcDt = data.ntcDt?.beforeDateFormatHyp()
            this.$refs.tab3.setCmnts(data)
            this.$refs.tab2.setCmnts(data.cmnts)
            this.items = data
            if(this.items.bzPk !=null && this.items.bzPk != undefined){
                this.getResPlnHisInfo(this.items.bzPk)
            }

        },
        //급여제공 결과평가 조회
        getResPlnHisInfo(bzpk){              
            let fcltyNum = this.$store.getters.getFcltyNum
            let state = {
                bnMmbrPk :  this.bnMmbrPk,
                inqYYYY : this.inqYYYY,
                bnftOfrPlnHisPk : bzpk,
            }
            getBnftOfrPlnHisInfo(fcltyNum, state)
            .then((response) => ( this.getResPlnHisInfoAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getResPlnHisInfoAfter:function(res){
            this.resPlnHis.bnftOfrPlnHisPk      = res.bnftOfrPlnHisPk
            this.resPlnHis.cmnts                = res.cmnts?.replaceAll("\n", "<br />")
            this.resPlnHis.wrtDt                = res.wrtDt
        },

        selBnftOfrPlnList: function(){              
            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum, 
                bnMmbrPk        : null,
                bnftOfrPlnHisPk : null,
            }

            if(this.getPreData){
                obj.bnMmbrPk    =  this.bnMmbrPk
            }else{
                obj.bnftOfrPlnHisPk  = this.bnftOfrPlnHisPk
            }

            selBnftOfrPlnList(obj)
            .then((response) => ( this.$refs.tab1.selBnftOfrPlnListAfter(response.data)))
            .catch((error) => console.log(error))
        },

        selBnftOfrPlnWrtList: function(){              
            let fcltyNum = this.$store.getters.getFcltyNum
            let bnftOfrPlnHisPk = null
            let bnMmbrPk = null

            if(this.getPreData){
                bnMmbrPk = this.bnMmbrPk
            }else{
                bnftOfrPlnHisPk = this.bnftOfrPlnHisPk
            }

            selBnftOfrPlnWrtList(fcltyNum,bnftOfrPlnHisPk,bnMmbrPk)
            .then((response) => ( this.$refs.tab2.selBnftOfrPlnWrtListAfter(response.data)))
            .catch((error) => console.log(error))
        },

        getBnfcrExamSmryList:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.bnMmbrPk,
                bnfcrPk     : this.bnfcrPk,
                clsfc1Cd    : '101',
                clsfc2Cd    : '999',
            }

            selBnfcrExamSmryList(obj)
                    .then((response) => ( this.$refs.tab3.getBnfcrExamSmryListAfter(response.data)))
                    .catch((error) => console.log(error))            
        },
       
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        insOfrPhyThrPln(){
            if(this.$refs.form.validate()){
                let tabValidate = this.$refs.tab2.selValidate()

                if(tabValidate.validateChk){

                    let ofrPlnHisInfo = {
                        wrtDt :  this.items.wrtDt?.afterDateFormatHyp(),
                        fcltyNum : this.$store.getters.getFcltyNum,
                        userNm : this.$store.getters.getUserNm,
                        bnMmbrPk : this.bnMmbrPk,
                        bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                        wrtr : this.items.wrtr,
                        mdfClcd : this.items.mdfClcd,
                        aplStrt : this.items.aplStrt?.afterDateFormatHyp(), 
                        aplEnd : this.items.aplEnd?.afterDateFormatHyp(),
                        ntcDt : this.items.ntcDt?.afterDateFormatHyp(),
                        plnClcd:'201',
                        plnYyyy: this.inqYYYY ,
                        cmnts : this.$refs.tab3.selCmnts(),
                        goal : this.$refs.tab3.selGoal(),                        
                        bzPk: this.items.bzPk,
                        bzClcd : this.items.bzClcd,
                    }

                    //결과평가 반영시
                    if(this.bzPk > 0 && this.bnftOfrPlnHisPk == 0){
                        ofrPlnHisInfo.bzPk      = this.bzPk
                        ofrPlnHisInfo.bzClcd    = '17'
                    }
        
                    let bnftOfrPlnWrtList = this.$refs.tab2.selBnftOfrPlnWrtList()
                    
                    if(this.getPreData){
                        bnftOfrPlnWrtList.forEach(el => {
                            el.bnftOfrPlnHisPk = 0
                            el.bnftOfrPlnPk = 0

                            el.listDtl.forEach(itm => {
                                itm.bnftOfrPlnDtlPk = 0
                                itm.bnftOfrPlnPk = 0
                            });
                        });
                    }
                    this.progress = true
                    
                    insBnftOfrWrtPln(ofrPlnHisInfo, bnftOfrPlnWrtList)
                        .then((response) => ( this.insBnftOfrWrtPlnAfter(response.data)))
                        .catch((error) => console.log(error))

                }else{
                    let obj = {}
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = tabValidate.validateMsg         // 경고 타이틀                
                    this.$store.commit('setWrnnInf', obj)                    
                    this.getPreData = false
                }
            }

            
        },
        insBnftOfrWrtPlnAfter: function (res) {
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀 

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)               
                this.bnftOfrPlnHisPk = Number(res.result)
                this.getOfrPlnHisInfo()
                this.selBnftOfrPlnList()
                this.selBnftOfrPlnWrtList()
                this.$emit('modalEvent', 99)
                this.filledBtn[0].visible = false
                this.getPreData = false

                this.outlinedBtn[0].disabled = (this.btnDisabled)?true:false
                this.outlinedBtn[1].disabled = false
            }
            else{
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.tmr = 5000
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }          

            this.$store.commit('setWrnnInf', obj)
        },   

        onDelete:function(){
            if(this.bnftOfrPlnHisPk > 0){
                let code = this.$store.getters.getFcltyNum;
                let state = {
                    bnMmbrPk :  this.bnMmbrPk,
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                    userNm:this.$store.getters.getUserNm,
                }
                this.progress = true
                delBnftOfrWrtPln(code, state)
                .then((response) => ( this.delBnftOfrWrtPlnAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },

        delBnftOfrWrtPlnAfter:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀 


            this.progress = false
            if(res.statusCode == 200){                 
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)                                     
                this.$emit('modalEvent', 99)
                this.$store.commit('setBnftOfrPlnHisPk', '')
                this.onClose()
            }
            else{
                this.$store.commit('setBnftOfrPlnHisPk', '')
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.tmr = 5000
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }
            this.$store.commit('setWrnnInf', obj)
        },

        frmReset:function(){    
            this.tab=0              
            this.$refs.form.reset();
            this.$refs.tab3.reset();
            //급여내용작성 탭 초기화
            // if(typeof this.$refs.tab1 != "undefined"){                
            //     this.$refs.tab1.close()
            // }

            // if(typeof this.$refs.tab2 != "undefined"){                
            //     this.$refs.tab2.close()
            // }
        },
        bnftSrvPlnReLoad:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀 


            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)                       
                this.bnftOfrPlnHisPk = Number(res.result)
                this.getOfrPlnHisInfo()
                this.selBnftOfrPlnList()
                this.selBnftOfrPlnWrtList()
                this.$emit('modalEvent', 99)
            }
            else{
                obj.tmr = 5000
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }
            this.$store.commit('setWrnnInf', obj)
        },

        //급여 제공 계획서 출력
        pyrlPlanPrt: function (){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.bnfcrPk
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '7'
            obj.inqYYYY = ''
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            obj.list[0].bzPk.push(this.bnftOfrPlnHisPk)

            // console.log(obj)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onModal: function () {
            this.prtWrtDt = this.items.wrtDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        onResCmnts:function(){
            this.menu.splice(2, 1, true)
        },
        onResClose:function(){
            this.menu.splice(2, 1, false)
        }
    },
    
    data: () => ({  
        getPreData:false,
        minDate:'',
        maxDate:'',
        prtWrtDt:'',        
        tab: 0,        
        bnftOfrPlnHisPk:0,  
        items:{},
        resPlnHis:{bnftOfrPlnHisPk:0,cmnts:'',wrtDt:''},
        dialog:[],
        menu: [false,false,false,false],        
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type: 'action', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', visible: true, disabled: false,},
            { icon: 'mdi-send', class: 'ml-1 white--text', color:'blueBtnColor', text: '급여제공계획 발송', type: 'action', visible: false, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', visible: true, disabled: false,},
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: false, },
            { icon: 'mdi-microsoft-excel', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '엑셀(공단용)', disabled: false, },
        ],
        tabItems: [
            { index: 0, tab: '기본정보', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '급여내용 조회', class: 'fontOneDotTwoRem px-15',},
            { index: 2, tab: '급여내용 작성', class: 'fontOneDotTwoRem px-15',},
        ],        
        forceRender:0,
        bizMsg:false,
        eCntnt:'',
        progress:false,
        rmBnftSrvCnFm:false,
    }),
};
</script>