<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md" style="height: 712px;">
        <v-row class="pa-0 mt-3 mb-3 align-center posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">인계일지 (주간근무자->야간근무자)
            </span>
            <v-spacer></v-spacer>
            <v-btn                
                class="white--text" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor"
                :disabled="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == -1"
                @click="onLclMdl('11', 1000)"
                min-width="20" height="20" small outlined>
                <span class="fontNineThRem" style="padding-bottom: 2px;">인계일지 작성</span>
            </v-btn>
            <v-btn                
                class="white--text ml-2" style="padding: 9px 7px 8px 5px !important;"
                color="grey006"
                @click="$parent.$parent.$parent.$parent.prtNtyInspJrnls('94')"
                :disabled="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == -1"
                min-width="20" height="20" small outlined>
                <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">출력</span>
            </v-btn>
        </v-row>
        <v-divider :class="[tkeOvrList.length > 15 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', tkeOvrList.length > 15 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">연번</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="3">
                <span class="fontOneDotOneRem font-weight-bold">작성일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">작성자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="5">
                <span class="fontOneDotOneRem font-weight-bold">인계사항</span>
            </v-col>
            <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[tkeOvrList.length > 15 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="tkeOvrList.length > 0" class="overflow-y-auto" style="width: 100%; height: 585px;">
            <v-row
                class="tableBB"
                v-for="(itm, i) in tkeOvrList" :key="i"
                no-gutters>               

                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                    <span class="fontOneRem">{{ i+1 }}</span>
                </v-col>

                <v-col class="tableBR txtAlgnMid pb-2 pl-2" style="padding-top: 6px;" cols="3">
                    <span class="fontOneRem">{{ itm.wrtDt }} &#40;{{ itm.wrtTmHh }}:{{ itm.wrtTmMm}}&#41;</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">                    
                    <span class="fontOneRem">{{ itm.wrtr}}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="5">                    
                    <div class="overflow-text-hidden">{{ itm.cntnt}}</div>
                </v-col>
                <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="1">
                    <v-btn
                        color="grey006" height="26"     
                        @click="onTkeOvr(itm)"   
                        :disabled="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == -1"
                        icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-square-edit-outline </v-icon>
                                </div>
                            </template>
                            <span>인계일지 작성상세</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 그룹정보가 없습니다.</span>
            </v-col>
        </v-row>
        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <TkeOvrMdl
                v-if="lclMdlInf.typ == '11'"
                ref="pMSelector" :isUpd="true"
                :inqymd="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem"
                :sltTkeOvrPk="sltTkeOvrPk"
                :clcd="'1'"
                @isClose="rstLclMdlInf">
            </TkeOvrMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import TkeOvrMdl from './TkeOvrMdl.vue';                // 야간점검일지

export default {
    name: 'DayTakeLog',

    props : {
       
    },
        
    components: {
        TkeOvrMdl
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {    
            this.getTkeOvrList()    
        })
    },

    computed: {
        getSltInspRecPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
        },
        getSltInspRecDt: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltInspRecDt:function(){
            this.getTkeOvrList()
        },
    },
    
    methods: {
        // 야간점검일지현황 내역
        getTkeOvrList: function (){ 
            this.tkeOvrList.splice(0)
            let inqymd =  this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
            if(inqymd != -1 && inqymd != undefined) {
                inqymd = this.$moment(this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem,'YYYY.MM.DD').format('YYYYMMDD')
            
                let obj = {
                    inqYMD:inqymd,
                    clcd:'1'
                }

                http.post('sfty/selTkeOvrList', obj)
                    .then((response) => ( this.tkeOvrListRst(response.data) ))
                    .catch((error) => console.log('connect error /sfty/selTkeOvrList : ' + error))
            } 
        },

        tkeOvrListRst:function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {
                        itm.wrtDt = itm.wrtDt?.beforeDateFormatDot()
                        this.tkeOvrList.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
            this.sltTkeOvrPk      = -1
            this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
            // this.gelInspRecDtlSttlist()
        },

        onTkeOvr:function(obj){
            this.sltTkeOvrPk = obj.tkeOvrPk
            this.onLclMdl('11', 1000)
        }
    },

    data: () => ({        
        sltTkeOvrPk   : -1,
        tkeOvrList   : [],
        lclMdlInf       : { isOn: false, maxWidth: 800, typ: '' },
    }),
};
</script>