<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="8">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">가족 및 환경상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 25px;" cols="3">
                        <span class="font-weight-medium">동거인</span>
                    </v-col>
                    <v-col class="pa-2" cols="9">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_1" :ripple="false"
                                true-value="1" false-value="2" label="독거"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_2" :ripple="false"
                                true-value="1" false-value="2" label="배우자"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_3" :ripple="false"
                                true-value="1" false-value="2" label="부모"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_4" :ripple="false"
                                true-value="1" false-value="2" label="자녀"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_5" :ripple="false"
                                true-value="1" false-value="2" label="자부·사위"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_6" :ripple="false"
                                true-value="1" false-value="2" label="손자녀"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_7" :ripple="false"
                                true-value="1" false-value="2" label="친척"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_8" :ripple="false"
                                true-value="1" false-value="2" label="친구·이웃"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 116px;">
                                <v-text-field
                                    v-model="inpTxt.val_1_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="3">
                        <span class="font-weight-medium">자녀수</span>
                    </v-col>
                    <v-col class="d-flex align-center pa-2" cols="9">
                        <v-radio-group
                            v-model="inpRadio.val_2_1"
                            class="radio-dft-Theme mt-0" ref="info" hide-details row>
                            <v-radio
                                class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                :ripple="false" label="무" value="2">
                            </v-radio>
                            <v-radio
                                class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                :ripple="false" label="유" value="1">
                            </v-radio>
                        </v-radio-group>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <span class="fontOneRem mr-2">아들:</span>
                        <span class="d-inline-block" style="width: 80px;">
                            <v-text-field
                                v-model="inpTxt.val_2_3"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="fontOneRem ml-1 mr-3">명,</span>
                        <span class="fontOneRem mr-2">딸:</span>
                        <span class="d-inline-block" style="width: 80px;">
                            <v-text-field
                                v-model="inpTxt.val_2_4"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="fontOneRem ml-1">명</span>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="1">
                        <div class="txtAlgnMid" style="width: 100%; line-height: 1.1;">
                            <span class="font-weight-medium">주</span> <br>
                            <span class="font-weight-medium">수</span> <br>
                            <span class="font-weight-medium">발</span> <br>
                            <span class="font-weight-medium">자</span>
                        </div>
                    </v-col>
                    <v-col
                        class="nmlBfClrImp-greyE01 txtAlgnMid tableBR"
                        style="padding-top: 10px; padding-bottom: 7px;"
                        cols="2">
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">유무</span>
                        </v-row>
                        <v-divider style="margin-top: 7px; margin-bottom: 10px;"></v-divider>
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">관계</span>
                        </v-row>
                        <v-divider style="margin-top: 7px; margin-bottom: 10px;"></v-divider>
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">경제상태</span>
                        </v-row>
                        <v-divider style="margin-top: 7px; margin-bottom: 19px;"></v-divider>
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">부양부담</span>
                        </v-row>
                    </v-col>
                    <v-col class="" cols="9">
                        <v-row class="px-0 pt-1 align-center" no-gutters>
                            <v-radio-group
                                v-model="inpRadio.val_3_1" @change="dftEvt()"
                                class="radio-dft-Theme mt-0 ml-2" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="무" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-3" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="유" value="1">
                                </v-radio>
                            </v-radio-group>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <v-radio-group
                                v-model="inpRadio.val_3_2"
                                class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                <v-radio
                                    class="px-0" style="padding-bottom: 2px !important; margin-right: 2px !important;"
                                    :ripple="false" label="10~20대" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-0" style="padding-bottom: 2px !important; margin-right: 2px !important;"
                                    :ripple="false" label="30~40대" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0" style="padding-bottom: 2px !important; margin-right: 2px !important;"
                                    :ripple="false" label="50~60대" value="3">
                                </v-radio>
                                <v-radio
                                    class="px-0" style="padding-bottom: 2px !important; margin-right: 2px !important;"
                                    :ripple="false" label="70대" value="4">
                                </v-radio>
                                <v-radio
                                    class="px-0" style="padding-bottom: 2px !important; margin-right: 2px !important;"
                                    :ripple="false" label="80대이상" value="5">
                                </v-radio>
                            </v-radio-group>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-divider style="margin-top: 6px;"></v-divider>
                        <v-row class="px-0 pt-1 align-center" no-gutters>
                            <v-radio-group
                                v-model="inpRadio.val_4_1"
                                class="radio-dft-Theme mt-0 ml-2" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="배우자" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="자녀" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="자부·사위" value="3">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="형제·자매" value="5">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-1" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="친척" value="6">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-0" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="기타" value="7">
                                </v-radio>
                            </v-radio-group>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 85px;">
                                <v-text-field
                                    v-model="inpTxt.val_4_7"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-divider style="margin-top: 6px; margin-bottom: 7px;"></v-divider>
                        <v-row class="px-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_5_1" :ripple="false"
                                true-value="1" false-value="2" label="안정"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_5_2" :ripple="false"
                                true-value="1" false-value="2" label="불안정"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_5_3" :ripple="false"
                                true-value="1" false-value="2" label="연금생활"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_5_4" :ripple="false"
                                true-value="1" false-value="2" label="기초생활수급"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 ml-2 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_5_5" :ripple="false"
                                true-value="1" false-value="2" label="의료급여"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-divider style="margin-top: 8px;"></v-divider>
                        <v-row class="px-0 pt-1 align-center" no-gutters>
                            <v-radio-group
                                v-model="inpRadio.val_6_1"
                                class="radio-dft-Theme mt-0 ml-2" ref="info" hide-details row>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="전혀 부담되지 않음" value="1">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="아주 가끔 부담됨" value="2">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="가끔 부담됨" value="3">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="자주 부담됨" value="4">
                                </v-radio>
                                <v-radio
                                    class="px-0 mr-4" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="항상 부담됨" value="5">
                                </v-radio>
                            </v-radio-group>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="4">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 10px; padding-bottom: 10px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="30" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'EnvrnState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                if(itm.depth3 == '1' || itm.depth3 == '5')
                    this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                else if(itm.depth3 == '2' || itm.depth3 == '3' || itm.depth3 == '4' || itm.depth3 == '6')
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '1' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '2' && itm.valCd == '3')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '2' && itm.valCd == '4')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '4' && itm.valCd == '7')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oTxt = {}
                oTxt.examPk = 0
                oTxt.depth2 = '5'
                oTxt.depth3 = '99'
                oTxt.valCd = '1'
                oTxt.val = ''
                oTxt.val2 = this.inpTxt.val_99_1
                finArr.push(oTxt)

                let oTxt_2_3 = {}
                oTxt_2_3.examPk = 0
                oTxt_2_3.depth2 = '5'
                oTxt_2_3.depth3 = '2'
                oTxt_2_3.valCd = '3'
                oTxt_2_3.val = ''
                oTxt_2_3.val2 = this.inpTxt.val_2_3
                finArr.push(oTxt_2_3)

                let oTxt_2_4 = {}
                oTxt_2_4.examPk = 0
                oTxt_2_4.depth2 = '5'
                oTxt_2_4.depth3 = '2'
                oTxt_2_4.valCd = '4'
                oTxt_2_4.val = ''
                oTxt_2_4.val2 = this.inpTxt.val_2_4
                finArr.push(oTxt_2_4)

                let oTxt_4_7 = {}
                oTxt_4_7.examPk = 0
                oTxt_4_7.depth2 = '5'
                oTxt_4_7.depth3 = '4'
                oTxt_4_7.valCd = '7'
                oTxt_4_7.val = ''
                oTxt_4_7.val2 = this.inpTxt.val_4_7
                finArr.push(oTxt_4_7)

                Object.keys(this.inpRadio).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '5'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpRadio[itm]
                    oObj.val2 = ''

                    finArr.push(oObj)
                });

                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '5'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '1' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    if(oObj.depth3 == '1' || oObj.depth3 == '5')
                        oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else
                        oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '1' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '2' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '2' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '4' && oObj.valCd == '7')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        dftEvt: function (){
            if(this.inpRadio.val_3_1 == '2')
                this.inpRadio.val_3_2 = '0'
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = '0'
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 동거인-기타, 자녀수-아들, 자녀수-딸, 주수발자-관계-기타, 판단근거
        inpTxt: {
            val_1_9: '', val_2_3: '', val_2_4: '', val_4_7: '',
            val_99_1: ''
        },
        // 자녀수 (d3: 2)
        // 주수발자 (d3: 3) - cd1: 유무, cd2: 연령
        // 주수발자 (d3: 4) - cd1: 관계
        // 주수발자 (d3: 6) - cd1: 부양부담
        inpRadio: { val_2_1: '0', val_3_1: '0', val_3_2: '0', val_4_1: '0', val_6_1: '0' },
        // 동거인 (d3: 1) - cd1: 독거, cd2: 배우자, cd3: 부모, cd4: 자녀, cd5: 자부,사위, cd6: 손자녀, cd7: 친척, cd8: 친구이웃, cd9: 기타
        // 주수발자 (d3: 5) - cd1: 안정, cd2: 불안정, cd3: 연금생활, cd4: 기초생활수급, cd5: 의료급여
        inpCheck: {
            val_1_1: '2', val_1_2: '2', val_1_3: '2', val_1_4: '2', val_1_5: '2', val_1_6: '2', val_1_7: '2', val_1_8: '2', val_1_9: '2',
            val_5_1: '2', val_5_2: '2', val_5_3: '2', val_5_4: '2', val_5_5: '2',
        },
    }),
};
</script>