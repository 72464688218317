<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="6">
                <span class="text-h4 font-weight-bold">인계일지</span>
                <span v-if="clcd=='2'" class="text-h4 font-weight-bold">(야간근무자->주간근무자)</span>
                <span v-else class="text-h4 font-weight-bold">(주간근무자->야간근무자)</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-center align-center" cols="6">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        @click="onClickBtn(i)"
                        :disabled="list.disabled" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
                <v-icon color="black" x-large @click="$emit('isClose', '.')">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters>
            <div class="mr-3 d-flex align-center">
                <v-icon color="red" size="18">mdi-alert-circle-outline</v-icon>
                <span class="pl-1 red--text">작성시간 00(24)시 ~ 06시 자동으로 익일로 계산됩니다.</span>
            </div>
        </v-row>
        <v-row class="mt-3" v-if="clcd=='2' && this.inspAreaPk > 0" no-gutters>
            <div class="mr-3 d-flex align-center">
                <v-icon color="red" size="18">mdi-alert-circle-outline</v-icon>                
                <span class="pl-1 red--text">작성일시, 작성자, 삭제는 야간점검일지에서 수정 할 수 있습니다!</span>
            </div>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">                
                <span class="font-weight-medium posn-rltv">
                    작성일자
                    <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="tableBR d-flex fontNineThRem justify-left align-center px-2 py-1" cols="2">
                <span class="fontOneRem">{{ inqymd }}</span>
                <span v-if="wrtDtlDt != ''">
                    &#40;{{ wrtDtlDt }}&#41;
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">                
                <span class="font-weight-medium posn-rltv">
                    작성시간
                    <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="tableBR d-flex justify-left align-center px-2 py-1" cols="2">
                <span class="d-inline-block pl-2" style="width: 80px;">
                    <CmmTimeField v-model="wrtTm" :required="true" :disabled="clcd=='2' && this.inspAreaPk > 0"  @input="setTmInspDT"></CmmTimeField>
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex justify-center align-center" cols="2">                
                <span class="font-weight-medium posn-rltv">
                    작성자
                    <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 py-1 d-flex justify-left align-center" cols="2">
                <span class="d-inline-block ml-1" style="width: 100px;">
                    <v-text-field
                        v-model="wrtr" :rules="[rules.required]"
                        height="28" readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3 d-inline-block"
                    style="padding: 0px 1px 0px 2px !important;"
                    :disabled="clcd=='2' && this.inspAreaPk > 0"
                    min-width="30" height="26" @click="menu.splice(0,1, true)"
                    dark small>
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>           
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex justify-center align-center" cols="2">
                <span class="font-weight-medium posn-rltv">
                    인계사항
                    <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 py-1 d-flex justify-left align-center" cols="10">
                <v-textarea
                    class="rmrksArea" rows="30"
                    v-model="cntnt"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>                  
        <v-dialog v-model="menu[0]" content-class="round" max-width="800" persistent eager>
            <EmpSelector v-if="menu[0]" @isClose="menu.splice(0,1,false)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'TkeOvrMdl',
    
    components: {
        EmpSelector,
        CmmTimeField
    },

    props : {         
        inqymd          : { type: String, default: '' },
        sltTkeOvrPk     : { type: Number, default: -1 },
        clcd            : { type: String, default: '' }
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {   
            this.prtWrtDt = this.$moment(this.inqymd, 'YYYY.MM.DD').format('YYYYMMDD')              
            this.cntnt = '특이사항 없음'
            if(this.sltTkeOvrPk > 0){                          
                this.filledBtn[1].disabled = false
            }
            else {
                this.filledBtn[1].disabled = true
            }
            this.getTkeOvrInfo()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {     
        getSubEvt: function (bol) {
            if(bol && this.rmTkeOvrCnFm){
                this.removeTkeOvr()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmNghChcCnFm = false
            }
        },
    },
        
    methods: {
         
        // 인수인계 단건 조회
        getTkeOvrInfo: function (){    
            if(this.sltTkeOvrPk > 0){
                let obj = {                
                    tkeOvrPk    : this.sltTkeOvrPk,
                }
    
                http.post('sfty/selTkeOvrInfo', obj)
                    .then((response) => ( this.tkeOvrInfoRst(response.data) ))
                    .catch((error) => console.log('connect error /sfty/selTkeOvrInfo : ' + error))
            }
        },

        // 인수인계 단건 조회
        insTkeOvr: function (obj){  
            http.post('sfty/insTkeOvr', obj)
                .then((response) => ( this.insTkeOvrRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/insTkeOvr : ' + error))
        },

        //인수인계 삭제
        removeTkeOvr:function(){
            if(this.sltTkeOvrPk > 0){  
                let obj = {
                    tkeOvrPk    : this.sltTkeOvrPk,
                }  
                http.post('sfty/delTkeOvr', obj)
                    .then((response) => ( this.insTkeOvrRst(response.data) ))
                    .catch((error) => console.log('connect error /sfty/delTkeOvr : ' + error))
            }            
        },

        tkeOvrInfoRst:function(res){
            if(res.statusCode == '200'){
                if(res.result != null){
                    if(res.result.cntnt != '') this.cntnt = res.result.cntnt

                    if(res.result.wrtTmHh != null && res.result.wrtTmMm)
                        this.wrtTm = res.result.wrtTmHh+":"+res.result.wrtTmMm

                    if(res.result.wrtDtlDt != null && res.result.wrtDtlDt)
                        this.wrtDtlDt = this.$moment(res.result.wrtDtlDt,'YYYYMMDD').format('YYYY.MM.DD')

                    this.wrtr = res.result.wrtr
                    this.wrtrMmbrPk = res.result.wrtrMmbrPk
                    if(res.result.inspAreaPk != null) {
                        this.inspAreaPk = res.result.inspAreaPk
                        this.filledBtn[1].disabled = true
                    } 
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        insTkeOvrRst:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀   

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit('isClose', '.')                    
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)   
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)             
            }            
            this.$store.commit('setWrnnInf', obj)
        },

        onClickBtn: function (index) {
            switch(index) {                
                case 0:     //저장
                    this.preInsTkeOvr()
                    break;
                case 1:    //삭제
                    this.preRemoveTkeOvr()
                    break;
                case 3:    
                    break;
                default:
                    break;
            }
        },

        preInsTkeOvr: function (){
            if(this.isTkeObrValid()){
                
                let obj = {                
                    tkeOvrPk    : this.sltTkeOvrPk,
                    clcd        : this.clcd,
                    wrtDt       : this.prtWrtDt,
                    wrtDtlDt    : (this.wrtDtlDt == '')?this.prtWrtDt:this.$moment(this.wrtDtlDt,'YYYY.MM.DD').format('YYYYMMDD'),
                    wrtTmHh     : '',
                    wrtTmMm     : '',
                    wrtr        : this.wrtr,
                    wrtrMmbrPk  : this.wrtrMmbrPk,
                    cntnt       : this.cntnt,
                }

                obj.wrtTmHh     = this.$moment(this.inqymd+' '+this.wrtTm).format('HH')
                obj.wrtTmMm     = this.$moment(this.inqymd+' '+this.wrtTm).format('mm')
    
                this.insTkeOvr(obj)
            }

        },

        preRemoveTkeOvr:function(){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '인수인계'
            obj.cntnt = ['삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });

            this.rmTkeOvrCnFm = true
        },

        //작성자 조회
        getEmpInfo: function (obj) { 
            this.wrtr = obj[0].name
            this.wrtrMmbrPk = obj[0].mmbrPk
            /* 사유 : 종일 근무자로 인해 오류 발생     
            let msg = '야간'

            if(this.clcd == '1'){
                msg = '주간'
            }
            
            if(this.clcd == '1'){
                if(obj[0].wrkOvnYn == '2' || obj[0].wrkOvnYn == null){
                    this.wrtr = obj[0].name
                    this.wrtrMmbrPk = obj[0].mmbrPk                
                }else{
                    this.onAlrt(msg+' 근무자만 선택 할 수 있습니다!')
                    this.wrtr = ''
                    this.wrtrMmbrPk = ''
                }
            }else{
                if(obj[0].wrkOvnYn == '1'){
                    this.wrtr = obj[0].name
                    this.wrtrMmbrPk = obj[0].mmbrPk                
                }else{
                    this.onAlrt(msg+' 근무자만 선택 할 수 있습니다!')
                    this.wrtr = ''
                    this.wrtrMmbrPk = ''
                }
            }
            */
        },

        isTkeObrValid:function(){
            let resrt = true

            if(this.wrtTm == '' || this.wrtTm == null) {
                this.onAlrt('작성일시을 입력해주세요!')
                resrt = false
            }

            if(resrt && (this.wrtr == '' || this.wrtr == null)) {
                this.onAlrt('작성자를 선택해주세요!')
                resrt = false
            }

            return resrt
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },

        //시간설정에 따른 날짜 변경
        setTmInspDT:function(){
            if(this.clcd == '2'){
                //new Date 안할 경우 provided is not in a recognized RFC2822 경고 발생
                let datetm = this.inqymd+' '+this.wrtTm
    
                if(this.$moment(datetm, 'YYYY.MM.DD HH:mm', true).isValid()){
                    let hh = this.$moment(this.inqymd+' '+this.wrtTm).format('HH')
    
                    //18:00~23:59 사이 값이 아닐 경우 inspDtlDT 내일로 설정
                    if(hh >= 7 && hh <= 23)
                        this.wrtDtlDt = this.inqymd
                    else 
                        this.wrtDtlDt = this.$moment(this.inqymd, 'YYYY.MM.DD').add(1, 'days').format('YYYY.MM.DD')
    
                } 
                else {
                    this.wrtDtlDt = ''
                }
            }
        },
    },

    data: () => ({
        rmTkeOvrCnFm    : false,
        prtWrtDt    : '',
        wrtDtlDt    : '',
        wrtr        : '',
        wrtrMmbrPk  : -1,
        inspAreaPk  : -1,
        wrtTm       : '', 
        cntnt       : '',
        menu        : [false],
        rules: {
            required: value => !!value || 'Required.',
        },
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
    }),
};
</script>