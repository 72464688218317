<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">        
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">급여제공&nbsp;결과평가</span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="btnDisabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :disabled="list.disabled"
                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0" style="" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">평가연도</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center" cols="1">
                    {{inqYYYY}}
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">평가일</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;"> 
                        <CmmDateComp v-model="items.wrtDt" :required="true" :minDt="minDate" :maxDt="maxDate"></CmmDateComp>                        
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="6">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>            
            </v-row>
            <v-divider color="" class="mb-3"></v-divider>
            <v-row no-gutters>
                <v-col class="pr-2" cols="4">
                    <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                        <v-col cols="6">
                            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                            </span>
                            <span class="fontOneDotTwoRem font-weight-medium ml-6">총평&#40;종합소견&#41;</span>                            
                        </v-col>
                        <v-spacer></v-spacer>                                                
                    </v-row>
                    <v-row class="pa-0 mb-2" no-gutters>
                        <v-col class="py-1 d-flex justify-center align-center posn-rltv" cols="12">
                            <v-textarea
                                v-model="items.cmnts"
                                :rules="[rules.required]" class="rmrksArea" no-resize  
                                rows="10" outlined dense hide-details>
                            </v-textarea>
                            <span style="position:absolute;bottom:9px;right:20px;">
                                <v-btn
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="28" height="28"
                                    outlined
                                    dark small
                                    @click="onCmnts()">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                        </template>
                                        <span>확대</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>   
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                        <v-col cols="6">
                            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                            </span>
                            <span class="fontOneDotTwoRem font-weight-medium ml-6">종합의견 및 향후계획</span>                            
                        </v-col>
                        <v-spacer></v-spacer>                        
                    </v-row>   
                    <v-divider color="black"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="3">
                            <span class="font-weight-medium fontNineThRem">수급자 욕구 확인</span>
                        </v-col>
                        <v-col class="px-3" cols="9">                                                          
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_1_1" :ripple="false"
                                :disabled="items.itm_204_1_1_3 == '1'"
                                @click="items.itm_204_1_1_3 = '2'"
                                true-value="1" false-value="2" label="서비스 욕구 변화있음"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_1_2" :ripple="false"
                                :disabled="items.itm_204_1_1_3 == '1'"
                                @click="items.itm_204_1_1_3 = '2'"
                                true-value="1" false-value="2" label="수급자 기능상태 변화있음"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_1_3" :ripple="false"
                                :disabled="items.itm_204_1_1_1 == '1' || items.itm_204_1_1_2 == '1'"
                                @click="items.itm_204_1_1_1 = '2', items.itm_204_1_1_2 = '2'"
                                true-value="1" false-value="2" label="변화없음"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="3">
                            <span class="font-weight-medium fontNineThRem">향후 계획</span>
                        </v-col>
                        <v-col class="px-3 py-1" cols="9">   
                            <div class="d-flex align-center">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                    v-model="items.itm_204_1_2_1" :ripple="false"
                                    :disabled="items.itm_204_1_2_2 == '1'"
                                    @click="items.itm_204_1_2_2 = '2'"
                                    true-value="1" false-value="2" label="급여제공계획 유지"
                                    on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                    hide-details>
                                </v-checkbox>
                                <span class="d-inline-block" style="width: 150px;">
                                    <v-text-field
                                        v-model="items.itm_204_1_2_1_desc"
                                        class="" height="28" color="blue"
                                        placeholder='사유'
                                        :disabled="items.itm_204_1_2_1 != '1'"
                                        hide-details outlined dense>
                                    </v-text-field>
                                </span>
                            </div>                           
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_2_2" :ripple="false"
                                :disabled="items.itm_204_1_2_1 == '1'"
                                @click="items.itm_204_1_2_1 = '2'"
                                true-value="1" false-value="2" label="욕구조사 재실시 및 급여제공계획서 재작성"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_2_3" :ripple="false"
                                true-value="1" false-value="2" label="수급자(보호자) 급여제공계획 내용 재안내"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_2_4" :ripple="false"
                                true-value="1" false-value="2" label="개인별 장기이용 계획서 재작성 요청"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_2_5" :ripple="false"
                                true-value="1" false-value="2" label="사례회의실시"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_2_6" :ripple="false"
                                true-value="1" false-value="2" label="지역사회지원 연계"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="items.itm_204_1_2_7" :ripple="false"
                                true-value="1" false-value="2" label="급여제공 종료"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <div class="d-flex align-center">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                    v-model="items.itm_204_1_2_8" :ripple="false"
                                    true-value="1" false-value="2" label="기타"
                                    on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                    hide-details>
                                </v-checkbox>  
                                <span class="d-inline-block" style="width: 250px;">
                                    <v-text-field
                                        v-model="items.itm_204_1_2_8_desc"
                                        class="" height="28" color="blue"
                                        placeholder='상세 내용'
                                        :disabled="items.itm_204_1_2_8 != '1'"
                                        hide-details outlined dense>
                                    </v-text-field>
                                </span>      
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider color=""></v-divider>   
                </v-col>
                <v-col cols="8">
                    <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                        <v-col cols="6">
                            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                            </span>
                            <span class="fontOneDotTwoRem font-weight-medium ml-6">급여제공계획</span>                            
                        </v-col>
                        <v-spacer></v-spacer>                        
                    </v-row>
                    <v-divider class="mr-2__5"  color="black"></v-divider>
                    <v-row class="nmlBfClrImp-greyE01 pa-0 mr-2__5" no-gutters>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearch"></exBtn>
                            <span class="font-weight-medium fontNineThRem">장기요양 필요영역</span>
                        </v-col>
                        <v-col class="" cols="10">
                            <v-row no-gutters>
                                <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                    <span class="fontNineThRem font-weight-medium">장기요양 세부목표</span>
                                </v-col>
                                <v-col class="pa-0 ma-0" cols="9">
                                    <v-row no-gutters>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                            <span class="fontEighthRem font-weight-medium">장기요양 필요내용</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                            <span class="fontEighthRem font-weight-medium">세부 제공내용</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                            <span class="fontEighthRem font-weight-medium">주기</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                            <span class="fontEighthRem font-weight-medium">횟수</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                            <span class="fontEighthRem font-weight-medium">시간(분)</span>
                                        </v-col>     
                                        <v-col class="txtAlgnMid py-1" cols="2">
                                            <span class="fontEighthRem font-weight-medium">작성자</span>
                                        </v-col>                                    
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-2__5"></v-divider>
                    <div v-if="bnftOfrPlnListCnt > 0" style="width: 100%; height: 540px;overflow-y:scroll;">
                        <v-progress-linear v-if="this.ldr" class="" color="cyan" indeterminate></v-progress-linear>
                        <div v-for="(pln, i) in bnftOfrPlnList" :key="i" >
                            <v-row                             
                                v-if="(srhObj != '') ? (srhObj.findIndex(x=>x == pln.clsfc2Cdnm) != -1) : true"
                                class="pa-0" 
                                no-gutters>
                                <v-col class="d-flex align-center justify-center tableBB tableBR px-1" cols="2">                                
                                    <span class="fontEighthRem font-weight-medium">{{pln.clsfc2Cdnm}}</span>
                                </v-col>
                                <v-col class="" cols="10">
                                    <v-row v-for="(list, j) in pln.list" :key="j"                                 
                                        class=""
                                        no-gutters>
                                        <v-col class="txtAlgnMid tableBB tableBR py-1" cols="3">
                                            <span class="fontEighthRem font-weight-medium">{{list.dtldGls}}</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid" cols="9">
                                            <v-row v-for="(subitm, j) in list.subList" :key="j"                                 
                                                class=""
                                                no-gutters>
                                                <v-col class="txtAlgnMid tableBB tableBR py-1" cols="3">
                                                    <span class="fontEighthRem font-weight-medium">{{subitm.clsfc3Cdnm}}</span>
                                                </v-col>
                                                <v-col class="txtAlgnMid tableBB tableBR py-1" cols="4">
                                                    <span class="fontEighthRem font-weight-medium">{{subitm.itmCdnm}}</span>
                                                </v-col>
                                                <v-col class="txtAlgnMid tableBB tableBR py-1" cols="1">
                                                    <span class="fontEighthRem font-weight-medium">{{subitm.itmVal1}}</span>
                                                </v-col>
                                                <v-col class="txtAlgnMid tableBB tableBR py-1" cols="1">
                                                    <span class="fontEighthRem font-weight-medium">{{subitm.itmVal2}}</span>
                                                </v-col>
                                                <v-col class="txtAlgnMid tableBB tableBR py-1" cols="1">
                                                    <span class="fontEighthRem font-weight-medium">{{subitm.itmVal3}}</span>
                                                </v-col>     
                                                <v-col class="txtAlgnMid tableBB py-1" cols="2">
                                                    <span class="fontEighthRem font-weight-medium">{{subitm.wrtr}}</span>
                                                </v-col>                                    
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-row v-else class="px-2" no-gutters>
                        <v-col class="txtAlgnMid py-2 tableBB" cols="12">
                            <span class="fontOneRem grey006--text">※ 급여제공계획이 존재하지 않습니다.</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>   
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <CmntsMdl v-if="dialog[1]" @isClose="dialog.splice(1, 1, false)" @outText="setDtldGls" :mdlTtl = "mdlTtl" :mdlCmnts="mdlCmnts" :hSize='30'></CmntsMdl>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import exBtn from '../commons/expandButtonCustom.vue';
import CmntsMdl from '../commons/CmntsMdl.vue';

export default {
    name: 'BnftSrvRsltEvltn',

    props : {
        inqYYYY             : { type: Number, default: 2025 },        
        bnMmbrPk            : { type: Number, default: -1 },
        bnfcrPk             : { type: Number, default: -1 },
        bnftSrvRsltEvltnPk  : { type: Number, default: -1 },
        btnDisabled         : { type: Boolean, default: false },
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        EmpSelector,
        exBtn,
        CmntsMdl,
    },
    
    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {             
        getSubEvt: function (bol) {
            if(bol && this.rmBnftSrvRsltCnFm){
                 
                this.rmBnftSrvRsltCnFm = false
                this.onDelete()   
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
            }

        },
    },

    mounted: function(){             
        this.$nextTick(function () {      
            this.getInit()
            this.getBnftSrvRsltEvltn()
        })
    },
    
    methods: {
        //급여제공결과평가 조회
        getBnftSrvRsltEvltn: function (){    
            this.ldr = true
            this.exBtn[0].expandList.splice(0)
            this.bnftOfrPlnList.splice(0)
            this.bnftOfrPlnListCnt = 0

            let obj = {
                inqYYYY         : this.inqYYYY,
                bnMmbrPk        : this.bnMmbrPk,
                bnfcrPk         : this.bnfcrPk,
                bnftOfrPlnHisPk : this.bnftSrvRsltEvltnPk
            }

            http.post('bnftoffer/selBnftSrvRsltEvltn', obj)
                .then((response) => ( this.bnftSrvRsltEvltnRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnftSrvRsltEvltn : ' + error))
        },

        //급여제공결과평가 저장
        savBnftSrvRsltEvltn:function(obj){            
            http.post('bnftoffer/insBnftSrvRsltEvltn', obj)
                .then((response) => ( this.savRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnftSrvRsltEvltn : ' + error))
        },

        onDelete:function(){
            if(this.bnftSrvRsltEvltnPk > 0){  

                let obj = {
                    bnftOfrPlnHisPk : this.bnftSrvRsltEvltnPk
                }

                http.post('bnftoffer/delBnftOfrPlnHis', obj)
                    .then((response) => ( this.delBnftOfrPlnHisAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnftoffer/selBnftSrvRsltEvltn : ' + error))
            }
        },

        bnftSrvRsltEvltnRst:function(res){
            if(res.statusCode == '200'){
                
                //급여제공계획 내역 설정
                if(res.result.plnHis != null) this.setPlnHisInfo(res.result.plnHis)

                //급역제공계획 향후계획 설정
                if(res.result.plnDtl != null) this.setSrvRsltEvltn(res.result.plnDtl)

                //급여제공계획 목록 설정
                if(res.result.plnList != null){
                    res.result.plnList.outList.forEach(el => {
                        el.visible = true
                        this.exBtn[0].expandList.push(el.clsfc2Cdnm)                
                        this.bnftOfrPlnList.push(el)
    
                        el.list.forEach(itm => {
                            this.bnftOfrPlnListCnt += itm.subList.length
                        });
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.ldr = false
        },

        savRst:function(res){
            let obj = {}    
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)   
                if(this.bnftSrvRsltEvltnPk > 0){
                    this.getInit()
                    this.getBnftSrvRsltEvltn()
                } else {
                    this.onClose()
                }                
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            this.$emit('modalEvent', 99)
            this.$store.commit('setWrnnInf', obj)
        },

        delBnftOfrPlnHisAfter:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀 

            if(res.statusCode == 200){         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)                                            
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.$store.commit('setBnftOfrPlnHisPk', '')
                obj.tmr = 5000
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        onClose: function () {                        
            this.$emit('modalEvent', 9)
        },

        getInit(){            
            let date        = new Date()                                    
            let dateStr     = date.toISOString().split('T',2)[0]
            this.minDate    = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate    = this.inqYYYY+'-'+'12'+'-'+'31'
            
            this.items.wrtDt                = dateStr
            this.items.wrtr                 = '';
            this.items.wrtrMmbrPk           = 0
            this.items.cmnts                = ''
            this.items.itm_204_1_1_pk      = 0
            this.items.itm_204_1_1_1       = ''
            this.items.itm_204_1_1_1_pk    = 0
            this.items.itm_204_1_1_2       = ''
            this.items.itm_204_1_1_2_pk    = 0
            this.items.itm_204_1_1_3       = ''
            this.items.itm_204_1_1_3_pk    = 0
            this.items.itm_204_1_2_pk      = 0
            this.items.itm_204_1_2_1       = ''
            this.items.itm_204_1_2_1_pk    = 0
            this.items.itm_204_1_2_1_desc  = ''
            this.items.itm_204_1_2_2       = ''
            this.items.itm_204_1_2_2_pk    = 0
            this.items.itm_204_1_2_3       = ''
            this.items.itm_204_1_2_3_pk    = 0
            this.items.itm_204_1_2_4       = ''
            this.items.itm_204_1_2_4_pk    = 0
            this.items.itm_204_1_2_5       = ''
            this.items.itm_204_1_2_5_pk    = 0
            this.items.itm_204_1_2_6       = ''
            this.items.itm_204_1_2_6_pk    = 0
            this.items.itm_204_1_2_7       = ''
            this.items.itm_204_1_2_7_pk    = 0
            this.items.itm_204_1_2_8       = ''
            this.items.itm_204_1_2_8_pk    = 0
            this.items.itm_204_1_2_8_desc  = ''

            this.forceRender += 1
        },

        //급여제공계획 내역 설정
        setPlnHisInfo:function(obj){
            this.items.wrtDt                = this.$moment(obj.wrtDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.items.wrtr                 = obj.wrtr
            this.items.wrtrMmbrPk           = obj.wrtrMmbrPk
            this.items.cmnts                = obj.cmnts
        },

        //급여제공계획 향후계획 설정
        setSrvRsltEvltn:function(arr){
            arr.forEach(itm => {
                if(itm.clsfc3Cd == '1') {
                    this.items.itm_204_1_1_pk = itm.bnftOfrPlnPk
                    this.setItm(itm, 1)
                } else if(itm.clsfc3Cd == '2') {
                    this.items.itm_204_1_2_pk = itm.bnftOfrPlnPk
                    this.setItm(itm, 2)
                }
            });
        },

        setItm:function(obj, typ){
            if(typ == 1){
                switch (obj.itmCd) {
                    case '1':
                        this.items.itm_204_1_1_1       = obj.itmVal
                        this.items.itm_204_1_1_1_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '2':
                        this.items.itm_204_1_1_2       = obj.itmVal
                        this.items.itm_204_1_1_2_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '3':
                        this.items.itm_204_1_1_3       = obj.itmVal
                        this.items.itm_204_1_1_3_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    default:
                        break;
                }
            }
            else if(typ == 2){
                switch (obj.itmCd) {
                    case '1':
                        this.items.itm_204_1_2_1       = obj.itmVal
                        this.items.itm_204_1_2_1_pk    = obj.bnftOfrPlnDtlPk
                        this.items.itm_204_1_2_1_desc  = obj.itmDesc
                        break;
                    case '2':
                        this.items.itm_204_1_2_2       = obj.itmVal
                        this.items.itm_204_1_2_2_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '3':
                        this.items.itm_204_1_2_3       = obj.itmVal
                        this.items.itm_204_1_2_3_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '4':
                        this.items.itm_204_1_2_4       = obj.itmVal
                        this.items.itm_204_1_2_4_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '5':
                        this.items.itm_204_1_2_5       = obj.itmVal
                        this.items.itm_204_1_2_5_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '6':
                        this.items.itm_204_1_2_6       = obj.itmVal
                        this.items.itm_204_1_2_6_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '7':
                        this.items.itm_204_1_2_7       = obj.itmVal
                        this.items.itm_204_1_2_7_pk    = obj.bnftOfrPlnDtlPk
                        break;
                    case '8':
                        this.items.itm_204_1_2_8       = obj.itmVal
                        this.items.itm_204_1_2_8_pk    = obj.bnftOfrPlnDtlPk
                        this.items.itm_204_1_2_8_desc  = obj.itmDesc
                        break;
                    default:
                        break;
                }
            }
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text=="급여제공결과 평가반영"){
                this.$parent.$parent.$parent.$parent.plnBzPk = this.bnftOfrPlnHisPk
                this.onClose()
                this.$parent.$parent.$parent.$parent.outOnSrvPln()
            } else if(text=="저장"){
                this.preBnftOfrPlnHis()                               
            } else if(text=="삭제"){
                if(this.bnftSrvRsltEvltnPk > 0){
                    let obj = {}
                    obj.icon = 'mdi-alert'
                    obj.iconClr = 'red'
                    obj.title = '급여제공 결과평가'
                    obj.cntnt = ['삭제하시겠습니까?']
                    obj.cnclTxt = '아니오'
                    obj.cnfrmTxt = '예, 삭제하겠습니다.'

                    this.$store.commit('setSubInf', {
                        actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                    });

                    this.rmBnftSrvRsltCnFm = true
                }
            }
        },

        preBnftOfrPlnHis:function(){
            if(this.isEduRecValid()){
                let obj = {                    
                    bnftOfrPlnHisPk : this.bnftSrvRsltEvltnPk,
                    bnMmbrPk        : this.bnMmbrPk,
                    wrtDt           : this.$moment(this.items.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD'),
                    wrtr            : this.items.wrtr,
                    wrtrMmbrPk      : this.items.wrtrMmbrPk,
                    cmnts           : this.items.cmnts,
                    plnYyyy         : this.items.inqYYYY,
                    aplStrt         : this.$moment(this.items.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD'),
                    aplEnd          : this.$moment(this.items.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD'),
                    mdfClcd         : '3',
                    plnClcd         : '204',
                    plnList         : [],
                }

                obj.plnList = this.setPlnList()                
                this.savBnftSrvRsltEvltn(obj)
            }
        },

        isEduRecValid:function(){
            let resrt = true

            if(resrt && (this.items.wrtDt == '' || this.items.wrtDt == null)) {
                this.onAlrt('평가일을 입력해주세요!')
                resrt = false
            }

            if(resrt && (this.items.wrtr == '' || this.items.wrtr == null)) {
                this.onAlrt('작성자을 선택해주세요!')
                resrt = false
            }

            if(resrt && (this.items.cmnts == '' || this.items.cmnts == null)) {
                this.onAlrt('총평(종합소견)을 입력해주세요!')
                resrt = false
            }

            return resrt
        },

        setPlnList:function(){
            let list = []

            //대204중1소1
            let pln204_1_1 = {                
                clsfc1Cd			: '204',            //대분류코드
                dtldGls				: null,            //세부목표
                clsfc2Cd			: '1',            //중분류코드
                clsfc2Cdnm			: '급여제공결과평가',            //중분류코드명
                clsfc3Cd			: '1',            //소분류코드
                clsfc3Cdnm			: '급여제공결과평가',            //소분류코드명
                itmVal1			    : null,            //항목값1(주기)
                itmVal2			    : null,            //항목값2(횟수)
                itmVal3			    : null,            //항목값3(시간)
                cmnts			    : null,            //의견
                bnftOfrPlnPk        : this.items.itm_204_1_1_pk,
                dtlList : []
            }

            let dtl11 = {
                itmCd   			: '1',//항목코드
                itmCdnm   			: '서비스 욕구 변화있음',//항목코드명
                itmVal   			: this.items.itm_204_1_1_1,//항목값
                itmDesc   			: null,//항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_1_1_pk
            }

            pln204_1_1.dtlList.push(dtl11)

            let dtl12 = {
                itmCd   			: '2',//항목코드
                itmCdnm   			: '수급자 기능상태 변화있음',//항목코드명
                itmVal   			: this.items.itm_204_1_1_2,//항목값
                itmDesc   			: null,//항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_1_2_pk
            }

            pln204_1_1.dtlList.push(dtl12)

            let dtl13 = {
                itmCd   			: '3',//항목코드
                itmCdnm   			: '변화없음',//항목코드명
                itmVal   			: this.items.itm_204_1_1_3,//항목값
                itmDesc   			: null,//항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_1_3_pk
            }

            pln204_1_1.dtlList.push(dtl13)

            //대204중1소1
            let pln204_1_2 = {
                clsfc1Cd			: '204',            //대분류코드
                dtldGls				: null,            //세부목표
                clsfc2Cd			: '1',            //중분류코드
                clsfc2Cdnm			: '급여제공결과평가',            //중분류코드명
                clsfc3Cd			: '2',            //소분류코드
                clsfc3Cdnm			: '향후계획',            //소분류코드명
                itmVal1			    : null,            //항목값1(주기)
                itmVal2			    : null,            //항목값2(횟수)
                itmVal3			    : null,            //항목값3(시간)
                cmnts			    : null,            //의견
                bnftOfrPlnPk        : this.items.itm_204_1_2_pk,
                dtlList : []
            }


            let dtl21 = {
                itmCd   			: '1',                              //항목코드
                itmCdnm   			: '급여제공계획 유지(사유)',        //항목코드명
                itmVal   			: this.items.itm_204_1_2_1,         //항목값
                itmDesc   			: (this.items.itm_204_1_2_1 == '1')?this.items.itm_204_1_2_1_desc:'',   //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_1_pk
            }

            pln204_1_2.dtlList.push(dtl21)

            let dtl22 = {
                itmCd   			: '2',                          //항목코드
                itmCdnm   			: '욕구조사 재실시 및 급여제공계획서 재작성',      //항목코드명
                itmVal   			: this.items.itm_204_1_2_2,     //항목값
                itmDesc   			: null,                         //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_2_pk
            }

            pln204_1_2.dtlList.push(dtl22)

            let dtl23 = {
                itmCd   			: '3',                          //항목코드
                itmCdnm   			: '수급자(보호자) 급여제공계획 내용 재안내',      //항목코드명
                itmVal   			: this.items.itm_204_1_2_3,     //항목값
                itmDesc   			: null,                         //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_3_pk
            }

            pln204_1_2.dtlList.push(dtl23)

            let dtl24 = {
                itmCd   			: '4',                          //항목코드
                itmCdnm   			: '개인별 장기이용 계획서 재작성 요청',      //항목코드명
                itmVal   			: this.items.itm_204_1_2_4,     //항목값
                itmDesc   			: null,                         //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_4_pk
            }

            pln204_1_2.dtlList.push(dtl24)

            let dtl25 = {
                itmCd   			: '5',                          //항목코드
                itmCdnm   			: '사례회의실시',      //항목코드명
                itmVal   			: this.items.itm_204_1_2_5,     //항목값
                itmDesc   			: null,                         //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_5_pk
            }

            pln204_1_2.dtlList.push(dtl25)

            let dtl26 = {
                itmCd   			: '6',                          //항목코드
                itmCdnm   			: '지역사회지원 연계',      //항목코드명
                itmVal   			: this.items.itm_204_1_2_6,     //항목값
                itmDesc   			: null,                         //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_6_pk
            }

            pln204_1_2.dtlList.push(dtl26)

            let dtl27 = {
                itmCd   			: '7',                          //항목코드
                itmCdnm   			: '급여제공 종료',      //항목코드명
                itmVal   			: this.items.itm_204_1_2_7,     //항목값
                itmDesc   			: null,                         //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_7_pk
            }

            pln204_1_2.dtlList.push(dtl27)

            let dtl28 = {
                itmCd   			: '8',                                  //항목코드
                itmCdnm   			: '기타',                               //항목코드명
                itmVal   			: this.items.itm_204_1_2_8,             //항목값
                itmDesc   			: (this.items.itm_204_1_2_8 == '1')?this.items.itm_204_1_2_8_desc:'',        //항목설명
                bnftOfrPlnDtlPk     : this.items.itm_204_1_2_8_pk
            }

            pln204_1_2.dtlList.push(dtl28)

            list.push(pln204_1_1)
            list.push(pln204_1_2)

            return list
        },
       
        getEmpInfo: function (obj) {
            this.items.wrtr         = obj[0].name
            this.items.wrtrMmbrPk   = obj[0].mmbrPk
        },
        onModal: function () {
            this.prtWrtDt = this.items.wrtDt
            this.dialog.splice(0, 1, true)
        },
        isModal: function () {
            this.dialog.splice(0, 1, false)
        },
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    break;
            }
        },
        filterSearch:function(srh){ 
            this.srhObj = srh
        },
        //총평(종합소견) 확대
        onCmnts:function(){
            this.mdlTtl     = '총평(종합소견)'
            this.mdlCmnts   = this.items.cmnts

            this.dialog.splice(1, 1, true)
        },
        //총평(종합소견) 설정
        setDtldGls:function(txt){
            this.items.cmnts = txt
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },

    },
    
    data: () => ({   
        ldr                 : false,
        minDate             : '',
        maxDate             : '',
        prtWrtDt            : '',   
        mdlTtl              : '',   
        mdlCmnts            : '',   
        items               : {
            wrtDt               : '',
            wrtr                : '',
            wrtrMmbrPk          : 0,
            cmnts               : '',
            itm_204_1_1_pk      : 0,
            itm_204_1_1_1       : '',
            itm_204_1_1_1_pk    : 0,
            itm_204_1_1_2       : '',
            itm_204_1_1_2_pk    : 0,
            itm_204_1_1_3       : '',
            itm_204_1_1_3_pk    : 0,
            itm_204_1_2_pk      : 0,
            itm_204_1_2_1       : '',
            itm_204_1_2_1_pk    : 0,
            itm_204_1_2_1_desc  : '',
            itm_204_1_2_2       : '',
            itm_204_1_2_2_pk    : 0,
            itm_204_1_2_3       : '',
            itm_204_1_2_3_pk    : 0,
            itm_204_1_2_4       : '',
            itm_204_1_2_4_pk    : 0,
            itm_204_1_2_5       : '',
            itm_204_1_2_5_pk    : 0,
            itm_204_1_2_6       : '',
            itm_204_1_2_6_pk    : 0,
            itm_204_1_2_7       : '',
            itm_204_1_2_7_pk    : 0,
            itm_204_1_2_8       : '',
            itm_204_1_2_8_pk    : 0,
            itm_204_1_2_8_desc  : '',
        },
        dialog              : [false,],        
        bnftOfrPlnList      : [], 
        bnftOfrPlnListCnt   : 0, 
        srhObj              : [],
        exBtn               : [
            {
                index:  0,
                btnTitle: '',
                btnIcon: 'listChecks',
                exWidth: 154,
                exHeigth: 300,
                expandList: [],
            },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'white--text', color:'blueBtnColor', text: '급여제공결과 평가반영', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled:true  },
        ],        
        forceRender:0,
        rmBnftSrvRsltCnFm   : false,
    }),
};
</script>