<template>
    <v-row :class="['pa-0 justify-center align-center', hrztAlign ]" no-gutters>
        <span class="text-h5 font-weight-bold topTitleColor--text mr-2" style="padding-bottom: 2px;">
            {{ $moment(sltStrtDt, 'YYYY-MM').format('YYYY년 MM월') }}
        </span>
        <CmmDatePicker
            v-model="sltStrtDt"
            @cnfDt="(v) => vmlRfltStrt(v)"
            :minDt="$moment(sltEndDt, 'YYYY-MM').subtract(11, 'month').format('YYYY-MM')" :maxDt="sltEndDt"
            :pckType="'month'" :disabled="disabled" :btnTyp="'left'">
        </CmmDatePicker>
        <span class="mx-4">
            <v-icon class="" color="topTitleColor" size="20">mdi-tilde</v-icon>
        </span>
        <span class="text-h5 font-weight-bold topTitleColor--text mr-2" style="padding-bottom: 2px;">
            {{ $moment(sltEndDt, 'YYYY-MM').format('YYYY년 MM월') }}
        </span>
        <CmmDatePicker
            v-model="sltEndDt"
            @cnfDt="(v) => vmlRfltEnd(v)"
            :minDt="sltStrtDt" :maxDt="$moment(sltStrtDt, 'YYYY-MM').add(11, 'month').format('YYYY-MM')"
            :pckType="'month'" :disabled="disabled" :btnTyp="'right'">
        </CmmDatePicker>
    </v-row>
</template>

<script>
import CmmDatePicker from './CmmDatePicker.vue';

export default {
    name: 'ExpRngMonthPicker',

    props : {
        strtValue: { type: String, default: '' },
        endValue: { type: String, default: '' },
        hrztAlign: { type: String, default: 'justify-center' },
        disabled: { type: Boolean, default: false },
    },
            
    components: {
        CmmDatePicker,
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.strtValue == '' || this.strtValue == 'Invalid date')
                this.sltStrtDt = this.$moment().format('YYYY-MM')
            else
                this.sltStrtDt = this.strtValue

            if(this.endValue == '' || this.endValue == 'Invalid date')
                this.sltEndDt = this.$moment().format('YYYY-MM')
            else
                this.sltEndDt = this.endValue

            this.emitEvt()
        })
    },

    computed: {

    },

    watch:{
        'strtValue': function () {
            if(this.strtValue == null || typeof this.strtValue == 'undefined')
                this.sltStrtDt = ''
            else
                this.sltStrtDt = this.strtValue
        
            this.emitEvt()
        },
        'endValue': function () {
            if(this.endValue == null || typeof this.endValue == 'undefined')
                this.sltEndDt = ''
            else
                this.sltEndDt = this.endValue

            this.emitEvt()
        },
    },
        
    methods: {
        vmlRfltStrt: function (v) {
            this.sltStrtDt = v
            this.emitEvt()
        },
        vmlRfltEnd: function (v) {
            this.sltEndDt = v
            this.emitEvt()
        },
        emitEvt: function () {
            let obj = {}
            obj.strtYYMM = this.$moment(this.sltStrtDt, 'YYYY-MM').format('YYYYMM')
            obj.endYYMM = this.$moment(this.sltEndDt, 'YYYY-MM').format('YYYYMM')

            this.$emit('chngDtRng', obj)
        },
    },
    
    data: () => ({
        sltStrtDt: '',
        sltEndDt: '',
        inRules: {
            birthCheck: value => /^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])$/.test(value) || 'different',
        },
    }),
};
</script>