<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">그룹별 프로그램</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>       
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기본정보</span>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">수급자 그룹</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ gInf.bnfcrgrNm }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid pb-1" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ gInf.idxCdNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">설명</span>
            </v-col>
            <v-col class="overflow-text-hidden px-3 pb-1" style="padding-top: 3px;" cols="10">
                <span class="fontOneRem">{{ gInf.bnfcrgrDesc }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">비고</span>
            </v-col>
            <v-col class="overflow-text-hidden px-3 pb-1" style="padding-top: 3px;" cols="10">
                <span class="fontOneRem">{{ sltInf.rmrks }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 목록</span>
        </v-row>        
        <v-divider :class="[bnfcrPrgList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01', bnfcrPrgList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="1">
                <span class="font-weight-medium">연번</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">프로그램 구분</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">프로그램 유형</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="5">
                <span class="font-weight-medium">프로그램명</span>
            </v-col>
            <v-col class="txtAlgnMid pt-1" cols="2">
                <span class="font-weight-medium">설정일자</span>
            </v-col>
        </v-row>
        <v-divider :class="[bnfcrPrgList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="bnfcrPrgList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', bnfcrPrgList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in bnfcrPrgList" :key="i"
                no-gutters>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span class="fontOneRem">{{ itm.rn }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontOneRem">{{ itm.prgTypNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontOneRem">{{ itm.idxCdNm }}</span>
                </v-col>                
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1 px-2" cols="5">
                    <span class="fontOneRem">{{ itm.prgNm }}</span>
                </v-col>    
                <v-col class="txtAlgnMid py-1" cols="2">
                    <span class="fontOneRem">{{ itm.wrtDtDot }}</span>
                </v-col>            
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 프로그램 수급자 그룹이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'BnfcrPrgmLogViw',
    
    components: {
        
    },

    props : { 
        gInf : { type: Object, default: new Object() },
        sltInf : { type: Object, default: new Object() },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {     
            this.getBnfcrgrPrgList()
        })
    },

    computed: {
       
    },

    watch: {
        
    },
        
    methods: {
        //팝업 닫기
        onClose: function () {
            this.$emit('isClose')
        },    
        
        // 수급자 그룹별 프로그램관리 목록 조회
        getBnfcrgrPrgList: function (){
            this.bnfcrPrgList.splice(0)

            let obj = {}
            obj.prgBnfcrgrInfPk = this.sltInf.prgBnfcrgrInfPk
            obj.inqYMD          = this.sltInf.wrtDt

            http.post('prgrm/selBnfcrgrPrgList', obj)
                .then((response) => ( this.BnfcrgrPrgListRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selBnfcrgrPrgList : ' + error))
        },

        BnfcrgrPrgListRst:function(res){         
            if(res.statusCode == '200') {
                res.result.list.forEach(itm => {
                    itm.wrtDtDot = this.$moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')

                    if(res.result.codes.vCd40 != null && res.result.codes.vCd40 != undefined){
                        let idx = res.result.codes.vCd40.findIndex( v => v.valcd == itm.idxCd )
                        if(idx > -1)
                            itm.idxCdNm = res.result.codes.vCd40[idx].valcdnm
                    }

                });
                this.bnfcrPrgList = res.result.list
            }
        },
    },

    data: () => ({        
        bnfcrPrgList : [],
    }),
};
</script>