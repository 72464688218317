<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+180" :max-width="areaWidth+180">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <span class="text-h5 font-weight-bold">{{ $moment(sltDate, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
            <v-spacer></v-spacer>            
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color" v-if="list.visible"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <div v-if="this.$parent.$parent.oldYn">
                <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                    <v-btn :disabled="list.disabled"
                        :class="list.class" @click = "onPrtBtn()"
                        samll height="30" :color="list.color" rounded outlined>
                        <v-icon small>{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>  
            </div>
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
            <div v-if="tab==0 && this.$parent.$parent.oldYn" class="" style="position:relative;">
                <div class="d-flex align-center" style="position: absolute; top: 8px; left: 866px; z-index: 1;">
                    <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                    <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;양호&#44;&nbsp;&nbsp;</span>
                    <v-icon small>mdi-triangle-outline</v-icon>
                    <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;보통&#44;&nbsp;&nbsp;</span>
                    <v-icon small>mdi-close</v-icon>
                    <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;불량</span>
                </div>
            </div>
            <div v-if="this.$parent.$parent.oldYn">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
    
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
    
                <v-tabs-items v-model="tab">
                    <v-tab-item transition="fade-transition">
                        <NghChcLog v-if="tab==0" ref="nghChcLog"></NghChcLog>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <NghWrkLog v-if="tab==1" ref="nghWrkLog"></NghWrkLog>
                    </v-tab-item>
                </v-tabs-items>
            </div>
            <!-- 2025년 이후 -->
            <div v-else>
                <v-tabs
                    v-model="ntab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in ntabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
    
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
    
                <v-tabs-items v-model="ntab">
                    <v-tab-item transition="fade-transition">
                        <NghChcNLog v-if="ntab==0" ref="nghChcNLog"></NghChcNLog>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <DayTakeLog v-if="ntab==1" ref="dayTakeLog"></DayTakeLog>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <NghTakeLog v-if="ntab==2" ref="nghTakeLog"></NghTakeLog>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <v-dialog v-model="menu[0]" max-width="500">    
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="800">    
            <InspAreaMdl v-if="menu[1]" @isClose="reload(1)"></InspAreaMdl>  
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="900">    
            <InspItmMdl v-if="menu[2]" @isClose="reload(2)"></InspItmMdl>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import NghChcLog from '../../components/sfty/NghChcLog.vue';
import NghWrkLog from '../../components/sfty/NghWrkLog.vue';

import NghChcNLog from '../../components/sfty/NghChcNLog.vue'; //2025년 이후 점검일지
import DayTakeLog from '../../components/sfty/DayTakeLog.vue'; //2025년 이후 인계일지(주간->야간)
import NghTakeLog from '../../components/sfty/NghTakeLog.vue'; //2025년 이후 인계일지(야간->주간)
import InspAreaMdl from '../../components/sfty/InspAreaMdl.vue'; //야간구역관리
import InspItmMdl from '../../components/sfty/InspItmMdl.vue'; //야간점검항목관리

export default {
    name: 'NtyInspJrnls',
    
    components: {
        btnModalDelete,
        NghChcLog,
        NghWrkLog,
        NghChcNLog,
        DayTakeLog,
        NghTakeLog,
        InspAreaMdl,
        InspItmMdl,
    },

    props : {

    },

    created: function(){
        for (let index = 0; index < this.outlinedBtn.length; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)

            this.sltDate = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        })
    },

    computed: {
        getStrtDate: function () {
            return this.$parent.$parent.date.startDay
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
    },  

    watch: {
        getStrtDate: function (value) {

            let fDate = new Date(value.beforeDateFormatHyp())
            let nDate = new Date(this.$parent.$parent.sltDate.beforeDateFormatHyp())
            let lDate = new Date(this.$parent.$parent.date.endDay.beforeDateFormatHyp())

            if(fDate <= nDate && nDate <= lDate) this.$parent.$parent.sltDate = this.sltDate
            else {
                fDate.setDate(fDate.getDate()+3)
                this.sltDate = fDate.toISOString().split('T',2)[0].afterDateFormatHyp()
            }

            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            if(this.$parent.$parent.oldYn){
                this.btnVisible(0, true)
                this.btnVisible(1, false)
                this.btnVisible(2, false)
                this.btnVisible(3, true)
            } else {
                this.btnVisible(0, false)
                this.btnVisible(1, true)
                this.btnVisible(2, true)
                this.btnVisible(3, false)
            }
        },
        getSltInspRecPk: function (value) {           
            if(value == 0){
                this.btnControll(3, true, 'fill')
                this.btnControll(0, true, 'out')
                this.tabItems[1].enter = true
                this.tab = 0
            }
            else if(value != 0){
                this.btnControll(3, false, 'fill')
                this.btnControll(0, false, 'out')
                this.tabItems[1].enter = false
            }
        },
        getSelectedItem: function (value) {
            if(value == -1)
                this.btnControll(0, true, 'fill')
            else{
                this.btnControll(0, false, 'fill')
            }
        },
    },

    methods: {
        getDate: function (value) {
            this.$parent.$parent.sltDate = value.date
            this.sltDate = value.date

            let fIndex = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == value.date.beforeDateFormatDot())
            if(fIndex != -1){
                let pkkk = this.$parent.$parent.sftyList[fIndex].inspRecPk

                if(pkkk == null)
                    this.$parent.$parent.sltInspRecPk = 0
                else
                    this.$parent.$parent.sltInspRecPk = pkkk

                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }
            else{
                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }

            let nDate = new Date(value.date.beforeDateFormatHyp())
            nDate.setDate(nDate.getDate() + 1)

            this.nextDate = nDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        },
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //저장
                    if(this.tab == 0)
                        this.$refs.nghChcLog.preInspRecDtllist()
                    else if(this.tab == 1)
                        this.$refs.nghWrkLog.preInspRec()
                    break;
                case 1:     //저장
                    this.menu.splice(1,1, true)
                    break;
                case 2:     //저장
                    this.menu.splice(2,1, true)
                    break;
                case 3:     //삭제
                    this.delTitle = '야간점검일지'
                    this.menu.splice(0, 1, true)
                    break;
                default:
                    break;
            }
        },
        //야간점검(근무)일지
        onPrtBtn:function(){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = null            
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '65'
            obj.inqYYYY = null
            obj.inqYmdStart = this.sltDate
            obj.inqYmdEnd = this.sltDate
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        delModalAct: function (res) {
            this.delTitle = ''
            if(res == 9990){    //OK
                this.$refs.nghChcLog.removeInspRecDtl()

                this.menu.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(0, 1, false)
        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        btnVisible: function (index, vis) {
            this.filledBtn[index].visible = vis
        },
        onLoad: function () {            
            // this.$refs.nghChcLog.getInspRecDtllist()
            // this.$refs.nghWrkLog.getInspRecInfo()
        },
        clearForm: function () {
            // this.$refs.nghChcLog.clearForm()
            // this.$refs.nghWrkLog.clearForm()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        //새로고침
        reload:function(key){
            this.menu.splice(key, 1, false)
            switch (this.ntab) {
                case 0:
                    this.$refs.nghChcNLog.gelInspRecDtlSttlist()
                    break;
            
                default:
                    break;
            }
        },
        //출력
        prtNtyInspJrnls:function(prtcd){
            this.prtThrowObj.fcltyNum   = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYmdStart   = this.sltDate
            this.prtThrowObj.inqYmdEnd    = this.sltDate
            this.prtThrowObj.prtCd      = prtcd
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        }
    },
    data: () => ({
        tab: 0,
        ntab: 0,
        menu: [false,false,false],
        dialog: [],
        delTitle: '',
        sltDate: '',
        nextDate: '',
        areaWidth: 708,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, visible: true,},
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '구역관리', disabled: false, visible: false,},
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '항목관리', disabled: false, visible: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, visible: true,},
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        tabItems: [
            { index: 0, tab: '야간점검일지', class: 'fontOneDotTwoRem px-10', enter: false, on:true},
            { index: 1, tab: '야간근무일지', class: 'fontOneDotTwoRem px-10', enter: true, on:true },
        ],
        ntabItems: [
            { index: 0, tab: '야간점검일지', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '인계일지(주간->야간)', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 2, tab: '인계일지(야간->주간)', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        snackOptions: [false, 3000, '', 'info'],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 1, prtCd: '65', inqYYYY: '', inqYmdStart:'',  inqYmdEnd:'',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>