<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">전자서명</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-3 justify-end" no-gutters>
            <v-btn
                v-for="(list, i) in filledBtn" :key="i"
                :class="list.class" :color="list.color"
                style="font-size: 16px;" height="26"
                @click="sltAction(list.text)" rounded>
                <v-icon class="mr-1" size="18">{{ list.icon }}</v-icon>
                <span>{{ list.text }}</span>
            </v-btn>
        </v-row>
        <div>
            <vue-esign
                ref="esign"
                class="mt-4 rounded" style="border: 3px solid #4bc5e8;" format="image/png"
                :width="400" :height="300" :lineWidth="lineWidth" :lineColor="lineColor"
                :bgColor.sync="bgColor" :isCrop="isCrop">
            </vue-esign>
        </div>
    </v-sheet>
</template>

<script>
import { uploadSIgn } from '../../api/index.js';

export default {
    name: 'SignModel',

    props : {

    },
            
    components: {
    
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getTmpObj
            this.mmbrPk = obj.mmbrPk
            this.bzClcdPk = obj.bzClcdPk
            this.eSigClcd = obj.eSigClcd
        })
    },
        
    methods: {
        setSign: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.mmbrPk = this.mmbrPk
            obj.bzClcdPk = this.bzClcdPk
            obj.eSigClcd = this.eSigClcd
            obj.sign = this.sign
            obj.userNm = this.$store.getters.getUserNm
         
            uploadSIgn(obj)
                .then((response) => ( this.afterSetSign(response.data) ))
                .catch((error) => console.log('connect error /cmm/insSign : ' + error))
        },

        afterSetSign: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                this.onClose()
                this.$emit('reload', this.eSigClcd)
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning' 
            }

            this.$store.commit('setWrnnInf', obj)
        },

        sltAction: function (text) {
            if(text == '저장')
                this.handleGenerate()
            else if(text == '다시쓰기')
                this.handleReset()  
        },

        handleReset: function () {
            this.$refs.esign.reset()
        },

        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.setSign()
            }).catch(err => {
                alert(err)
            })
        },
        
        onClose: function () {
            this.handleReset()
            this.$emit('modalClose', this.eSigClcd)
        },
    },
    
    data: () => ({
        mmbrPk: -1,
        bzClcdPk: -1,
        eSigClcd : '',
        sign: '',
        lineWidth: 8,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', },
            { icon: 'mdi-eraser', class: 'ml-2 white--text pr-5', color:'#aaa', text: '다시쓰기', },
        ],
    }),
};
</script>