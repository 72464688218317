<template>
    <v-sheet>        
        <div class="d-flex align-center mt-2" style="width:1150px;">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">투약상황기록</span>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="onMdctnLvnRm"
                small outlined>
                <v-icon size="14">mdi-clipboard-text-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">생활실별 투약관리</span>                
            </v-btn>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="onMdctnPrscr"
                small outlined>
                <v-icon size="14">mdi-clipboard-text-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">투약처방</span>                
            </v-btn>
            <!-- <v-btn v-if="getPreData"
                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="getPreDoseRecListHis"
                small outlined>
                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
            </v-btn> -->

            <v-btn 
                class="ml-1" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="onModal(0,'1')" small outlined>
                <v-icon size="14">mdi-plus-circle-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">의약품 추가</span>
            </v-btn>

            <v-btn 
                class="ml-1" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="$parent.$parent.$parent.$parent.prtPyrprRcrd(2)" small outlined>
                <v-icon size="14">mdi-printer-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">출력</span>
            </v-btn>
        </div>
        <v-form ref="tabform1" lazy-validation>
            <div :class="['mt-3 fontNineThRem',doseReclist.length > 7 ? 'mr-2__5' : '']">
                <table class="mdctntbl">
                    <thead>
                        <tr>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR" colspan="3" rowspan="2">제공자</th> 
                            <th class="py-1 nmlBfClrImp-greyE01 tableBB tableBR" colspan="2">
                                <span v-if="dummyHisInfo.wrtrGr[0] !='' || dummyHisInfo.wrtrGr[1] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(0)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-eraser</v-icon>
                                </v-btn>
                            </th>
                            <th class="py-1 nmlBfClrImp-greyE01 tableBB tableBR" colspan="2">
                                <span v-if="dummyHisInfo.wrtrGr[2] !='' || dummyHisInfo.wrtrGr[3] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(1)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-eraser</v-icon>
                                </v-btn>
                            </th>
                            <th class="py-1 nmlBfClrImp-greyE01 tableBB tableBR" colspan="2">
                                <span v-if="dummyHisInfo.wrtrGr[4] !='' || dummyHisInfo.wrtrGr[5] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>                            
                                <v-btn icon @click="dsReset(2)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-eraser</v-icon>
                                </v-btn>
                            </th>
                            <th class="py-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span v-if="dummyHisInfo.wrtrGr[6] !=''" class="black--text fontEighthRem font-weight-medium">완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(3)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-eraser</v-icon>
                                </v-btn>
                            </th>
                            <th class="py-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span v-if="dummyHisInfo.wrtrGr[7] !=''" class="black--text fontEighthRem font-weight-medium">완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(4)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-eraser</v-icon>
                                </v-btn>
                            </th>
                            <th class="nmlBfClrImp-greyE01 tableBB" style="width:18%" rowspan="4">잔여량</th>
                        </tr>
                        <tr>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[0]" :key="forceRender" @click="onEmpModel(0)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span>
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[1]" :key="forceRender" @click="onEmpModel(1)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span>
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[2]" :key="forceRender" @click="onEmpModel(2)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span> 
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[3]" :key="forceRender" @click="onEmpModel(3)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span>
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[4]" :key="forceRender" @click="onEmpModel(4)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span> 
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[5]" :key="forceRender"  @click="onEmpModel(5)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span>  
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem" color="blue" height="28" ref="info"
                                        v-model="hisInfo.wrtrGr[6]" :key="forceRender" @click="onEmpModel(6)"
                                        placeholder="제공자"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span>   
                            </th>
                            <th class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field
                                        class="mdctnField fontEighthRem"
                                        v-model="hisInfo.wrtrGr[7]" :key="forceRender" @click="onEmpModel(7)"
                                        placeholder="제공자"
                                        color="blue" height="28" ref="info"
                                        hide-details outlined dense readonly>
                                    </v-text-field>
                                </span> 
                            </th>
                        </tr>
                        <tr>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR" style="width:22%" rowspan="2">
                                <div>투약명</div>
                                <div>&#40;투약방법&#41;</div>
                            </th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR" style="width:6%" rowspan="2">총량</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR" style="width:6%" rowspan="2">
                                <div>
                                    <div>투약량</div>
                                    <div>&#40;1회&#41;</div>
                                </div>
                            </th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">아침식전</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">아침식후</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">점심식전</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">점심식후</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">저녁식전</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">저녁식후</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">취침전</th>
                            <th class="nmlBfClrImp-greyE01 tableBB tableBR fontEighthRem" style="width:6%">필요시</th>
                        </tr>
                        <tr>                        
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;">
                                    <CmmTimeField v-model="hisInfo.dsBrkfsBfTm"></CmmTimeField>
                                </span>
                            </th>
                            <th class="pa-1 tableBB tableBR">                                
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsBrkfsAfTm"></CmmTimeField></span>
                            </th>
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsLunchBfTm"></CmmTimeField></span>
                            </th>
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsLunchAfTm"></CmmTimeField></span>
                            </th>
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsDinerBfTm"></CmmTimeField></span>
                            </th>
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsDinerAfTm"></CmmTimeField></span>
                            </th>
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsBedBfTm"></CmmTimeField></span>
                            </th>
                            <th class="pa-1 tableBB tableBR">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsNeedTm"></CmmTimeField></span>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="fontNineThRem overflow-y-auto" style="height:423px;">
                <table v-if="doseReclist.length > 0" class="mdctntbl">
                    <tr v-for="(list, k) in doseReclist" :key="k">
                        <td class="pa-1 tableBB tableBR " style="width:22%">
                            <v-row no-gutters>
                                <span class="fontOneDotOneRem font-weight-medium">{{list.drugNm}}</span>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="d-flex justify-left align-center" cols="4">&#40;{{list.drugMthd}}&#41;</v-col>
                                <v-col class="d-flex justify-left align-center" cols="4">
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.injctYn" label="주사" true-value="1" false-value="2" hide-details></v-checkbox>
                                </v-col>
                                <v-col class="d-flex justify-left align-center" cols="4">
                                    <v-chip v-if="list.doseRecPk == null"
                                        class="fontNineThRem"
                                        color="red"
                                        text-color="white"
                                        small
                                        >
                                        투약전
                                    </v-chip>                                    
                                </v-col>
                            </v-row>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">    
                            <span v-if="oldRecYn">

                            </span>
                            <span v-else>
                                <v-text-field v-model="list.totDoseAmt" :disabled="list.doseInfPk > 0" color="blue" height="28" hide-details outlined dense></v-text-field>
                            </span>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <span v-if="oldRecYn">                                
                                <v-text-field v-model="list.drugAmt" color="blue" height="28" hide-details outlined dense></v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field v-model="list.snglDoseAmt" color="blue" height="28" hide-details outlined dense></v-text-field>
                            </span>
                        </td>                       
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsBrkfsBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsBrkfsAf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsLunchBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsLunchAf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsDinerBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsDinerAf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsBedBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB tableBR" style="width:6%">
                            <div class="d-flex justify-center align-center">
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="list.dsNeed" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </td>
                        <td class="pa-1 tableBB posn-rltv" style="width:18%">
                            <v-row no-gutters>
                                <v-col class="pa-0 ma-0 d-flex align-center" cols="3">                                    
                                    <span :class="['pl-4', list.rmnDoseAmt < 0?'red--text font-weight-medium':'']">
                                        {{ list.rmnDoseAmt?.toLocaleString('ko-KR')}}
                                    </span>
                                </v-col>         
                                <v-col class="pa-0 ma-0 d-flex align-center" cols="3">
                                    <v-btn icon color='grey006' @click="delList(k,'subRec1')">                                         
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="18" v-if="list.doseRecPk > 0">mdi-trash-can-outline</v-icon>                                           
                                                    <v-icon size="18" v-else>mdi-minus-circle-outline</v-icon>
                                                </div>
                                            </template>
                                            <span>{{list.drugNm}} 투약 기록 삭제</span>
                                        </v-tooltip>
                                    </v-btn> 
                                </v-col>                       
                                <v-col class="pa-0 ma-0 d-flex align-center" cols="6">                                    
                                    <v-btn 
                                        class="ml-1 white--text" style="padding: 13px 10px 12px 10px !important"
                                        color="blueBtnColor"
                                        @click="onDoseRmn(list.doseInfPk)"
                                        min-width="30" height="25" samll>
                                        <v-icon size="14" v-if="list.rmnAdjstYn=='1'">mdi-check-bold</v-icon>
                                        <v-icon size="14" v-else>mdi-checkbox-blank-outline</v-icon>
                                        <span class="fontOneRem ml-1" style="padding-bottom: 2px;">잔량조정</span>
                                    </v-btn>
                                </v-col>
                            </v-row>                                      
                        </td>
                    </tr>
                </table>
                <table v-else class="mdctntbl">
                    <tr>
                        <td class="pa-1 tableBB txtAlgnMidImp">
                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div :class="['fontNineThRem',doseReclist.length > 7 ? 'mr-2__5' : '']">
                <table class="mdctntbl">
                    <tr>
                        <td class="pa-1 nmlBfClrImp-greyE01 tableBB tableBR txtAlgnMidImp" style="width:34%">
                            <span class="black--text font-weight-medium">비고</span>
                        </td>
                        <td class="pa-1 tableBB" style="width:66%">
                            <v-text-field v-model="hisInfo.cntnt" color="blue" height="28" hide-details outlined dense></v-text-field>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="black--text">※ 투약처방 외 의약품 추가건은 잔량이 0 이오니 총량과 투약량을 맞춰서 입력바랍니다.</div>
        </v-form>        
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1100">    
                <drugInfMgmt @outDrugInf="addDrugInf" @isClose="isModal"></drugInfMgmt>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[2]" content-class="round" persistent max-width="1500">
                <MdctnMgmtLvnRmMdl 
                    :mdctnLvnRmMdlPk="mdctnLvnRmMdlPk"
                    :inqYMD="$parent.$parent.$parent.$parent.inqYMD"
                    :lvnRmInfPk="lvnRmInfPk"
                    @modalEvent="reLoad"
                    @isClose="isModal(2)">
                </MdctnMgmtLvnRmMdl>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[3]" content-class="round" persistent max-width="800">
                <DoseRmnMdl v-if="dialog[3]"                     
                    :inqYMD="$parent.$parent.$parent.$parent.inqYMD"
                    :bnName="$parent.$parent.$parent.$parent.bnMmbrNm"
                    :bnMmbrPk="this.$parent.$parent.$parent.$parent.bnMmbrPk"
                    :doseInfPk="sltDosePk"
                    @isClose="isModal(3)">
                </DoseRmnMdl>
            </v-dialog>  
        </div>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';
import { selDoseInfList, insDoseRecMulti, delDoseRec, delDoseRecHis } from '../../api/bnftrcrd.js';

import CmmTimeField from '../commons/CmmTimeField.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import drugInfMgmt from '../../components/commons/DrugInfMgmt.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import MdctnMgmtLvnRmMdl from './MdctnMgmtLvnRmMdl.vue';
import DoseRmnMdl from './DoseRmnMdl.vue';


export default {
    name: 'MdctnMgmtTab',

    components: {
        CmmTimeField,
        btnModalDelete,
        drugInfMgmt,
        EmpSelector,
        MdctnMgmtLvnRmMdl,
        DoseRmnMdl,
    },

    props : {
        lvnRmInfPk:{type:Number, default:0},
    },

    created: function(){    
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           this.inqYMD = this.$parent.$parent.$parent.$parent.inqYMD
           this.oldRecYn =  this.$moment(this.inqYMD ,'YYYYMMDD').isBefore('2025-01-01')           
        })
    },

    computed: {
        getYmd:function(){
            return this.$parent.$parent.$parent.$parent.inqYMD
        }
    },  

    watch: {
        'getYmd':function(value){
           this.inqYMD = value 
           this.oldRecYn =  this.$moment(this.inqYMD ,'YYYYMMDD').isBefore('2025-01-01')
        }
    },

    methods: {   
        onLoad:function(){            
            this.frmReset()   
            this.getDoseRecListHis()         
        },
        reLoad:function(){
            this.frmReset()
            this.getDoseRecListHis()
        },
        frmReset:function(){
            this.empidx =0
            this.idx = 0
            this.type = ''
            this.addType = ''
            this.delTitle = '투약관리'
            
            this.hisInfo = Object.assign({bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',}) 
            this.dummyHisInfo = Object.assign({bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',}) 

            this.doseReclist.splice(0)
            this.$refs.tabform1.resetValidation();            
        },
        dsReset:function(key){
            if(key==0){
                this.hisInfo.wrtrGr.splice(0, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(0, 1, '')
                this.hisInfo.wrtrGr.splice(1, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(1, 1, '')
                this.hisInfo.dsBrkfsBfTm = ''
                this.hisInfo.dsBrkfsAfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsBrkfsBf = "2"   
                    e.dsBrkfsAf = "2"
                })
            }else if(key==1){
                this.hisInfo.wrtrGr.splice(2, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(2, 1, '')
                this.hisInfo.wrtrGr.splice(3, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(3, 1, '')
                this.hisInfo.dsLunchBfTm = ''
                this.hisInfo.dsLunchAfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsLunchBf = "2"   
                    e.dsLunchAf = "2"
                })
            }else if(key==2){
                this.hisInfo.wrtrGr.splice(4, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(4, 1, '')
                this.hisInfo.wrtrGr.splice(5, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(5, 1, '')
                this.hisInfo.dsDinerBfTm = ''
                this.hisInfo.dsDinerAfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsDinerBf = "2"   
                    e.dsDinerAf = "2"
                })
            }else if(key==3){
                this.hisInfo.wrtrGr.splice(6, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(6, 1, '')
                this.hisInfo.dsBedBfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsBedBf = "2"   
                })
            }else if(key==4){
                this.hisInfo.wrtrGr.splice(7, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(7, 1, '')
                this.hisInfo.dsNeedTm = ''
                this.doseReclist.forEach(e => {
                    e.dsNeed = "2"   
                })
            }
            
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm            
            let gInqYMD = this.$parent.$parent.$parent.$parent.inqYMD

            let valid = this.isMdctnValid()
            

            if(gBnMmbrPk > 0 && valid){
                if(this.$refs.tabform1.validate()){
                    let obj = {    
                        bnftOfrRecHisPk:this.hisInfo.bnftOfrRecHisPk,
                        fcltyNum:gFcltyNum,
                        bnMmbrPk:gBnMmbrPk,
                        userNm:gUserNm,
                        lvnRmInfPk  : this.lvnRmInfPk,
                        recDt:gInqYMD,
                        wrtrGr:this.hisInfo.wrtrGr,
                        wrtrGrMmbrPk:this.hisInfo.wrtrGrMmbrPk,
                        wrtrMmbrPk1 : this.hisInfo.wrtrGrMmbrPk[0],
                        wrtrMmbrPk2 : this.hisInfo.wrtrGrMmbrPk[1],
                        wrtrMmbrPk3 : this.hisInfo.wrtrGrMmbrPk[2],
                        wrtrMmbrPk4 : this.hisInfo.wrtrGrMmbrPk[3],
                        wrtrMmbrPk5 : this.hisInfo.wrtrGrMmbrPk[4],
                        wrtrMmbrPk6 : this.hisInfo.wrtrGrMmbrPk[5],
                        wrtrMmbrPk7 : this.hisInfo.wrtrGrMmbrPk[6],
                        wrtrMmbrPk8 : this.hisInfo.wrtrGrMmbrPk[7], 
                        brkfsTmHh   : '',
                        brkfsTmMm   : '',
                        lunchTmHh   : '',
                        lunchTmMm   : '',
                        dinerTmHh   : '',
                        dinerTmMm   : '',
                        bedTmHh     : '',
                        bedTmMm     : '',
                        needTmHh    : '',
                        needTmMm    : '',                       
                        cntnt:this.hisInfo.cntnt,
                        list:[],
                    }

                    this.doseReclist.forEach(e => {
                        
                        let item = {
                            drugNm		: e.drugNm,   		//투약명
                            drugAmt		: e.drugAmt,   	    //투약량
                            drugMthd	: e.drugMthd,   	    //투약방법
                            dsBrkfsBf	: e.dsBrkfsBf,   	    //아침투약여부_식전
                            dsBrkfsAf	: e.dsBrkfsAf,  	    //아침투약여부_식후
                            dsLunchBf	: e.dsLunchBf,   	    //점심투약여부_식전
                            dsLunchAf	: e.dsLunchAf,  	    //점심투약여부_식후
                            dsDinerBf	: e.dsDinerBf,   	    //저녁투약여부_식전
                            dsDinerAf	: e.dsDinerAf,  	    //저녁투약여부_식후
                            dsBedBf		: e.dsBedBf,   	    //취침전투약여부
                            dsNeed		: e.dsNeed,   		//필요시투약여부
                            brkfsTmHh	: e.brkfsTmHh,           //아침투약시간_시
                            brkfsTmMm	: e.brkfsTmMm,           //아침투약시간_분
                            lunchTmHh	: e.lunchTmHh,           //점심투약시간_시
                            lunchTmMm	: e.lunchTmMm,           //점심투약시간_분
                            dinerTmHh	: e.dinerTmHh,           //저녁투약시간_시
                            dinerTmMm	: e.dinerTmMm,           //저녁투약시간_분
                            bedTmHh		: e.bedTmHh,           //취침전투약시간_시
                            bedTmMm		: e.bedTmMm,           //취침전투약시간_분
                            needTmHh	: e.needTmHh,           //필요시투약시간_시
                            needTmMm	: e.needTmMm,           //필요시투약시간_분
                            totDoseAmt	: e.totDoseAmt,           //총량
                            snglDoseAmt	: e.snglDoseAmt,           //1회 투약량
                            injctYn		: e.injctYn,   		//주사
                            wrtr		: e.wrtr,   		//작성자
                            doseInfPk	: e.doseInfPk,
                            doseRecPk	: e.doseRecPk,
                        }

                        item.fcltyNum = gFcltyNum
                        item.bnMmbrPk = gBnMmbrPk
                        item.userNm = gUserNm
                        item.doseDt = gInqYMD

                        //아침
                        if(e.dsBrkfsBf == "1"){
                            if(this.hisInfo.dsBrkfsBfTm?.isEmptyVal()){
                                let dsBrkfsBfTm =  this.hisInfo.dsBrkfsBfTm.split(":")
                                item.brkfsTmHh = dsBrkfsBfTm[0]
                                item.brkfsTmMm = dsBrkfsBfTm[1]
                                obj.brkfsTmHh = dsBrkfsBfTm[0]
                                obj.brkfsTmMm = dsBrkfsBfTm[1]
                            }
                        }

                        if(e.dsBrkfsAf == "1"){
                            if(this.hisInfo.dsBrkfsAfTm?.isEmptyVal()){
                                let dsBrkfsAfTm =  this.hisInfo.dsBrkfsAfTm.split(":")
                                item.brkfsTmHh = dsBrkfsAfTm[0]
                                item.brkfsTmMm = dsBrkfsAfTm[1]
                                obj.brkfsTmHh = dsBrkfsAfTm[0]
                                obj.brkfsTmMm = dsBrkfsAfTm[1]
                            }
                        }

                        //점심
                        if(e.dsLunchBf == "1"){
                            if(this.hisInfo.dsLunchBfTm?.isEmptyVal()){
                                let dsLunchBfTm =  this.hisInfo.dsLunchBfTm.split(":")
                                item.lunchTmHh = dsLunchBfTm[0]
                                item.lunchTmMm = dsLunchBfTm[1]
                                obj.lunchTmHh = dsLunchBfTm[0]
                                obj.lunchTmMm = dsLunchBfTm[1]
                            }
                        }

                        if(e.dsLunchAf == "1"){
                            if(this.hisInfo.dsLunchAfTm?.isEmptyVal()){
                                let dsLunchAfTm =  this.hisInfo.dsLunchAfTm.split(":")
                                item.lunchTmHh = dsLunchAfTm[0]
                                item.lunchTmMm = dsLunchAfTm[1]
                                obj.lunchTmHh = dsLunchAfTm[0]
                                obj.lunchTmMm = dsLunchAfTm[1]
                            }
                        }

                        //저녁
                        if(e.dsDinerBf == "1"){
                            if(this.hisInfo.dsDinerBfTm?.isEmptyVal()){
                                let dsDinerBfTm =  this.hisInfo.dsDinerBfTm.split(":")
                                item.dinerTmHh = dsDinerBfTm[0]
                                item.dinerTmMm = dsDinerBfTm[1]
                                obj.dinerTmHh = dsDinerBfTm[0]
                                obj.dinerTmMm = dsDinerBfTm[1]
                            }
                        }

                        if(e.dsDinerAf == "1"){
                            if(this.hisInfo.dsDinerAfTm?.isEmptyVal()){
                                let dsDinerAfTm =  this.hisInfo.dsDinerAfTm.split(":")
                                item.dinerTmHh = dsDinerAfTm[0]
                                item.dinerTmMm = dsDinerAfTm[1]
                                obj.dinerTmHh = dsDinerAfTm[0]
                                obj.dinerTmMm = dsDinerAfTm[1]
                            }
                        }

                        //취췸전
                        if(e.dsBedBf == "1"){
                            if(this.hisInfo.dsBedBfTm?.isEmptyVal()){
                                let dsBedBfTm =  this.hisInfo.dsBedBfTm.split(":")
                                item.bedTmHh = dsBedBfTm[0]
                                item.bedTmMm = dsBedBfTm[1]
                                obj.bedTmHh = dsBedBfTm[0]
                                obj.bedTmMm = dsBedBfTm[1]
                            }
                        }

                        //취췸전
                        if(e.dsNeed == "1"){
                            if(this.hisInfo.dsNeedTm?.isEmptyVal()){
                                let dsNeedTm =  this.hisInfo.dsNeedTm.split(":")
                                item.needTmHh = dsNeedTm[0]
                                item.needTmMm = dsNeedTm[1]
                                obj.needTmHh = dsNeedTm[0]
                                obj.needTmMm = dsNeedTm[1]
                            }
                        }
                        
                        
                        item.dsBrkfsBf  = (obj.wrtrMmbrPk1 > 0) ? e.dsBrkfsBf : null
                        item.dsBrkfsAf  = (obj.wrtrMmbrPk2 > 0)? e.dsBrkfsAf : null
                        item.dsLunchBf  = (obj.wrtrMmbrPk3 > 0)? e.dsLunchBf : null
                        item.dsLunchAf  = (obj.wrtrMmbrPk4 > 0)? e.dsLunchAf : null
                        item.dsDinerBf  = (obj.wrtrMmbrPk5 > 0)? e.dsDinerBf : null
                        item.dsDinerAf  = (obj.wrtrMmbrPk6 > 0)? e.dsDinerAf : null
                        item.dsBedBf    = (obj.wrtrMmbrPk7 > 0)? e.dsBedBf : null
                        item.dsNeed     = (obj.wrtrMmbrPk8> 0)? e.dsNeed : null

                        obj.list.push(item)
                    });

                    if(this.doseReclist.length > 0){                    
                        insDoseRecMulti(obj)
                            .then((response) => ( this.onSaveAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftrcrd/insDoseRecMulti : ' + error))
                    }
                }                
            }
        },
        onSaveAfter:function(res){
            this.$emit("hlthBlnftTrigger",res)
            if(res.statusCode != 300) this.reLoad()
        },
        //기록 DB 삭제
        onDelete:function(res){
            let fcltyNum = this.$store.getters.getFcltyNum    
            let userNm = this.$store.getters.getUserNm
            let bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(res == 9990){
                this.dialogDelete = false
                if(this.type=='all'){      
                    let bnftOfrRecHisPk = this.hisInfo.bnftOfrRecHisPk
                    if(bnftOfrRecHisPk > 0){
                        let obj = {
                            fcltyNum: fcltyNum,
                            bnMmbrPk : bnMmbrPk,
                            userNm : userNm,
                            bnftOfrRecHisPk : bnftOfrRecHisPk,
                        }
                        delDoseRecHis(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delDoseRecHis : ' + error))
                    }
                }else if(this.type=="subRec1"){
                    let doseRecPk = this.doseReclist[this.idx].doseRecPk

                    if(doseRecPk > 0){
                        let obj = {
                            fcltyNum: fcltyNum,
                            bnMmbrPk : bnMmbrPk,
                            userNm : userNm,
                            doseRecPk : doseRecPk,
                        }
                        delDoseRec(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delDoseRec : ' + error))
                    }else{
                        let error = {
                            statusCode:500,
                            message:'삭제 대상이 존재하지 않습니다.'
                        }
                        this.$emit("hlthBlnftTrigger",error)
                    }
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){

            if(this.type=="subRec1"){
                this.doseReclist.splice(this.idx,1)    
            }

            this.$emit("hlthBlnftTrigger",res)
            this.reLoad()
            this.idx = 0
            this.type = ''
            this.delTitle = ''
        },
        onModal: function (value,type) {
            this.dialog.splice(value, 1, true)
            this.addType = type
        },
        onEmpModel:function(value){
            this.empidx = value
            this.dialog.splice(1, 1, true)
        },
        isModal: function (value) {    
            if(value == 2) this.mdctnLvnRmMdlPk = -1
            if(value == 3) this.reLoad()
            this.dialog.splice(value, 1, false)
        },
        getDoseRecListHis(){
            this.doseInfPks.splice(0)
            this.getPreData = true

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }        
            
            http.post('bnftrcrd/selNDoseRecListHis', obj)
                .then((response) => ( this.getDoseRecListHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selNDoseRecListHis : ' + error))
        },
        getDoseRecListHisAfter:function(res){   
            if(res.hisInfo != null){
                this.hisInfo = res.hisInfo
                this.getPreData = false
                this.dummyHisInfo = JSON.parse(JSON.stringify(res.hisInfo))
            }

            if(res.doseTmInfo != null){
                if(res.doseTmInfo.brkfsTmHhBf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmBf?.isEmptyVal()){
                    this.hisInfo.dsBrkfsBfTm = res.doseTmInfo.brkfsTmHhBf + ":" + res.doseTmInfo.brkfsTmMmBf
                }
    
                if(res.doseTmInfo.brkfsTmHhAf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmAf?.isEmptyVal()){
                    this.hisInfo.dsBrkfsAfTm = res.doseTmInfo.brkfsTmHhAf + ":" + res.doseTmInfo.brkfsTmMmAf
                }
    
                if(res.doseTmInfo.lunchTmHhBf?.isEmptyVal() && res.doseTmInfo.lunchTmMmBf?.isEmptyVal()){
                    this.hisInfo.dsLunchBfTm = res.doseTmInfo.lunchTmHhBf + ":" + res.doseTmInfo.lunchTmMmBf
                }
    
                if(res.doseTmInfo.lunchTmHhAf?.isEmptyVal() && res.doseTmInfo.lunchTmMmAf?.isEmptyVal()){
                    this.hisInfo.dsLunchAfTm = res.doseTmInfo.lunchTmHhAf + ":" + res.doseTmInfo.lunchTmMmAf
                }
    
                if(res.doseTmInfo.dinerTmHhBf?.isEmptyVal() && res.doseTmInfo.dinerTmMmBf?.isEmptyVal()){
                    this.hisInfo.dsDinerBfTm = res.doseTmInfo.dinerTmHhBf + ":" + res.doseTmInfo.dinerTmMmBf
                }
    
                if(res.doseTmInfo.dinerTmHhAf?.isEmptyVal() && res.doseTmInfo.dinerTmMmAf?.isEmptyVal()){
                    this.hisInfo.dsDinerAfTm = res.doseTmInfo.dinerTmHhAf + ":" + res.doseTmInfo.dinerTmMmAf
                }
    
                if(res.doseTmInfo.bedTmHh?.isEmptyVal() && res.doseTmInfo.bedTmMm?.isEmptyVal()){
                    this.hisInfo.dsBedBfTm = res.doseTmInfo. bedTmHh+ ":" + res.doseTmInfo.bedTmMm
                }
            }
            

            if(res.doseReclist.length > 0){
                
                this.doseReclist = res.doseReclist

                this.doseReclist.forEach(e => {
                    e.valid = false
                    e.remAmt = 0
                    //아침
                    if(e.dsBrkfsBf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsBfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    if(e.dsBrkfsAf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsAfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    //점심
                    if(e.dsLunchBf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchBfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    if(e.dsLunchAf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchAfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    //저녁
                    if(e.dsDinerBf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerBfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
    
                    if(e.dsDinerAf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerAfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
                    
                    //취침전
                    if(e.dsBedBf == "1"){
                        if(e.bedTmHh?.isEmptyVal() && e.bedTmMm?.isEmptyVal()){
                            this.hisInfo.dsBedBfTm = e.bedTmHh + ":" + e.bedTmMm
                        }
                    }
    
                    //필요시
                    if(e.dsNeed == "1"){
                        if(e.needTmHh?.isEmptyVal() && e.needTmMm?.isEmptyVal()){
                            this.hisInfo.dsNeedTm = e.needTmHh + ":" + e.needTmMm
                        }
                    }    

                    this.doseInfPks.push(e.doseInfPk)
                });
                
                // if(!this.oldRecYn && this.doseInfPks.length > 0){
                //     this.getDoseRemCalcList()
                // }

            }else{
                this.getDoseInfList()
            }
        },

        getPreDoseRecListHis:function(){
            this.doseInfPks.splice(0)

            let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
            let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
            date = dateArr[0]?.afterDateFormatHyp()    
            this.preymd = date
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.preymd,
            }        

            http.post('bnftrcrd/selNDoseRecListHis', obj)
                .then((response) => ( this.getPreDoseRecListHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selNDoseRecListHis : ' + error))
        },

        getPreDoseRecListHisAfter:function(res){
           
            if(res.hisInfo != null){
                this.hisInfo = res.hisInfo
            }

            if(res.doseTmInfo.brkfsTmHhBf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmBf?.isEmptyVal()){
                this.hisInfo.dsBrkfsBfTm = res.doseTmInfo.brkfsTmHhBf + ":" + res.doseTmInfo.brkfsTmMmBf
            }

            if(res.doseTmInfo.brkfsTmHhAf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmAf?.isEmptyVal()){
                this.hisInfo.dsBrkfsAfTm = res.doseTmInfo.brkfsTmHhAf + ":" + res.doseTmInfo.brkfsTmMmAf
            }

            if(res.doseTmInfo.lunchTmHhBf?.isEmptyVal() && res.doseTmInfo.lunchTmMmBf?.isEmptyVal()){
                this.hisInfo.dsLunchBfTm = res.doseTmInfo.lunchTmHhBf + ":" + res.doseTmInfo.lunchTmMmBf
            }

            if(res.doseTmInfo.lunchTmHhAf?.isEmptyVal() && res.doseTmInfo.lunchTmMmAf?.isEmptyVal()){
                this.hisInfo.dsLunchAfTm = res.doseTmInfo.lunchTmHhAf + ":" + res.doseTmInfo.lunchTmMmAf
            }

            if(res.doseTmInfo.dinerTmHhBf?.isEmptyVal() && res.doseTmInfo.dinerTmMmBf?.isEmptyVal()){
                this.hisInfo.dsDinerBfTm = res.doseTmInfo.dinerTmHhBf + ":" + res.doseTmInfo.dinerTmMmBf
            }

            if(res.doseTmInfo.dinerTmHhAf?.isEmptyVal() && res.doseTmInfo.dinerTmMmAf?.isEmptyVal()){
                this.hisInfo.dsDinerAfTm = res.doseTmInfo.dinerTmHhAf + ":" + res.doseTmInfo.dinerTmMmAf
            }

            if(res.doseTmInfo.bedTmHh?.isEmptyVal() && res.doseTmInfo.bedTmMm?.isEmptyVal()){
                this.hisInfo.dsBedBfTm = res.doseTmInfo. bedTmHh+ ":" + res.doseTmInfo.bedTmMm
            }

            this.hisInfo.bnftOfrRecHisPk = 0

            if(res.doseReclist.length > 0){
                this.doseReclist = res.doseReclist

                this.doseReclist.forEach(e => {    
                    e.valid = false
                    e.doseRecPk = 0    
                    e.remAmt = 0
                    //아침
                    if(e.dsBrkfsBf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsBfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    if(e.dsBrkfsAf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsAfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    //점심
                    if(e.dsLunchBf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchBfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    if(e.dsLunchAf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchAfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    //저녁
                    if(e.dsDinerBf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerBfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
    
                    if(e.dsDinerAf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerAfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
                    
                    //취침전
                    if(e.dsBedBf == "1"){
                        if(e.bedTmHh?.isEmptyVal() && e.bedTmMm?.isEmptyVal()){
                            this.hisInfo.dsBedBfTm = e.bedTmHh + ":" + e.bedTmMm
                        }
                    }
    
                    //필요시
                    if(e.dsNeed == "1"){
                        if(e.needTmHh?.isEmptyVal() && e.needTmMm?.isEmptyVal()){
                            this.hisInfo.dsNeedTm = e.needTmHh + ":" + e.needTmMm
                        }
                    }    

                    this.doseInfPks.push(e.doseInfPk)
                });

                // if(!this.oldRecYn && this.doseInfPks.length > 0){
                //     this.getDoseRemCalcList()
                // }
                
            }else{
                this.getPreDoseInfList()
            }
        },

        getDoseInfList(){
            this.doseInfPks.splice(0)

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            } 
            
            selDoseInfList(obj)
            .then((response) => ( this.getDoseInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getPreDoseInfList(){
            this.doseInfPks.splice(0)

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.preymd,
            } 
            
            selDoseInfList(obj)
            .then((response) => ( this.getDoseInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getDoseInfListAfter:function(res){
            this.doseReclist.splice(0)

            res.forEach(e => {                    
                let item = {
                    doseRecPk:0,
                    doseInfPk:e.doseInfPk,
                    doseDt:'',
                    drugNm:e.drugNm,
                    drugAmt:e.drugAmt,
                    drugMthd:e.drugMthd,
                    dsBrkfsBf:e.dsBrkfsBf,
                    dsBrkfsAf:e.dsBrkfsAf,
                    dsLunchBf:e.dsLunchBf,
                    dsLunchAf:e.dsLunchAf,
                    dsDinerBf:e.dsDinerBf,
                    dsDinerAf:e.dsDinerAf,
                    dsBedBf:e.dsBedBf,
                    dsNeed:'2',
                    snglDoseAmt:e.snglDoseAmt,
                    totDoseAmt:e.totDoseAmt,
                    valid:false,
                    remAmt:0,
                }
                this.doseInfPks.push(e.doseInfPk)
                this.doseReclist.push(item)                                          
            });

            if(!this.oldRecYn && this.doseInfPks.length > 0){
                this.getDoseRemCalcList()
            }
        },

         // 직무 스트레스 해소 교육 조회
         getDoseRemCalcList: function (){    
            this.doseCalc = true
            let obj = {
                inqYMD      : this.inqYMD,
                doseInfPks   : this.doseInfPks
            }

            http.post('bnftrcrd/selDoseRemCalcList', obj)
                .then((response) => ( this.rstDoseRemCalcList(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selDoseRemCalcList : ' + error))
        },

        rstDoseRemCalcList:function(res){            
            this.doseCalc = false
            if(res.statusCode == '200'){   
                if(res.result.list != null){
                    
                    if(res.result.list.length > 0){
                        let doselist = []
                        this.doseReclist.forEach(el => {
                            let obj = el

                            if(el.doseInfPk > 0){
                                let idx = res.result.list.findIndex(v=>v.doseInfPk == el.doseInfPk)
                                if(idx  > -1) obj.remAmt =  res.result.list[idx].remAmt
                            }

                            doselist.push(el)

                        });

                        this.doseReclist = doselist
                    }
                }

            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }

            
        },
        
        addDrugInf:function(list){
            list.forEach(item => {                
                this.doseReclist.push({
                    drugInfPk:item.drugInfPk,
                    doseInfPk:0,
                    doseRecPk:0,doseDt:'',
                    drugNm:item.drugNm,drugAmt:'',drugMthd:item.drugMthd,
                    dsBrkfsBf:'2',dsBrkfsAf:'2',
                    dsLunchBf:'2',dsLunchAf:'2',dsDinerBf:'2',dsDinerAf:'2',dsBedBf:'2',dsNeed:'2',
                    snglDoseAmt:'',
                    totDoseAmt:'',
                    valid:false,
                })
            });
        },
        delList:function(idx,type){
            if(type == "subRec1"){
                if(this.doseReclist[idx].doseRecPk > 0){
                    this.dialogDelete=true
                    this.idx = idx
                    this.type= type
                    this.delTitle = "투약상황기록" 
                }else{
                    this.doseReclist.splice(idx,1)                
                }
            }
           
        },
        delAll:function(){
            this.type= 'all'
            this.delTitle = "투약관리"
            this.dialogDelete=true
        },
        getEmpInfo: function (obj) {
            this.hisInfo.wrtrGr[this.empidx] = obj[0].name
            this.hisInfo.wrtrGrMmbrPk[this.empidx] = obj[0].mmbrPk
        },
        onMdctnLvnRm:function(){
            this.mdctnLvnRmMdlPk = 1
            this.dialog.splice(2, 1, true)
        },                
        onMdctnPrscr:function(){
            this.$emit("onMdctnPrscr")
        },

        isMdctnValid:function(){            
            let isLop = true
            let regDciml = /^-?\d*(\.\d*)?$/

            this.doseReclist.forEach(itm => {
                itm.valid = false
                

                if(!this.oldRecYn){                        
                    
                    if(isLop && (itm.totDoseAmt == '' || itm.totDoseAmt == null)) {
                        this.onAlrt(itm.drugNm+'의 총량을 입력해주세요.')
                        isLop = false
                        itm.valid = true
                    }

                    if(isLop) { 

                        if (!regDciml.test(itm.totDoseAmt)) {
                            this.onAlrt(itm.drugNm+'의 총량을 확인해주세요.(유효값 : 숫자, 소수점)')
                            isLop = false
                            itm.valid = true
                        }
                    }

                    if(isLop && (itm.totDoseAmt == '' || itm.totDoseAmt == null)) {
                        this.onAlrt(itm.drugNm+'의 1회 투약량을 입력해주세요.')
                        isLop = false
                        itm.valid = true
                    }

                    if(isLop) { 

                        if (!regDciml.test(itm.snglDoseAmt)) {
                            this.onAlrt(itm.drugNm+'의 1회 투약량을 확인해주세요.(유효값 : 숫자, 소수점)')
                            isLop = false
                            itm.valid = true
                        }
                    }

                }
            });

            return isLop;
        }, 

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },

        //잔여량 조정정보 오픈
        onDoseRmn:function(pk){
            this.sltDosePk = pk
            this.dialog.splice(3, 1, true)
        }
    },
    data: () => ({
        oldRecYn    : false,   //2025-01-01 이전인지 체크
        preymd      : '',
        doseInfPks  : [],
        doseCalc    : false,
        getPreData  : true,
        empidx:0,
        idx:0,
        type:'',
        addType:'',
        delTitle:'투약관리',
        forceRender:0,
        chkAll:false,
        subTab:0,      
        doseStrt:'', 
        doseEnd:'',
        doseItems:[],
        sltDosePk:-1,
        hisInfo:{
            bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',},
        dummyHisInfo:{
            bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',},
        doseReclist:[{
            doseRecPk:0,doseInfPk:0,doseDt:'',drugNm:'',drugAmt:'',drugMthd:'',dsBrkfsBf:'2',dsBrkfsAf:'2',
            dsLunchBf:'2',dsLunchAf:'2',dsDinerBf:'2',dsDinerAf:'2',dsBedBf:'2',dsNeed:'2',
        }],
        mdctnLvnRmMdlPk:-1,
        menu:[false,false,false,false,],
        menu2:[false,false,false,false,],
        menu3:[false,false,false,false,],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
        dialog:[false,false,false,false]
    }),
}
</script>

<style>
    table.mdctntbl {
        width:100%;
        padding:0;
        border:0;
        border-spacing:0px;
        border-collapse:collapse;
    }
    table.mdctntbl th,td {padding:0;}
</style>