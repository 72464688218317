import { http } from '@/api/baseAxios';
//import qs from 'qs';

function getEmpChcList(code, wrdCd, date) {
    return http.post('emp/selEmpChcList', { fcltyNum: code, wrkStcd: wrdCd, wrtDt: date })
}

function getExtEmpList(code, ymd) {
    return http.post('emp/selExtEmpList', { fcltyNum: code, inqYMD: ymd })
}

//직원근무일정 시간 다건 조회
function selEmpWrkSchdHrlList(code, wrdCd, date) {
    return http.post('emp/selEmpWrkSchdHrlList', { fcltyNum: code, wrkStcd: wrdCd, inqYYMM: date })
}

//직원근무직종별 건수 조회
function selEmpWrkJobCntList(code, date) {
    return http.post('emp/selEmpWrkJobCntList', { fcltyNum: code, inqYYMM: date })
}

//직원근무일정 다건 조회(팝업용)
function selEmpWrkSchdlList(code, yyyymmdd) {
    return http.post('emp/selEmpWrkSchdlList', { fcltyNum: code, inqYMD: yyyymmdd })
}

//근무패턴 다건 조회
function selWrkPtnList(code) {
    return http.post('emp/selWrkPtnList', { fcltyNum: code })
}

//근무패턴 단건 저장
function insWrkPtn(obj) {
    return http.post('emp/insWrkPtn', obj)
}

//근무패턴 단건 삭제
function delWrkPtn(code, nm, pk) {
    return http.post('emp/delWrkPtn', { fcltyNum: code, userNm: nm, wrkPtnPk: pk })
}

//근무 다중 삭제
function delMultiEmpWrkSchdl(obj) {
    return http.post('emp/delMultiEmpWrkSchdl', obj)
}

//근무일정 작성 기본정보 조회
function selEmpSchdlInfo(code, yymm, mPk, ePk) {
    return http.post('emp/selEmpSchdlInfo', { fcltyNum: code, inqYYMM: yymm, mmbrPk: mPk, empPk: ePk })
}

//직원일정 조회
function selEmpWrkSchdlMnthList(code, yymm, pks) {
    return http.post('emp/selEmpWrkSchdlMnthList', { fcltyNum: code, inqYYMM: yymm, mmbrPks: pks })
}

//직원일정 등록
function insEmpWrkSchdlMulti(list) {
    return http.post('emp/insEmpWrkSchdlMulti', { list })
}

//직원일정 삭제
function delEmpWrkSchdl(obj) {
    return http.post('emp/delEmpWrkSchdl', obj)
}

//직원 정보 다건 조회
function selEmpList(code, stcd) {
    return http.post('emp/selEmpList', { fcltyNum: code, wrkStcd: stcd })
}

//직원 정보 상세 조회
function selEmpInfo(code, pk) {
    return http.post('emp/selEmpInfo', { fcltyNum: code, empPk: pk })
}

//직원 정보 저장
function insEmp(obj) {
    return http.post('emp/insEmp', obj)
}

//직원 유급휴일 이월
function insVctnSbst(obj) {
    return http.post('emp/insVctnSbst', obj)
}

//직원 유급휴일 내역조회
function selVctnSbstList(code, pk, yyyy) {
    return http.post('emp/selVctnSbstList', { fcltyNum: code, empPk: pk, inqYYYY: yyyy })
}

//직원 유급휴일 수정(스케쥴 포함)
function updVctnSbst(obj) {
    return http.post('emp/updVctnSbst', obj)
}

//직원 유급휴일 삭제
function delVctnSbst(code, nm, pk) {
    return http.post('emp/delVctnSbst', { fcltyNum: code, userNm: nm, vctnSbstPk: pk })
}

//외부강사, 자원봉사자 저장
function insExtEmp(obj) {
    return http.post('emp/insExtEmp', obj)
}

//직원 정보 삭제
function delEmp(code, pk, nm) {
    return http.post('emp/delEmp', { fcltyNum: code, empPk: pk, userNm: nm })
}

//직원 근무시간 다건 조회
function selEmpWrkHrList(code, pk, apl) {
    return http.post('emp/selEmpWrkHrList', { fcltyNum: code, empPk: pk, aplEnd: apl })
}

//직원 근무시간 저장
function insEmpWrkHr(obj) {
    return http.post('emp/insEmpWrkHr', obj)
}

//직원 근무시간 삭제
function delEmpWrkHr(code, nm, pk) {
    return http.post('emp/delEmpWrkHr', { fcltyNum: code, userNm: nm, empWrkHrPk: pk })
}

//직원 계정 중복검사
function selEmpAcntIdSrch(code, iId) {
    return http.post('emp/selEmpAcntIdSrch', { fcltyNum: code, id: iId })
}

//직원 정보 수정
function insEmpAcnt(obj) {
    return http.post('emp/insEmpAcnt', obj)
}

//직원 기타정보 다건 조회
function selEmpEtcList(code, pk) {
    return http.post('emp/selEmpEtcList', { fcltyNum: code, empPk: pk } )
}

//직원 기타정보 저장
function insEmpEtc(obj) {
    return http.post('emp/insEmpEtc', obj )
}

//직원 기타정보 삭제
function delEmpEtc(code, pk, nm) {
    return http.post('emp/delEmpEtc', { fcltyNum: code, empEtcPk: pk, userNm: nm } )
}

//근무현황 조회
function selEmpAtndSumList(code, pk, yymm) {
    return http.post('emp/selEmpAtndSumList', { fcltyNum: code, empPk: pk, inqYYMM: yymm } )
}

//직원 사용연차 다건 조회
function selEmpUseAnlList(code, pk, yyyy) {
    return http.post('emp/selEmpUseAnlList', { fcltyNum: code, empPk: pk, inqYYYY: yyyy } )
}

//직원 연차정보 조회
function selEmpAnlUseInfo(code, pk, yymm) {
    return http.post('emp/selEmpAnlUseInfo', { fcltyNum: code, empPk: pk, inqYYMM: yymm } )
}

//직원 근무상태 저장
function insEmpHis(obj) {
    return http.post('emp/insEmpHis', obj )
}

//직원 재입사
function insReEmp(obj) {
    return http.post('emp/insReEmp', obj )
}

//직원건강관리 다건 조회
function selEmpHltChcList(code, yyyy) {
    return http.post('emp/selEmpHltChcList', { fcltyNum: code, inqYYYY: yyyy } )
}

//직원 조 편성 관리 조회
function selEmpGrpList(code) {
    return http.post('emp/selEmpGrpList', { fcltyNum: code } )
}

//직원 조 편성 저장
function insEmpGrp(obj) {
    return http.post('emp/insEmpGrp', obj )
}

//직원 조 편성 삭제
function delEmpGrp(obj) {
    return http.post('emp/delEmpGrp', obj )
}

//직원 선택(근무정보) 다건 조회
function selEmpChcWhList(code, cd, ymd = null) {
    return http.post('emp/selEmpChcWhList', { fcltyNum: code, wrkStcd: cd ,inqYMD: ymd} )
}

//기준 근무시간 조회
function selStdWrkHrInfo(yymm) {
    return http.post('impExp/selStdWrkHrInfo', { inqYYMM: yymm } )
}

//직원 실근무시간 저장
function insEmpAtnd(obj) {
    return http.post('emp/insEmpAtnd', obj )
}

//직원 실근무시간 저장
function insEmpAtndDt(code, pk, dt, atnd, leav, nm ) {
    return http.post('emp/insEmpAtndDt', { fcltyNum: code, empPk: pk, wrkDt: dt, atndDt: atnd, leavDt: leav, userNm: nm } )
}

//근골격계증상검사 한건 조회 
function selMscldChckpInfo(code, pk) {
    return http.post('emp/selMscldChckpInfo', { fcltyNum: code, mscldChckpPk: pk } )
}

//근골격계증상 조사표 다건 조회 
function selMscldChckpDtlList(cd, pk) {
    return http.post('emp/selMscldChckpDtlList', { clsfc2Cd: cd, mscldChckpPk: pk } )
}

//근골격계 증상검사 저장
function insMscldChckp(obj) {
    return http.post('emp/insMscldChckp', obj )
}

//근골격계 증상검사 삭제
function delMscldChckp(obj) {
    return http.post('emp/delMscldChckp', obj )
}

//근골격계 증상검사 삭제
function insMscldChckpDtl(obj) {
    return http.post('emp/insMscldChckpDtl', obj )
}

//고충처리 상세내용 저장
function insCmplnRec(obj) {
    return http.post('emp/insCmplnRec', obj )
}

//고충처리 상세내용 삭제
function delCmplnRec(obj) {
    return http.post('emp/delCmplnRec', obj )
}

//고충처리 상세내용 한건 조회
function selCmplnRecInfo(obj) {
    return http.post('emp/selCmplnRecInfo', obj )
}

//고충처리 상세내용 다건 조회
function selCmplnRecList(obj) {
    return http.post('emp/selCmplnRecList', obj )
}

//직원근무현황 시간 다건 조회 (왼쪽)
function selEmpAtndHrlList(code, wrk, yymm) {
    return http.post('emp/selEmpAtndHrlList', { fcltyNum: code, wrkStcd: wrk, inqYYMM: yymm } )
}

//직원근무현황 다건 조회
function selEmpAtndList(code, pk, yymm, ymd) {
    return http.post('emp/selEmpAtndList', { fcltyNum: code, empPk: pk, inqYYMM: yymm, inqYMD: ymd } )
}

//직원근무현황 다건 조회
function selEmpAtndYnList(code, pk, ymd) {
    return http.post('emp/selEmpAtndYnList', { fcltyNum: code, empPk: pk, inqYMD: ymd } )
}

//외부강사/자원봉사자 조회(left)
function selExtEmpList(code) {
    return http.post('emp/selExtEmpList', { fcltyNum: code } )
}

//월별근무표 조회
function selExtEmpWrkSchdlList(code, yymm) {
    return http.post('emp/selExtEmpWrkSchdlList', { fcltyNum: code, inqYYMM: yymm } )
}

//외부직원 단건 조회
function selExtEmpInfo(code, pk) {
    return http.post('emp/selExtEmpInfo', { fcltyNum: code, empPk: pk } )
}

//기간별 활동일지 다건 조회
function selExtEmpWrkRecList(code, ymdStrt, ymdEnd) {
    return http.post('emp/selExtEmpWrkRecList', { fcltyNum: code, inqYmdStart: ymdStrt, inqYmdEnd: ymdEnd } )
}

//자원봉사자 활동일지 한건 조회
function selVlntrWrkRecInfo(code, pk) {
    return http.post('emp/selVlntrWrkRecInfo', { fcltyNum: code, vlntrWrkRecPk: pk } )
}

//자원봉사자 활동일지 저장
function insVlntrWrkRec(obj) {
    return http.post('emp/insVlntrWrkRec', obj )
}

//자원봉사자 활동일지 삭제
function delVlntrWrkRec(code, pk, nm) {
    return http.post('emp/delVlntrWrkRec', { fcltyNum: code, vlntrWrkRecPk: pk, userNm: nm } )
}

//직원근태 일괄저장
function insAllEmpAtnd(code, nm, dt) {
    return http.post('emp/insAllEmpAtnd', { fcltyNum: code, userNm: nm, wrkDt: dt } )
}

//직원 연차 차감 저장
function insEmpAnlDdc(obj) {
    return http.post('emp/insEmpAnlDdc', obj )
}

//직원 연차 삭제
function delEmpAnlDdc(ddcPk, code, nm) {
    return http.post('emp/delEmpAnlDdc', { empAnlDdcPk: ddcPk, fcltyNum: code, userNm: nm } )
}

//직원 근무일정 엑셀 데이터 조회
function selExlEmpAtndList(code, yymm) {
    return http.post('emp/selExlEmpAtndList', { fcltyNum: code, inqYYMM: yymm } )
}

//역량강화교육 다건 조회
function selEduRec704List(obj) {
    return http.post('emp/selEduRec704List', obj )
}

//역량강화교육 다건 조회
function selEmpHisList(code, pk) {
    return http.post('emp/selEmpHisList', { fcltyNum: code, mmbrPk: pk } )
}

export {
    getEmpChcList,
    getExtEmpList,
    selEmpWrkSchdHrlList,
    selEmpWrkJobCntList,
    selEmpWrkSchdlList,
    selWrkPtnList,
    insWrkPtn,
    delWrkPtn,
    delMultiEmpWrkSchdl,
    selEmpSchdlInfo,
    selEmpWrkSchdlMnthList,
    insEmpWrkSchdlMulti,
    delEmpWrkSchdl,
    selEmpList,
    selEmpInfo,
    insEmp,
    delEmp,
    insVctnSbst,
    updVctnSbst,
    delVctnSbst,
    selVctnSbstList,
    selEmpWrkHrList,
    insEmpWrkHr,
    delEmpWrkHr,
    selEmpAcntIdSrch,
    insEmpAcnt,
    selEmpEtcList,
    insEmpEtc,
    delEmpEtc,
    selEmpAtndSumList,
    selEmpUseAnlList,
    selEmpAnlUseInfo,
    insEmpHis,
    insReEmp,
    selEmpGrpList,
    insEmpGrp,
    delEmpGrp,
    selEmpHltChcList,
    selEmpChcWhList,
    selStdWrkHrInfo,
    insEmpAtnd,
    insEmpAtndDt,
    selMscldChckpInfo,
    selMscldChckpDtlList,
    insMscldChckp,
    delMscldChckp,
    insMscldChckpDtl,
    insCmplnRec,
    delCmplnRec,
    selCmplnRecInfo,
    selCmplnRecList,
    selEmpAtndHrlList,
    selEmpAtndList,
    selEmpAtndYnList,
    selExtEmpList,
    selExtEmpWrkSchdlList,
    selExtEmpInfo,
    selExtEmpWrkRecList,
    selVlntrWrkRecInfo,
    insVlntrWrkRec,
    delVlntrWrkRec,
    insExtEmp,
    insAllEmpAtnd,
    insEmpAnlDdc,
    delEmpAnlDdc,
    selExlEmpAtndList,
    selEduRec704List,
    selEmpHisList,
}