<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold blue--text">{{this.bnName}} </span>
            <span class="text-h4 font-weight-bold pl-2">투약처방</span>
            <span class="text-h4 pl-2">&#40;{{$moment(inqYMD, 'YYYYMMDD').format('YYYY.MM.DD')}} 기준&#41;</span>            
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <span class="d-inline-block font-weight-bold red--text" style="width:300px;">※ 투약기록이 있는 건은 삭제 불가합니다.</span>
            <span>                
                <v-btn
                    width="20" height="20"
                    @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 41)"
                    icon>
                    <v-icon size="20">mdi-help-circle-outline</v-icon>
                    <span>주의사항</span>
                </v-btn>
            </span>
            <v-spacer></v-spacer>
            <v-btn
                width="20" height="20"
                @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 40)"
                icon>
                <v-icon size="20">mdi-help-circle-outline</v-icon>
            </v-btn>
            <span v-if="!progress">
                <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="list.disabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>      
            <span v-if="!progress">
                <v-btn
                    class="font-weight-bold ml-2" color="grey006" height="30" style="font-size: 14px;"
                    @click="onClickBtn('출력')"
                    rounded outlined>
                    <v-icon class="mr-1" size="18">mdi-printer-outline</v-icon>
                    <span>출력</span>
                </v-btn>                 
            </span>
        </div>
        <v-form ref="tabform2" lazy-validation>   
        <div>
            <div v-if="progress" style="height:649px;">
                <div
                
                    class="d-inline-block"
                    style="width: 100%; height: 38px; text-align: center;">
                    <v-progress-linear
                        class="mb-1" color="yellow darken-2"
                        indeterminate>
                    </v-progress-linear>
                    <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
                </div>            
            </div> 
            <div v-else>
                <div :class="['pa-0 mt-1 d-flex',doseList.length > 7 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;font-size:1rem !important">            
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:9%;">
                        <span class="black--text font-weight-medium">투약명</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">총량</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">                        
                        <div class="black--text font-weight-medium">
                            <div>투약량</div>
                            <div>&#40;1회&#41;</div>
                        </div>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약방법</span>
                    </div>                    
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">주사</span>
                    </div>
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">
                        <v-simple-checkbox color="blue200" v-model="chkAll" @input="setAllDose"></v-simple-checkbox>
                        <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">                           
                            <CmmDateComp v-model="doseStrt"></CmmDateComp>               
                        </span>
                        <span class="px-1">&#126;</span>
                        <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">
                            <CmmDateComp v-model="doseEnd"></CmmDateComp>
                        </span>
                    </div>
                    <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">아침</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                <span class="black--text font-weight-medium">식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">식후</span>
                            </div>
                        </div>
                    </div>
                    <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">점심</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                <span class="black--text font-weight-medium">식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">식후</span>
                            </div>
                        </div>
                    </div>
                    <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">저녁</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                <span class="black--text font-weight-medium">식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">식후</span>
                            </div>
                        </div>
                    </div>
                    <div class="greyE01 pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                        <div>
                            <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">취침</span></div>
                            <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">전</span></div>
                        </div>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약효능&#47;비고</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:65px;margin-left:12px;">
                            <v-text-field v-model="allwrtr" color="blue" height="28" :key="forceRender" readonly hide-details outlined dense></v-text-field>
                        </span>                                
                            <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="22"
                            height="22"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onEmpModal(1000000, 'all')"
                            >                                    
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>

                        </v-btn>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                        <v-btn icon class="mr-1" color="blue200" height="26" @click="onModal(0)">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div :class="['',doseList.length > 7 ? 'mdctnField-y-scroll' : '']" style="height:550px;"> 
                    <div v-if="doseList.length > 0">                        
                        <div v-for="(item,i) in doseList" :key="i" :class="['pa-0 ma-0 d-flex', item.valid==true?'nmlBfClrImp-yellow001':'']" style="border-bottom: 1px solid #dbdbdb;">            
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">                    
                                <v-text-field v-model="item.drugNm" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">  
                                <span><v-text-field v-model="item.totDoseAmt" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense></v-text-field></span>
                                <span>
                                    <v-btn icon small style="padding:0px !important" @click="getTotDoseAmtCalc(item)">                                                
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on">mdi-calculator</v-icon>
                                            </template>
                                            <span v-if="item.doseStrt != null && item.doseStrt !='' && $moment(item.doseStrt).isBefore('2025-01-01')">
                                                2025-01-01 ~ {{ item.doseEnd }} 총량 계산
                                            </span>
                                            <span v-else>{{item.doseStrt}} ~ {{ item.doseEnd }} 총량 계산</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.snglDoseAmt" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense></v-text-field>                                
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.drugMthd" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </div>                            
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">                                
                                <v-checkbox class="dftChcBox-Theme pt-0" 
                                style="margin-top: 3px !important;" 
                                v-model="item.injctYn" hide-details 
                                on-icon="mdi-check-circle-outline" 
                                off-icon="mdi-minus-circle-outline"
                                :ripple="false" 
                                true-value="1" false-value="2"></v-checkbox>
                            </div>
                            <div class="pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center mt-1" style="width:100%;">
                                    <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">  
                                        <CmmDateComp v-model="item.doseStrt" @input="resetTotAmt(item)"></CmmDateComp>
                                    </span>
                                    <span class="px-1">&#126;</span>
                                    <span class="white d-inline-block" style="width:130px;border-radius: 10px;">  
                                        <CmmDateComp v-model="item.doseEnd" @input="resetTotAmt(item)"></CmmDateComp>
                                    </span>
                                    <span>                                
                                        <v-btn 
                                            icon color='grey006'
                                            min-width="30" height="28"
                                            @click="onWeek(i)">
                                            <v-tooltip top >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-calendar-week-outline</v-icon>
                                                </template>
                                                <span>요일별</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </div>
                                <div v-if="item.weekChk" class="d-flex justify-center align-center" style="width:100%;">
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnMon" hide-details :ripple="false" label="월" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnTue" hide-details :ripple="false" label="화" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnWed" hide-details :ripple="false" label="수" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnThu" hide-details :ripple="false" label="목" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnFri" hide-details :ripple="false" label="금" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnSat" hide-details :ripple="false" label="토" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsYnSun" hide-details :ripple="false" label="일" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                </div>
                            </div>
                            <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                    
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsBrkfsBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsBrkfsAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                   
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">
                                        <!-- <v-simple-checkbox color="blue200" v-model="item.dsLunchBf" ></v-simple-checkbox> -->
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsLunchBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsLunchAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                   
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">                                        
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsDinerBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsDinerAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">                                
                                <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.dsBedBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                            <div class="justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                                            
                                <v-text-field v-model="item.drugEfct" height="28" hide-details outlined dense placeholder="투약효능"></v-text-field>
                                <v-text-field v-model="item.rmrks" class="mt-2" height="28" hide-details outlined dense placeholder="비고"></v-text-field>                            
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:65px;margin-left:12px;">
                                    <v-text-field v-model="item.wrtr" color="blue" height="28" :key="forceRender" readonly hide-details outlined dense></v-text-field>
                                </span>                                
                                    <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="22"
                                    height="22"  
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onEmpModal(i, 'row')"
                                    >                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>

                                </v-btn>                               
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                                <v-btn v-if="item.doseInfPk > 0"
                                    color='blueBtnColor'
                                    class="ml-3" style="padding: 13px 8px 12px 6px !important"
                                    min-width="30" height="25" @click="preAutoMdctn(item)" small outlined>
                                    <v-icon size="14">mdi-autorenew</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">갱신</span>
                                </v-btn>

                                <v-btn icon color='grey006' :disabled="item.doseRecCnt > 0" @click="delList(i)"> 
                                    <v-icon v-if="item.doseInfPk > 0">mdi-trash-can-outline</v-icon>                                           
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                </v-btn>          
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                            데이터가 없습니다.
                        </div> 
                    </div>
                </div>
            </div>            
        </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1100">    
                <drugInfMgmt @outDrugInf="addDrugInf" @isClose="isModal(0)"></drugInfMgmt>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[2]" content-class="round" persistent max-width="1400">
                <MdctnPrscrMdlRnw 
                    v-if="dialog[2]"
                    :bnMmbrPk   = "bnMmbrPk"
                    :inqYMD     = "inqYMD"
                    @isClose="isModal(2)"                     
                ></MdctnPrscrMdlRnw>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import { selDoseInfList,  delDoseInf } from '../../api/bnftrcrd.js';

import drugInfMgmt from '../../components/commons/DrugInfMgmt.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import MdctnPrscrMdlRnw from './MdctnPrscrMdlRnw.vue';


export default {
    name: 'MdctnPrscrMdl',

    props : {
        mdctnPrscrMdlPk:{type:Number, default:0},
        inqYMD:{type:String, default:''},
        bnName:{type:String, default:''}
    },
            
    components: {
       CmmDateComp,
       EmpSelector,
       drugInfMgmt,
       MdctnPrscrMdlRnw,
       btnModalDelete,
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.bnMmbrPk   = this.mdctnPrscrMdlPk
            this.ymd        = this.inqYMD            
            this.getDoseInfList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        'mdctnPrscrMdlPk':function(value){
            if(value > 0){
                this.bnMmbrPk   = value
                this.ymd        = this.inqYMD
                this.getDoseInfList()                
            }
        },
        getSubEvt: function (bol) {
            if(bol && this.atMdctnCnFm){
                
                //갱신
                this.setDoseInfPrd()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.atMdctnCnFm = false
            }
        },
    },
        
    methods: {
        onClickBtn:function(text){            
           switch (text) {
            case '갱신내역':                
                this.dialog.splice(2, 1, true)
                break;
            case '저장':                
                this.onSave()
                break;
            case '출력':
                this.$parent.$parent.$parent.$parent.rprtActCall()     
                break;
            default:
                break;
           }
        },
        getDoseInfList(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.ymd,
            }           
            this.progress = true 
            selDoseInfList(obj)
            .then((response) => ( this.getDoseInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getDoseInfListAfter:function(res){
            
            this.doseList.splice(0)

            res.forEach(e => {     
                e.totOn  = true
                e.valid  = false   
                e.doseStrt = e.doseStrt?.beforeDateFormatHyp()
                e.doseEnd = e.doseEnd?.beforeDateFormatHyp()
                e.weekChk = false                
                this.doseList.push(e)
            });

            this.progress = false

            // this.isTotAmtColmn()
        },
        onSave:function(){
            let valid = this.isMdctnValid()
            
            if(valid){
                let obj = {                            
                    list:[],
                }
                
                this.doseList.forEach(e => {
                    let item = Object.assign({},e)
                    item.doseStrt = item.doseStrt?.afterDateFormatHyp()
                    item.doseEnd = item.doseEnd?.afterDateFormatHyp()
                    item.bnMmbrPk =this.bnMmbrPk
                    obj.list.push(item)
                });

                this.progress = true

                http.post('bnftrcrd/insNDoseInfMulti', obj)
                    .then((response) => ( this.rstSave(response.data) ))
                    .catch((error) => console.log('connect error /bnftrcrd/insNDoseInfMulti : ' + error))
            }
        },
        rstSave:function(res){                      
            this.progress = false

            let obj = {}
           
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit("modelEvent")
                this.getDoseInfList()
            }else if(res.statusCode == 400){
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

           this.$store.commit('setWrnnInf', obj)
        },
        delList:function(idx){
           if(this.doseList[idx].doseInfPk > 0){
                this.dialogDelete=true
                this.idx = idx
                this.delTitle = "투약내용" 
            }else{
                this.doseList.splice(idx,1)                
            }
        },
        //기록 DB 삭제
        onDelete:function(res){
            let fcltyNum = this.$store.getters.getFcltyNum    
            let userNm = this.$store.getters.getUserNm
            let bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(res == 9990){
                this.dialogDelete = false                
                let doseInfPk = this.doseList[this.idx].doseInfPk

                if(doseInfPk > 0){
                    let obj = {
                        fcltyNum: fcltyNum,
                        bnMmbrPk : bnMmbrPk,
                        userNm : userNm,
                        doseInfPk : doseInfPk,
                    }
                    delDoseInf(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delDoseInf : ' + error))
                }else{
                    let error = {
                        statusCode:500,
                        message:'삭제 대상이 존재하지 않습니다.'
                    }
                    this.$emit("hlthBlnftTrigger",error)
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.doseList.splice(this.idx,1)
                this.$emit("modelEvent")
                this.getDoseInfList()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }    

            this.idx = 0
            this.delTitle = ''
        },
        setAllDose:function(){
            if(this.chkAll){
                this.doseList.forEach(e => {
                    e.doseStrt = this.doseStrt
                    e.doseEnd = this.doseEnd
                    e.totDoseAmt = 0
                });
            }

            // this.isTotAmtColmn()
        },
        onModal: function (key) {
            this.dialog.splice(key, 1, true)          
        },
        isModal: function (key) {            
            this.dialog.splice(key, 1, false)
        },
        addDrugInf:function(list){
            list.forEach(el => {                
                this.doseList.push({
                    drugInfPk:el.drugInfPk,
                    doseInfPk:0,
                    drugNm:el.drugNm,
                    drugAmt:'',
                    snglDoseAmt:'',
                    drugMthd:el.drugMthd,
                    doseYn:false,
                    doseStrt:'',
                    doseEnd:'',
                    dsBrkfsBf:'2',
                    dsBrkfsAf:'2',
                    dsLunchBf:'2',
                    dsLunchAf:'2',
                    dsDinerBf:'2',
                    dsDinerAf:'2',
                    dsBedBf:'2',
                    weekChk:false,
                    dsYnMon:'2',
                    dsYnTue:'2',
                    dsYnWed:'2',
                    dsYnThu:'2',
                    dsYnFri:'2',
                    dsYnSat:'2',
                    dsYnSun:'2',
                    drugEfct:el.drugEfct,
                    rmrks:el.rmrks,
                    totOn : true,
                    valid : false
                })
            });
        },
        onWeek:function(idx){
            this.doseList[idx].weekChk = !this.doseList[idx].weekChk
        },
        onEmpModal:function(key,  typ){
            this.type = typ

            if(typ == 'row'){
                this.prtWrtDt = this.doseList[key].doseStrt
                this.idx = key
            } else {
                this.prtWrtDt = (this.doseStrt != '')?this.doseStrt:this.$moment().format('YYYY-MM-DD')
            }
            this.dialog.splice(1, 1, true)
        },
        getEmpInfo:function(obj){      
            if(this.type == 'row'){      
                this.doseList[this.idx].wrtr = obj[0].name
            } else {
                this.doseList.forEach(itm => {
                    itm.wrtr = obj[0].name
                });
                this.allwrtr = obj[0].name
            }
            ++this.forceRender
        },
        onClose: function () {
            this.bnMmbrPk = 0
            this.ymd = ''
            this.doseStrt = ''
            this.doseEnd = ''
            this.allwrtr = ''
            this.type = ''
            this.doseList.splice(0)
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        isTotAmtColmn:function(){
            this.doseList.forEach(itm => {
                itm.totOn = true
                if(this.$moment(itm.doseStrt,'YYYY-MM-DD').isValid()){
                    if(this.$moment(itm.doseStrt).isBefore('2025-01-01')){
                        itm.totOn = false
                    }
                }
            });
        },

        resetTotAmt:function(itm){
            itm.totDoseAmt = 0
        },

        isMdctnValid:function(){            
            let isLop = true
            let regDciml = /^-?\d*(\.\d*)?$/

            

            this.doseList.forEach(itm => {
                itm.vaild = false

                //투약명 체크
                if(isLop){
                    if(itm.drugNm == '' || itm.drugNm == null) {
                        this.onAlrt('투약명을 입력해주세요.')
                        isLop = false
                        itm.valid = true
                    }
                }            

                if(isLop && !this.$moment(itm.doseStrt,'YYYY-MM-DD').isValid()) {
                    this.onAlrt('투약시작일을 입력해주세요.')
                    isLop = false
                    itm.valid = true
                }

                if(isLop && !this.$moment(itm.doseEnd,'YYYY-MM-DD').isValid()) {
                    this.onAlrt('투약종료일을 입력해주세요.')
                    isLop = false
                    itm.valid = true
                }
                
                if(this.$moment(itm.doseStrt,'YYYY-MM-DD').isValid()){
                    if(isLop && (itm.totDoseAmt == '' || itm.totDoseAmt == null)) {
                        this.onAlrt('총량을 입력해주세요.')
                        isLop = false
                        itm.valid = true
                    }

                    if(isLop) { 

                        if (!regDciml.test(itm.totDoseAmt)) {
                            this.onAlrt('총량을 확인해주세요.(유효값 : 숫자, 소수점)')
                            isLop = false
                            itm.valid = true
                        }
                    }

                    if(isLop && (itm.snglDoseAmt == '' || itm.snglDoseAmt == null)) {
                        this.onAlrt('1회 투약량을 입력해주세요.')
                        isLop = false
                        itm.valid = true
                    }

                    if(isLop) { 

                        if (!regDciml.test(itm.snglDoseAmt)) {
                            this.onAlrt('1회 투약량을 확인해주세요.(유효값 : 숫자, 소수점)')
                            isLop = false
                            itm.valid = true
                        }
                    }
                }
            });

            return isLop;
        }, 

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },

        preAutoMdctn:function(itm){


            // let regDciml = /^-?\d*(\.\d*)?$/
            let chk = true

            // if(itm.totDoseAmt == '' || itm.totDoseAmt == null) {
            //     this.onAlrt('총량을 입력해주세요.')
            //     chk = false
            // }
            
            // if (chk && !regDciml.test(itm.totDoseAmt)) {
            //     this.onAlrt('총량을 확인해주세요.(유효값 : 숫자, 소수점)')
            //     chk = false
            // }
          
            // if(chk && itm.snglDoseAmt == '' || itm.snglDoseAmt == null) {
            //     this.onAlrt('1회 투약량을 입력해주세요.')
            //     chk = false
            // }

            // if (chk && !regDciml.test(itm.snglDoseAmt)) {
            //     this.onAlrt('1회 투약량을 확인해주세요.(유효값 : 숫자, 소수점)')
            //     chk = false
            // }

            if(chk) {
                this.sltPk = itm.doseInfPk
    
                let obj = {}
                    obj.icon = 'mdi-alert'
                    obj.iconClr = 'blue'                
                    obj.title = '투약명 : '+itm.drugNm
                    obj.cntnt = ['투약처방을 갱신하시겠습니까?']
                    obj.cnclTxt = '아니오'
                    obj.cnfrmTxt = '예, 갱신하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.atMdctnCnFm = true
            }


        },      

        //투약갱신
        setDoseInfPrd:function(){              
            let idx = this.doseList.findIndex(v=>v.doseInfPk == this.sltPk)

            if(idx > -1){
                let obj = {
                    doseInfPk   : this.sltPk,
                    bnMmbrPk    : this.bnMmbrPk,
                    drugNm      : this.doseList[idx].drugNm
                }  
    
                http.post('bnftrcrd/insDoseInfPrdRnw', obj)
                    .then((response) => ( this.rstAutoMdctn(response.data) ))
                    .catch((error) => console.log('connect error /bnftrcrd/insDoseInfPrdRnw : ' + error))
            } else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '갱신 대상을 찾을 수 없습니다.(ER400)'        // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },

        rstAutoMdctn:function(res){
            let obj = {}
           
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
               obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
               obj.type = 'success'            // 경고 유형 (success, info, warning, error)
               this.getDoseInfList()
            }else if(res.statusCode == 400){
               obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
               obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }else{
               obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
               obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        getTotDoseAmtCalc:function(itm){
                     
            let regDciml = /^-?\d*(\.\d*)?$/
            let chk = true

            if(chk && itm.snglDoseAmt == '' || itm.snglDoseAmt == null) {
                this.onAlrt('1회 투약량을 입력해주세요.')
                chk = false
            }

            if (chk && !regDciml.test(itm.snglDoseAmt)) {
                this.onAlrt('1회 투약량을 확인해주세요.(유효값 : 숫자, 소수점)')
                chk = false
            }

            if(chk && !this.$moment(itm.doseStrt,'YYYY-MM-DD').isValid()) {
                this.onAlrt('투약시작일을 입력해주세요.')
                chk = false
            }

            if(chk && !this.$moment(itm.doseEnd,'YYYY-MM-DD').isValid()) {
                this.onAlrt('투약종료일을 입력해주세요.')
                chk = false
            }

            if(chk) {
                let doseStrt    = this.$moment(itm.doseStrt,'YYYY-MM-DD')

                if(this.$moment(itm.doseStrt).isBefore('2025-01-01')){
                    doseStrt    = this.$moment('2025-01-01','YYYY-MM-DD')
                }

                let doseEnd     = this.$moment(itm.doseEnd,'YYYY-MM-DD')
                let daydiff     = doseEnd.diff(doseStrt, 'days')
                let cntnt       = 0;

                if(daydiff > 370){
                    this.onAlrt('투약종료일을 투약시작일의 1년 이전으로 선택해주세요.')
                } else {
                    if(typeof daydiff == 'number') {
                        ++daydiff
                        
                        if(itm.dsBrkfsBf == '1') ++cntnt
                        if(itm.dsBrkfsAf == '1') ++cntnt
                        if(itm.dsLunchBf == '1') ++cntnt
                        if(itm.dsLunchAf == '1') ++cntnt
                        if(itm.dsDinerBf == '1') ++cntnt
                        if(itm.dsDinerAf == '1') ++cntnt
                        if(itm.dsBedBf == '1') ++cntnt
                        
                        let dsYnCnt = daydiff
                        
                        let bl = itm.dsYnMon == '1' || itm.dsYnTue == '1' || itm.dsYnWed == '1' || itm.dsYnThu == '1' || itm.dsYnFri == '1' || itm.dsYnSat == '1' || itm.dsYnSun == '1'
    
                        if(bl) { 
                            //초기화
                            dsYnCnt = 0;
    
                            let cday    = this.dsWeekCalc(itm, daydiff)
    
                            if(itm.dsYnMon == '1') dsYnCnt += cday.monCnt
                            if(itm.dsYnTue == '1') dsYnCnt += cday.tueCnt
                            if(itm.dsYnWed == '1') dsYnCnt += cday.wedCnt
                            if(itm.dsYnThu == '1') dsYnCnt += cday.thuCnt
                            if(itm.dsYnFri == '1') dsYnCnt += cday.friCnt
                            if(itm.dsYnSat == '1') dsYnCnt += cday.satCnt
                            if(itm.dsYnSun == '1') dsYnCnt += cday.sunCnt                        
                        }
    
                        // itm.totDoseAmt = itm.snglDoseAmt * (cntnt * daydiff)
                        itm.totDoseAmt = itm.snglDoseAmt * (cntnt*dsYnCnt)
                    }
                }

            }
            ++this.forceRender
        },
        //요일별 체크 확인 및 계산
        dsWeekCalc:function(itm, totday){
           
            let res = {
                monCnt : 0,
                tueCnt : 0,
                wedCnt : 0,
                thuCnt : 0,
                friCnt : 0,
                satCnt : 0,
                sunCnt : 0
            }

            for(let i=0;i<totday;i++){                
                let day = this.$moment(itm.doseStrt, 'YYYY-MM-DD').add(i,'day')  
                
                switch (day.day()) {
                    case 0:
                        res.sunCnt++
                        break;
                    case 1:
                        res.monCnt++
                        break;
                    case 2:
                        res.tueCnt++
                        break;
                    case 3:
                        res.wedCnt++
                        break;
                    case 4:
                        res.thuCnt++
                        break;
                    case 5:
                        res.friCnt++
                        break;
                    case 6:
                        res.satCnt++
                        break;
                    default:
                        break;
                }
            }    
            
            return res
        }
    },

    
    data: () => ({   
        atMdctnCnFm : false,
        sltPk : -1,
        prtWrtDt:'',     
        forceRender:0,
        chkAll:false,
        idx:0,
        type:'',
        bnMmbrPk:0,
        ymd:'',
        doseStrt:'', 
        doseEnd:'',
        doseList:[],
        snackOptions: [false, 3000, '', 'info'],
        dialog:[false,false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [            
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '갱신내역', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
        ],
        delTitle:'',
        dialogDelete:false,
        progress:false,
        allwrtr:'',        
    }),
};
</script>
<style>    
    .mdctnField-y-scroll {overflow-y:scroll;}
</style>