<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700" style="position:relative">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">급여제공계획</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>    

        <div class="mt-3">
            <tab1 ref="tab1"                    
                :inqYYYY="inqYYYY"                  
                @bnftSrvPlnTrigger="bnftSrvPlnReLoad" 
                style="height: 500px;"></tab1>        
        </div>
    </v-sheet>
</template>
<script>
import { selBnftOfrPlnList, } from '../../api/bnftSrv.js';

import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import tab1 from './BnftSrvPlnTab1.vue';

export default {
    name: 'BnftSrvPln',

    props : {
        inqYYYY         : Number,       
        bnMmbrPk        : { type: Number, default: -1 },
        bnfcrPk         : { type: Number, default: -1 },
        bnftSrvPlnCmpPk : { type: Number, default: -1 }, 
        btnDisabled     : { type: Boolean, default: false },
        bzPk            : { type: Number, default: -1 },
    },
        
    components: {
        basicInfo,
        tab1,
    },
    

    watch: {     
        
    },

    mounted: function(){             
        this.$nextTick(function () {   
            console.log(this.bnftSrvPlnCmpPk)                   
            this.bnftOfrPlnHisPk = this.bnftSrvPlnCmpPk

            if(this.bnftOfrPlnHisPk > -1){
                this.onShow()                 
            }
            
        })
    },
    
    methods: {       
        onShow : function(){
            this.selBnftOfrPlnList()
        },
        onClose: function () {
            this.$emit('modalEvent', 7)
        },      
        
        selBnftOfrPlnList: function(){              
            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum, 
                bnMmbrPk        : null,
                bnftOfrPlnHisPk : null,
            }

            if(this.getPreData){
                obj.bnMmbrPk    =  this.bnMmbrPk
            }else{
                obj.bnftOfrPlnHisPk  = this.bnftOfrPlnHisPk
            }

            selBnftOfrPlnList(obj)
            .then((response) => ( this.$refs.tab1.selBnftOfrPlnListAfter(response.data)))
            .catch((error) => console.log(error))
        },

        bnftSrvPlnReLoad:function(res){
            console.log(res)
        },
       
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

    },
    
    data: () => ({  
        getPreData:false,
        minDate:'',
        maxDate:'',
        prtWrtDt:'',        
        tab: 0,        
        bnftOfrPlnHisPk:0,  
        items:{},
        resPlnHis:{bnftOfrPlnHisPk:0,cmnts:'',wrtDt:''},
        dialog:[],
        menu: [false,false,false,false],        
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            
        ],
        outlinedBtn: [
            
        ],        
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        dialogDelete:false,
        bizMsg:false,
        eCntnt:'',
    }),
};
</script>