<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="py-7 pl-7 pr-6 midAreaColor" cols="5">
                <v-sheet class="" color="midAreaColor">
                    <p class="hamletFont text-h4 topTitleColor--text font-weight-black">프로그램 의견반영</p>
                </v-sheet>
                <v-divider class="my-1 midAreaColor"></v-divider>
                <v-row class="pa-0 mb-2 posn-rltv" no-gutters>
                    <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 의견 정보</span>
                </v-row>
                <div class="nmlBfClrImp-white brdSurrd-greyC00 rounded">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">작성분기</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneRem">{{ opnnClctnInf.yyyy }}년 {{ opnnClctnInf.qtr }}분기</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">작성일</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-2" cols="3">
                            <span class="fontOneRem">{{ $moment(opnnClctnInf.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">프로그램 유형</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneRem">{{ opnnClctnInf.idxNm }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">작성자</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-2" cols="3">
                            <span class="fontOneRem">{{ opnnClctnInf.wrtr }}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">수급자 그룹</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-2" cols="9">
                            <span class="fontOneRem">{{ opnnClctnInf.bnfcrgrNm }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="black" style="padding-top: 1px;"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex align-center tableBR py-2" cols="3">
                            <div class="txtAlgnMid" style="width: 100%;">
                                <span class="fontOneDotOneRem font-weight-medium">수급자 &#40;보호자&#41;</span>
                                <br>
                                <span class="fontOneDotOneRem font-weight-medium">의견수렴 내용</span>
                            </div>
                        </v-col>
                        <v-col class="pa-2" cols="9">
                            <v-textarea
                                class="rmrksArea" rows="20"
                                v-model="opnnClctnInf.imprvOpn"
                                readonly no-resize hide-details outlined dense>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col class="py-7 pr-7 pl-6" cols="7">
                <v-sheet class="" color="">
                    <v-row class="pa-0 justify-center align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <v-icon color="black" @click="$emit('offMdl', '.')" x-large>mdi-close</v-icon>
                    </v-row>
                    <v-divider class="my-2 white"></v-divider>
                    <v-row class="pa-0 mb-2 mr-1 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 의견반영</span>
                    </v-row>
                    <v-divider class="mr-1" color="black"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 7px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                반영일
                                <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR px-4 py-1" cols="4">
                            <CmmDateComp
                                v-model="opnnRflctInf.rflDt" :required="false"
                                :minDt="lmtQtr.strtDt" :maxDt="lmtQtr.endDt"
                                @input="aplctSavBtn">
                            </CmmDateComp>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 7px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                작성자
                                <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="4">
                            <v-row class="pa-0" no-gutters>
                                <span class="d-inline-block mr-1" style="width: 122px;">
                                    <v-text-field
                                        v-model="opnnRflctInf.rflWrtr" @input="aplctSavBtn"
                                        height="28" readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn
                                    class="white--text" color="brown lighten-3" style="padding: 0px 1px 0px 2px !important;"
                                    min-width="30" height="26"
                                    :disabled="/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.opnnRflctInf.rflDt) == false"
                                    @click="onLclMdl('01', 800)" small>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원 선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 170px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv" style="line-height: 1;">
                                의견수렴<br>의견결과 반영
                                <span class="d-inline-block" style="position: absolute; top: -22px; left: 18px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="10">
                            <v-textarea
                                class="rmrksArea" rows="20"
                                v-model="opnnRflctInf.imprvRfl"
                                @input="aplctSavBtn"
                                no-resize hide-details outlined dense>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pl-1" style="padding-top: 56px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium ">
                                자료첨부
                            </span>
                            <span class="d-inline-block" style="padding-top: 2px;">
                                <v-file-input
                                    v-model="files"
                                    class="athFileInput" truncate-length="5" prepend-icon="mdi-upload"
                                    @change="upFile()" :disabled="sltPrgImprvPk == -1 ? true : false"
                                    multiple counter hide-input outlined dense hide-details>
                                </v-file-input>
                            </span>
                        </v-col>
                        <v-col class="overflow-y-auto px-4 py-1" style="width: 100%; height: 140px;" cols="10">
                            <span v-for="(item, j) in fileList" :key="j" class="pb-2 pr-2">
                                <v-chip
                                    class="mt-1"
                                    @click="athFileDownload(j, '1')" @click:close="delAtchfiles(j)"
                                    label close outlined>
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    <span class="longText">{{ item.orgFileNm }}</span>
                                </v-chip> 
                            </span>
                            <v-divider v-if="fileList.length > 0" class="my-2"></v-divider>
                            <div>
                                <span v-for="( item, i ) in imgList" :key="i" class="d-inline-block mx-1">
                                    <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)" @onDown="athFileDownload(i, '2')"></imageCard>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-divider class="white my-2"></v-divider>
                    <v-row class="pa-0 justify-center" no-gutters>
                        <v-btn
                            v-if="!delBtn"
                            class="" color="grey006" min-width="110" height="36"
                            @click="dcsnDelBtn">
                            <span class="white--text fontOneDotTwoRem">삭제</span>
                        </v-btn>
                        <v-col v-if="!delBtn" cols="1"></v-col>
                        <v-btn
                            class="" color="blueBtnColor" min-width="110" height="36"
                            :disabled="savBtn" @click="dcsnSavBtn">
                            <span class="white--text fontOneDotTwoRem">저장</span>
                        </v-btn>
                    </v-row>
                    <v-divider class="white mt-1"></v-divider>
                </v-sheet>
            </v-col>
        </v-row>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <EmpSelector
                v-if="lclMdlInf.typ == '01'"
                :prtWrtDt="opnnClctnInf.wrtDt"
                @isClose="rstLclMdlInf" @outEmp="getSltEmp">
            </EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http, remoteURL } from '@/api/baseAxios';
import { tmpFileUpload, fileDownload, delAtchdFile } from '../../api/index.js';

import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import imageCard from '../commons/imageCard.vue';

export default {
    name: 'OpnnRflctMdl',
    
    components: {
        CmmDateComp,
        EmpSelector,
        imageCard,
    },

    props : { 
        prtCd40: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getTmpObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                this.rmPrgImprvRfl(this.sltPrgImprvPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },
        
    methods: {
        // 프로그램 의견수렴 내역 단건 조회
        getPrgImprvInf: function (pk){
            this.clrForm()

            let obj = {}
            obj.prgImprvPk = pk

            http.post('prgrm/selPrgImprvInfo', obj)
                .then((response) => ( this.prgImprvDtlRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvInfo : ' + error))
        },

        prgImprvDtlRst: function (res){
            if(res.rflDt == null) {
                res.rflDt = ''
                this.delBtn = true
            }
            else
                this.delBtn = false
            
            if(res.imprvRfl == null) res.imprvRfl = ''
            if(res.rflWrtr == null) res.rflWrtr = ''
            if(res.rflWrtrMmbrPk == null) res.rflWrtrMmbrPk = -1

            if(res.rflDt != '')
                this.opnnRflctInf.rflDt = this.$moment(res.rflDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.opnnRflctInf.imprvRfl = res.imprvRfl
            this.opnnRflctInf.rflWrtr = res.rflWrtr
            this.opnnRflctInf.rflWrtrMmbrPk = res.rflWrtrMmbrPk

            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]
                this.fileList.push(items)
            })
    
            iList.forEach(itm => {
                itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePth+'&fileKndCd='+itm.fileKndCd
                this.imgList.push(itm)
            })

            this.files = null

            this.aplctSavBtn()
        },

        // 프로그램 의견반영 저장
        setPrgImprvRfl: function (obj){
            http.post('prgrm/updPrgImprvRfl', obj)
                .then((response) => ( this.prgImprvRflRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/updPrgImprvRfl : ' + error))
        },

        prgImprvRflRst: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)
        
            if(res.statusCode == '200')
                this.$emit('offMdl', '.')
            else{
                obj.type = 'warning'
                obj.tmr = 3500
            }

            this.$store.commit('setWrnnInf', obj)

            this.aplctSavBtn()
        },

        // 프로그램 의견반영 삭제
        rmPrgImprvRfl: function (pk){
            let obj = {}
            obj.prgImprvPk = pk

            http.post('prgrm/delPrgImprvRfl', obj)
                .then((response) => ( this.prgImprvRflRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/delPrgImprvRfl : ' + error))
        },

        // 첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/tmpFileUpload : ' + error))
        },

        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },

        // 첨부파일 다운로드
        athFileDownload: function (index, mode) {
            if(mode == '1'){
                fileDownload(this.fileList[index])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
            else if(mode == '2'){
                fileDownload(this.imgList[index])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error)) 
            }
        },

        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/delAtchdFile : ' + error))
        },

        delAtchdFileAfter: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                this.fileList.splice(0)

                obj.tmr = 2500
                obj.type = 'info'
                
                this.getPrgImprvInf(this.sltPrgImprvPk)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.sltPrgImprvPk = obj.prgImprvPk

            this.opnnClctnInf.yyyy = obj.trgYyyy
            this.opnnClctnInf.qtr = obj.trgQtr
            this.opnnClctnInf.wrtDt = obj.wrtDt
            this.opnnClctnInf.wrtr = obj.wrtr
            this.opnnClctnInf.idxNm = obj.idxNm
            this.opnnClctnInf.bnfcrgrNm = obj.bnfcrgrNm
            this.opnnClctnInf.imprvOpn = obj.imprvOpn

            this.lmtQtr.strtDt = this.$moment(obj.wrtDt, 'YYYYMMDD').format('YYYY-MM-DD')
            // 20250317 상무님 지시사항 - 연도분기 제약조건 제거
            // let trgDt = this.$moment(obj.trgYyyy, 'YYYY').quarter(obj.trgQtr)
            // this.lmtQtr.strtDt = trgDt.startOf('quarter').format('YYYY-MM-DD')
            // this.lmtQtr.endDt = trgDt.endOf('quarter').format('YYYY-MM-DD')

            this.getPrgImprvInf(obj.prgImprvPk)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
          
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.opnnRflctInf.rflDt) == false)
                return 0
            if(this.opnnRflctInf.rflWrtr == '')
                return 0
            if(this.opnnRflctInf.imprvRfl == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                         // 경고 노출여부
            obj.tmr = 2500                          // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                       // 경고 유형 (success, info, warning, error)
            obj.title = '작성일을 다시 확인해주세요.'   // 경고 타이틀
            obj.cntnt = []                          // 경고 내용 (arr만 출력됨!)

            let trgDt = this.$moment(this.opnnRflctInf.rflDt, 'YYYY-MM-DD')
            if(!trgDt.isSameOrAfter(this.lmtQtr.strtDt) || !trgDt.isSameOrBefore(this.lmtQtr.endDt))
                this.$store.commit('setWrnnInf', obj)

            let oObj = Object.assign({}, this.opnnRflctInf)
            oObj.prgImprvPk = this.sltPrgImprvPk
            oObj.rflDt = this.$moment(oObj.rflDt, 'YYYY-MM-DD').format('YYYYMMDD')
            oObj.files = []

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined) oObj.files.push(items)
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined) oObj.files.push(items)
            })

            this.setPrgImprvRfl(oObj)
        },

        // 삭제버튼 분기
        dcsnDelBtn: function () {
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '반영일: ' + this.$moment(this.opnnRflctInf.rflDt, 'YYYYMMDD').format('YYYY.MM.DD')
            obj.cntnt = ['의견반영 내역을 삭제할까요?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },

        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }
        },

        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined)
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            else
                this.imgList.splice(idx, 1)
        },

        delAtchfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined)
                this.removeAthFile(this.fileList[idx].atchdFilePk)
            else 
                this.fileList.splice(idx, 1)
        },

        // 초기화
        clrForm: function () {
            this.opnnRflctInf.rflDt = ''
            this.opnnRflctInf.rflWrtr = ''
            this.opnnRflctInf.rflWrtrMmbrPk = -1
            this.opnnRflctInf.imprvRfl = ''

            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            this.aplctSavBtn()
        },

        // 직원 선택 모달 이벤트
        getSltEmp: function (obj) {
            this.opnnRflctInf.rflWrtr = obj[0].name
            this.opnnRflctInf.rflWrtrMmbrPk = obj[0].empPk

            this.aplctSavBtn()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        delBtn: true,
        files: null,                    // 자료첨부
        fileList: [],                   // 자료첨부
        imgList: [],                    // 자료첨부
        runAble: '0',
        sltPrgImprvPk: -1,
        opnnClctnInf : {
            yyyy: '', qtr: '', bnfcrgrNm: '', idxNm: '', wrtDt: '', wrtr: '', imprvOpn: '',
        },
        opnnRflctInf: {
            rflDt: '', rflWrtr: '', rflWrtrMmbrPk: -1, imprvRfl: '',
        },
        lmtQtr: { strtDt: '', endDt: '', },
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },     // 통합 팝업 제어 (01: 직원 선택)
    }),
};
</script>