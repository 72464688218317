<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="8">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">영양상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="1">
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">영양</span>
                        </v-row>
                    </v-col>
                    <v-col cols="11">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 32px;" cols="2">
                                <span class="font-weight-medium">영양상태</span>
                            </v-col>
                            <v-col class="" style="padding-top: 1px; padding-bottom: 2px;" cols="10">
                                <v-radio-group
                                    v-model="inpRadio.val_1_1"
                                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                    <v-radio
                                        class="pl-2 pr-0" style="padding-bottom: 2px !important;"
                                        :ripple="false" label="양호: 건강 및 섭식, 영양 등에 문제가 없는 상태" value="1">
                                    </v-radio>
                                    <v-radio
                                        class="pl-2 pr-0" style="padding-bottom: 2px !important;"
                                        :ripple="false" label="불량: 건강, 섭식, 영양 등에 문제가 있어 세심한 관찰이 요구" value="2">
                                    </v-radio>
                                    <v-radio
                                        class="dft-rdo-line pl-2 pr-0" style="padding-bottom: 2px !important;"
                                        :ripple="false" label="심한불량: 극도의 건강, 섭식, 영양 등에 문제가 있어 치료적 처치가 필요한 상태" value="3">
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 32px;" cols="2">
                                <span class="font-weight-medium">식사형태</span>
                            </v-col>
                            <v-col class="pa-2" cols="10">
                                <v-row class="pa-0 mb-1 align-center" no-gutters>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_2_1" :ripple="false"
                                        true-value="1" false-value="2" label="일반식"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_2_2" :ripple="false"
                                        true-value="1" false-value="2" label="다진식"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_2_3" :ripple="false"
                                        true-value="1" false-value="2" label="죽"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_2_4" :ripple="false"
                                        true-value="1" false-value="2" label="미음"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_2_5" :ripple="false"
                                        true-value="1" false-value="2" label="경관식"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                </v-row>
                                <v-row class="pa-0 mb-1 align-center" no-gutters>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_2_6" :ripple="false"
                                        true-value="1" false-value="2" label="기타"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                                    <span class="d-inline-block" style="width: 272px;">
                                        <v-text-field
                                            v-model="inpTxt.val_2_6"
                                            class="" height="28" color="blue"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="2">
                                <span class="font-weight-medium">소화상태</span>
                            </v-col>
                            <v-col class="pa-2" cols="10">
                                <v-row class="pa-0 align-center" no-gutters>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_3_1" :ripple="false"
                                        true-value="1" false-value="2" label="저작곤란"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_3_2" :ripple="false"
                                        true-value="1" false-value="2" label="소화불량"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_3_3" :ripple="false"
                                        true-value="1" false-value="2" label="오심·구토"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_3_4" :ripple="false"
                                        true-value="1" false-value="2" label="기타"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                                    <span class="d-inline-block" style="width: 154px;">
                                        <v-text-field
                                            v-model="inpTxt.val_3_4"
                                            class="" height="28" color="blue"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="2">
                                <span class="font-weight-medium">연하상태</span>
                            </v-col>
                            <v-col class="pa-2" cols="10">
                                <v-row class="pa-0 align-center" no-gutters>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_4_1" :ripple="false"
                                        true-value="1" false-value="2" label="양호"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_4_2" :ripple="false"
                                        true-value="1" false-value="2" label="가끔 사레걸림"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_4_3" :ripple="false"
                                        true-value="1" false-value="2" label="자주 사레걸림"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_4_4" :ripple="false"
                                        true-value="1" false-value="2" label="연하곤란"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="1">
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">배설</span>
                        </v-row>
                    </v-col>
                    <v-col cols="11">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="2">
                                <v-row class="pa-0 justify-center align-center" no-gutters>
                                    <span class="font-weight-medium">소변상태</span>
                                    <v-btn
                                        style="margin-left: 2px;" width="18" height="18"
                                        @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 44)"
                                        icon>
                                        <v-icon size="17">mdi-help-circle-outline</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <v-col class="pa-2" cols="10">
                                <v-row class="pa-0 align-center" no-gutters>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_6_1" :ripple="false"
                                        true-value="1" false-value="2" label="양호"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_6_2" :ripple="false"
                                        true-value="1" false-value="2" label="요실금"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_6_3" :ripple="false"
                                        true-value="1" false-value="2" label="배뇨곤란"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme v-check_blue_fnt pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_6_4" :ripple="false"
                                        true-value="1" false-value="2" label="기저귀"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme v-check_blue_fnt pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_6_5" :ripple="false"
                                        true-value="1" false-value="2" label="유치도뇨"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme v-check_blue_fnt pt-0" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_6_6" :ripple="false"
                                        true-value="1" false-value="2" label="방광루"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 10px;" cols="2">
                                <span class="font-weight-medium">대변상태</span>
                            </v-col>
                            <v-col class="pa-2" cols="10">
                                <v-row class="pa-0 align-center" no-gutters>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_7_1" :ripple="false"
                                        true-value="1" false-value="2" label="양호"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_7_2" :ripple="false"
                                        true-value="1" false-value="2" label="지속적인 설사"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_7_3" :ripple="false"
                                        true-value="1" false-value="2" label="변비"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_7_4" :ripple="false"
                                        true-value="1" false-value="2" label="기저귀"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                        v-model="inpCheck.val_7_5" :ripple="false"
                                        true-value="1" false-value="2" label="장루"
                                        on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                        hide-details>
                                    </v-checkbox>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="1">
                        <v-row class="pa-0 justify-center" no-gutters>
                            <span class="font-weight-medium">식품</span>
                        </v-row>
                    </v-col>
                    <v-col cols="11">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                                <v-row class="pa-0 justify-center" no-gutters>
                                    <span class="font-weight-medium">기피식품</span>
                                </v-row>
                            </v-col>
                            <v-col class="pa-2" cols="10">
                                <v-row class="pa-0 mb-1 align-center" no-gutters>
                                    <v-radio-group
                                        v-model="inpRadio.val_8_1"
                                        class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                        <v-radio
                                            class="px-0" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="없음" value="1">
                                        </v-radio>
                                        <v-radio
                                            class="px-0" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="있음" value="2">
                                        </v-radio>
                                    </v-radio-group>
                                    <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                                    <span class="mr-2" style="font-size: 0.98rem;">사유:</span>
                                    <span class="d-inline-block" style="width: 317px;">
                                        <v-text-field
                                            v-model="inpTxt.val_8_1"
                                            class="" height="28" color="blue"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                                </v-row>
                                <v-row class="pa-0 mb-1 align-center" no-gutters>
                                    <span class="ml-1 mr-2" style="font-size: 0.98rem;">대체식품:</span>
                                    <span class="d-inline-block" style="width: 432px;">
                                        <v-text-field
                                            v-model="inpTxt.val_8_2"
                                            class="" height="28" color="blue"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                </v-row>
                                <v-row class="pa-0 align-center" no-gutters>
                                    <span class="ml-1 mr-2" style="font-size: 0.98rem;">알레르기:</span>
                                    <v-radio-group
                                        v-model="inpRadio.val_8_3"
                                        class="radio-dft-Theme mt-0" ref="info" hide-details row>
                                        <v-radio
                                            class="px-0" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="없음" value="2">
                                        </v-radio>
                                        <v-radio
                                            class="px-0" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="있음" value="1">
                                        </v-radio>
                                    </v-radio-group>
                                    <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                                    <span class="mr-2" style="font-size: 0.98rem;">종류:</span>
                                    <span class="d-inline-block" style="width: 250px;">
                                        <v-text-field
                                            v-model="inpTxt.val_8_3"
                                            class="" height="28" color="blue"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="4">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="py-2" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="25" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'NtrtState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '1' && itm.valCd == '1')
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                else if(itm.depth3 == '2' && itm.valCd == '6')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '3' && itm.valCd == '4')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '8' && itm.valCd == '1'){
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                }
                else if(itm.depth3 == '8' && itm.valCd == '2')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '8' && itm.valCd == '3'){
                    this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                }
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });

        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let grnd = {}
                grnd.examPk = 0
                grnd.depth2 = '10'
                grnd.depth3 = '99'
                grnd.valCd = '1'
                grnd.val = ''
                grnd.val2 = this.inpTxt.val_99_1
                finArr.push(grnd)

                let meal = {}
                meal.examPk = 0
                meal.depth2 = '10'
                meal.depth3 = '8'
                meal.valCd = '2'
                meal.val = ''
                meal.val2 = this.inpTxt.val_8_2
                finArr.push(meal)

                Object.keys(this.inpRadio).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '10'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpRadio[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '8' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '8' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
   
                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '10'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '2' && oObj.valCd == '6')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '3' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt[itm]
                    
                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    if(oObj.depth3 == '1' || oObj.depth3 == '8')
                        oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else
                        oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '2' && oObj.valCd == '6')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '3' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '8')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr

        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = ''
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 식사형태-기타, 소화상태-기타, 기피식품-사유, 기피식품-대체식품, 기피식품-종류, 판단근거
        inpTxt: { val_2_6: '', val_3_4: '', val_8_1: '', val_8_2: '', val_8_3: '', val_99_1: '' },
        // 식사형태 (d3: 1) - 1, 2, 3
        // 기피식품 (d3: 8) - 1. 있음, 2. 없음
        inpRadio: { val_1_1: '0', val_8_1: '0', val_8_3: '0' },
        // 식사형태 (d3: 2) - cd1: 일반식, cd2: 다진식, cd3: 죽, cd4: 미음, cd5: 경관식, cd6: 기타(입력)
        // 소화상태 (d3: 3) - cd1: 저작곤란, cd2: 소화불량, cd3: 오심구토, cd4: 기타(입력)
        // 연하상태 (d3: 4) - cd1: 양호, cd2: 가끔 사레 걸림, cd3: 자주 사레 걸림, cd4: 연하곤란
        // 소변상태 (d3: 6) - cd1: 양호, cd2: 요실금, cd3: 배뇨곤란, cd4: 기저귀, cd5: 유치도뇨, cd6: 방광루
        // 대변상태 (d3: 7) - cd1: 양호, cd2: 지속적인 설사, cd3: 변비, cd4: 기저귀, cd5: 장루
        inpCheck: {
            val_2_1: '2', val_2_2: '2', val_2_3: '2', val_2_4: '2', val_2_5: '2', val_2_6: '2',
            val_3_1: '2', val_3_2: '2', val_3_3: '2', val_3_4: '2',
            val_4_1: '2', val_4_2: '2', val_4_3: '2', val_4_4: '2',
            val_6_1: '2', val_6_2: '2', val_6_3: '2', val_6_4: '2', val_6_5: '2', val_6_6: '2',
            val_7_1: '2', val_7_2: '2', val_7_3: '2', val_7_4: '2', val_7_5: '2',
        },
    }),
};
</script>