<template>
    <v-row class="pa-0 justify-center align-end" no-gutters>
        <v-btn
            class="nmlBfClrImp-white" color="btnBrdrColor" width="26" height="26"
            :disabled="prevBtn" @click="moveEvt('prvs')"
            x-small outlined>
            <v-icon color="black">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
            class="ml-1 nmlBfClrImp-white" color="btnBrdrColor" width="26" height="26"
            :disabled="nxtBtn" @click="moveEvt('nxt')"
            x-small outlined>
            <v-icon color="black">mdi-chevron-right</v-icon>
        </v-btn>
        <span class="text-h5 font-weight-bold topTitleColor--text mx-3">
            {{ $moment(sltTrgDt, 'YYYY-MM').format('YYYY') }}년 {{ $moment(sltTrgDt, 'YYYY-MM').quarter() }}분기
        </span>
        <v-menu
            v-model="isClndr" :close-on-content-click="false"
            transition="scale-transition" offset-y min-width="auto" dense>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="nmlBfClrImp-white" color="btnBrdrColor" style="padding-top: 1px;"
                    v-bind="attrs" v-on="on" width="26" height="26" outlined x-small>
                    <v-icon color="black" size="16">mdi-calendar-month-outline</v-icon>
                </v-btn>
            </template>
            <v-date-picker
                v-model="sltTrgDt"
                class="cmmDtPckr cmmQtrPckr" type="month" locale="en-US"
                :weekday-format="getDay" :month-format="getMonth"
                :min="aplyMin" :max="aplyMax" :allowed-dates="alwMnth" :key="forceRender"
                @click:month="isClndr = false" 
                no-title scrollable>
            </v-date-picker>
        </v-menu>
    </v-row>
</template>

<script>
    export default {
        name: 'CmmYearQtrSelector',

        props : {
            applyDt: { type: String, default: '' },     // 적용일
            minDt: { type: String, default: '' },       // 최소
            maxDt: { type: String, default: '' },       // 최대
        },
        
        components: {
        
        },

        created: function(){

        },

        mounted: function(){
            this.$nextTick(function () {
                if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.applyDt + '-01'))
                    this.sltTrgDt = this.applyDt
                else
                    this.sltTrgDt = this.$moment().format('YYYY-MM')

                //this.initComp()
            })
        },

        watch: {
            'applyDt': function(){
                if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.applyDt + '-01'))
                    this.sltTrgDt = this.applyDt
            },
            'sltTrgDt': function(){
                let trg = this.$moment(this.sltTrgDt, 'YYYY-MM')
                if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.minDt + '-01')){
                    let tmp = this.$moment(this.minDt, 'YYYY-MM').startOf('quarter')
                    this.prevBtn = trg.isSameOrBefore(tmp)
                }
                if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.maxDt + '-01')){
                    let tmp = this.$moment(this.maxDt, 'YYYY-MM').startOf('quarter')
                    this.nxtBtn = trg.isSameOrAfter(tmp)
                }

                this.$emit('cnfDt', this.sltTrgDt)
                this.forceRender += 1
            },
            'minDt': function(){
                let trg = this.$moment(this.sltTrgDt, 'YYYY-MM')
                let tmp = this.$moment(this.minDt, 'YYYY-MM').startOf('quarter')
                this.prevBtn = trg.isSameOrBefore(tmp)

                this.aplyMin = tmp.format('YYYY-MM')
            },
            'maxDt': function(){
                let trg = this.$moment(this.sltTrgDt, 'YYYY-MM')
                let tmp = this.$moment(this.maxDt, 'YYYY-MM').startOf('quarter')
                this.nxtBtn = trg.isSameOrAfter(tmp)

                this.aplyMax = tmp.format('YYYY-MM')
            },
        },
    
        methods: {
            initComp: function(){
                //if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.applyDt + '-01') == false)
                // if(this.yyyy != 1900)
                //     this.sltYyyy = this.yyyy
                // else
                //     this.sltYyyy = this.$moment().format('YYYY')

                // if(this.qtr > 0 && this.qtr < 5 )
                //     this.sltQtr = this.qtr
                // else
                //     this.sltQtr = this.$moment().quarter()

                // this.sltTrgDt = this.$moment(this.sltYyyy, 'YYYY').quarter(this.sltQtr).startOf('quarter').format('YYYY-MM')
            },
            moveEvt: function (typ) {
                let tmp = ''
                if(typ == 'prvs')
                    tmp = this.$moment(this.sltTrgDt, 'YYYY-MM').subtract(3, 'month')
                else if(typ == 'nxt')
                    tmp = this.$moment(this.sltTrgDt, 'YYYY-MM').add(3, 'month')

                this.sltTrgDt = tmp.format('YYYY-MM')
            },

            getDay: function (date) {
                const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
                let i = new Date(date).getDay(date)
                return daysOfWeek[i]
            },

            getMonth: function(date) {
                let monthName = [
                    '1분기',
                    '',
                    '',
                    '2분기',
                    '',
                    '',
                    '3분기',
                    '',
                    '',
                    '4분기',
                    '',
                    '',
                ];

                let i = new Date(date).getMonth(date)
                return monthName[i]
            },

            alwMnth: function (v) {
                let chcMnth = this.$moment(v, 'YYYY-MM').format('MM')
                let chc = false

                switch (chcMnth) {
                    case '01':
                    case '04':
                    case '07':
                    case '10':
                        chc = true
                        break;
                    default:
                        chc = false
                        break;
                }

                return chc
            },
        },

        data: () => ({
            sltTrgDt: '',
            aplyMin: '',
            aplyMax: '',
            isClndr: false,
            prevBtn: false,
            nxtBtn: false,
            forceRender: 0,
        }),
    };
</script>