<template>
    <v-sheet class="posn-rltv ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">프로그램&nbsp;유형관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-form ref="mdlform" lazy-validation>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid pa-2" cols="6">
                    <span class="black--text font-weight-medium">프로그램 구분</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBL pa-2" cols="4">
                    <span class="black--text font-weight-medium">프로그램 유형</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBL" style="padding-top: 10px;" cols="2">
                    <v-btn                    
                        @click="addRow()"
                        class="ml-1" color="blue200" height="26" icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pb-1" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-plus-circle-outline</v-icon>
                                </div>
                            </template>
                            <span>추가</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row v-for="(item, i) in items" :key="i" class="pa-0 mr-1 tableBB" no-gutters>
                <v-col class="txtAlgnMid pa-1" cols="6">
                    <v-text-field
                        v-model="item.prgTypNm"
                        color="blue" height="28"
                        :rules="[rules.required]"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="txtAlgnMid tableBL pa-1" cols="4">
                    <v-select
                        v-model="item.idxCd"
                        :items="cdItems" :rules="[rules.selectCheck]"
                        item-text="valcdnm" item-value="valcd"
                        hide-details outlined dense/>
                </v-col>
                <v-col class="d-flex align-center justify-center tableBL pa-1" cols="2">
                    <v-btn                    
                        @click="onSave(i)"
                        class="" color="blue200" height="26" icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pb-1" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-content-save-outline</v-icon>
                                </div>
                            </template>
                            <span>저장</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn
                        v-if="item.prgTypMngPk > 0"              
                        @click="onDelete(i)"
                        class="" color="grey006" height="26" icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pb-1" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-trash-can-outline</v-icon>
                                </div>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn
                        v-else         
                        @click="onDelete1(i)"
                        class="" color="grey006" height="26" icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pb-1" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-minus-circle-outline</v-icon>
                                </div>
                            </template>
                            <span>제거</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import { selPrgTypMnglist, insPrgTypMng, delPrgTypMng } from '../../api/prgrm.js';
import { getStcd } from '../../api/index.js';

export default {
    name: 'PrgrmCategory',

    components: {
 
    },

    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.onShow()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                let chc = this.items.findIndex( v => v.rmPosn == true )
                if(chc > -1){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgTypMngPk: this.items[this.key].prgTypMngPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgTypMng(obj)
                        .then((response) => (this.delPrgTypMngAfter(response.data)))
                        .catch((error) => console.log(error))
                }

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                delete this.items[chc].rmPosn
                this.runAble = '0'
            }
        },
        'items': function () {
            if (this.items.length < 1) console.log('code Mapping Failed')
            else {
                this.items.forEach((item) => {
                    this.cdItems.forEach((cd) => {
                        if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                    });//end foreach
                });//end foreach
            }//end else

        },
    },

    methods: {
        stCdMapping: function (res) {
            this.cdItems = res
        },
        onShow() {
            this.getPrgTypMng()
            this.$refs.mdlform.resetValidation()
        },
        onClose: function () {
            this.$emit('isClose', '.')
            this.$emit('modalEvent', 1)
        },
        getPrgTypMng() {
            let fcltyNum = this.$store.getters.getFcltyNum
            this.valClcd = '40'
            this.valClcdnm = '지표구분'
            selPrgTypMnglist(fcltyNum, '40')
                .then((response) => (this.resData(response.data)))
                .catch((error) => console.log(error))
        },
        resData(data) {
            this.items = data
        },
        addRow() {
            let addObj = {
                idxCd: '',
                prgTypNm: '',
                fcltyNum: '',
                prgTypMngPk: '',
                useYn: '1',
            }
            this.items.push(addObj)
        },
        onSave(key) {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    idxCd: this.items[key].idxCd,
                    prgTypNm: this.items[key].prgTypNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    prgTypMngPk: this.items[key].prgTypMngPk,
                    useYn: '1',
                }
                insPrgTypMng(obj)
                    .then((response) => (this.insPrgTypMngAfter(response.data)))
                    .catch((error) => console.log(error))
            }
        },
        insPrgTypMngAfter(res) {
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.onShow()
                this.$emit('reload', true)
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        delPrgTypMngAfter(res) {
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.onShow()
                this.$emit('reload', true)
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        onDelete1(key) {
            this.items.splice(key, 1)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.items[key].rmPosn = true
                this.runAble = '2'

                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '프로그램 구분: ' + this.items[key].prgTypNm
                obj.cntnt = ['프로그램을 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   
            } else {
                if (key != 0) {
                    this.items.splice(key, 1)
                }
            }
        },
    },
    
    data: () => ({
        key: 0,
        items: [],
        cdItems: [],
        runAble: '0',
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
    }),
};
</script>