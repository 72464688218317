<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">인지상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0 nmlBfClrImp-greyE01" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="11">
                        <span class="font-weight-medium">구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="1">
                        <span class="font-weight-medium">확인</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">1</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">지남력 저하</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_1_1" :ripple="false"
                            true-value="1" false-value="2" label="날짜·시간"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_1_2" :ripple="false"
                            true-value="1" false-value="2" label="장소"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_1_3" :ripple="false"
                            true-value="1" false-value="2" label="사람"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_1_4" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">2</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">기억력 저하</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_2_1" :ripple="false"
                            true-value="1" false-value="2" label="단기"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_2_2" :ripple="false"
                            true-value="1" false-value="2" label="장기"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_2_3" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">3</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">주의 집중력 저하</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_3_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">4</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">계산력 저하</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_4_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">5</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">판단력 저하</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_5_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">6</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">부적절한 옷입기</span>
                        <span class="" style="font-size: 0.98rem;">&#40;</span>
                        <span class="fontOneRem mx-1">상하의 구분 못함, 겉옷과 속옷 구분 못함 등</span>
                        <span class="" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_6_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">7</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">망상</span>
                        <span class="" style="font-size: 0.98rem;">&#40;</span>
                        <span class="fontOneRem mx-1">부적절한 믿음, 편집증 등</span>
                        <span class="" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_7_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">8</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">배회</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_8_1" :ripple="false"
                            true-value="1" false-value="2" label="밖으로 나가려함"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_8_2" :ripple="false"
                            true-value="1" false-value="2" label="의미없는 서성거림"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_8_3" :ripple="false"
                            true-value="1" false-value="2" label="길 잃음"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_8_4" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">9</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">환각</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_9_1" :ripple="false"
                            true-value="1" false-value="2" label="환시"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_9_2" :ripple="false"
                            true-value="1" false-value="2" label="환청"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_9_3" :ripple="false"
                            true-value="1" false-value="2" label="환미"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_9_4" :ripple="false"
                            true-value="1" false-value="2" label="환촉"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_9_5" :ripple="false"
                            true-value="1" false-value="2" label="기타"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_9_6" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">10</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">반복적인 행동</span>
                        <span class="" style="font-size: 0.98rem;">&#40;</span>
                        <span class="fontOneRem mx-1">물건감추기, 짐싸기 등</span>
                        <span class="" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_10_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">11</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">부적절한 행동</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_11_1" :ripple="false"
                            true-value="1" false-value="2" label="부적절한 성적행동"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_11_2" :ripple="false"
                            true-value="1" false-value="2" label="부적절한 일반행동"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_11_3" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">12</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">폭력적 행동</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_12_1" :ripple="false"
                            true-value="1" false-value="2" label="신체적인 공격"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_12_2" :ripple="false"
                            true-value="1" false-value="2" label="폭언"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_12_3" :ripple="false"
                            true-value="1" false-value="2" label="도움에의 저항"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_12_4" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">13</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">야간수면장애</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_13_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">14</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">불결행동</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_14_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 5px;" cols="1">
                        <span class="fontNineThRem">15</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3 py-1" cols="10">
                        <span class="fontOneRem mr-2">식습관 변화</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_15_1" :ripple="false"
                            true-value="1" false-value="2" label="식욕 저하"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_15_2" :ripple="false"
                            true-value="1" false-value="2" label="식욕 증가"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_15_3" :ripple="false"
                            true-value="1" false-value="2" label="기타"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <span class="d-inline-block" style="width: 138px;">
                            <v-text-field
                                v-model="inpTxt.val_15_3"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_15_4" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">16</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">먹는 것이 아닌 물건을 먹음</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_16_1" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">17</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">불안</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_17_1" :ripple="false"
                            true-value="1" false-value="2" label="혼자 남겨짐에 대한 공포"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_17_2" :ripple="false"
                            true-value="1" false-value="2" label="초조"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_17_3" :ripple="false"
                            true-value="1" false-value="2" label="안절부절"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_17_4" :ripple="false"
                            true-value="1" false-value="2" label="기타"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_17_5" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0;" cols="1">
                        <span class="fontNineThRem">18</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-3" cols="10">
                        <span class="fontOneRem mr-2">우울</span>
                        <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_18_1" :ripple="false"
                            true-value="1" false-value="2" label="두려움"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_18_2" :ripple="false"
                            true-value="1" false-value="2" label="무기력함"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_18_3" :ripple="false"
                            true-value="1" false-value="2" label="절망"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-center" cols="1">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                            v-model="inpCheck.val_18_4" :ripple="false"
                            true-value="1" false-value="2"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 6px; padding-bottom: 5px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="31" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'CgntnState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '15' && itm.valCd == '3')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt.val_99_1 = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oObj = {}
                oObj.examPk = 0
                oObj.depth2 = '3'
                oObj.depth3 = '99'
                oObj.valCd = '1'
                oObj.val = ''
                oObj.val2 = this.inpTxt.val_99_1
                finArr.push(oObj)
   
                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '3'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '15' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '15' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr

        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 식습관변화-기타, 판단근거
        inpTxt: { val_15_3: '', val_99_1: '' },
        // 
        inpRadio: {  },
        // 지남력 저하 (d3: 1) - cd1: 날짜시간, cd2: 장소, cd3: 사람, cd4: 확인
        // 기억력 저하 (d3: 2) - cd1: 단기, cd2: 장기, cd3: 확인
        // 주의집중력 저하 (d3: 3) - cd1: 확인
        // 계산력 저하 (d3: 4) - cd1: 확인
        // 판단력 저하 (d3: 5) - cd1: 확인
        // 부적절한 옷입기 (d3: 6) - cd1: 확인
        // 망상(부적절한 믿음, 편집증 등) (d3: 7) - cd1: 확인
        // 배회 (d3: 8) - cd1: 밖으로 나가려함, cd2: 의미없는 서성거림, cd3: 길 잃음, cd4: 확인
        // 환각 (d3: 9) - cd1: 환시, cd2: 환청, cd3: 환미, cd4: 환촉, cd5: 기타, cd6: 확인
        // 반복적인 행동(물건감추기, 짐싸기 등) (d3: 10) - cd1: 확인
        // 부적절한 행동 (d3: 11) - cd1: 부적절한 성적행동, cd2: 부적절한 일반행동, cd3: 확인
        // 폭력적 행동 (d3: 12) - cd1: 신체적인 공격, cd2: 폭언, cd3: 도움에의 저항, cd4: 확인
        // 야간수면장애 (d3: 13) - cd1: 확인
        // 불결행동 (d3: 14) - cd1: 확인
        // 식습관 변화 (d3: 15) - cd1: 식욕 저하, cd2: 식욕 증가, cd3: 기타, cd4: 확인
        // 먹는 것이 아닌 물건을 먹음 (d3: 16) - cd1: 확인
        // 불안 (d3: 17) - cd1: 혼자 남겨짐에 대한 공포, cd2: 초조, cd3: 안절부절, cd4: 기타, cd5: 확인
        // 우울 (d3: 18) - cd1: 두려움, cd2: 무기력함, cd3: 절망, cd4: 확인
        inpCheck: {
            val_1_1: '2', val_1_2: '2', val_1_3: '2', val_1_4: '2',
            val_2_1: '2', val_2_2: '2', val_2_3: '2',
            val_3_1: '2', val_4_1: '2', val_5_1: '2', val_6_1: '2', val_7_1: '2', val_10_1: '2',
            val_8_1: '2', val_8_2: '2', val_8_3: '2', val_8_4: '2',
            val_9_1: '2', val_9_2: '2', val_9_3: '2', val_9_4: '2', val_9_5: '2', val_9_6: '2',
            val_11_1: '2', val_11_2: '2', val_11_3: '2',
            val_12_1: '2', val_12_2: '2', val_12_3: '2', val_12_4: '2',
            val_13_1: '2', val_14_1: '2', val_16_1: '2',
            val_15_1: '2', val_15_2: '2', val_15_3: '2', val_15_4: '2',
            val_17_1: '2', val_17_2: '2', val_17_3: '2', val_17_4: '2', val_17_5: '2',
            val_18_1: '2', val_18_2: '2', val_18_3: '2', val_18_4: '2',
        },
    }),
};
</script>

