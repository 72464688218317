<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">신체상태</span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-1">&#40; 일상생활동작 수행능력 &#41;</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0 nmlBfClrImp-greyE01" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="font-weight-medium">기본동작 항목</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                        <span class="font-weight-medium">확인</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="font-weight-medium">신체기능 항목</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="2">
                        <span class="font-weight-medium">확인</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;"  cols="1">
                        <span class="fontOneRem">1</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">일어나 앉기</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_1_1"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">1</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">체위변경하기</span>
                    </v-col>
                    <v-col class="pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_2_1"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">2</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">일어서기</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_1_2"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">2</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">세수하기</span>
                    </v-col>
                    <v-col class="pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_2_2"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">3</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">이동 (옮겨 앉기)</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_1_3"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">3</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">양치질 (틀니관리)</span>
                    </v-col>
                    <v-col class="pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_2_3"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">4</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">실내보행 (보장구 사용)</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_1_4"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">4</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">화장실 (이동변기) 사용</span>
                    </v-col>
                    <v-col class="pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_2_4"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">5</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">휠체어 이동</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_1_5"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">5</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">기저귀 갈기</span>
                    </v-col>
                    <v-col class="pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_2_5"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-divider style="margin-top: 10px;" color="black"></v-divider>
                <v-row class="pa-0 nmlBfClrImp-greyE01" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="font-weight-medium">일상생활 동작</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                        <span class="font-weight-medium">확인</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">1</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">식사하기</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_3_1"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">2</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">옷 벗고 입기</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_3_2"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">3</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">목욕하기</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_3_3"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                        <span class="fontOneRem">4</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                        <span class="fontOneRem">몸단장하기</span>
                    </v-col>
                    <v-col class="tableBR pa-1" cols="2">
                        <v-select
                            v-model="inpRadio.val_3_4"
                            :items="asstNdd" 
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 mt-2" no-gutters>
                    <v-spacer></v-spacer>
                    <span class="fontOneRem grey006--text">※ 도움필요도 표기: 상(전적인 도움), 중(수행도움), 하(준비·지켜보기 도움), 최하(혼자수행)</span>
                </v-row>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 4px; padding-bottom: 3px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="23" 
                        v-model="inpTxt.val_4_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'PhysState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpRadio['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '4' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oObj = {}
                oObj.examPk = 0
                oObj.depth2 = '1'
                oObj.depth3 = '4'
                oObj.valCd = '1'
                oObj.val = ''
                oObj.val2 = this.inpTxt.val_4_1
                finArr.push(oObj)

                Object.keys(this.inpRadio).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '1'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpRadio[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '4' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpRadio['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '4'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpRadio).forEach(itm => {
                this.inpRadio[itm] = '0'
            });
        },
    },

    data: () => ({
        // 판단근거
        inpTxt: { val_4_1: '' },
        // 기본동작 (d3: 1) - cd1: 일어나 앉기, cd2: 일어서기, cd3: 이동(옮겨 앉기), cd4: 실내보행(보장구사용), cd5: 휠체어 이동
        // 신체기능 (d3: 2) - cd1: 체위변경하기, cd2: 세수하기, cd3: 양치질(틀니관리), cd4: 화장실(이동변기)사용, cd5: 기저귀 갈기
        // 일상생활동작 (d3: 3) - cd1: 식사하기, cd2: 옷벗고 입기, cd3: 목욕하기, cd4: 몸단장하기
        inpRadio: {
            val_1_1: '', val_1_2: '', val_1_3: '',  val_1_4: '', val_1_5: '',
            val_2_1: '', val_2_2: '', val_2_3: '',  val_2_4: '', val_2_5: '',
            val_3_1: '', val_3_2: '', val_3_3: '',  val_3_4: '',
        },
        // 
        inpCheck: {  },
        // 도움필요 항목
        asstNdd: [
            { valcd: '', valcdnm: '' }, { valcd: '1', valcdnm: '상' }, { valcd: '2', valcdnm: '중' },
            { valcd: '3', valcdnm: '하' }, { valcd: '4', valcdnm: '최하' }
        ]
    }),
};
</script>