<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="py-7 pl-7 pr-6 midAreaColor" cols="4">
                <v-sheet class="" color="midAreaColor">
                    <p class="hamletFont text-h5 topTitleColor--text font-weight-black">
                        <span>
                            그룹별
                            <span v-if="compTyp =='bnfcr'">수급자</span>
                            <span v-else-if="compTyp =='prgm'">프로그램</span>
                            정보
                        </span>
                    </p>
                    <v-row class="pa-0 mt-4" no-gutters>
                        <exBtn ref="exBtnClose" :btnData="exBtn[0]" @changeCheck="filterSearch" />
                        <v-spacer></v-spacer>
                        <span class="d-inline-block" style="width: 150px; height: 34px;">
                            <v-text-field
                                @input="schAct"
                                class="txt-Theme" height="34" label="그룹명 조회"
                                background-color="white" prepend-inner-icon="mdi-magnify"
                                single-line outlined dense filled hide-details>
                            </v-text-field>
                        </span>
                    </v-row>
                    <v-divider class="midAreaColor my-1"></v-divider>
                    <v-data-table
                        class="dft-hvr-tbl" height="559" locale="ko"
                        :headers="headers" :items="prgBnfcrGrpList"
                        :search="schDummy" :custom-filter="customFilter"
                        fixed-header hide-default-header disable-pagination hide-default-footer dense>

                        <template v-slot:header="props">
                            <th class="black--text bottomBorder px-1 py-3" style="width: 70px;">{{ props.props.headers[0].text }}</th>
                            <th class="black--text bottomBorder px-1 py-3" style="width: 100px;">{{ props.props.headers[1].text }}</th>
                            <th class="black--text bottomBorder px-1 py-3" style="width: 125px;">{{ props.props.headers[2].text }}</th>
                            <th class="black--text bottomBorder px-1 py-3" style="width: 86px;">{{ props.props.headers[3].text }}</th>
                        </template>
                        <template v-slot:item='{ item }'>
                            <tr
                                :class="[tblIdx == item.prgBnfcrgrInfPk ? 'onClr' : '']" 
                                @click="chcTblIdx(item)">
                                <td class="txtAlgnMid tableBB pa-1">
                                    <span class="fontOneRem">{{ item.rn }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB pa-1">
                                    <div class="overflow-text-hidden" style="width: 92px;">
                                        <span class="fontOneRem">{{ item.idxnm }}</span>
                                    </div>
                                </td>
                                <td class="txtAlgnMid tableBB pa-1">
                                    <div class="overflow-text-hidden" style="width: 117px;">
                                        <span class="fontOneRem">{{ item.bnfcrgrNm }}</span>
                                    </div>
                                </td>
                                <td class="txtAlgnMid tableBB pa-1">
                                    <span v-if="item.useYn == '1'" class="fontOneRem">사용</span>
                                    <span v-else-if="item.useYn == '2'" class="fontOneRem">미사용</span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-divider class="midAreaColor my-1"></v-divider>
                    <v-row class="pa-0 justify-end" no-gutters>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 3px !important;"
                            v-model="chcInclude" @change="resetTbl"
                            true-value="1" false-value="2" label="미사용 포함"
                            on-icon="mdi-checkbox-marked-outline" off-icon="mdi-checkbox-blank-outline"
                            hide-details>
                        </v-checkbox>
                    </v-row>
                </v-sheet>
            </v-col>
            <v-col class="py-7 pr-7 pl-6" cols="8">
                <v-sheet class="" color="">
                    <v-row class="pa-0 justify-center align-center" no-gutters>
                        <v-spacer></v-spacer>
                        <v-icon color="black" @click="$emit('isClose', '.')" x-large>mdi-close</v-icon>
                    </v-row>
                    <v-divider class="mr-1" color="black" style="margin-top: 6px;"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">수급자 그룹</span>
                        </v-col>
                        <v-col class="overflow-text-hidden txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="4">
                            <span class="fontOneRem">{{ grpByBnfcrInf.bnfcrgrNm }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
                        </v-col>
                        <v-col class="overflow-text-hidden txtAlgnMid pb-1" style="padding-top: 3px;" cols="4">
                            <span class="fontOneRem">{{ grpByBnfcrInf.idxCdNm }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mr-1" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">설명</span>
                        </v-col>
                        <v-col class="overflow-text-hidden px-3 pb-1" style="padding-top: 3px;" cols="10">
                            <span class="fontOneRem">{{ grpByBnfcrInf.bnfcrgrDesc }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-divider class="white my-2"></v-divider>
                    <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">
                            그룹별 
                            <span v-if="compTyp =='bnfcr'">수급자</span>
                            <span v-else-if="compTyp =='prgm'">프로그램</span>
                            목록
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="compTyp =='prgm'"
                            class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" min-width="20" height="20"
                            @click="onPrgmGrpMdl" :disabled="tblIdx == -1"
                            small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">그룹별 프로그램 설정</span>
                        </v-btn>
                        <v-btn
                            v-if="compTyp =='prgm'"
                            class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" min-width="20" height="20"
                            @click="onLclMdl('11', 900)" :disabled="tblIdx == -1"
                            small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">그룹별 프로그램 내역</span>
                        </v-btn>
                    </v-row>
                    <div v-if="compTyp =='bnfcr'">
                        <v-divider :class="[grpByBnfcrSttList.length > 15 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
                        <v-row
                            :class="['nmlBfClrImp-greyE01 pa-0', grpByBnfcrSttList.length > 15 ? 'mr-2__5' : 'mr-1']"
                            no-gutters>
                            <v-col class="tableBR" cols="3">
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="6">
                                        <span class="fontOneDotOneRem font-weight-bold">수급자</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid pb-2" style="padding-top: 7px;" cols="6">
                                        <span class="fontOneDotOneRem font-weight-bold">생년월일</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="1">
                                <span class="fontOneDotOneRem font-weight-bold">등급</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">낙상위험도</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">욕창위험도</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">인지기능</span>
                            </v-col>
                            <v-col class="txtAlgnMid pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">그룹 설정일</span>
                            </v-col>
                        </v-row>
                        <v-divider :class="[grpByBnfcrSttList.length > 15 ? 'mr-2__5' : 'mr-1']"></v-divider>
                        <div v-if="grpByBnfcrSttList.length > 0" class="overflow-y-auto" style="width: 100%; height: 482px;">
                            <v-row
                                :class="['tableBB', grpByBnfcrSttList.length > 15 ? '' : 'mr-1']"
                                v-for="(itm, i) in grpByBnfcrSttList" :key="i"
                                no-gutters>
                                <v-col class="tableBR" cols="3">
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="6">
                                            <span class="fontOneRem">{{ itm.name }}</span>
                                            <span v-if="itm.gndr == 'M'" class="fontOneRem"> &#40; 남 &#41;</span>
                                            <span v-else-if="itm.gndr == 'F'" class="fontOneRem"> &#40; 여 &#41;</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid py-1" cols="6">
                                            <span class="fontOneRem">{{ $moment(itm.birth, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                    <span v-if="itm.acknwRtng == '99'">등급외</span>
                                    <span v-else>{{ itm.acknwRtng }}등급</span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                                    <span
                                        v-if="itm.itm102ValNm != null && itm.itm102ValNm != ''"
                                        :class="['fontOneRem', itm.itm102ValNm == '아주 높음' ? 'red--text' : '']">
                                        {{ itm.itm102ValNm }}&nbsp;{{ itm.itm102Val != null ? '( ' + itm.itm102Val + '점 )' : '' }}
                                    </span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                                    <span
                                        v-if="itm.itm103ValNm != null && itm.itm103ValNm != ''"
                                        :class="['fontOneRem', itm.itm103ValNm == '위험 매우높음' ? 'red--text' : '']">
                                        {{ itm.itm103ValNm }}&nbsp;{{ itm.itm103Val != null ? '( ' + itm.itm103Val + '점 )' : '' }}
                                    </span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                                    <span
                                        v-if="itm.itm104ValNm != null && itm.itm104ValNm != ''"
                                        :class="['fontOneRem', itm.itm104ValNm == '인지저하의심' ? 'red--text' : '']">
                                        {{ itm.itm104ValNm }}&nbsp;{{ itm.itm104Val != null ? '( ' + itm.itm104Val + '점 )' : '' }}
                                    </span>
                                </v-col>
                                <v-col class="txtAlgnMid pa-1" cols="2">
                                    <span
                                        v-if="itm.wrtDt != null"
                                        class="fontOneRem">
                                        {{ $moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-else class="tableBB pa-0" no-gutters>
                            <v-col v-if="loading" class="txtAlgnMid pb-2" cols="12">
                                <v-progress-linear
                                indeterminate
                                color="blue"
                                ></v-progress-linear>
                                <span class="fontOneRem grey006--text">※ 잠시만 기다려주세요.</span>
                            </v-col>
                            <v-col v-else class="txtAlgnMid py-2" cols="12">
                                <span class="fontOneRem grey006--text">※ 그룹에 소속된 수급자가 없습니다.</span>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else-if="compTyp =='prgm'">
                        <v-divider :class="[grpByPrgmSttList.length > 15 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
                        <v-row
                            :class="['nmlBfClrImp-greyE01 pa-0', grpByPrgmSttList.length > 15 ? 'mr-2__5' : 'mr-1']"
                            no-gutters>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="1">
                                <span class="fontOneDotOneRem font-weight-bold">연번</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">프로그램 구분</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="5">
                                <span class="fontOneDotOneRem font-weight-bold">프로그램명</span>
                            </v-col>
                            <v-col class="txtAlgnMid pb-2" style="padding-top: 7px;" cols="2">
                                <span class="fontOneDotOneRem font-weight-bold">설정일자</span>
                            </v-col>
                        </v-row>
                        <v-divider :class="[grpByPrgmSttList.length > 15 ? 'mr-2__5' : 'mr-1']"></v-divider>
                        <div v-if="grpByPrgmSttList.length > 0" class="overflow-y-auto" style="width: 100%; height: 482px;">
                            <v-row
                                :class="['tableBB', grpByPrgmSttList.length > 15 ? '' : 'mr-1']"
                                v-for="(itm, i) in grpByPrgmSttList" :key="i"
                                no-gutters>
                                <v-col class="txtAlgnMid tableBR pa-1" cols="1">
                                    <span class="fontOneRem">{{ itm.rn }}</span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                                    <span class="fontOneRem">{{ itm.prgTypNm }}</span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                                    <span class="fontOneRem">{{ itm.idxCdNm }}</span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="5">
                                    <span class="fontOneRem">{{ itm.prgNm }}</span>
                                </v-col>
                                <v-col class="txtAlgnMid pa-1" cols="2">
                                    <span v-if="itm.wrtDt != null" class="fontOneRem">
                                        {{ $moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-else class="tableBB pa-0" no-gutters>
                            <v-col v-if="loading" class="txtAlgnMid pb-2" cols="12">
                                <v-progress-linear
                                indeterminate
                                color="blue"
                                ></v-progress-linear>
                                <span class="fontOneRem grey006--text">※ 잠시만 기다려주세요.</span>
                            </v-col>
                            <v-col v-else class="txtAlgnMid py-2" cols="12">
                                <span class="fontOneRem grey006--text">※ 그룹에 소속된 프로그램이 없습니다.</span>
                            </v-col>
                        </v-row>                       
                    </div>
                </v-sheet>
            </v-col>
        </v-row>
        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <BnfcrPrgmLogMdl
                v-if="lclMdlInf.typ == '11'"
                ref="pMSelector" :isUpd="true"
                :grpInf="grpByBnfcrInf"
                @isClose="rstLclMdlInf">
            </BnfcrPrgmLogMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import exBtn from '../commons/expandButtonCustom.vue';
import BnfcrPrgmLogMdl from './BnfcrPrgmLogMdl.vue';                // 그룹별 프로그램 내역

export default {
    name: 'GrpByBnfcrMdl',
    
    components: {
        exBtn,
        BnfcrPrgmLogMdl,
    },

    props : { 
        compTyp: { type: String, default: 'bnfcr' },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCmmCd(['40'])
            this.getPrgBnfcrGrpList('1')
        })
    },

    computed: {
        getRootActv: function () {
            return this.$store.getters.getRootActv
        },
    },

    watch: {
        'tblIdx': function (v){
            if(v > -1){
                switch (this.compTyp) {
                    case 'bnfcr':
                        this.getGrpBnfcrInf(v)
                        break;
                    case 'prgm':
                        this.getGrpPrgmInf(v)
                        break;
                    default:
                        break;
                }
            }
            else
                this.clrForm()
        },
        getRootActv: function (v) {
            if(this.runAble == '1' && !v){
                if(this.tblIdx != -1)
                    this.getGrpPrgmInf(this.tblIdx)
                else
                    this.clrForm()
            }

            if(!v)
                this.runAble = '0'
        },
    },
        
    methods: {
        // 공통코드 조회
        getCmmCd: function (arr){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.valClcdS = arr

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.cmmCdRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgBnfcrgrList : ' + error))
        },

        cmmCdRst: function (res){
            this.vCd40 = res.vCd40
            res.vCd40.forEach(itm => {
                this.exBtn[0].expandList.push(itm.valcdnm)
            });
        },

        // 프로그램 수급자 그룹정보 다건 조회
        getPrgBnfcrGrpList: function (yn){
            this.prgBnfcrGrpList.splice(0)

            let obj = {}
            obj.useYn = yn

            http.post('prgrm/selPrgBnfcrgrList', obj)
                .then((response) => ( this.prgBnfcrGrpRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgBnfcrgrList : ' + error))
        },

        prgBnfcrGrpRst: function (res){
            if(Array.isArray(res))
                this.prgBnfcrGrpList = res
        },

        // 그룹별 수급자 정보 조회
        getGrpBnfcrInf: function (pk){
            this.grpByBnfcrSttList.splice(0)
            this.loading = true

            let obj = {}
            obj.prgBnfcrgrInfPk = pk

            http.post('prgrm/selGrpBnfcrInfo', obj)
                .then((response) => ( this.grpBnfcrInfRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selGrpBnfcrInfo : ' + error))
        },

        grpBnfcrInfRst: function (res){
            this.loading = false
            if(res.statusCode == '200'){
                this.grpByBnfcrInf.prgBnfcrgrInfPk = res.result.bnfcrInfo.prgBnfcrgrInfPk
                this.grpByBnfcrInf.bnfcrgrNm = res.result.bnfcrInfo.bnfcrgrNm
                this.grpByBnfcrInf.bnfcrgrDesc = res.result.bnfcrInfo.bnfcrgrDesc
                this.grpByBnfcrInf.idxCd = res.result.bnfcrInfo.idxCd
                this.grpByBnfcrInf.useYn = res.result.bnfcrInfo.useYn

                let chc = this.vCd40.findIndex( v => v.valcd == res.result.bnfcrInfo.idxCd )
                if(chc > -1)
                    this.grpByBnfcrInf.idxCdNm = this.vCd40[chc].valcdnm

                if(Array.isArray(res.result.sttList))
                    this.grpByBnfcrSttList = res.result.sttList
            }
        },

        // 그룹별 프로그램 정보 조회
        getGrpPrgmInf: function (pk){
            this.grpByPrgmSttList.splice(0)
            this.loading = true

            let obj = {}
            obj.prgBnfcrgrInfPk = pk

            http.post('prgrm/selBnfcrGrpInfo', obj)
                .then((response) => ( this.grpPrgmInfRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selBnfcrGrpInfo : ' + error))
        },

        grpPrgmInfRst: function (res){
            this.loading = false
            if(res.statusCode == '200'){
                this.grpByBnfcrInf.prgBnfcrgrInfPk = res.result.bnfcrInfo.prgBnfcrgrInfPk
                this.grpByBnfcrInf.bnfcrgrNm = res.result.bnfcrInfo.bnfcrgrNm
                this.grpByBnfcrInf.bnfcrgrDesc = res.result.bnfcrInfo.bnfcrgrDesc
                this.grpByBnfcrInf.idxCd = res.result.bnfcrInfo.idxCd
                this.grpByBnfcrInf.useYn = res.result.bnfcrInfo.useYn

                let chc = this.vCd40.findIndex( v => v.valcd == res.result.bnfcrInfo.idxCd )
                
                if(chc > -1)
                    this.grpByBnfcrInf.idxCdNm = this.vCd40[chc].valcdnm

                if(Array.isArray(res.result.prgList)){
                    res.result.prgList.forEach(itm => {
                        let idx = this.vCd40.findIndex( v => v.valcd == itm.idxCd )
                        
                        if(idx > -1)
                            //itm.idxCdNm = this.vCd40[chc].valcdnm 2025.02.05 오류로 수정됨 이유 알수없음
                            itm.idxCdNm = this.vCd40[idx].valcdnm
                    });

                    this.grpByPrgmSttList = res.result.prgList
                }
            }
        },

        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.idxnm.includes(this.schField)
            
            let col1 = true
            if (this.schChkField.length != 0) {
                this.schChkField.forEach((idxCdnm1, i) => {
                    if (i == 0) col1 = filter.idxnm.includes(idxCdnm1)
                    else col1 = col1 || filter.idxnm.includes(idxCdnm1)
                });
            }

            return col0 && col1;
        },

        filterSearch: function (sch) {
            if (sch.length == 0) this.schChkField.push('')

            this.schChkField = []

            sch.forEach((item) => {
                this.schChkField.push(item)
            });

            //배열 공백 제거
            this.schChkField = this.schChkField.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField = this.schChkField.filter((val, idx) => {
                return this.schChkField.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        schAct: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },

        chcTblIdx: function (itm) {
            if(this.tblIdx == itm.prgBnfcrgrInfPk)
                this.tblIdx = -1
            else
                this.tblIdx = itm.prgBnfcrgrInfPk
        },

        resetTbl: function () {
            this.tblIdx = -1

            if(this.chcInclude == '1')
                this.getPrgBnfcrGrpList(null)
            else
                this.getPrgBnfcrGrpList('1')
        },

        clrForm: function (){
            switch (this.compTyp) {
                case 'bnfcr':
                    this.grpByBnfcrSttList.splice(0)
                    break;
                case 'prgm':
                    this.grpByPrgmSttList.splice(0)
                    break;
                default:
                    break;
            }

            this.grpByBnfcrInf.prgBnfcrgrInfPk = -1
            this.grpByBnfcrInf.bnfcrgrNm = ''
            this.grpByBnfcrInf.bnfcrgrDesc = ''
            this.grpByBnfcrInf.idxCd = ''
            this.grpByBnfcrInf.useYn = ''
        },

        // 그룹별 프로그램 설정 모달
        onPrgmGrpMdl: function () {
            let obj = {}
            obj.prgBnfcrgrInfPk = this.tblIdx
      
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '4-04', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
            this.getGrpPrgmInf(this.tblIdx)
        },
    },

    data: () => ({
        tblIdx: -1,             // prgBnfcrgrInfPk
        chcInclude: '2',
        schField: '',
        schDummy: '',
        runAble: '0',
        grpByBnfcrInf: { prgBnfcrgrInfPk: -1, bnfcrgrNm: '', bnfcrgrDesc: '', idxCd: '', idxCdNm: '', useYn: '' },
        grpByBnfcrSttList: [],
        grpByPrgmSttList: [],
        vCd40: [],
        schChkField: [],
        prgBnfcrGrpList: [],
        exBtn: [
            { btnTitle: '프로그램 유형', btnIcon: 'category', exWidth: 152, exHeigth: 130, expandList: [] },
        ],
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '73', class: 'black--text bottomBorder py-3', align: 'center', },
            { text: '프로그램 유형', value: 'idxnm', sortable: true, width: '110', class: 'black--text bottomBorder', align: 'center', },
            { text: '수급자 그룹', value: 'bnfcrgrNm', sortable: true, width: '150', class: 'black--text bottomBorder', align: 'center', },
            { text: '사용', value: 'useYn', sortable: true, width: '73', class: 'black--text bottomBorder', align: 'center', },
        ],
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },   
        loading:false,
    }),
};
</script>