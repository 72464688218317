<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-divider :class="['mt-2', bnfcrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', bnfcrList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="d-inline-block ml-1" style="width: 26px;" v-if="chkTyp == '2'">
                    <v-checkbox
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                        v-model="allChckr" :key="forceRender" @click="allChnger"
                        true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                <span class="black--text font-weight-medium">수급자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">성별</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">나이</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">현황</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="3">
                <span class="black--text font-weight-medium">생활실</span>
            </v-col>
        </v-row>
        <v-divider :class="[bnfcrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 402px;">
            <v-row class="pa-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(wList, i) in bnfcrList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="d-inline-block ml-1" style="width: 26px;" v-if="chkTyp == '2'">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="allChekrFunc"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 26px;" v-else-if="chkTyp == '1'">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="onlyOneCheckr(i)"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                    <span class="black--text">{{ wList.name }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                    <span class="d-inline-block" style="width: 20px; height: 20px;">
                        <v-img class="ic-sex" v-if="wList.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="wList.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.age }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.entStcd }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2" cols="3">
                    <span class="black--text">{{ wList.lvnRmNm }}</span>
                </v-col>
            </v-row>
        </div>
        <v-row v-if="rtrYN" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="4">
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 100px;">
                    <v-checkbox
                        v-model="entChgBox" :ripple="false"
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                        true-value="1" false-value="2" label="퇴소자 조회"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selBnfcrCntctList, selBnfcrCntctSttList } from '../../api/bnfcr.js';

export default {
    name: 'BnfcrMltpRR',

    props : {
        exChekrList: { type: Array, default: new Array() },
        chkTyp: { type: String, default: '2' },
        rtrYN: { type: Boolean, default: false },
    },
            
    components: {
        
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {            
            this.callBnfcrList()
        })
    },

    computed: {
        getWrkDt: function () {
            return this.$parent.$parent.$parent.$parent.wrtDt
        }
    },

    watch: {
        getWrkDt: function (value) {
            if(value != '' && this.bnfcrList.length == 0)
                this.callBnfcrList()
        },
        'exChekrList': function () {
            this.bnfcrList.splice(0)
            this.allChckr = '2'

            this.callBnfcrList()

            this.forceRender += 1
        },
        'entChgBox': function () {            
            this.getBnfcrCntctList()
        },
    },
        
    methods: {
        // 수급자 정보 입소현황 다건 조회 (퇴소자 포함)
        getBnfcrCntctList: function () {
            this.bnfcrList.splice(0)
            let entStcd = null

            if(this.entChgBox == 1)
                entStcd = '4'

            selBnfcrCntctList({ fcltyNum: this.$store.getters.getFcltyNum, entStcd: entStcd})
                .then((response) => ( this.getBnfcrCntctListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBnfcrCntctSttList : ' + error))
        },
        // 수급자 정보 입소현황 다건 조회 (일자별)
        getBnfcrCntctSttList: function () {
            this.bnfcrList.splice(0)
     
            selBnfcrCntctSttList({ fcltyNum:this.$store.getters.getFcltyNum, inqYMD:this.$parent.$parent.$parent.$parent.wrtDt })
                .then((response) => ( this.getBnfcrCntctListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBnfcrCntctSttList : ' + error))
        },
        getBnfcrCntctListAfter: function (res) {
            this.forceRender += 1

            res.forEach(item => {
                item.disChekr = false
                item.checkr = '2'
                item.dtTyp = 'bnfcr'

                this.$parent.$parent.$parent.$parent.cd65.forEach(cd => {
                    if(cd.valcd == item.entStcd) item.entStcd = cd.valcdnm
                });

            });

            this.bnfcrList = res

            if(this.bnfcrList.length > 0){
                this.chngInfrm()
            }
        },
        callBnfcrList: function () {            
            if(!this.rtrYN)
                this.getBnfcrCntctSttList()
            else
                this.getBnfcrCntctList()
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.bnfcrList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.bnfcrList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.bnfcrList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },
        onlyOneCheckr: function (index) {
            if(this.chkTyp == '1'){
                this.bnfcrList.forEach(el => {
                    if(el.disChekr == false) el.checkr = '2'
                });
                this.forceRender += 1
            }
            this.bnfcrList[index].checkr = '1'
        },
        chngInfrm: function () {
            this.exChekrList.forEach(item => {
                this.bnfcrList.forEach(items => {
               
                    if(item.mmbrpk == items.bnMmbrPk){
                        items.checkr = '1'
                        items.disChekr = item.disChekr
                    }
                    else if(item.bnMmbrPk == items.bnMmbrPk){
                        items.checkr = '1'
                        items.disChekr = item.disChekr
                    }
                });
            });
        },
        fltrThrw: function () {
            let arr = this.bnfcrList.filter(v => v.disChekr == false)

            return arr.filter(v => v.checkr == '1')
        },
    },
    
    data: () => ({
        bnfcrList: [],
        allChckr: '2',
        forceRender: 0,
        entChgBox:2,
    }),
};
</script>