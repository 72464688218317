<template>
    <v-sheet class="rounded-lg rounded-l-0 px-3" color="" min-width="1260" max-width="1560" height="835">
        <v-divider class="white my-1"></v-divider>
        <v-row class="pa-0 align-center" no-gutters>
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">4-3. 프로그램&nbsp;일정관리</span>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"
                @click="menu=true"
                samll rounded>
                <v-icon small>mdi-pencil-outline</v-icon>
                <span>프로그램 일정등록</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
        <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        <v-divider class="white my-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-left align-center" cols="6">
                <v-icon class="grey006--text">mdi-circle-medium</v-icon>
                <span
                    v-if="$moment(sltDate+'01', 'YYYYMMDD').isSameOrAfter(this.$moment('20250101', 'YYYYMMDD'))"
                    class="grey006--text font-weight-medium">
                    신체기능 (주 2회 이상), 인지기능 (주 3회 이상), 여가활동 (주 2회 이상)
                </span>
                <span v-else class="grey006--text font-weight-medium">
                    인지기능 (주 3회 이상), 여가활동 (주 1회 이상)
                </span>
            </v-col>
            <v-col class="d-flex align-center pr-1" cols="6">
                <v-spacer></v-spacer>
                <v-icon class="deep-orange--text" size="18">mdi-sticker-alert-outline</v-icon>
                <span class="grey006--text font-weight-medium ml-2">운영기록 미작성,</span>
                <v-icon class="deep-purple--text ml-5" size="18">mdi-sticker-check-outline</v-icon>
                <span class="grey006--text font-weight-medium ml-2">운영기록 작성완료</span>
            </v-col>
        </v-row>
        <div class="overflow-y-auto mt-2" style="width: 100%; height: 680px;">
            <v-calendar
                class="empCalendar calInit" color="dayNow darken-2 white--text" locale="ko" ref="calendar" :event-more="false"
                v-model="start" :type="calendarObj.type" :now="now" :events="events" :event-color="getEventColor" interval-height="20"
                :event-ripple="false">
                <template #day-label="{ date, day, }" >
                    <div class="d-flex align-center tableBB pl-5 pr-3 mb-1" style="height: 29px;">
                        <span
                            :class="['mb-1', date == now ? 'ic-level nowDay dayNow' : '']"
                            style="font-size: 1.1rem;">
                            {{ day }}
                        </span>
                        <v-spacer></v-spacer>
                        <!-- <span style="padding-bottom: 3px;" v-if="year == start.split('-', 3)[0] && month == start.split('-', 3)[1]">
                            <v-checkbox class="checkIcon wrkPtnCkb"
                                v-model="calendarCheckPoint[day]" :disabled="cldMode == '1'"
                                @change="daySltChecker(day)" true-value="1" false-value="2" hide-details>
                            </v-checkbox>
                        </span> -->
                    </div>
                </template>
                <template #event="{ event }" >
                    <v-row class="mb-1 pa-0" v-for="(prgm, i) in event.schdl" :key="i" no-gutters>
                        <v-col class="" cols="12">
                            <v-card class="py-1" style="margin-left: 6px;"
                                outlined rounded>
                                <v-row class="mt-1 pa-0" style="cursor: initial;" no-gutters>
                                    <v-col class="d-flex align-center pl-3" cols="12">
                                        <span class="d-inline-block" style="width: 24px; height: 24px;">
                                            <v-icon v-if="prgm.prgOpRecPk == null" class="deep-orange--text" size="18">mdi-sticker-alert-outline</v-icon>
                                            <v-icon v-else class="deep-purple--text" size="18">mdi-sticker-check-outline</v-icon>
                                        </span>
                                        <v-spacer></v-spacer>
                                        <span :class="['rounded-pill px-3', 'level0'+prgm.idxCd ]" style="line-height: 1.7;">
                                            <span class="white--text font-weight-medium">{{ prgm.valCdNm }}</span>
                                        </span>
                                        <v-spacer></v-spacer>
                                        <span v-if="prgm.prgOpRecPk == null">
                                            <v-btn class="" color="grey006" height="17" @click="preRm(prgm.prgOpPlnPk)" icon>
                                                <v-icon size="17">mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </span>
                                        <span v-else class="d-inline-block" style="width: 36px; height: 24px;"></span>
                                    </v-col>
                                </v-row>
                                <div @click="onMdl(prgm.prgOpPlnPk, prgm.prgOpRecPk, prgm.prgInfPk)">
                                    <v-row class="my-1 pa-0" no-gutters>
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex justify-center align-center" cols="10">
                                            <span class="font-weight-medium" style="font-size: 1.1rem;">{{ prgm.prgNm }}</span>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                    </v-row>
                                    <v-row class="mb-2 pa-0" no-gutters>
                                        <v-col class="d-flex justify-center" cols="12">
                                            <span class="fontOneRem" style="">{{ prgm.opTm }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
            </v-calendar>
        </div>
        <v-dialog v-model="menu" max-width="1220" content-class="round" persistent>
            <PrgrmNew ref="prgrmNew" @isClose="menu=false" @modalEvent="getPickerDate(sltDate)"></PrgrmNew>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import PrgrmNew from '../../components/prgrm/PrgrmNew.vue';

import { selPrgOpSchdList, delPrgOpPln } from '../../api/prgrm.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'PrgSchdlMgmt',
        
    components: {
        expandMonthPicker,
        PrgrmNew,
    },

    created: function(){
        let timezoneOffset = new Date().getTimezoneOffset() * 60000
        let timezoneDate = new Date(Date.now() - timezoneOffset)
        this.now = timezoneDate.toISOString().split('T',2)[0]
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['40'])
        })
    },

    computed: {
        getRootEvt: function () {
            return this.$store.getters.getRootRunEvt
        },
    },

    watch:{ 
        getRootEvt: function (bol) {
            if(bol){
                this.getPickerDate(this.sltDate)
                this.$store.commit('setRootRunEvt', false)
            }
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getPrgOpSchdList: function (strt, end) {
            selPrgOpSchdList(this.$store.getters.getFcltyNum, strt, end)
                .then((response) => ( this.getPrgOpSchdListAfter(response.data) ))
                .catch((error) => console.log('connect error /prgm/selPrgOpSchdList : ' + error))
        },
        removePrgOpPln: function (obj) {
            delPrgOpPln(obj)
                .then((response) => ( this.removePrgOpPlnAfter(response.data) ))
                .catch((error) => console.log('connect error /prgm/delPrgOpPln : ' + error))
        },
        getPrgOpSchdListAfter: function (res) {
            this.events.splice(0)
            // if(res.length > 0){
            //     res.forEach(items => {
            //         items.start = items.opDt?.beforeDateFormatHyp()
            //         items.end = items.opDt?.beforeDateFormatHyp()
            //         this.events.push(items)
            //     });
            // }
            let crtrDt = new Date(this.start)

            if(res.length > 0){
                for (let index = 1; index <= new Date(crtrDt.getFullYear(), crtrDt.getMonth()+1, 0).getDate(); index++) {
                    let obj = {}
                    let schDt = crtrDt.getFullYear() + '' + ('00' + (crtrDt.getMonth()+1)).slice(-2)  + ('00' + index).slice(-2)
                    let dt = (crtrDt.getFullYear() + ('00' + (crtrDt.getMonth()+1)).slice(-2) + ('00' + index).slice(-2)).beforeDateFormatHyp()
                    obj.start = dt
                    obj.end = dt 
                    obj.schdl = res.filter( v => v.opDt == schDt )

                    if(obj.schdl.length > 0){
                        obj.schdl.forEach(idx => {
                            this.vCd40.forEach(cd => {
                                if(idx.idxCd == cd.valcd)
                                    idx.valCdNm = cd.valcdnm.slice(0, 2)
                            });
                        });
                        this.events.push(obj)
                    }
                }
            }
        },
        stCdMapping: function (res){
            if(res.vCd40.length > 0)
                this.vCd40 = res.vCd40
        },
        removePrgOpPlnAfter: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200)
                this.getPickerDate(this.sltDate)
            else{
                obj.tmr = 4500
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        preRm: function (pk) {
            let obj = {}
            obj.userNm = this.$store.getters.getUserNm
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.prgOpPlnPk = pk

            let wrnnObj = {}
            wrnnObj.actv = true                             // 경고 노출여부
            wrnnObj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            wrnnObj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            wrnnObj.title = '삭제가 불가능한 상황 입니다.'      // 경고 타이틀
            wrnnObj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(obj.prgOpPlnPk != null || obj.prgOpPlnPk != 0)
                this.removePrgOpPln(obj)
            else
                this.$store.commit('setWrnnInf', wrnnObj)
        },
        daySltChecker: function (index) {
            let sr = 0
            let chkSum = []
            let res = null
            let dType = new Date(this.start.substring(0, 7) + '-' + index).getDay()

            if(Math.floor(index/7) == 0)
                sr = Number(index)
            else {
                let tr = Math.floor(index / 7)
                sr = Number(index) - (7 * tr)
            }
            for (let i = sr; i < this.calendarCheckPoint.length; i+=7) {
                chkSum.push(this.calendarCheckPoint[i])
            }
            res = chkSum.indexOf('2')

            if(res == -1)
                this.weekAllChecker.splice(dType, 1, '1')
            else
                this.weekAllChecker.splice(dType, 1, '2')

        },
        getPickerDate: function (date) {
            this.start = date.substr(0, 4) + '-' + date.substr(4, 2) + '-01'
            this.sltDate = date

            let crtrDt = new Date(this.sltDate.substring(0, 4), this.sltDate.substring(4, 6), 0)
            this.getPrgOpSchdList(this.sltDate+'01', this.sltDate+crtrDt.getDate())
        },
        onMdl: function (plnPk, recPk, prgInfPk) {
            let obj = {}
            obj.prgOpPlnPk = plnPk
            obj.prgOpRecPk = recPk
            obj.prgInfPk = prgInfPk

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: obj, outObj: null, runEvt: false,
            });
        },
        getEventColor: function (event) {
            return event.color
        },
        calendarDayAct: function (value) {
            this.sltDate = value.day.date
        },
    },
    
    data: () => ({
        menu: false,
        now: '',
        start: '',
        sltDate: '',
        cldMode: '1',
        vCd40: [],
        calendarCheckPoint: [],
        events: [
            //{ name: '1', start: '2023-01-01', end: '2023-01-01', color: 'light-blue darken-2', cnt: 1 },
        ],
        colors: ['blue', 'deep-orange darken-3', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
    }),
};
</script>

<style>
    .calInit .v-calendar-weekly__week .v-event {
        border: initial !important; height: 91% !important;
        margin-top: 8px !important;
        padding-bottom: 1px !important; margin-bottom: 1px !important;
    }
    .calInit .v-calendar-weekly__week .v-calendar-weekly__day { min-height: 117px; }
    .calInit .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label { cursor: initial !important; }
</style>