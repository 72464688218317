<template>
    <v-sheet class="d-inline" color="midAreaColor">
        <v-btn
            class="rounded py-4 px-2" style="background-color: white;" 
            color="btnBrdrColor" min-width="24" height="22"
            @click="$emit('input', !value)"
            rounded outlined>
            <v-img
                v-if="vTyp == '1'"
                class="ic-notice black--text"
                :src="require(`@/assets/icon/${bImg}.svg`)">
            </v-img>
            <v-icon
                v-else-if="vTyp == '2'"
                class="ic-notice black--text"
                small>
                {{ bIcon }}
            </v-icon>
            <span
                class="fontOneRem black--text" style="margin-left: 2px;"
                v-text="btnNm">
            </span>
        </v-btn>
        <v-expand-transition>
            <v-card
                v-show="value"
                class="" style="position: absolute; z-index: 11;"
                outlined>
                <div
                    class="d-inline-block mt-1 ml-3 mr-1"
                    :style="{
                        width: (mxmWrdLng * 19 + 38) + 'px',
                        height: (ctrlArr.length + 1) * 32 + 'px',
                        minWidth: '82px',
                        maxHeight: '202px' }">
                    <v-checkbox
                        v-model="isAll"
                        @click="allApply()"
                        class="expnChcBtn-Theme font-weight-medium"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details="true" label="전체" dense>
                    </v-checkbox>
                    <div
                        class="overflow-y-auto"
                        :style="{ maxHeight: '170px', height: ((ctrlArr.length * 32) + 4) + 'px', }">
                        <v-checkbox
                            v-model="isArr"
                            v-for="(arr, i) in ctrlArr" :key="i"
                            @click="ctrlArrApply()"
                            class="expnChcBtn-Theme font-weight-medium"
                            :label="arr.valcdnm" :value="arr.valcd"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details="true" dense>
                        </v-checkbox>
                    </div>
                </div>
                <v-row class="mb-2 justify-center" no-gutters>
                    <v-btn
                        class="white--text" color="blackBtnColor" height="23" elevation="1"
                        @click="$emit('input', !value)"
                        rounded small>
                        닫기
                    </v-btn>
                </v-row>
            </v-card>
        </v-expand-transition>
    </v-sheet>
</template>

<script>

export default {
    name: 'CmmExpnBtn',

    props : {
        value: { type: Boolean, default: false },               // 메뉴 활성여부
        btnNm: { type: String, default: 'NoName' },             // 버튼 타이틀
        bIcon: { type: String, default: '' },                   // 아이콘 명 ( vTyp == 2 )
        bImg: { type: String, default: '' },                    // 이미지 경로 ( vTyp == 1 )
        vTyp: { type: String, default: '2' },                   // 1: 이미지, 2: 아이콘
        iList: { type: Array, default: () => { return [] } },
    },
            
    components: {

    },

    created: function(){
      
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch:{
        iList: {
            deep: true,
            handler: function (value){
                if(this.ctrlArr.length != value.length){
                    this.ctrlArr.splice(0)
                    this.isArr.splice(0)
                    
                    value.forEach(itm => {
                        if(typeof itm.isOn == 'undefined')
                            itm.isOn = false
                        if(this.mxmWrdLng < itm.valcdnm.length)
                            this.mxmWrdLng = itm.valcdnm.length
                        this.ctrlArr.push(itm)
                    });

                    this.ctrlArr.forEach(itm => {
                        if(itm.isOn)
                            this.isArr.push(itm.valcd)
                    });

                    this.ctrlArrApply()
                }
            }
        },

        isArr: {
            deep: true,
            handler: function (value){
                this.$emit('onList', value)
            }
        },
    },
        
    methods: {
        // 체크박스 전체 선택 이벤트
        allApply: function() {
            this.isArr.splice(0)
            if(this.isAll)
                for (let idx = 0; idx < this.ctrlArr.length; idx++) {
                    this.isArr.push(String(idx+1))
                }

            this.ctrlArr.filter( v => v.isOn == true ).forEach(itm => {
                itm.isOn = false
            });
            this.isArr.forEach(itm => {
                this.ctrlArr[Number(itm)-1].isOn = true
            });
        },
        // 체크박스 클릭 이벤트
        ctrlArrApply: function() {
            this.ctrlArr.filter( v => v.isOn == true ).forEach(itm => {
                itm.isOn = false
            });
     
            this.isArr.forEach(itm => {
                let chc = this.ctrlArr.findIndex( v => v.valcd == itm )
                if(chc > -1)
                    this.ctrlArr[chc].isOn = true
            });
           
            if(this.isArr.length == this.ctrlArr.length )
                this.isAll = true
            else
                this.isAll = false
        },
    },
     
    data: () => ({
        isAll: false,               // 전체 선택
        isArr: [],
        mxmWrdLng: 0,               // 목록 리스트 단어 최대 길이
        ctrlArr: []                 // 목록 리스트 체크박스
    }),
};
</script>