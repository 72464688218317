<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div class="d-flex justify-left align-center">
            <span class="text-h4 font-weight-bold blue--text">{{ bnName }}</span>
            <span class="text-h4 font-weight-bold pl-2">{{doseInf.drugNm}}</span>
            <span class="text-h4 pl-2">잔량 조정</span>            
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" :class="list.class" samll height="30" :color="list.color"
                    :disabled="list.disabled"
                    @click="onClickBtn(list.text)" rounded>
                    <v-icon small>{{ list.icon }}</v-icon>
                    <span style="font-size: 1rem;">{{ list.text }}</span>
                </v-btn>
            </span>
            <v-icon class="ml-1" color="black" x-large @click="$emit('isClose')">mdi-close</v-icon>
        </div>     
        <v-divider class="mt-3 mr-1"></v-divider>
        <v-row no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR" cols="2">
                <span class="font-weight-medium posn-rltv">투약명</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="10">
                {{doseInf.drugNm}}
            </v-col>
        </v-row>        
        <v-divider class="mr-1"></v-divider>
        <v-row no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR" cols="2">
                <span class="font-weight-medium posn-rltv">투약기간</span>
            </v-col>
            <v-col class="d-flex align-center pl-2" style="padding-top: 3px;" cols="4">
                <span>{{ $moment(doseInf.doseStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                <span>&#126;</span>
                <span>{{ $moment(doseInf.doseEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center tableBR" cols="2">
                <span class="font-weight-medium posn-rltv">작성자</span>
            </v-col>
            <v-col class="d-flex align-center pl-2 py-1" style="padding-top: 3px;" cols="4">
                <span class="white d-inline-block" style="width: 140px;">
                    <v-text-field
                        class="text-field-clearable"
                        v-model="doseInf.wrtr" :rules="[rules.required]"
                        height="28" readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="brown lighten-3 ml-1" style="padding: 0px 1px 0px 2px !important;"
                    min-width="30" height="28"
                    @click="onLclMdl('01', 800)"
                    small depressed>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>    
        <v-divider class="mr-1"></v-divider>
        <v-row no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center  tableBR" cols="2">
                <span class="font-weight-medium posn-rltv">투약조정일자</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="4">                
                {{ $moment(inqYMD, 'YYYYMMDD').format('YYYY.MM.DD') }}
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center  tableBR" cols="2">
                <span class="font-weight-medium posn-rltv">조정 잔여량</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="4">
                <v-text-field 
                    v-model="doseInf.adjstRmnAmt" :rules="[rules.required]" color="blue" height="28" hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>    
        <v-divider class="mr-1"></v-divider>
        <v-row no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center  tableBR" cols="2">
                <span class="font-weight-medium posn-rltv">조정사유</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="10">
                <v-text-field 
                    v-model="doseInf.adjstRsn" :rules="[rules.required]" color="blue" height="28" hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>   
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">잔량 조정 내역</span>
        </v-row>
        <v-divider :class="[doseRmnList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row
            :class="['nmlBfClrImp-greyE01 pa-0', doseRmnList.length > 5 ? 'mr-2__5' : 'mr-1']"
            no-gutters>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">투약조정일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">조정 잔여량</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">작성자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="6">
                <span class="fontOneDotOneRem font-weight-bold">조정사유</span>
            </v-col>
            <v-col class="txtAlgnMid" style="padding-top: 6px;" cols="1">                
            </v-col>
        </v-row>
        <v-divider :class="[doseRmnList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="doseRmnList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', doseRmnList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in doseRmnList" :key="i"
                no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ $moment(itm.adjstDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.adjstRmnAmt }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="1">
                    <span class="fontOneRem">{{ itm.wrtr }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="6">
                    <span class="fontOneRem">{{ itm.adjstRsn }}</span>
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">                                     
                    <v-btn                        
                        v-if="itm.doseRmnPk > 0"
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"         
                        @click="rmRow(itm)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>{{ itm.adjstDt }} 잔량 조정 제거</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 잔량 조정 내역이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn" :max-width="lclMdlInf.maxWidth"
            content-class="round"
            persistent eager>
            <EmpSelector
                v-if="lclMdlInf.typ == '01'"                
                @outEmp="getEmpInfo" :prtWrtDt = "inqYMD" @isClose="rstLclMdlInf">
            </EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import EmpSelector from '../../components/commons/EmpSelector.vue';

export default {
    name: 'DoseRmnMdl',

    props : {        
        inqYMD      : {type:String, default:''},        
        doseInfPk    : {type: Number, default: -1},
        bnName      : {type:String, default:''},        
        bnMmbrPk      : {type: Number, default: -1},   
    },
            
    components: {
        EmpSelector
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           this.getDoseRmnInfo()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmDoseRmnCnFm){
                
                //갱신
                this.rmDoseRmn()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmDoseRmnCnFm = false
            }
        },
    },
        
    methods: {
        //투약 잔여량 조정정보 상세 조회
        getDoseRmnInfo:function(){
            this.clearDoseInf()
            this.doseRmnList.splice(0)
            this.filledBtn[1].disabled = true

            let obj = {                
                doseInfPk   : this.doseInfPk,
                adjstDt     : this.inqYMD,
                bnMmbrPk    : this.bnMmbrPk
            }        
            
            http.post('bnftrcrd/selDoseRmnInfo', obj)
                .then((response) => ( this.doseRmnInfoRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selDoseRmnInfo : ' + error))
        },

        //저장
        setDoseRmn:function(obj){
            http.post('bnftrcrd/insDoseRmn', obj)
                .then((response) => ( this.setDoseRmnRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/insDoseRmn : ' + error))
        },

        //삭제
        rmDoseRmn:function(){
            if(this.sltDoseRmnPk > 0){
                let obj = {
                    doseRmnPk : this.sltDoseRmnPk
                }
                http.post('bnftrcrd/delDoseRmn', obj)
                    .then((response) => ( this.setDoseRmnRst(response.data) ))
                    .catch((error) => console.log('connect error /bnftrcrd/delDoseRmn : ' + error))
            } else {
                this.onAlrt('조정정보 고유번호가 존재하지 않습니다. (ER401)')
            }
        },

        doseRmnInfoRst:function(res){
            let obj = {}

            if(res.statusCode == 200){                    

                if(res.result.info != null){
                    this.doseInf.doseInfPk  	= res.result.info.doseInfPk
                    this.doseInf.bnMmbrPk       = res.result.info.bnMmbrPk
                    this.doseInf.drugNm	        = res.result.info.drugNm
                    this.doseInf.doseStrt	    = res.result.info.doseStrt
                    this.doseInf.doseEnd	    = res.result.info.doseEnd
                    this.doseInf.doseRmnPk	    = res.result.info.doseRmnPk
                    this.doseInf.adjstDt	    = res.result.info.adjstDt
                    this.doseInf.adjstRmnAmt	= res.result.info.adjstRmnAmt
                    this.doseInf.adjstRsn	    = res.result.info.adjstRsn
                    this.doseInf.wrtr	        = res.result.info.wrtr
                    this.doseInf.wrtrMmbrPk	    = res.result.info.wrtrMmbrPk

                    if(this.doseInf.doseRmnPk > 0) this.filledBtn[1].disabled = false
                }

                if(res.result.list != null){
                    this.doseRmnList = res.result.list
                }

            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }

        },

        setDoseRmnRst:function(res){            
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getDoseRmnInfo()
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }
            this.$store.commit('setWrnnInf', obj)
        },

        onClickBtn:function(txt){
            switch (txt) {
                case '저장':
                    this.preInsDoseRmn()
                    break;
                
                case '삭제':
                    if(this.doseInf.doseRmnPk > 0){
                        let obj = {}
                        obj.icon = 'mdi-alert'
                        obj.iconClr = 'red'
                        obj.title = this.doseInf.drugNm+' 잔량 조정'
                        obj.cntnt = ['조정일자 '+this.$moment(this.inqYMD, 'YYYYMMDD').format('YYYY.MM.DD') +'를(을) 삭제하시겠습니까?']
                        obj.cnclTxt = '아니오'
                        obj.cnfrmTxt = '예, 삭제하겠습니다.'

                        this.$store.commit('setSubInf', {
                            actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                        });

                        this.rmDoseRmnCnFm = true
                        this.sltDoseRmnPk = this.doseInf.doseRmnPk
                    }
                    break;  
                default:
                    break;
            }
        },

        //저장
        preInsDoseRmn:function(){
            if(this.isDoseDmnValid()){
                let obj = {
                    doseInfPk   : this.doseInf.doseInfPk,
                    bnMmbrPk    : this.bnMmbrPk,
                    adjstDt     : this.inqYMD,
                    adjstRmnAmt : this.doseInf.adjstRmnAmt,
                    adjstRsn    : this.doseInf.adjstRsn,
                    wrtr        : this.doseInf.wrtr,
                    wrtrMmbrPk  : this.doseInf.wrtrMmbrPk,
                    doseRmnPk   : this.doseInf.doseRmnPk,
                }

                this.setDoseRmn(obj)
            }
        },

        clearDoseInf : function(){
            this.doseInf.doseInfPk  	= -1
            this.doseInf.bnMmbrPk	    = -1
            this.doseInf.drugNm	        = ''
            this.doseInf.doseStrt	    = ''
            this.doseInf.doseEnd	    = ''
            this.doseInf.doseRmnPk	    = ''
            this.doseInf.adjstDt	    = ''
            this.doseInf.adjstRmnAmt	= 0
            this.doseInf.adjstRsn	    = ''
            this.doseInf.wrtr	        = ''
            this.doseInf.wrtrMmbrPk	    = -1
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        getEmpInfo:function(obj){                  
            this.doseInf.wrtr = obj[0].name
            this.doseInf.wrtrMmbrPk = obj[0].mmbrPk
        },

        isDoseDmnValid:function(){
                     
            let regDciml = /^-?\d*(\.\d*)?$/
            let chk = true

            if(chk && (this.doseInf.wrtr == '' || this.doseInf.wrtr == null)) {
                this.onAlrt('작성자를 선택해주세요.')
                chk = false
            }

            if (chk && !regDciml.test(this.doseInf.adjstRmnAmt)) {
                this.onAlrt('조정 잔여량을 확인해주세요.(유효값 : 숫자, 소수점)')
                chk = false
            }


            if (chk && (this.doseInf.adjstRsn == '' || this.doseInf.adjstRsn == null)) {
                this.onAlrt('조정 사유를 입력해주세요.')
                chk = false
            }

            return chk            
        },

        rmRow:function(itm){
            if(itm.doseRmnPk > 0) { 
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = this.doseInf.drugNm+' 잔량 조정'
                obj.cntnt = ['조정일자 '+this.$moment(itm.adjstDt, 'YYYYMMDD').format('YYYY.MM.DD') +'를(을) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmDoseRmnCnFm = true
                this.sltDoseRmnPk = itm.doseRmnPk
            }
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
    },
    
    data: () => ({
        rmDoseRmnCnFm : false,      
        doseInf : {
            doseInfPk  	: -1,
            bnMmbrPk    : -1,
            drugNm	    : '',
            doseStrt	: '',
            doseEnd	    : '',
            doseRmnPk	: -1,
            adjstDt	    : '',
            adjstRmnAmt	: 0,
            adjstRsn	: '',
            wrtr	    : '',
            wrtrMmbrPk	: -1
        } ,
        sltDoseRmnPk : -1,
        doseRmnList : [],
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', disabled:false },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action', disabled:true  },
        ],
        rules: {
            required: value => !!value || 'Required.',
        },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 작성자 선택)
    }),
};
</script>