<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">프로그램&nbsp;일정등록</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-form ref="mdlform" lazy-validation>
            <v-divider class="white my-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="" cols="7">
                    <v-row class="pa-0 mb-2 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램&nbsp;일정&nbsp;정보</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="crsShp-Default white--text mt-1" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor"
                            @click="insPrgOpPln()" :disabled="btnSave"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">프로그램 일정 저장</span>
                        </v-btn>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">계획일시</span>  
                        </v-col>
                        <v-col class="pl-1 py-1" cols="3">
                            <CmmDateComp v-model="opDt" :required="true"></CmmDateComp>
                        </v-col>
                        <v-col class="d-flex pa-1" cols="5">
                            <span class="d-inline-block ml-1" style="width: 60px;">
                                <CmmTimeField v-model="outStrt" :required="true"></CmmTimeField>
                            </span>
                            <span class="d-flex align-center mx-1">
                                <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px;">
                                <CmmTimeField v-model="outEnd" :required="true"></CmmTimeField>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">프로그램</span>                                
                        </v-col>
                        <v-col class="pl-1 py-1" cols="5">
                            <v-text-field
                                v-model="items.prgNm" :rules="[rules.required]"
                                height="28" :disabled="prgInfPk == 0"
                                hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col v-if="isUpd" class="tableBR" style="padding-top: 3px;" cols="1">
                            <v-btn
                                class="ml-1 brown lighten-3" style="padding:0px 1px 0px 2px !important;"
                                min-width="30" height="25"
                                @click="onLclMdl('01', 800)"
                                :disabled="btnDisOpDt"
                                small>
                                <v-icon color="white" size="20">mdi-playlist-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            v-if="isUpd"
                            class="nmlBfClrImp-greyE01 txtAlgnMid tableBR"
                            style="padding-top: 6px;" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">진행자</span>                                
                        </v-col>
                        <v-col v-if="isUpd" class="pa-1" cols="2">
                            <v-text-field
                                v-model="items1.mc"
                                color="blue" height="28"
                                hide-details outlined dense readonly>
                            </v-text-field>
                        </v-col>                            
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">프로그램 구분</span>  
                        </v-col>
                        <v-col class="pl-2" style="padding-top: 3px;" cols="5">
                            <!-- <v-text-field
                                v-model="items.prgTypNm" :rules="[rules.required]"
                                height="28" hide-details outlined dense readonly>
                            </v-text-field> -->
                            <span class="fontOneRem">{{ items.prgTypNm }}</span>  
                        </v-col>
                        <v-col class="tableBR" cols="1"></v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>  
                        </v-col>
                        <v-col class="pl-2" style="padding-top: 3px;" cols="2">
                            <!-- <v-text-field
                                v-model="items.idxNm" :rules="[rules.required]"
                                color="blue" height="28"
                                hide-details outlined dense readonly>
                            </v-text-field> -->
                            <span class="fontOneRem">{{ items.idxNm }}</span> 
                        </v-col>
                    </v-row>                   
                    <v-divider class="mb-6"></v-divider>
                    <v-row class="pa-0 mb-2 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램&nbsp;수급자&nbsp;그룹&nbsp;지정</span>
                    </v-row>
                    <v-data-table
                        class="" height="404" locale="ko" 
                        :headers="headers" :items="prgBnfcrgrInfList"
                        hide-default-header fixed-header hide-default-footer dense>
                        <template v-slot:header="{ props }">
                            <thead>
                                <tr>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBT tableBR py-2" style="width: 20%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[0].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBT tableBR py-2" style="width: 25%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[1].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBT tableBR" style="width: 44%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[2].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBT" style="width: 10%;">
                                        <v-btn
                                            class="" color="blue200" height="26"
                                            :disabled="btnOnOff" @click="onLclMdl('02', 900) "
                                            icon>
                                            <v-icon>mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                    <span class="fontOneRem">{{ item.idxNm }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                    <span class="fontOneRem">{{ item.bnfcrgrNm }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2" style="line-height: 1.3;">
                                    <span class="fontOneRem">{{ item.bnfcrgrDesc }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB">                                  
                                    <v-btn
                                        v-if="item.bnfcrgrPrgPk > 0"
                                        color="grey006" icon height="26"
                                        @click="callRmMdl(index)">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        color="grey006" icon height="26"
                                        @click="onDelete3(index)">
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <div v-if="prgBnfcrgrInfList.length == 0" class="posn-rltv" style="height: 10px;">
                        <span
                            class="d-inline-block white"
                            style="height: 30px; position: absolute; top: -358px; left: 50%; transform: translateX(-50%);">
                            <span class="fontOneRem grey006--text">※ 수급자 그룹이 지정되지 않았습니다.</span>
                        </span>
                        <span
                            class="d-inline-block white"
                            style="width: 392px; height: 30px; position: absolute; top: -338px; left: 50%; transform: translateX(-50%);">
                            <span class="fontOneRem grey006--text">프로그램을 선택한 뒤 더하기 버튼으로 수급자 그룹을 추가해주세요.</span>
                        </span>
                        <v-divider style="width: 100%; position: absolute; top: -304px; left: 0;"></v-divider>
                    </div>
                </v-col>
                <v-col class="pl-4" cols="5">
                    <v-row class="pa-0 mb-2 posn-rltv" no-gutters>
                        <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">참여대상&nbsp;수급자&nbsp;정보</span>
                    </v-row>
                    <v-divider :class="bnfcrgrBnfcrList.length > 14 ? '' : 'mr-2__5'"></v-divider>
                    <v-data-table
                        :class="bnfcrgrBnfcrList.length > 14 ? '' : 'mr-2__5'"
                        height="571" locale="ko" item-key="rn"
                        :headers="bnfcrHeaders" :items="bnfcrgrBnfcrList"
                        disable-pagination hide-default-header fixed-header hide-default-footer dense>
                        <template v-slot:header="{ props }">
                            <thead>
                                <tr>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width: 10%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[0].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width: 20%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[1].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width: 20%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[2].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width: 10%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[3].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp tableBR px-1 py-2" style="width: 10%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[4].text }}</span>
                                    </th>
                                    <th class="nmlBfClrImp-greyE01 txtAlgnMidImp px-1 py-2" style="width: 10%;">
                                        <span class="fontOneDotOneRem black--text">{{ props.headers[5].text }}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>  
                        <template v-slot:item='{ item, index }'>    
                            <tr>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                    <span class="fontOneRem">{{ index + 1 }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                    <span class="fontOneRem">{{ item.bnfcrgrNm }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                    <span class="fontOneRem">{{ item.name }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                    <span class="fontOneRem">{{ item.age }}</span>
                                </td>
                                <td class="txtAlgnMid tableBB tableBR px-1" style="padding-top: 7px;">
                                    <span class="d-inline-block" style="width: 20px; height: 20px;">
                                        <v-img
                                            v-if="item.gndr == 'F'"
                                            class="ic-sex"
                                            src="../../assets/icon/ic-female.svg">
                                        </v-img>
                                        <v-img
                                            v-else-if="item.gndr == 'M'"
                                            class="ic-sex"
                                            src="../../assets/icon/ic-male.svg">
                                        </v-img>
                                    </span>
                                </td>
                                <td class="txtAlgnMid tableBB px-1" style="padding-top: 2px;">
                                    <span
                                        :class="'d-inline-block ic-level level0' + item.acknwRtng"
                                        style="padding-top: 1px; padding-right: 1px;">
                                        <span v-if="item.acknwRtng == '99'">외</span>
                                        <span v-else>{{ item.acknwRtng }}</span>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <div v-if="bnfcrgrBnfcrList.length == 0" class="posn-rltv" style="height: 10px;">
                        <span
                            class="d-inline-block white"
                            style="width: 228px; height: 30px; position: absolute; top: -527px; left: 50%; transform: translateX(-50%);">
                            <span class="fontOneRem grey006--text">※ 수급자 그룹이 추가되지 않았습니다.</span>
                        </span>
                        <v-divider style="width: 98%; position: absolute; top: -498px; left: 0;"></v-divider>
                    </div>
                </v-col>
            </v-row>
        </v-form>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <PrgrmSelect
                v-if="lclMdlInf.typ == '01'"
                ref="pSelect"
                @isClose="rstLclMdlInf" @outPrgrm="setPrgrm">
            </PrgrmSelect>
            <PrgrmgrMultiSelector
                v-else-if="lclMdlInf.typ == '02'"
                ref="pMSelector" 
                @isClose="rstLclMdlInf" @reload="selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum)" @outEmp="setprggr" 
                :prgBnfcrgrChkItems="prgBnfcrgrInfPks" :prgBnfcrgrNoItems="prgGrNoItems" :prgInfPk="prgInfPk" :isUpd="false">
            </PrgrmgrMultiSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selPrgInfInfo, selBnfcrgrBnfcrList, insPrgOpPln, selPrgBnfcrgrInfList, delBnfcrgrPrg } from '../../api/prgrm.js';

import PrgrmSelect from '../../components/prgrm/PrgrmSelect.vue';
import PrgrmgrMultiSelector from '../../components/prgrm/PrgrmgrMultiSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'PrgrmNew',

    props:{
        isUpd: { type: Boolean, default: true },
        prtObj: { type: Object, default: () => { return {} } }
    },

    components: {
        PrgrmSelect,
        PrgrmgrMultiSelector,
        CmmDateComp,
        CmmTimeField,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(!this.isUpd){
                let arr = []
                arr.push(this.prtObj)
                this.setPrgrm(arr)
            }
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                if (this.prgBnfcrgrInfList[this.key].bnfcrgrPrgPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        bnfcrgrPrgPk: this.prgBnfcrgrInfList[this.key].bnfcrgrPrgPk
                    }
                    delBnfcrgrPrg(obj)
                        .then((response) => (this.delBnfcrgrPrgAfter(response.data)))
                        .catch((error) => console.log(error))
                }
                else
                    this.prgBnfcrgrInfList.splice(this.key, 1)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
           
                this.runAble = '0'
            }
        },
        //일자 변경시
        'opDt':function(){            
            this.setVal()
        }
    },

    methods: {
        init:function(){
            this.bnfcrgrBnfcrList.splice(0) 
            this.prgBnfcrgrInfList.splice(0)
            this.items1 = Object.assign({})
            this.prgBnfcrgrInfPks.splice(0)
            this.$refs.mdlform.reset()      
            this.btnOnOff = true
            this.btnSave = true
            this.$refs.mdlform.resetValidation()                  
        },
        // 프로그램 정보 조회
        selPrgInfInfo: function (code) {
            selPrgInfInfo(code, this.prgInfPk, this.inqymd )
                .then((response) => (this.selPrgInfInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        selPrgInfInfoAfter: function (res) {
            this.items1 = Object.assign({})            
            this.btnOnOff = true

            if(res!=null){
                this.btnOnOff = false
                this.items1 = res
            }

            this.$refs.mdlform.resetValidation()
        },
        // 참여대상 수급자 조회
        selBnfcrgrBnfcrList: function () {
            this.bnfcrgrBnfcrList.splice(0)    

            if(this.prgBnfcrgrInfPks.length > 0){
                selBnfcrgrBnfcrList(this.$store.getters.getFcltyNum, null, null, this.prgBnfcrgrInfPks, this.inqymd)
                    .then((response) => (this.selBnfcrgrBnfcrListAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/selBnfcrgrBnfcrList : ' + error))
            }
        },
        selBnfcrgrBnfcrListAfter: function (res) {
            res.forEach(el => {
                this.bnfcrgrBnfcrList.push(el)
            });
        },
        selPrgBnfcrgrInfList: function (code) {
            selPrgBnfcrgrInfList(code, this.prgInfPk, '1', this.inqymd)
                .then((response) => (this.selPrgBnfcrgrInfListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgBnfcrgrInfList : ' + error))
        },
        selPrgBnfcrgrInfListAfter: function (res) {
            this.prgBnfcrgrInfList.splice(0)
            this.prgBnfcrgrInfPks.splice(0)

            if(res.statusCode == '200'){
                res.result.list.forEach(e => {
                    // let chc = res.result.code.vCd40.findIndex( v => v.valcd == e.idxCd )

                    // if(e.idxCd == null) e.idxCd = ''
                    // if(chc > -1) e.idxNm = res.result.code.vCd40[chc].valcdnm
                    
                    if(e.bnfcrgrPrgPk != null){
                        this.prgBnfcrgrInfList.push(e)
                        this.prgBnfcrgrInfPks.push(e.prgBnfcrgrInfPk)
                    }
                });

                this.selBnfcrgrBnfcrList()
            }

        },
        insPrgOpPln: function () {
            if (this.$refs.mdlform.validate()) {
                let outStrt = this.outStrt.split(':', 2)
                let outEnd = this.outEnd.split(':', 2)
                let obj = {
                    opDt: this.opDt?.afterDateFormatHyp(),
                    opTmHhStrt: outStrt[0],
                    opTmMmStrt: outStrt[1],
                    opTmHhEnd: outEnd[0],
                    opTmMmEnd: outEnd[1],
                    prgInfPk: this.prgInfPk,
                    prgNm: this.items.prgNm,
                    userNm: this.$store.getters.getUserNm,
                    wrtr: this.$store.getters.getUserNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    list: [],
                }
                this.prgBnfcrgrInfList.forEach(e => {
                    let item = {
                        prgBnfcrgrInfPk: e.prgBnfcrgrInfPk,
                        userNm: this.$store.getters.getUserNm,
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgInfPk: this.prgInfPk,
                        // bnfcrgrPrgPk: this.
                    }
                    obj.list.push(item)
                    // 20250116 상무님 요청 사항 제거
                    // if(e.bnfcrgrPrgPk==null){
                    //     obj.list.push(item)
                    // }
                });
        
                insPrgOpPln(obj)
                    .then((response) => (this.insPrgOpPlnAfter(response.data)))
                    .catch((error) => console.log(error))
            }else{
                let obj = {}
                obj.actv = true                         // 경고 노출여부
                obj.tmr = 2500                          // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                    // 경고 유형 (success, info, warning, error)
                obj.title = '필수 항목을 입력해주세요.'     // 경고 타이틀
                obj.cntnt = []                          // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        insPrgOpPlnAfter(res) {
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)
            
            if (res.statusCode == 200) {
                this.$emit('modalEvent', 99)
                this.onClose()               
            }
            else {
                obj.tmr = 3500
                obj.type = 'warning' 
            }

            this.$store.commit('setWrnnInf', obj)
        },
        onClose: function () {
            this.$emit('isClose', '.')    
            this.init()        
        },
        setPrgrm: function (obj) {
            let oObj = {}
            oObj.idxCd = obj[0].idxCd
            oObj.idxNm = obj[0].idxNm
            oObj.prgInfPk = obj[0].prgInfPk
            oObj.prgNm = obj[0].prgNm
            oObj.prgTypMngPk = obj[0].prgTypMngPk
            oObj.prgTypNm = obj[0].prgTypNm
            //oObj.rn
            oObj.useYn = obj[0].useYn

            this.btnSave = false
            this.items = oObj
            this.prgInfPk = obj[0].prgInfPk

            if(this.isUpd){
                this.selPrgInfInfo(this.$store.getters.getFcltyNum)
                this.selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum)
            }
            else
                this.selPrgInfInfo('10000000000')
        },
        setprggr: function (grInf) {
            let pkS = []
            let fxdArr = this.prgBnfcrgrInfList.filter( v => v.bnfcrgrPrgPk != null )
            fxdArr.forEach(itm => {
                pkS.push(itm.prgBnfcrgrInfPk)
            });

            this.prgBnfcrgrInfList.splice(0)
            this.prgBnfcrgrInfPks.splice(0)

            let vrdArr = []
            let vrdPk = []
            grInf.forEach(itm => {
                let chc = pkS.findIndex( v => v == itm.prgBnfcrgrInfPk )
                if(chc == -1){
                    let obj = {}
                    obj.bnfcrgrNm = itm.bnfcrgrNm
                    obj.bnfcrgrDesc = itm.bnfcrgrDesc
                    obj.prgBnfcrgrInfPk = itm.prgBnfcrgrInfPk
                    obj.idxCd = itm.idxCd
                    obj.idxNm = itm.idxNm

                    vrdArr.push(obj)
                    vrdPk.push(itm.prgBnfcrgrInfPk)
                }
            });

            this.prgBnfcrgrInfList = [...fxdArr, ...vrdArr]
            this.prgBnfcrgrInfPks = [...pkS, ...vrdPk]
            
            // grInf.forEach(x => {
            //     console.log(x.bnfcrgrPrgPk)
            //     let bl = this.prgBnfcrgrInfPks.includes(x.prgBnfcrgrInfPk)
            //     if (!bl) {
            //         let obj = {
            //             bnfcrgrNm: x.bnfcrgrNm,
            //             bnfcrgrDesc: x.bnfcrgrDesc,
            //             prgBnfcrgrInfPk: x.prgBnfcrgrInfPk,
            //             idxCd: x.idxCd,
            //             idxNm: x.idxNm,
            //             // bnfcrgrBnfcrPk: 0
            //         }
            //         this.prgBnfcrgrInfList.push(obj)
            //         this.prgBnfcrgrInfPks.push(x.prgBnfcrgrInfPk)
            //     }
            // })

            this.selBnfcrgrBnfcrList()
        },
        callRmMdl(key) {
            this.key = key
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = this.prgBnfcrgrInfList[key].bnfcrgrNm
            obj.cntnt = ['프로그램 수급자 그룹을 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },
        onDelete3(key) {
            let trgPk = this.prgBnfcrgrInfList[key].prgBnfcrgrInfPk
            let finArr = this.bnfcrgrBnfcrList.filter( v => v.prgBnfcrgrInfPk != trgPk )

            this.bnfcrgrBnfcrList = finArr

            let chc = this.prgBnfcrgrInfPks.findIndex( v => v == this.prgBnfcrgrInfList[key].prgBnfcrgrInfPk )
            if(chc > -1){
                this.prgBnfcrgrInfList.splice(key, 1)
                this.prgBnfcrgrInfPks.splice(chc, 1)
            }
        },
        delBnfcrgrPrgAfter(res) {
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200) {
                this.prgBnfcrgrInfList.splice(0)
                this.selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum)
            }
            else {
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
        //조회일자 설정 및 초기화
        setVal:function(){            
            this.btnDisOpDt = true
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.opDt)){
                this.inqymd = this.$moment(this.opDt, 'YYYY-MM-DD').format('YYYYMMDD')

                if(this.prgInfPk > 0){
                    this.selPrgInfInfo(this.$store.getters.getFcltyNum)
                    this.selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum)
                }

                this.btnDisOpDt = false
            } else {
                this.inqymd = null
            }
        }
    },

    data: () => ({
        key: 0,
        prgInfPk: 0,
        runAble: '0',
        outStrt: '',
        outEnd: '',
        opDt: '',
        inqymd:null,
        items: {},
        items1: {},
        btnOnOff: true,
        btnSave: true,
        btnDisOpDt:true,
        bnfcrgrBnfcrList: [],
        prgBnfcrgrInfList: [],
        prgBnfcrgrInfPks: [],
        prgGrNoItems: [],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },     // 통합 팝업 제어 (01: 프로그램 선택, 02: 프로그램 수급자 그룹관리
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', disabled: false, },
        ],
        bnfcrHeaders: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn0' },
            { text: '그룹명', value: 'name', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn1' },
            { text: '수급자', value: 'name', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn1' },
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn2' },
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        headers: [
            { text: '프로그램 유형', hisPk: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '수급자 그룹명', hisPk: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '설명', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
    }),

};
</script>