<template>
    <v-sheet class="pa-0 ma-0">
        <v-row class="mt-2" no-gutters>  
            <v-col class="d-flex justify-start align-center" cols="4">
                <CmmExpnBtn
                    v-model="isEntStt"
                    :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                    :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                </CmmExpnBtn>
                <CmmLvnExpnBtn
                    v-model="isLvnStt"
                    class="ml-1"
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>
                <span class="ml-1 d-inline-block" style="width: 106px; height: 34px;">
                    <v-text-field
                        v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                        class="txt-Theme" height="34" label="이름조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-col>          
            <v-col class="d-flex justify-center align-center" cols="4">
                <expandMonthPicker @nowYearWithMonth="getPickerDate" :prtDate="''"></expandMonthPicker>
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="4">
                <v-btn                    
                    class="white--text mr-1" style="padding: 10px 10px 10px 10px !important;"
                    color="grey006"
                    @click="prtAllAgrOfr('93')"
                    min-width="20" height="20" small outlined>
                    <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">구강관찰기록지 출력</span>
                </v-btn>
            </v-col>
        </v-row>    
        <v-row class="ma-0 pa-0">
            <v-data-table locale="ko" height="660"
                class="tableBB mt-2"
                :headers="headers" :items="oralCareList"
                :loading="isLoad" :loading-text="loadMsg"
                :search="dmyTxt" :custom-filter="srchFltr" 
                fixed-header disable-pagination hide-default-footer dense>

                <template v-slot:header="props" >
                    <thead>
                        <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
            
                    <tr class="fixedCol">
                        <td class="fixedTable fixPstn0 zPstn2 txtAlgnMid fontOneRem removeBB">
                            <span>{{item.rn}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable fixPstn1 zPstn2 txtAlgnMid fontOneRem removeBB">
                            <span>{{item.name}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable fixPstn3 zPstn2 txtAlgnMid fontOneRem removeBB">
                            <span>{{item.entStcdNm}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.lvnRmNm}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">{{$moment(item.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">{{item.orlCndNm}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">{{item.dntCndNm}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">{{item.clnMthdNm}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">{{item.spclNm}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        
                        <td class="txtAlgnMid fontOneRem removeBB">                            
                            <span v-if="item.dntObsPk > 0">{{item.dtlCntntNm}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">{{item.actns}}</span>
                            <span v-else>&#173;</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable txtAlgnMid fixRPstn01 zPstn2 pa-1 fontOneRem removeBB">
                            <span v-if="item.dntObsPk > 0">
                                <v-btn @click=onOralSav(item) icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </span>
                            <span v-else>                                
                                <v-btn          
                                    min-width="56" @click=onOralSav(item) width="56" height="26" color="red" samll outlined
                                >
                                    미작성
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-row>
        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn" :max-width="lclMdlInf.maxWidth"
            content-class="round"
            persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부, checkr: 체크박스 모델
            -->           
            <OralCareSavMdl
                v-if="lclMdlInf.typ == '01'"
                :inObj="sltObj"
                :cd142="vCd142"
                :cd152="vCd152"
                :cd153="vCd153"
                :cd154="vCd154"
                :cd155="vCd155"
                @isClose="rstLclMdlInf">
            </OralCareSavMdl>
        </v-dialog>
    </v-sheet> 
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import OralCareSavMdl from './OralCareSavMdl.vue';

export default {
    name: 'OralCareMgmt',

    props : {        
    },

    components: {
        CmmExpnBtn,
        CmmLvnExpnBtn,
        expandMonthPicker,
        OralCareSavMdl,
    },

    computed: {

    },

    watch: {        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    methods:{
        getPickerDate: function (date) {  
            this.inqyyyymm = date

            if(this.vCd65.length > 0)
                this.getAllDntObsList()
            else
                this.getCdnAllDntObsList()
        },
         // 전체 구강관찰기록 다건 조회(코드포함)
         getCdnAllDntObsList: function (){
            this.oralCareList.splice(0)
            this.isLoad = true
            let obj = {}
            obj.inqYYMM = this.inqyyyymm

            http.post('bnftrcrd/selCdnAllDntObsList', obj)
                .then((response) => ( this.allDntObsListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selCdnAllDntObsList : ' + error))
        },

        //전체 구강관찰기록 다건 조회
        getAllDntObsList: function (){
            this.oralCareList.splice(0)
            this.isLoad = true
            let obj = {}
            obj.inqYYMM = this.inqyyyymm

            http.post('bnftrcrd/selAllDntObsList', obj)
                .then((response) => ( this.allDntObsListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllDntObsList : ' + error))
        },

        allDntObsListRst:function(res){
            
            this.isLoad = false

            if(res.statusCode == '200'){
                if(res.result.list != null && res.result.list != undefined) {
                    
                    if(this.vCd65.length == 0){
                        if(res.result.codes != null) { 
                            this.vCd62 = res.result.codes.vCd62
                            this.vCd63 = res.result.codes.vCd63
                            this.vCd65 = res.result.codes.vCd65
                            this.vCd142 = res.result.codes.vCd142
                            this.vCd152 = res.result.codes.vCd152
                            this.vCd153 = res.result.codes.vCd153
                            this.vCd154 = res.result.codes.vCd154
                            this.vCd155 = res.result.codes.vCd155

                            if(this.vCd65.length > 0) this.vCd65[0].isOn = true
                        }
                    }

                    res.result.list.forEach(item => {  
                        
                        this.vCd65.forEach(code => {
                            if(item.entStcd == code.valcd) item.entStcdNm = code.valcdnm
                        });
                        
                        let orlCndNm    = ''
                        let dntCndNm    = ''
                        let clnMthdNm   = ''
                        let spclNm      = ''
                        let dtlCntntNm  = ''
                        // 구강상태코드(공통항목값분류코드 152참조)
                        if(item.orlCndCd != null && item.orlCndCd != ''){
                            item.orlCndCd.forEach((val, index) => {                                
                                if(index != 0) orlCndNm   += ','
                                
                                this.vCd152.forEach(code => {
                                    if(val == code.valcd) orlCndNm += code.valcdnm
                                });
                            });
                        }
                        // 치아상태코드(공통항목값분류코드 153참조)
                        if(item.dntCndCd != null && item.dntCndCd != ''){
                            this.vCd153.forEach(code => {
                                if(item.dntCndCd == code.valcd) dntCndNm = code.valcdnm
                            });
                        }
                        // 세척방법코드(공통항목값분류코드 154참조)
                        if(item.clnMthdCd != null && item.clnMthdCd != ''){
                            item.clnMthdCd.forEach((val, index) => {                                
                                if(index != 0) clnMthdNm   += ','
                                
                                this.vCd154.forEach(code => {
                                    if(val == code.valcd) clnMthdNm += code.valcdnm
                                });
                            });
                        }
                        //특이사항코드(공통항목값분류코드 142참조)
                        if(item.spclCd != null && item.spclCd != ''){
                            this.vCd142.forEach(code => {
                                if(item.spclCd == code.valcd) spclNm = code.valcdnm
                            });
                        }
                        // 상세내용코드(공통항목값분류코드 155참조)
                        if(item.dtlCntntCd != null && item.dtlCntntCd != ''){
                            item.dtlCntntCd.forEach((val, index) => {                                
                                if(index != 0) dtlCntntNm   += ','
                                
                                this.vCd155.forEach(code => {
                                    if(val == code.valcd) dtlCntntNm += code.valcdnm
                                });
                            });
                        }
                        
                        item.orlCndNm   = orlCndNm
                        item.dntCndNm   = dntCndNm
                        item.clnMthdNm  = clnMthdNm
                        item.spclNm     = spclNm
                        item.dtlCntntNm = dtlCntntNm
                        this.oralCareList.push(item)
                    });
                }
            }
            else {
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }  
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )
            
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                field3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else field3 = field3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return field1 && field2 && field3
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr

            this.dmyTxt = String(Number(this.dmyTxt)+1)
        },

        //구강관찰기록 ON
        onOralSav:function(itm){
            
            this.sltObj.name        = itm.name
            this.sltObj.bnMmbrPk    = itm.mmbrPk
            this.sltObj.dntObsPk    = itm.dntObsPk
            this.sltObj.orlCndCd    = itm.orlCndCd
            this.sltObj.dntCndCd    = itm.dntCndCd
            this.sltObj.clnMthdCd   = itm.clnMthdCd
            this.sltObj.spclCd      = itm.spclCd
            this.sltObj.dtlCntntCd  = itm.dtlCntntCd
            this.sltObj.actns       = itm.actns
            this.sltObj.wrtr        = itm.wrtr
            this.sltObj.wrtDt       = itm.wrtDt
            this.sltObj.wrtrMmbrPk  = itm.wrtrMmbrPk
            this.sltObj.obsYymm     = (itm.obsYymm != '' && itm.obsYymm != null)?itm.obsYymm:this.inqyyyymm

            this.onLclMdl('01', 800)
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
            this.getAllDntObsList()
        },
        //출력
        prtAllAgrOfr:function(prtcd){            

            this.prtThrowObj.fcltyNum   = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYYMM    = this.inqyyyymm
            this.prtThrowObj.prtCd      = prtcd

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        }
    },

    data: () => ({
        isLoad      : true,
        loadMsg     : '잠시만 기다려주세요.',
        inqyyyymm   :'',
        vCd62       : [],
        vCd63       : [],
        vCd65       : [],
        vCd142      : [],
        vCd152      : [],
        vCd153      : [],
        vCd154      : [],
        vCd155      : [],
        srchTxt     : '',                            // 테이블 이름 검색 필드
        dmyTxt      : '',                           // 테이블 반응 더미(검색용)
        routeLctnNm : '',                           // 라우터 경로
        isEntStt    : false,                        // 현황 확장 활성화 여부
        isLvnStt    : false,                        // 생활실 확장 활성화 여부
        aslLvnStt   : [],
        sltObj      : {},
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 fixPstn0 greyE01 zPstn3 fontOneRem'},
            { text: '수급자', value: 'name', sortable: true, width: '110', align: 'center', class: 'black--text fixPstn1 greyE01 zPstn3 fontOneRem'},
            { text: '현황', value: 'entStcd', sortable: true, width: '120', align: 'center', class: 'black--text fixPstn3 greyE01 zPstn3 fontOneRem'},           
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '작성일', value: 'wrtDt', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '구강상태', value: 'orlCndCd', sortable: false, width: '250', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '치아상태', value: 'dntCndCd', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '세정방법', value: 'clnMthdCd', sortable: false, width: '400', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '특이사항', value: 'spclCd', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '상세내용', value: 'dtlCntntCd', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '조치사항', value: 'actns', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '기록', value: 'action', sortable: false, width: '100', align: 'center', class: 'black--text fixRPstn01 zPstn3 greyE01 fontOneRem'},
        ],
        oralCareList   : [],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 1, prtCd: '', inqYYMM: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 수급자 선택)
    })
}
</script>