<template>
    <v-container fluid>
        <v-divider class="white mt-1" :style="{ width: $parent.$parent.viewWdth+'px' }"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-btn
                class="rndClrBtn-Theme white--text my-0" height="28" color="blueBtnColor"
                @click="onOpnnClctnAllMdl"
                samll rounded>
                <v-icon small>mdi-pencil-outline</v-icon>
                <span>신규 의견수렴</span>
            </v-btn>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row class="pa-0 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 의견수렴 내역</span>
        </v-row>
        <v-divider :class="[rowCnt > 15 ? 'mr-2__5' : '']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', rowCnt > 15 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="1">
                <span class="font-weight-medium">분기</span>
            </v-col>
            <v-col class="" cols="11">
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                        <span class="font-weight-medium">프로그램 유형</span>
                    </v-col>
                    <v-col cols="10">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                <span class="font-weight-medium">수급자 그룹</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                <span class="font-weight-medium">작성일</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span class="font-weight-medium">작성자</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                <span class="font-weight-medium">의견수렴 반영</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="1">
                                <span class="font-weight-medium">상세</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider :class="[rowCnt > 15 ? 'mr-2__5' : '']" color=""></v-divider>
        <div v-if="imprvGrpList.length > 0" class="overflow-y-auto" style="width: 100%; height: 482px;">
            <v-row
                class="tableBB"
                v-for="(itm, i) in imprvGrpList" :key="i"
                no-gutters>
                <v-col class="d-flex align-center justify-center tableBR pa-1" cols="1">
                    <span class="fontOneRem">{{ itm.wrtQrtr }}분기</span>
                </v-col>
                <v-col class="" cols="11">
                    <v-row
                        v-for="(idx, i) in itm.idxList" :key="i"
                        :class="['pa-0', itm.idxList.length != (i+1) ? 'tableBB' : '']"
                        no-gutters>
                        <v-col class="d-flex align-center justify-center tableBR pa-1" cols="2">
                            <span class="fontOneRem">{{ idx.idxNm }}</span>
                        </v-col>
                        <v-col class="" cols="10">
                            <v-row
                                v-for="(grp, j) in idx.grpList" :key="j"
                                :class="['pa-0', idx.grpList.length != (j+1) ? 'tableBB' : '']"
                                no-gutters>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="3">
                                    <span class="fontOneRem">{{ grp.bnfcrgrNm }}</span>
                                </v-col>
                                <v-col class="txtAlgnMid tableBR pa-1" cols="3">
                                    <span class="fontOneRem">{{ grp.wrtDtDot }}</span>
                                </v-col>
                                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                                    <span class="fontOneRem">{{ grp.wrtr }}</span>
                                </v-col>
                                <v-col class="crsShp-Pointer txtAlgnMid tableBR pa-1" @click="onOpnnRflctMdl(grp, itm.wrtQrtr)" cols="3">
                                    <span v-if="grp.rflDtDot == ''" class="fontOneRem red--text">미반영</span>
                                    <span v-else class="fontOneRem">{{ grp.rflDtDot }}</span>
                                </v-col>
                                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">
                                    <v-btn
                                        class="nmlBfClrImp-white"
                                        color="blueBtnColor" min-width="20" height="20"
                                        @click="$parent.$parent.onOpnnClctnMdl(grp, itm.wrtQrtr)"
                                        small icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                            </template>
                                            <span>의견수렴 상세</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PrgImprvAnthr',
    
    components: {

    },

    props : { 
        prtYyyy: { type: Number, default: 2000 },
        prtCd40: { type: Array, default: () => { return [] } },
    },

    created: function(){
       
    },

    computed: {
        chngEvnt: function () {
            return this.$parent.$parent.prgmImprvList.length
        },
    },

    watch: {
        chngEvnt: function (v) {
            if(v > 0)
                this.getImprvGrpList(this.prtYyyy)
            else
                this.imprvGrpList.splice(0)
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.prtYyyy > 2024 && this.$parent.$parent.prgmImprvList.length == 0)
                this.getImprvGrpList(this.prtYyyy)
        })
    },
    
    methods: {
        // 연도별 프로그램 개선 내역 조회
        getImprvGrpList: function (yyyy) {
            this.imprvGrpList.splice(0)
            
            let obj = {}
            obj.inqYYYY = yyyy

            http.post('prgrm/selPrgImprvGrpList', obj)
                .then((response) => ( this.imprvGrpRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvGrpList : ' + error))
        },

        imprvGrpRst: function (res) {
            if(res.statusCode == '200'){
                this.rowCnt = 0
                res.result.list.forEach(dep1 => {
                    dep1.idxList.forEach(dep2 => {
                        let chc = this.prtCd40.findIndex( v => v.valcd == dep2.idxCd )
                        if(chc > -1)
                            dep2.idxNm = this.prtCd40[chc].valcdnm
                        else
                            dep2.idxNm = ''

                        dep2.grpList.forEach(dep3 => {
                            this.rowCnt += 1
                            if(dep3.wrtDt == null) dep3.wrtDt = ''
                            if(dep3.rflDt == null) dep3.rflDt = ''

                            if(dep3.wrtDt != '')
                                dep3.wrtDtDot = this.$moment(dep3.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                            else
                                dep3.wrtDtDot = ''
                            if(dep3.rflDt != '')
                                dep3.rflDtDot = this.$moment(dep3.rflDt, 'YYYYMMDD').format('YYYY.MM.DD')
                            else
                                dep3.rflDtDot = ''

                            dep3.idxNm = dep2.idxNm
                        });
                    });

                });
              
                this.imprvGrpList = res.result.list
            }
        },

        // 프로그램 의견수렴 (연단위) 저장 팝업 오픈
        onOpnnClctnAllMdl: function () {
            let oObj = {}
            oObj.trgYyyy = this.$parent.$parent.sltYyyy
            oObj.trgQtr = ''
            oObj.prgBnfcrgrInfPk = -1
            oObj.bnfcrgrNm = ''
            oObj.idxCd = ''
            oObj.idxNm = ''
            oObj.prgImprvPk = -1

            this.$store.commit('setTmpObj', oObj)
            this.$parent.$parent.onLclMdl('01', 1200)
        },

        // 프로그램 의견반영 저장 팝업 오픈
        onOpnnRflctMdl: function (obj, qtr) {
            let oObj = {}
            oObj.trgYyyy = this.$parent.$parent.sltYyyy
            oObj.trgQtr = qtr
            oObj.prgImprvPk = obj.prgImprvPk
            oObj.wrtDt = obj.wrtDt
            oObj.wrtr = obj.wrtr
            oObj.idxNm = obj.idxNm
            oObj.bnfcrgrNm = obj.bnfcrgrNm
            oObj.imprvOpn = obj.imprvOpn

            this.$store.commit('setTmpObj', oObj)
            this.$parent.$parent.onLclMdl('02', 1200)
        },
    },

    data: () => ({
        rowCnt: 0,
        imprvGrpList: [],
    }),
};
</script>