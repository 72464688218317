<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">그룹별 프로그램 설정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">그룹 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">수급자 그룹</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ bscsInf.bnfcrgrNm }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid pb-1" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ bscsInf.idxCdNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">설명</span>
            </v-col>
            <v-col class="overflow-text-hidden px-3 pb-1" style="padding-top: 3px;" cols="10">
                <span class="fontOneRem">{{ bscsInf.bnfcrgrDesc }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">그룹 설정 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium posn-rltv">
                    설정일자
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center tableBR px-4" cols="4">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp v-model="bscsInp.wrtDt" :required="true" @input="aplctSavBtn"></CmmDateComp>
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium posn-rltv">
                    작성자
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="4">
                <span class="white d-inline-block" style="width: 140px;">
                    <v-text-field
                        class="text-field-clearable" :append-icon="bscsInp.wrtr == '' ? '' : 'mdi-close-circle-outline'"
                        v-model="bscsInp.wrtr" @click:append="clrWrtrField" @input="aplctSavBtn"
                        height="28" readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="brown lighten-3 ml-1" style="padding: 0px 1px 0px 2px !important;"
                    min-width="30" height="28"
                    @click="onLclMdl('01', 700)"
                    small depressed>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-5" cols="2">
                <span class="font-weight-medium">비고</span>
            </v-col>
            <v-col class="px-4 py-1" cols="10">
                <v-textarea
                    class="rmrksArea" rows="3" 
                    v-model="bscsInp.rmrks" @input="aplctSavBtn"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 목록</span>
        </v-row>
        <v-divider :class="[grpByPrgmSttList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row
            :class="['nmlBfClrImp-greyE01 pa-0', grpByPrgmSttList.length > 5 ? 'mr-2__5' : 'mr-1']"
            no-gutters>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">프로그램 구분</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="6">
                <span class="fontOneDotOneRem font-weight-bold">프로그램명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 7px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">설정일자</span>
            </v-col>
            <v-col class="txtAlgnMid" style="padding-top: 6px;" cols="1">
                <v-btn
                    class="" color="blue200" height="26"
                    @click="onLclMdl('02', 900)"
                    icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider :class="[grpByPrgmSttList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="grpByPrgmSttList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', grpByPrgmSttList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in grpByPrgmSttList" :key="i"
                no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.prgTypNm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ itm.idxCdNm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="6">
                    <span class="fontOneRem">{{ itm.prgNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span v-if="itm.wrtDt != null" class="fontOneRem">
                        {{ $moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                    </span>
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">                                     
                    <v-btn                        
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="rmPrgmRow(i)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-minus-circle-outline</v-icon>
                            </template>
                            <span>프로그램 제거</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 그룹에 소속된 프로그램이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn" :max-width="lclMdlInf.maxWidth"
            content-class="round"
            persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부, checkr: 체크박스 모델
            -->
            <IntgrSlctn
                v-if="lclMdlInf.typ == '01'"
                :mdlTtl="'직원 선택'" :tabInfList="['2']" :fixList="[]" :mode="'1'"
                :cd41="prtCd41"
                :cd42="prtCd42"
                :cd65="prtCd65"
                :prtWrtDt="bscsInp.wrtDt"
                @intgrResList="getAplyList" @onClose="rstLclMdlInf">
            </IntgrSlctn>
            <PrgmSlector
                v-if="lclMdlInf.typ == '02'"
                ref="pSelect" :sltTyp="'multi'"
                @isClose="rstLclMdlInf"  @outPrgrm="getSltPrgm">
            </PrgmSlector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import PrgmSlector from './PrgrmSelect.vue';

export default {
    name: 'PrgmGrpCnfgrMdl',
    
    components: {
        CmmDateComp,
        IntgrSlctn,
        PrgmSlector,
    },

    props : { 
        prtCd40: { type: Array, default: () => { return [] } },
        prtCd41: { type: Array, default: () => { return [] } },
        prtCd42: { type: Array, default: () => { return [] } },
        prtCd65: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
        
    },

    watch: {
       
    },
        
    methods: {
        // 프로그램 수급자 그룹 목록 조회
        getBnfcrGrpList: function (){
            this.savBtn = true
            this.grpByPrgmSttList.splice(0)

            let obj = {}
            obj.prgBnfcrgrInfPk = this.bscsInf.prgBnfcrgrInfPk

            http.post('prgrm/selBnfcrGrpInfo', obj)
                .then((response) => ( this.bnfcrGrpRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selBnfcrGrpInfo : ' + error))
        },

        bnfcrGrpRst: function (res){
            if(res.statusCode == '200'){
                this.bscsInf.bnfcrgrNm = res.result.bnfcrInfo.bnfcrgrNm
                this.bscsInf.bnfcrgrDesc = res.result.bnfcrInfo.bnfcrgrDesc
                this.bscsInf.idxCd = res.result.bnfcrInfo.idxCd
                this.bscsInf.useYn = res.result.bnfcrInfo.useYn

                let chc = this.prtCd40.findIndex( v => v.valcd == res.result.bnfcrInfo.idxCd )
                if(chc > -1)
                    this.bscsInf.idxCdNm = this.prtCd40[chc].valcdnm

                if(Array.isArray(res.result.prgList)){
                    res.result.prgList.forEach(itm => {
                        let idx = this.prtCd40.findIndex( v => v.valcd == itm.idxCd )
                        if(idx > -1)
                            itm.idxCdNm = this.prtCd40[chc].valcdnm
                    });

                    this.grpByPrgmSttList = res.result.prgList
                }
            }

            this.aplctSavBtn()
        },

        // 그룹별 프로그램 단건 저장
        setBnfcrgrPrgs: function (obj){
            this.savBtn = true

            http.post('prgrm/insMulitBnfcrgrPrg', obj)
                .then((response) => ( this.grpPrgRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/insMulitBnfcrgrPrg : ' + error))
        },

        grpPrgRst: function (res){
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200){
                this.rstFld()
                this.bscsInp.wrtDt = this.$moment().format('YYYY-MM-DD')
                this.getBnfcrGrpList()
            }
            else {
                obj.tmr = 5000
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
            
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.prgBnfcrgrInfPk = obj.prgBnfcrgrInfPk
            this.bscsInp.wrtDt = this.$moment().format('YYYY-MM-DD')
            
            this.getBnfcrGrpList()
        },

        // 저장버튼
        dcsnSavBtn: function () {            
            let obj = {}   
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr         
            obj.wrtDt = this.$moment(this.bscsInp.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD')
            obj.wrtr = this.bscsInp.wrtr
            obj.rmrks = this.bscsInp.rmrks
            obj.prgBnfcrgrInfPk = this.bscsInf.prgBnfcrgrInfPk
            obj.list = []

            this.grpByPrgmSttList.forEach(itm => {                
                let inObj = {}
                inObj.prgInfPk = itm.prgInfPk
                obj.list.push(inObj)
            });
            this.setBnfcrgrPrgs(obj)
        },

        // 수급자 선택 이벤트
        getAplyList: function (arr){
            if(arr.length == 1){
                this.bscsInp.wrtr = arr[0].name
                this.bscsInp.wrtrPk = arr[0].empPk
            }

            this.aplctSavBtn()
        },

        // 프로그램 선택 이벤트
        getSltPrgm: function (arr){
            let errList = []

            arr.forEach(itm => {
                let chc = this.grpByPrgmSttList.findIndex( v => v.prgInfPk == itm.prgInfPk )
                if(chc == -1){
                    let obj = Object.assign({}, itm)
                    obj.bnfcrgrPrgPk = null
                    obj.idxCdNm = obj.idxCdnm           //컬럼 수정
                    this.grpByPrgmSttList.push(obj)
                }
                else
                    errList.push(itm.prgNm)
            });
            
            if(errList.length > 0){
                let obj = {}
                obj.actv = true                                       // 경고 노출여부
                obj.tmr = 3500                                        // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                                  // 경고 유형 (success, info, warning, error)
                obj.title = '기 등록된 프로그램은 추가 할 수 없습니다.'     // 경고 타이틀
                obj.cntnt = errList                                   // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 프로그램 목록 제거
        rmPrgmRow: function (idx) {
            this.grpByPrgmSttList.splice(idx, 1)
        },

        // 작성자 초기화
        clrWrtrField: function () {
            this.bscsInp.wrtr = ''
            this.bscsInp.wrtrPk = -1
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInp.wrtDt = ''
            this.bscsInp.wrtrPk = -1
            this.bscsInp.wrtr = ''
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {            
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInp.wrtDt) == false)
                return 0
            if(this.bscsInp.wrtr == '')
                return 0

            this.savBtn = false
        },
    },

    data: () => ({
        savBtn: true,        
        grpByPrgmSttList: [],
        bscsInf: { prgBnfcrgrInfPk: -1, bnfcrgrNm: '', bnfcrgrDesc: '', idxCd: '', idxCdNm: '', useYn: '' },
        bscsInp: { wrtDt: '', wrtr: '', wrtrPk: -1 },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 수급자 선택)
    }),
};
</script>