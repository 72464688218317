import { http } from '@/api/baseAxios';

//프로그램 운영기록 작성용 내역 조회
function selPrgOpRecWrtList(code,startWeek,endWeek){
    return http.post('prgrm/selPrgOpRecWrtList', { fcltyNum: code, inqYmdStart : startWeek, inqYmdEnd: endWeek })
}
//프로그램 운영기록 작성
function insPrgOpRecFiles(obj){
    return http.post('prgrm/insPrgOpRecFiles',obj)
}
//프로그램정보 다건 조회
function getAllPrgIM(code, prgInfPk){
    return http.post('prgrm/selPrgInflist', { fcltyNum: code, prgInfPk : prgInfPk })
}
//프로그램정보 한건 조회
function selPrgInfInfo(code, prgInfPk, ymd=null){
    return http.post('prgrm/selPrgInfInfo', { fcltyNum: code, prgInfPk : prgInfPk, inqYMD : ymd })
}
//프로그램 유형관리 조회
function selPrgTypMnglist(code,prgTypMng){
    return http.post('prgrm/selPrgTypMnglist', { fcltyNum: code, prgTypMngPk: prgTypMng })
}
//프로그램 유형관리 저장
function insPrgTypMng(obj){
    return http.post('prgrm/insPrgTypMng', obj)
}
//프로그램 유형관리 삭제
function delPrgTypMng(obj) {
    return http.post('prgrm/delPrgTypMng', obj)
}
//프로그램 정보 저장
function insPrgInf(obj) {
    return http.post('prgrm/insPrgInf', obj)
}
//프로그램 정보 삭제
function delPrgInf(obj) {
    return http.post('prgrm/delPrgInf', obj)
}
//프로그램 운영기록 한건 조회
function selPrgOpRecInfo(code, prgOpRecPk) {
    return http.post('prgrm/selPrgOpRecInfo', { fcltyNum: code, prgOpRecPk : prgOpRecPk})
}
//프로그램 운영기록 삭제
function delPrgOpRec(obj) {
    return http.post('prgrm/delPrgOpRec', obj)
}
//프로그램 수급자그룹관리 조회
function selPrgBnfcrgrInfList(code, prgInfPk, usable, ymd) {
    return http.post('prgrm/selPrgBnfcrgrInfList', { fcltyNum: code, prgInfPk: prgInfPk, useYn: usable, inqYMD: ymd })
}
//프로그램 수급자그룹정보 조회(수급자별)
//수급자 그룹별 수급자 관리 조회
//참여대상 수급자 정보
function selBnfcrgrBnfcrList(code, bnMmbrPk, prgBnfcrgrInfPk = null, prgBnfcrgrInfPks = null, ymd = null) {
    return http.post('prgrm/selBnfcrgrBnfcrList', { fcltyNum: code, bnMmbrPk : bnMmbrPk, prgBnfcrgrInfPk : prgBnfcrgrInfPk, prgBnfcrgrInfPks : prgBnfcrgrInfPks, inqYMD: ymd })
}
//프로그램 수급자그룹정보 저장
function insBnfcrgrBnfcrMulti(obj) {
    return http.post('prgrm/insBnfcrgrBnfcrMulti', obj)
}
//프로그램 수급자그룹정보 저장
function insBnfcrgrBnfcr(obj) {
    return http.post('prgrm/insBnfcrgrBnfcr', obj)
}
//프로그램 수급자그룹정보 삭제
function delBnfcrgrBnfcr(obj) {
    return http.post('prgrm/delBnfcrgrBnfcr', obj)
}

//관련프로그램 조회(수급자그룹별)
function selBnfcrgrPrgList(code, bnMmbrPk, prgBnfcrgrInfPk) {
    return http.post('prgrm/selBnfcrgrPrgList'
    , { fcltyNum: code, bnMmbrPk : bnMmbrPk, prgBnfcrgrInfPk : prgBnfcrgrInfPk})
}
//프로그램수급자그룹정보 다건 조회
function selPrgBnfcrgrList(code) {
    return http.post('prgrm/selPrgBnfcrgrList', { fcltyNum: code})
}
//프로그램운영 기록 수급자평가 다건 조회
function selPrgOpRecBnfcrList(code, prgInfPk, prgOpRecPk, prgOpPlnPk, inqYMD, opTmHh, opTmMm ) {
    return http.post('prgrm/selPrgOpRecBnfcrList'
    , { fcltyNum: code, prgInfPk: prgInfPk, prgOpRecPk: prgOpRecPk, prgOpPlnPk: prgOpPlnPk, inqYMD: inqYMD, opTmHh: opTmHh, opTmMm: opTmMm})
}
//프로그램 참여 대상자 수 조회
function selBnfcrgrBnfcrInfo(code, prgBnfcrgrInfPk) {
    return http.post('prgrm/selBnfcrgrBnfcrInfo', { fcltyNum: code, prgBnfcrgrInfPk: prgBnfcrgrInfPk})
}
//프로그램 수급자그룹관리 저장
function insPrgBnfcrgrInf(obj) {
    return http.post('prgrm/insPrgBnfcrgrInf', obj)
}
//프로그램 수급자그룹관리 삭제
function delPrgBnfcrgrInf(obj) {
    return http.post('prgrm/delPrgBnfcrgrInf', obj)
}
//프로그램운영 기록 수급자별 기본 조회
function selPrgOpRecBnfcrInfo(code, prgOpPlnPk) {
    return http.post('prgrm/selPrgOpRecBnfcrInfo', { fcltyNum: code, prgOpPlnPk: prgOpPlnPk})
}
//프로그램참여수급자 저장  
function insPrgPrtcpBnfcrMulti(obj) {
    return http.post('prgrm/insPrgPrtcpBnfcrMulti', obj)
}
//프로그램참여수급자 삭제
function delPrgPrtcpBnfcr(obj) {
    return http.post('prgrm/delPrgPrtcpBnfcr', obj)
}
//프로그램 일정등록 저장
function insPrgOpPln(obj){
    return http.post('prgrm/insPrgOpPln',obj)
}
//프로그램개선 분기 내역 조회
function selPrgImprvQrtrList(code, yyyy){
    return http.post('prgrm/selPrgImprvQrtrList',{ fcltyNum: code, inqYYYY: yyyy})
}
//프로그램운영 기록 내역 조회
function selPrgOpRecList(code, yyyy, inq){
    return http.post('prgrm/selPrgOpRecList',{ fcltyNum: code, inqYYYY: yyyy, inqQtr:inq})
}
//프로그램개선사항 내역 다건 조회
function selPrgImprvList(code, yyyy, inq){
    return http.post('prgrm/selPrgImprvList',{ fcltyNum: code, inqYYYY: yyyy, inqQtr:inq})
}
//프로그램개선 분기 내역 한건 조회
function selPrgImprvInfo(code, value){
    return http.post('prgrm/selPrgImprvInfo',{ fcltyNum: code, prgImprvPk : value})
}
//프로그램개선(의견수렴) 작성
function insPrgImprv(obj){
    return http.post('prgrm/insPrgImprv', obj)
}
//프로그램개선(의견수렴) 삭제
function delPrgImprv(obj){
    return http.post('prgrm/delPrgImprv',obj)
}
//관련프로그램 저장
function insBnfcrgrPrg(obj){
    return http.post('prgrm/insBnfcrgrPrg',obj)
}
//관련프로그램 저장(다건)
function insMultiBnfcrgrPrg(obj){
    return http.post('prgrm/insMultiBnfcrgrPrg',obj)
}
//프로그램운영계획 삭제
function delPrgOpPln(obj){
    return http.post('prgrm/delPrgOpPln',obj)
}
//프로그램운영계획 삭제
function delBnfcrgrPrg(obj){
    return http.post('prgrm/delBnfcrgrPrg',obj)
}
//프로그램 일정관리 조회
function selPrgOpSchdList( code, strt, end ){
    return http.post('prgrm/selPrgOpSchdList', { fcltyNum: code, inqYmdStart: strt, inqYmdEnd: end })
}

//프로그램계획 한건 조회
function selPrgOpPlnInfo( code, pk ){
    return http.post('prgrm/selPrgOpPlnInfo', { fcltyNum: code, prgOpPlnPk: pk })
}


export {
    //완성된 함수를 외부에 알림
    selPrgOpRecWrtList,
    insPrgOpRecFiles,
    getAllPrgIM,
    selPrgInfInfo,
    insPrgTypMng,
    delPrgTypMng,
    selPrgTypMnglist,
    insPrgInf,
    delPrgInf,
    selPrgOpRecInfo,
    delPrgOpRec,
    selPrgBnfcrgrInfList,
    selBnfcrgrBnfcrList,
    selBnfcrgrPrgList,
    insBnfcrgrBnfcrMulti,
    delBnfcrgrBnfcr,
    insPrgBnfcrgrInf,
    delPrgBnfcrgrInf,
    selPrgBnfcrgrList,
    insBnfcrgrBnfcr,
    selBnfcrgrBnfcrInfo,
    selPrgOpRecBnfcrList,
    selPrgOpRecBnfcrInfo,
    insPrgPrtcpBnfcrMulti,
    delPrgPrtcpBnfcr,
    insPrgOpPln,
    selPrgImprvQrtrList,
    selPrgOpRecList,
    selPrgImprvList,
    selPrgImprvInfo,
    insPrgImprv,
    delPrgImprv,
    insBnfcrgrPrg,
    insMultiBnfcrgrPrg,
    delPrgOpPln,
    delBnfcrgrPrg,
    selPrgOpSchdList,
    selPrgOpPlnInfo,
}