<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-6. 인건비&nbsp;지출비율&nbsp;계산</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
        </div>
        <div class="mt-5">
            <expandMonthPicker :prtDate="date" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <div class="mt-3 px-2">
        <v-form ref="form" lazy-validation>
            <v-row no-gutters>
                <v-col class="ma-0 pr-4" cols="12">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">{{yymm?.beforeDateFormatMonthDot()}}&nbsp;인건비반영&nbsp;급여비용&nbsp;합계</span>
                    </div>
                    <v-divider class="mt-3"></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-2" cols="6">
                            <span class="black--text font-weight-medium">인건비반영 급여비용 합계 ①</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-2 pl-4" cols="3">
                            <span class="d-flex black--text blnftAmntlnf-text-field" style="width:200px;">
                                <v-text-field :rules="[rules.required, rules.numberCheck]"
                                    v-model="items.bnftSlrExp" @input="getTotCalcl"
                                    type="number" height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                                &nbsp;원
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center py-2 pl-4" cols="3">
                            <span>{{items.bnftSlrExp?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <div class="d-flex mt-3 align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">{{yymm?.beforeDateFormatMonthDot()}}&nbsp;인건비&nbsp;지급내역</span>
                        <span class="text-h7">&nbsp;(※&nbsp;금액&nbsp;단위&nbsp;:&nbsp;원)</span>
                    </div>
                    <v-divider class="mt-3"></v-divider>
                    <v-row class="greyE01" no-gutters>
                        <v-col class="d-flex justify-center align-center py-2" cols="2">
                            <span class="black--text font-weight-medium">종사자&nbsp;구분</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-2 tableBL" cols="1">
                            <span class="black--text font-weight-medium">실배치&nbsp;인원</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-2 tableBL" cols="3">
                            <span class="black--text font-weight-medium">임금&nbsp;총액&nbsp;②</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-2 tableBL" cols="3">
                            <span class="black--text font-weight-medium">사회보험&nbsp;기관부담금&nbsp;③</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-2 tableBL" cols="3">
                            <span class="black--text font-weight-medium">퇴직&nbsp;적립금&nbsp;④</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <div v-for="(list, i) in items1" :key="i">
                        <v-row no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                                <span class="black--text">{{list.jobNm}}</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-2 tableBL" cols="1">
                                <span class="txtfldRT" style="width:70px">
                                    <v-text-field @input="empCntInput" color="blue" height="28" v-model="list.empCnt" type="number" hide-details outlined dense></v-text-field>
                                </span>
                                <span class="ml-1">명</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-2 tableBL" cols="3">
                                <v-row no-gutters>
                                    <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="7">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:150px; text-align: right !important;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details @input="getTotCalcl"
                                                v-model="list.wgeTot" type="number" height="28"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </v-col>
                                    <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="5">
                                        {{list.wgeTot?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                    </v-col>
                                </v-row>                                
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-2 tableBL" cols="3">
                                <v-row no-gutters>
                                    <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="7">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:150px; text-align: right !important;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details @input="getTotCalcl"
                                                v-model="list.fcltyChrg" type="number" height="28"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </v-col>
                                    <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="5">
                                        {{list.fcltyChrg?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                    </v-col>
                                </v-row>                                  
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-2 tableBL" cols="3">
                                <v-row no-gutters>
                                    <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="7">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:150px; text-align: right !important;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details @input="getTotCalcl"
                                                v-model="list.rtrmnSvngs" type="number" height="28"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </v-col>
                                    <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="5">
                                        {{list.rtrmnSvngs?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                    </v-col>
                                </v-row> 
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                    </div>
                    <v-row class="greyE01" no-gutters>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">합계</span>
                        </v-col>
                        <v-col class="d-flex justify-end align-center pa-1 ma-0 tableBL" cols="1">
                            <span class="black--text font-weight-medium">{{ empCnt }} 명</span>
                        </v-col>
                        <v-col class="d-flex justify-end align-center pa-1 ma-0 tableBL" cols="3">
                            <span class="black--text font-weight-medium mr-2">{{ wgeTot?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}&nbsp;원</span>
                        </v-col>              
                        <v-col class="d-flex justify-end align-center pa-1 ma-0 tableBL" cols="3">
                            <span class="black--text font-weight-medium" style="margin-right: 44px;">{{ fcltyChrg?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}&nbsp;원</span>
                        </v-col>
                        <v-col class="d-flex justify-end align-center pa-1 ma-0 tableBL" cols="2">
                            <span class="black--text font-weight-medium mr-2">{{ rtrmnSvngs?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}&nbsp;원</span>
                        </v-col>
                    </v-row> 
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">
                            <span class="black--text font-weight-medium">⑥&nbsp;인건비&nbsp;(②+③+④)</span>
                        </v-col>
                        <v-col class="d-flex justify-end align-center ma-0 tableBL" cols="5">
                            <span class="black--text font-weight-medium mr-3">{{ (slrExp)?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}&nbsp;원</span>
                        </v-col>
                    </v-row> 
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">
                            <span class="black--text font-weight-medium">{{ yymm.substr(4, 2) }}월&nbsp;인건비&nbsp;지급비율&nbsp;(⑥&nbsp;÷①&nbsp;×&nbsp;100)</span>
                        </v-col>
                        <v-col class="d-flex justify-end align-center ma-0 tableBL" cols="5">
                            <span v-if="sum != 0" class="black--text font-weight-medium mr-3" >
                                {{ (this.sum.toFixed(1))?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}&nbsp;&#37;
                            </span>
                            <span v-else-if="sum == 0" class="black--text font-weight-medium mr-3" style="color: red !important;">
                                -
                            </span>
                        </v-col>
                    </v-row> 
                    <v-divider class=""></v-divider>
                </v-col>                
            </v-row>
            <div class="d-flex mt-3 align-center">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">{{yyyy?.beforeDateFormatMonthDot()}}&nbsp;인건비&nbsp;지급비율 </span>
                <span class="text-h7">&nbsp;(※&nbsp;금액&nbsp;단위&nbsp;:&nbsp;원,&nbsp;비율&nbsp;단위&nbsp;:&nbsp;&#37;)</span>
            </div>
            <v-divider class="mt-3"></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                    <span class="black--text font-weight-medium">기관&nbsp;연간비율</span>
                </v-col>  
                <v-col class=" d-flex justify-center align-center pa-1 ma-0 tableBL tableBR" cols="3">
                    <span class="black--text">{{ items.lbrexRto }}&#37;</span>
                </v-col>   
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                    <span class="black--text font-weight-medium">공단&nbsp;기준&nbsp;인건비&nbsp;지출비율</span>
                </v-col>              
                <v-col class=" d-flex justify-center align-center pa-1 ma-0 tableBL" cols="3">
                    <span class="black--text">{{ items.nhisLbrexRto }}&#37;</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="12">
                    <span class="black--text font-weight-medium">{{yyyy?.beforeDateFormatMonthDot()}}&nbsp;연간비율</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01" no-gutters>
                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">1월</span>
                </v-col>  
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">2월</span>
                </v-col>   
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">3월</span>
                </v-col>              
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">4월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">5월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">6월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">7월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">8월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">9월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">10월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">11월</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text font-weight-medium">12월</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text">{{ items.lbrexRt01 }}</span>
                </v-col>  
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt02 }}</span>
                </v-col>   
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt03 }}</span>
                </v-col>              
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt04 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text">{{ items.lbrexRt05 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt06 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt07 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt08 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt09 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt10 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt11 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="1">
                    <span class="black--text">{{ items.lbrexRt12 }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        </div>
        <v-dialog eager v-model="dialog[0]" persistent max-width="1400" min-width="1400">
            <LbrexImp ref="lbrexImp" @isClose="isModalType" @reload="reset()"></LbrexImp>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import LbrexImp from '../../components/impExp/LbrexImp.vue';
import { insLbrexRto, insLbrexPym, selLbrexRtoInfo, selLbrexPymList, } from '../../api/impExp.js';
import { getStcd } from '../../api/index.js';
export default {
    name: 'LbrCstExpRtCalc',
    components: {
        expandMonthPicker,
        LbrexImp,
    },
    created: function(){
        getStcd('42')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },
    methods: {
        stCdMapping: function (res) {
            this.lbrCode = res
        },
        onClickBtn: function (text) {
            if(text == '저장'){
                if(this.$refs.form.validate()){
                    this.insLbrexRto()               
                    this.insLbrexPym()      
                }
            }
            else if(text == '안내'){    
                this.dialog.splice(0, 1, true)
            }
            
        },
        insLbrexPym:function(){
            let obj = {
                list:[],
            }
            this.items1.forEach(e => {
                let item = {
                    stdYymm: this.yymm,
                    empCnt: e.empCnt,   
                    fcltyChrg: e.fcltyChrg,
                    jobCd: e.jobCd,
                    rtrmnSvngs: e.rtrmnSvngs,
                    wgeTot: e.wgeTot,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm
                }
                obj.list.push(item)
            });
            insLbrexPym(obj)
                .then((response) => ( this.insLbrexPymAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/insLbrexPym : ' + error))
        },
        insLbrexPymAfter:function(res){
            if(res.statusCode == 200){                                                                
                this.selLbrexRtoInfo(this.yyyy, this.yymm)
                this.selLbrexPymList(this.yymm)
                this.snackControll([true, 2500, res.message, 'info'])
            }
        },
        insLbrexRto: function () {
            if(this.items.bnftSlrExp!=0){
                let obj = {
                    bnftSlrExp: this.items.bnftSlrExp,
                    slrExp: this.slrExp,
                    stdYyyy: this.yyyy,
                    stdYymm: this.yymm,
                    userNm: this.$store.getters.getUserNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                }        
                insLbrexRto(obj)
                    .then((response) => ( this.insLbrexRtoAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/insLbrexRto : ' + error))
            }
        },
        insLbrexRtoAfter:function(res){
            if(res.statusCode == 200){                                                                
                this.selLbrexRtoInfo(this.yyyy, this.yymm)
                this.selLbrexPymList(this.yymm)
                this.snackControll([true, 2500, res.message, 'info'])
            }
        },
        getPickerDate: function (date) {
            this.yyyy = date.substring(0,4);
            this.yymm = date
            this.selLbrexRtoInfo(this.yyyy, this.yymm)
            this.selLbrexPymList(date)
        },
        selLbrexRtoInfo: function (date, yymm) {
            selLbrexRtoInfo(this.$store.getters.getFcltyNum, date, yymm)
                .then((response) => ( this.selLbrexRtoInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selLbrexRtoInfo : ' + error))
        },
        selLbrexRtoInfoAfter: function (res) {
            
            if(res == ""){                
                this.sum = 0
                this.items.bnftSlrExp	 	= 0
                this.items.lbrexRto	 		= 0
                this.items.nhisLbrexRto	 	= 0
                this.items.lbrexRt01	 	= 0
                this.items.lbrexRt02	 	= 0
                this.items.lbrexRt03	 	= 0
                this.items.lbrexRt04	 	= 0
                this.items.lbrexRt05	 	= 0
                this.items.lbrexRt06	 	= 0
                this.items.lbrexRt07	 	= 0
                this.items.lbrexRt08	 	= 0
                this.items.lbrexRt09	 	= 0
                this.items.lbrexRt10	 	= 0
                this.items.lbrexRt11	 	= 0
                this.items.lbrexRt12	 	= 0
            }else if(res != ""){

                this.items.bnftSlrExp	 	= res.bnftSlrExp
                this.items.lbrexRto	 		= res.lbrexRto
                this.items.nhisLbrexRto	 	= res.nhisLbrexRto
                this.items.lbrexRt01	 	= res.lbrexRt01
                this.items.lbrexRt02	 	= res.lbrexRt02
                this.items.lbrexRt03	 	= res.lbrexRt03
                this.items.lbrexRt04	 	= res.lbrexRt04
                this.items.lbrexRt05	 	= res.lbrexRt05
                this.items.lbrexRt06	 	= res.lbrexRt06
                this.items.lbrexRt07	 	= res.lbrexRt07
                this.items.lbrexRt08	 	= res.lbrexRt08
                this.items.lbrexRt09	 	= res.lbrexRt09
                this.items.lbrexRt10	 	= res.lbrexRt10
                this.items.lbrexRt11	 	= res.lbrexRt11
                this.items.lbrexRt12	 	= res.lbrexRt12

                if(this.items.bnftSlrExp!=0){
                    this.sum = (this.wgeTot + this.fcltyChrg + this.rtrmnSvngs)/this.items.bnftSlrExp*100
                }else if(this.items.bnftSlrExp==0){
                    this.sum = 0
                }
            }
            this.$refs.form.resetValidation();
        },
        selLbrexPymList: function (date) {
            selLbrexPymList(this.$store.getters.getFcltyNum, date)
                .then((response) => ( this.selLbrexPymListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selLbrexPymList : ' + error))
        },
        selLbrexPymListAfter: function (res) {
            res.forEach(e => {
                e.empCnt = Number(e.empCnt)
                e.wgeTot = Number(e.wgeTot)
                e.fcltyChrg = Number(e.fcltyChrg)
                e.rtrmnSvngs = Number(e.rtrmnSvngs)
            });
            this.items1 = res
            this.empCntInput()
            this.getTotCalcl()
           
        },
             
        snackControll: function (options) {
            this.snackOptions = options
        },
        empCntInput: function () {
            let empCntVal = 0
            this.items1.forEach(e => {
                e.empCnt = Number(e.empCnt)
                empCntVal += e.empCnt
            });
            this.empCnt = empCntVal
        },
        
        getTotCalcl:function(){
            this.wgeTot = 0
            this.items1.forEach(el => {
                this.wgeTot += Number(el.wgeTot)
            });

            this.fcltyChrg = 0
            this.items1.forEach(el => {
                this.fcltyChrg += Number(el.fcltyChrg)
            });

            this.rtrmnSvngs = 0
            this.items1.forEach(el => {
                this.rtrmnSvngs += Number(el.rtrmnSvngs)
            });
            if(this.items.bnftSlrExp!=0){
                this.sum = (this.wgeTot + this.fcltyChrg + this.rtrmnSvngs)/this.items.bnftSlrExp*100
            }
            this.slrExp = this.wgeTot + this.fcltyChrg + this.rtrmnSvngs
        },
        isModalType: function () {
            this.dialog.splice(0, 1, false)
        },
    },
    
    data: () => ({
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },        
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'grey006', text: '안내', type: 'action', width: '500px', disabled: false, },    
        ],
        snackOptions: [false, 3000, '', 'info'],
        date:'',
        yymm:'',
        yyyy:'',
        items:{
            bnftSlrExp	: 0,
            lbrexRto	: 0,
            nhisLbrexRto	: 0,
            lbrexRt01	: 0,
            lbrexRt02	: 0,
            lbrexRt03	: 0,
            lbrexRt04	: 0,
            lbrexRt05	: 0,
            lbrexRt06	: 0,
            lbrexRt07	: 0,
            lbrexRt08	: 0,
            lbrexRt09	: 0,
            lbrexRt10	: 0,
            lbrexRt11	: 0,
            lbrexRt12	: 0,
        },
        items1:[  
        ],
        dateRatio:{
            jan: '', feb: '', mar: '', apr: '', may: '', jun: '', aug: '', sep: '', oct: '', nov: '', dec: '', 
        },
        empCnt:0,
        wgeTot:0,
        fcltyChrg:0,
        rtrmnSvngs:0,
        slrExp:0,
        lbrCode: [],
        paymtsTotAmt:0,
        sum:0,
        dialog: [false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    })
};
</script>