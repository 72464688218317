<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+150" :max-width="areaWidth+150">        
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate" :lmtNxtDt="lmtNxtDt" :key="forceRender"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="30"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i+'l'">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
                <span v-for="(list, j) in outlinedBtn" :key="j+'r'">
                    <v-btn :disabled="list.disabled" @click="onClickPrtBtn(list.text)"
                        :class="list.class" samll height="30" :color="list.color" rounded outlined>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </span>
        </div>
        <v-divider class="my-2"></v-divider>
        <div style="position:relative;">           
            <div v-if="recPsbYn != '1'" style="position:absolute;top:0px;left:0px;width:100%;height:100%;z-index:7;">
                <div style="width:100%;height:100%;background-color:black;opacity: 0.2;"></div>
                <v-icon size="70" style="position:absolute;top:36%;left:42%;">mdi-pencil-off-outline</v-icon>
                <div v-if="recPsbYn == '3' && bnMmbrPk > 0" style="position:absolute;top:35%;left:50%;">
                    <span class="font-weight-bold" style="font-size:4rem;color:rgba(0, 0, 0, 0.54)">외박중</span>
                </div>
                <div v-if="recPsbYn == '2' && bnMmbrPk > 0" style="position:absolute;top:35%;left:50%;">
                    <span class="font-weight-bold" style="font-size:4rem;color:rgba(0, 0, 0, 0.54)">퇴소중</span>
                </div>
            </div>
            
            <v-tabs
                v-model="tab" class="tab-dft-Theme mt-2" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">            
                <v-tab-item eager transition="fade-transition">
                    <tab1 
                        ref="tab1"
                        @nsgCareRcrdTrigger="onTrigger" 
                        @setDate="setSltDate"
                        @eventBtn="onBtn"></tab1>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <tab2  
                        ref="tab2"   
                        @setDate="setSltDate"
                        @nsgCareRcrdTrigger="onTrigger"></tab2>
                </v-tab-item>     
                <v-tab-item eager transition="fade-transition">
                    <tab3  
                        ref="tab3"
                        @setDate="setSltDate"
                        @onMsgTrigger="onMsgTrigger"
                        @nsgCareRcrdTrigger="onTrigger"></tab3>
                </v-tab-item>       
                <v-tab-item eager transition="fade-transition">
                    <tab4  
                        ref="tab4"
                        @setDate="setSltDate"
                        @onMsgTrigger="onMsgTrigger"
                        @nsgCareRcrdTrigger="onTrigger"></tab4>
                </v-tab-item>       
            </v-tabs-items>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="1400" min-width="1400">                
                <ChgPosObsrvRcrd 
                    v-if="dialog[0]"
                    ref="chgPosObsrvRcrd"
                    :chgPosObsrvRcrdPk="chgPosObsrvRcrdPk"
                    :inqYMD="inqYMD"
                    :bnMmbrPk="$parent.$parent.sltBnMmBrPk"
                    @modalEvent="modalEvent"></ChgPosObsrvRcrd>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import tab1 from '../../components/bnftrcrd/NsgCareRcrdTab1.vue';               //욕창관리
import tab2 from '../../components/bnftrcrd/NsgCareRcrdTab2.vue';               //경관영양
import tab3 from '../../components/bnftrcrd/NsgCareRcrdTab3.vue';               //배설관리
import tab4 from '../../components/bnftrcrd/NsgCareRcrdTab4.vue';               //도뇨관관리
import ChgPosObsrvRcrd from '../../components/bnftrcrd/ChgPosObsrvRcrd.vue';    //체위변경

import { selFcltyInitList  } from '../../api/opr.js';
import { selBnfcrSttChkInfo  } from '../../api/bnfcr.js';

export default {
    name: 'NsgCareRcrd',
    
    components: {
        expandNowDatePicker,
        tab1,
        tab2,
        tab3,
        tab4,
        ChgPosObsrvRcrd,
    },

    props : {
        
    },

    created: function(){       
        this.lmtNxtDt = this.$moment().format('YYYY-MM-DD')
        this.getFcltyInitList()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk
            this.onBtn()
            this.getBnfcrSttChkInfo()     
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.outlinedBtn[0].disabled = true
            this.bnMmbrPk = value  
            this.onBtn()
            this.getBnfcrSttChkInfo()
        },
        'inqYMD':function(value){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            if(value != ''){
                this.$parent.$parent.getAllNsgCareList(value, entStcd)
                if(this.bnMmbrPk > 0) this.getBnfcrSttChkInfo()
            }
        },
        'tab':function(){
            this.outlinedBtn[0].disabled = true
            
            this.onBtn()

            if(this.bnMmbrPk > 0) 
                this.getBnfcrSttChkInfo()            
        },
        getSubEvt: function (bol) {
            if(bol && this.nsgCareCnfm){
                this.onIgnrSave()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.nsgCareCnfm = false
            }
        },

        getSubActv:function (bol){
            if(!bol && this.nsgCareCnfm){
                this.onLoad()
                this.nsgCareCnfm = false
            }
        }
    },

    methods: {
        getDate: function (value) {
            this.inqYMD = value.date
        },
        setSltDate:function(value){            
            this.sltDate=value
            this.forceRender++
        },        
        onTrigger:function(res){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            let obj = {}

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
                this.$parent.$parent.getAllNsgCareList(this.inqYMD, entStcd)
            }else if(res.statusCode == 205){

                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = res.message
                obj.cntnt = []

                if(res.messagesList != null && res.messagesList != undefined){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                obj.cntnt.push('등록된 기록을 삭제하고 저장하시겠습니까?')
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 저장하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.nsgCareCnfm = true

            } else if(res.statusCode == 300){
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            } else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }

            this.progress = false
        },
        onMsgTrigger:function(type, message){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = message         // 경고 타이틀
            obj.tmr = 3000      
            obj.type = type            // 경고 유형 (success, info, warning, error)
            this.$store.commit('setWrnnInf', obj)            
        },
        onLoad(){            
            switch (this.tab) {
                case 0:
                    this.$refs.tab1.onLoad()
                    break                 
                case 1:
                    this.$refs.tab2.onLoad()
                    break 
                case 2:
                    this.$refs.tab3.onLoad()
                    break                 
                case 3:
                    this.$refs.tab4.onLoad()
                    break                 
                default:
                    break
            }
        },
        onIgnrSave:function(){
            switch (this.tab) {
                    case 0:
                        this.$refs.tab1.onSave('1')
                        break
                    case 1:
                        this.$refs.tab2.onSave('1')
                        break                   
                    case 2:
                        this.$refs.tab3.onSave('1')
                        break
                    case 3:
                        this.$refs.tab4.onSave('1')
                        break              
                    default:
                        break
                }
        },
        onClickBtn:function(text){
                  
            if(text == "저장") {                
                switch (this.tab) {
                    case 0:
                        this.$refs.tab1.onSave()
                        break
                    case 1:
                        this.$refs.tab2.onSave()
                        break
                    case 2:
                        this.$refs.tab3.onSave()
                        break
                    case 3:
                        this.$refs.tab4.onSave()
                        break
                    default:
                        break
                }
            }else if(text == "삭제"){               
                switch (this.tab) {
                    case 0:
                        this.$refs.tab1.delAll()
                        break
                    case 1:
                        this.$refs.tab2.delAll()
                        break
                    case 2:
                        this.$refs.tab3.delAll()
                        break
                    case 3:
                        this.$refs.tab4.delAll()
                        break
                    default:
                        break
                }
            }else if(text == "체위변경기록"){
                this.showDialog()
            }
        },
        onClickPrtBtn:function(text){
            if(text == "간호처치기록 출력") {
                this.prtPyrprRcrd()
            }
        },
        onBtn:function(){
            if(this.bnMmbrPk > 0){
                if(this.tab == 0){
                    let subTab = this.$refs.tab1.subTab
                    if(subTab == 1){
                        this.filledBtn[0].disabled = true
                        this.filledBtn[1].disabled = true
                        this.filledBtn[2].disabled = false
                    }else{
                        this.filledBtn[0].disabled = false
                        this.filledBtn[1].disabled = false
                        this.filledBtn[2].disabled = true
                    }
                }else if(this.tab == 1){
                    this.filledBtn[0].disabled = false
                    this.filledBtn[1].disabled = false
                    this.filledBtn[2].disabled = true
                }else{
                    this.filledBtn[0].disabled = false
                    this.filledBtn[1].disabled = false
                    this.filledBtn[2].disabled = true
                }             
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
            }
        },
        getBnfcrSttChkInfo:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum, 
                bnMmbrPk    : this.bnMmbrPk,
                inqYMD      : this.inqYMD, 
            }
            selBnfcrSttChkInfo(obj)
                .then((response) => ( this.getBnfcrSttChkInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBnfcrSttChkInfo : ' + error))
        },
        getBnfcrSttChkInfoAfter:function(res){
            if(res.statusCode == 200){
                if(res.result != null) {
                    this.recPsbYn = res.result.recPsbYn
                    if(this.recPsbYn == '1') {
                        this.filledBtn[1].disabled = false
                    }else{
                        this.filledBtn[1].disabled = true
                    }
                }else{
                    this.recPsbYn = '2'
                    this.filledBtn[1].disabled = true
                }
                this.onLoad()
            } else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)                
            }
        },
        showDialog:function(){
            this.chgPosObsrvRcrdPk = 1
            this.dialog.splice(0, 1, true)
        },
        modalEvent: function (event) { 
            if(event == 99){
                if(this.tab == 0){
                    this.$refs.tab1.onLoad();
                }
            }else{
                this.chgPosObsrvRcrdPk = -1
                this.dialog.splice(0, 1, false)
            }      
        },
        getFcltyInitList:function(){           
            selFcltyInitList(this.$store.getters.getFcltyNum, ['4'])
                .then((response) => ( this.getFcltyInitListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selFcltyInitList : ' + error))
        },
        getFcltyInitListAfter:function(res){
            let tday = this.$moment()
            let tommday = this.$moment().add(1, 'days')
            res.forEach(el => {
                if(el.itmVal1 == '1') this.lmtNxtDt = ''
                else {
                    let day     = this.$moment(tday.format('YYYYMMDD')+' '+el.itmVal2,'YYYYMMDD hh:mm')                    
                    let yn = this.$moment.duration(tday.diff(day)).asMinutes()                       
                    this.lmtNxtDt = yn > 0 ? tommday.format('YYYY-MM-DD') : tday.format('YYYY-MM-DD')
                }
            });
        },
        prtPyrprRcrd: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYmdStart = this.inqYMD
            this.prtThrowObj.inqYmdEnd = this.inqYMD
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.prtThrowObj.prtCd = '53'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
    },
    data: () => ({
        nsgCareCnfm:false,
        forceRender:0,
        bnMmbrPk:0,
        tab:0,
        areaWidth: 708,
        inqYMD:'',
        sltDate: '',
        chgPosObsrvRcrdPk:-1,        
        btnDialog: [false, false, false, false, false, false, false, false, false, false ],
        dialog:[false,false],
        filledBtn: [                 
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '체위변경기록', disabled: true, },
        ],
        outlinedBtn: [      
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '간호처치기록 출력',  disabled: true, },
        ],
        tabItems: [
            { index: 0, tab: '욕창간호', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '경관영양', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 2, tab: '배설관리', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 3, tab: '도뇨관관리', class: 'fontOneDotTwoRem px-15', enter:false},
        ],         
        lmtNxtDt:'',
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '53',
            inqYmdStart: '', inqYmdEnd: '', inqYMD: '', inqYYYY: '', rptTyp: '1', mode: '2',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
        recPsbYn:'1',
        progress:false
    }),
};
</script>