<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">야간 점검 구역 관리</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="$emit('isClose', '.')">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters>
            <div class="mr-3 d-flex align-center">
                <v-icon color="red" size="18">mdi-alert-circle-outline</v-icon>
                <span class="pl-1">야간점검 횟수 3회이상,</span>
                <span class="pl-1 red--text">야간점검기록이 있는건은 삭제 불가합니다.</span>
            </div>
        </v-row>
        <v-divider :class="[inspAreaList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', inspAreaList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">연번</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="5">
                <span class="fontOneDotOneRem font-weight-bold">점검구역</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <v-btn
                    width="20" height="20"
                    @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 43)"
                    icon>
                    <v-icon size="20">mdi-help-circle-outline</v-icon>
                </v-btn>
                <span class="fontOneDotOneRem font-weight-bold">일일 점검횟수</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">사용여부</span>
            </v-col>
            <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="2">
                <v-btn class="" color="blue200" height="26" @click="addRow()" icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider :class="[inspAreaList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="inspAreaList.length > 0" class="overflow-y-auto" style="width: 100%; height: 385px;">
            <v-row
                class="tableBB"
                v-for="(itm, i) in inspAreaList" :key="i"
                no-gutters>               

                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                    <span class="fontOneRem">{{ i+1 }}</span>
                </v-col>
                <v-col class="py-1 px-2 tableBR" style="padding-top: 6px;" cols="5">                    
                    <v-text-field
                        v-model="itm.inspAreaNm"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1 px-2" style="padding-top: 6px;" cols="2">
                    <v-select
                        v-model="itm.inspCnt"
                        :key="forceRender"
                        :items="inspCntItm"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>   
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1 px-2" style="padding-top: 6px;" cols="2">                                        
                    <v-select
                        v-model="itm.useYn"
                        :key="forceRender"
                        :items="useYnItm"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>   
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 6px;" cols="2">                    

                    <v-btn
                        class="nmlBfClrImp-white"
                        color="blueBtnColor" min-width="26" height="26"
                        @click="preInspArea(itm)"
                        small icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="24">mdi-content-save-outline</v-icon>
                            </template>
                            <span>저장</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn
                        class="nmlBfClrImp-white pl-3"
                        color="grey006" min-width="26" height="26"
                        @click="rmRow(i)"
                        small icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-if="itm.inspAreaPk > 0" v-bind="attrs" v-on="on" size="24">mdi-trash-can-outline</v-icon>
                                <v-icon v-else v-bind="attrs" v-on="on" size="24">mdi-minus-circle-outline</v-icon>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 구역정보가 없습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>

import { http } from '@/api/baseAxios';

export default {
    name: 'InspAreaMdl',
    
    components: {
    },

    props : { 
       
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getInspAreaList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmAreaCnFm){

                let obj = {
                    inspAreaPk : this.sltPk
                }

                this.delInspArea(obj)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmAreaCnFm = false
            }
        },
    },
        
    methods: {
        
        preInspArea:function(itm){
            if(this.isAreaRowValid(itm)){                
                this.insInspArea(itm)
            }
        },

        // 점검구역 내역
        getInspAreaList: function (){    
            this.inspAreaList.splice(0)

            let obj = {                
            }

            http.post('sfty/selInspAreaList', obj)
                .then((response) => ( this.inspAreaListRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspAreaList : ' + error))
        },

        //점검구역 저장
        insInspArea: function (obj){    
            http.post('sfty/insInspArea', obj)
                .then((response) => ( this.insInspAreaRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspArea : ' + error))
        },

        //점검구역 삭제
        delInspArea: function (obj){    
            http.post('sfty/delInspArea', obj)
                .then((response) => ( this.delInspAreaRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspArea : ' + error))
        },

        inspAreaListRst : function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {                        
                        this.inspAreaList.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            ++this.forceRender
        },

        insInspAreaRst:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀   

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getInspAreaList()                             
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)   
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)             
            }            
            this.$store.commit('setWrnnInf', obj)
        },

        delInspAreaRst:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀   

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getInspAreaList()                    
                this.sltPk = -1         
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)   
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)             
            }            
            this.$store.commit('setWrnnInf', obj)
        },

        

        //점검구역 ROW ADD
        addRow:function(){

            let itm = {
                inspAreaPk  : 0,
                inspAreaNm  : '',
                inspCnt     : 3,
                useYn       : '1',
            }

            this.inspAreaList.push(itm)

            ++this.forceRender
        },

        //점검구역 ROW DELETE
        rmRow:function(idx){
            if(this.inspAreaList[idx].inspAreaPk > 0){
                this.sltPk = this.inspAreaList[idx].inspAreaPk
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '야간점검구역'
                obj.cntnt = [this.inspAreaList[idx].inspAreaNm+'을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmAreaCnFm = true
            } else {
                this.inspAreaList.splice(idx, 1)
            }
        },

        isAreaRowValid:function(itm){
            let resrt = true

            if(itm.inspAreaNm == '' || itm.inspAreaNm == null) {
                this.onAlrt('점검구역명을 입력해주세요.')
                resrt = false
            }
            
            if(resrt && (itm.inspCnt < 3 || itm.inspCnt == null)) {
                this.onAlrt('점검횟수를 선택해주세요.')
                resrt = false
            }

            if(resrt && (itm.useYn == '' || itm.useYn == null)) {
                this.onAlrt('사용여부를 선택해주세요.')
                resrt = false
            }

            return resrt
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
    },

    data: () => ({
        rmAreaCnFm      : false,
        sltPk           : -1,
        forceRender     : 0,
        inspAreaList    : [],
        inspCntItm      : [
            {valcdnm:'선택', valcd:0},
            {valcdnm:'3회', valcd:3},
            {valcdnm:'4회', valcd:4},
            {valcdnm:'5회', valcd:5},
            {valcdnm:'6회', valcd:6},
            {valcdnm:'7회', valcd:7},
            {valcdnm:'8회', valcd:8},
            {valcdnm:'9회', valcd:9}
        ],
        useYnItm      : [
            {valcdnm:'선택', valcd:''},
            {valcdnm:'사용', valcd:'1'},
            {valcdnm:'미사용', valcd:'2'},
        ]
    }),
};
</script>