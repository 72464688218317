<template>    
    <v-sheet class="rounded-lg rounded-l-0 allbnftsrvprcs" min-width="1260" max-width="1560" height="835" color="">
        <v-divider class="mt-3 white"></v-divider>
        <v-row class="pl-4 py-0" no-gutters>
            <span class="hamletFont text-h5 topTitleColor--text font-weight-black">2-1. 전체&nbsp;급여제공과정</span>
        </v-row>
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <v-spacer></v-spacer>
            <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            <span v-if="year > 2024" class="d-inline-block pl-2" style="width: 100px;">
                <v-select
                    v-model="half" :items="inqItem"
                    item-text="valcdnm" item-value="valcd"
                    dense hide-details outlined>
                </v-select>
            </span>
            <v-spacer></v-spacer>
        </v-row>
        <v-divider class="mt-1 white"></v-divider>
        <AllBnftOfrMdl v-if="year > 2024" :inqYYYY="year" :inqHalf="half" ref="allBnftOfr"></AllBnftOfrMdl>           
        <AllBnftOfrOldMdl v-else :inqYYYY="year" ref="allBnftOfr"></AllBnftOfrOldMdl> 

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <bizMsgMdl
                v-if="lclMdlInf.typ == '01'"
                ref="bizMsgMdl" @madalState="onSendClose"           
                :bizMsgData="bizMsgData"
                :bizMsgDocClcd="4"
                :bizMsgBzPk="bizMsgBzPk"
                :bizMsgShow="bizMsg"
                :bizMsgTitle="'급여제공계획'"
                :bizMsgCntnt="$store.getters.getBnfcrState.name">
            </bizMsgMdl>
            <CnslnJrnlsMdl
                v-else-if="lclMdlInf.typ == '02'"
                @isClose="rstLclMdlInf()">
            </CnslnJrnlsMdl>
            <DsrEvltnComp 
                v-else-if="lclMdlInf.typ == '11'"
                ref="dsrEvltn" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :bsrEvltnPk="bsrEvltnPk" :inqYYYY="year">
            </DsrEvltnComp>
            <FallEvltnComp 
                v-else-if="lclMdlInf.typ == '12'"
                ref="fallEvltn" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :fallEvltnPk="fallEvltnPk" :inqYYYY="year">
            </FallEvltnComp>
            <BdsrEvltnComp 
                v-else-if="lclMdlInf.typ == '13'"
                ref="bdsrEvltn" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :bdsrEvltnPk="bdsrEvltnPk" :inqYYYY="year">
            </BdsrEvltnComp>
            <CgntvEvltnComp 
                v-else-if="lclMdlInf.typ == '14'"
                ref="cgntvEvltn" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :cgntvEvltnPk ="cgntvEvltnPk" :inqYYYY="year">
            </CgntvEvltnComp>
            <HlthEvltnComp 
                v-else-if="lclMdlInf.typ == '15'"
                ref="hlthEvltn"  @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :hlthEvltnPk ="hlthEvltnPk" :inqYYYY="year">
            </HlthEvltnComp>
            <PhyscThrpyEvltnComp 
                v-else-if="lclMdlInf.typ == '16'"
                ref="physcThrpyEvltn" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :physcThrpyEvltnPk="physcThrpyEvltnPk" :inqYYYY="year">
            </PhyscThrpyEvltnComp>
            <PhyscThrpyPlnComp 
                v-else-if="lclMdlInf.typ == '17'"
                ref="physcThrpyPln" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :bnfcrPk="bnfcrPk" :physcThrpyPlnPk="physcThrpyPlnPk" :inqYYYY="year">
            </PhyscThrpyPlnComp>
            <BnftSrvPlnComp
                v-else-if="lclMdlInf.typ == '18'"
                ref="bnftSrvPln" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :bnfcrPk="bnfcrPk" :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                :inqYYYY="year" :btnDisabled="false">
            </BnftSrvPlnComp>
            <BnftSrvPlnMdfComp 
                v-else-if="lclMdlInf.typ == '19'"
                ref="bnftSrvPlnMdf"
                :bnMmbrPk="bnMmbrPk" :bnftSrvPlnPk="bnftSrvPlnPk" :inqYYYY="year"
                @isClose="modalEvent(8)" @modalEvent="modalEvent">
            </BnftSrvPlnMdfComp>
            <BnftSrvRsltEvltnComp 
                v-else-if="lclMdlInf.typ == '20'"
                ref="bnftSrvRsltEvltn" @modalEvent="modalEvent"
                :bnMmbrPk="bnMmbrPk" :bnfcrPk="bnfcrPk" :bnftSrvRsltEvltnPk="bnftSrvRsltEvltnPk" :inqYYYY="year">
            </BnftSrvRsltEvltnComp>
            <DsrEvltnAvrInd
                v-else-if="lclMdlInf.typ == '21'"
                @isClose="offDsrEvltnMdl">
            </DsrEvltnAvrInd>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import CnslnJrnlsMdl from '../../components/bnfcr/CnslnJrnlsMdl.vue';               // 상담일지

import DsrEvltnComp from '../../components/bnftSrv/DsrEvltn.vue';                   // 욕구사정
import FallEvltnComp from '../../components/bnftSrv/FallEvltn.vue';                 // 낙상위험도
import BdsrEvltnComp from '../../components/bnftSrv/BdsrEvltn.vue';                 // 욕창위험도
import CgntvEvltnComp from '../../components/bnftSrv/CgntvEvltn.vue';               // 인지기능
import HlthEvltnComp from '../../components/bnftSrv/HlthEvltn.vue';                 // 건강수준
import PhyscThrpyEvltnComp from '../../components/bnftSrv/PhyscThrpyEvltn.vue';     // 물리치료평가
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue';         // 물리치료계획
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue';               // 급여제공계획
import BnftSrvPlnMdfComp from '../../components/bnftSrv/BnftSrvPlnMdf.vue';         // 급여제공계획변경
import BnftSrvRsltEvltnComp from '../../components/bnftSrv/BnftSrvRsltEvltn.vue';   // 급여제공결과평가
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';                     // 발송관리

import DsrEvltnAvrInd from '../../components/bnftSrv/DsrEvltnAvrInd.vue';           // 욕구사정평가 (2025~)

import AllBnftOfrMdl from '../../components/bnftSrv/AllBnftOfrMdl.vue';             // 전체급여제공과정목록(2025년이후)
import AllBnftOfrOldMdl from '../../components/bnftSrv/AllBnftOfrOldMdl.vue';       // 전체급여제공과정목록(2025년이전)


export default {
    name: 'AllBnftSrvPrcs',
        
    components: {
        expandYearOnlyPicker,
        CnslnJrnlsMdl,
        DsrEvltnComp,
        FallEvltnComp,
        BdsrEvltnComp,
        CgntvEvltnComp,
        HlthEvltnComp,
        PhyscThrpyEvltnComp,
        PhyscThrpyPlnComp,
        BnftSrvPlnComp,
        BnftSrvPlnMdfComp,
        BnftSrvRsltEvltnComp,
        bizMsgMdl,
        DsrEvltnAvrInd,
        AllBnftOfrMdl,
        AllBnftOfrOldMdl,
    },

    created: function(){
        
    },

    mounted: function(){
       
    },

    watch:{        
    },
        
    methods: {               
        getYear: function (yyyy){
            this.year = yyyy
        },
        onModal: function (bnmmbrPk, q){
            this.sltBnMmbrPk = Number(bnmmbrPk)
            this.qtr = q

            let obj = {}
            obj.bnMmbrPk = Number(bnmmbrPk)
            // obj.bnfcrPk
            // obj.name
            // obj.age
            // obj.gndr
            // obj.entStcdNm
            // obj.entDtDot
            // obj.lvDtDot
            // obj.qtrToYear
            // obj.vCd104
            obj.mode = 'all'

            this.$store.commit('setTmpObj', obj)
            this.onLclMdl('02', 1250)
        },
        modalEvent: function (event) {
            if(event == 99)
                this.$refs.allBnftOfr.getAllBnftOfrList()
            else{
                switch (event) {
                    case 0:
                        this.bsrEvltnPk = -1
                        break;
                    case 1:
                        this.fallEvltnPk = -1
                        break;
                    case 2:
                        this.bdsrEvltnPk = -1
                        break;
                    case 3:
                        this.cgntvEvltnPk = -1
                        break;    
                    case 4:
                        this.hlthEvltnPk = -1
                        break;    
                    case 5:
                        this.physcThrpyEvltnPk = -1
                        break; 
                    case 6:
                        this.physcThrpyPlnPk = -1
                        break;  
                    case 7:
                        this.bnftSrvPlnCmpPk = -1
                        break;      
                    case 8:
                        this.bnftSrvPlnPk = -1
                        break;
                    case 9:
                        this.bnftSrvRsltEvltnPk = -1
                        break;
                    default:
                        break;
                }

                this.rstLclMdlInf()
            }      
        },
        outOnSrvPln: function(){
            this.bnftSrvPlnCmpPk = 0
            this.onLclMdl('18', 1600)
        },
        showDialog(tabkey, item, examPk, bnfcr = null){
            this.bnfcrPk  = (bnfcr != null) ? bnfcr : item.bnfcrPk
            this.bnMmbrPk = item.bnMmbrPk

            if(examPk == null || examPk == '') examPk = 0

            this.$store.commit('setBnfcrState', {
                bnmmbr: item.bnMmbrPk,
                bnfcr: (bnfcr != null) ? bnfcr : item.bnfcrPk,
                name: item.name,
                entStcd: '',
                entDt: '',
                lvDt: ''
            });

            let mdlWdt = 1400
            if(tabkey == 7) tabkey= '18'
            switch (tabkey) {
                case '11':
                    this.bsrEvltnPk = examPk
                    break;
                case '12':
                    this.fallEvltnPk = examPk
                    break;
                case '13':
                    this.bdsrEvltnPk = examPk
                    break;
                case '14':
                    this.cgntvEvltnPk = examPk
                    break;
                case '15':
                    this.hlthEvltnPk = examPk
                    break;
                case '16':
                    this.physcThrpyEvltnPk = examPk
                    break;
                case '17':
                    this.physcThrpyPlnPk = examPk
                    mdlWdt = 1470  
                    break;
                case '18':
                    this.bnftSrvPlnCmpPk = examPk
                    mdlWdt = 1600
                    break;
                case '19':
                    this.bnftSrvPlnPk = examPk
                    break;
                case '20':
                    this.bnftSrvRsltEvltnPk = examPk
                    mdlWdt = 1500
                    break;
                default:
                    break;
            }

            this.onLclMdl(tabkey, mdlWdt)
        },
        showBizMsg:function(item, selPk){
            if(selPk > 0){
                this.$store.commit('setBnfcrState', {
                    bnmmbr: item.bnMmbrPk,
                    bnfcr: item.bnfcrPk,
                    name: item.name,
                    entStcd: '',
                    entDt: '',
                    lvDt: ''
                });
                this.bizMsgData.push(item.bnMmbrPk)
                this.bizMsgBzPk = selPk
                this.onLclMdl('01', 1100)
            }else{
                
                let obj = {}
                obj.actv = true                         // 경고 노출여부
                obj.tmr = 5000                          // 경고 노출시간(-1: 무제한)
                obj.type = 'error'                      // 경고 유형 (success, info, warning, error)
                obj.title = '급여제공계획을 작성해주세요.'  // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },        
        onSendClose:function(){
            this.bizMsgBzPk = -1

            this.rstLclMdlInf()
        },
        // 욕구사정평가 모달 (2024년 이후)
        onDsrEvltnMdl: function (obj) {
            let oObj = {}
            oObj.name = obj.name
            oObj.gndr = obj.gndr
            oObj.age = obj.age
            oObj.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
            oObj.majorDsss = obj.majorDsss
            if(obj.bnfcrExamHisPk101 == null)
                oObj.bsrEvltnPk = 0
            else
                oObj.bsrEvltnPk = obj.bnfcrExamHisPk101
            oObj.isUpd = true

            this.$store.commit('setTmpObj', oObj)
            this.onLclMdl('21', 1400)
        },
        offDsrEvltnMdl: function () {
            this.$refs.allBnftOfr.getAllBnftOfrList()
            this.$store.commit('setTmpObj', {})

            this.rstLclMdlInf()
        },
        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },
    
    data: () => ({
        bnMmbrPk:0,
        bnfcrPk:0,
        bsrEvltnPk          :-1,            //욕구사정
        fallEvltnPk         :-1,            //낙상위험도
        bdsrEvltnPk         :-1,            //욕창위험도
        cgntvEvltnPk        :-1,            //인지기능
        hlthEvltnPk         :-1,            //건강수준
        physcThrpyEvltnPk   :-1,            //물리치료평가
        physcThrpyPlnPk     :-1,            //물리치료계획
        bnftSrvPlnCmpPk     :-1,            //급여제공계획
        bnftSrvPlnPk        :-1,            //급여제공계획변경
        bnftSrvRsltEvltnPk  :-1,            //급여제공결과평가
        half                :'1',           //1:상반기, 2:하반기
        inqItem: [ { valcd: '1', valcdnm: '상반기' }, { valcd: '2', valcdnm: '하반기' } ],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어
        bizMsgBzPk  : -1,
        bizMsg      : false,
        bizMsgData  : [],
        sltBnMmbrPk : 0,
        year        : 1990,
        qtr         : 0,
    }),
};
</script>