<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">신규계약 등록</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="offMdl()" x-large>mdi-close</v-icon>
        </v-row>
        <v-form
            class="overflow-y-auto mt-4 pb-9" style="width: 100%; height: 720px;"
            ref="cntrcInf"
            lazy-validation>
            <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">계약정보</span>
                <v-spacer></v-spacer>
                <span class="fontOneRem grey006--text mr-2">※ 계약시작일을 입력하면 본인부담률, 인정등급, 보조금이 조회됩니다.</span>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        계약일
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="3">
                    <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                        <CmmDateComp
                            v-model="cntrcInpInf.cntrcDt" :minDt="entDt" :required="true" @input="aplctSavBtn">
                        </CmmDateComp>
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        계약기간
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="px-2 py-1" cols="5">
                    <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                        <CmmDateComp
                            v-model="cntrcInpInf.cntrcPrdStrt"
                            :minDt="entDt" :required="true"
                            @input="aplctSavBtn">
                        </CmmDateComp>
                    </span>
                    <span class="mx-1">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                        <CmmDateComp
                            v-model="cntrcInpInf.cntrcPrdEnd"
                            :minDt="cntrcInpInf.cntrcPrdStrt" :required="true"
                            @input="aplctSavBtn">
                        </CmmDateComp>
                    </span>
                    <span class="d-inline-block ml-2" style="width: 70px;">
                        <v-select
                            v-model="sltPrd"
                            :items="prtYearPeriod" @change="prdCalc"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">납부방법</span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="3">
                    <span class="d-inline-block" style="width: 101px;">
                        <v-select
                            v-model="cntrcInpInf.pymntMthdCd"
                            :items="pymntMthd" :disabled="true"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        납부일
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="d-flex align-center px-3 py-1" cols="3">
                    <span class="d-inline-block" style="width: 52px;">매월</span>
                    <span class="d-inline-block" style="width: 66px;">
                        <v-text-field
                            v-model="cntrcInpInf.pymntDt" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="ml-1">일</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-divider class="mr-1 mt-2"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">본인부담률</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span>{{ cpyGvrmn.copayRateNm }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">인정등급</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span>{{ cpyGvrmn.acknwRtngNm }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">보조금</span>
                </v-col>
                <v-col class="txtAlgnMid py-1" cols="2">
                    <span v-if="cpyGvrmn.snackSprtYn == '1'">간식비</span>
                    <span v-if="cpyGvrmn.snackSprtYn == '1' && cpyGvrmn.mealSprtYn == '1'">, </span>
                    <span v-if="cpyGvrmn.mealSprtYn == '1'">식사재료비</span>
                    <span v-if="cpyGvrmn.snackSprtYn != '1' && cpyGvrmn.mealSprtYn != '1'">해당없음</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <div
                class="mt-4 mr-1"
                v-if="cntrcMsg.onMdl">
                <div
                    v-if="cntrcMsg.acknwRtngMsg"
                    class="brdSurrd px-6 py-2"
                    style="background-color: #f4f4f4 !important;">
                    <p class="fontOneDotOneRem red--text mb-1">
                        ※ {{ cntrcMsg.msgDt }} 날짜에 해당하는 인정등급 정보가 없습니다.
                    </p>
                    <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                        [1-1. 수급자 정보관리] 카테고리 - 인정등급 등록/수정 팝업에서 인정등급을 작성하시기 바랍니다.
                    </p>
                </div>
                <div
                    v-if="cntrcMsg.copayRateMsg"
                    class="brdSurrd px-6 py-2"
                    style="background-color: #f4f4f4 !important;">
                    <p class="fontOneDotOneRem red--text mb-1">
                        ※ {{ cntrcMsg.msgDt }} 날짜에 해당하는 본인부담률 정보가 없습니다.
                    </p>
                    <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                        [1-1. 수급자 정보관리] 카테고리 - 본인부담률 등록/수정 팝업에서 본인부담률을 작성하시기 바랍니다.
                    </p>
                </div>
                <div
                    v-if="cntrcMsg.bnftFeeMsg"
                    class="brdSurrd px-6 py-2"
                    style="background-color: #f4f4f4 !important;">
                    <p class="fontOneDotOneRem red--text mb-1">
                        ※ {{ cntrcMsg.msgDt }} 날짜에 해당하는 공단급여 수가 정보가 없습니다.
                    </p>
                    <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                        [6-5. 급여수가정보] 카테고리 - 공단급여 수가 데이터를 확인하세요.
                    </p>
                </div>
                <div
                    v-if="cntrcMsg.nBnftFeeMsg"
                    class="brdSurrd px-6 py-2"
                    style="background-color: #f4f4f4 !important;">
                    <p class="fontOneDotOneRem red--text mb-1">
                        ※ {{ cntrcMsg.msgDt }} 날짜에 해당하는 비급여 수가 정보가 없습니다.
                    </p>
                    <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                        [6-5. 급여수가정보] 카테고리 - 비급여 수가 정보를 작성하시기 바랍니다.
                    </p>
                </div>
            </div>
            <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">보호자 정보</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="white--text mr-2" style="padding: 9px 7px 8px 5px !important;"
                    color="blueBtnColor" @click="getGalFmlyInf()"
                    :disabled="bnfcrPk == -1"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">보호자 정보 조회</span>
                </v-btn>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        보호자명
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="2">
                    <v-text-field
                        v-model="fmlyInpInf.name" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 pl-5 tableBR" style="padding-top: 6px;" cols="1">
                    <span class="font-weight-medium posn-rltv">
                        관계
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="1">
                    <v-text-field
                        v-model="fmlyInpInf.rltns" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">전화번호</span>
                </v-col>
                <v-col class="px-2 py-1" cols="4">
                    <span class="d-inline-block" style="width: 50px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.tel1" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.tel2" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.tel3" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        생년월일
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="4">
                    <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                        <CmmDateComp
                            v-model="fmlyInpInf.rsdntBirth" :required="true" @input="aplctSavBtn">
                        </CmmDateComp>
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">휴대폰</span>
                </v-col>
                <v-col class="px-2 py-1" cols="4">
                    <span class="d-inline-block" style="width: 50px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.celphn1" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.celphn2" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.celphn3" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        주소
                        <span class="d-inline-block" style="position: absolute; top: -3px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="px-2 py-1" cols="10">
                    <span class="d-inline-block" style="width: 70px; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.zip"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn
                        class="white--text ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                        min-width="30" height="26" @click="onLclMdl('01', 600)" small>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                            </template>
                            <span>주소검색</span>
                        </v-tooltip>
                    </v-btn>
                    <span class="d-inline-block ml-2" style="width: 45%; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.addr"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 35%; height: 100%">
                        <v-text-field
                            v-model="fmlyInpInf.addrDtl" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">비급여 수가 정보</span>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">비급여 수가 기준</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-radio-group
                        v-model="cntrcInpInf.npymntFeeStd"
                        class="radio-dft-Theme d-flex mt-0 pt-0"
                        @change="aplctSavBtn"
                        hide-details row>
                        <v-radio
                            class="d-inline-block ma-0 pt-2 pl-2 col col-6 tableBR"
                            style="padding-bottom: 6px !important;"
                            :ripple="false" label="시설 비급여 수가" value="1">
                        </v-radio>
                        <v-radio
                            class="d-inline-block ma-0 pt-2 pl-2 col col-6"
                            style="padding-bottom: 6px !important;"
                            :ripple="false" label="개별 비급여 수가" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">식사 재료비</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tableBR" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 py-2 tableBR" cols="6">
                                    <span>&#40; 1식 &#41;</span>
                                </v-col>
                                <v-col class="txtAlgnEnd py-2 pr-3" cols="6">
                                    <span>{{ nBnftFee.mealCost?.toLocaleString('ko-KR') }} 원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <span>&#40; 1식 &#41;</span>
                                </v-col>
                                <v-col class="px-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <v-select
                                        v-model="cntrcInpInf.mealCnt"
                                        :items="mealCntMthd" @input="aplctSavBtn"
                                        :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="px-2 pb-1" style="padding-top: 6px;" cols="6">
                                    <span class="d-inline-block" style="width: 150px; height: 100%;">
                                        <v-text-field
                                            v-model="cntrcInpInf.mealCost"
                                            @input="aplctSavBtn" :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                            class="" height="28" color="blue" type="number"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1">원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">경관 유동식</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tableBR" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 py-2 tableBR" cols="6">
                                    <span v-if="nBnftFee.lqdFoodClcd == '1'">&#40; 1개월 &#41;</span>
                                    <span v-else-if="nBnftFee.lqdFoodClcd == '2'">&#40; 1식 &#41;</span>
                                </v-col>
                                <v-col class="txtAlgnEnd py-2 pr-3" cols="6">
                                    <span>{{ nBnftFee.lqdFoodFee?.toLocaleString('ko-KR') }} 원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="px-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <v-select
                                        v-model="cntrcInpInf.lqdFoodClcd"
                                        :items="lqdFoodMthd" @input="aplctSavBtn"
                                        :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="px-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <v-select
                                        v-model="cntrcInpInf.lqdFoodCnt"
                                        :items="mealCntMthd" @input="aplctSavBtn"
                                        :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="px-2 pb-1" style="padding-top: 6px;" cols="6">
                                    <span class="d-inline-block" style="width: 150px; height: 100%;">
                                        <v-text-field
                                            v-model="cntrcInpInf.lqdFood"
                                            @input="aplctSavBtn" :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                            class="" height="28" color="blue" type="number"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1">원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">간식비</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tableBR" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 pt-2 tableBR" cols="3">
                                    <span v-if="nBnftFee.snackOfrClcd == '1'">&#40; 1일 &#41;</span>
                                    <span v-else-if="nBnftFee.snackOfrClcd == '2'">&#40; 1식 &#41;</span>
                                </v-col>
                                <v-col class="txtAlgnEnd px-2 pt-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <span>{{ nBnftFee.snackCntCd == null ? '' : '( ' + nBnftFee.snackCntCd + '회 )' }}</span>
                                    <!-- <v-select
                                        v-model="nBnftFee.snackCntCd"
                                        :items="snackCntMthd" :disabled="cntrcInpInf.npymntFeeStd == '2'"
                                        @input="aplctSavBtn"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select> -->
                                </v-col>
                                <v-col class="txtAlgnEnd py-2 pr-3" cols="6">
                                    <span>{{ nBnftFee.snackFee?.toLocaleString('ko-KR') }} 원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="px-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <v-select
                                        v-model="cntrcInpInf.snackOfrClcd"
                                        :items="snackOfrMthd" @input="aplctSavBtn"
                                        :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="px-2 tableBR" style="padding-top: 6px;" cols="3">
                                    <v-select
                                        v-model="cntrcInpInf.snackCntCd"
                                        :items="snackCntMthd" @input="aplctSavBtn"
                                        :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="px-2 pb-1" style="padding-top: 6px;" cols="6">
                                    <span class="d-inline-block" style="width: 150px; height: 100%;">
                                        <v-text-field
                                            v-model="cntrcInpInf.snackFee"
                                            @input="aplctSavBtn" :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                            class="" height="28" color="blue" type="number"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1">원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">이/미용료</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tableBR" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 py-2 tableBR" cols="6">
                                    <span>&#40; 1개월 &#41;</span>
                                </v-col>
                                <v-col class="txtAlgnEnd py-2 pr-3" cols="6">
                                    <span>{{ nBnftFee.btyFee ?.toLocaleString('ko-KR') }} 원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 tableBR" style="padding-top: 6px;" cols="6">
                                    <span>&#40; 1개월 &#41;</span>
                                </v-col>
                                <v-col class="px-2 pb-1" style="padding-top: 6px;" cols="6">
                                    <span class="d-inline-block" style="width: 150px; height: 100%;">
                                        <v-text-field
                                            v-model="cntrcInpInf.btyFee"
                                            @input="aplctSavBtn" :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                            class="" height="28" color="blue" type="number"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1">원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">상급 침실비</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tableBR" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 py-2" cols="2">
                                    <span>&#40; 1박 &#41;</span>
                                </v-col>
                                <v-col class="px-2 py-1 tableBR" cols="4">
                                    <!-- <v-select
                                        v-model="nBnftFee.upBedClcd"
                                        :items="vCd114" :disabled="cntrcInpInf.npymntFeeStd == '2'"
                                        @change="aplctSavBtn"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select> -->
                                </v-col>
                                <v-col class="txtAlgnEnd py-1 px-3" cols="6">
                                    <span>{{ nBnftFee.upBedFee?.toLocaleString('ko-KR') }} 원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pl-2 py-2" cols="2">
                                    <span>&#40; 1박 &#41;</span>
                                </v-col>
                                <v-col class="px-2 py-1 tableBR" cols="4">
                                    <v-select
                                        v-model="cntrcInpInf.upBedClcd"
                                        :items="vCd114"
                                        :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </v-col>
                                <v-col class="px-2 pb-1" style="padding-top: 6px;" cols="6">
                                    <span class="d-inline-block" style="width: 150px; height: 100%;">
                                        <v-text-field
                                            v-model="cntrcInpInf.upBedFee"
                                            @input="aplctSavBtn" :disabled="cntrcInpInf.npymntFeeStd == '1'"
                                            class="" height="28" color="blue" type="number"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="ml-1">원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">식사 재료비</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-radio-group
                        v-model="cntrcInpInf.mealCalcCd"
                        class="radio-dft-Theme d-flex mt-0 pt-0"
                        @change="aplctSavBtn"
                        hide-details row>
                        <v-radio
                            class="d-inline-block ma-0 pt-2 pl-2 col col-6 tableBR"
                            style="padding-bottom: 6px !important;"
                            :ripple="false" label="식사 재료비로 계산" value="1">
                        </v-radio>
                        <v-radio
                            class="d-inline-block ma-0 pt-2 pl-2 col col-6"
                            style="padding-bottom: 6px !important;"
                            :ripple="false" label="경관 유동식으로 계산" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-divider v-if="cpyGvrmn.acknwRtng == '99'" class="white my-1"></v-divider>
            <v-divider v-if="cpyGvrmn.acknwRtng == '99'" class="mr-1"></v-divider>
            <v-row v-if="cpyGvrmn.acknwRtng == '99'" class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="2">
                    <span class="font-weight-medium">등급외 본인부담금</span>
                </v-col>
                <v-col class="" cols="3">
                    <v-radio-group
                        v-model="cntrcInpInf.rtngOtrClcd"
                        class="radio-dft-Theme d-flex mt-0 pt-0"
                        @change="aplctSavBtn"
                        hide-details row>
                        <v-radio
                            class="d-inline-block ma-0 pt-2 pl-2"
                            style="padding-bottom: 6px !important;"
                            :ripple="false" label="월별" value="1">
                        </v-radio>
                        <v-radio
                            class="d-inline-block ma-0 pt-2 pl-2"
                            style="padding-bottom: 6px !important;"
                            :ripple="false" label="일별" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="pt-1" cols="5">
                    <span class="d-inline-block" style="width: 124px;">
                        <v-text-field
                            v-model="cntrcInpInf.rtngOtrAmt" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider v-if="cpyGvrmn.acknwRtng == '99'" class="mr-1"></v-divider>
            <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">세부내역 &#40; 30일 기준 &#41;</span>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0" style="margin-right: 5px !important;" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                    <span class="font-weight-medium">구분</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                    <span class="font-weight-medium">금액&#40;원&#41;</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-2" cols="6">
                    <span class="font-weight-medium">내역</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" style="margin-right: 5px !important;" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                    <span class="font-weight-medium">총계</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-2" cols="3">
                    <span>{{ (prnExpCost.ltrmCost + prnExpCost.totCost)?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="txtAlgnMid py-2" cols="6">
                    <span>{{ prnExpCost.ltrmCost?.toLocaleString('ko-KR') }}</span>
                    <span class="mx-1">+</span>
                    <span>{{ prnExpCost.totCost?.toLocaleString('ko-KR') }}</span>
                    <span class="mx-2">=</span>
                    <span>{{ (prnExpCost.ltrmCost + prnExpCost.totCost)?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center lnHgt1-3 tableBR" cols="1">
                    <span class="font-weight-medium">요양보험<br>부담비용</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" cols="2">
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="font-weight-medium">소계</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="font-weight-medium">요양급여비용 &#40; {{ 100 - cpyGvrmn.copayRateVal }}&#37; &#41;</span>
                    </v-row>
                </v-col>
                <v-col class="txtAlgnMid tableBR" cols="3">
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>{{ prnExpCost.ltrmCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>{{ prnExpCost.ltrmCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                </v-col>
                <v-col class="txtAlgnMid" cols="6">
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>&nbsp;</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="mr-1">{{ bnftFee?.toLocaleString('ko-KR') }}</span>
                        <span v-if="cpyGvrmn.acknwRtng == '99'">&#40; 등급외 &#41;</span>
                        <span v-else>&#40; {{ cpyGvrmn.acknwRtng }}등급 &#41;</span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">{{ 100 - cpyGvrmn.copayRateVal }}%</span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">30일</span>
                        <span class="mx-2">=</span>
                        <span>{{ prnExpCost.ltrmCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex align-center justify-center lnHgt1-3 tableBR" cols="1">
                    <div class="txtAlgnMid">
                        <span class="font-weight-medium">개인<br>부담비용</span>
                    </div>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" cols="2">
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="font-weight-medium">소계</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="font-weight-medium">요양급여비용 &#40; {{ cpyGvrmn.copayRateVal }}&#37; &#41;</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="justify-center" style="padding-top: 19.4px; padding-bottom: 19.4px;" no-gutters>
                        <span class="font-weight-medium">식사재료비 / 간식비</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="font-weight-medium">이 / 미용료</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="font-weight-medium">상급 침실비</span>
                    </v-row>
                    <v-divider v-if="cpyGvrmn.acknwRtng == '99'" class=""></v-divider>
                    <v-row
                        class="py-2 justify-center"
                        v-if="cpyGvrmn.acknwRtng == '99'"
                        no-gutters>
                        <span class="font-weight-medium">등급외 본인부담금</span>
                    </v-row>
                </v-col>
                <v-col class="txtAlgnMid tableBR" cols="3">
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>{{ prnExpCost.totCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>{{ prnExpCost.bnftCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="justify-center" style="padding-top: 19.4px; padding-bottom: 19.4px;" no-gutters>
                        <span>{{ (prnExpCost.mealCost + prnExpCost.snackCost)?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>{{ prnExpCost.btyCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>{{ prnExpCost.upBedCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider v-if="cpyGvrmn.acknwRtng == '99'" class=""></v-divider>
                    <v-row
                        class="py-2 justify-center"
                        v-if="cpyGvrmn.acknwRtng == '99'"
                        no-gutters>
                        <span>{{ Number(prnExpCost.rtngOtrCost)?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                </v-col>
                <v-col class="txtAlgnMid" cols="6">
                    <v-row class="py-2 justify-center" no-gutters>
                        <span>&nbsp;</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span class="mr-1">{{ bnftFee?.toLocaleString('ko-KR') }}</span>
                        <span v-if="cpyGvrmn.acknwRtng == '99'">&#40; 등급외 &#41;</span>
                        <span v-else>&#40; {{ cpyGvrmn.acknwRtng }}등급 &#41;</span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">{{ cpyGvrmn.copayRateVal }}%</span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">30일</span>
                        <span class="mx-2">=</span>
                        <span>{{ prnExpCost.bnftCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pt-2 justify-center" no-gutters>
                        <span v-if="cntrcInpInf.npymntFeeStd == '1'">
                            <span v-if="cntrcInpInf.mealCalcCd == '1'">{{ nBnftFee.mealCost?.toLocaleString('ko-KR') }}</span>
                            <span v-else-if="cntrcInpInf.mealCalcCd == '2'">{{ nBnftFee.lqdFoodFee?.toLocaleString('ko-KR') }}</span>
                        </span>
                        <span v-if="cntrcInpInf.npymntFeeStd == '2'">
                            <span v-if="cntrcInpInf.mealCalcCd == '1'">{{ Number(cntrcInpInf.mealCost)?.toLocaleString('ko-KR') }}</span>
                            <span v-else-if="cntrcInpInf.mealCalcCd == '2'">{{ Number(cntrcInpInf.lqdFood)?.toLocaleString('ko-KR') }}</span>
                        </span>
                        <span v-if="cntrcInpInf.mealCalcCd == '1'" class="ml-1">&#40;식사재료비&#41;</span>
                        <span v-else-if="cntrcInpInf.mealCalcCd == '2'" class="ml-1">&#40;경관유동식&#41;</span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span v-if="cntrcInpInf.npymntFeeStd == '1'">3식</span>
                        <span v-else-if="cntrcInpInf.npymntFeeStd == '2'">
                            <span v-if="cntrcInpInf.mealCalcCd == '1'" class="">{{ cntrcInpInf.mealCnt }}식</span>
                            <span v-else-if="cntrcInpInf.mealCalcCd == '2'" class="">{{ cntrcInpInf.lqdFoodCnt }}식</span>
                        </span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">30일</span>
                        <span class="mx-1">+</span>
                    </v-row>
                    <v-row class="pb-2 justify-center" no-gutters>
                        <span v-if="cntrcInpInf.npymntFeeStd == '1'">
                            <span v-if="nBnftFee.snackOfrClcd == '1'">
                                {{ nBnftFee.snackFee?.toLocaleString('ko-KR') }} &#40;간식비&#41;
                            </span>
                            <span v-else-if="nBnftFee.snackOfrClcd == '2'">
                                <span>{{ nBnftFee.snackFee?.toLocaleString('ko-KR') }} &#40;간식비&#41;</span>
                                <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                                <span>{{ nBnftFee.snackCntCd }}회</span>
                            </span>
                        </span>
                        <span v-else-if="cntrcInpInf.npymntFeeStd == '2'">
                            <span v-if="cntrcInpInf.snackOfrClcd == '1'">
                                {{ Number(cntrcInpInf.snackFee)?.toLocaleString('ko-KR') }} &#40;간식비&#41;
                            </span>
                            <span v-else-if="cntrcInpInf.snackOfrClcd == '2'">
                                <span>{{ Number(cntrcInpInf.snackFee)?.toLocaleString('ko-KR') }} &#40;간식비&#41;</span>
                                <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                                <span>{{ cntrcInpInf.snackCntCd }}회</span>
                            </span>
                        </span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">30일</span>
                        <span class="mx-2">=</span>
                        <span>{{ (prnExpCost.mealCost + prnExpCost.snackCost)?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" style="height: 39px;" no-gutters>
                        &nbsp;
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="py-2 justify-center" no-gutters>
                        <span v-if="cntrcInpInf.npymntFeeStd == '1'">{{ nBnftFee.upBedFee?.toLocaleString('ko-KR') }}</span>
                        <span v-if="cntrcInpInf.npymntFeeStd == '2'">{{ Number(cntrcInpInf.upBedFee)?.toLocaleString('ko-KR') }}</span>
                        <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                        <span class="mr-1">30일</span>
                        <span class="mx-2">=</span>
                        <span>{{ prnExpCost.upBedCost?.toLocaleString('ko-KR') }}</span>
                    </v-row>
                    <v-divider v-if="cpyGvrmn.acknwRtng == '99'" class=""></v-divider>
                    <v-row v-if="cpyGvrmn.acknwRtng == '99'" class="py-2 justify-center" no-gutters>
                        <span v-if="cntrcInpInf.rtngOtrClcd == '1'">{{ Number(cntrcInpInf.rtngOtrAmt)?.toLocaleString('ko-KR') }}</span>
                        <span
                            v-else-if="cntrcInpInf.rtngOtrClcd == '2'"
                            class="d-flex align-center">
                            <span>{{ Number(cntrcInpInf.rtngOtrAmt)?.toLocaleString('ko-KR') }}</span>
                            <v-icon class="mx-1" style="padding-top: 1px;" size="18">mdi-close</v-icon>
                            <span class="">30일</span>
                            <span class="mx-2">=</span>
                            <span>{{ prnExpCost.rtngOtrCost?.toLocaleString('ko-KR') }}</span>
                        </span>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-divider class="white my-3"></v-divider>
            <v-row class="pa-0 justify-center" no-gutters>
                <v-btn
                    class="" color="blueBtnColor" min-width="110" height="36"
                    :disabled="savBtn" @click="dcsnSavBtn">
                    <span class="white--text fontOneDotTwoRem">저장</span>
                </v-btn>
            </v-row>
            <v-divider class="white my-1"></v-divider>
        </v-form>
        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <AdrsSrch v-if="lclMdlInf.typ == '01'" @onDataThr="rflAddrInf" @isClose="rstLclMdlInf"></AdrsSrch>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';         // 날짜 입력
import AdrsSrch from '../../commons/AdrsSrch.vue';               // 주소 검색

export default {
    name: 'CntrcAddMdl',
    
    components: {
        CmmDateComp,
        AdrsSrch,
    },

    props : { 
        prtCd62: { type: Array, default: () => { return [] } },         // 인정등급
        prtCd63: { type: Array, default: () => { return [] } },         // 본인부담률
        prtCd114: { type: Array, default: () => { return [] } },        // 상급침실구분
        prtYearPeriod: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.vCd114 = [...this.prtCd114]
            this.vCd114.unshift({ valcd: '0', valcdnm: '선택' })

            let obj = this.$store.getters.getRootInObj
            this.bnMmbrPk = obj.bnMmbrPk
            this.bnfcrPk = obj.bnfcrPk
            this.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')

            this.getFcltyDpsInf()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                this.$parent.$parent.$parent.$parent.mdlOff()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                
                this.runAble = '0'
            }
        },
        'cntrcInpInf.cntrcPrdStrt': function (){
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcPrdStrt)){
                let inqDt = this.$moment(this.cntrcInpInf.cntrcPrdStrt, 'YYYY-MM-DD').format('YYYYMMDD')
                this.bnftFee = 0
                this.nBnftFee = Object.assign({})
                this.cpyGvrmn = Object.assign({ copayRateVal: 0 })
                this.cntrcMsg

                Object.keys(this.cntrcMsg).forEach(itm => {
                    if(itm == 'msgDt')
                        this.cntrcMsg[itm] = ''
                    else
                        this.cntrcMsg[itm] = false
                });

                this.getCntrcBscInf(inqDt)
                // this.getNbnftFeeInf(inqDt)
                // this.getCpyGvrmnInf(inqDt)
            }
            else
                this.sltPrd = '0'
        },
        // 'cpyGvrmn.acknwRtng': function (){
        //     if(typeof this.cpyGvrmn.acknwRtng != 'undefined')
        //         this.getBnftFeeInf(this.cpyGvrmn.acknwRtng)
        // },
        'prtCd114': function(v){
            this.vCd114.splice(0)
            this.vCd114 = [...v]
            this.vCd114.unshift({ valcd: '0', valcdnm: '선택' })
        },
        // 'bnftFee': function(v){
        //     if(v > 0 && Object.keys(this.nBnftFee).length > 0)
        //         this.cntrcDtlCalc()
        // },
        // 'nBnftFee': function(){
        //     if(this.cpyGvrmn.acknwRtng == '99')
        //         this.cntrcDtlCalc()
        // },
        'nBnftFee.upBedClcd': function(){
            if(typeof this.nBnftFee.aplStrt != 'undefined'){
                switch (this.nBnftFee.upBedClcd) {
                    case '1':
                        this.nBnftFee.upBedFee = Number(this.nBnftFee.upBedFee1)
                        break;
                    case '2':
                        this.nBnftFee.upBedFee = Number(this.nBnftFee.upBedFee2)
                        break;
                    default:
                        this.nBnftFee.upBedFee = 0
                        break;
                }
                this.aplctSavBtn()
            }
        },
        'cntrcInpInf.upBedClcd': function(){
            if(typeof this.nBnftFee.aplStrt != 'undefined'){
                switch (this.cntrcInpInf.upBedClcd) {
                    case '1':
                        this.cntrcInpInf.upBedFee = Number(this.nBnftFee.upBedFee1)
                        break;
                    case '2':
                        this.cntrcInpInf.upBedFee = Number(this.nBnftFee.upBedFee2)
                        break;
                    default:
                        this.cntrcInpInf.upBedFee = 0
                        break;
                }
                this.aplctSavBtn()
            }
        },
    },
        
    methods: {
        // 계약 필수 정보 종합 조회
        getCntrcBscInf: function (ymd){
            let obj = {}
            obj.bnMmbrPk = this.bnMmbrPk
            obj.bnfcrPk = this.bnfcrPk
            obj.inqYMD = ymd

            http.post('bnfcrAuth/selCntrcBasic', obj)
                .then((response) => ( this.cntrcBscRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selCntrcBasic : ' + error))
        },

        cntrcBscRst: function (res){
            this.cpyGvrmn = res.result.rtngGvrnmInf

            // 오류 메시자창 작성
            if(this.cpyGvrmn.acknwRtngNm == null)
                this.cntrcMsg.acknwRtngMsg = true
            if(this.cpyGvrmn.copayRateNm == null)
                this.cntrcMsg.copayRateMsg = true

            if(res.result.bnftFeeInfo == null)
                this.cntrcMsg.bnftFeeMsg = true
            else if(Number.isNaN(res.result.bnftFeeInfo.bnftFee) == false)
                this.bnftFee = res.result.bnftFeeInfo.bnftFee

            if(res.result.nbnftFeeInfo == null)
                this.cntrcMsg.nBnftFeeMsg = true
            else if(Object.keys(res.result.nbnftFeeInfo).length > 0){
                res.result.nbnftFeeInfo.upBedClcd = '0'
                res.result.nbnftFeeInfo.upBedFee = 0
                res.result.nbnftFeeInfo.mealCnt = 3
                res.result.nbnftFeeInfo.lqdFoodCnt = 3

                this.nBnftFee = res.result.nbnftFeeInfo

                if(this.bnftFee > 0)
                    this.cntrcDtlCalc()
            }
            else
                this.nBnftFee = Object.assign({})

            // 미조회 데이터 정보 메시지 노출
            if(this.cpyGvrmn.acknwRtngNm == null || this.cpyGvrmn.copayRateNm == null ||
                res.result.bnftFeeInfo == null || res.result.nbnftFeeInfo == null){
                    this.cntrcMsg.msgDt = this.$moment(this.cntrcInpInf.cntrcPrdStrt, 'YYYY-MM-DD').format('YYYY.MM.DD')
                    this.cntrcMsg.onMdl = true
                }
            else{
                this.cntrcMsg.msgDt = ''
                this.cntrcMsg.onMdl = false
            }

            this.aplctSavBtn()
        },

        // 수급자 중요 이력정보 조회(~2024.12.23)
        getCpyGvrmnInf: function (ymd){
            let obj = {}
            obj.bnMmbrPk = this.bnMmbrPk
            obj.bnfcrPk = this.bnfcrPk
            obj.inqYMD = ymd

            http.post('bnfcrAuth/selBnfcrCopayRtngGvrnmInfo', obj)
                .then((response) => ( this.cpyGvrmn = response.data.result ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrCopayRtngGvrnmInfo : ' + error))
        },

        // 신규계약
        addCntrcInf: function (obj){
            this.savBtn = true

            http.post('/bnfcrAuth/insBnfcrCntrc', obj)
                .then((response) => ( this.insCntrcRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrCntrc : ' + error))
        },
        insCntrcRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.savBtn = false
                this.rstInp()
            }
            else{
                if(Array.isArray(res.messageList))
                    obj.cntnt = res.messageList

                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 비급여 수가정보(~2024.12.23)
        getNbnftFeeInf: function (yymmdd){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.inqYMD = yymmdd

            http.post('opr/selNbnftFeeInf', obj)
                .then((response) => ( this.nBnftFeeRst(response.data) ))
                .catch((error) => console.log('connect error /opr/selNbnftFeeInf : ' + error))
        },

        nBnftFeeRst: function (res){
            if(Object.keys(res).length > 0){
                res.upBedClcd = '0'
                res.upBedFee = 0

                this.nBnftFee = res

                if(this.bnftFee > 0)
                    this.cntrcDtlCalc()
            }
            else
                this.nBnftFee = Object.assign({})
        },

        // 급여 수가정보(~2024.12.23)
        getBnftFeeInf: function (acknw){
            let obj = {}
            obj.fcltyClcd = 1
            obj.bnftClcd = 1
            obj.acknwRtng = acknw

            http.post('opr/selBnftFeeInfo', obj)
                .then((response) => ( this.bnftFee = response.data.bnftFee ))
                .catch((error) => console.log('connect error /opr/selBnftFeeInfo : ' + error))
        },

        // 기관 입금정보 조회
        getFcltyDpsInf: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('opr/selFcltyDpsInfInfo', obj)
                .then((response) => ( this.fcltyDpsRst(response.data) ))
                .catch((error) => console.log('connect error /opr/selFcltyDpsInfInfo : ' + error))
        },

        fcltyDpsRst: function (res){
            if(Object.keys(res).length > 0){
                let tmp = ''
                if(res.dpsDt.length == 1)
                    tmp = '0' + res.dpsDt
                else
                    tmp = res.dpsDt

                if(this.cntrcInpInf.pymntDt == '')
                    this.cntrcInpInf.pymntDt = tmp
            }
        },

        // 주 보호자 조회
        getGalFmlyInf: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnfcrPk = this.bnfcrPk

            http.post('bnfcr/selGarnInfo', obj)
                .then((response) => {
                    if(Object.keys(response.data).length > 0){
                        this.fmlyInpInf.name = response.data.name
                        this.fmlyInpInf.rltns = response.data.rltns
                        this.fmlyInpInf.zip = response.data.zip
                        this.fmlyInpInf.addr = response.data.addr
                        this.fmlyInpInf.addrDtl = response.data.addrDtl
                        this.fmlyInpInf.tel1 = response.data.tel_1
                        this.fmlyInpInf.tel2 = response.data.tel_2
                        this.fmlyInpInf.tel3 = response.data.tel_3
                        this.fmlyInpInf.celphn1 = response.data.celphn_1
                        this.fmlyInpInf.celphn2 = response.data.celphn_2
                        this.fmlyInpInf.celphn3 = response.data.celphn_3

                        if( response.data.rsdntBirth != null && response.data.rsdntBirth != '' )
                            this.fmlyInpInf.rsdntBirth = this.$moment(response.data.rsdntBirth, 'YYYYMMDD').format('YYYY-MM-DD')

                        this.aplctSavBtn()
                    }
                })
                .catch((error) => console.log('connect error /bnfcr/selGarnInfo : ' + error))
        },

        // 계약정보 본인부담금 계산
        cntrcDtlCalc: function (){
            if(typeof this.cpyGvrmn.copayRateVal == 'undefined')
                return 0

            let cpyRate = Number(this.cpyGvrmn.copayRateVal) / 100

            // 공단부담금
            if(this.cpyGvrmn.acknwRtng != '99')
                this.prnExpCost.ltrmCost = (this.bnftFee * (1 - cpyRate)) * 30

            // 본인부담금
            if(this.cpyGvrmn.acknwRtng != '99')
                this.prnExpCost.bnftCost = (this.bnftFee * cpyRate) * 30

            switch (this.cntrcInpInf.npymntFeeStd) {            // 비급여수가기준 (1: 시설, 2: 개별)
                case '1':
                    this.nBnftCalc(this.nBnftFee, '1')
                    break;
                case '2':
                    this.nBnftCalc(this.cntrcInpInf, '2')
                    break;
                default:
                    break;
            }

            this.prnExpCost.totCost = this.prnExpCost.bnftCost
            this.prnExpCost.totCost
                += this.prnExpCost.mealCost + this.prnExpCost.snackCost + this.prnExpCost.btyCost + this.prnExpCost.upBedCost

            if(this.cpyGvrmn.acknwRtng == '99')
                this.prnExpCost.totCost += Number(this.prnExpCost.rtngOtrCost)
        },

        // 개인부담 비금여 계산
        nBnftCalc: function (obj, typ) {
            // 식사재료비
            switch (this.cntrcInpInf.mealCalcCd) {      // 식사재료비 계산방법 (1: 식사, 2: 경관)
                case '1':
                    this.prnExpCost.mealCost = ( obj.mealCost * obj.mealCnt ) * 30
                    break;
                case '2':
                    if(obj.lqdFoodClcd == '0')
                        this.prnExpCost.mealCost = 0
                    else if(obj.lqdFoodClcd == '1'){
                        if(typ == '1')
                            this.prnExpCost.mealCost = obj.lqdFoodFee
                        else if(typ == '2')
                            this.prnExpCost.mealCost = obj.lqdFood
                    }
                    else if(obj.lqdFoodClcd == '2'){
                        if(typ == '1')
                            this.prnExpCost.mealCost = ( obj.lqdFoodFee * obj.lqdFoodCnt ) * 30
                        else if(typ == '2')
                            this.prnExpCost.mealCost = ( obj.lqdFood * obj.lqdFoodCnt ) * 30
                    }
                    break;
                default:
                    break;
            }
            if(this.cpyGvrmn.mealSprtYn == '1')         // 식사 보조금 처리
                this.prnExpCost.mealCost = 0

            // 간식비
            switch (obj.snackOfrClcd) {
                case '0':
                    this.prnExpCost.snackCost = 0
                    break;
                case '1':
                    this.prnExpCost.snackCost = obj.snackFee * 30
                    break;
                case '2':
                    this.prnExpCost.snackCost = ( obj.snackFee * Number(obj.snackCntCd) ) * 30
                    break;
                default:
                    break;
            }
            // 상무님 지시사항 ( 간식비 1일인경우 횟수 계산 안함 ~2025.01.28 )
            // if(obj.snackOfrClcd == '0')
            //     this.prnExpCost.snackCost = 0
            // else
            //     this.prnExpCost.snackCost = ( obj.snackFee * Number(obj.snackCntCd) ) * 30

            if(this.cpyGvrmn.snackSprtYn == '1')        // 간식비 보조금 처리
                this.prnExpCost.snackCost = 0

            // 식사재료비 월 한도액 제한
            // if(obj.mealCostLmtYn == '1')
            //     if(obj.mealCostLmt < (this.prnExpCost.mealCost + this.prnExpCost.snackCost))
            //         this.prnExpCost.mealCost = obj.mealCostLmt

            // 이미용료
            this.prnExpCost.btyCost = Number(obj.btyFee)

            // 상급침실비
            if(obj.upBedClcd == '0')
                this.prnExpCost.upBedCost = 0
            else
                this.prnExpCost.upBedCost = obj.upBedFee * 30

            // 상급침실비 월 한도액 제한
            // if(obj.upBedFeeLmtYn == '1' && obj.upBedFeeLmt < this.prnExpCost.upBedCost)
            //     this.prnExpCost.upBedCost = obj.upBedFeeLmt

            // 등급외 본인부담금
            if(this.cntrcInpInf.rtngOtrClcd == '1')
                this.prnExpCost.rtngOtrCost = this.cntrcInpInf.rtngOtrAmt
            else if(this.cntrcInpInf.rtngOtrClcd == '2')
                this.prnExpCost.rtngOtrCost = this.cntrcInpInf.rtngOtrAmt * 30
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
            this.cntrcDtlCalc()

            if(this.cntrcMsg.onMdl)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcDt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcPrdStrt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcPrdEnd) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.fmlyInpInf.rsdntBirth) == false)
                return 0
            if(this.$moment(this.entDt).isAfter(this.$moment(this.cntrcInpInf.cntrcDt)))
                return 0
            if(this.$moment(this.cntrcInpInf.cntrcPrdStrt).isAfter(this.$moment(this.cntrcInpInf.cntrcPrdEnd)))
                return 0
            if(this.cntrcInpInf.pymntDt == '')
                return 0
            if(this.fmlyInpInf.name == '')
                return 0
            if(this.fmlyInpInf.rltns == '')
                return 0
            if(this.fmlyInpInf.zip == '')
                return 0
            if(this.fmlyInpInf.addr == '')
                return 0
            if(this.cpyGvrmn.bnfcrCopayRatePk == null)
                return 0
            if(typeof this.cpyGvrmn.bnfcrCopayRatePk == 'undefined')
                return 0
            if(this.cpyGvrmn.bnfcrAcknwRtngPk == null)
                return 0
            if(typeof this.cpyGvrmn.bnfcrAcknwRtngPk == 'undefined')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                                      // 경고 노출여부
            obj.tmr = 3500                                       // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                                 // 경고 유형 (success, info, warning, error)
            obj.title = '필수요소가 부족하여 저장 할 수 없습니다.'  // 경고 타이틀
            obj.cntnt = []                                       // 경고 내용 (arr만 출력됨!)

            let insObj = {}
            insObj.bnMmbrPk = this.bnMmbrPk
            insObj.bnfcrPk = this.bnfcrPk
            insObj.cntrcDt = this.$moment(this.cntrcInpInf.cntrcDt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.cntrcPrdStrt = this.$moment(this.cntrcInpInf.cntrcPrdStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.cntrcPrdEnd = this.$moment(this.cntrcInpInf.cntrcPrdEnd, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.pymntMthdCd = this.cntrcInpInf.pymntMthdCd
            insObj.pymntDt = this.cntrcInpInf.pymntDt

            if(this.cpyGvrmn.bnfcrAcknwRtngPk == null || this.cpyGvrmn.bnfcrCopayRatePk == null){
                if(this.cpyGvrmn.bnfcrAcknwRtngPk == null)
                    obj.cntnt.push('계약기간에 해당하는 인정등급 내역이 없습니다.')
                if(this.cpyGvrmn.bnfcrCopayRatePk == null)
                    obj.cntnt.push('계약기간에 해당하는 본인부담률 내역이 없습니다.')

                this.$store.commit('setWrnnInf', obj)

                return 0
            }

            insObj.bnfcrAcknwRtngPk = this.cpyGvrmn.bnfcrAcknwRtngPk
            insObj.bnfcrCopayRatePk = this.cpyGvrmn.bnfcrCopayRatePk
            insObj.bnfcrGvrnmSprtPk = this.cpyGvrmn.bnfcrGvrnmSprtPk

            insObj.grdnName = this.fmlyInpInf.name
            insObj.grdnRltns = this.fmlyInpInf.rltns
            insObj.grdnRsdntBirth = this.$moment(this.fmlyInpInf.rsdntBirth, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.grdnCelphn1 = this.fmlyInpInf.celphn1
            insObj.grdnCelphn2 = this.fmlyInpInf.celphn2
            insObj.grdnCelphn3 = this.fmlyInpInf.celphn3
            insObj.grdnTel1 = this.fmlyInpInf.tel1
            insObj.grdnTel2 = this.fmlyInpInf.tel2
            insObj.grdnTel3 = this.fmlyInpInf.tel3
            insObj.grdnZip = this.fmlyInpInf.zip
            insObj.grdnAddr = this.fmlyInpInf.addr
            insObj.grdnAddrDtl = this.fmlyInpInf.addrDtl

            insObj.npymntFeeStd = this.cntrcInpInf.npymntFeeStd
            insObj.mealCalcCd = this.cntrcInpInf.mealCalcCd

            switch (insObj.npymntFeeStd) {
                case '1':
                    if(typeof this.nBnftFee.aplStrt == 'undefined')
                        return 0

                    insObj.mealCost = this.nBnftFee.mealCost
                    insObj.mealCnt = this.nBnftFee.mealCnt
                    insObj.lqdFoodClcd = this.nBnftFee.lqdFoodClcd
                    insObj.lqdFood = this.nBnftFee.lqdFoodFee
                    insObj.lqdFoodCnt = this.nBnftFee.lqdFoodCnt
                    insObj.snackOfrClcd = this.nBnftFee.snackOfrClcd
                    insObj.snackCntCd = this.nBnftFee.snackCntCd
                    insObj.snackFee = this.nBnftFee.snackFee
                    insObj.btyFee = this.nBnftFee.btyFee
                    insObj.upBedClcd = this.nBnftFee.upBedClcd
                    insObj.upBedFee = this.nBnftFee.upBedFee
                    break;
                case '2':
                    insObj.mealCost = this.cntrcInpInf.mealCost
                    insObj.mealCnt = this.cntrcInpInf.mealCnt
                    insObj.lqdFoodClcd = this.cntrcInpInf.lqdFoodClcd
                    insObj.lqdFood = this.cntrcInpInf.lqdFood
                    insObj.lqdFoodCnt = this.cntrcInpInf.lqdFoodCnt
                    insObj.snackOfrClcd = this.cntrcInpInf.snackOfrClcd
                    insObj.snackCntCd = this.cntrcInpInf.snackCntCd
                    insObj.snackFee = this.cntrcInpInf.snackFee
                    insObj.btyFee = this.cntrcInpInf.btyFee
                    insObj.upBedClcd = this.cntrcInpInf.upBedClcd
                    insObj.upBedFee = this.cntrcInpInf.upBedFee
                    break;
                default:
                    break;
            }

            if(this.cpyGvrmn.acknwRtng == '99'){
                insObj.rtngOtrClcd = this.cntrcInpInf.rtngOtrClcd
                insObj.rtngOtrAmt = this.cntrcInpInf.rtngOtrAmt
            }
            else{
                insObj.rtngOtrClcd = null
                insObj.rtngOtrAmt = 0
            }

            this.addCntrcInf(insObj)
        },

        // 계약기간 계산
        prdCalc: function () {
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcPrdStrt) == false)
                return 0

            let strt = this.$moment(this.cntrcInpInf.cntrcPrdStrt, 'YYYY-MM-DD') 
            switch (this.sltPrd) {
                case '0':
                    this.cntrcInpInf.cntrcPrdEnd = ''
                    break;
                default:
                    this.cntrcInpInf.cntrcPrdEnd = strt.add(Number(this.sltPrd), 'years').subtract(1, 'days').format('YYYY-MM-DD')
                    break;
            }
        },

        // 개별 비급여 수가 간식비 snackOfr 값 변환 이벤트
        snackOfrChngd: function () {
            if(this.cntrcInpInf.snackOfrClcd == '1')
                this.cntrcInpInf.snackCntCd = '1'
        },

        // 입력필드 초기화
        rstInp: function () {
            this.sltPrd = '0'

            let cntrcInf = {
                cntrcDt: '', cntrcPrdStrt: '', cntrcPrdEnd: '', pymntMthdCd: '1', pymntDt: '',
                npymntFeeStd : '1', mealCost: 0, lqdFoodClcd: '0', lqdFood: 0, snackOfrClcd: '0',
                snackFee: 0, snackCntCd: '0', mealCostLmtYn: '', mealCostLmt: 0, btyFee: 0, mealCalcCd: '1',
                upBedClcd: '0', upBedFee: 0, upBedFeeLmt: 0, upBedFeeLmtYn: '', rtngOtrClcd: '1', rtngOtrAmt: 0,
            }

            let fmlyInf = {
                name: '', rltns: '', rsdntBirth: '', tel1: '', tel2: '', tel3: '', celphn1: '', celphn2: '', celphn3: '',
                zip: '', addr: '', addrDtl: ''
            }
            
            this.cntrcInpInf = Object.assign(cntrcInf)
            this.fmlyInpInf = Object.assign(fmlyInf)

            this.$refs.cntrcInf.resetValidation()
        },

        // 주소 검색 내용 적용
        rflAddrInf: function (obj) {
            this.fmlyInpInf.zip = obj.zonecode
            this.fmlyInpInf.addr = obj.address

            this.aplctSavBtn()
            this.rstLclMdlInf()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        // 입력항목 변경여부 확인
        chngInpChc: function () {
            let cnt = 0

            // 계약정보 체크 5
            if(this.cntrcInpInf.cntrcDt == '')
                cnt += 1
            if(this.cntrcInpInf.cntrcPrdStrt == '')
                cnt += 1
            if(this.cntrcInpInf.cntrcPrdEnd == '')
                cnt += 1
            if(this.cntrcInpInf.pymntMthdCd == '1')
                cnt += 1
            // if(this.cntrcInpInf.pymntDt == '')
            //     cnt += 1
            
            // 보호자 정보 체크 12
            if(this.fmlyInpInf.rsdntBirth == '')
                cnt += 1
            if(this.fmlyInpInf.name == '')
                cnt += 1
            if(this.fmlyInpInf.rltns == '')
                cnt += 1
            if(this.fmlyInpInf.tel1 == '')
                cnt += 1
            if(this.fmlyInpInf.tel2 == '')
                cnt += 1
            if(this.fmlyInpInf.tel3 == '')
                cnt += 1
            if(this.fmlyInpInf.celphn1 == '')
                cnt += 1
            if(this.fmlyInpInf.celphn2 == '')
                cnt += 1
            if(this.fmlyInpInf.celphn3 == '')
                cnt += 1
            if(this.fmlyInpInf.zip == '')
                cnt += 1
            if(this.fmlyInpInf.addr == '')
                cnt += 1
            if(this.fmlyInpInf.addrDtl == '')
                cnt += 1

            if(cnt == 16)
                return 0
            else
                return 1
        },

        // 팝업 닫기/확인
        offMdl: function () {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '저장되지 않은 작성항목이 존재합니다.'
            obj.cntnt = ['저장하지 않은 항목은 손실됩니다. 창을 닫을까요?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 창을 닫겠습니다.'

            if(this.chngInpChc() == 1){
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
                this.runAble = '1'
            }
            else if(this.chngInpChc() == 0)
                this.$parent.$parent.$parent.$parent.mdlOff()
        },
    },

    data: () => ({
        sltPrd: '0',
        runAble: '0',
        savBtn: true,
        bnMmbrPk: -1,
        bnfcrPk: -1,
        entDt: '',
        bnftFee: 0,                                                     // 수가금액
        nBnftFee: {},                                                   // 비급여 수가 정보
        cpyGvrmn: { copayRateVal: 0 },                                  // 본인부담률, 인정등급, 보조금 정보
        cntrcInpInf: {
            cntrcDt: '', cntrcPrdStrt: '', cntrcPrdEnd: '', pymntMthdCd: '1', pymntDt: '',
            npymntFeeStd : '1', mealCost: 0, mealCnt: 3, lqdFoodClcd: '0', lqdFood: 0, lqdFoodCnt: 3,
            snackOfrClcd: '0', snackFee: 0, snackCntCd: '0', mealCostLmtYn: '', mealCostLmt: 0, btyFee: 0,
            mealCalcCd: '1', upBedClcd: '0', upBedFee: 0, upBedFeeLmt: 0, upBedFeeLmtYn: '',
            rtngOtrClcd: '1', rtngOtrAmt: 0,
        },
        fmlyInpInf: {
            name: '', rltns: '', rsdntBirth: '', tel1: '', tel2: '', tel3: '', celphn1: '', celphn2: '', celphn3: '',
            zip: '', addr: '', addrDtl: ''
        },
        prnExpCost: {                                                   // 세부내역 계산 값
            ltrmCost: 0, bnftCost: 0, mealCost: 0, snackCost: 0, btyCost: 0, upBedCost: 0, rtngOtrCost: 0, totCost: 0
        },
        vCd114: [],                                                     // 상급침실구분
        pymntMthd: [
            { valcd: '1', valcdnm: '계좌이체' }, { valcd: '2', valcdnm: '신용카드' }
        ],
        lqdFoodMthd: [
            { valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '1개월' }, { valcd: '2', valcdnm: '1식' },
        ],
        mealCntMthd: [
            { valcd: 1, valcdnm: '1회' }, { valcd: 2, valcdnm: '2회' }, { valcd: 3, valcdnm: '3회' },
        ],
        snackOfrMthd: [
            { valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '1일' }, { valcd: '2', valcdnm: '1식' },
        ],
        snackCntMthd: [
            { valcd: '0', valcdnm: '0회' }, { valcd: '1', valcdnm: '1회' }, { valcd: '2', valcdnm: '2회' },
        ],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 주소검색)
        cntrcMsg: {                                                     // 계약정보 메시지 출력
            msgDt: '', onMdl: false, acknwRtngMsg: false, copayRateMsg: false, bnftFeeMsg: false, nBnftFeeMsg: false
        },
    }),
};
</script>