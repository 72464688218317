<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <v-btn
                class="ml-2 font-weight-bold" height="30" color="grey006"
                :disabled="bnMmbrPk == -1" @click="onClickBtn('출력')"
                rounded outlined samll>
                <v-icon class="mr-1" small>mdi-printer-outline</v-icon>
                <span class="fontOneRem">출력</span>
            </v-btn>
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo ref="basicInfo" :bnMmbrPk="$parent.$parent.sltBnMmBrPk" @setParentData="getBnfcrInfo"></basicInfo>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">특별침실사용 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="630" locale="ko"
                :headers="spclBURHeaders"
                :items="spclBURList" 
                disable-pagination fixed-header hide-default-header hide-default-footer dense>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="black--text clmsFixedHeader tableBR" style="width:180px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:250px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[3].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[4].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:100px !important;">{{props.headers[5].text}}</th>
                        </tr>
                    </thead>
                </template>
 
                <template v-slot:item='{ item, index }'>
                    <tr class="disSltRow">
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div class="d-flex justify-center align-center">
                                <span>{{item.useDtStrt?.beforeDateFormatDot()}}</span>
                                <span>&#126;</span>
                                <span>{{item.useDtEnd?.beforeDateFormatDot()}}</span>
                            </div>
                        </td>
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:250px" class="text-overflow">{{item.useRsn}}</div>
                        </td>
                        <td class="clmsBodyCommon tableBR fixedCol0 ">                            
                            <div class="crsShp-Pointer pa-1" v-if="item.sndList != null && bnMmbrPk > 0" @click="onShowGrdnNtc(item.spclbdUsePk)">
                                <div v-for="(snd, j) in item.sndList" :key="j">
                                    {{ snd.rcvNm }}&#40;{{snd.rcvInfm}}&#41;
                                </div>
                            </div>
                        </td>
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div class="fixedCol" v-if="item.ntcDt !='' && item.ntcDt !=null" @click="onShowGrdnNtc(item.spclbdUsePk)">
                                {{item.ntcDt?.beforeDateFormatDot()}}
                            </div>
                            <div v-else>
                                <v-btn icon color="blueBtnColor" @click="onShowGrdnNtc(item.spclbdUsePk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </div>
                        </td>
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.wrtr}}</td>
                        <td class="clmsBodyCommon fixedCol0">
                            <div class="d-flex justify-center align-center">   
                                <v-btn icon @click="onShowSpcl(item.spclbdUsePk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>                             
                                <v-btn icon color='grey006' @click="delRow(index)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>                                
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="1300">    
                <spclBdrmUseRcrdWrt 
                    ref="spclBdrmUseRcrdWrt" 
                    :bnfcrInfo="bnfcrInfo"
                    @isClose="isModal(0)" 
                    @modalEvent="modalEvent"></spclBdrmUseRcrdWrt>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[1]" max-width="700">    
                <grdnNtcModel 
                    ref="grdnNtcModel"
                    @isClose="isModal(1)"
                    @modalEvent="modalEvent"
                    ></grdnNtcModel>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'특별침실사용기록'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import spclBdrmUseRcrdWrt from '../../components/bnftrcrd/SpclBdrmUseRcrdWrt.vue';
import grdnNtcModel from '../../components/bnftrcrd/GrdnNtcModel.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import { selBnfcrSpclbdUseList, delSpclbdUse } from '../../api/bnftrcrd.js';

export default {
    name: 'SpclBdrmUseRcrd',
    
    components: {
        expandYearOnlyPicker,
        spclBdrmUseRcrdWrt,
        basicInfo,
        grdnNtcModel,
        btnModalDelete,
    },

    props : {

    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk
            
            if(this.bnMmbrPk > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
            }     
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        }
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value  
            if(value > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
            }
        },
        'year':function(value){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            if(value != ''){
                this.$parent.$parent.getAllSpclbdUseList(value, entStcd)
                this.onLoad()
            }
        }
    },

    methods: {
        onLoad:function(){
            this.getBnfcrSpclbdUseList()
        },
        onClickBtn: function(txt){
            switch (txt) {
                case '작성 및 통지':
                    this.dialog.splice(0, 1, true)
                    break;
                case '출력':
                    this.spclBdrmActCall()
                    break;
                default:
                    break;
            }
        },
        onShowSpcl:function(pk){
            this.$refs.spclBdrmUseRcrdWrt.onLoad(pk)
            this.dialog.splice(0, 1, true)
        },
        onShowGrdnNtc:function(pk){
            this.dialog.splice(1, 1, true)
            this.$refs.grdnNtcModel.onLoad('7',pk,this.bnfcrInfo.bnMmbrPk,'2')                
        },
        delRow:function(idx){
            this.idx=idx
            this.dialogDelete = true
        },
        onDelete:function(res){
            let gFcltyNum = this.$store.getters.getFcltyNum    
            let gUserNm = this.$store.getters.getUserNm
            
            if(res == 9990){  
                let target = this.spclBURList[this.idx]              
                if(target.spclbdUsePk > 0){
                    let spclbdUsePk = target.spclbdUsePk
                    if(spclbdUsePk > 0){                        
                        delSpclbdUse(gFcltyNum,gUserNm,spclbdUsePk)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('error /bnftrcrd/delSpclbdUse : ' + error))
                    }
                }else{
                    this.dialogDelete = false    
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){        
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.onLoad()     
                this.$parent.$parent.getAllSpclbdUseList(this.year, entStcd)          
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.idx = 0
            this.dialogDelete = false            
        },
        getBnfcrSpclbdUseList(){
            let gFcltyNum = this.$store.getters.getFcltyNum
                      
            selBnfcrSpclbdUseList(gFcltyNum,this.bnMmbrPk,this.year)
            .then((response) => ( this.getBnfcrSpclbdUseListAfter(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selBnfcrSpclbdUseList : ' + error))
        },
        getBnfcrSpclbdUseListAfter:function(res){
            this.spclBURList = res
        },
        getYear: function (yyyy){
            this.year = yyyy
        },
        getBnfcrInfo: function(item){
            this.bnfcrInfo = item
        },
        snackControll: function (options) {
            this.snackOptions = options
        },     
        modalEvent: function (event) {
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            if(event == 99){
                this.$parent.$parent.getAllSpclbdUseList(this.year, entStcd)
                this.onLoad()
            }else{
                this.dialog.splice(event, 1, false)
            }      
        },    
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },
        spclBdrmActCall: function (){
            let obj = {}
            obj.rptTyp = '1'
            obj.prtClcd = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.bnMmbrPk
            obj.prtCd = '49'
            obj.inqYYYY = this.year
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            obj.inqClcd = 2

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },      
    },
    data: () => ({
        idx:0,
        year:'',
        bnMmbrPk:0,
        areaWidth: 708,
        bnfcrInfo:{},
        snackOptions: [false, 3000, '', 'info'],
        btnDialog: [false, false, false, false, ],
        dialog:[false,false,false,],
        spclBURHeaders: [            
            { text: '사용기간',width: '', value: '', align: 'center', },
            { text: '사용사유',width: '', value: '', align: 'center', },
            { text: '통지대상(관계)',width: '', value: '', align: 'center', },
            { text: '보호자동의',width: '', value: '', align: 'center', },
            { text: '작성자',width: '', value: '', align: 'center', },
            { text: '처리',width: '', value: '', align: 'center', },
        ],
        spclBURList:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '작성 및 통지', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-2 font-weight-bold', color:'grey006 ', text: '출력',  disabled: false, },
        ], 
        dialogDelete:false,
    }),
};
</script>