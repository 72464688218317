<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="8">
                <span class="text-h4 font-weight-bold">기관 야간 점검 항목 관리</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="$emit('isClose', '.')">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters>
            <div class="mr-3 d-flex align-center">
                <v-icon color="red" size="18">mdi-alert-circle-outline</v-icon>
                <span class="pl-1 red--text">야간점검기록이 있는건은 삭제 불가합니다.</span>
            </div>
        </v-row>
        <v-divider :class="[itmCdList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', itmCdList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">연번</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="3">
                <span class="fontOneDotOneRem font-weight-bold">구분</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="4">
                <span class="fontOneDotOneRem font-weight-bold">점검항목</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">사용여부</span>
            </v-col>
            <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="2">
                <v-btn class="" color="blue200" height="26" @click="addRow()" icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider :class="[itmCdList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="itmCdList.length > 0" class="overflow-y-auto" style="width: 100%; height: 385px;">
            <v-row
                class="tableBB"
                v-for="(itm, i) in itmCdList" :key="i"
                no-gutters>               

                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                    <span class="fontOneRem">{{ i+1 }}</span>
                </v-col>
                <v-col class="py-1 px-2 tableBR" style="padding-top: 6px;" cols="3">                    
                    <v-select
                        v-model="itm.clsfc3Cd"
                        :key="forceRender"
                        :items="clsfc3CdItm"
                        :disabled="itm.inspItmCdPk > 0"
                        item-text="clsfc3Cdnm" item-value="clsfc3Cd"
                        dense hide-details outlined>
                    </v-select>  
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1 px-2" style="padding-top: 6px;" cols="4">
                    <v-text-field
                        v-model="itm.itmCdnm"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="py-1 px-2 tableBR" style="padding-top: 6px;" cols="2">                    
                    <v-select
                        v-model="itm.useYn"
                        :key="forceRender"
                        :items="useYnItm"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>    
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 6px;" cols="2">   
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="blueBtnColor" min-width="26" height="26"
                        @click="preInspItmCd(itm)"
                        small icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="24">mdi-content-save-outline</v-icon>
                            </template>
                            <span>저장</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn
                        class="nmlBfClrImp-white pl-3"
                        color="grey006" min-width="26" height="26"
                        @click="rmRow(i)"
                        small icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-if="itm.inspItmCdPk > 0" v-bind="attrs" v-on="on" size="24">mdi-trash-can-outline</v-icon>
                                <v-icon v-else v-bind="attrs" v-on="on" size="24">mdi-minus-circle-outline</v-icon>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 구역정보가 없습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'InspItmCdMdl',
    
    components: {
    },

    props : { 
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getFclInspItmCdList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmItmCdCnFm){

                let obj = {
                    inspItmCdPk : this.sltItmCdPk
                }

                this.delInspItmCd(obj)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmItmCdCnFm = false
            }
        },
    },
        
    methods: {
         // 기관 점검항목코드 다건 조회
         getFclInspItmCdList: function (){    
            this.itmCdList.splice(0)

            let obj = {                
            }

            http.post('sfty/selFclInspItmCdList', obj)
                .then((response) => ( this.itmCdListRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/selFclInspItmCdList : ' + error))
        },
        //기관 점검항목코드 저장
        insInspItmCd: function (obj){  
            http.post('sfty/insInspItmCd', obj)
                .then((response) => ( this.insInspItmCdRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspItmCd : ' + error))
        },
        //기관 점검항목코드 삭제
        delInspItmCd: function (obj){  
            http.post('sfty/delInspItmCd', obj)
                .then((response) => ( this.insInspItmCdRst(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspItmCd : ' + error))
        },

        itmCdListRst:function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {                        
                        this.itmCdList.push(itm)                
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            ++this.forceRender
        },

        insInspItmCdRst:function(res){            
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getFclInspItmCdList()
            }
            else{               
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }
            this.$store.commit('setWrnnInf', obj)
        },
        addRow:function(){
            let obj = {
                inspItmCdPk : 0,
                clsfc3Cd    : '',
                clsfc3Cdnm  : '',
                itmCd       : '',
                itmCdnm     : '',
                valClcd     : '',
                itmDesc     : '',
                ord         : '',
                useYn       : '',
            }
            this.itmCdList.push(obj)
        },
        preInspItmCd:function(itm){
            if(this.isInspItmCdValid(itm)){
                let cdidx = this.clsfc3CdItm.findIndex(v=> v.clsfc3Cd == itm.clsfc3Cd )
                let obj = {
                    inspItmCdPk : itm.inspItmCdPk,
                    clsfc3Cd    : itm.clsfc3Cd,
                    clsfc3Cdnm  : this.clsfc3CdItm[cdidx].clsfc3Cdnm,
                    itmCdnm     : itm.itmCdnm,
                    itmCd       : itm.itmCd,
                    ord         : itm.ord,
                    useYn       : itm.useYn,
                }

                this.insInspItmCd(obj)
            }
        },

        //점검구역 ROW DELETE
        rmRow:function(idx){
            if(this.itmCdList[idx].inspItmCdPk > 0){
                this.sltItmCdPk = this.itmCdList[idx].inspItmCdPk
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '기관 야간 점검 항목 관리'
                obj.cntnt = [this.itmCdList[idx].itmCdnm+'을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmItmCdCnFm = true
            } else {
                this.itmCdList.splice(idx, 1)
            }
        },

        isInspItmCdValid:function(itm){
            let resrt = true

            if(itm.clsfc3Cd == '' || itm.clsfc3Cd == null) {
                this.onAlrt('구분을 선택해주세요.')
                resrt = false
            }

            if(resrt && (itm.itmCdnm == '' || itm.itmCdnm == null)) {
                this.onAlrt('점검항목을 선택해주세요.')
                resrt = false
            }
            
            if(resrt && (itm.useYn == '' || itm.useYn == null)) {
                this.onAlrt('사용여부를 선택해주세요.')
                resrt = false
            }

            return resrt
        },

        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
    },

    data: () => ({        
        rmItmCdCnFm  : false,
        sltItmCdPk   : -1,
        forceRender : 0,
        itmCdList   : [],
        clsfc3CdItm : [
            {clsfc3Cd:'', clsfc3Cdnm:'선택'},
            {clsfc3Cd:'1', clsfc3Cdnm:'전기가스'},
            {clsfc3Cd:'2', clsfc3Cdnm:'시설전반'},
            {clsfc3Cd:'3', clsfc3Cdnm:'수급자상태'},
            {clsfc3Cd:'9', clsfc3Cdnm:'기타'},
        ],
        useYnItm      : [
            {valcdnm:'선택', valcd:''},
            {valcdnm:'사용', valcd:'1'},
            {valcdnm:'미사용', valcd:'2'},
        ]
    }),
};
</script>