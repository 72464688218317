<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">주요질병상태</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 8px;" cols="2">
                        <span class="font-weight-medium">만성질환</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_21_1" :ripple="false"
                                true-value="1" false-value="2" label="당뇨"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_21_2" :ripple="false"
                                true-value="1" false-value="2" label="고혈압"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_21_3" :ripple="false"
                                true-value="1" false-value="2" label="만성호흡기질환"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_21_4" :ripple="false"
                                true-value="1" false-value="2" label="암"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 145px;">
                                <v-text-field
                                    v-model="inpTxt.val_21_4"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 20px;" cols="2">
                        <span class="font-weight-medium">순환기계</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 align-center mb-1" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_22_1" :ripple="false"
                                true-value="1" false-value="2" label="뇌경색"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_22_2" :ripple="false"
                                true-value="1" false-value="2" label="뇌출혈"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_22_3" :ripple="false"
                                true-value="1" false-value="2" label="협심증"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_22_4" :ripple="false"
                                true-value="1" false-value="2" label="심근경색증"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_22_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_22_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 20px;" cols="2">
                        <span class="font-weight-medium">신경계</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 pb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_23_1" :ripple="false"
                                true-value="1" false-value="2" label="치매"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_23_2" :ripple="false"
                                true-value="1" false-value="2" label="파킨슨병"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_23_3" :ripple="false"
                                true-value="1" false-value="2" label="간질"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_23_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_23_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 20px;" cols="2">
                        <span class="font-weight-medium">근골격계</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_24_1" :ripple="false"
                                true-value="1" false-value="2" label="관절염"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_24_2" :ripple="false"
                                true-value="1" false-value="2" label="요통, 좌골통"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_24_3" :ripple="false"
                                true-value="1" false-value="2" label="골절 등 후유증"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_24_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_24_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 20px;" cols="2">
                        <span class="font-weight-medium">정신, 행동장애</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_25_1" :ripple="false"
                                true-value="1" false-value="2" label="우울증"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_25_2" :ripple="false"
                                true-value="1" false-value="2" label="수면장애"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_25_3" :ripple="false"
                                true-value="1" false-value="2" label="정신질환"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_25_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_25_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>

                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 20px;" cols="2">
                        <span class="font-weight-medium">호흡기계</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_26_1" :ripple="false"
                                true-value="1" false-value="2" label="호흡곤란"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_26_2" :ripple="false"
                                true-value="1" false-value="2" label="결핵"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_26_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_26_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 20px;" cols="2">
                        <span class="font-weight-medium">만성신장질환</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_27_1" :ripple="false"
                                true-value="1" false-value="2" label="만성신부전증"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_27_2" :ripple="false"
                                true-value="1" false-value="2" label="복막투석"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_27_3" :ripple="false"
                                true-value="1" false-value="2" label="혈액투석"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-4" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_27_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_27_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 23px;" cols="2">
                        <span class="font-weight-medium">기타 질환</span>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 6px; padding-bottom: 6px;" cols="10">
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_28_1" :ripple="false"
                                true-value="1" false-value="2" label="알레르기"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="" style="font-size: 0.98rem;">&#40;</span>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_28_2" :ripple="false"
                                true-value="1" false-value="2" label="식품"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 317px;">
                                <v-text-field
                                    v-model="inpTxt.val_28_2"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1" style="font-size: 0.98rem;">&#41;</span>
                            <span class="" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_28_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_28_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">판단근거</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row style="padding-top: 8px; padding-bottom: 7px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="30" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'MajorDssState',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '21' && itm.valCd == '4')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '22' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '23' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '24' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '25' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '26' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '27' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '28' && itm.valCd == '2')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '28' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt.val_99_1 = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oObj = {}
                oObj.examPk = 0
                oObj.depth2 = '2'
                oObj.depth3 = '99'
                oObj.valCd = '1'
                oObj.val = ''
                oObj.val2 = this.inpTxt.val_99_1
                finArr.push(oObj)
   
                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '2'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '21' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '22' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '23' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '24' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '25' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '26' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '27' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '28' && oObj.valCd == '2')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '28' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '21' && oObj.valCd == '4')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '28' && oObj.valCd == '2')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '28' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 기타, 판단근거
        inpTxt: {
            val_21_4: '', val_22_9: '', val_23_9: '', val_24_9: '', val_25_9: '', val_26_9: '', val_27_9: '', val_28_2: '',
            val_28_9: '', val_99_1: ''
        },
        // 
        inpRadio: {  },
        // 만성질환 (d3: 21) - cd1: 당뇨, cd2: 고혈압, cd3: 만성호흡기질환, cd4: 암
        // 순환기계 (d3: 22) - cd1: 뇌경색, cd2: 뇌출혈, cd3: 협심증, cd4: 심근경색증, cd5: 기타
        // 신경계 (d3: 23) - cd1: 치매, cd2: 파킨슨병, cd3: 간질, cd4: 기타
        // 근골격계 (d3: 24) - cd1: 관절염, cd2: 요통,좌골통, cd3: 골정등후유증, cd4: 기타
        // 정신,행동장애 (d3: 25) - cd1: 우울증, cd2: 수면장애, cd3: 정신질환, cd4: 기타
        // 호흡기계 (d3: 26) - cd1: 호흡곤란, cd2: 결핵, cd3: 기타
        // 만성신장질환 (d3: 27) - cd1: 만성신부전증, cd2: 복막투석, cd3: 혈액투석, cd4: 기타
        // 기타질환 (d3: 28) - cd1: 알레르기, cd2: 식품, cd3: 기타
        inpCheck: {
            val_21_1: '2', val_21_2: '2', val_21_3: '2', val_21_4: '2',
            val_22_1: '2', val_22_2: '2', val_22_3: '2', val_22_4: '2', val_22_9: '2',
            val_23_1: '2', val_23_2: '2', val_23_3: '2', val_23_9: '2',
            val_24_1: '2', val_24_2: '2', val_24_3: '2', val_24_9: '2',
            val_25_1: '2', val_25_2: '2', val_25_3: '2', val_25_9: '2',
            val_26_1: '2', val_26_2: '2', val_26_9: '2',
            val_27_1: '2', val_27_2: '2', val_27_3: '2', val_27_9: '2',
            val_28_1: '2', val_28_2: '2', val_28_9: '2',
        },
    }),
};
</script>