<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">그룹별 프로그램 내역</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>       
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기본정보</span>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">수급자 그룹</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ grpInf.bnfcrgrNm }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">프로그램 유형</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid pb-1" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ grpInf.idxCdNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-1" style="padding-top: 3px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">설명</span>
            </v-col>
            <v-col class="overflow-text-hidden px-3 pb-1" style="padding-top: 3px;" cols="10">
                <span class="fontOneRem">{{ grpInf.bnfcrgrDesc }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">그룹별 내역</span>
        </v-row>
        <v-divider :class="[bnfcrgrPrgList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01', bnfcrgrPrgList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">설정일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="5">
                <span class="font-weight-medium">프로그램</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                <span class="font-weight-medium">비고</span>
            </v-col>
            <v-col class="txtAlgnMid pt-1" cols="1">
            </v-col>
        </v-row>
        <v-divider :class="[bnfcrgrPrgList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="bnfcrgrPrgList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', bnfcrgrPrgList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in bnfcrgrPrgList" :key="i"
                no-gutters>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontOneRem">{{ itm.wrtDtDot }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="5">
                    <span class="fontOneRem">{{ itm.prgNms }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="4">
                    <span class="fontOneRem">{{ itm.rmrks }}</span>
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="vwRow(itm)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                            </template>
                            <span>상세조회</span>
                        </v-tooltip>
                    </v-btn>                    
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="rmRow(i)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>내역 삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 수급자별 그룹내역이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-dialog v-model="dialog[0]" max-width="800">    
            <BnfcrPrgmLogViw v-if="dialog[0]"
                :gInf="grpInf"
                :sltInf= "sltItm"
                @isClose="dialog.splice(0, 1, false)"></BnfcrPrgmLogViw>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import BnfcrPrgmLogViw from './BnfcrPrgmLogViw.vue';                // 그룹별 프로그램 상세조회

export default {
    name: 'BnfcrPrgmLogMdl',
    
    components: {
        BnfcrPrgmLogViw
    },

    props : { 
        grpInf: { type: Object, default: new Object() },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getBnfcrgrPrgList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                let chc = this.bnfcrgrPrgList.findIndex( v => v.rmPosn == true )
                if(chc > -1)
                    this.rmBnfcrGrpPrg(this.bnfcrgrPrgList[chc])

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
           
                this.runAble = '0'
            }
        }
    },
        
    methods: {
        //팝업 닫기
        onClose: function () {
            this.$emit('isClose')
        },    
        
        // 수급자 그룹별 프로그램관리 조회
        getBnfcrgrPrgList: function (){
            this.bnfcrgrPrgList.splice(0)

            let obj = {}
            obj.prgBnfcrgrInfPk = this.grpInf.prgBnfcrgrInfPk

            http.post('prgrm/selBnfcrgrPrgAggList', obj)
                .then((response) => ( this.BnfcrgrPrgListRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selBnfcrgrPrgAggList : ' + error))
        },

        BnfcrgrPrgListRst:function(res){
            if(res.statusCode == '200') {
                res.result.list.forEach(itm => {
                    itm.wrtDtDot = this.$moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                });
                this.bnfcrgrPrgList = res.result.list
            }

        },

         // 그룹별 프로그램 단건 삭제
         rmBnfcrGrpPrg: function (obj){
            http.post('prgrm/delBnfcrgrPrg', obj)
                .then((response) => ( this.grpPrgRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/delBnfcrgrPrg : ' + error))
        },

        grpPrgRst: function (res){
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200){                
                this.getBnfcrgrPrgList()
            }
            else {
                obj.tmr = 5000
                obj.type = 'error'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 그룹별 프로그램 로우 상세 이벤트
        vwRow:function(obj){            
            this.sltItm = Object.assign({}, obj)
            this.dialog.splice(0, 1, true)
        },

        // 그룹별 프로그램 로우 삭제 이벤트
        rmRow: function (idx) {
            this.bnfcrgrPrgList[idx].rmPosn = true
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = this.bnfcrgrPrgList[idx].prgNms
            obj.cntnt = ['프로그램을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });
        },


    },

    data: () => ({        
        runAble         : '',
        bnfcrgrPrgList  : [],
        sltItm          : {},
        dialog          : [false],
    }),
};
</script>