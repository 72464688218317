<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">수급자별 그룹 내역</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 기본정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="tableBR px-4 py-1" cols="4">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span v-if="bscsInf.age != ''" class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">입소일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="4">
                <span class="mr-2">{{ bscsInf.entDt }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">주요질환</span>
            </v-col>
            <v-col class="overflow-text-hidden px-4 py-1" cols="10">
                <span class="mr-2">{{ bscsInf.majorDsss }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자별 그룹내역 목록</span>
        </v-row>
        <v-divider :class="[bnfcrAggList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01', bnfcrAggList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">작성일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">작성자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                <span class="font-weight-medium">프로그램 그룹</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">비고</span>
            </v-col>
            <v-col class="txtAlgnMid pt-1" cols="1">

            </v-col>
        </v-row>
        <v-divider :class="[bnfcrAggList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="bnfcrAggList.length > 0" class="overflow-y-auto" style="width: 100%; height: 161px;">
            <v-row
                :class="['tableBB', bnfcrAggList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in bnfcrAggList" :key="i"
                no-gutters>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontOneRem">{{ itm.wrtDtDot }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontOneRem">{{ itm.wrtr }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="4">
                    <span class="fontOneRem">{{ itm.bnfcrgrNms }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="3">
                    <span class="fontOneRem">{{ itm.rmrks }}</span>
                </v-col>
                <v-col class="txtAlgnMid" style="padding-top: 2px;" cols="1">
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="rmRow(i)" :disabled="delBtn"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>내역 삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 수급자별 그룹내역이 없습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'BnfcrGrpLogMdl',
    
    components: {
        
    },

    props : { 
        prtCd40: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                let chc = this.bnfcrAggList.findIndex( v => v.rmPosn == true )
                if(chc > -1)
                    this.rmBnfcrGrp(this.bnfcrAggList[chc])

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
           
                this.runAble = '0'
            }
        }
    },
        
    methods: {
        // 수급자별 수급자 그룹내역 다건 조회
        getBnfcrAggList: function (mbr){
            this.bnfcrAggList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr

            http.post('prgrm/selBnfcrGrBnfcrAggList', obj)
                .then((response) => ( this.bnfcrAggListRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selBnfcrGrBnfcrAggList : ' + error))
        },

        bnfcrAggListRst: function (res){
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    itm.wrtDtDot = this.$moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD')
                });
                this.bnfcrAggList = res.result.list
            }
        },

        // 수급자별 수급자 그룹내역 다건 조회
        rmBnfcrGrp: function (obj){
            this.delBtn = true

            http.post('prgrm/delBnfcrGrBnfcrs', obj)
                .then((response) => ( this.bnfcrGrRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/delBnfcrGrBnfcrs : ' + error))
        },

        bnfcrGrRst: function (res){
            let obj = {}
            obj.actv = true             // 경고 노출여부
            obj.tmr = 2500              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'           // 경고 유형 (success, info, warning, error)
            obj.title = res.message     // 경고 타이틀
            obj.cntnt = []              // 경고 내용 (arr만 출력됨!)

            if (res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.mdlOff()
            }
            else {
                obj.tmr = 5000
                obj.type = 'error'
                this.delBtn = false
            }

            this.$store.commit('setWrnnInf', obj)
        },

       // 기본정보 적용
       aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.entDt = obj.entDt
            this.bscsInf.majorDsss = obj.majorDsss

            this.getBnfcrAggList(this.$store.getters.getBnfcrState.bnmmbr)
        },

        // 수급자별 그룹내역 로우 삭제 이벤트
        rmRow: function (idx) {
            this.bnfcrAggList[idx].rmPosn = true
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = this.bnfcrAggList[idx].bnfcrgrNms
            obj.cntnt = ['수급자별 그룹내역을 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });
        },
    },

    data: () => ({
        delBtn: false,
        bnfcrAggList: [],
        bscsInf: { bnMmbrPk: -1, name: '', gndr: '', age: '', entDt: '', majorDsss: '' },
    }),
};
</script>