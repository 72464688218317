<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-row class="pa-0" no-gutters>
            <v-col class="pr-4" cols="7">
                <v-row no-gutters>
                    <v-icon class="" style="margin-left: -7px;" color="black" size="24">mdi-chevron-right</v-icon>
                    <span class="fontOneDotTwoRem font-weight-medium ml-2">수급자 및 보호자 개별 욕구</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                        <div class="txtAlgnMid" style="width: 100%;">
                            <span class="font-weight-medium">일상생활</span>
                        </div>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_1" :ripple="false"
                                true-value="1" false-value="2" label="개인위생 (세수, 구강청결, 몸씻기 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_2" :ripple="false"
                                true-value="1" false-value="2" label="식사하기 (식사준비, 식사도움)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_3" :ripple="false"
                                true-value="1" false-value="2" label="화장실이용하기 (이동변기, 기저귀 교환 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_4" :ripple="false"
                                true-value="1" false-value="2" label="이동도움 (부축, 보행기, 휠체어 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_5" :ripple="false"
                                true-value="1" false-value="2" label="산책동행"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_6" :ripple="false"
                                true-value="1" false-value="2" label="병원진료 동행"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-4" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_7" :ripple="false"
                                true-value="1" false-value="2" label="관공서 동행"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_8" :ripple="false"
                                true-value="1" false-value="2" label="여가활동 동행"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-1" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_9" :ripple="false"
                                true-value="1" false-value="2" label="청소·주변정돈"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_10" :ripple="false"
                                true-value="1" false-value="2" label="세탁"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_11" :ripple="false"
                                true-value="1" false-value="2" label="장보기"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_1_12" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_1_12"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                        <div class="txtAlgnMid" style="width: 100%;">
                            <span class="font-weight-medium">기능회복훈련</span>
                        </div>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_1" :ripple="false"
                                true-value="1" false-value="2" label="신체기능훈련 (연하훈련, 관절운동, 근력운동, 팔운동, 손가락운동 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_2" :ripple="false"
                                true-value="1" false-value="2" label="기본동작훈련 (체위변경, 일어나앉기, 일어서기, 서있기, 옮겨앉기, 보행 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_3" :ripple="false"
                                true-value="1" false-value="2" label="일상생활 동작훈련 (식사동작, 양치동작, 옷 갈아입기 동작, 화장실 사용 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_4" :ripple="false"
                                true-value="1" false-value="2" label="인지기능 향상 프로그램 (회상훈련, 감각활동, 작업치료 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_5" :ripple="false"
                                true-value="1" false-value="2" label="사회적응훈련 (대중교통이용, 문화체험, 종교활동 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_6" :ripple="false"
                                true-value="1" false-value="2" label="신체기능향상 (가동범위강화,소근육활성 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_7" :ripple="false"
                                true-value="1" false-value="2" label="인지기능향상 (회상훈련, 감각활동, 작업치료 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_8" :ripple="false"
                                true-value="1" false-value="2" label="여가·정서 (개인활동, 취미활동, 요리활동, 산책 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_2_9" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_2_9"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="2">
                        <div class="txtAlgnMid" style="width: 100%;">
                            <span class="font-weight-medium">정서지원</span>
                        </div>
                    </v-col>
                    <v-col class="pa-2" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_1" :ripple="false"
                                true-value="1" false-value="2" label="말벗 서비스"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 mb-1 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_2" :ripple="false"
                                true-value="1" false-value="2" label="여가·정서 프로그램 (개인활동, 취미활동, 요리활동, 산책 등)"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_3_3" :ripple="false"
                                true-value="1" false-value="2" label="기타"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                            <span class="mr-1" style="font-size: 0.98rem;">&#40;</span>
                            <span class="d-inline-block" style="width: 410px;">
                                <v-text-field
                                    v-model="inpTxt.val_3_3"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="ml-1" style="font-size: 0.98rem;">&#41;</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="5">
                <v-row class="px-0 pt-1" no-gutters>
                    <v-spacer></v-spacer>
                    <v-icon color="black" size="16">mdi-checkbox-marked-outline</v-icon>
                    <span class="fontOneRem ml-1 mr-3">표기</span>
                    <v-icon color="black" size="16">mdi-square-outline</v-icon>
                    <span class="fontOneRem ml-1">미표기</span>
                </v-row>
                <v-divider class="mt-2" color="black"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR" cols="3">
                        <div class="txtAlgnMid" style="width: 100%; line-height: 1.2;">
                            <span class="font-weight-medium">가족수발 경감</span>
                        </div>
                    </v-col>
                    <v-col class="pa-2" cols="9">
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_4_1" :ripple="false"
                                true-value="1" false-value="2" label="신체적 부양부담 완화"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_4_2" :ripple="false"
                                true-value="1" false-value="2" label="정신적 부양부담 완화"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                        <v-row class="pa-0 align-center" no-gutters>
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                                v-model="inpCheck.val_4_3" :ripple="false"
                                true-value="1" false-value="2" label="경제적 부양부담 완화"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
                    <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6">판단근거</span>
                </v-row>
                <v-divider class="" color="black"></v-divider>
                <v-row style="padding-top: 3px; padding-bottom: 2px;" no-gutters>
                    <v-textarea
                        class="rmrksArea" rows="23" 
                        v-model="inpTxt.val_99_1"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-row>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'IndvNeds',
    
    components: {
        
    },

    props : { 
        recList: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
       
    },

    watch: {
        recList: {
            deep: true,
            handler: function (value){
                if(value.length > 0)
                    this.prcRecAbst(value)
            },
        },
    },
        
    methods: {
        // 급여제공계획 레코드 목록에서 값 추출 
        prcRecAbst: function (arr){
            arr.forEach(itm => {
                this.inpCheck['val_'+itm.depth3+'_'+itm.valCd] = itm.val

                if(itm.depth3 == '1' && itm.valCd == '12')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '2' && itm.valCd == '9')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '3' && itm.valCd == '3')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
                else if(itm.depth3 == '99' && itm.valCd == '1')
                    this.inpTxt['val_'+itm.depth3+'_'+itm.valCd] = itm.val2
            });
        },
        // 급여제공계획 레코드 목록으로 입력 값 가공
        valThrm: function (){
            let finArr = []

            // 신규작성
            if(this.recList.length == 0){
                let oTxt = {}
                oTxt.examPk = 0
                oTxt.depth2 = '6'
                oTxt.depth3 = '99'
                oTxt.valCd = '1'
                oTxt.val = ''
                oTxt.val2 = this.inpTxt.val_99_1
                finArr.push(oTxt)
   
                Object.keys(this.inpCheck).forEach(itm => {
                    let keyArr = itm?.split('_')
                    let oObj = {}
                    oObj.examPk = 0
                    oObj.depth2 = '6'
                    oObj.depth3 = keyArr[1]
                    oObj.valCd = keyArr[2]
                    oObj.val = this.inpCheck[itm]
                    oObj.val2 = ''

                    if(oObj.depth3 == '1' && oObj.valCd == '12')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '2' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '3' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt[itm]
                    else if(oObj.depth3 == '99' && oObj.valCd == '1')
                        oObj.val2 = this.inpTxt[itm]

                    finArr.push(oObj)
                });
            }
            // 기존 데이터 수정
            else{
                this.recList.forEach(itm => {
                    let oObj = Object.assign({}, itm)
                    oObj.val = this.inpCheck['val_' + oObj.depth3 + '_' + oObj.valCd]

                    if(oObj.depth3 == '99'){
                        oObj.val = ''
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    }
                    else if(oObj.depth3 == '1' && oObj.valCd == '12')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '2' && oObj.valCd == '9')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]
                    else if(oObj.depth3 == '3' && oObj.valCd == '3')
                        oObj.val2 = this.inpTxt['val_' + oObj.depth3 + '_' + oObj.valCd]

                    finArr.push(oObj)
                });
            }

            return finArr
        },

        clrForm: function (){
            Object.keys(this.inpTxt).forEach(itm => {
                this.inpTxt[itm] = ''
            });

            Object.keys(this.inpCheck).forEach(itm => {
                this.inpCheck[itm] = '2'
            });
        },
    },

    data: () => ({
        // 일상생활-기타, 기능회복훈련-여가, 정서지원-기타, 판단근거
        inpTxt: {
            val_1_12: '', val_2_9: '', val_3_3: '', val_99_1: ''
        },
        inpRadio: {  },
        // 일상생활 (d3: 1) - cd1: 개인위생(세수,구강청결,몸씻기 등), cd2: 식사하기(식사준비, 식사도움),
        //                  cd3: 화장실이용하기(이동변기, 기저귀 교환 등), cd4: 이동도움(부축, 보행기, 휠체어 등),
        //                  cd5: 산책 동행, cd6: 병원진료 동행, cd7: 관공서 동행, cd8: 여가활동 동행, cd9: 청소〮주변정돈,
        //                  cd10: 세탁, cd11: 장보기, cd12: 기타
        // 기능회복훈련 (d3: 2) - cd1: 신체기능훈련, cd2: 기본동작훈련, cd3: 일상생활동작훈련, cd4: 인지기능향상프로그램,
        //                      cd5: 사회적응훈련, cd6: 신체기능향상, cd7: 인지기능향상, cd8: 여가·정서, cd9: 기타
        // 정서지원 (d3: 3) - cd1: 말벗 서비스, cd2: 여가정서프로그램, cd3: 기타
        // 가족수발경감 (d3: 4) - cd1: 신체적 부양부담 완화, cd2: 정신적 부양부담 완화, cd3: 경제적 부양부담 완화
        inpCheck: {
            val_1_1: '2', val_1_2: '2', val_1_3: '2', val_1_4: '2', val_1_5: '2',
            val_1_6: '2', val_1_7: '2', val_1_8: '2', val_1_9: '2', val_1_10: '2',
            val_1_11: '2', val_1_12: '2',
            val_2_1: '2', val_2_2: '2', val_2_3: '2', val_2_4: '2', val_2_5: '2',
            val_2_6: '2', val_2_7: '2', val_2_8: '2', val_2_9: '2',
            val_3_1: '2', val_3_2: '2', val_3_3: '2',
            val_4_1: '2', val_4_2: '2', val_4_3: '2',
        },
    }),
};
</script>