<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">수급자 선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose()" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 mr-1 align-center" no-gutters>
            <span class="d-inline-block" style="width: 106px; height: 34px;">
                <v-text-field
                    v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                    class="txt-Theme" height="34" label="이름조회"
                    background-color="white" prepend-inner-icon="mdi-magnify"
                    single-line outlined dense filled hide-details>
                </v-text-field>
            </span>
            <v-spacer></v-spacer>
            <v-checkbox
                class="dftChcBox-Theme pt-0" style="margin-top: 2px !important;"
                v-model="chcLv" :ripple="false"
                true-value="1" false-value="2" label="퇴소자 조회"
                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                hide-details>
            </v-checkbox>
        </v-row>
        <v-divider class="mt-2 mr-1" color="#ddd"></v-divider>
        <v-data-table
            class="greyE00-hdr-tbl mr-1" height="447" locale="ko"
            :headers="tblHdr" :items="bnfcrList" item-key="rn"
            :loading="isLoad" :loading-text="loadMsg"
            :search="dmyTxt" :custom-filter="srchFltr"
            fixed-header disable-pagination hide-default-header hide-default-footer dense>
            <template v-slot:header="{ props }" >
                <thead>
                    <tr>
                        <th v-for="(itm, j) in props.headers" :key="j" :style="itm.style">
                            <span>{{ itm.text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item }'>
                <tr class="crsShp-Pointer" @click="thrObj(item)">
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.rn }}</span>
                    </td>
                    <td class="overflow-text-hidden txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.name }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.age }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <v-img
                            v-if="item.gndr == 'F'"
                            class="d-inline-block" style="vertical-align: middle;"
                            src="../../assets/icon/ic-female.svg" width="24">
                        </v-img>
                        <v-img
                            v-else-if="item.gndr == 'M'"
                            class="d-inline-block" style="vertical-align: middle;"
                            src="../../assets/icon/ic-male.svg" width="24">
                        </v-img>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.entStcdNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                            {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                        </span>
                    </td>
                    <td class="txtAlgnMid tableBR px-1 py-2">
                        <span class="fontOneRem">{{ item.entStcdDot }}</span>
                    </td>
                    <td class="txtAlgnMid px-1 py-2">
                        <span class="fontOneRem">{{ item.lvnRmNm }}</span>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'BnfcrSelector',

    props : {

    },
            
    components: {
    
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['65'])
            this.getBnfcrList(null)
        })
    },

    computed: {
    
    },

    watch: {
        vCd65: {
            deep: true,
            handler: function (value){
                if(this.bnfcrList.length > 0){
                    let arr = Object.assign([], this.bnfcrList)
                    arr.forEach(itm => {
                        let chc = value.findIndex( v => v.valcd == itm.entStcd )
                        if(chc > -1)
                            itm.entStcdNm = value[chc].valcdnm
                    });
                    this.bnfcrList = arr
                }
            },
        },

        'chcLv': function (){
            let chc = null
            if(this.chcLv == '1')
                chc = '4'
            this.getBnfcrList(chc)
        },
    },
        
    methods: {
        // 공통 코드 다중 조회
        getCode: function (arr) {
            let obj = {}
            obj.valClcdS = arr
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.vCd65 = response.data.vCd65 ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        // 수급자 목록 다건 조회
        getBnfcrList: function (cd) {
            this.isLoad = true
            this.bnfcrList.splice(0)

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.entStcd = cd
            obj.inqYmdStart = null
            obj.inqYmdEnd = null

            http.post('bnfcr/selBnfcrList', obj)
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },

        getBnfcrListAfter: function (res){
            if(res.length > 0){
                res.forEach(items => {
                    this.vCd65.forEach(cd => {
                        if(items.entStcd == cd.valcd) items.entStcdNm = cd.valcdnm
                    });
                    items.entStcdDot = this.$moment(items.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                });
                this.bnfcrList = res
            }
            this.isLoad = false
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            return field1
        },

        thrObj: function (obj) {
            this.$emit('outBnfcr', obj)
            this.onClose()
        },

        onClose: function () {
            this.$emit('isClose', '.')
        },
    },
    
    data: () => ({
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        chcLv: '2',
        srchTxt: '',                            // 테이블 이름 검색 필드
        dmyTxt: '',                             // 테이블 반응 더미(검색용)
        bnfcrList: [],
        vCd65: [],
        tblHdr: [
            { text: '연번', value: 'rn', sortable: true, filterable: false, style: 'width: 70px;' },
            { text: '수급자명', value: 'name', sortable: true, filterable: true, style: 'width: 180px;' },
            { text: '나이', value: 'age', sortable: true, filterable: false, style: 'width: 70px;' },
            { text: '성별', value: 'gndr', sortable: true, filterable: false, style: 'width: 70px;' },
            { text: '현황', value: 'entStcdNm', sortable: true, filterable: true, style: 'width: 90px;' },
            { text: '등급', value: 'acknwRtng', sortable: true, filterable: true, style: 'width: 70px;' },
            { text: '입소일', value: 'acknwRtng', sortable: true, filterable: true, style: 'width: 100px;' },
            { text: '생활실', value: 'acknwRtng', sortable: true, filterable: true, style: 'width: 80px;' },
        ],
    }),
};
</script>