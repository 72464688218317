<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4" class="d-flex align-center">
                <span class="text-h4 font-weight-bold">연차&nbsp;이력</span>
                <span class="pl-1">
                    <v-btn
                        width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 24)" icon>
                        <v-icon size="20">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">근무년차</span>
            </v-col>
            <v-col class="d-flex align-center py-1 px-2 tableBL" cols="8">
                <span class="d-inline-block" style="width: 100px;">
                    <v-select
                        v-model="wrkMnthNm"
                        :disabled="mode == 'emp' ? false : true" :key="forceRender" :items="wrkMnthCode"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="mode=='emp'"
                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                    @click="menu = true" :disabled="wrkMnthCode.length > 0 ? false : true"
                    min-width="30" height="25" small outlined>
                    <v-icon size="20">mdi-text-box-edit-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">차감연차</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-divider :class="['mt-3', anlList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', anlList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                <span class="black--text font-weight-medium">사용일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                <span class="black--text font-weight-medium">종류</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="5">
                <span class="black--text font-weight-medium">비고</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="1">

            </v-col>
        </v-row>
        <v-divider :class="[anlList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 320px;">
            <div v-if="anlList.length == 0">
                <v-row class="pa-0 tableBB" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1" cols="12">조회된 내역이 없습니다.</v-col>
                </v-row>
            </div>
            <div class="" v-else>
                <v-row class="pa-0 tableBB" v-for="(list, x) in anlList" :key="x" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text">{{ list.anlDt }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text">{{ list.vctnTypNm }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="5">
                        <span class="black--text">{{ list.rmrks }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="1">
                        <span class="black--text font-weight-medium" v-if="list.useClcd == '2'">
                            <v-btn
                                class="" color="grey006" height="26"
                                @click="rmMdl = true, sltEmpAnlDdcPk = list.empAnlDdcPk"
                                :disabled="mode == 'emp' ? false : true"
                                icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog v-model="menu" max-width="700" content-class="round" persistent>
            <AnlRgstMdl @modalClose="menu = false"></AnlRgstMdl>
        </v-dialog>
        <v-dialog v-model="rmMdl" max-width="500">
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selEmpUseAnlList, delEmpAnlDdc } from '../../api/emp.js';

import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import AnlRgstMdl from './AnlRgstMdl.vue';

export default {
    name: 'AnlHstrMdl',

    props : {
        sltEmpPk: { type: Number, default: -1 },
        wrtMnt: { type: Number, default: 0 },
        mode: { type: String, default: 'emp' },
    },
        
    components: {
        btnModalDelete,
        AnlRgstMdl,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.wrkMnth = this.wrtMnt
        })
    },

    computed: {
        // getSltEmpPk: function() {
        //     return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        // },
    },

    watch:{
        'sltEmpPk': function() {
            this.clearForm()
            if(this.sltEmpPk != -1)
                this.getEmpAnlUseList(this.sltEmpPk)
        },
        // getSltEmpPk: function(value) {
        //     this.clearForm()
        //     if(value != -1)
        //         this.getEmpAnlUseList(value)

        // },
        'wrkMnth': function() {
            if(this.wrkMnth > 1){
                let yearCnt = Math.floor(this.wrkMnth / 12)
                let rmnCnt = this.wrkMnth % 12

                this.wrkMnthCode.push({ valcdnm: '전체', valcd: 0 })

                if(yearCnt == 0){
                    for (let index = 0; index <= rmnCnt; index++) {
                        if(index > 1){
                            this.wrkMnthCode.push({ valcdnm: index + '개월', valcd: index })
                            this.ddctMnthCode.push({ valcdnm: index + '개월', valcd: index })
                        }
                    }
                }
                else if(yearCnt > 0){
                    for (let index = 2; index < 12; index++) {
                        this.wrkMnthCode.push({ valcdnm: index + '개월', valcd: index })
                        this.ddctMnthCode.push({ valcdnm: index + '개월', valcd: index })
                    }
                }

                for (let index = 0; index <= yearCnt; index++) {
                    if(index > 0){
                        this.wrkMnthCode.push({ valcdnm: index + '년차', valcd: index*12 })
                        this.ddctMnthCode.push({ valcdnm: index + '년차', valcd: index*12 })
                    }
                }
            }
        },
        'wrtMnt': function() {
            this.wrkMnth = this.wrtMnt
        },
    },
    
    methods: {
        getEmpAnlUseList: function (pk) {
            selEmpUseAnlList(this.$store.getters.getFcltyNum, pk, null)
                .then((response) => ( this.getEmpAnlUseListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpUseAnlList : ' + error))
        },
        rmEmpAnlDdc: function (pk) {
            delEmpAnlDdc(pk, this.$store.getters.getFcltyNum, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmpAnlDdc : ' + error))
        },
        getEmpAnlUseListAfter: function (res) {
            res.forEach(item => {
                item.anlDt = item.anlDt?.beforeDateFormatDot()
                // if(this.wrkMnth == '')
                //     this.wrkMnth = Math.floor(item.wrkMnthCalc / 12)
            });
            this.anlList = res
            this.orgAnlList = Object.assign([], res)
        },
        asyncRst: function (res) {
            if(res.statusCode == 200){
                this.getEmpAnlUseList(this.sltEmpPk)

                this.$emit('rnwlAnl', 'rm')
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        srchAnlList: function () {
            let nowDt = new Date().getFullYear()

            if(this.wrkMnthNm == 0)
                this.anlList = Object.assign([], this.orgAnlList)
            else{
                let tmp = nowDt - (this.wrkMnthCode.length - 1) + this.wrkMnthNm
                this.anlList = this.orgAnlList.filter(v => v.anlDt.includes(tmp))
            }
        },
        delModalAct: function (res) {
            if(res == 9990){    //OK
                if(this.sltEmpAnlDdcPk != -1)
                    this.rmEmpAnlDdc(this.sltEmpAnlDdcPk)

                this.rmMdl = false
            }
            else if(res == 7)   //Cancel
                this.rmMdl = false
        },
        clearForm: function () {
            this.anlList.splice(0)
            this.orgAnlList.splice(0)
            this.wrkMnthCode.splice(0)
            this.ddctMnthCode.splice(0)
            this.wrkMnth = ''
            this.wrkMnthNm = 0
            this.forceRender += 1
        },
        onClose: function () {
            this.$emit('modalClose', '0')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: false,
        rmMdl: false,
        delTitle: '연차 차감 내역',
        sltEmpAnlDdcPk: -1,
        wrkMnth: '',
        wrkMnthNm: 0,
        wrkMnthCode: [],
        ddctMnthCode: [],
        anlList: [],
        orgAnlList: [],
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>