<template>
    <v-sheet class="rounded-lg rounded-l-0 px-3" style="position: relative;" min-width="1100" max-width="1100">
        <div class="d-flex">
            <expandWeekOnlyPicker @nowWeek = "getWeek" class="mt-2"></expandWeekOnlyPicker>
            <v-spacer></v-spacer>
            <div class="d-flex justify-end pl-1 pr-3 pt-2">
                <span v-if="progress">
                    <v-progress-circular            
                    :size="25"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </span>
                <span v-if="!progress">
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                            :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                    </span>
                </span>
                <v-btn
                    class="font-weight-bold ml-2" height="30" color="grey006"
                    @click="prtPyrprRcrd()" :disabled="bnMmbrPk == -1"
                    rounded outlined samll>
                    <v-icon small>mdi-printer-outline</v-icon>
                    <span class="fontOneRem ml-1">급여제공기록지 출력</span>
                </v-btn>
            </div>
        </div>
        <v-divider class="my-2"></v-divider>
        <div class="overflow-y-auto" style="height:761px;">

            <basicInfo ref="basicInfo" :bnMmbrPk="bnMmbrPk"></basicInfo>        
            <div class="pa-0 mt-1 d-flex" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:8%">
                    <span class="black--text font-weight-medium">배설상태</span>
                </div>
                <div class="pa-1 ma-0 d-flex justify-center align-center" style="width:32%;border-left: 1px solid #dbdbdb;">
                    {{blnftItems.exrtSt}}
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:8%;border-left: 1px solid #dbdbdb;">
                    <span class="black--text font-weight-medium">보행</span>
                </div>
                <div class="pa-1 ma-0 d-flex justify-center align-center" style="width:12%;border-left: 1px solid #dbdbdb;">
                    {{blnftItems.actSt}}
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%;border-left: 1px solid #dbdbdb;">
                    <span class="black--text font-weight-medium">낙상위험도</span>
                </div>
                <div class="pa-1 ma-0 d-flex justify-center align-center" style="width:10%;border-left: 1px solid #dbdbdb;">
                    {{blnftItems.falRsk}}
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%;border-left: 1px solid #dbdbdb;">
                    <span class="black--text font-weight-medium">욕창위험도</span>
                </div>
                <div class="pa-1 ma-0 d-flex justify-center align-center" style="width:10%;border-left: 1px solid #dbdbdb;">
                    {{blnftItems.bdSre}}
                </div>
            </div>
            <div style="position:relative">
                <div style="position:absolute;top:4px;left:500px;z-index:3">
                    <v-btn
                        class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 18)" icon>
                        <v-icon size="20" style="padding-top: 3px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </div>
                <v-tabs
                    v-model="tab" class="tab-dft-Theme mt-2" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">            
                    <v-tab-item eager transition="fade-transition">
                        <tab1 ref="tab1"                                  
                            @dialog="showDialog"    
                            @careBlnftTrigger="careBlnftTrigger"
                            @msgTrigger="msgTrigger"
                        ></tab1>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <tab2  ref="tab2"                     
                            @careBlnftTrigger="careBlnftTrigger"
                            @msgTrigger="msgTrigger"
                        ></tab2>
                    </v-tab-item>            
                </v-tabs-items>
            </div>
        </div>


        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="1200" min-width="1200">                
                <BathObsrvRcrd 
                    v-if="dialog[0]"
                    ref="bathObsrvRcrd"
                    :bathObsrvRcrdPk="bathObsrvRcrdPk"
                    :bnMmbrPk="$parent.$parent.sltBnMmBrPk"
                    :inqYMD="inqYMD"
                    :empWrtr="empWrtr"
                    :empWrtrpk="empWrtrpk"
                    @modalEvent="modalEvent"></BathObsrvRcrd>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" persistent max-width="1350" min-width="1350">                
                <MealSrvRcrd 
                    v-if="dialog[1]"
                    ref="mealSrvRcrd"
                    :mealSrvRcrdPk="mealSrvRcrdPk"
                    :bnMmbrPk="$parent.$parent.sltBnMmBrPk"
                    :inqYMD="inqYMD"
                    :empWrtr="empWrtr"
                    :empWrtrpk="empWrtrpk"
                    @modalEvent="modalEvent"></MealSrvRcrd>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[2]" persistent max-width="1400" min-width="1400">                
                <ChgPosObsrvRcrd 
                    v-if="dialog[2]"
                    ref="chgPosObsrvRcrd"
                    :chgPosObsrvRcrdPk="chgPosObsrvRcrdPk"
                    :bnMmbrPk="$parent.$parent.sltBnMmBrPk"
                    :inqYMD="inqYMD"
                    :empWrtr="empWrtr"
                    :empWrtrpk="empWrtrpk"
                    @modalEvent="modalEvent"></ChgPosObsrvRcrd>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[3]" persistent max-width="1400" min-width="1400">                
                <TltUseRcrd 
                    v-if="dialog[3]"
                    ref="tltUseRcrd"
                    :tltUseRcrdPk="tltUseRcrdPk"
                    :bnMmbrPk="$parent.$parent.sltBnMmBrPk"
                    :inqYMD="inqYMD"
                    :empWrtr="empWrtr"
                    :empWrtrpk="empWrtrpk"
                    :exrtSt="blnftItems.exrtSt"
                    :cathYn="cathYn"
                    :dprYn="dprYn"
                    @modalEvent="modalEvent"></TltUseRcrd>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">            
            <v-dialog v-model="dialog[7]" persistent max-width="1600" min-width="1600">                
                <BnftSrvPlnComp 
                    v-if="dialog[7]"
                    ref="bnftSrvPln"
                    :bnMmbrPk="$parent.$parent.sltBnMmBrPk" 
                    :inqYYYY="bnftSrvPlnYear"
                    :btnDisabled="false"
                    :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                    @modalEvent="modalEvent"></BnftSrvPlnComp>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[4]" max-width="1400">    
                <cncntExcrtObsrvRcrdWrt 
                    ref="cncntRcrdWrt" 
                    :sltBnftOfrRecPk="sltBnftOfrRecPk"
                    :ymd="inqYMD"
                    :bnMmbrPk="bnMmbrPk"
                    @isClose="modalEvent(4)" 
                    @cncntExcrtTrigger="modalEvent(99)"
                ></cncntExcrtObsrvRcrdWrt>                  
            </v-dialog>   
        </div>              
    </v-sheet>
</template>

<script>
import { getMultiStcd,} from '../../api/index.js';
import { selBnfcrStInfo, selBnftOfrRecWeekInfo  } from '../../api/bnftrcrd.js';
import { selFcltyInitList  } from '../../api/opr.js';

import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';
import tab1 from '../../components/bnftrcrd/CareBlnftSrvRcrdTab1.vue';
import tab2 from '../../components/bnftrcrd/CareBlnftSrvRcrdTab2.vue';
import BathObsrvRcrd from '../../components/bnftrcrd/BathObsrvRcrd.vue';                    //몸씻기
import MealSrvRcrd from '../../components/bnftrcrd/MealSrvRcrd.vue';                        //식사하기
import ChgPosObsrvRcrd from '../../components/bnftrcrd/ChgPosObsrvRcrd.vue';                //체위변경
import TltUseRcrd  from '../../components/bnftrcrd/TltUseRcrd.vue';                         //화장실이용
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue';                       //급여제공계획
import cncntExcrtObsrvRcrdWrt from '../../components/bnftrcrd/CncntExcrtObsrvRcrdWrt.vue';  //집중배설

export default {
    name: 'CareBlnftSrvRcrd',
        
    components: {
        expandWeekOnlyPicker,
        basicInfo,
        tab1,
        tab2,
        BathObsrvRcrd,
        MealSrvRcrd,
        ChgPosObsrvRcrd,
        TltUseRcrd,
        BnftSrvPlnComp,
        cncntExcrtObsrvRcrdWrt,
    },

    props : {
        // sltBnMmBrPk : {type:Number, default:0},
    },

    created: function(){
        this.onCodeload()
        this.getFcltyInitList()
    },

    computed: {
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },    

    watch: {
        getBnmmbrPk:function(value){
            // let start  = performance.now()
            this.bnMmbrPk = value             
            this.gelBnfcrStInfo() 
            this.getBnftOfrRecWeekInfo()
            if(value > 0){
                this.filledBtn[0].disabled = false
                this.filledBtn[1].disabled = false
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
            }
            // let end  = performance.now()
            // console.log((end-start) + 'ms')
        },
        getSubEvt: function (bol) {
            if(bol && this.careCnfm){
                if(this.tab == 0)
                    this.$refs.tab1.onSave('1')
                else if(this.tab == 1)
                    this.$refs.tab2.onSave('1')

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.careCnfm = false
            }
        },

        getSubActv:function (bol){
            if(!bol && this.careCnfm){
                this.getBnftOfrRecWeekInfo()
                this.careCnfm = false
            }
        }

    },
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = -1
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk            
            // if(typeof this.sltBnMmBrPk == 'undefined')
            //     this.bnMmbrPk = -1
            // else
            //     this.bnMmbrPk = this.sltBnMmBrPk
            this.gelBnfcrStInfo()  
            this.getBnftOfrRecWeekInfo()          
            if(this.bnMmbrPk > 0){
                this.filledBtn[0].disabled = false
                this.filledBtn[1].disabled = false
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
            }
        })
    },
    
        
    methods: {
        getWeek : function(ws, we){
            this.startDay = ws
            this.endDay = we

            if(!this.dataCall) this.getBnftOfrRecWeekInfo()

            let entStcd = null

            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            this.$parent.$parent.getBnfcrList(entStcd, ws, we)
        },
        clearForm:function(){
        },
        getList:function(){
        },
        clearBlob:function(){
        },
        onCodeload:function(){
            getMultiStcd(['45'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping:function(res){
            this.vCd45.splice(0)
            this.vCd45 = res["vCd45"]
        },
        onClickBtn:function(text){
            if(text == "저장"){
                if(this.tab == 0) this.$refs.tab1.onSave()
                else if(this.tab == 1) this.$refs.tab2.onSave()
            }else if(text == "급여제공계획"){
                this.bnftSrvPlnYear =  Number(this.startDay.substr(0,4))
                this.bnftSrvPlnCmpPk = this.bnftOfrPlnHisPk
                this.dialog.splice(7,1,true)
            }
        },
        onTab:function(){           
        },
        //급여기본정보 조회
        gelBnfcrStInfo:function(){            
            let fcltyNum = this.$store.getters.getFcltyNum
            let bnmmbr = this.bnMmbrPk
            
            if(bnmmbr == -1){
                this.blnftItems = Object.assign({})
            }
            
            if(bnmmbr > 0){
                let state = {
                    bnMmbrPk : this.bnMmbrPk,
                    inqYYYY : this.startDay.substring(0,4),
                    inqYMD : this.startDay,
                }

                
                selBnfcrStInfo(fcltyNum, state)
                            .then((response) => ( this.gelBnfcrStInfoAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftoffer/selBnfcrStInfo : ' + error))
            }
        },
        gelBnfcrStInfoAfter:function(res){           
            this.blnftItems = res
            if(res.bnftOfrPlnHisPk != undefined && res.bnftOfrPlnHisPk !=null) this.bnftOfrPlnHisPk = Number(res.bnftOfrPlnHisPk)
            else this.bnftOfrPlnHisPk = 0
        },
        //급여제공기록 주간 정보 조회
        getBnftOfrRecWeekInfo:function(){
            
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.bnMmbrPk,
                    inqYmdStart : this.startDay,
                    inqYmdEnd : this.endDay,
                    clsfc1Cd:'301',
                    clsfc2Cd:'1',
                    clsfc3CdS:['1','2','3'],
                }

                this.dataCall = true
                this.loadable = true
                selBnftOfrRecWeekInfo(obj)
                            .then((response) => ( this.getBnftOfrRecWeekInfoAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecWeekInfo : ' + error))
        },
        getBnftOfrRecWeekInfoAfter:function(res){
            this.$refs.tab1.getBnftOfrRecWeekListAfter(res)
            this.$refs.tab2.getBnftOfrRecWeekListAfter(res)
            this.dataCall = false            
        },
        careBlnftTrigger:function(res){
            let obj = {}

            if(res.statusCode == 200){                                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
                this.getBnftOfrRecWeekInfo()
            }else if(res.statusCode == 205) {
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = res.message
                obj.cntnt = []

                if(res.messagesList != null && res.messagesList != undefined){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                obj.cntnt.push('등록된 기록을 삭제하고 저장하시겠습니까?')
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 저장하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.careCnfm = true
            }else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        msgTrigger:function(message){
            
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = message        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
        showDialog(key, ymd, hispk ,cathYn = '', dprYn = ''){   
            this.inqYMD = ymd

            if(key == 2){                
                this.chgPosObsrvRcrdPk = 1
                this.dialog.splice(key, 1, true)    
            }else{
                switch (key) {
                    case 0:
                        this.bathObsrvRcrdPk = 1                                
                        break;
                    case 1:
                        this.mealSrvRcrdPk = 1
                        break;
                    case 2:
                        this.chgPosObsrvRcrdPk = 1                                
                        break;
                    case 3:
                        this.tltUseRcrdPk = 1
                        this.cathYn = cathYn
                        this.dprYn = dprYn
                        break;
                    case 4:
                        this.sltBnftOfrRecPk = 1                                
                        break;
                    default:
                        break;
                }

                this.dialog.splice(key, 1, true)  
                // if(hispk > 0){
                    
                //     if(this.empWrtr == '' || this.empWrtr == null){
                //         let obj = {}
                //         obj.actv = true                 // 경고 노출여부
                //         obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                //         obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                //         obj.title = '작성자를 선택해주세요.'         // 경고 타이틀

                //         this.$store.commit('setWrnnInf', obj)
                //     }else{
                        
                //         switch (key) {
                //             case 0:
                //                 this.bathObsrvRcrdPk = 1                                
                //                 break;
                //             case 1:
                //                 this.mealSrvRcrdPk = 1
                //                 break;
                //             case 2:
                //                 this.chgPosObsrvRcrdPk = 1                                
                //                 break;
                //             case 3:
                //                 this.tltUseRcrdPk = 1
                //                 this.cathYn = cathYn
                //                 this.dprYn = dprYn
                //                 break;
                //             case 4:
                //                 this.sltBnftOfrRecPk = 1                                
                //                 break;
                //             default:
                //                 break;
                //         }

                //         this.dialog.splice(key, 1, true)  
                //     }
                // }else{
                //     let obj = {}
                //     obj.actv = true                 // 경고 노출여부
                //     obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                //     obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                //     obj.title = '작성자를 저장해주세요.'         // 경고 타이틀

                //     this.$store.commit('setWrnnInf', obj)
                // }
            }
        },
        modalEvent: function (event) { 
            if(event == 99){
                if(this.tab == 0){
                    this.getBnftOfrRecWeekInfo()
                }
            }else if(event == 7){
                this.bnftSrvPlnCmpPk = -1
                this.dialog.splice(event, 1, false)            
            }else{
                switch (event) {
                    case 0:
                        this.bathObsrvRcrdPk = -1
                        break;
                    case 1:
                        this.mealSrvRcrdPk = -1
                        break;
                    case 2:
                        this.chgPosObsrvRcrdPk = -1
                        break;
                    case 3:
                        this.tltUseRcrdPk = -1
                        this.cathYn = ''
                        this.dprYn = ''
                        break;
                    case 4:
                        this.sltBnftOfrRecPk = -1
                        break;
                    default:
                        break;
                }
                this.dialog.splice(event, 1, false)
            }      
        },
        prtPyrprRcrd: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYmdStart = this.startDay
            this.prtThrowObj.inqYmdEnd = this.endDay
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.prtThrowObj.prtCd = '14'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },    

        onDupSave:function(res){
            if(res == 9990){
                if(this.tab == 0) this.$refs.tab1.onSave('1')
                else if(this.tab == 1) this.$refs.tab2.onSave('1')
            }else if(res == 7){
                this.getBnftOfrRecWeekInfo()
            }
             this.dialogDup = false
        },
        getFcltyInitList:function(){           
            selFcltyInitList(this.$store.getters.getFcltyNum, ['4'])
                .then((response) => ( this.getFcltyInitListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selFcltyInitList : ' + error))
        },
        getFcltyInitListAfter:function(res){            
            res.forEach(el => {
                this.sysVal1 = el.itmVal1
                this.sysVal2 = el.itmVal2
            });
        }
    },
    
    data: () => ({
        careCnfm :false,
        dataCall:false,
        loadable: true,
        tab:0,
        areaWidth: 708,
        startDay:'',
        endDay:'',        
        inqYMD:'',
        bnMmbrPk:-1,
        empWrtr:'',                     //작성자
        empWrtrpk:0,                    //작성자 PK
        bathObsrvRcrdPk:-1,             //목욕급여기록 컴포넌트 고유번호
        mealSrvRcrdPk:-1,               //식사급여기록 컴포넌트 고유번호
        chgPosObsrvRcrdPk:-1,           //체위변경 관찰기록 컴포넌트 고유번호
        tltUseRcrdPk:-1,                //화장실이용기록 컴포넌트 고유번호
        bnftOfrPlnHisPk:0,
        bnftSrvPlnYear:0,
        bnftSrvPlnCmpPk:-1,
        sltBnftOfrRecPk:-1,
        cathYn:'',                      //유치도뇨관여부
        dprYn:'',                       //기저귀여부
        blnftItems:{actSt:'',bdSre:'',exrtSt:'',falRsk:'',},        
        vCd45:[],
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-2 white--text', color:'blue001', text: '급여제공계획', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '급여제공기록지 출력', disabled: true, },
        ],  
        tabItems: [
            { index: 0, tab: '신체활동지원', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '인지/정서/기능회복', class: 'fontOneDotTwoRem px-15', enter:false},
        ], 
        dialog:[false,false,false,false,false,false,false,false,],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '14',
            inqYmdStart: '', inqYmdEnd: '', inqYMD: '', inqYYYY: '', rptTyp: '1', mode: '2',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
        menu: [ false,],
        dialogDup: false,        
        sysVal1:'',
        sysVal2:'',
        progress:false,
    }),
};
</script>