<template>
    <v-sheet class="ma-0 pl-7 pt-7 pb-6 pr-6" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">{{ mdlTtl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="white my-3"></v-divider>
        <v-textarea
            v-model="cmnts" :rows="hSize"
            class="rmrksArea mr-2" 
            outlined dense no-resize hide-details>
        </v-textarea>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 mr-2 justify-center" no-gutters>
            <v-btn
                class="white--text" color="blueBtnColor" height="30"
                @click="insText()"
                samll rounded>
                <v-icon size="16">mdi-receipt-text-arrow-right-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">적용</span>
            </v-btn>
        </v-row>
    </v-sheet>            
</template>

<script>
export default {
    name: 'CmntsMdl',

    props : {
        mdlTtl      : { type: String, default: '상세보기' },    // 타이틀
        mdlCmnts    : { type: String, default: '' },           // 내용
        hSize       : { type: Number, default: 5 },            // 높이
    },
            
    components: {
       
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.cmnts = this.mdlCmnts
        }) 
    },

    computed: {
        
    },

    watch:{
        
    },
        
    methods: {        
        onClose: function () {
            this.$emit('isClose')
        },
        insText: function(){
            this.$emit('outText', this.cmnts)
            this.onClose()
        }
    },
    
    data: () => ({
        cmnts: '',
    }),
};
</script>