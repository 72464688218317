<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="5">
                <span class="text-h4 font-weight-bold">자원봉사자&nbsp;정보수정</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="mdfExtVlntrInfo" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">봉사자명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="5">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field
                            v-model="empInfo.name" :rules="[rules.required]"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                    <span class="black--text font-weight-medium">성별</span>
                </v-col>
                <v-col class="tableBL px-1" cols="4">
                    <span class=""> 
                        <v-radio-group
                            class="radio-dft-Theme mt-0 pl-2 pt-2"
                            v-model="empInfo.gndr" hide-details row>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="d-flex align-center tableBL tableBR px-1 py-1" cols="5">
                    <span class="d-inline-block" style="width: 60px;">
                        <v-text-field
                            v-model="empInfo.celphn_1"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex align-center px-1">
                        <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px;">
                        <v-text-field
                            v-model="empInfo.celphn_2"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex align-center px-1">
                        <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px;">
                        <v-text-field
                            v-model="empInfo.celphn_3"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                    <span class="black--text font-weight-medium">소속</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="4">
                    <span class="d-inline-block" style="width: 80px;">
                        <v-select
                            class="wrkSelect"
                            v-model="empInfo.afltnClcd" :key="forceRender"
                            :items="extSrtt" item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 90px;" v-if="empInfo.afltnClcd == '2'">
                        <v-text-field
                            v-model="empInfo.afltnNm"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">계약기간</span>
                </v-col>
                <v-col class="d-flex align-center tableBL tableBR px-1 py-1" cols="5">
                    <CmmDateComp v-model="empInfo.entDt" :required="true"></CmmDateComp>
                    <!-- <span class="d-inline-block black--text" style="width: 100px;">
                        <v-menu
                            ref="datePicker0" v-model="menu[0]"
                            :close-on-content-click="false" :return-value.sync="empInfo.entDt"
                            transition="scale-transition" offset-y min-width="auto" dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="empInfo.entDt"
                                    readonly hide-details outlined dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="empInfo.entDt" :max="empInfo.rtrDt" :key="forceRender" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(0)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker0.save(empInfo.entDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span> -->
                    <span class="d-flex align-center mx-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <CmmDateComp v-model="empInfo.rtrDt" :required="true"></CmmDateComp>
                    <!-- <span class="d-inline-block black--text" style="width: 100px;">
                        <v-menu
                            ref="datePicker1" v-model="menu[1]"
                            :close-on-content-click="false" :return-value.sync="empInfo.rtrDt"
                            transition="scale-transition" offset-y min-width="auto" dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="empInfo.rtrDt"
                                    readonly hide-details outlined dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="empInfo.rtrDt" :min="empInfo.entDt" :key="forceRender" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(1)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker1.save(empInfo.rtrDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span> -->
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                    <span class="black--text font-weight-medium">서명</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="2">
                    <v-img
                        :src="esignInf.src" :aspect-ratio="4/3"
                        class="rounded"
                        :min-height="esignInf.cnfmYn != '1' ? 22 : 30"
                        :max-height="esignInf.cnfmYn != '1' ? 23 : 31"
                        :contain="esignInf.cnfmYn == '1' ? true : false">
                    </v-img>
                </v-col>
                <v-col class="txtAlgnEnd pa-1" style="line-height: 1.9;" cols="2">
                    <span v-if="esignInf.cnfmYn == '1'" class="fontOneRem grey006--text">서명완료</span>
                    <span v-else class="fontOneRem grey006--text">서명없음</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL py-1" cols="10">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field
                            v-model="empInfo.rmrks"
                            color="blue" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" height="26" @click="preExtEmpsInfo()" samll rounded>
                    <v-icon small>mdi-content-save-outline</v-icon>
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
                <v-btn
                    class="white--text ml-3" color="grey006" height="26" @click="menu.splice(2, 1, true)" samll rounded>
                    <v-icon small>mdi-trash-can-outline</v-icon>
                    <span style="font-size: 1rem;">삭제</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[2]" max-width="500">  
            <btnModalDelete :title="rechkModalTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { remoteURL } from '@/api/baseAxios';
import { selExtEmpInfo, insEmp, delEmp } from '../../api/emp.js';

import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'MdfExtVlntrMdl',

    props : {
   
    },
        
    components: {
        btnModalDelete,
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {
        getSltEmpPk: function() {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        }
    },

    watch:{
        getSltEmpPk: function(value) {
            if(value == -1)
                this.clearForm()
            else
                this.getExtEmpInfo(value)
        },
    },
    
    methods: {
        getExtEmpInfo: function (pk) {
            selExtEmpInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getExtEmpInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selExtEmpInfo : ' + error))
        },
        setEmpInfo: function (obj) {
            insEmp(obj)
                .then((response) => ( this.asyncResultMdf(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmp : ' + error))
        },
        removeEmpInfo: function (pk) {
            delEmp(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncResultRemove(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmp : ' + error))
        },
        getExtEmpInfoAfter: function (res) {
            if(res.statusCode == '200'){
                if(res.result.info.entDt != '' && res.result.info.entDt != null)
                    res.result.info.entDt = res.result.info.entDt.beforeDateFormatHyp()
                if(res.result.info.rtrDt != '' && res.result.info.rtrDt != null)
                    res.result.info.rtrDt = res.result.info.rtrDt.beforeDateFormatHyp()

                this.empInfo = res.result.info

                if(res.wage == null || res.wage == '')
                    this.empInfo.wage = 0
                else
                    this.empInfo.wage = res.wage

                let path = remoteURL + 'cmm/eSigImgDownload?'
                let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum

                if(res.result.eSig != null){
                    this.esignInf.cnfmYn = '1'
                    this.esignInf.src = path + fcltyNum + '&eSigClcd=9&bzClcdPk=' +  res.result.info.empPk + '&t=' + new Date().getTime()
                }
                else{
                    this.esignInf.cnfmYn = '2'
                    this.esignInf.src = ''
                }
            }

            this.forceRender += 1
        },
        asyncResultMdf: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getExtEmpInfo(this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk)
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        asyncResultRemove: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.clearForm()
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getExtEmpList()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])

            this.onClose()
        },
        preExtEmpsInfo: function () {
            let obj = Object.assign({}, this.empInfo )
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.wrkStcd = '1'

            if(obj.entDt != '')
                obj.entDt = obj.entDt?.afterDateFormatHyp()
            if(obj.rtrDt != '')
                obj.rtrDt = obj.rtrDt?.afterDateFormatHyp()

            let chk = this.$refs.mdfExtVlntrInfo.validate()
            if(chk)
                this.setEmpInfo(obj)
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        onClose: function () {
            this.$emit('modalClose', '2')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        delModalAct: function(res){
            if(res == 9990){    //OK
                this.removeEmpInfo(this.empInfo.empPk)

                this.menu.splice(2, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(2, 1, false)
        },
        clearForm: function () {
            this.empInfo = Object.assign({
                empPk: 0, empWgePk: 0, empEtcPk: 0,
                name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', jobCd: '17', pstnCd: '6',
                celphn_1: '', celphn_2: '', celphn_3: '', rmrks: '', gndr: 'M', wrkStcd: '1',
            })

            this.forceRender += 1
        },
    },

    data: () => ({
        menu: [false, false, false,],
        rechkModalTitle: '자원봉사자 정보',
        extSrtt: [
            { valcd: '1', valcdnm: '개인' },
            { valcd: '2', valcdnm: '단체' },
        ],
        empInfo: {
            empPk: 0, empWgePk: 0, empEtcPk: 0,
            name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', jobCd: '17', pstnCd: '6',
            celphn_1: '', celphn_2: '', celphn_3: '', rmrks: '', gndr: 'M', wrkStcd: '1',
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            pwdSizeCheck: value => (value && value.length > 3) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
        esignInf: { src: '' , cnfmYn: '2' },
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>