<template>
    <v-sheet class="posn-rltv">
        <!-- 가림판 -->
        <div
            v-if="$parent.$parent.sltRow.bnMmbrPk == -1"
            class="rounded-lg d-inline-block d-flex align-center justify-center"
            style="position: absolute; top: 62px; left: 4px; width: 99%; height: 244px; z-index: 1; background-color: #4c88b3;">
            <div>
                <v-row class="pa-0" no-gutters>
                    <span class="fontTwoRem font-weight-bold white--text">좌측 목록에서 수급자를 선택해주세요.</span>
                </v-row>
                <v-row class="pa-0 mt-5 justify-center" no-gutters>
                    <v-btn
                        class="" style="padding: 9px 24px 8px 24px !important;"
                        color="white" min-width="30" height="30"
                        @click="onBsAddMdl"
                        small outlined>
                        <span class="fontOneDotOneRem pb-1">수급자 신규 등록</span>
                    </v-btn>
                </v-row>
            </div>
        </div>
        <v-row class="px-3" no-gutters>
            <v-spacer></v-spacer>
            <v-btn
                v-if="tab == 0"
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="onBscMdl()"
                samll rounded>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span>기본정보 수정</span>
            </v-btn>
            <v-btn
                v-if="tab == 1"
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="onCntrcAddMdl()"
                samll rounded>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span>신규계약</span>
            </v-btn>
            <v-btn
                v-if="tab == 1"
                class="rndClrBtn-Theme white--text" height="28" color="blueBtnColor"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1 || cntrcInf.bnfcrCntrcInfPk == -1"
                @click="onCntrcMdl(cntrcInf.bnfcrCntrcInfPk, $parent.$parent.sltRow.bnfcrPk, bnfcrInf.entDt)"
                samll rounded>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span>계약정보 수정</span>
            </v-btn>
            <v-btn
                v-if="tab == 0 && bnfcrInf.entStcd == '4'"
                class="rndClrBtn-Theme white--text" height="28" color="blue001"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="onReEntMdl()"
                samll rounded>
                <v-icon small>mdi-clipboard-text-outline</v-icon>
                <span>재입소</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme white--text" height="28" color="blue001"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="onLclMdl('12', 1000)"
                samll rounded>
                <v-icon small>mdi-clipboard-text-outline</v-icon>
                <span>퇴소/연계기록지</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme white--text" height="28" color="blue001"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="onCnslnJrnlsMdl"
                samll rounded>
                <v-icon small>mdi-clipboard-text-outline</v-icon>
                <span>상담일지</span>
            </v-btn>
            <v-btn
                v-if="tab == 0"
                class="rndClrBtn-Theme white--text" height="28" color="grey006"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="rmBnfcrBtn"
                samll rounded>
                <v-icon small>mdi-trash-can-outline</v-icon>
                <span>삭제</span>
            </v-btn>
            <v-btn
                class="rndClrBtn-Theme" height="28" color="grey006"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="onLclMdl('11', 800)"
                samll outlined rounded>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>입소사실 확인서</span>
            </v-btn>
            <v-btn
                v-if="tab == 0"
                class="rndClrBtn-Theme" height="28" color="grey006"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                @click="bnfcrInfoPrt"
                samll outlined rounded>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>수급자 정보</span>
            </v-btn>
            <v-btn
                v-if="tab == 1"
                class="rndClrBtn-Theme" height="28" color="grey006"
                :disabled="$parent.$parent.sltRow.bnMmbrPk == -1 && cntrcInf.bnfcrCntrcInfPk == -1"
                @click="sttrmCndtnPrt(cntrcInf.bnfcrCntrcInfPk)"
                samll outlined rounded>
                <v-icon small>mdi-printer-outline</v-icon>
                <span>표준약관</span>
            </v-btn>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-2">
            <v-divider class="mt-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center tableBR pa-1" cols="2">
                    <imageCard
                        :cstmFit="'vrtclAspct'" :imgSrc="imgInf.src"
                        @onDown="getAthFileInf" @isEvent="delImgfiles">
                    </imageCard>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            수급자명
                        </v-col>
                        <v-col class="overflow-text-hidden tableBR fontOneRem txtAlgnMid py-1" cols="2">
                            {{ bnfcrInf.name }}
                            <span v-if="typeof bnfcrInf.name != 'undefined'">&#40;만{{ bnfcrInf.age }}세&#41;</span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                            건강검진 제출일
                        </v-col>
                        <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="2">
                            <span v-if="bnfcrInf.entHlthChdt == '' || bnfcrInf.entHlthChdt == null"></span>
                            <span v-else>{{ $moment( bnfcrInf.entHlthChdt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                            생신일
                        </v-col>
                        <v-col class="fontOneRem txtAlgnMid py-1" cols="3">
                            <span class="mr-1">{{ $moment( bnfcrInf.birth, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                            <span v-if="typeof bnfcrInf.lnrYn != 'undefined'">
                                &#40; {{ bnfcrInf.lnrYn == '1' ? '양력' : '음력' }} &#41;
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            성별
                        </v-col>
                        <v-col class="d-flex justify-center tableBR py-1" cols="2">
                            <span class="mr-1" style="padding-top: 2px;">
                                <v-img
                                    v-if="bnfcrInf.gndr == 'F'" src="../../assets/icon/ic-female.svg"
                                    class="ic-sex" style="margin-left: 3px;">
                                </v-img>
                                <v-img
                                    v-else-if="bnfcrInf.gndr == 'M'" src="../../assets/icon/ic-male.svg"
                                    class="ic-sex" style="margin-left: 3px;">
                                </v-img>
                            </span>
                            <span v-if="bnfcrInf.gndr == 'M'" class="fontOneRem">&#40; 남 &#41;</span>
                            <span v-else-if="bnfcrInf.gndr == 'F'" class="fontOneRem">&#40; 여 &#41;</span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                            <span class="mr-1">생활실</span>
                            <v-btn
                                v-if=" bnfcrInf.entStcd != '4'"
                                class="nmlBfClrImp-white" style="padding: 3px 3px 2px 4px !important;"
                                @click="onLvnMdl()" :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                color="blueBtnColor" min-width="16" height="16" small outlined>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="13">mdi-text-box-edit-outline</v-icon>
                                    </template>
                                    <span>생활실 수정</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ bnfcrInf.lvnRm }}</span>
                                </template>
                                <span>{{ bnfcrInf.bldNm }} - {{ bnfcrInf.bldFlr }}층</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                            <span class="mr-1">입소일</span>
                            <v-btn
                                v-if=" bnfcrInf.entStcd != '4'"
                                class="nmlBfClrImp-white" style="padding: 3px 3px 2px 4px !important;"
                                @click="onEntMdl()" :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                color="blueBtnColor" min-width="16" height="16" small outlined>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="13">mdi-text-box-edit-outline</v-icon>
                                    </template>
                                    <span>입소일 수정</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="fontOneRem txtAlgnMid py-1" cols="3">
                            <span>{{ $moment( bnfcrInf.entDt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                            <span v-if="typeof bnfcrInf.entTmHh != 'undefined'">
                                &#40;{{ bnfcrInf.entTmHh }}&#58;{{ bnfcrInf.entTmMm }}&#41;
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            생년월일
                        </v-col>
                        <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="2">
                            <span>{{ $moment( bnfcrInf.rsdntBirth, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 d-flex align-center justify-center tableBR py-1" cols="2">
                            <span class="mr-1">본인부담률</span>
                            <v-btn
                                v-if="bnfcrInf.entStcd != '4'"
                                class="nmlBfClrImp-white" style="padding: 3px 3px 2px 4px !important;"
                                @click="onCpyMdl()" :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                color="blueBtnColor" min-width="16" height="16" small outlined>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="13">mdi-text-box-edit-outline</v-icon>
                                    </template>
                                    <span>본인부담률 수정</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="2">
                            {{ bnfcrInf.copayRateNm }}
                        </v-col>
                        <v-col class="tblHdr-greyE01 d-flex align-center justify-center tableBR py-1" cols="2">
                            <span class="mr-1">보조금</span>
                            <v-btn
                                v-if="bnfcrInf.entStcd != '4'"
                                class="nmlBfClrImp-white" style="padding: 3px 3px 2px 4px !important;"
                                @click="onGrantsMdl()" :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                color="blueBtnColor" min-width="16" height="16" small outlined>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="13">mdi-text-box-edit-outline</v-icon>
                                    </template>
                                    <span>보조금 수정</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="txtAlgnMid" style="line-height: 1.7;" cols="3">
                            <span
                                v-if="bnfcrInf.snackSprtYn != '1' && bnfcrInf.mealSprtYn != '1'"
                                class="fontOneRem">
                                해당없음
                            </span>
                            <div v-else>
                                <v-btn
                                    class="crsShp-Default white--text" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" :ripple="false" :disabled="bnfcrInf.snackSprtYn != '1'"
                                    min-width="20" height="20" small outlined>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">간식비</span>
                                </v-btn>
                                <v-btn
                                    class="crsShp-Default white--text ml-1" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" :ripple="false" :disabled="bnfcrInf.mealSprtYn != '1'"
                                    min-width="20" height="20" small outlined>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">식사재료비</span>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            인정번호
                        </v-col>
                        <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="2">
                            <span>{{ bnfcrInf.acknwNum1 }}</span>
                            <span v-if="typeof bnfcrInf.acknwNum2 != 'undefined'" style="padding: 0 2px;">
                                <span v-if="bnfcrInf.acknwNum2 != '' && typeof bnfcrInf.acknwNum2 == 'string'">-</span>
                            </span>
                            <span>{{ bnfcrInf.acknwNum2 }}</span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 d-flex align-center justify-center tableBR py-1" cols="2">
                            <span class="mr-1">인정등급/기간</span>
                            <v-btn
                                v-if="bnfcrInf.entStcd != '4'"
                                class="nmlBfClrImp-white" style="padding: 3px 3px 2px 4px !important;"
                                @click="onAcknwMdl" :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                color="blueBtnColor" min-width="16" height="16" small outlined>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" size="13">mdi-text-box-edit-outline</v-icon>
                                    </template>
                                    <span>인정등급 수정</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="fontOneRem txtAlgnMid py-1" cols="4">
                            <span class="mr-1">{{ bnfcrInf.acknwRtngNm }}</span>
                            <span v-if="typeof bnfcrInf.acknwPrdStrt == 'string'">
                                &#40;&nbsp;
                                <span>{{ $moment( bnfcrInf.acknwPrdStrt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                                &nbsp;&#126;&nbsp;
                                <span>{{ $moment( bnfcrInf.acknwPrdEnd, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                                &#41;
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            현황
                        </v-col>
                        <v-col class="fontOneRem pl-2 py-1" cols="5">
                            <span class="mr-2">{{ bnfcrInf.entStcdNm }}</span>
                            <span v-if="bnfcrInf.entStcd == '2' || bnfcrInf.entStcd == '3'">
                                <span class="mr-2">&#40; {{ $moment( bnfcrInf.outDtStrt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                                <span>{{ bnfcrInf.outTmHhStrt }}&#58;{{ bnfcrInf.outTmMmStrt }}</span>
                                <span v-if="bnfcrInf.outDtEnd != ''" class="mr-2">
                                    <span class="ml-1">&#126;</span>
                                    {{ $moment( bnfcrInf.outDtEnd, 'YYYYMMDD' ).format('YYYY.MM.DD') }}
                                </span>
                                <span v-if="bnfcrInf.outTmHhEnd != ''">{{ bnfcrInf.outTmHhEnd }}&#58;{{ bnfcrInf.outTmMmEnd }}</span>
                                <span>&#41;</span>
                            </span>
                            <span v-if="bnfcrInf.entStcd == '4'">
                                &#40; {{ $moment( bnfcrInf.lvDt, 'YYYYMMDD' ).format('YYYY.MM.DD') }} &#41;
                            </span>
                        </v-col>
                        <v-col class="tableBR" cols="1"></v-col>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                            휴대폰
                        </v-col>
                        <v-col class="fontOneRem txtAlgnMid py-1" cols="3">
                            <div v-if="typeof bnfcrInf.celphn_1 == 'string'" class="ma-0 pa-0">
                                <span>{{ bnfcrInf.celphn_1 }}</span>
                                <span v-if="bnfcrInf.celphn_2 != ''" class="mx-1">-</span>
                                <span>{{ bnfcrInf.celphn_2 }}</span>
                                <span v-if="bnfcrInf.celphn_3 != ''" class="mx-1">-</span>
                                <span>{{ bnfcrInf.celphn_3 }}</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            주소
                        </v-col>
                        <v-col class="overflow-text-hidden tableBR fontOneRem px-2 py-1" cols="6">
                            <span class="mr-2" v-if="typeof bnfcrInf.zip == 'string'">&#40; {{ bnfcrInf.zip }} &#41;</span>
                            <span>{{ bnfcrInf.addr }} {{ bnfcrInf.addrDtl }}</span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                            전화번호
                        </v-col>
                        <v-col class="fontOneRem txtAlgnMid pt-1" cols="3">
                            <div v-if="typeof bnfcrInf.tel_1 == 'string'" class="mb-0">
                                <span>{{ bnfcrInf.tel_1 }}</span>
                                <span v-if="bnfcrInf.tel_2 != ''" class="mx-1">-</span>
                                <span>{{ bnfcrInf.tel_2 }}</span>
                                <span v-if="bnfcrInf.tel_3 != ''" class="mx-1">-</span>
                                <span>{{ bnfcrInf.tel_3 }}</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            주요질환
                        </v-col>
                        <v-col class="fontOneRem px-2 py-1" cols="11">
                            {{ bnfcrInf.majorDsss }}
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR py-1" cols="1">
                            비고
                        </v-col>
                        <v-col
                            class="fontOneRem posn-rltv px-2 py-1"
                            cols="11">
                            <div
                                class="overflow-text-hidden txtAlgnFst"
                                style="position: absolute; left: 8px; top: 4px; width: 98%;">
                                {{ bnfcrInf.rmrks }}
                            </div>
                      
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mb-4"></v-divider>
            <v-tabs
                v-model="tab"
                class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab
                    v-for="item in tabItems" :key="item.tab"
                    :class="item.class" :disabled="item.enter">
                    {{ item.tab }}
                </v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding: 1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">
                <v-tab-item transition="false" eager>
                    <v-row class="pa-0 mt-1 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">주 보호자 정보</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="bnfcrInf.entStcd != '4'"
                            class="mr-2" width="20" height="20"
                            @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 34)"
                            icon>
                            <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="bnfcrInf.entStcd != '4'"
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" @click="onLclMdl('14', 800)"
                            :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-cellphone-cog</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">보호자 모바일 설정</span>
                        </v-btn>
                        <v-btn
                            v-if="bnfcrInf.entStcd != '4'"
                            class="white--text ml-2" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" @click="onFmlyMdl()"
                            :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">보호자 정보 수정</span>
                        </v-btn>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">보호자명</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span>{{ fmlyInf.name }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">청구서수신</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span v-if="fmlyInf.msClcd == '1'">알림톡</span>
                            <span v-else-if="fmlyInf.msClcd == '2'">문자</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">전화번호</span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="5">
                            <div v-if="typeof fmlyInf.tel_1 == 'string'" class="ma-0 pa-0">
                                <span>{{ fmlyInf.tel_1 }}</span>
                                <span v-if="fmlyInf.tel_2 != ''" class="mx-1">-</span>
                                <span>{{ fmlyInf.tel_2 }}</span>
                                <span v-if="fmlyInf.tel_3 != ''" class="mx-1">-</span>
                                <span>{{ fmlyInf.tel_3 }}</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">관계</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span>{{ fmlyInf.rltns }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">생년월일</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span v-if="typeof fmlyInf.rsdntBirth != 'undefined'">
                                {{ $moment(fmlyInf.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">휴대폰</span>
                        </v-col>
                        <v-col class="d-flex align-center px-4 py-1" cols="5">
                            <div v-if="typeof fmlyInf.celphn_1 == 'string'" class="ma-0 pa-0">
                                <span>{{ fmlyInf.celphn_1 }}</span>
                                <span v-if="fmlyInf.celphn_2 != ''" class="mx-1">-</span>
                                <span>{{ fmlyInf.celphn_2 }}</span>
                                <span v-if="fmlyInf.celphn_3 != ''" class="mx-1">-</span>
                                <span>{{ fmlyInf.celphn_3 }}</span>
                            </div>
                            <v-btn
                                class="crsShp-Default white--text ml-4" style="padding: 9px 7px 8px 5px !important;"
                                color="blueBtnColor" :ripple="false" :disabled="!fmlyInf.isPay"
                                min-width="20" height="20" small outlined>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">급여</span>
                            </v-btn>
                            <v-btn
                                class="crsShp-Default white--text ml-2" style="padding: 9px 7px 8px 5px !important;"
                                color="blueBtnColor" :ripple="false" :disabled="!fmlyInf.isBill"
                                min-width="20" height="20" small outlined>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">청구</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">이메일</span>
                        </v-col>
                        <v-col class="tableBR px-4 py-1" cols="5">
                            <span>{{ fmlyInf.emailId }}</span>
                            <span v-if="typeof fmlyInf.emailDmn == 'string' && fmlyInf.emailDmn.length > 0" class="">@</span>
                            <span>{{ fmlyInf.emailDmn }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">주소</span>
                        </v-col>
                        <v-col class="overflow-text-hidden px-4 py-1" cols="5">
                            <span v-if="typeof fmlyInf.zip != 'undefined'">
                                &#40; {{ fmlyInf.zip }} &#41; {{ fmlyInf.addr }} {{ fmlyInf.addrDtl }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">가족 정보</span>
                    </v-row>
                    <v-divider :class="[fmlyList.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <v-row :class="[fmlyList.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">이름</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">청구서수신</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">관계</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="4">
                            <span class="font-weight-medium">휴대폰</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="2">
                            <span class="font-weight-medium">수신여부</span>
                        </v-col>
                    </v-row>
                    <v-divider :class="[fmlyList.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <div v-if="fmlyList.length > 0" class="overflow-y-auto" style="width: 100%; height: 66px;">
                        <v-row
                            :class="['tableBB', fmlyList.length > 2 ? '' : 'mr-1']"
                            v-for="(itm, i) in fmlyList" :key="i"
                            no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span class="fontOneRem">{{ itm.name }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span v-if="itm.msClcd == '1'" class="fontOneRem">알림톡</span>
                                <span v-else-if="itm.msClcd == '2'" class="fontOneRem">문자</span>
                                <span v-else-if="itm.msClcd == '3'" class="fontOneRem">이메일</span>
                            </v-col>
                            <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="2">
                                <span class="fontOneRem">{{ itm.rltns }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <div v-if="typeof itm.celphn_1 == 'string'" class="ma-0 pa-0">
                                    <span>{{ itm.celphn_1 }}</span>
                                    <span v-if="itm.celphn_2 != ''" class="mx-1">-</span>
                                    <span>{{ itm.celphn_2 }}</span>
                                    <span v-if="itm.celphn_3 != ''" class="mx-1">-</span>
                                    <span>{{ itm.celphn_3 }}</span>
                                </div>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="2">
                                <v-btn
                                    class="crsShp-Default white--text" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" :ripple="false" :disabled="itm.isPay == false"
                                    min-width="20" height="20" small outlined>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">급여</span>
                                </v-btn>
                                <v-btn
                                    class="crsShp-Default white--text ml-1" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" :ripple="false" :disabled="itm.isBill == false"
                                    min-width="20" height="20" small outlined>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">청구</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else class="tableBB pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">입/퇴소 이력</span>
                    </v-row>
                    <v-divider :class="[bnfcrHis.length > 3 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <v-row :class="[bnfcrHis.length > 3 ? 'mr-2__5' : 'mr-1']" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">구분</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">입소일</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">입소시간</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">퇴소일</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">퇴소시간</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="5">
                            <span class="font-weight-medium">퇴소사유</span>
                        </v-col>
                    </v-row>
                    <v-divider :class="[bnfcrHis.length > 3 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <div
                        v-if="bnfcrHis.length > 0"
                        class="overflow-y-auto" style="width: 100%; height: 97px;">
                        <v-row
                            :class="['tableBB', bnfcrHis.length > 3 ? '' : 'mr-1']"
                            v-for="(itm, i) in bnfcrHis" :key="i"
                            no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                <span class="fontOneRem">{{ itm.clNm }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span v-if="itm.entDt != null" class="fontOneRem">
                                    {{ $moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                </span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                <span class="fontOneRem">{{ itm.entTm }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span v-if="itm.lvDt != null && itm.lvDt != ''" class="fontOneRem">
                                    {{ $moment(itm.lvDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                </span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                <span class="fontOneRem">{{ itm.lvTm }}</span>
                            </v-col>
                            <v-col class="overflow-text-hidden txtAlgnMid py-1" cols="5">
                                <span class="fontOneRem">{{ itm.lvRsn }}</span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else class="tableBB pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item transition="false" eager>
                    <v-row class="pa-0 mt-1 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">계약정보</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="bnfcrInf.entStcd != '4'"
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor"
                            @click="cntrcCnfmMsg(cntrcInf.bnfcrCntrcInfPk)"
                            :disabled="cntrcInf.bnfcrCntrcInfPk == -1"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-arrow-expand-right</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">표준약관 발송</span>
                        </v-btn>
                        <v-btn
                            class="white--text ml-2" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor"
                            :disabled="cntrcInf.bnfcrCntrcInfPk == -1"
                            @click="onLclMdl('16', 900)"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-file-document-edit-outline</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">확인사항 관리</span>
                        </v-btn>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">계약일자</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span v-if="typeof cntrcInf.cntrcDt != 'undefined'">
                                {{ $moment(cntrcInf.cntrcDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">계약기간</span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="8">
                            <span v-if="typeof cntrcInf.cntrcPrdStrt != 'undefined'">
                                {{ $moment(cntrcInf.cntrcPrdStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </span>
                            <span v-if="typeof cntrcInf.cntrcPrdEnd != 'undefined'">&nbsp;&#126;&nbsp;</span>
                            <span v-if="typeof cntrcInf.cntrcPrdEnd != 'undefined'">
                                {{ $moment(cntrcInf.cntrcPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </span>
                            
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">보호자</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span>{{ cntrcInf.grdnName }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">납부방법</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span v-if="cntrcInf.pymntMthdCd == '1'">계좌이체</span>
                            <span v-else-if="cntrcInf.pymntMthdCd == '2'">신용카드</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">납부일</span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="2">
                            <span v-if="typeof cntrcInf.pymntDt != 'undefined'">
                                매월 {{ cntrcInf.pymntDt }} 일
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">본인부담률</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span>{{ cntrcInf.copayRateNm }}</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">인정등급</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                            <span v-if="typeof cntrcInf.acknwRtng == 'undefined'"></span>
                            <span v-else-if="cntrcInf.acknwRtng == '99'">등급외</span>
                            <span v-else>{{ cntrcInf.acknwRtng }}등급</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                            <span class="font-weight-medium">보조금</span>
                        </v-col>
                        <v-col class="px-4 py-1" cols="5">
                            <span v-if="cntrcInf.snackSprtYn == '1'">간식비</span>
                            <span v-if="cntrcInf.snackSprtYn == '1' && cntrcInf.mealSprtYn == '1'">, </span>
                            <span v-if="cntrcInf.mealSprtYn == '1'">식사재료비</span>
                            <span v-if="cntrcInf.snackSprtYn == '2' && cntrcInf.mealSprtYn == '2'">해당없음</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">비급여 수가정보</span>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">비급여 수가 기준</span>
                        </v-col>
                        <v-col class="tableBR px-3 py-1" cols="4">
                            <span v-if="cntrcInf.npymntFeeStd == '1'">시설비급여수가</span>
                            <span v-else-if="cntrcInf.npymntFeeStd == '2'">개별비급여수가</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">본인부담금 합계</span>
                        </v-col>
                        <v-col class="d-flex px-3 py-1" cols="4">
                            <span>&#40; 30일 기준 &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.copayFee?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">식사재료비</span>
                        </v-col>
                        <v-col class="d-flex tableBR px-3 py-1" cols="4">
                            <span>&#40; 1식 &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.mealCost?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">경관유동식비</span>
                        </v-col>
                        <v-col class="d-flex px-3 py-1" cols="4">
                            <span v-if="cntrcInf.lqdFoodClcd == '1'">&#40; 1개월 &#41;</span>
                            <span v-else-if="cntrcInf.lqdFoodClcd == '2'">&#40; 1식 &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.lqdFood?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">간식비</span>
                        </v-col>
                        <v-col class="d-flex tableBR px-3 py-1" cols="4">
                            <span v-if="cntrcInf.snackOfrClcd == '1'">&#40; 1일</span>
                            <span v-else-if="cntrcInf.snackOfrClcd == '2'">&#40; 1식</span>
                            <span v-if="typeof cntrcInf.snackCntCd != 'undefined'">&nbsp;{{ cntrcInf.snackCntCd }}회 &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.snackFee?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">이/미용료</span>
                        </v-col>
                        <v-col class="d-flex px-3 py-1" cols="4">
                            <span>&#40; 1개월 &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.btyFee?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">상급침실비</span>
                        </v-col>
                        <v-col class="d-flex tableBR px-3 py-1" cols="4">
                            <span v-if="typeof cntrcInf.upBedNm == 'undefined'">&#40; 미사용 &#41;</span>
                            <span v-else>&#40; {{ cntrcInf.upBedNm }} &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.upBedFee?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">등급외 본인부담금</span>
                        </v-col>
                        <v-col class="d-flex px-3 py-1" cols="4">
                            <span v-if="cntrcInf.rtngOtrClcd == '1'">&#40; 1개월 &#41;</span>
                            <span v-else-if="cntrcInf.rtngOtrClcd == '2'">&#40; 1일 &#41;</span>
                            <v-spacer></v-spacer>
                            <span>{{ cntrcInf.rtngOtrAmt?.toLocaleString('ko-KR') }}원</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">계약이력</span>
                    </v-row>
                    <v-divider :class="[cntrcHis.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <v-row :class="[cntrcHis.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                            <span class="font-weight-medium">계약일자</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                            <span class="font-weight-medium">계약기간</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">수급자 서명</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">보호자 서명</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="2">
                            
                        </v-col>
                    </v-row>
                    <v-divider :class="[cntrcHis.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <div v-if="cntrcHis.length > 0" class="overflow-y-auto" style="width: 100%; height: 66px;">
                        <v-row
                            :class="['tableBB', cntrcHis.length > 2 ? '' : 'mr-1']"
                            v-for="(itm, i) in cntrcHis" :key="i"
                            no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                <span v-if="itm.cntrcDt != ''" class="posn-rltv">
                                    <v-icon
                                        v-if="itm.bnfcrPk == $parent.$parent.sltRow.bnfcrPk"
                                        style="position: absolute; top: 4px; left: -20px;"
                                        color="blueBtnColor" size="18">
                                        mdi-circle-medium
                                    </v-icon>
                                    <span class="fontOneRem">{{ $moment(itm.cntrcDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                </span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                <span class="fontOneRem">{{ itm.cntrcprd }}</span>
                                <v-btn
                                    class="nmlBfClrImp-white ml-1" style="margin-bottom: 2px;"
                                    color="grey006" min-width="18" height="18"
                                    @click="onCntrcDtSavMdl(itm)"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-calendar-edit-outline</v-icon>
                                        </template>
                                        <span>계약기간 수정</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-col>
                            <v-col class="tableBR" cols="2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            class="crsShp-Pointer"
                                            @click="onSigMdl('1', itm)"
                                            v-bind="attrs" v-on="on">
                                            <v-img
                                                :src="itm.bnfcrSrc" :aspect-ratio="4/3"
                                                :class="['rounded', itm.bnfcrSigYn != '1' ? 'my-1 mx-4' : '']"
                                                :min-height="itm.bnfcrSigYn != '1' ? 22 : 30"
                                                :max-height="itm.bnfcrSigYn != '1' ? 23 : 31"
                                                :contain="itm.bnfcrSigYn == '1' ? true : false">
                                            </v-img>
                                        </span>
                                    </template>
                                    <span>수급자 직접서명</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR" cols="2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            class="crsShp-Pointer"
                                            @click="onSigMdl('2', itm)"
                                            v-bind="attrs" v-on="on">
                                            <v-img
                                                :src="itm.grdnSrc" :aspect-ratio="4/3"
                                                :class="['rounded', itm.grdnSigYn != '1' ? 'my-1 mx-4' : '']"
                                                :min-height="itm.grdnSigYn != '1' ? 22 : 30"
                                                :max-height="itm.grdnSigYn != '1' ? 23 : 31"
                                                :contain="itm.grdnSigYn == '1' ? true : false">
                                            </v-img>
                                        </span>
                                    </template>
                                    <span>보호자 직접서명</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="2">
                                <v-btn
                                    class="nmlBfClrImp-white"
                                    color="grey006" min-width="20" height="20"
                                    @click="onCntrcMdl(itm.bnfcrCntrcInfPk, itm.bnfcrPk, itm.entDt)"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>계약정보 수정</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn
                                    class="nmlBfClrImp-white ml-3"
                                    color="grey006" min-width="20" height="20"
                                    @click="cntrcCnfmMsg(itm.bnfcrCntrcInfPk)"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-arrow-expand-right</v-icon>
                                        </template>
                                        <span>표준약관 발송</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn
                                    class="nmlBfClrImp-white ml-3"
                                    color="grey006" min-width="20" height="20"
                                    @click="sttrmCndtnPrt(itm.bnfcrCntrcInfPk)"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                        </template>
                                        <span>표준약관 출력</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else class="tableBB pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item transition="false" eager>
                    <v-row class="pa-0 mt-1 mb-2 align-center posn-rltv" no-gutters>
                        <span
                            class="d-inlne-block"
                            style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                            <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                        </span>
                        <span class="fontOneDotTwoRem font-weight-medium ml-6">개인정보 수집 및 이용동의&#40;입소시&#41;</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="bnfcrInf.entStcd != '4'"
                            class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" @click="onAgrAddMdl('1')"
                            :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                            min-width="20" height="20" small outlined>
                            <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">추가</span>
                        </v-btn>
                    </v-row>
                    <v-divider :class="[prvcyAgr.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <v-row :class="[prvcyAgr.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">작성일</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">동의여부</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">수급자 서명</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                            <span class="font-weight-medium">보호자 서명</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="4">
                            
                        </v-col>
                    </v-row>
                    <v-divider :class="[prvcyAgr.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <div
                        v-if="prvcyAgr.length > 0"
                        class="overflow-y-auto" style="width: 100%; height: 66px; padding-bottom: 1px;">
                        <v-row
                            :class="['tableBB', prvcyAgr.length > 2 ? '' : 'mr-1']"
                            v-for="(itm, i) in prvcyAgr" :key="i"
                            no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span class="fontOneRem">{{ $moment(itm.agrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                <span v-if="itm.agrYn == '1'" class="fontOneRem">예</span>
                                <span v-else-if="itm.agrYn == '2'" class="fontOneRem">아니오</span>
                            </v-col>
                            <v-col class="tableBR" cols="2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            class="crsShp-Pointer"
                                            @click="onSigMdl('5', itm)"
                                            v-bind="attrs" v-on="on">
                                            <v-img
                                                :src="itm.bnfcrSrc" :aspect-ratio="4/3"
                                                :class="['rounded', itm.bnfcrSigYn != '1' ? 'my-1 mx-4' : '']"
                                                :min-height="itm.bnfcrSigYn != '1' ? 22 : 30"
                                                :max-height="itm.bnfcrSigYn != '1' ? 23 : 31"
                                                :contain="itm.bnfcrSigYn == '1' ? true : false">
                                            </v-img>
                                        </span>
                                    </template>
                                    <span>수급자 직접서명</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR" cols="2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            class="crsShp-Pointer"
                                            @click="onSigMdl('6', itm)"
                                            v-bind="attrs" v-on="on">
                                            <v-img
                                                :src="itm.grdnSrc" :aspect-ratio="4/3"
                                                :class="['rounded', itm.grdnSigYn != '1' ? 'my-1 mx-4' : '']"
                                                :min-height="itm.grdnSigYn != '1' ? 22 : 30"
                                                :max-height="itm.grdnSigYn != '1' ? 23 : 31"
                                                :contain="itm.grdnSigYn == '1' ? true : false">
                                            </v-img>
                                        </span>
                                    </template>
                                    <span>보호자 직접서명</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="txtAlgnMid" cols="4">
                                <v-btn
                                    class="nmlBfClrImp-white"
                                    color="grey006" min-width="20" height="20"
                                    @click="onAgrMdl('1', itm)"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>이용동의 수정</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn
                                    class="nmlBfClrImp-white ml-3"
                                    color="grey006" min-width="20" height="20"
                                    @click="agrMsg(itm.agrOfrPk)"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-arrow-expand-right</v-icon>
                                        </template>
                                        <span>이용동의 발송</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn
                                    class="nmlBfClrImp-white ml-3"
                                    color="grey006" min-width="20" height="20"
                                    @click="prtAgrCall()"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                        </template>
                                        <span>이용동의 출력</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn
                                    class="nmlBfClrImp-white ml-3"
                                    color="grey006" min-width="20" height="20"
                                    @click="rmAgrBtn(itm, '1')"
                                    small icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>이용동의 삭제</span>
                                    </v-tooltip>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else class="tableBB pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                                <span
                                    class="d-inlne-block"
                                    style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                                </span>
                                <span class="fontOneDotTwoRem font-weight-medium ml-6">노인인권 보호지침 제공&#40;입소시&#41;</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    v-if="bnfcrInf.entStcd != '4'"
                                    class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" @click="onAgrAddMdl('2')"
                                    :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">추가</span>
                                </v-btn>
                            </v-row>
                            <v-divider :class="[eldHmnOfr.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <v-row :class="[eldHmnOfr.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                                    <span class="font-weight-medium">작성일</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                                    <span class="font-weight-medium">동의여부</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="4">
                                    <span class="font-weight-medium">제공 받은자</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="4">
                                    
                                </v-col>
                            </v-row>
                            <v-divider :class="[eldHmnOfr.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <div
                                v-if="eldHmnOfr.length > 0"
                                class="overflow-y-auto" style="width: 100%; height: 66px; padding-bottom: 1px;">
                                <v-row
                                    :class="['tableBB', eldHmnOfr.length > 2 ? '' : 'mr-1']"
                                    v-for="(itm, i) in eldHmnOfr" :key="i"
                                    no-gutters>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                        <span class="fontOneRem">{{ $moment(itm.ofrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                        <span v-if="itm.agrYn == '1'" class="fontOneRem">예</span>
                                        <span v-else-if="itm.agrYn == '2'" class="fontOneRem">아니오</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                        <span v-if="itm.rcvPstnCd == '1'" class="fontOneRem">수급자</span>
                                        <span v-if="itm.rcvPstnCd == '2'" class="fontOneRem">
                                            보호자
                                            <span v-if="itm.rcvNm != ''">: </span>
                                            <span>{{ itm.rcvNm }}</span>
                                        </span>
                                    </v-col>
                                    <v-col class="txtAlgnMid" cols="4">
                                        <v-btn
                                            class="nmlBfClrImp-white"
                                            color="grey006" min-width="20" height="20"
                                            @click="onAgrMdl('2', itm)"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                                </template>
                                                <span>노인 보호지침 수정</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="prtEldCall()"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                                </template>
                                                <span>노인 보호지침 출력</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="rmAgrBtn(itm, '2')"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>이용동의 삭제</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row v-else class="tableBB pa-0" no-gutters>
                                <v-col class="txtAlgnMid py-2" cols="12">
                                    <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-4" cols="6">
                            <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                                <span
                                    class="d-inlne-block"
                                    style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                                </span>
                                <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 안전관리 통지서&#40;입소시&#41;</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    v-if="bnfcrInf.entStcd != '4'"
                                    class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" @click="onAgrAddMdl('3')"
                                    :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">추가</span>
                                </v-btn>
                            </v-row>
                            <v-divider :class="[sftNtcAgr.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <v-row :class="[sftNtcAgr.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                                    <span class="font-weight-medium">작성일</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                                    <span class="font-weight-medium">동의여부</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="4">
                                    <span class="font-weight-medium">통지 받은자</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="4">
                                    
                                </v-col>
                            </v-row>
                            <v-divider :class="[sftNtcAgr.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <div
                                v-if="sftNtcAgr.length > 0"
                                class="overflow-y-auto" style="width: 100%; height: 66px; padding-bottom: 1px;">
                                <v-row
                                    :class="['tableBB', sftNtcAgr.length > 2 ? '' : 'mr-1']"
                                    v-for="(itm, i) in sftNtcAgr" :key="i"
                                    no-gutters>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                        <span class="fontOneRem">{{ $moment(itm.ofrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                        <span v-if="itm.agrYn == '1'" class="fontOneRem">예</span>
                                        <span v-else-if="itm.agrYn == '2'" class="fontOneRem">아니오</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                        <span v-if="itm.rcvPstnCd == '1'" class="fontOneRem">수급자</span>
                                        <span v-if="itm.rcvPstnCd == '2'" class="fontOneRem">
                                            보호자
                                            <span v-if="itm.rcvNm != ''">: </span>
                                            <span>{{ itm.rcvNm }}</span>
                                        </span>
                                    </v-col>
                                    <v-col class="txtAlgnMid" cols="4">
                                        <v-btn
                                            class="nmlBfClrImp-white"
                                            color="grey006" min-width="20" height="20"
                                            @click="onAgrMdl('3', itm)"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                                </template>
                                                <span>안전관리 수정</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="onFallRiskrMdl(itm)"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-list-box-outline</v-icon>
                                                </template>
                                                <span>낙상위험 방지 확인</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="prtSftCall(itm)"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                                                </template>
                                                <span>안전관리 통지서 출력</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="rmAgrBtn(itm, '3')"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>안전관리 삭제</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row v-else class="tableBB pa-0" no-gutters>
                                <v-col class="txtAlgnMid py-2" cols="12">
                                    <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                                <span
                                    class="d-inlne-block"
                                    style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                                </span>
                                <span class="fontOneDotTwoRem font-weight-medium ml-6">직원 인권 보호 제공&#40;입소시&#41;</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    v-if="bnfcrInf.entStcd != '4'"
                                    class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" @click="onAgrAddMdl('4')"
                                    :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">추가</span>
                                </v-btn>
                            </v-row>
                            <v-divider :class="[empHmnRgh.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <v-row :class="[empHmnRgh.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                                    <span class="font-weight-medium">제공일자</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                                    <span class="font-weight-medium">보호자명&#40;관계&#41;</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                                    <span class="font-weight-medium">안내방법</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="4">
                                    
                                </v-col>
                            </v-row>
                            <v-divider :class="[empHmnRgh.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <div
                                v-if="empHmnRgh.length > 0"
                                class="overflow-y-auto" style="width: 100%; height: 66px; padding-bottom: 1px;">
                                <v-row
                                    :class="['tableBB', empHmnRgh.length > 2 ? '' : 'mr-1']"
                                    v-for="(itm, i) in empHmnRgh" :key="i"
                                    no-gutters>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                        <span class="fontOneRem">{{ $moment(itm.ofrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                        <span>{{ itm.rcvNm }}&#40;{{itm.rltns}}&#41;</span>                                        
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="3">                                        
                                        <span>{{ itm.ntcMthd }}</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid" cols="4">
                                        <v-btn
                                            class="nmlBfClrImp-white"
                                            color="grey006" min-width="20" height="20"
                                            @click="onAgrMdl('4', itm)"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                                </template>
                                                <span>직원 인권 보호 수정</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="goPage('https://drive.google.com/file/d/1VXI31ouHUt_QTDkNSg4FeDdYuAAjn8lM/view?usp=sharing')"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-download</v-icon>
                                                </template>
                                                <span>직원 인권 보호 PDF</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="rmAgrBtn(itm, '4')"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>직원 인권 보호 삭제</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row v-else class="tableBB pa-0" no-gutters>
                                <v-col class="txtAlgnMid py-2" cols="12">
                                    <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-4" cols="6">
                            <v-row class="pa-0 mt-4 mb-2 align-center posn-rltv" no-gutters>
                                <span
                                    class="d-inlne-block"
                                    style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                                </span>
                                <span class="fontOneDotTwoRem font-weight-medium ml-6">연명 의료 결정 제도&#40;필요시&#41;</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    v-if="bnfcrInf.entStcd != '4'"
                                    class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" @click="onAgrAddMdl('5')"
                                    :disabled="$parent.$parent.sltRow.bnMmbrPk == -1"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">추가</span>
                                </v-btn>
                            </v-row>
                            <v-divider :class="[lifesSystm.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <v-row :class="[lifesSystm.length > 2 ? 'mr-2__5' : 'mr-1']" no-gutters>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                                    <span class="font-weight-medium">제공일자</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                                    <span class="font-weight-medium">제공 받은자</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                                    <span class="font-weight-medium">안내방법</span>
                                </v-col>
                                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="4">
                                    
                                </v-col>
                            </v-row>
                            <v-divider :class="[lifesSystm.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
                            <div
                                v-if="lifesSystm.length > 0"
                                class="overflow-y-auto" style="width: 100%; height: 66px; padding-bottom: 1px;">
                                <v-row
                                    :class="['tableBB', lifesSystm.length > 2 ? '' : 'mr-1']"
                                    v-for="(itm, i) in lifesSystm" :key="i"
                                    no-gutters>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                        <span class="fontOneRem">{{ $moment(itm.ofrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                        <span v-if="itm.rcvPstnCd == '1'" class="fontOneRem">수급자</span>
                                        <span v-if="itm.rcvPstnCd == '2'" class="fontOneRem">
                                            보호자
                                            <span v-if="itm.rcvNm != ''">: </span>
                                            <span>{{ itm.rcvNm }}</span>
                                        </span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                        {{ itm.ntcMthd }}
                                    </v-col>
                                    <v-col class="txtAlgnMid" cols="4">
                                        <v-btn
                                            class="nmlBfClrImp-white"
                                            color="grey006" min-width="20" height="20"
                                            @click="onAgrMdl('5', itm)"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                                                </template>
                                                <span>연명의료결정제도 수정</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="goPage('https://drive.google.com/file/d/1eAjPhNaHccxXQkF6Nt2MeC2FN-ZCIIhg/view?usp=sharing')"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-download</v-icon>
                                                </template>
                                                <span>연명의료결정제도 브로슈어 PDF</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            class="nmlBfClrImp-white ml-3"
                                            color="grey006" min-width="20" height="20"
                                            @click="rmAgrBtn(itm, '5')"
                                            small icon>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>연명의료결정제도 삭제</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row v-else class="tableBB pa-0" no-gutters>
                                <v-col class="txtAlgnMid py-2" cols="12">
                                    <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item transition="false">
                    <CctvBdrmMgmt v-if="tab == 3"></CctvBdrmMgmt>
                </v-tab-item>
                <v-tab-item transition="false">
                    <DocMgmt v-if="tab == 4"></DocMgmt>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <CnfrOfdmMdl v-if="lclMdlInf.typ == '11'" @madalState="rstLclMdlInf"></CnfrOfdmMdl>
            <ExtLinkRec
                v-else-if="lclMdlInf.typ == '12'"
                @madalState="rstLclMdlInf" @midLoad="$parent.$parent.getAllBnfcrList()"
                @onMsg="onInfMsg" @onSndMsg="sendLinkMsgAct" @onRcrdPrt="rcrdPrt">
            </ExtLinkRec>
            <CnslnJrnlsMdl
                v-else-if="lclMdlInf.typ == '13'" @isClose="rstLclMdlInf"></CnslnJrnlsMdl>
            <BnfcrFmlyActMgmt
                v-else-if="lclMdlInf.typ == '14'"
                :prtFmlyInf="fmlyInf" :prtFmlyList="fmlyList"
                @isClose="rstLclMdlInf">
            </BnfcrFmlyActMgmt>
            <bizMsgMdl 
                v-else-if="lclMdlInf.typ == '15'"
                :bizMsgData="bizMsgInfo.mmbrPk"
                :bizMsgDocClcd="bizMsgInfo.docClcd"
                :bizMsgBzPk="bizMsgInfo.bzPk"
                :bizMsgShow="lclMdlInf.isOn"
                :bizMsgTitle="bizMsgInfo.bzTitle"
                :bizMsgCntnt="bizMsgInfo.bzCntnt"
                @madalState="rstLclMdlInf">
            </bizMsgMdl>
            <CnFmItem
                v-else-if="lclMdlInf.typ == '16'"
                :bnfcrCntrcInfPk="cntrcInf.bnfcrCntrcInfPk" @modalClose="rstLclMdlInf">
            </CnFmItem>
            <SignComp
                v-else-if="lclMdlInf.typ == '17'"
                :mmbrPk="cntrcInf.eSigClcd == 1 ? $store.getters.getBnfcrState.bnmmbr : cntrcInf.grdnMmbrPk"
                :bzClcdPk="cntrcInf.bnfcrCntrcInfPk" :eSigClcd="cntrcInf.eSigClcd"
                @modalClose="rstSig">
            </SignComp>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http, remoteURL } from '@/api/baseAxios';

import imageCard from '../../components/commons/imageCard.vue';             // 이미지 로드 (다운로드)
import CnfrOfdmMdl from '../../components/bnfcr/CnfrOfdmMdl.vue';           // 입소사실확인서 팝업
import ExtLinkRec from '../../components/bnfcr/BnfcrExtLinkRec.vue';        // 퇴소/연계기록지
import CnslnJrnlsMdl from '../../components/bnfcr/CnslnJrnlsMdl.vue';       // 상담일지
import BnfcrFmlyActMgmt from '../../components/bnfcr/BnfcrFmlyActMgmt.vue'; // 보호자 모바일 설정
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';             // 전자발송
import CnFmItem from '../../components/bnfcr/BnfcrBasicCnFmItm.vue';        // 확인사항 관리
import SignComp from '../../components/commons/SignModel.vue';              // 수급자 보호자 서명
import DocMgmt from '../../components/bnfcr/BnfcrDocMgmt.vue';              // 수급자 기타서류
import CctvBdrmMgmt from '../../components/bnfcr/BnfcrCctvBdrmMgmt.vue';    // CCTV 동의

export default {
    name: 'BnfcrInfrmMgmt',
    
    components: {
        imageCard,
        CnfrOfdmMdl,
        ExtLinkRec,
        CnslnJrnlsMdl,
        BnfcrFmlyActMgmt,
        bizMsgMdl,
        CnFmItem,
        SignComp,
        DocMgmt,
        CctvBdrmMgmt,
    },

    created: function(){
       
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getRootActv: function () {
            return this.$store.getters.getRootActv
        },
        getSelectedItem(){
            return this.$parent.$parent.sltRow
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$parent.$parent.sltRow.bnMmbrPk == -1)
                this.rstField('1')
            else{
                this.getBnfcrInf(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)
                this.rstTabData()
            }
        })
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '6'){
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
            if(v && this.runAble == '4'){
                this.rmBnfcrInf(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
            if(v && this.runAble == '3'){
                let oobj = this.$store.getters.getSubOutObj
                this.rmAgrOfr(oobj.agrOfrPk, oobj.clsfcCode)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
        getRootActv: function (v) {
            if(this.runAble == '1' && !v && this.$parent.$parent.sltRow.bnMmbrPk > -1){
                this.$parent.$parent.getAllBnfcrList()
                this.getBnfcrInf(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)
                this.rstTabData()
            }

            if(this.runAble == '2' && !v && this.$parent.$parent.sltRow.bnMmbrPk == -1)
                this.$parent.$parent.getAllBnfcrList()
            
            if(this.runAble == '3' && !v && this.$parent.$parent.sltRow.bnMmbrPk > -1)
                this.rstTabData()
            
            if(this.runAble == '4' && !v && this.$parent.$parent.sltRow.bnMmbrPk != -1){
                this.$parent.$parent.sltRow.bnMmbrPk = -1
                this.$parent.$parent.sltRow.bnfcrPk = -1
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });
                this.rstField('1')
                this.$parent.$parent.getAllBnfcrList()
            }

            if(!v)
                this.runAble = '0'
        },
        getSelectedItem: {
            deep: true,
            handler: function (value){
                if(value.bnMmbrPk == -1)
                    this.rstField('1')
                else{
                    this.rstTabData()
                    this.getBnfcrInf(value.bnMmbrPk, value.bnfcrPk)
                }
            },
        },
        'tab': function () {
            this.rstTabData()
        },
    },
    
    methods: {
        // 수급자 기본정보 조회
        getBnfcrInf: function (mbr, fcr){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrInfo', obj)
                .then((response) => ( this.bnfcrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrInfo : ' + error))
        },

        bnfcrRst: function (res){
            if(res.result.bnfcr != null){
                this.bnfcrInf = res.result.bnfcr
                this.valCdApply()
            }
            if(res.result.fileList.length == 1){
                let iList = res.result.fileList.filter(v => v.fileKndCd == '2')
                iList.forEach(itm => {
                    itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePth+'&fileKndCd='+itm.fileKndCd
                    this.imgInf = itm
                })
            }
            else
                this.imgInf.src = remoteURL + 'opr/imgView'
        },

        // 보호자 기본정보 조회
        getFmlyInf: function (mbr, fcr){
            this.fmlyList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrFmlyInfo', obj)
                .then((response) => ( this.fmlyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrFmlyInfo : ' + error))
        },

        fmlyRst: function (res){
            if(Object.keys(res.result.galFmly).length > 0){
                res.result.galFmly.isPay = false
                res.result.galFmly.isBill = false
                if(Array.isArray(res.result.galFmly.cellRcvYn))
                    res.result.galFmly.cellRcvYn.forEach(itm => {
                        switch (itm) {
                            case '1':
                                res.result.galFmly.isPay = true
                                break;
                            case '2':
                                res.result.galFmly.isBill = true
                                break;
                            default:
                                break;
                        }
                    });
                res.result.galFmly.cellRcvYn
                this.fmlyInf = res.result.galFmly
            }
            if(res.result.fmlyList.length > 0){
                res.result.fmlyList.forEach(itm => {
                    itm.isPay = false
                    itm.isBill = false
                    if(Array.isArray(itm.cellRcvYn))
                        itm.cellRcvYn.forEach(idx => {
                            switch (idx) {
                                case '1':
                                    itm.isPay = true
                                    break;
                                case '2':
                                    itm.isBill = true
                                    break;
                                default:
                                    break;
                            }
                        });
                });

                this.fmlyList = res.result.fmlyList
            }
        },

        // 수급자 기본정보 이력 조회
        getBnfcrHis: function (mbr, fcr){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrHisList', obj)
                .then((response) => ( this.bnfcrHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrHisList : ' + error))
        },

        bnfcrHisRst: function (res){
            if(res.result.hisList.length > 0)
                this.bnfcrHis = res.result.hisList
        },

        // 수급자 계약정보 단건 조회
        getCntrcInf: function (mbr, fcr){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrCntrcInfo', obj)
                .then((response) => ( this.cntrcRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrCntrcInfo : ' + error))
        },

        cntrcRst: function (res){
            if(res.result.cntrcInfo == null){
                this.cntrcInf = {}
                this.cntrcInf.eSigClcd = 0
                this.cntrcInf.bnfcrCntrcInfPk = -1
            }
            else{
                // 2025.01.24 계약 단건 정보에 이력 정보 대입
                res.result.cntrcInfo.copayRate = res.result.rtngGvrnmInfo.copayRate
                res.result.cntrcInfo.acknwRtng = res.result.rtngGvrnmInfo.acknwRtng
                res.result.cntrcInfo.acknwRtngNm = res.result.rtngGvrnmInfo.acknwRtngNm
                res.result.cntrcInfo.mealSprtYn = res.result.rtngGvrnmInfo.mealSprtYn
                res.result.cntrcInfo.snackSprtYn = res.result.rtngGvrnmInfo.snackSprtYn
                let chc = res.result.bnftFeeList.findIndex( v => v.acknwRtng == res.result.rtngGvrnmInfo.acknwRtng )
                if(chc > -1)
                    res.result.cntrcInfo.bnftFee = res.result.bnftFeeList[chc].rtgFee

                let cpy = this.$parent.$parent.vCd63.findIndex( v => v.valcd == res.result.rtngGvrnmInfo.copayRate )
                if(cpy > -1)
                    res.result.cntrcInfo.copayRateNm = this.$parent.$parent.vCd63[cpy].valcdnm

                let uBed = this.$parent.$parent.vCd114.findIndex( v => v.valcd == res.result.cntrcInfo.upBedClcd )
                if(uBed > -1)
                    res.result.cntrcInfo.upBedNm = this.$parent.$parent.vCd114[uBed].valcdnm
                
                if(res.result.cntrcInfo.upBedFeeLmt == null)
                    res.result.cntrcInfo.upBedFeeLmt = 0
                if(res.result.cntrcInfo.upBedFeeLmtYn == null)
                    res.result.cntrcInfo.upBedFeeLmtYn = '2'
        
                res.result.cntrcInfo.copayFee = this.cntrcDtlCalc(res.result.cntrcInfo, this.$parent.$parent.vCd63)
                res.result.cntrcInfo.eSigClcd = 0

                this.cntrcInf = res.result.cntrcInfo
            }
        },

        // 수급자 계약정보 변경이력 조회
        getCntrcHisList: function (mbr, fcr){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrCntrcList', obj)
                .then((response) => ( this.cntrcHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrCntrcList : ' + error))
        },

        cntrcHisRst: function (res){
            if(res.result.length > 0){
                let path = remoteURL + 'cmm/eSigImgDownload?'
                let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum

                res.result.forEach(itm => {
                    itm.bnfcrSrc = path + fcltyNum + '&eSigClcd=1&bzClcdPk=' + itm.bnfcrCntrcInfPk + '&t=' + new Date().getTime()
                    itm.grdnSrc = path + fcltyNum + '&eSigClcd=2&bzClcdPk=' + itm.bnfcrCntrcInfPk + '&t=' + new Date().getTime()
                });
          
                this.cntrcHis = res.result
            }
        },

        // 개인정보 동의 조회
        getPrvcyAgr: function (mbr){
            let obj = {}
            obj.bnMmbrPk = mbr

            http.post('bnfcrAuth/selPrvcyAgrList', obj)
                .then((response) => ( this.prvcyAgrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selPrvcyAgrList : ' + error))
        },

        prvcyAgrRst: function (res){
            if(res.result.length > 0){
                let path = remoteURL + 'cmm/eSigImgDownload?'
                let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum

                res.result.forEach(itm => {
                    itm.bnfcrSrc = path + fcltyNum + '&eSigClcd=5&bzClcdPk=' + itm.agrOfrPk + '&t=' + new Date().getTime()
                    itm.grdnSrc = path + fcltyNum + '&eSigClcd=6&bzClcdPk=' + itm.agrOfrPk + '&t=' + new Date().getTime()
                });

                this.prvcyAgr = res.result
            }

        },

        // 노인인권, 안전통지, 직원인권보호, 연명의료결정제도  동의 조회
        getAgrOfr: function (mbr, clcd){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.clsfcCode = clcd

            http.post('bnfcrAuth/selAgrOfrList', obj)
                .then((response) => ( this.agrRst(response.data, clcd) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selAgrOfrList : ' + error))
        },

        agrRst: function (res, clcd){
            
            if(res.result.length > 0){
                switch (clcd) {
                    case '2':
                        this.eldHmnOfr = res.result
                        break;
                    case '3':
                        this.sftNtcAgr = res.result
                        break;
                    case '4':
                        this.empHmnRgh = res.result
                        break;
                    case '5':
                        this.lifesSystm = res.result
                        break;
                    default:
                        break;
                }
            } else {
                switch (clcd) {
                    case '2':
                        this.eldHmnOfr.splice(0)
                        break;
                    case '3':
                        this.sftNtcAgr.splice(0)
                        break;
                    case '4':
                        this.empHmnRgh.splice(0)
                        break;
                    case '5':
                        this.lifesSystm.splice(0)
                        break;
                    default:
                        break;
                }
            }
        },

        // 수급자 정보 삭제
        rmBnfcrInf: function (mbr, fcr){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/delBnfcrInfo', obj)
                .then((response) => ( this.rmBnfcrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/delBnfcrInfo : ' + error))
        },

        rmBnfcrRst: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if( Array.isArray(res.messageList))
                obj.cntnt = res.messageList

            if(res.statusCode == '200'){
                obj.type = 'info'
                
                this.rstField('1')

                this.$parent.$parent.sltRow.bnMmbrPk = -1
                this.$parent.$parent.sltRow.bnfcrPk = -1
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });

                this.$parent.$parent.getAllBnfcrList()
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 동의 항목 삭제
        rmAgrOfr: function (pk, typ){
            let obj = {}
            obj.agrOfrPk = pk

            http.post('bnfcrAuth/delAgrOfr', obj)
                .then((response) => ( this.rmAgyRst(response.data, typ) ))
                .catch((error) => console.log('connect error /bnfcrAuth/delAgrOfr : ' + error))
        },

        rmAgyRst: function (res, typ) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if( Array.isArray(res.messageList) )
                obj.cntnt = res.messageList

            if(res.statusCode == '200'){
                obj.type = 'info'
                
                switch (typ) {          // 1: 개인정보, 2: 노인인권, 3: 안전관리
                    case '1':
                        this.getPrvcyAgr(this.$parent.$parent.sltRow.bnMmbrPk)
                        break;
                    case '2':
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '2')
                        break;
                    case '3':
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '3')
                        break;
                    case '4':
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '4')
                        break;
                    case '5':
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '5')
                        break;
                    default:
                        break;
                }
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 이미지 다운로드
        getAthFileInf: function () {
            http({
                method: 'post', url: 'cmm/fileDownload',
                responseType: 'arraybuffer', data: this.imgInf })
                .then((response) => ( this.createFile(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },

        createFile: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        // 수급자 이미지 삭제
        delImgfiles: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '수급자 사진 삭제는 기본정보 수정 화면에서 가능합니다.'// 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            this.$store.commit('setWrnnInf', obj)
        },

        rmBnfcrBtn: function () {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '수급자: ' + this.bnfcrInf.name + '을/를 삭제합니다.'
            obj.cntnt = ['삭제된 정보는 복구가 불가능하며 영구적으로 제거됩니다.']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '4'
        },

        rmAgrBtn: function (itm, clcd) {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            switch (clcd) {
                case '1':
                    obj.title = '개인정보 수집 및 이용동의를 삭제합니다.'
                    break;
                case '2':
                    obj.title = '노인인권 보호지침 제공을 삭제합니다.'
                    break;
                case '3':
                    obj.title = '수급자 안전관리 통지서를 삭제합니다.'
                    break;
                case '4':
                    obj.title = '직원 인권 보호 통지서를 삭제합니다.'
                    break;
                case '5':
                    obj.title = '연명 의료 결정 제도 통지서를 삭제합니다.'
                    break;
                default:
                    break;
            }
            obj.cntnt = ['삭제된 정보는 복구가 불가능하며 영구적으로 제거됩니다.']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            let exObj = Object.assign(itm)
            exObj.clsfcCode = clcd

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: exObj, runEvt: false,
            });

            this.runAble = '3'
        },

        // 탭 변경 이벤트
        rstTabData: function () {
            this.rstField('2')

            if(this.$parent.$parent.sltRow.bnMmbrPk > -1){
                switch (this.tab) {
                    case 0:
                        this.getFmlyInf(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)
                        this.getBnfcrHis(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)
                        break;
                    case 1:
                        this.getCntrcInf(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)
                        this.getCntrcHisList(this.$parent.$parent.sltRow.bnMmbrPk, this.$parent.$parent.sltRow.bnfcrPk)
                        break;
                    case 2:
                        this.getPrvcyAgr(this.$parent.$parent.sltRow.bnMmbrPk)
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '2')
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '3')
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '4')
                        this.getAgrOfr(this.$parent.$parent.sltRow.bnMmbrPk, '5')
                        break;
                    default:
                        break;
                }
            }
        },

        // 데이터 필드 초기화
        rstField: function (chc){
            // 수급자 정보 테이터 초기화
            if(chc == '1')
                this.bnfcrInf = {}

            // 탭별 데이터 초기화
            switch (this.tab) {
                case 0:
                    this.fmlyInf = {}
                    this.fmlyList.splice(0)
                    this.bnfcrHis.splice(0)
                    break;
                case 1:
                    this.cntrcInf = {}
                    this.cntrcInf.bnfcrCntrcInfPk = -1
                    this.cntrcHis.splice(0)
                    break;
                case 2:
                    this.prvcyAgr.splice(0)
                    this.eldHmnOfr.splice(0)
                    this.sftNtcAgr.splice(0)
                    break;
                default:
                    break;
            }

            // 이미지 데이터 제거
            this.imgInf.src = remoteURL + 'opr/imgView'
            delete this.imgInf.atchdFilePk
            delete this.imgInf.bzClcd
            delete this.imgInf.filePth
            delete this.imgInf.fileNm
        },

        // 테이블 데이터 코드 맵핑
        valCdApply: function (){
            if(typeof this.bnfcrInf.entStcd != 'undefined'){
                let chc = this.$parent.$parent.vCd65.findIndex( v => v.valcd == this.bnfcrInf.entStcd )
                if(chc > -1)
                    this.bnfcrInf.entStcdNm = this.$parent.$parent.vCd65[chc].valcdnm
            }
            if(typeof this.bnfcrInf.copayRate != 'undefined'){
                let chc = this.$parent.$parent.vCd63.findIndex( v => v.valcd == this.bnfcrInf.copayRate )
                if(chc > -1){
                    this.bnfcrInf.copayRateNm = this.$parent.$parent.vCd63[chc].valcdnm
                    this.bnfcrInf.copayRateDesc = this.$parent.$parent.vCd63[chc].valdesc
                }
            }
            if(typeof this.bnfcrInf.acknwRtng != 'undefined'){
                let chc = this.$parent.$parent.vCd62.findIndex( v => v.valcd == this.bnfcrInf.acknwRtng )
                if(chc > -1)
                    this.bnfcrInf.acknwRtngNm = this.$parent.$parent.vCd62[chc].valcdnm
            }
        },

        // 계약정보 본인부담금 계산
        cntrcDtlCalc: function (obj, vCd63){
            let cpyRate = 0
            let cpy = vCd63.findIndex( v => v.valcd == obj.copayRate )
            if(cpy > -1)
                cpyRate = Number(vCd63[cpy].valdesc) / 100
            
            // 본인부담금
            let copayFee = 0
            if(obj.acknwRtng == '99'){
                if(obj.rtngOtrClcd == '1')
                    copayFee = obj.rtngOtrAmt
                else if(obj.rtngOtrClcd == '2')
                    copayFee = obj.rtngOtrAmt * 30
            }
            else
                copayFee = (obj.bnftFee * cpyRate) * 30
   
            // 식사재료비
            let mealFee = 0
            switch (obj.mealCalcCd) {
                case '1':
                    mealFee = ( obj.mealCost * obj.mealCnt ) * 30
                    break;
                case '2':
                    if(obj.lqdFoodClcd == '1')
                        mealFee = obj.lqdFood
                    else if(obj.lqdFoodClcd == '2')
                        mealFee = ( obj.lqdFood * obj.lqdFoodCnt ) * 30
                    break;
                default:
                    break;
            }

            if(obj.mealSprtYn == '1')      // 식사 보조금 처리
                mealFee = 0
            
            // 간식비
            let snackFee = 0
            if(obj.snackOfrClcd == '0')
                snackFee = 0
            else
                snackFee = ( obj.snackFee * Number(obj.snackCntCd) ) * 30

            if(obj.snackSprtYn == '1')      // 간식비 보조금 처리
                snackFee = 0

            // 월 한도액 제한
            // if(obj.mealCostLmtYn == '1')
            //     if(obj.mealCostLmt < (mealFee + snackFee))
            //         mealFee = obj.mealCostLmt

            // 이미용료
            let btyFee = obj.btyFee
            
            // 상급침실비
            let upBedFee = obj.upBedFee * 30
            // if(obj.upBedFeeLmtYn == '1' && obj.upBedFeeLmt < upBedFee)
            //     upBedFee = obj.upBedFeeLmt

            return ( copayFee + mealFee + snackFee + btyFee + upBedFee )
        },

        // 본인부담률 수정 팝업
        onCpyMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.name = this.bnfcrInf.name
            obj.gndr = this.bnfcrInf.gndr
            obj.age = this.bnfcrInf.age
            obj.rsdntBirth = this.bnfcrInf.rsdntBirth
            //obj.entDt = this.bnfcrInf.entDt

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-01', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 인정등급 수정 팝업
        onAcknwMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.name = this.bnfcrInf.name
            obj.gndr = this.bnfcrInf.gndr
            obj.age = this.bnfcrInf.age
            obj.rsdntBirth = this.bnfcrInf.rsdntBirth
            obj.acknwNum1 = this.bnfcrInf.acknwNum1
            obj.acknwNum2 = this.bnfcrInf.acknwNum2

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-02', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 보조금 수정 팝업
        onGrantsMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.name = this.bnfcrInf.name
            obj.gndr = this.bnfcrInf.gndr
            obj.age = this.bnfcrInf.age
            obj.rsdntBirth = this.bnfcrInf.rsdntBirth
            //obj.snackSprtYn = this.bnfcrInf.gvrnmSnack
            //obj.mealSprtYn = this.bnfcrInf.gvrnmMeal

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-03', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 생활실 수정 팝업
        onLvnMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.name = this.bnfcrInf.name
            obj.gndr = this.bnfcrInf.gndr
            obj.age = this.bnfcrInf.age
            obj.rsdntBirth = this.bnfcrInf.rsdntBirth

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 900, prsst: true, typ: '1-05', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 입소일 수정 팝업
        onEntMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = this.bnfcrInf.bnfcrPk
            obj.name = this.bnfcrInf.name
            obj.gndr = this.bnfcrInf.gndr
            obj.age = this.bnfcrInf.age
            obj.rsdntBirth = this.bnfcrInf.rsdntBirth
            obj.entDt = this.bnfcrInf.entDt
            obj.lvDt = this.bnfcrInf.lvDt

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-08', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 수급자 기본정보 수정 팝업
        onBscMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 900, prsst: true, typ: '1-04', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 수급자 신규등록 팝업
        onBsAddMdl: function () {
            let obj = {}

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '1-07', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '2'
        },

        // 보호자 정보 수정 팝업
        onFmlyMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = this.bnfcrInf.bnfcrPk

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '1-06', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '6'
        },

        // 신규계약 팝업
        onCntrcAddMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = this.bnfcrInf.bnfcrPk
            obj.entDt = this.bnfcrInf.entDt

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '1-11', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '3'
        },

        // 계약정보 수정 팝업
        onCntrcMdl: function (infPk, bnfcr, eDt) {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = bnfcr
            obj.bnfcrCntrcInfPk = infPk
            obj.entDt = eDt
            obj.subTtl = '수정'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '1-09', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '3'
        },

        // 계약기간 수정 팝업
        onCntrcDtSavMdl: function (obj) {
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 700, prsst: true, typ: '1-14', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 재입소 팝업
        onReEntMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = this.bnfcrInf.bnfcrPk
            obj.name = this.bnfcrInf.name
            obj.gndr = this.bnfcrInf.gndr
            obj.age = this.bnfcrInf.age
            obj.rsdntBirth = this.bnfcrInf.rsdntBirth
            obj.entDt = this.bnfcrInf.entDt
            obj.lvDt = this.bnfcrInf.lvDt

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-10', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '4'
        },

        // 동의 신규 팝업
        onAgrAddMdl: function (clcd) {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = this.bnfcrInf.bnfcrPk 
            obj.name    = this.bnfcrInf.name
            obj.clsfcCode = clcd
            obj.subTtl = '신규'
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-12', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '3'
        },

        // 동의 변경 팝업
        onAgrMdl: function (clcd, itm) {
            let obj = Object.assign(itm)
            obj.clsfcCode = clcd
            obj.subTtl = '수정'
            obj.name    = this.bnfcrInf.name

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1-12', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '3'
        },

        // 낙상위험 변경 팝업
        onFallRiskrMdl: function (itm) {
            let obj = Object.assign(itm)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 860, prsst: true, typ: '1-13', inObj: obj, outObj: null, runEvt: false,
            })

            this.runAble = '3'
        },

        // 상담일지 모달 오픈
        onCnslnJrnlsMdl: function () {
            let obj = {}
            obj.bnMmbrPk = this.bnfcrInf.bnMmbrPk
            obj.bnfcrPk = this.bnfcrInf.bnfcrPk
            obj.name = this.bnfcrInf.name
            obj.age = this.bnfcrInf.age
            obj.gndr = this.bnfcrInf.gndr
            obj.entStcdNm = this.bnfcrInf.entStcdNm
            obj.entDtDot = this.$moment(this.bnfcrInf.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
            if(this.bnfcrInf.lvDt != '' && this.bnfcrInf.lvDt != null)
                obj.lvDtDot = this.$moment(this.bnfcrInf.lvDt, 'YYYYMMDD').format('YYYY.MM.DD')
            else
                obj.lvDtDot = ''
            obj.qtrToYear = this.$moment().startOf('quarter').format('YYYY-MM')
            obj.vCd104 = this.$parent.$parent.vCd104
            obj.mode = 'all'

            this.$store.commit('setTmpObj', obj)
            this.onLclMdl('13', 1400)
        },

        // 공통 출력물 팝업 호출
        rprtActCall: function (){
            this.prtThrowObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.prtThrowObj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },

        // 연계기록지 출력
        rcrdPrt: function (lrPk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.list[0].bzPk.push(lrPk)
            this.prtThrowObj.prtCd = '3'
            this.prtThrowObj.prtClcd = '1'

            this.rprtActCall()
        },

        // 수급자 정보 출력
        bnfcrInfoPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.prtCd = '51'
            this.prtThrowObj.prtClcd = '1'

            this.rprtActCall()
        },

        // 표준약관 출력
        sttrmCndtnPrt: function (pk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.prtCd = '6'
            this.prtThrowObj.prtClcd = '1'

            if(pk != -1)
                this.prtThrowObj.list[0].bzPk.push(pk)

            this.rprtActCall()
        },

        // 개인정보 이용동의 출력
        prtAgrCall: function () {
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.prtCd = '25'
            this.prtThrowObj.prtClcd = '1'

            this.rprtActCall()
        },

        // 노인인권 보호지침 출력
        prtEldCall: function () {
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.prtCd = '201'
            this.prtThrowObj.prtClcd = '2'

            this.rprtActCall()
        },

        // 안전관리 통지서 출력
        prtSftCall: function (obj) {
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)

            this.prtThrowObj.list[0].bzPk.push(obj.agrOfrPk)
            this.prtThrowObj.prtCd = '26'
            this.prtThrowObj.prtClcd = '1'

            this.rprtActCall()
        },

        // CCTV 동의 내역 출력
        cnsnFlmnPrt: function (bPk, yyyy){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.prtCd = '37'
            this.prtThrowObj.prtClcd = '1'
            this.prtThrowObj.inqYYYY = yyyy
            this.prtThrowObj.list[0].bzPk.push(bPk)

            this.rprtActCall()
        },

        // 표준약관 전자발송
        cntrcCnfmMsg: function (pk) {
            this.bizMsgInfo.mmbrPk.splice(0)

            this.bizMsgInfo.bzTitle = '장기이용급여 표준약관'
            this.bizMsgInfo.docClcd = 1
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = this.$store.getters.getBnfcrState.name
            this.bizMsgInfo.bzPk = pk

            this.onLclMdl('15', 1100)
        },

        // 개인정보 이용동의 전자발송
        agrMsg: function (pk) {
            this.bizMsgInfo.mmbrPk.splice(0)

            this.bizMsgInfo.bzTitle = '개인정보 수집 및 이용동의'
            this.bizMsgInfo.docClcd = 2
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = this.$store.getters.getBnfcrState.name
            this.bizMsgInfo.bzPk = pk

            this.onLclMdl('15', 1100)
        },

        // CCTV 동의 전자발송
        sendMsgAct: function (pk){
            this.bizMsgInfo.mmbrPk.splice(0)

            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = this.$store.getters.getBnfcrState.name
            this.bizMsgInfo.bzPk = pk

            this.onLclMdl('15', 1100)
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            if(this.lclMdlInf.typ == '13')
                this.$store.commit('setTmpObj', {})

            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        // 서명 팝업 오픈
        onSigMdl: function (clcd, itm) {
            let obj = {}
       
            if(clcd == '1' || clcd == '5')
                obj.mmbrPk = itm.bnMmbrPk
            else if(clcd == '2' || clcd == '6')
                obj.mmbrPk = itm.grdnMmbrPk

            switch (clcd) {
                case '1':
                case '2':
                    obj.bzClcdPk = itm.bnfcrCntrcInfPk
                    break;
                case '5':
                case '6':
                    obj.bzClcdPk = itm.agrOfrPk
                    break;
                default:
                    break;
            }

            obj.eSigClcd = clcd

            this.$store.commit('setTmpObj', obj)

            this.onLclMdl('17', 450)
        },

        // 서명 팝업 초기화
        rstSig: function () {
            this.rstLclMdlInf()
            this.rstTabData()
        },

        // 알림메시지 노출
        onInfMsg: function (txt){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = txt                             // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            this.$store.commit('setWrnnInf', obj)
        },

        // 연계기록지 발송 팝업
        sendLinkMsgAct: function (lrpk, dt){
            let tmp = this.$moment(dt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = tmp + ' 연계기록지'
            this.bizMsgInfo.bzPk = lrpk
            this.bizMsgInfo.bizMsgVal1 = tmp

            this.bizMsgInfo.bzTitle = tmp + ' 연계기록지'
            this.bizMsgInfo.docClcd = 13
            
            this.onLclMdl('15', 1100)
        },

        goPage:function(url){
            window.open(url, '_blank')
        }
    },

    data: () => ({
        tab: 0,
        subTab: 0,
        runAble: '0',
        bnfcrInf: {},                                                   // 수급자 기본정보
        cntrcInf: {},                                                   // 수급자 계약정보
        imgInf: {},                                                     // 수급자 사진정보
        fmlyInf: {},                                                    // 주 보호자 정보
        fmlyList: [],                                                   // 부 보호자 정보
        bnfcrHis: [],                                                   // 수급자 입퇴소이력 목록
        cntrcHis: [],                                                   // 수급자 계약이력 목록
        prvcyAgr: [],                                                   // 개인정보 동의 목록
        eldHmnOfr: [],                                                  // 노인인권 보호지침 동의 목록
        sftNtcAgr: [],                                                  // 수급자 안전관리 통지서 목록
        empHmnRgh: [],                                                  // 직원인권보호 목록
        lifesSystm: [],                                                  // 연명의료결정제도
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },             // 통합 팝업 제어 (11: 입소사실확인)
        prtThrowObj: {                                                  // 출력물 관리 정보
            fcltyNum: 0, bnMmbrPk: 0, bnfcrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '4',
            inTxt1: '', inTxt2: '', inqYYYY: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        bizMsgInfo: {
            mmbrPk: [], docClcd: 0, bzPk: 0, bzTitle: '', bzCntnt: '', bizMsgVal1: '',
        },
        tabItems: [
            { index: 0, tab: '기본정보', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '계약정보', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 2, tab: '동의 및 제공서류', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 3, tab: 'CCTV 동의', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 4, tab: '기타서류', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        subTabItms: [
            { index: 0, tab: '개인정보', class: 'rounded-pill px-6 mr-2', isOff: false },
            { index: 1, tab: '노인인권', class: 'rounded-pill px-6 ', isOff: false },
        ],
    }),
};
</script>