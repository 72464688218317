<template>
    <div class="d-flex">
        <v-sheet class="pa-3" width="558" height="835" color="midAreaColor">
            <p class="hamletFont text-h5 topTitleColor--text font-weight-black">
                <span>4-5.&nbsp;프로그램 개선&nbsp;&#40;의견수렴&#41;</span>
            </p>
            <v-divider class="midAreaColor mt-1"></v-divider>
            <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            <v-divider class="midAreaColor mt-1"></v-divider>
            <v-data-table
                v-if="sltYyyy < 2025"
                class="dft-none-mltTbl rounded" height="660" locale="ko"
                :headers="prgmHdr" :items="prgmQtrList" item-key="rn"
                :loading="isLoad" :loading-text="loadMsg"
                fixed-header hide-default-header hide-default-footer disable-pagination dense>
                <template v-slot:header="{ props }">
                    <thead>
                        <tr>
                            <th style="width: 62px; border-bottom: 2px solid !important;" colspan="1" rowspan='2'>
                                <span>{{ props.headers[0].text }}</span>
                            </th>
                            <th v-for="(qtr, i) in 4" :key="i" style="width: 102px;" colspan="2" rowspan='1'>
                                <span>{{ (i+1) }}분기</span>
                            </th>
                            <th style="width: 62px; border-bottom: 2px solid !important;" colspan="1" rowspan='2'>
                                <span>{{ props.headers[1].text }}</span>
                            </th>
                        </tr>
                        <tr>
                            <th v-for="(qtr, m) in 8" :key="m" class="tableBR" style="top: 32px;" colspan="1" rowspan='1'>
                                <span v-if="m % 2 == 0">{{ props.headers[2].text }}</span>
                                <span v-else>{{ props.headers[3].text }}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="overflow-text-hidden txtAlgnMid tableBR px-1 py-2" colspan="1" rowspan='1'>
                            <span class="fontOneRem">{{ item.idxNm }}</span>
                        </td>
                        <td v-for="(qtr, i) in 4" :key="i" class="tableBR pa-0" colspan="2" rowspan='1'>
                            <span class="d-inline-block txtAlgnMid tableBR py-2" style="width: 50%;">
                                <span class="fontOneRem red--text" v-if="item['recQrtr' + (i+1)] == 0">미작성</span>
                                <span class="fontOneRem" v-else>{{ item['recQrtr' + (i+1)] }}건</span>
                            </span>
                            <span class="d-inline-block txtAlgnMid py-2" style="width: 50%;">
                                <span class="fontOneRem red--text" v-if="item['opnQrtr' + (i+1)] == null">미작성</span>
                                <span class="fontOneRem" v-else>{{ item['opnQrtr' + (i+1)] }}건</span>
                            </span>
                        </td>
                        <td class="txtAlgnMid px-1 py-2" colspan="1" rowspan='1'>
                            <span class="fontOneRem red--text" v-if="item.rflDt == null">미작성</span>
                            <span class="fontOneRem" v-else>{{ item.rflDt }}건</span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-data-table
                v-else
                class="dft-none-mltTbl rounded" height="660" locale="ko"
                :headers="prgmHdr" :items="prgmImprvList" item-key="rn"
                :loading="isLoad" :loading-text="loadMsg"
                fixed-header hide-default-header hide-default-footer disable-pagination dense>
                <template v-slot:header="{ props }">
                    <thead>
                        <tr>
                            <th style="width: 100px;">
                                <span class="d-inline-block pt-2" style="height: 36px;">{{ props.headers[4].text }}</span>
                            </th>
                            <th style="width: 160px;">
                                <span>{{ props.headers[5].text }}</span>
                            </th>
                            <th v-for="(qtr, i) in 4" :key="i" style="width: 51px;">
                                <span>{{ (i+1) }}분기</span>
                            </th>
                            <th style="width: 68px;">
                                <span>{{ props.headers[1].text }}</span>
                            </th>
                        </tr>
                        <tr>
                            <th style="top: 52px;" colspan="2">
                                <span>신체, 인지, 여가 작성 건수 / 그룹수</span>
                            </th>
                            <th v-for="(qtr, i) in 4" :key="i" style="width: 51px; top: 52px">
                                <span>{{ prgmImprvTot['qrtr'+(i+1)+'WrtCnt'] }} / {{ prgmImprvTot['qrtr'+(i+1)+'GrpCnt'] }}</span>
                            </th>
                            <th style="width: 68px; top: 52px">
                                <span>{{ prgmImprvTot.qrtr5WrtCnt }} / {{ prgmImprvTot.qrtr5GrpCnt }}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="overflow-text-hidden txtAlgnMid tableBR px-1 py-2" colspan="1" rowspan="1">
                            <span class="fontOneRem">{{ item.idxNm }}</span>
                        </td>
                        <td class="px-0" colspan="6" rowspan="1">
                            <div
                                v-for="(grp, g) in item.grpList" :key="g"
                                :class="['d-flex', item.grpList.length != (g+1) ? 'tableBB' : '']" style="height: 39px;">
                                <span
                                    class="d-inline-block txtAlgnMid overflow-text-hidden tableBR px-1"
                                    style="width: 160px; height: 100%; padding-top: 9px;">
                                    <span class="fontOneRem">{{ grp.bnfcrgrNm }}</span>
                                </span>
                                <span
                                    v-for="(qtr, i) in 4" :key="i"
                                    class="d-inline-block crsShp-Pointer txtAlgnMid tableBR px-1"
                                    style="width: 51px; height: 100%; padding-top: 10px;" @click="onOpnnClctnMdl(grp, (i+1))">
                                    <span v-if="grp['opnQrtr'+(i+1)+'Cnt'] == 0" class="fontOneRem red--text">미작성</span>
                                    <span v-else class="fontOneRem">{{ grp['opnQrtr'+(i+1)+'Cnt'] }}건</span>
                                </span>
                                <span
                                    class="d-inline-block crsShp-Pointer txtAlgnMid px-1"
                                    style="width: 68px; height: 100%; padding-top: 10px;" @click="onOpnnRflctYyMdl(grp)">
                                    <span v-if="grp.rflDtCnt == 0" class="fontOneRem red--text">미작성</span>
                                    <span v-else class="fontOneRem">{{ grp.rflDtCnt }}건</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <p v-if="sltYyyy < 2025" class="txtAlgnMid grey006--text my-3">※&nbsp;인지, 여가 프로그램 <strong>의견수렴은 분기별 1회 이상</strong>, <strong>의견반영은 연 1회 이상</strong></p>
            <p v-else class="txtAlgnMid grey006--text my-3">※&nbsp;의견수렴은 <strong>그룹별 분기 1회 이상</strong>, 의견반영은 <strong>그룹별 연 1회 이상</strong></p>
        </v-sheet>
        <v-sheet
            class="rounded-lg rounded-l-0 overflow-auto"
            min-width="654" max-width="1002">
            <PrgImprv v-if="sltYyyy < 2025" :prtYyyy="sltYyyy" :prtCd40="vCd40"></PrgImprv>
            <PrgImprvAnthr v-else :prtYyyy="sltYyyy" :prtCd40="vCd40"></PrgImprvAnthr>
        </v-sheet>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <OpnnClctnMdl v-if="lclMdlInf.typ == '01'" :prtCd40="vCd40" @offMdl="offOpnnClctnMdl"></OpnnClctnMdl>
            <OpnnRflctMdl v-else-if="lclMdlInf.typ == '02'" :prtCd40="vCd40" @offMdl="offOpnnRflctMdl"></OpnnRflctMdl>
            <OpnnRflctYyMdl v-else-if="lclMdlInf.typ == '03'" :prtCd40="vCd40" @offMdl="offOpnnRflctMdl"></OpnnRflctYyMdl>
        </v-dialog>
    </div>
</template>

<script>
import { http } from '@/api/baseAxios';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import PrgImprv from './PrgImprv.vue';                                            // 2025년 이전
import PrgImprvAnthr from '../../components/prgrm/PrgImprvAnthr.vue';             // 2024년 이후
import OpnnClctnMdl from '../../components/prgrm/OpnnClctnMdl.vue';               // 프로그램 의견수렴
import OpnnRflctMdl from '../../components/prgrm/OpnnRflctMdl.vue';               // 프로그램 의견반영
import OpnnRflctYyMdl from '../../components/prgrm/OpnnRflctYyMdl.vue';           // 프로그램 연별 의견반영

export default {
    name: 'PrgrmvCnfgr',
    
    components: {
        expandYearOnlyPicker,
        PrgImprv,
        PrgImprvAnthr,
        OpnnClctnMdl,
        OpnnRflctMdl,
        OpnnRflctYyMdl,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['40'])

            let calc = this.$vuetify.breakpoint.width-808
            if(calc < 654)
                this.viewWdth = 654
            else if(calc > 653 && calc < 1003)
                this.viewWdth = calc
            else if(calc > 1002)
                this.viewWdth = 1002
        })
    },

    computed: {
        getDspWdth: function () {
            return this.$vuetify.breakpoint.width
        },
    },

    watch: {
        getDspWdth: function (v) {
            let calc = v-808
            if(calc < 654)
                this.viewWdth = 654
            else if(calc > 653 && calc < 1003)
                this.viewWdth = calc
            else if(calc > 1002)
                this.viewWdth = 1002
        },
    },

    methods: {
        // 공통 코드 다중 조회
        getCode: function (arr) {
            let obj = {}
            obj.valClcdS = arr
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        stCdMapping: function (res){
            this.vCd40 = res.vCd40
        },

        // 프로그램 개선 분기 내역 조회
        getPrgmQrtrList: function (yyyy) {
            this.prgmQtrList.splice(0)
            this.prgmImprvList.splice(0)
            Object.keys(this.prgmImprvTot).forEach(itm => {
                this.prgmImprvTot[itm] = 0
            });
            
            this.isLoad = true

            let obj = {}
            obj.inqYYYY = yyyy
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('prgrm/selPrgImprvQrtrList', obj)
                .then((response) => ( this.prgmQrtrRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvQrtrList : ' + error))
        },

        prgmQrtrRst: function (res) {
            if(Array.isArray(res)){
                this.prgmQtrList = res
            }

            this.isLoad = false
        },

        // 프로그램 개선사항 그룹별 건수 조회
        getPrgmImprvQrtrList: function (yyyy) {
            this.prgmQtrList.splice(0)
            this.prgmImprvList.splice(0)
            Object.keys(this.prgmImprvTot).forEach(itm => {
                this.prgmImprvTot[itm] = 0
            });
            
            this.isLoad = true

            let obj = {}
            obj.inqYYYY = yyyy

            http.post('prgrm/selPrgImprvQrtr2025List', obj)
                .then((response) => ( this.prgmImprvQrtrRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgImprvQrtr2025List : ' + error))
        },

        prgmImprvQrtrRst: function (res) {
            if(res.statusCode == '200'){
                for (let index = 1; index < 6; index++) {
                    this.prgmImprvTot['qrtr'+index+'GrpCnt'] = res.result['qrtr'+index+'GrpCnt']
                    this.prgmImprvTot['qrtr'+index+'WrtCnt'] = res.result['qrtr'+index+'WrtCnt']
                }

                this.prgmImprvList = res.result.list
            }

            this.isLoad = false
        },

        getYear: function (obj) {
            this.sltYyyy = obj

            if(obj < 2025)
                this.getPrgmQrtrList(obj)
            else
                this.getPrgmImprvQrtrList(obj)
        },

        // 프로그램 의견수렴(분기단위) 저장 팝업 오픈
        onOpnnClctnMdl: function (obj, qtr) {
            let oObj = {}
            oObj.trgYyyy = this.sltYyyy
            oObj.trgQtr = qtr
            oObj.prgBnfcrgrInfPk = obj.prgBnfcrgrInfPk
            oObj.bnfcrgrNm = obj.bnfcrgrNm
            oObj.idxCd = obj.idxCd
            oObj.idxNm = obj.idxNm
            if(typeof obj.prgImprvPk == 'undefined')
                oObj.prgImprvPk = -1
            else
                oObj.prgImprvPk = obj.prgImprvPk

            this.$store.commit('setTmpObj', oObj)
            this.onLclMdl('01', 1200)
        },

        // 프로그램 의견수렴 저장 팝업 닫기
        offOpnnClctnMdl: function () {
            //if(this.$store.getters.getTmpObj.obj.trgQtr == '')

            this.$store.commit('setTmpObj', {})
            this.getPrgmImprvQrtrList(this.sltYyyy)
            this.rstLclMdlInf()
        },

        // 프로그램 의견반영 저장 팝업 오픈
        onOpnnRflctYyMdl: function (obj) {
            let wObj = {}
            wObj.actv = true                                    // 경고 노출여부
            wObj.tmr = 3000                                     // 경고 노출시간 (-1: 무제한)
            wObj.type = 'warning'                               // 경고 유형 (success, info, warning, error)
            wObj.title = '의견수렴 작성건수가 존재하지 않습니다.'     // 경고 타이틀
            wObj.cntnt = []                                     // 경고 내용 (arr만 출력됨!)

            if((obj.opnQrtr1Cnt + obj.opnQrtr2Cnt + obj.opnQrtr3Cnt + obj.opnQrtr4Cnt) == 0){
                this.$store.commit('setWrnnInf', wObj)
                return
            }

            let oObj = {}
            oObj.trgYyyy = this.sltYyyy
            oObj.idxCd = obj.idxCd
            oObj.idxNm = obj.idxNm
            oObj.prgBnfcrgrInfPk = obj.prgBnfcrgrInfPk
            oObj.bnfcrgrNm = obj.bnfcrgrNm


            this.$store.commit('setTmpObj', oObj)
            this.onLclMdl('03', 1200)
        },

        // 프로그램 의견반영 저장 팝업 닫기
        offOpnnRflctMdl: function () {
            this.$store.commit('setTmpObj', {})
            this.getPrgmImprvQrtrList(this.sltYyyy)
            this.rstLclMdlInf()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        viewWdth: 654,
        sltYyyy: 2000,
        runAble: '0',
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        vCd40: [],
        prgmQtrList: [],
        prgmImprvList: [],
        prgmImprvTot: {
            qrtr1GrpCnt: 0, qrtr2GrpCnt: 0, qrtr3GrpCnt: 0, qrtr4GrpCnt: 0, qrtr5GrpCnt: 0,
            qrtr1WrtCnt: 0, qrtr2WrtCnt: 0, qrtr3WrtCnt: 0, qrtr4WrtCnt: 0, qrtr5WrtCnt: 0, 
        },
        prgmHdr: [
            { text: '지표구분', value: 'idxNm', align: 'center', sortable: false, class: '', style: '' },
            { text: '의견반영', value: 'rflDt', align: 'center', sortable: false, class: '', style: '' },
            { text: '제공', value: 'recQrtr', align: 'center', sortable: false, class: '', style: '' },
            { text: '의견', value: 'opnQrtr', align: 'center', sortable: false, class: '', style: '' },
            { text: '프로그램 유형', value: 'opnQrtr', align: 'center', sortable: false, class: '', style: '' },
            { text: '수급자 그룹', value: 'opnQrtr', align: 'center', sortable: false, class: '', style: '' },
        ],
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },     // 통합 팝업 제어 (01: 의견수렴 등록/삭제)
    }),
};
</script>