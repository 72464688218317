<template>
    <div class="d-flex">
        <v-sheet class="pa-3" width="500" height="835" color="midAreaColor">
            <p class="hamletFont text-h5 topTitleColor--text font-weight-black">
                <span>4-2.&nbsp;프로그램&nbsp;정보관리</span>
            </p>
            <v-row class="pa-0 mt-4" no-gutters>
                <exBtn ref="exBtnClose" :btnData="exBtn[0]" @changeCheck="filterSearchD1" />
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 150px; height: 34px;">
                    <v-text-field
                        @input="setSearchText"
                        class="txt-Theme" height="34" label="프로그램명 조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-row>
            <v-divider class="midAreaColor my-1"></v-divider>
            <v-data-table
                class="dft-hvr-tbl" height="684" locale="ko"
                :headers="headers" :items="prgrmList"
                :search="schDummy" :custom-filter="customFilter"
                fixed-header hide-default-header disable-pagination hide-default-footer dense>

                <template v-slot:header="props">
                    <th class="black--text bottomBorder px-1 py-3" style="width: 130px;">{{ props.props.headers[0].text }}</th>
                    <th class="black--text bottomBorder px-1 py-3" style="width: 100px;">{{ props.props.headers[1].text }}</th>
                    <th class="black--text bottomBorder px-1 py-3" style="width: 196px;">{{ props.props.headers[2].text }}</th>
                </template>
                <template v-slot:item='{ item, index }'>
                    <tr :class="[selectedItem == index ? 'onClr' : '']"  @click="tableClickEvent(index, item.prgInfPk)">
                        <td class="txtAlgnMid tableBB px-1 pt-1">
                            <span class="d-inline-block fontOneRem overflow-text-hidden" style="width: 122px;">
                                {{ item.prgTypNm }}
                            </span>
                        </td>
                        <td class="txtAlgnMid tableBB">
                            <v-chip
                                class="" style="padding-bottom: 2px;"
                                :ripple="false" :color="'level0' + item.idxCd" small>
                                <span class="fontOneRem font-weight-medium white--text">{{ item.idxCdnm?.slice(0, 2) }}</span>
                            </v-chip>
                        </td>
                        <td class="txtAlgnMid tableBB py-1" style="line-height: 1.3;">
                            <span class="fontOneRem">{{ item.prgNm }}</span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-divider class="midAreaColor my-1"></v-divider>
            <v-row class="pa-0 justify-end" no-gutters>
                <v-checkbox
                    class="dftChcBox-Theme pt-0 mr-1" style="margin-top: 3px !important;"
                    v-model="chcInclude" @change="reset"
                    true-value="1" false-value="2" label="미사용 포함"
                    on-icon="mdi-checkbox-marked-outline" off-icon="mdi-checkbox-blank-outline"
                    hide-details>
                </v-checkbox>
            </v-row>
        </v-sheet>

        <v-sheet
            class="rounded-lg rounded-l-0 overflow-auto"
            height="835" min-width="800" max-width="850">
            <router-view
                ref="callDetail"
                @reload="reset()" @trColorChange="trColorChange()">
            </router-view>
        </v-sheet>
    </div>
</template>

<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';

import { http } from '@/api/baseAxios';

export default {
    watch: {
        
    },

    components: {
        exBtn,
    },
    created: function () {
       
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCdPrgInflist()
        })
    },
    methods: {
        //프로그램정보 다건 조회(코드포함)
        getCdPrgInflist:function(){
            this.exBtn[0].expandList.splice(0)
            this.prgrmList.splice(0)
            this.prgTyplist.splice(0)

            let obj = {}
            obj.useYn = '1'

            http.post('prgrm/selCdPrgInflist', obj)
                .then((response) => ( this.rstCdPrgInflist(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selCdPrgInflist : ' + error))
        },

        //프로그램정보 다건 조회
        getPrgInflist:function(){
            this.prgrmList.splice(0)

            let obj = {}
            if(this.chcInclude == '1')
                obj.useYn = null
            else
                obj.useYn = '1'

            http.post('prgrm/selPrgInflist', obj)
                .then((response) => ( this.rstPrgInflist(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selCdPrgInflist : ' + error))
        },

        //프로그램 유형 조회
        getTyplist:function(){
            this.prgTyplist.splice(0)
            let obj = {}

            http.post('prgrm/selPrgTypMnglist', obj)
                .then((response) => ( this.rstTyplist(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgTypMnglist : ' + error))
        },

        //결과 :: 프로그램정보 다건 조회(코드포함)
        rstCdPrgInflist:function(res){
            if(res.statusCode == '200'){
                if(res.result != null){
    
                    if(res.result.codes != null){
                        if(res.result.codes.vCd40 != null && res.result.codes.vCd40 != undefined){
                           res.result.codes.vCd40.forEach((code) => {
                                this.exBtn[0].expandList.push(code.valcdnm)
                                this.vCd40.push(code)
                            });//end foreach
                        }

                        if(res.result.codes.vCd38 != null && res.result.codes.vCd38 != undefined){
                            res.result.codes.vCd38.forEach((code) => {
                                this.vCd38.push(code)
                            });//end foreach
                        }

                        if(res.result.codes.vCd41 != null && res.result.codes.vCd41 != undefined){
                            res.result.codes.vCd41.forEach((code) => {
                                this.vCd41.push(code)
                            });//end foreach
                        }

                        if(res.result.codes.vCd42 != null && res.result.codes.vCd42 != undefined){
                            res.result.codes.vCd42.forEach((code) => {
                                this.vCd42.push(code)
                            });//end foreach
                        }

                        if(res.result.codes.vCd65 != null && res.result.codes.vCd65 != undefined){
                            res.result.codes.vCd65.forEach((code) => {
                                this.vCd65.push(code)
                            });//end foreach
                        }
                    }

                    if(res.result.prgTyplist != null){
                        
                        res.result.prgTyplist.forEach(itm => {
                            this.prgTyplist.push(itm)
                        });//end foreach
                    }
    
    
                    if(res.result.list != null){
    
                        res.result.list.forEach(itm => {
    
                            this.vCd40.forEach(cd => {
                                if(cd.valcd == itm.idxCd) itm.idxCdnm = cd.valcdnm
                            });
    
                            this.prgrmList.push(itm)
                        });
                    }
    
                }                
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'              // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //결과 :: 프로그램정보 다건 조회
        rstPrgInflist:function(res){
            if(res != null){

                res.forEach(itm => {

                    this.vCd40.forEach(cd => {
                        if(cd.valcd == itm.idxCd) itm.idxCdnm = cd.valcdnm
                    });

                    this.prgrmList.push(itm)
                });

            }
        },

        //결과 :: 프로그램 유형관리 조회
        rstTyplist:function(res){
            res.forEach(itm => {
                this.prgTyplist.push(itm)
            });//end foreach
        },

        reset: function (pk = 0) {
            this.getPrgInflist()
            this.selectedItem = -1

            if(pk == -1) this.sltPk = 0
            else this.sltPk         = -1
        },
    
        tableClickEvent: function (rn, pk) {
            if (this.selectedItem == -1) {
                this.selectedItem   = rn
                this.sltPk          = pk
            }
            else if (this.selectedItem != rn) {
                this.selectedItem   = rn
                this.sltPk          = pk
            }
            else {
                //Form Clear
                this.selectedItem = -1
                this.sltPk        = -1
            }
        },
        trColorChange: function () {
            this.selectedItem = -1
            this.sltPk        = -1
        },
        filterSearchD1: function (sch) {
            if (sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.prgNm.includes(this.schField)
            
            let col1 = true
            if (this.schChkField1.length != 0) {
                this.schChkField1.forEach((idxCdnm1, i) => {
                    if (i == 0) col1 = filter.idxCdnm.includes(idxCdnm1)
                    else col1 = col1 || filter.idxCdnm.includes(idxCdnm1)
                });
            }

            return col0 && col1;
        },
    },

    data: () => ({
        schField            : '',
        selectedItemIndex   : [],
        selectedItem        : -1,
        sltPk               : -1,
        prgrmList           : [],
        chkHisPk            : 0,
        vCd38               : [],
        vCd40               : [],
        vCd41               : [],
        vCd42               : [],
        vCd65               : [],
        prgTyplist          : [],
        schChkField1        : [],
        schDummy            : '',
        chcInclude          : '2',
        exBtn: [
            { btnTitle: '프로그램 유형', btnIcon: 'category', exWidth: 144, exHeigth: 130, expandList: [] },
        ],
        headers: [
            { text: '프로그램 구분', value: 'prgTypNm', sortable: true, width: '140', class: 'black--text bottomBorder py-3', align: 'center', },
            { text: '프로그램 유형', value: 'idxCd', sortable: true, width: '110', class: 'black--text bottomBorder', align: 'center', },
            { text: '프로그램', value: 'prgNm', sortable: true, width: '180', class: 'black--text bottomBorder', align: 'center', },
        ],
    })
}
</script>