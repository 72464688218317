<template>
    <v-sheet class="ml-0" min-width="" max-width="">       
        <v-row class="pa-0 mt-2 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">프로그램 제공 현황</span>
        </v-row>
        <v-divider :class="['mt-4', prgTblRowCnt > 17 ? 'ml-2 mr-2__5' : 'mx-2']"  color="black"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', prgTblRowCnt > 17 ? 'ml-2 mr-2__5' : 'mx-2']" no-gutters>
            <v-col class="txtAlgnMid tableBR" cols="1">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col cols="11">
                <v-row no-gutters>
                    <v-col class="txtAlgnMid tableBR" cols="1">
                        <span class="font-weight-medium">제공월도</span>
                    </v-col>
                    <v-col class="txtAlgnMid" cols="11">
                        <v-row no-gutters>
                            <v-col class="txtAlgnMid tableBR" cols="2">
                                <span class="font-weight-medium">제공주간</span>
                            </v-col>
                            <v-col class="txtAlgnMid" cols="10">
                                <v-row no-gutters>
                                    <v-col class="txtAlgnMid tableBR" cols="2">
                                        <span class="font-weight-medium">프로그램유형</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR" cols="3">
                                        <span class="font-weight-medium">제공일시</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR" cols="3">
                                        <span class="font-weight-medium">프로그램명</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR" cols="2">
                                        <span class="font-weight-medium">진행자</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid tableBR" cols="1">
                                        <span class="fontNineThRem font-weight-medium">참여여부</span>
                                    </v-col>
                                    <v-col class="txtAlgnMid" cols="1">
                                        <span class="fontNineThRem font-weight-medium">상세</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider :class="[prgTblRowCnt > 17 ? 'ml-2 mr-2__5' : 'mx-2']"></v-divider>
        <div v-if="prgGrpList.length > 0"  :class="['overflow-y-auto', prgTblRowCnt > 17 ? 'pl-2' : 'px-2']" style="width: 100%; height: 610px;">
            <v-progress-linear v-if="this.$parent.$parent.$parent.$parent.ldr" class="" color="cyan" indeterminate></v-progress-linear>            
            <v-row v-for="(bnfcr, i) in prgGrpList" :key="i" 
                class="pa-0 " 
                no-gutters>
                <v-col class="d-flex align-center justify-center tableBB tableBR" cols="1">
                    <span class="fontOneRem">{{bnfcr.name}}</span>
                </v-col>
                <v-col cols="11">
                    <v-row v-for="(opym, j) in bnfcr.opYymmList" :key="j" 
                        class="pa-0 tableBB"
                        no-gutters>
                        <v-col class="d-flex align-center justify-center tableBR py-1" cols="1">
                            <span class="fontOneRem">{{$moment(opym.opYymm, 'YYYYMM').format('YYYY.MM')}}</span>
                        </v-col>
                        <v-col cols="11">
                            <v-row v-for="(wk, m) in opym.wkList" :key="m" 
                                :class="['pa-0', opym.wkList.length != (m+1) ? 'tableBB' : '']"
                                no-gutters>
                                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                    <span class="fontOneRem">{{$moment(wk.wkStart, 'YYYYMMDD').format('MM.DD')}}</span>
                                    <span> ~ </span>
                                    <span class="fontOneRem">{{$moment(wk.wkEnd, 'YYYYMMDD').format('MM.DD')}}</span>
                                </v-col>
                                <v-col class="" cols="10">
                                    <v-row v-for="(prg, n) in wk.prgList" :key="n" 
                                        :class="['pa-0', wk.prgList.length != (n+1) ? 'tableBB' : '']"
                                        no-gutters>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                            <v-chip
                                                class="" style="padding-bottom: 2px;"
                                                :ripple="false" :color="'level0' + prg.idxCd" small>
                                                <span class="fontOneRem font-weight-medium white--text">{{ prg.idxCdNm?.slice(0, 2) }}</span>
                                            </v-chip>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="3">
                                            <span class="fontOneRem pr-1">{{$moment(prg.opDt, 'YYYYMMDD').format('MM.DD')}}</span>
                                            <span class="fontOneRem">&#40;</span>
                                            <span class="fontOneRem">{{prg.opTmHhStrt}}:{{prg.opTmMmStrt}}</span>
                                            <span> ~ </span>
                                            <span class="fontOneRem">{{prg.opTmHhEnd}}:{{prg.opTmMmEnd}}</span>
                                            <span class="fontOneRem">&#41;</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1 text-overflow" cols="3">
                                            <span class="fontOneRem">{{prg.prgNm}}</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="2">
                                            <span class="fontOneRem">{{prg.mc}}</span>
                                        </v-col>
                                        <v-col class="txtAlgnMid tableBR py-1" cols="1">
                                            <span v-if="prg.prtcpYn == '1'">
                                                <v-icon color="blue" small>mdi-check-circle</v-icon>
                                            </span>
                                            <span v-else>
                                                <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                            </span>
                                        </v-col>
                                        <v-col class="txtAlgnMid py-1" cols="1">                                            
                                            <v-btn
                                                color="grey006" height="22"
                                                @click="showDialog(prg.prgOpPlnPk, prg.prgOpRecPk, prg.prgInfPk)"
                                                icon>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                            <v-icon size="18">mdi-square-edit-outline </v-icon>
                                                        </div>
                                                    </template>
                                                    <span>{{prg.prgNm}} 작성상세</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="px-2" no-gutters>
            <v-col class="txtAlgnMid py-2 tableBB" cols="12">
                <span class="fontOneRem grey006--text">※ 프로그램 제공 현황이 존재하지 않습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PrgBnfcrPrtcpSttsTab1',

    props: {
        
    },
    components: {
        
    },

    computed: {     
        getSltbnfcrList: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected
        },   
        getStartYYMM: function () {
            return this.$parent.$parent.$parent.$parent.startYYMM
        },
        getEndYYMM: function () {
            return this.$parent.$parent.$parent.$parent.endYYMM
        },
    },
    
    watch: {        
        getSltbnfcrList: {
            deep: true,
            handler: function (value){
                let pkS = []
                value.forEach(items => {
                    pkS.push(items.bnMmbrPk)
                });
              
                if(pkS.length == 0) {
                    this.prgGrpList.splice(0)
                    this.prgTblRowCnt = 0
                }
                else if(pkS.length > 0) {
                    if(pkS.length > 3) {
                        this.onAlrt('수급자 3명이상 선택 할 수 없습니다.')
                    } else {
                        this.bnMmbrPks = pkS                    
                        this.getBtcInqPrgPrtcpList()
                    }
                }
            }
        },
        getStartYYMM:function() {
            let pkS = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                pkS.push(items.bnMmbrPk)
            });

            if(pkS.length > 0) {
                if(pkS.length > 3) {
                    this.onAlrt('수급자 3명이상 선택 할 수 없습니다.')
                } else {
                    this.bnMmbrPks = pkS                    
                    this.getBtcInqPrgPrtcpList()
                }
            }
        },
        getEndYYMM :function()  {
            let pkS = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                pkS.push(items.bnMmbrPk)
            });

            if(pkS.length > 0) {
                if(pkS.length > 3) {
                    this.onAlrt('수급자 3명이상 선택 할 수 없습니다.')
                } else {
                    this.bnMmbrPks = pkS                    
                    this.getBtcInqPrgPrtcpList()
                }
            }
        }
    },

    created: function () {
    },

    mounted: function () {
        this.$nextTick(function () {
            
        })
    },

    methods: {
        getBtcInqPrgPrtcpList:function(){
            this.$parent.$parent.$parent.$parent.ldr = true
            this.prgGrpList.splice(0)
            this.prgTblRowCnt = 0

            if(this.bnMmbrPks.length > 0){
                let obj = {
                    bnMmbrPks   : this.bnMmbrPks,  
                    inqYmdStart : this.$parent.$parent.$parent.$parent.startYYMM+'01',  
                    inqYmdEnd   : this.$moment(this.$parent.$parent.$parent.$parent.endYYMM,'YYYYMM').endOf('month').format('YYYYMMDD')
                }
    
                http.post('prgrm/selBtcInqPrgPrtcpList', obj)
                    .then((response) => ( this.btcinqPrgPrtcpListRst(response.data) ))
                    .catch((error) => console.log('connect error /prgrm/selBtcInqPrgPrtcpList : ' + error))
            }
        },
        btcinqPrgPrtcpListRst:function(res){
            if(res.statusCode == '200') {
                if(res.result.code != null && res.result.code != undefined)
                    this.vCd40 = res.result.code.vCd40

                res.result.list.forEach(el => {
                    el.opYymmList.forEach(itm => {
                        itm.wkList.forEach(wk => {
                           this.prgTblRowCnt += wk.prgList.length

                           wk.prgList.forEach(prg => {
                                let chc = this.vCd40.findIndex( v => v.valcd == prg.idxCd )
                                if(chc > -1)
                                prg.idxCdNm = this.vCd40[chc].valcdnm
                           });
                           
                        });
                    });
                    this.prgGrpList.push(el)
                });
                
            }
            else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀 
                this.$store.commit('setWrnnInf', obj)
            }
            this.$parent.$parent.$parent.$parent.ldr = false
        },
        showDialog: function (plnPk, recPk, prgInfPk) {
            let obj = {}
            obj.prgOpPlnPk = plnPk
            obj.prgOpRecPk = recPk
            obj.prgInfPk = prgInfPk

            this.plnPk = plnPk
            this.recPk = recPk
            this.prgInfPk = prgInfPk

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: obj, outObj: null, runEvt: false,
            });
        },
        onAlrt:function(msg, timer = 3000){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = timer                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg        // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },
    },
    data: () => ({
        bnMmbrPks       : [],
        prgGrpList      : [],
        vCd40           : [],
        prgTblRowCnt    : 0,
    }),
};
</script>