<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md" style="height: 712px;">
        <v-row class="pa-0 mt-3 mb-3 align-center posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">야간점검일지 내역</span>
            <v-spacer></v-spacer>
            <v-btn                
                class="white--text" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor"
                :disabled="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == -1"
                @click="onLclMdl('11', 1300)"
                min-width="20" height="20" small outlined>
                <span class="fontNineThRem" style="padding-bottom: 2px;">점검일지 작성</span>
            </v-btn>
            <v-btn                
                class="white--text ml-2" style="padding: 9px 7px 8px 5px !important;"
                color="grey006"
                @click="$parent.$parent.$parent.$parent.prtNtyInspJrnls('65')"
                :disabled="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == -1"
                min-width="20" height="20" small outlined>
                <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">출력</span>
            </v-btn>
        </v-row>
        <v-divider :class="[nghDtlSttList.length > 14 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['nmlBfClrImp-greyE01 pa-0', nghDtlSttList.length > 14 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="4">
                <span class="fontOneDotOneRem font-weight-bold">점검구역</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">점검회차</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">점검시간</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">점검일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                <span class="fontOneDotOneRem font-weight-bold">점검자</span>
            </v-col>
            <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">작성</span>
            </v-col>
        </v-row>
        <v-divider :class="[nghDtlSttList.length > 14 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="nghDtlSttList.length > 0" class="overflow-y-auto" style="width: 100%; height: 585px;">
            <v-row
                class="tableBB"
                v-for="(itm, i) in nghDtlSttList" :key="i"
                no-gutters>               

                <v-col class="tableBR pb-2 pl-2" style="padding-top: 6px;" cols="4">
                    <span class="fontOneRem">{{ itm.inspAreaNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                    <span class="fontOneRem">{{ itm.rn }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                    <span v-if="itm.inspTmHh == null || itm.inspTmMm == null" class="fontOneRem red--text">미작성</span>
                    <span v-else class="fontOneRem">{{ itm.inspTmHh+':'+itm.inspTmMm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">                    
                    <span v-if="itm.inspDt == null" class="fontOneRem red--text">미작성</span>
                    <span v-else class="fontOneRem">{{ itm.inspDtlDt}}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="2">
                    <span v-if="itm.wrtr == null" class="fontOneRem red--text">미작성</span>
                    <span v-else class="fontOneRem">{{ itm.wrtr}}</span>
                </v-col>
                <v-col class="txtAlgnMid pb-2" style="padding-top: 6px;" cols="1">
                    <v-btn v-if="itm.inspAreaPk == null"               
                        class="white--text" style="padding: 9px 7px 8px 5px !important;"
                        color="red"                        
                        min-width="20" height="20" small outlined>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">미작성</span>
                    </v-btn>
                    <v-btn v-else
                        color="grey006" height="26"     
                        @click="onNghChc(itm)"   
                        :disabled="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == -1"
                        icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-square-edit-outline </v-icon>
                                </div>
                            </template>
                            <span>{{ itm.inspAreaNm }} 야간점검일지 작성상세</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 그룹정보가 없습니다.</span>
            </v-col>
        </v-row>
        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <NghChcMdl
                v-if="lclMdlInf.typ == '11'"
                ref="pMSelector" :isUpd="true"
                :inqymd="$parent.$parent.$parent.$parent.$parent.$parent.selectedItem"
                :nghList="nghDtlSttList"
                :areaList="areaItms"
                :sltOrd="sltInspOrd"
                :sltAreaPk="sltInspAreaPk"
                @isClose="rstLclMdlInf">
            </NghChcMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import NghChcMdl from './NghChcMdl.vue';                // 야간점검일지

export default {
    name: 'NghChcNLog',

    props : {
       
    },
        
    components: {
        NghChcMdl
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {    
            this.gelInspRecDtlSttlist()    
        })
    },

    computed: {
        getSltInspRecPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
        },
        getSltInspRecDt: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltInspRecDt:function(){
            this.gelInspRecDtlSttlist()
        },
    },
    
    methods: {
        // 야간점검일지현황 내역
        gelInspRecDtlSttlist: function (){ 
            this.nghDtlSttList.splice(0)
            let inqymd =  this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
            if(inqymd != -1 && inqymd != undefined) {
                inqymd = this.$moment(this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem,'YYYY.MM.DD').format('YYYYMMDD')
            
                let obj = {
                    inqYMD:inqymd
                }

                http.post('sfty/selInspRec501DtlSttlist', obj)
                    .then((response) => ( this.inspRecDtlSttlistRst(response.data) ))
                    .catch((error) => console.log('connect error /sfty/selInspRec501DtlSttlist : ' + error))
            } 
        },

        inspRecDtlSttlistRst:function(res){
            if(res.statusCode == '200'){
                if(res.result.list != null) {
                    res.result.list.forEach(itm => {
                        itm.inspDt = itm.inspDt?.beforeDateFormatDot()
                        itm.inspDtlDt = itm.inspDtlDt?.beforeDateFormatDot()
                        this.nghDtlSttList.push(itm)
                    });
                }

                if(res.result.areaItms != null) {
                    this.areaItms = res.result.areaItms
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
            this.sltInspOrd      = -1
            this.sltInspAreaPk   = -1
            this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
            // this.gelInspRecDtlSttlist()
        },

        onNghChc:function(itm){            
            this.sltInspOrd = itm.rn
            this.sltInspAreaPk = itm.inspAreaPk            
            this.onLclMdl('11', 1300)
        },
    },

    data: () => ({
        sltDate         : '',
        sltInspOrd      : -1,
        sltInspAreaPk   : -1,
        nghDtlSttList   : [],
        lclMdlInf       : { isOn: false, maxWidth: 800, typ: '' },   
        areaItms        : [],
    }),
};
</script>