<template>
    <v-sheet class="ma-0 pa-7 posn-rltv" rounded="lg">
        <!-- 가림판 -->
        <div
            v-if="dspAtv"
            class="white rounded-lg"
            style="position: absolute; top: 82px; left: 20px; width: 1360px; height: 690px; z-index: 98;">
            <!-- <v-skeleton-loader
                class=""
                max-width="1356"
                type="article, actions">
            </v-skeleton-loader>
            <v-skeleton-loader
                class="mx-4"
                max-width="1356"
                type="card-avatar">
            </v-skeleton-loader>
            <v-divider class="white my-12"></v-divider> -->
            <v-divider class="white" style="margin-top: 320px;"></v-divider>
            <v-row class="justify-center">
                <span class="fontTwoRem font-weight-medium grey006--text">
                    로딩중입니다.&nbsp;&nbsp;&nbsp;&nbsp;잠시 기다려주세요.
                </span>
            </v-row>
        </div>
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">욕구사정 평가</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$emit('isClose', true)" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">수급자</span>
            </v-col>
            <v-col class="overflow-text-hidden txtAlgnMid tableBR px-1" style="padding-top: 6px;" cols="2">
                <span v-if="bscsInf.name != ''" class="fontOneRem">{{ bscsInf.name }}&nbsp;&#40;&nbsp;</span>
                <span v-if="bscsInf.gndr == 'M'" class="fontOneRem">남</span>
                <span v-else-if="bscsInf.gndr == 'F'" class="fontOneRem">여</span>
                <span v-if="typeof bscsInf.age == 'number'" class="fontOneRem">&nbsp;&#47;&nbsp;{{ bscsInf.age }}세&nbsp;&#41;</span>
            </v-col>
            <v-col cols="9">
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                        <span class="fontOneDotOneRem font-weight-bold">입소일</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                        <span class="fontOneRem">{{ bscsInf.entDtDot }}</span>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                        <span class="fontOneDotOneRem font-weight-bold">주요질환</span>
                    </v-col>
                    <v-col class="overflow-text-hidden px-3" style="padding-top: 6px;" cols="8">
                        <span class="fontOneRem">{{ bscsInf.majorDsss }}</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-bold">조사사유</span>
            </v-col>
            <v-col class="tableBR px-1" style="padding-top: 3px;" cols="2">
                <v-radio-group
                    v-model="bscsInp.examRsncd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio class="px-0 mr-1" style="padding-bottom: 2px !important;" :ripple="false" label="신규" value="1"></v-radio>
                    <v-radio class="px-0 mr-1" style="padding-bottom: 2px !important;" :ripple="false" label="재사정" value="2"></v-radio>
                    <v-radio class="px-0 mr-1" style="padding-bottom: 2px !important;" :ripple="false" label="상태변화" value="3"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="9">
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center py-2" cols="1">
                        <span class="font-weight-bold posn-rltv">
                            작성일
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-2" cols="2">
                        <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                            <CmmDateComp
                                v-model="bscsInp.examDt"
                                :required="true" :minDt="minExamDt"
                                @input="aplctSavBtn">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="1">
                        <span class="font-weight-bold posn-rltv">
                            작성자
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="d-flex align-center tableBR px-2" cols="2">
                        <span class="d-inline-block" style="width: 120px;">
                            <v-text-field
                                v-model="bscsInp.wrtr" @input="aplctSavBtn"
                                class="" height="28" color="blue"
                                hide-details readonly outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" style="padding: 0px 1px 0px 2px !important;"
                            min-width="30" height="25" @click="onLclMdl('01', 800)"
                            :disabled="/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInp.examDt) == false"
                            small>
                            <v-icon color="white" size="20">mdi-account-edit</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="3">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pb-2" style="padding-top: 8px;" cols="4">
                                <span class="fontOneDotOneRem font-weight-bold">처리</span>
                            </v-col>
                            <v-col class="d-flex align-center px-4" style="padding-top: 2px;" cols="8">
                                <v-btn
                                    v-if="bscsInf.bsrEvltnPk < 1"
                                    class="white--text mr-4" style="padding: 9px 7px 8px 5px !important;"
                                    color="green001" :disabled="dspAtv"
                                    @click="getPrvsExamList"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-text-box-search-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">이전자료</span>
                                </v-btn>
                                <v-btn
                                    class="white--text mr-4" style="padding: 9px 7px 8px 5px !important;"
                                    color="blueBtnColor" :disabled="savBtn"
                                    @click="dcsnSavBtn"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-content-save-edit-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">저장</span>
                                </v-btn>
                                <v-btn
                                    class="white--text mr-4" style="padding: 9px 7px 8px 5px !important;"
                                    color="red" :disabled="delBtn"
                                    @click="rflExamBtn"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-trash-can-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">삭제</span>
                                </v-btn>
                                <v-btn
                                    class="white--text" style="padding: 9px 7px 8px 5px !important;"
                                    color="grey006" :disabled="delBtn"
                                    @click="rprtActCall"
                                    min-width="20" height="20" small outlined>
                                    <v-icon class="mr-1" size="14">mdi-printer-outline</v-icon>
                                    <span class="fontNineThRem" style="padding-bottom: 2px;">출력</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-tabs
            v-model="tab"
            class="tab-vertical-Theme rounded-lg" active-class="blue600 white--text"
            hide-slider vertical>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">1. 일반상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">2. 주요질병상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">3. 신체상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">4. 재활상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">5. 간호처치상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">6. 구강상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">7. 영양상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">8. 인지상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">9. 의사소통</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">10. 가족 및 환경상태</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">11. 자원이용</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">12. 수급자 및 보호자 개별욕구</span>
            </v-tab>
            <v-tab class="rounded-lg" :ripple="false">
                <span class="fontOneDotOneRem">13. 종합의견</span>
            </v-tab>

            <v-tab-item class="pl-4" :transition="false" eager>
                <GnrlState ref="gnrlState" :recList="[...rec4List, ...rec12List]"></GnrlState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <MajorDssState ref="majorDssState" :recList="rec2List"></MajorDssState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <PhysState ref="physState" :recList="rec1List"></PhysState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <RhblState ref="rhblState" :recList="rec8List"></RhblState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <NrsnCareState ref="nrsnCareState" :recList="rec7List"></NrsnCareState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <ToothState ref="toothState" :recList="rec9List"></ToothState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <NtrtState ref="ntrtState" :recList="rec10List"></NtrtState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <CgntnState ref="cgntnState" :recList="rec3List"></CgntnState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <CmncState ref="cmncState" :recList="rec13List"></CmncState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <EnvrnState ref="envrnState" :recList="rec5List"></EnvrnState>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <UseOfRsr ref="useOfRsr" :recList="rec11List"></UseOfRsr>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <IndvNeds ref="indvNeds" :recList="rec6List"></IndvNeds>
            </v-tab-item>
            <v-tab-item class="pl-4" :transition="false" eager>
                <CmprOpnn ref="cmprOpnn" :recList="rec999List"></CmprOpnn>
            </v-tab-item>
        </v-tabs>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <EmpSelector
                v-if="lclMdlInf.typ == '01'"
                @isClose="rstLclMdlInf" @outEmp="getEmpInf"
                :prtWrtDt="bscsInp.examDt">
            </EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';

import GnrlState from './DsrEvltnTab/GnrlState.vue';                    // 1.일반상태
import MajorDssState from './DsrEvltnTab/MajorDssState.vue';            // 2.주요질병상태
import PhysState from './DsrEvltnTab/PhysState.vue';                    // 3.신체상태
import RhblState from './DsrEvltnTab/RhblState.vue';                    // 4.재활상태
import NrsnCareState from './DsrEvltnTab/NrsnCareState.vue';            // 5.간호처치상태
import ToothState from './DsrEvltnTab/ToothState.vue';                  // 6.구강상태
import NtrtState from './DsrEvltnTab/NtrtState.vue';                    // 7.영양상태
import CgntnState from './DsrEvltnTab/CgntnState.vue';                  // 8.인지상태
import CmncState from './DsrEvltnTab/CmncState.vue';                    // 9.의사소통
import EnvrnState from './DsrEvltnTab/EnvrnState.vue';                  // 10.가족및환경상태
import UseOfRsr from './DsrEvltnTab/UseOfRsr.vue';                      // 11.자원이용
import IndvNeds from './DsrEvltnTab/IndvNeds.vue';                      // 12.수급자 및 보호자 개별 욕구
import CmprOpnn from './DsrEvltnTab/CmprOpnn.vue';                      // 13.종합의견

export default {
    name: 'DsrEvltnAvrInd',
    
    components: {
        CmmDateComp,
        EmpSelector,
        GnrlState,
        MajorDssState,
        PhysState,
        RhblState,
        NrsnCareState,
        ToothState,
        NtrtState,
        CgntnState,
        CmncState,
        EnvrnState,
        UseOfRsr,
        IndvNeds,
        CmprOpnn,
    },

    props : { 
   
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getTmpObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                this.rmBnfcrExam()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },
        
    methods: {
        // 수급자 평가 내역 저장
        setBnfcrExam: function (obj){
            http.post('bnftoffer/insbnfcrExamInpt', obj)
                .then((response) => ( this.examRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/insbnfcrExamInpt : ' + error))
        },

        examRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                if(this.bscsInf.bsrEvltnPk < 1)
                    this.$emit('isClose', true)
                else
                    this.getBnfcrExamList()
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
            this.savBtn = false
            this.delBtn = false
            this.dspAtv = false
        },

        // 수급자 평가 내역 삭제
        rmBnfcrExam: function (){
            this.dspAtv = true
            this.savBtn = true
            this.delBtn = true

            let obj = {}
            obj.bnfcrExamHisPk = this.bscsInf.bsrEvltnPk

            this.bscsInf.bsrEvltnPk = 0

            http.post('bnftoffer/delBnfcrExamHis', obj)
                .then((response) => ( this.examRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/delBnfcrExamHis : ' + error))
        },

        // 수급자 급여제공계획 및 결과평가 이전자료 조회
        getPrvsExamList: function (){
            this.dspAtv = true
            this.clrTabInf()
            this.clrList()

            let obj = {}
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.examClcd = '101'
            obj.examYyyy = this.bscsInf.examYyyy

            http.post('bnftoffer/preBnfcrExamInpt', obj)
                .then((response) => ( this.bnfcrExamRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/preBnfcrExamInpt : ' + error))
        },

        // 수급자 급여제공계획 및 결과평가 조회
        getBnfcrExamList: function (){
            this.clrTabInf()
            this.clrList()

            let obj = {}
            obj.bnfcrExamHisPk = this.bscsInf.bsrEvltnPk
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.examClcd = '101'

            http.post('bnftoffer/selbnfcrExamInpt', obj)
                .then((response) => ( this.bnfcrExamRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selbnfcrExamInpt : ' + error))
        },

        bnfcrExamRst: function (res){
            if(res.statusCode == '200'){
                this.bscsInp.examRsncd = res.result.examRsncd
                if(res.result.examDt == '')
                    this.bscsInp.examDt = this.$moment().format('YYYY-MM-DD')
                else
                    this.bscsInp.examDt = this.$moment(res.result.examDt, 'YYYYMMDD').format('YYYY-MM-DD')
                this.bscsInp.wrtr = res.result.wrtr
                this.bscsInp.wrtrPk = -1

                if(res.result.list.length > 0){
                    res.result.list.forEach(itm => {
                        switch (itm.depth2) {
                            case '1':
                                this.rec1List.push(itm)
                                break;
                            case '2':
                                this.rec2List.push(itm)
                                break;
                            case '3':
                                this.rec3List.push(itm)
                                break;
                            case '4':
                                this.rec4List.push(itm)
                                break;
                            case '5':
                                this.rec5List.push(itm)
                                break;
                            case '6':
                                this.rec6List.push(itm)
                                break;
                            case '7':
                                this.rec7List.push(itm)
                                break;
                            case '8':
                                this.rec8List.push(itm)
                                break;
                            case '9':
                                this.rec9List.push(itm)
                                break;
                            case '10':
                                this.rec10List.push(itm)
                                break;
                            case '11':
                                this.rec11List.push(itm)
                                break;
                            case '12':
                                this.rec12List.push(itm)
                                break;
                            case '13':
                                this.rec13List.push(itm)
                                break;
                            case '999':
                                this.rec999List.push(itm)
                                break;
                            default:
                                break;
                        }
                    });
                }
            }

            this.dspAtv = false
            this.aplctSavBtn()
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.entDt = obj.entDt
            this.bscsInf.entDtDot = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bscsInf.majorDsss = obj.majorDsss
            this.bscsInf.bsrEvltnPk = obj.bsrEvltnPk
            this.bscsInf.examYyyy = obj.examYyyy

            if(obj.isUpd == false)
                this.isAction = false

            if(this.bscsInf.bsrEvltnPk == 0){
                this.bscsInp.examRsncd = '1'
                this.delBtn = true
            }
            else if(this.bscsInf.bsrEvltnPk > 0 && obj.isUpd == true)
                this.delBtn = false

            if(this.$moment(obj.entDt, 'YYYYMMDD').isBefore('2025-01-01'))
                this.minExamDt = '2025-01-01'
            else
                this.minExamDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')

            if(this.bscsInf.bsrEvltnPk > 0)
                this.getBnfcrExamList()
            else{
                this.bscsInp.examDt = this.$moment().format('YYYY-MM-DD')
                this.dspAtv = false
            }
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(this.isAction == false)
                return 0

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInp.examDt) == false)
                return 0
            if(this.bscsInp.examRsncd == '')
                return 0
            if(this.bscsInp.wrtr == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true         // 경고 노출여부
            obj.tmr = 3000          // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'       // 경고 유형 (success, info, warning, error)
            obj.title = ''          // 경고 타이틀
            obj.cntnt = []          // 경고 내용 (arr만 출력됨!)

            let tmpDt = this.$moment(this.bscsInp.examDt, 'YYYY-MM-DD')
            if( tmpDt.isBefore(this.$moment('2025-01-01', 'YYYY-MM-DD')) ){
                obj.title = '현재 팝업은 2025년 부터 작성 가능한 팝업입니다.'
                obj.type = 'warning'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            if( tmpDt.isBefore(this.$moment(this.bscsInf.entDt, 'YYYY-MM-DD')) ){
                obj.title = '작성일은 입소일 이후로 입력해주세요.'
                obj.type = 'warning'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            this.dspAtv = true
            this.savBtn = true
            this.delBtn = true

            let oObj = {}
            oObj.bnfcrExamHisPk = this.bscsInf.bsrEvltnPk
            oObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            oObj.examClcd = '101'
            oObj.examRsncd = this.bscsInp.examRsncd
            oObj.examYyyy = this.$moment(this.bscsInp.examDt, 'YYYY-MM-DD').format('YYYY')
            oObj.examDt = this.$moment(this.bscsInp.examDt, 'YYYY-MM-DD').format('YYYYMMDD')
            oObj.wrtr = this.bscsInp.wrtr

            let gnrlArr = this.$refs.gnrlState.valThrm()
            let majorDssArr = this.$refs.majorDssState.valThrm()
            let physArr = this.$refs.physState.valThrm()
            let rhblArr = this.$refs.rhblState.valThrm()
            let nrsnCareArr = this.$refs.nrsnCareState.valThrm()
            let toothArr = this.$refs.toothState.valThrm()
            let ntrtArr = this.$refs.ntrtState.valThrm()
            let cgntnArr = this.$refs.cgntnState.valThrm()
            let cmncArr = this.$refs.cmncState.valThrm()
            let envrnArr = this.$refs.envrnState.valThrm()
            let useOfRsrArr = this.$refs.useOfRsr.valThrm()
            let indvNedsArr = this.$refs.indvNeds.valThrm()
            let cmprOpnnArr = this.$refs.cmprOpnn.valThrm()

            oObj.list = [
                ...gnrlArr, ...majorDssArr, ...physArr, ...rhblArr, ...nrsnCareArr,
                ...toothArr, ...ntrtArr, ...cgntnArr, ...cmncArr, ...envrnArr,
                ...useOfRsrArr, ...indvNedsArr, ...cmprOpnnArr
            ]
       
            this.setBnfcrExam(oObj)
        },

        rflExamBtn: function () {
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '수급자: ' + this.bscsInf.name + ', 작성일: ' + this.bscsInp.examDt
            obj.cntnt = ['욕구사정 평가를 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });
        },

        clrForm: function () {
            this.bscsInp.examRsncd = ''
            this.bscsInp.examDt = ''
            this.bscsInp.wrtr = ''
            this.bscsInp.wrtrPk = -1
        },

        clrList: function () {
            this.rec1List.splice(0)
            this.rec2List.splice(0)
            this.rec3List.splice(0)
            this.rec4List.splice(0)
            this.rec5List.splice(0)
            this.rec6List.splice(0)
            this.rec7List.splice(0)
            this.rec8List.splice(0)
            this.rec9List.splice(0)
            this.rec10List.splice(0)
            this.rec11List.splice(0)
            this.rec12List.splice(0)
            this.rec13List.splice(0)
            this.rec999List.splice(0)
        },

        clrTabInf: function () {
            this.$refs.gnrlState.clrForm()
            this.$refs.majorDssState.clrForm()
            this.$refs.physState.clrForm()
            this.$refs.rhblState.clrForm()
            this.$refs.nrsnCareState.clrForm()
            this.$refs.toothState.clrForm()
            this.$refs.ntrtState.clrForm()
            this.$refs.cgntnState.clrForm()
            this.$refs.cmncState.clrForm()
            this.$refs.envrnState.clrForm()
            this.$refs.useOfRsr.clrForm()
            this.$refs.indvNeds.clrForm()
            this.$refs.cmprOpnn.clrForm()
        },

        // 탭별 유효데이터 가공
        prcCtgrInf: function () {
            switch (this.tab) {
                case 0:
                    
                    break;
            
                default:
                    break;
            }
        },

        getEmpInf: function (obj) {
            this.bscsInp.wrtr = obj[0].name
            this.bscsInp.wrtrPk = obj[0].mmbrPk

            this.aplctSavBtn()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        // 공통 출력물 팝업 호출
        rprtActCall: function (){
            let obj = {}
            obj.actv = true         // 경고 노출여부
            obj.tmr = 3000          // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'    // 경고 유형 (success, info, warning, error)
            obj.title = ''          // 경고 타이틀
            obj.cntnt = []          // 경고 내용 (arr만 출력됨!)

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInp.examDt) == false){
                obj.title = '작성일을 올바르게 입력해주세요'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let oObj = {}
            oObj.rptTyp = '1'
            oObj.mode = '2'
            oObj.fcltyNum = this.$store.getters.getFcltyNum
            oObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            oObj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            oObj.prtClcd = '1'
            oObj.inqClcd = 2
            oObj.prtCd = '40'
            oObj.inqYYYY = this.$moment(this.bscsInp.examDt, 'YYYY-MM-DD').format('YYYY')
            oObj.list = [ { bzClcd: [], bzPk: [] } ]
            oObj.list[0].bzPk.push(this.bscsInf.bsrEvltnPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: oObj, outObj: null, runEvt: true,
            });
        },
    },

    data: () => ({
        tab: 0,
        dspAtv: true,
        savBtn: true,
        delBtn: true,
        isAction: true,
        minExamDt: '',
        rec1List: [],
        rec2List: [],
        rec3List: [],
        rec4List: [],       // 1.일반상태, 9.의사소통 목록
        rec5List: [],
        rec6List: [],
        rec7List: [],
        rec8List: [],
        rec9List: [],
        rec10List: [],
        rec11List: [],
        rec12List: [],
        rec13List: [],
        rec999List: [],
        bscsInf: { bsrEvltnPk: -1, name: '', gndr: '', age: '', entDt: '', majorDsss: '', examYyyy: '' },
        bscsInp: { examRsncd: '', examDt: '', wrtr: '', wrtrPk: -1 },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어
    }),
};
</script>

<!--
대분류: 101
중분류: 1 - ( 3.신체상태 ), 2 -( 2.주요질병상태 ), 3 - ( 8.인지상태 ), 4 - ( 1.일반상태, 9. 의사소통 ),
       5 - ( 10.가족및환경상태 ), 6 - ( 12. 수급자및보호자 개별욕구 ), 7 - ( 5.간호처치상태 ), 8 - ( 4.재활상태 ),
       9 - ( 6.구강상태 ), 10 - ( 7.영양상태 ), 11 - ( 11.자원이용 ), 999 - ( 13. 종합의견 )
소분류: 1.일반상태(8~12)
-->